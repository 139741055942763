export default function extractNumberFromString(
  value?: string | number | null | undefined,
  defaultValue: null | number = null
): number | null {
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return defaultValue;
  }
  return Number(String(value).match(/\d+/)?.[0]);
}
