import React, {useEffect, useState} from 'react';
import useFlashMessagesStore, {
  flashMessagesActions,
} from '@/stores/flashMessagesStore';
import FlashMessage from '../flash-message/FlashMessage';
import styles from './FlashMessagesService.module.scss';
import {FlashMessageData} from './flashMessageTypes';

type FlashMessageProps = {
  onCloseCallback?: () => void;
} & FlashMessageData;

function FlashMessageItem(props: FlashMessageProps) {
  const {
    message,
    noAutoHide,
    duration,
    delay,
    closeOnClick,
    onCloseCallback,
    icon,
    mode,
    withCloseButton,
    style,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const visibleTimeout = setTimeout(() => setIsVisible(true), delay);

    if (noAutoHide) {
      return () => clearTimeout(visibleTimeout);
    }

    const removeTimeout = setTimeout(
      () => onCloseCallback?.(),
      duration + delay
    );

    return () => {
      clearTimeout(visibleTimeout);
      clearTimeout(removeTimeout);
    };
  }, []);

  const onPress = () => {
    if (closeOnClick) {
      onCloseCallback?.();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      data-testid="flash-message-item"
      role="button"
      aria-label="flash-message"
      tabIndex={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        cursor: closeOnClick ? 'pointer' : undefined,
      }}
      onClick={onPress}
    >
      <FlashMessage
        testID="flash-message-item"
        message={message}
        onCloseCallback={onCloseCallback}
        icon={icon}
        mode={mode}
        style={style}
        withCloseButton={withCloseButton}
      />
    </div>
  );
}

function FlashMessagesService() {
  const {flashMessages} = useFlashMessagesStore();

  return (
    <div className={styles.container}>
      {flashMessages
        .slice()
        .sort((a: FlashMessageProps, b: FlashMessageProps) => a.delay - b.delay)
        .map((flashMessage: FlashMessageProps) => (
          <FlashMessageItem
            key={flashMessage.id}
            {...flashMessage}
            onCloseCallback={() =>
              flashMessagesActions.removeFlashMessage(flashMessage)
            }
          />
        ))}
    </div>
  );
}

export default FlashMessagesService;
