import React from 'react';
import {Box} from '@mui/material';
import {Checkbox} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

const TRANSLATION_PREFIX = 'components.list-creators-table';

type CreatorNameHeaderProps = {
  memberships: CreatorGroupMembership[];
  isLoading: boolean;
};

function CreatorNameHeader(props: CreatorNameHeaderProps) {
  const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
  const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';
  const {memberships, isLoading} = props;
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const allCreatorsSelected =
    !isLoading &&
    memberships.every((creator) => selectedCreators.has(creator.id));

  return (
    <Box sx={{height: '18px', display: 'flex'}}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          style={{height: '18px'}}
          customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
          customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
          checked={allCreatorsSelected}
          onChange={() => {
            if (allCreatorsSelected) {
              listCreatorsActions.deselectAllCreators();
            } else {
              listCreatorsActions.selectCreators(memberships);
            }
          }}
        />
      </div>
      {translate(`${TRANSLATION_PREFIX}.header.name`)}
    </Box>
  );
}

export default CreatorNameHeader;
