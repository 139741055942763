import React from 'react';
import {Avatar} from '@lightricks/react-design-system';
import {SharedListCreatorProfile} from '@/types/creatorProfile';
import Link from '@/components/link';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import styles from './CreatorsSidebar.module.scss';

interface CreatorsSidebarProps {
  testID?: string;
  creators?: SharedListCreatorProfile[];
  getCreatorProfileUrl: (id: string) => string;
  activeCreatorProfileId?: string;
}

interface CreatorAvatarProps {
  size?: '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs' | '3xs';
  creatorImageUrl: string;
  creatorDisplayName: string;
}

function CreatorAvatar({
  size = 'lg',
  creatorImageUrl,
  creatorDisplayName,
}: CreatorAvatarProps) {
  const {imageUrl, initials} = useAvatarImageOrInitials(
    creatorImageUrl,
    creatorDisplayName
  );
  return (
    <Avatar
      size={size}
      type={imageUrl ? 'picture' : 'initials'}
      initials={initials}
      src={imageUrl}
    />
  );
}

function CreatorsSidebar(props: CreatorsSidebarProps) {
  const {
    testID = 'creators-sidebar',
    creators,
    getCreatorProfileUrl,
    activeCreatorProfileId,
  } = props;
  if (!creators) {
    return null;
  }

  return (
    <div data-testid={testID} className={styles.creatorsSidebarContainer}>
      {creators.map((creator) => (
        <Link
          key={creator.id}
          to={getCreatorProfileUrl(creator.id)}
          className={`${styles.creator} ${
            creator.id === activeCreatorProfileId ? styles.active : ''
          }`}
        >
          <div className={styles.creatorImage}>
            <CreatorAvatar
              size="lg"
              creatorImageUrl={creator.imageUrl}
              creatorDisplayName={creator.displayName}
            />
          </div>
          <div className={styles.creatorName}>{creator.displayName}</div>
        </Link>
      ))}
    </div>
  );
}

export default CreatorsSidebar;
