import {ChartOptions, ChartData, ChartDataset} from 'chart.js';
import React from 'react';
import {Bar} from 'react-chartjs-2';
import styles from './BarChart.module.scss';

interface BarChartProps {
  datasets: ChartDataset<'bar', number[]>[];
  additionalOptions?: ChartOptions<'bar'>;
  labels: string[];
  displayTooltip?: boolean;
  displayLegend?: boolean;
  stackDatasets?: boolean;
}

function BarChart({
  datasets,
  additionalOptions = {},
  labels,
  displayTooltip,
  displayLegend,
  stackDatasets,
}: BarChartProps) {
  const data: ChartData<'bar', number[], string> = {
    labels,
    datasets,
  };

  const options: ChartOptions<'bar'> = {
    hover: {
      mode: undefined,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: displayTooltip,
      },
      legend: {
        display: displayLegend,
        position: 'bottom',
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        stacked: stackDatasets,
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        stacked: stackDatasets,
      },
    },
    ...additionalOptions,
  };

  return (
    <div className={styles.barChartContainer}>
      <Bar data={data} options={options} />
    </div>
  );
}

export default BarChart;
