import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import getChartLabelAbbreviation from '@/utils/getChartLabelAbbreviation';
import getTickValues from '@/utils/getTickValues';
import isNumber from '@/utils/isNumber';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {Post} from '@/types/post';
import PoppaysLoader from '@/components/PoppaysLoader';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import Conditional from '@/components/conditional';
import defaultDataByMonth, {
  DataByMonth,
} from '@/components/creator-profile/components/identity-social-health/utils/defaultDataByMonth';
import defaultLineChartTooltipLabel from '@/components/creator-profile/components/identity-social-health/utils/defaultLineChartTooltipLabel';
import {
  ADDITIONAL_OPTIONS_BASE,
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';
import {SocialHealthChartProps} from '../../socialHealthChart';

const TRANSLATION_PREFIX =
  'components.creator-profile.identity-social-health.charts.impressions';

interface MonthlyData {
  averageImpressions: number | null;
}

function getFilteredPosts(posts: Post[]) {
  if (!posts) {
    return [];
  }

  const postsWithData = posts.filter((post) => !!post.impressionCount);

  return sortBy(postsWithData, (post) => dayjs(post.createdAtDay).unix());
}

function getAverageImpressions(posts: Post[]) {
  if (!posts || !posts.length) {
    return null;
  }

  const totalImpressions = posts.reduce(
    (total, post) => total + (post.impressionCount || 0),
    0
  );

  return totalImpressions / posts.length;
}

function ImpressionsChart(props: SocialHealthChartProps) {
  const {posts, isLoadingPosts} = props;

  const filteredPosts = useMemo(() => getFilteredPosts(posts), [posts]);

  const dataByMonth: DataByMonth<MonthlyData>[] = useMemo(
    () =>
      defaultDataByMonth(filteredPosts, (postsForMonth) => ({
        averageImpressions: getAverageImpressions(postsForMonth),
      })),
    [filteredPosts]
  );

  const averageImpressionsData = dataByMonth.map(
    ({data: {averageImpressions}}) => averageImpressions
  );

  const months = dataByMonth.map(({month}) => month);

  const impressionsWithoutNulls = averageImpressionsData.filter(isNumber);
  const impressionTickValues = getTickValues(impressionsWithoutNulls);

  const chart = (
    <Conditional
      condition={!isLoadingPosts}
      fallback={<PoppaysLoader absolute />}
    >
      <LineChart
        datasets={[
          {
            label: translate(`${TRANSLATION_PREFIX}.average-impressions`),
            fill: false,
            data: averageImpressionsData,
            yAxisID: 'y',
            backgroundColor: LEGACY_COLORS.BLUE,
            borderColor: LEGACY_COLORS.BLUE,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
          },
        ]}
        additionalOptions={{
          ...ADDITIONAL_OPTIONS_BASE,
          scales: {
            x: getDefaultScaleX(months, SHORT_MONTH_YEAR_FORMAT),
            y: getDefaultScaleY(
              translate(`${TRANSLATION_PREFIX}.impressions`),
              impressionTickValues,
              (label) => getChartLabelAbbreviation(label as number)
            ),
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              intersect: false,
              callbacks: {
                label: defaultLineChartTooltipLabel,
              },
            },
          },
        }}
        labels={months}
      />
    </Conditional>
  );

  return (
    <ChartLayout
      title={translate(`${TRANSLATION_PREFIX}.impressions`)}
      chart={chart}
      showEmptyState={filteredPosts.length === 0 && !isLoadingPosts}
    />
  );
}

export default ImpressionsChart;
