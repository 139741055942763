import React, {useMemo} from 'react';
import {Checkbox, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ContentAgreementTerm} from '@/types/campaign';
import {
  BriefFieldManagerAttributes,
  ErrorMessages,
} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import {getFooterFields} from '@/views/campaigns/brief/brief-field-manager/brief-field/ContentAgreementBriefItem';
import BriefSubSection from '@/views/campaigns/brief/components/campaign-brief/components/brief-sub-section';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {SectionProps} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  DEFAULT_SAVE_CAMPAIGN_DELAY,
  IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY,
} from '@/views/campaigns/brief/constants';
import Select from '@/components/select';
import useContentAgreementTermsQuery from '@/hooks/queries/use-content-agreement-terms-query';
import styles from './ContentAgreement.module.scss';

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.content-agreement';

const TIME_PERIOD_OPTIONS = [
  {value: '6', labelLocaleKey: `${TRANSLATION_PREFIX}.time-period-6`},
  {value: '12', labelLocaleKey: `${TRANSLATION_PREFIX}.time-period-12`},
];

function AgreementTermCheckbox({
  contentAgreementTerm,
  checked,
  disabled,
  onChange,
}: {
  contentAgreementTerm: ContentAgreementTerm;
  checked: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <div className={styles.contentAgreementTermCheckboxContainer}>
      <Checkbox
        value={checked}
        checked={checked}
        onChange={(value: boolean) => onChange(value)}
        disabled={disabled}
      />
      <label>{contentAgreementTerm.displayText}</label>
    </div>
  );
}

function AgreementTerms({
  editable,
  contentAgreementTermsOptions,
  contentAgreementTerms,
  contentAgreementTimePeriodInMonths,
  updateBriefForm,
  errors,
}: {
  editable?: boolean;
  contentAgreementTermsOptions: ContentAgreementTerm[];
  contentAgreementTerms: ContentAgreementTerm[];
  contentAgreementTimePeriodInMonths?: string;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  errors?: ErrorMessages;
}) {
  const checkedAgreementTerms = useMemo(
    () =>
      contentAgreementTerms.reduce((acc: any, contentAgreementTerm) => {
        acc[contentAgreementTerm.id] = true;
        return acc;
      }, {}),
    [contentAgreementTerms]
  );

  const getAgreementTerms = () => {
    return editable
      ? contentAgreementTermsOptions
      : contentAgreementTermsOptions.filter(
          (term) => !!checkedAgreementTerms[term.id]
        );
  };

  const onContentAgreementTermChange = (
    contentAgreementTerm: ContentAgreementTerm,
    value: boolean
  ) => {
    if (value) {
      const checkedAgreementTermsObject = [
        ...contentAgreementTerms,
        contentAgreementTerm,
      ].reduce((acc: any, term) => {
        acc[term.id] = true;
        return acc;
      }, {});
      const newAgreementTerms = contentAgreementTermsOptions.filter(
        (term) => checkedAgreementTermsObject[term.id]
      );
      updateBriefForm?.(
        `contentAgreementTerms`,
        newAgreementTerms,
        'contentAgreementTerms',
        DEFAULT_SAVE_CAMPAIGN_DELAY
      );
    } else {
      updateBriefForm?.(
        `contentAgreementTerms`,
        contentAgreementTerms.filter(
          (term) => term.id !== contentAgreementTerm.id
        ),
        'contentAgreementTerms',
        DEFAULT_SAVE_CAMPAIGN_DELAY
      );
    }
  };

  const getTimePeriodOptions = () => {
    return (
      <Select
        testID={
          CAMPAIGN_BRIEF_TEST_IDS.CONTENT_AGREEMENT__CONTENT_AGREEMENT_TIME_PERIOD_IN_MONTHS
        }
        containerClassName={styles.selectContainer}
        immediateOnChange
        disabled={!editable}
        options={TIME_PERIOD_OPTIONS.map((option) => ({
          ...option,
          id: option.value,
          label: translate(option.labelLocaleKey),
        }))}
        variant="onboarding"
        selectedValueIds={[String(contentAgreementTimePeriodInMonths)]}
        checkmarkRadioButtonClassName={styles.checkmarkRadioButtonClassName}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(value: string) => {
          updateBriefForm?.(
            'contentAgreementTimePeriodInMonths',
            Number(value),
            'contentAgreementTimePeriodInMonths',
            IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
          );
        }}
      />
    );
  };

  return (
    <div className={styles.contentAgreementContainer}>
      <ValidatedField errors={errors?.contentAgreementTerms}>
        <div
          className={`${styles.contentAgreementTermsContainer} ${
            errors?.contentAgreementTerms ? styles.invalid : ''
          }`}
        >
          <div
            className={styles.contentAgreementTermsCheckboxes}
            data-testid={
              CAMPAIGN_BRIEF_TEST_IDS.CONTENT_AGREEMENT__TERMS_CHECKBOXES
            }
          >
            {getAgreementTerms().map((contentAgreementTerm) => (
              <AgreementTermCheckbox
                key={contentAgreementTerm.id}
                disabled={!editable}
                contentAgreementTerm={contentAgreementTerm}
                checked={!!checkedAgreementTerms[contentAgreementTerm.id]}
                onChange={(value) =>
                  onContentAgreementTermChange(contentAgreementTerm, value)
                }
              />
            ))}
          </div>
        </div>
      </ValidatedField>
      <div className={styles.timePeriod}>
        <BriefSubSection
          disabled={!editable}
          title={translate(`${TRANSLATION_PREFIX}.time-period`)}
        >
          {getTimePeriodOptions()}
        </BriefSubSection>
      </div>
    </div>
  );
}

function Footer({
  briefFieldManager,
}: {
  briefFieldManager: BriefFieldManagerAttributes;
}) {
  const footerFields = getFooterFields(
    briefFieldManager.fields.contentAgreementTerms.value
  );

  return (
    <div className={styles.footerContainer}>
      <label dangerouslySetInnerHTML={{__html: footerFields.join('')}} />
      <div className={styles.footerNote}>
        <label>
          <Icon size="small" appearance="secondary" name="Actions-Info-Line" />
        </label>
        <label
          dangerouslySetInnerHTML={{
            __html: translate(`${TRANSLATION_PREFIX}.footer-note`),
          }}
        />
      </div>
    </div>
  );
}

function ContentAgreement(props: SectionProps) {
  const {data: availableAgreementTerms} = useContentAgreementTermsQuery();
  const {updateBriefForm, editable, errors, saveCampaign, briefFieldManager} =
    props;

  return (
    <div className={styles.container}>
      <BriefSubSection
        disabled={!editable}
        title={translate(
          `${TRANSLATION_PREFIX}.rights-to-content-created-title`
        )}
        titleClassName={styles.titleLabel}
      >
        <div className={styles.rightsToContentCreated}>
          <label className={styles.contentLabel}>
            {translate(
              `${TRANSLATION_PREFIX}.rights-to-content-created-content`
            )}
          </label>
        </div>
      </BriefSubSection>
      <AgreementTerms
        editable={editable}
        contentAgreementTermsOptions={availableAgreementTerms || ([] as any)}
        contentAgreementTerms={
          briefFieldManager.fields.contentAgreementTerms.value
        }
        contentAgreementTimePeriodInMonths={
          briefFieldManager.fields.contentAgreementTimePeriodInMonths.value
        }
        updateBriefForm={updateBriefForm}
        errors={errors}
      />
      <Footer briefFieldManager={briefFieldManager} />
    </div>
  );
}

export default ContentAgreement;
