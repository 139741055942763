import {useEffect, useRef} from 'react';

function usePrevious(value: any, initialValue?: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current || initialValue;
}

export default usePrevious;
