import React, {useEffect, useMemo, useState} from 'react';
import {Box, Stack} from '@mui/material';
import {Body, Button} from '@lightricks/react-design-system';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import Autocomplete from '@/views/creators/components/autocomplete';
import {Option} from '@/components/select/SelectProps';
import useRaiseCreatorInvitedToCampaignSuccess from '@/hooks/flash-messages/use-raise-creator-invited-to-campaign-success/useRaiseCreatorInvitedToCampaignSuccess';
import useInviteCreatorsToCampaign from '@/hooks/mutations/creators/use-invite-creators-to-campaign';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import {CampaignListItem} from '@/hooks/queries/use-brand-campaigns-query/useBrandCampaignsQuery';
import useBrandId from '@/hooks/use-brand-id';
import {ActionProps} from './ActionProps';
import styles from './AddToCampaign.module.scss';
import ContentContainer from './ContentContainer';
import SubmitButtonContainer from './SubmitButtonContainer';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.add-to-campaign';

function AddToCampaign(
  props: ActionProps & {setHasActiveCampaigns: (hasActive: boolean) => void}
) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNoActiveCampaignsModalOpen, setIsNoActiveCampaignsModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {creators, selectedCampaignId, onSubmitSuccess, setHasActiveCampaigns} =
    props;
  const brandId = useBrandId();
  const inviteCreatorsToCampaign = useInviteCreatorsToCampaign();
  const raiseCreatorInvitedToCampaignSuccess =
    useRaiseCreatorInvitedToCampaignSuccess();

  const {
    brandCampaigns: campaignsAcceptingProposals,
    isLoading: isCampaignsLoading,
  } = useBrandCampaignsQuery({
    brandId,
    states: ['accepting_proposals'],
  });

  useEffect(() => {
    if (!isCampaignsLoading) {
      setIsLoading(false);
    }
  }, [isCampaignsLoading]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const creatorIds = creators.map((creator) => creator.id);
    try {
      const result = await inviteCreatorsToCampaign.invite({
        creatorIds,
        campaignId: selectedOption?.id ?? '',
      });
      if (result) {
        onSubmitSuccess(selectedOption?.id);
        raiseCreatorInvitedToCampaignSuccess(creatorIds.length);
      }
    } catch {
      raiseFlashMessageError();
    }
    setIsSubmitting(false);
  };

  const mappedOptions = useMemo(
    () =>
      (campaignsAcceptingProposals || []).map((campaign: CampaignListItem) => ({
        id: campaign.id,
        label: campaign.name,
      })),
    [campaignsAcceptingProposals]
  );

  const selectedCampaign = useMemo(() => {
    return (
      mappedOptions.find((option) => option.id === selectedCampaignId) || null
    );
  }, [mappedOptions, selectedCampaignId]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(
    selectedCampaign
  );

  useEffect(() => {
    if (isLoading) return;
    // auto select the only option if there is only one
    if (mappedOptions.length === 1) {
      setSelectedOption(mappedOptions[0]);
    }
    if (mappedOptions.length === 0) {
      setIsNoActiveCampaignsModalOpen(true);
      setHasActiveCampaigns(false);
    } else {
      setHasActiveCampaigns(true);
    }
  }, [mappedOptions, setHasActiveCampaigns, isLoading]);

  const handleChange = (_: React.SyntheticEvent, value: Option) => {
    setSelectedOption(value);
  };

  if (isNoActiveCampaignsModalOpen) {
    return null;
  }

  return (
    <Stack>
      <ContentContainer>
        <Autocomplete
          placeholder={translate(`${TRANSLATION_PREFIX}.dropdown-placeholder`)}
          options={mappedOptions}
          selectedOptions={selectedOption}
          onChange={handleChange}
          disableCloseOnSelect={false}
        />
        <Box className={styles.warningContainer}>
          <Body
            className={styles.warningText}
            size="lg"
            dangerouslySetInnerHTML={{
              __html: translate(`${TRANSLATION_PREFIX}.invite-action-warning`),
            }}
          />
        </Box>
      </ContentContainer>
      <SubmitButtonContainer>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          disabled={!selectedOption || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {translate(`${TRANSLATION_PREFIX}.submit-button`)}
        </Button>
      </SubmitButtonContainer>
    </Stack>
  );
}

export default AddToCampaign;
