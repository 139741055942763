import clsx from 'clsx';
import React from 'react';
import {Container} from '@mui/material';
import {Display} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {type VettableCreators} from '@/types/vettableCreatorsResponse';
import CreatorSearchBar from '../creator-search-bar';
import styles from './SearchSection.module.scss';
import {ReactComponent as DarkBanner} from './images/dark.svg';
import {ReactComponent as LightBanner} from './images/light.svg';

type SearchSectionProps = {
  testID?: string;
  headline?: string;
  darkMode?: boolean;
  searchResults: VettableCreators | undefined;
  onSubmitSearch: (value: string) => void;
  onResetSearch: () => void;
  isLoading?: boolean;
};

function SearchSection(props: SearchSectionProps) {
  const {
    testID = 'search-section',
    headline = translate('safe-collabs-start-with-vetting'),
    darkMode = false,
    searchResults,
    onSubmitSearch,
    onResetSearch,
    isLoading,
  } = props;

  const Banner = React.useCallback(() => {
    return darkMode ? (
      <DarkBanner className={styles.banner} />
    ) : (
      <LightBanner className={styles.banner} />
    );
  }, [darkMode]);

  return (
    <Container
      className={clsx(styles.container, darkMode && styles.containerDarkMode)}
      data-testid={testID}
    >
      <div className={styles.bannerContainer}>
        <Banner />
      </div>
      <Display className={styles.headline} size="sm">
        {headline}
      </Display>
      <CreatorSearchBar
        resultsData={searchResults}
        onSubmit={onSubmitSearch}
        onClose={onResetSearch}
        isLoading={isLoading}
      />
    </Container>
  );
}

export default SearchSection;
