import React from 'react';
import {Outlet} from 'react-router-dom';
import styles from './Campaigns.module.scss';

function Campaigns() {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
}

export default Campaigns;
