import vettingHubFetchers from '@/api/fetchers/vettingHub';
import useDataQuery from '@/hooks/use-data-query';

type VettableCreatorsQueryArgs = {
  searchTerm: string | undefined;
};

function useVettableCreatorsQuery({searchTerm}: VettableCreatorsQueryArgs) {
  return useDataQuery({
    queryKey: ['vettableCreators', searchTerm],
    queryFn: vettingHubFetchers.vettableCreators,
    meta: {handle: searchTerm},
    enabled: !!searchTerm,
  });
}

export default useVettableCreatorsQuery;
