import dayjs from 'dayjs';
import i18n from 'i18next';
import {
  camelCase,
  capitalize,
  difference,
  get,
  orderBy,
  snakeCase,
} from 'lodash';
import {useMemo} from 'react';
import translate from '@/utils/translate';
import {PostAnalysis, PostAnalysisFailure} from '@/types/creatorSafetyReport';
import {TimeRange} from '@/types/timeRange';
import {
  FAILED_SCAN_FLAG,
  Severity,
  VETTING_CATEGORY,
  VETTING_FLAGS_TO_CATEGORY_MAPPING,
} from '@/components/creator-vetting-report/constants';
import getSeverityFromFlagScore from '@/components/creator-vetting-report/utils/getSeverityFromFlagScore';

interface Flag {
  id: string;
  name: string;
  postsAmount: number;
  percentage: number;
  posts: PostAnalysis[];
}

export interface VettingCategory {
  id: string;
  name: string;
  postsAmount: number;
  percentage: number;
  flags: Flag[];
}

export interface FlagWithCategory extends Flag {
  category: Omit<VettingCategory, 'flags'>;
}

export interface FailedFlagWithCategory
  extends Omit<FlagWithCategory, 'posts'> {
  posts: PostAnalysisFailure[];
}

export interface VettingCategories {
  flagged: VettingCategory[];
  cleared: VettingCategory[];
  failed: FailedFlagWithCategory;
  flatFlaggedWithCategory: FlagWithCategory[];
  totalFlaggedPosts: number;
  filteredFlaggedPosts: number;
}

interface FlagAcc extends Flag {
  topScore: number;
  flagTopScore: number;
}

interface FlagObjectAcc {
  [key: string]: FlagAcc;
}

interface VettingCategoryAcc extends Omit<VettingCategory, 'flags'> {
  topScore: number;
  flags: FlagObjectAcc;
}

interface CategoryAcc {
  [key: string]: VettingCategoryAcc;
}

interface VettingFilters {
  timeRange: TimeRange;
  severity: Severity;
}

function getCategoryFlags(category: string): FlagObjectAcc {
  return Object.entries(VETTING_FLAGS_TO_CATEGORY_MAPPING)
    .filter(([_, flagCategory]) => flagCategory === category)
    .reduce((acc: FlagObjectAcc, [flag]) => {
      acc[flag] = {
        id: flag,
        name: flag,
        postsAmount: 0,
        percentage: 0,
        topScore: 0,
        flagTopScore: 0,
        posts: [],
      };
      return acc;
    }, {});
}

function getPrettyName(name: string, camelCaseLookup = false): string {
  let lookupName = name;

  if (camelCaseLookup) {
    lookupName = camelCase(name);
  }

  if (i18n.exists(lookupName)) {
    return translate(lookupName);
  }
  const splitName = name.split('_');
  const [firstWord, ...rest] = splitName;

  return [
    capitalize(firstWord.toLowerCase()),
    ...rest.map((word) => word.toLowerCase()),
  ].join(' ');
}

function getPostIsAtGivenSeverity(
  severity: Severity,
  post: PostAnalysis,
  flag: string
): boolean {
  if (severity === Severity.ALL) {
    return true;
  }
  const {flags} = post.mediaAnalysisResult;

  const flagScore = get(flags, `${flag}.score`, 0) as number;
  const flagSeverity = getSeverityFromFlagScore(flagScore);
  return flagSeverity === severity;
}

function filteredPosts(
  posts: PostAnalysis[],
  vettingFilters: VettingFilters,
  flag: string
): PostAnalysis[] {
  return posts.filter((post: PostAnalysis) => {
    const {timeRange} = vettingFilters;
    if (!timeRange.start || !timeRange.end) {
      return true;
    }
    const {createdAt} = post.postMetadata;
    const createdAtDate = dayjs(createdAt).toDate();
    const isAtGivenCreatedAtDateRange =
      createdAtDate >= timeRange.start && createdAtDate <= timeRange.end;
    const isAtGivenSeverity = getPostIsAtGivenSeverity(
      vettingFilters.severity,
      post,
      flag
    );
    return isAtGivenCreatedAtDateRange && isAtGivenSeverity;
  });
}

function useGetVettingCategories(
  flaggedPosts: PostAnalysis[],
  failedPosts: PostAnalysisFailure[],
  vettingFilters: VettingFilters
): VettingCategories {
  const flaggedCategories = useMemo(
    () =>
      flaggedPosts.reduce((acc: CategoryAcc, post) => {
        const {flags} = post.mediaAnalysisResult;
        Object.keys(flags).forEach((flag) => {
          if (!(flag in VETTING_FLAGS_TO_CATEGORY_MAPPING)) {
            return;
          }
          const category = VETTING_FLAGS_TO_CATEGORY_MAPPING[flag];
          if (!acc[category]) {
            acc[category] = {
              id: category,
              name: category,
              postsAmount: 0,
              percentage: 0,
              topScore: 0,
              flags: getCategoryFlags(category),
            };
          }
          acc[category].postsAmount += 1;
          const flagScore = flags[flag]?.score || 0;
          if (flagScore > acc[category].topScore) {
            acc[category].topScore = flagScore;
          }
          if (!acc[category].flags[flag]) {
            acc[category].flags[flag] = {
              id: flag,
              name: flag,
              postsAmount: 0,
              percentage: 0,
              topScore: 0,
              flagTopScore: 0,
              posts: [],
            };
          }
          acc[category].flags[flag].postsAmount += 1;
          acc[category].flags[flag].topScore = acc[category].topScore;
          if (flagScore > acc[category].flags[flag].flagTopScore) {
            acc[category].flags[flag].flagTopScore = flagScore;
          }
          acc[category].flags[flag].posts.push(post);
        });

        return acc;
      }, {}),
    [flaggedPosts]
  );

  const flagged = useMemo(
    () =>
      orderBy(
        Object.values(flaggedCategories).map((category) => ({
          id: category.name,
          name: getPrettyName(category.name),
          postsAmount: category.postsAmount,
          percentage: category.topScore * 100,
          flags: orderBy(
            Object.values(category.flags).map((flag) => ({
              ...flag,
              percentage: flag.topScore * 100,
              name: getPrettyName(snakeCase(flag.name), true),
            })),
            ['name'],
            ['asc']
          ),
        })),
        ['percentage'],
        ['desc']
      ),
    [flaggedCategories]
  );

  const clearedCategories = useMemo(
    () =>
      difference(
        Object.keys(VETTING_CATEGORY),
        flagged.map((category) => category.id)
      ),
    [flagged]
  );

  const cleared = useMemo(
    () =>
      orderBy(
        clearedCategories.map((category) => ({
          id: category,
          name: getPrettyName(category),
          postsAmount: 0,
          percentage: 0,
          flags: orderBy(
            Object.values(getCategoryFlags(category)).map((flag) => ({
              ...flag,
              name: getPrettyName(snakeCase(flag.name), true),
            })),
            ['name'],
            ['asc']
          ),
        })),
        ['name'],
        ['asc']
      ),
    [clearedCategories]
  );

  const flatFlaggedWithCategory = useMemo(
    () =>
      orderBy(
        flagged.reduce((acc: FlagWithCategory[], category) => {
          const filteredFlaggedCategories = category.flags
            .filter((flag) => flag.postsAmount > 0)
            .map((flag) => {
              const posts = orderBy(
                filteredPosts(flag.posts, vettingFilters, flag.id),
                [`mediaAnalysisResult.flags.${flag.id}.score`],
                ['desc']
              );
              return {
                ...flag,
                posts,
                postsAmount: posts.length,
                category: {
                  id: category.id,
                  name: category.name,
                  postsAmount: posts.length,
                  percentage: category.percentage,
                },
              };
            });

          return acc.concat(
            filteredFlaggedCategories.filter((flag) => flag.postsAmount > 0)
          );
        }, []),
        ['flagTopScore'],
        ['desc']
      ),
    [flagged, vettingFilters]
  );

  const failed = useMemo(() => {
    const posts = orderBy(failedPosts, [`postMetadata.createdAt`], ['desc']);
    return {
      id: FAILED_SCAN_FLAG,
      name: translate('Content Not Scanned'),
      postsAmount: posts.length,
      posts,
      percentage: -1,
      category: {
        id: FAILED_SCAN_FLAG,
        name: translate('Content Not Scanned'),
        postsAmount: posts.length,
        percentage: -1,
      },
    };
  }, [failedPosts]);

  const totalFlaggedPosts = flaggedPosts.reduce((acc, post) => {
    const {flags} = post.mediaAnalysisResult;
    return acc + Object.keys(flags).length;
  }, 0);
  const filteredFlaggedPosts = flatFlaggedWithCategory.reduce((acc, flag) => {
    return acc + flag.postsAmount;
  }, 0);

  return {
    flagged,
    cleared,
    failed,
    flatFlaggedWithCategory,
    totalFlaggedPosts,
    filteredFlaggedPosts,
  };
}

export default useGetVettingCategories;
