import {useMutation} from '@tanstack/react-query';
import externalProfileUpdaters from '@/api/updaters/externalProfile';

function useCreateExternalProfile() {
  return useMutation({
    mutationKey: ['externalProfile', 'create'],
    mutationFn: externalProfileUpdaters.create,
  });
}

export default useCreateExternalProfile;
