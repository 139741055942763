import React, {useEffect, useMemo, useRef} from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {v4 as uuid} from 'uuid';
import {Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {
  DELIVERABLES_OPTIONS_BY_NETWORK,
  DELIVERABLES_OPTIONS_CONTENT_CREATION,
} from '@/views/campaigns/brief/utils/getDeliverablesOptionsByNetwork';
import ErrorLabel from '@/components/error-label';
import {CAMPAIGN_TYPE} from '@/components/new-campaign-wizard-modal/CampaignTypeOptions';
import OptionButton from '@/components/option-button';
import StepContainer from '../../components/step-container';
import StepSectionContainer from '../../components/step-section-container';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import {StepProps} from '../StepProps';
import styles from './Deliverables.module.scss';

const TRANSLATION_PREFIX =
  'views.campaigns.components.wizard.steps.deliverables';
const MAX_REQUIRED_DELIVERABLES = 10;

function RequiredDeliverable({
  id,
  index,
  value,
  title,
  subtitle,
  titleOverride,
  icon,
  onDelete,
}: any) {
  const deliverableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    deliverableRef.current?.scrollIntoView?.({behavior: 'smooth'});
  }, []);

  return (
    <div
      ref={deliverableRef}
      className={styles.deliverableItem}
      data-testid={`${BRIEF_WIZARD_TEST_IDS.DELIVERABLES__DELIVERABLE_ITEM}--${index}`}
    >
      <div className={styles.deliverableItemInfo}>
        <span>{icon}</span>
        <div className={styles.deliverableItemDescription}>
          <label className={styles.title}>{titleOverride || title}</label>
          <label className={styles.dash}>-</label>
          <label className={styles.subtitle}>{subtitle}</label>
        </div>
      </div>
      <div className={styles.deleteDeliverable} onClick={() => onDelete(id)}>
        <Icon size="medium" appearance="neutral" name="Actions-Remove" />
      </div>
    </div>
  );
}

function Deliverables(props: StepProps) {
  const {
    testID,
    formData,
    formErrors,
    updateFormData,
    onClickNext,
    canContinue,
    campaignType,
  } = props;

  const awarenessDeliverables = useMemo(
    () =>
      formData.network
        ? DELIVERABLES_OPTIONS_BY_NETWORK[formData.network].map(
            (deliverable: any) => ({
              icon: deliverable.icon,
              value: deliverable.value,
              title: translate(deliverable.labelLocaleKeyTitle),
              subtitle: translate(deliverable.labelLocaleKeySubtitle),
              titleOverride: deliverable.labelLocaleKeyTitleOverride
                ? translate(deliverable.labelLocaleKeyTitleOverride)
                : null,
            })
          )
        : [],
    [formData.network]
  );

  const contentDeliverables = useMemo(
    () =>
      DELIVERABLES_OPTIONS_CONTENT_CREATION.map((deliverable: any) => ({
        icon: deliverable.icon,
        value: deliverable.value,
        title: translate(deliverable.labelLocaleKeyTitle),
        subtitle: translate(deliverable.labelLocaleKeySubtitle),
        titleOverride: deliverable.labelLocaleKeyTitleOverride
          ? translate(deliverable.labelLocaleKeyTitleOverride)
          : null,
      })),
    []
  );

  const getErrorLabel = () => {
    if (formErrors.requiredDeliverables) {
      return (
        <ErrorLabel className={styles.stepError}>
          {formErrors.requiredDeliverables}
        </ErrorLabel>
      );
    }
    if (formData.requiredDeliverables.length === MAX_REQUIRED_DELIVERABLES) {
      return (
        <ErrorLabel className={styles.stepError}>
          {translate(
            `views.campaigns.components.wizard.errors.required-deliverables-limit`
          )}
        </ErrorLabel>
      );
    }
    return null;
  };

  function renderBrandAwarenessDeliverables() {
    return campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS ? (
      <StepSectionContainer
        title={translate(
          `${TRANSLATION_PREFIX}.awareness-deliverables.deliverables-title`
        )}
        subtitle={translate(
          `${TRANSLATION_PREFIX}.awareness-deliverables.deliverables-subtitle`
        )}
        containerClassName={styles.stepsSectionCustomStyle}
      >
        <div className={styles.deliverablesContainer}>
          {awarenessDeliverables.map((deliverable: any) => (
            <OptionButton
              testID={`${BRIEF_WIZARD_TEST_IDS.DELIVERABLES__DELIVERABLE_TYPE}--${deliverable.value}`}
              key={deliverable.value}
              className={styles.deliverableButton}
              disabled={
                formData.requiredDeliverables.length >=
                MAX_REQUIRED_DELIVERABLES
              }
              onClick={() =>
                updateFormData?.({
                  requiredDeliverables: [
                    ...formData.requiredDeliverables,
                    {id: uuid(), ...deliverable},
                  ],
                })
              }
              isActive={false}
            >
              <div className={styles.deliverableButton}>
                <span>{deliverable.icon}</span>
                <div className={styles.deliverableButtonInfo}>
                  <label className={styles.title}>{deliverable.title}</label>
                  <label className={styles.subtitle}>
                    {deliverable.subtitle}
                  </label>
                </div>
              </div>
            </OptionButton>
          ))}
        </div>
      </StepSectionContainer>
    ) : null;
  }

  function renderContentCreationDeliverables() {
    return (
      <StepSectionContainer
        title={
          campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
            ? undefined
            : translate(
                `${TRANSLATION_PREFIX}.awareness-deliverables.deliverables-title`
              )
        }
        subtitle={
          campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
            ? translate(
                `${TRANSLATION_PREFIX}.content-deliverables.deliverables-subtitle`
              )
            : translate(
                `${TRANSLATION_PREFIX}.content-deliverables.deliverables-subtitle-content-only`
              )
        }
        containerClassName={styles.stepsSectionCustomStyle}
      >
        <div
          className={`${styles.deliverablesContainer} ${
            campaignType === CAMPAIGN_TYPE.BRAND_AWARENESS
              ? ''
              : styles.deliverablesContentContainer
          }`}
        >
          {contentDeliverables.map((deliverable: any) => (
            <OptionButton
              testID={`${BRIEF_WIZARD_TEST_IDS.DELIVERABLES__DELIVERABLE_TYPE}--${deliverable.value}`}
              key={deliverable.value}
              className={styles.deliverableButton}
              disabled={
                formData.requiredDeliverables.length >=
                MAX_REQUIRED_DELIVERABLES
              }
              onClick={() =>
                updateFormData?.({
                  requiredDeliverables: [
                    ...formData.requiredDeliverables,
                    {id: uuid(), ...deliverable},
                  ],
                })
              }
              isActive={false}
            >
              <div className={styles.deliverableButton}>
                <span>{deliverable.icon}</span>
                <div className={styles.deliverableButtonInfo}>
                  <label className={styles.title}>{deliverable.title}</label>
                  <label className={styles.subtitle}>
                    {deliverable.subtitle}
                  </label>
                </div>
              </div>
            </OptionButton>
          ))}
        </div>
      </StepSectionContainer>
    );
  }

  function renderSelectedDeliverablesList() {
    return (
      <StepSectionContainer>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <TransitionGroup
          className={`${styles.selectedDeliverables} ${
            !formData.requiredDeliverables.length
              ? styles.noSelectedDeliverables
              : ''
          } ${formData.requiredDeliverables.length >= 4 ? styles.shadow : ''}`}
        >
          {formData.requiredDeliverables.map((requiredDeliverable, index) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <CSSTransition
              key={requiredDeliverable.id}
              timeout={250}
              classNames="fade"
            >
              <RequiredDeliverable
                key={requiredDeliverable.id}
                index={index}
                {...requiredDeliverable}
                onDelete={(id: string) =>
                  updateFormData?.({
                    requiredDeliverables: formData.requiredDeliverables.filter(
                      (_requiredDeliverable) => _requiredDeliverable.id !== id
                    ),
                  })
                }
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {getErrorLabel()}
      </StepSectionContainer>
    );
  }

  return (
    <div className={styles.container}>
      <StepContainer
        onClickContinue={onClickNext}
        continueButtonDisabled={!canContinue}
        testID={testID}
        containerClassName={styles.stepContainerCustomStyle}
      >
        {renderBrandAwarenessDeliverables()}
        {renderContentCreationDeliverables()}
        {renderSelectedDeliverablesList()}
      </StepContainer>
    </div>
  );
}

export default Deliverables;
