import React from 'react';
import {Body, Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './Header.module.scss';

const TRANSLATION_PREFIX = 'views.shared-list';

function Header({title}: {title: string}) {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleWrap}>
        <Headline size="sm" className={styles.title}>
          {title}
        </Headline>
      </div>
      <div className={styles.poweredBy}>
        <Body size="sm" className={styles.text}>
          {translate(`${TRANSLATION_PREFIX}.powered-by`)}
        </Body>
        <div className={styles.logo} />
      </div>
    </div>
  );
}

export default Header;
