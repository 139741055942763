import React, {useLayoutEffect, useRef} from 'react';
import styles from './Page.module.scss';

export type PageProps = {
  testID?: string;
  id?: string;
  className?: string;
  children: React.ReactNode;
  childrenClassName?: string;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  stickyHeader?: boolean;
  childrenStyle?: React.CSSProperties;
  scrollToTopOnMount?: boolean;
  pageRef?: React.RefObject<HTMLDivElement>;
};

function Page(props: PageProps) {
  const {
    testID = 'page',
    id,
    className,
    children,
    childrenClassName,
    headerComponent,
    footerComponent,
    stickyHeader,
    childrenStyle,
    scrollToTopOnMount,
    pageRef,
  } = props;
  const footerRef = useRef(null);
  const [childrenStyleProxy, setChildrenStyleProxy] =
    React.useState(childrenStyle);

  useLayoutEffect(() => {
    if (scrollToTopOnMount) {
      window.scrollTo(0, 0);
    }
  }, []);

  useLayoutEffect(() => {
    if (footerRef.current) {
      setChildrenStyleProxy({
        ...childrenStyle,
        // paddingBottom: (footerRef.current as { offsetHeight: number })?.offsetHeight || 0
      });
    }
  }, [children]);

  return (
    <div
      id={id}
      className={`${styles.container} ${className || ''}`}
      data-testid={testID}
      ref={pageRef}
    >
      <div
        className={`${styles.children || ''} ${childrenClassName || ''}`}
        style={childrenStyleProxy}
      >
        {headerComponent ? (
          <div
            className={`${styles.header} ${
              stickyHeader ? styles.stickyHeader : ''
            }`}
          >
            {headerComponent}
          </div>
        ) : null}
        {children}
        {footerComponent ? (
          <div className={styles.footer} ref={footerRef}>
            {footerComponent}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Page;
