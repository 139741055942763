import React from 'react';
import styles from './ErrorLabel.module.scss';

function ErrorLabel({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return children ? (
    <label className={`${styles.error} ${className || ''}`}>{children}</label>
  ) : null;
}

export default ErrorLabel;
