import {v4 as uuid} from 'uuid';
import translate from '@/utils/translate';
import {
  BASE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
  TERMS_AND_CONDITIONS_BY_NETWORK,
} from '@/views/campaigns/brief/components/campaign-brief/section/terms-and-conditions/constants';

const getTermsAndConditionsSectionBriefItems = (
  brandName?: string,
  network?: string
) => {
  const TRANSLATION_PREFIX =
    'components.campaign-brief.sections.terms-and-conditions.sub-sections';

  const termsAndConditionsByNetwork = network
    ? TERMS_AND_CONDITIONS_BY_NETWORK?.[network]
    : BASE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS;

  termsAndConditionsByNetwork.sort(
    (a: {weight: number}, b: {weight: number}) => a.weight - b.weight
  );

  return termsAndConditionsByNetwork.map(
    ({key, collapsible}: {key: string; collapsible: boolean}) => ({
      id: uuid(),
      title: translate(`${TRANSLATION_PREFIX}.${key}.title`),
      contentHtml: translate(`${TRANSLATION_PREFIX}.${key}.content`, {
        brandName,
      }),
      highlighted: '',
      clientBriefTemplateId: '',
      collapsible,
      testID: key,
    })
  );
};

export default getTermsAndConditionsSectionBriefItems;
