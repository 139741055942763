import React, {useEffect, useMemo, useState} from 'react';
import styles from './GradientProgressBar.module.scss';

export interface GradientProgressBarProps {
  testID?: string;
  value: number;
  initialValue?: number;
  fillColor?: string;
  backgroundColor?: string;
}

function GradientProgressBar(props: GradientProgressBarProps) {
  const {
    testID = 'gradient-progress-bar',
    value,
    initialValue = 0,
    fillColor,
    backgroundColor,
  } = props;
  const [valueProxy, setValueProxy] = useState(initialValue);

  useEffect(() => {
    // in order to initially animate the progress bar, we need to set the value in the next tick
    const timeout = setTimeout(() => setValueProxy(Math.min(100, value)));
    return () => clearTimeout(timeout);
  }, [value]);

  const fillerRelativePercentage =
    valueProxy > 0 ? (100 / valueProxy) * 100 : 0;

  const fillColorStyle = useMemo(() => {
    const style = {
      width: `${fillerRelativePercentage}%`,
    } as React.CSSProperties;

    if (fillColor) {
      style.background = fillColor;
    }
    return style;
  }, [fillColor, fillerRelativePercentage]);

  const backgroundStyle = useMemo(() => {
    if (!backgroundColor) {
      return {};
    }
    return {
      background: backgroundColor,
    };
  }, [backgroundColor]);

  return (
    <div data-testid={testID} className={styles.gradientProgressBarContainer}>
      <div className={styles.barContainer} style={backgroundStyle}>
        <div className={styles.filler} style={{width: `${valueProxy}%`}}>
          <div className={styles.fillerBackground} style={fillColorStyle} />
        </div>
      </div>
    </div>
  );
}

export default GradientProgressBar;
