import React from 'react';
import Link from '@/components/link';
import styles from './Breadcrumbs.module.scss';

type BreadcrumbProps = {
  label: string | React.ReactNode;
  link: string;
  isSeparator?: boolean | undefined;
};

export type BreadcrumbsProps = {
  testID?: string;
  breadcrumbs: BreadcrumbProps[];
};

function Breadcrumbs(props: BreadcrumbsProps) {
  const {testID = 'breadcrumbs', breadcrumbs} = props;

  const breadcrumbsParts = breadcrumbs
    .flatMap((breadcrumb, index) => [
      breadcrumb,
      index === breadcrumbs.length - 1
        ? undefined
        : {label: '|', link: '', isSeparator: true},
    ])
    .filter(Boolean) as BreadcrumbProps[];

  return (
    <div className={styles.container} data-testid={testID}>
      <ol className={styles.breadcrumbs}>
        {breadcrumbsParts.map((breadcrumb, index) => {
          const isLast = index === breadcrumbsParts.length - 1;
          const isFirst = index === 0;
          const {label, link, isSeparator} = breadcrumb;

          if (isSeparator)
            return (
              <li
                key={`${link}-${String(index)}-separator`}
                className={styles.breadcrumbItemSeparator}
                aria-current="page"
              >
                |
              </li>
            );
          return (
            <li
              key={`${link}-${String(index)}`}
              className={`${styles.breadcrumbItem} ${isLast ? 'active' : ''}`}
            >
              {(isLast || !link) && !isFirst ? (
                label
              ) : (
                <Link to={link} className={styles.breadcrumbItemLink}>
                  {label}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default Breadcrumbs;
