import React from 'react';
import {Button} from '@lightricks/react-design-system';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import styles from './StepContainer.module.scss';

type StepContainerProps = {
  testID?: string;
  children: React.ReactNode;
  withContinueButton?: boolean;
  onClickContinue?: () => void;
  continueButtonDisabled?: boolean;
  continueButtonLabel?: string;
  containerClassName?: string;
};
function StepContainer({
  testID = 'step-container',
  children,
  withContinueButton = true,
  onClickContinue,
  continueButtonDisabled,
  continueButtonLabel,
  containerClassName,
}: StepContainerProps) {
  return (
    <div
      className={`${styles.container} ${containerClassName}`}
      data-testid={testID}
    >
      {children}
      {withContinueButton ? (
        <div className={styles.nextButtonContainer}>
          <Button
            testID={`${BRIEF_WIZARD_TEST_IDS.STEP__CONTINUE_BUTTON}--${testID}`}
            className={styles.nextButton}
            appearance="neutral"
            mode="filled"
            size="large"
            onClick={onClickContinue}
            disabled={continueButtonDisabled}
          >
            {continueButtonLabel || 'Continue'}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default StepContainer;
