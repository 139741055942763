// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
function pageViewEvents() {
  window.uetq.push('event', 'page_view', {}); // Bing
  window.fbq('track', 'PageView'); // Facebook
  window.gtag('event', 'conversion', {
    send_to: 'AW-11244051408/6SCwCObQ2rYYENC3yvEp',
  }); // Google Ads
  // eslint-disable-next-line no-underscore-dangle
  window._tfa.push({notify: 'event', name: 'page_view', id: 1548556}); // Taboola
  window.twq('event', 'tw-oetaz-oetb6', {}); // Twitter
}

export default pageViewEvents;
