export const SHORT_NAME_TO_FULL_NAME_MAP = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
} as any;

export const FULL_NAME_TO_SHORT_NAME_MAP = Object.keys(
  SHORT_NAME_TO_FULL_NAME_MAP
).reduce((acc: any, shortNameKey: string) => {
  acc[SHORT_NAME_TO_FULL_NAME_MAP[shortNameKey]] = shortNameKey;
  return acc;
}, {});

export function convertShortStateNamesToFull(shortStateNames?: string[]) {
  if (!shortStateNames) {
    return [];
  }
  return shortStateNames.map(
    (shortStateName) => SHORT_NAME_TO_FULL_NAME_MAP[shortStateName]
  );
}

export function convertFullStateNamesToShort(fullStateNames?: string[]) {
  if (!fullStateNames) {
    return [];
  }
  return fullStateNames.map(
    (fullStateName) => FULL_NAME_TO_SHORT_NAME_MAP[fullStateName]
  );
}
