import brandFetchers from '@/api/fetchers/brand';
import useDataQuery from '@/hooks/use-data-query';

type UseBrandCampaignsQueryParams = {
  brandId?: string;
  query?: string;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  states?: string[];
};

interface SubmittedBy {
  email: string;
  displayName: string | null;
}

interface UnderReviewSubmissionInfo {
  submittedBy: SubmittedBy;
  submittedOn: string | null;
}

export type CampaignListItem = {
  contentAgreementTimePeriodInMonths: number;
  generatedByBriefAi: boolean;
  id: string;
  imageUrl: string;
  name: string;
  network: string;
  state: string;
  underReviewSubmissionInfo?: UnderReviewSubmissionInfo;
};

function useBrandCampaignsQuery({
  brandId,
  refetchOnWindowFocus = true,
  states,
}: UseBrandCampaignsQueryParams) {
  const response = useDataQuery({
    queryKey: ['brandCampaigns', brandId],
    queryFn: brandFetchers.brandCampaigns,
    enabled: !!brandId,
    meta: {
      brandId,
      state: states ? states?.join(',') : undefined,
    },
    refetchOnWindowFocus,
  });

  return {
    ...response,
    brandCampaigns: (response.data || []) as CampaignListItem[],
  };
}

export default useBrandCampaignsQuery;
