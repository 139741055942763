import {useCallback} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';

type ButtonPressedEventParams = {
  button_name: string;
  screen_name: string;
  flow_id?: string;
  flow_name?: string;
  triggered_flow_id?: string;
  triggered_flow_name?: string;
  screen_presentation_id?: string;
  campaign_id?: string;
  creator_id?: string;
  tab?: string;
};

function useTrackButtonEvent(flowName?: string) {
  const pressed = useCallback(
    ({
      button_name,
      screen_name,
      flow_id = '',
      flow_name = '',
      triggered_flow_id = '',
      triggered_flow_name = '',
      screen_presentation_id = '',
      campaign_id = '',
      creator_id = '',
      tab = '',
    }: ButtonPressedEventParams) => {
      const flow = flowName ? AnalyticsService.getFlow(flowName) : undefined;
      AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
        button_name,
        screen_name,
        flow_id: flow?.flow_id || flow_id,
        flow_name: flow?.flow_name || flow_name,
        triggered_flow_id,
        triggered_flow_name,
        screen_presentation_id:
          flow?.screen_presentation_id ||
          screen_presentation_id ||
          AnalyticsService.getActiveScreenPresented().screen_presentation_id,
        campaign_id,
        creator_id,
        tab,
      });
    },
    []
  );

  return {pressed};
}

export default useTrackButtonEvent;
