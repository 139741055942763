import React, {useState} from 'react';
import {Input, Button} from '@lightricks/react-design-system';
import queryClient from '@/utils/reactQueryClient';
import useUpdateDashboardUser from '@/hooks/mutations/use-update-dashboard-user';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useToken from '@/hooks/use-token';
import translate from '../../../utils/translate';
import styles from './Account.module.scss';

function Account() {
  const {isAuthenticated} = useToken(true);
  const dashboardUserMutation = useUpdateDashboardUser();
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  const [displayName, setDisplayName] = useState(
    dashboardUser?.displayName || ''
  );
  const [email, setEmail] = useState(dashboardUser?.email);
  const TRANSLATION_PREFIX = 'components.preferences.account';

  const updateDisplayName = async () => {
    await dashboardUserMutation.mutateAsync({
      id: dashboardUser.id,
      displayName,
    });

    queryClient.setQueryData(['dashboardUser'], {
      ...dashboardUser,
      displayName,
    });
  };

  const handleDisplayNameChange = (value: string) => {
    setDisplayName(value);
  };

  if (!dashboardUser?.loaded) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>{translate(`${TRANSLATION_PREFIX}.details`)}</h3>
        <p className={styles.description}>
          {translate(`${TRANSLATION_PREFIX}.description`)}
        </p>
      </div>

      <div className={styles.form}>
        <div className={styles.formItem}>
          <label>{translate(`${TRANSLATION_PREFIX}.display-name`)}</label>
          <Input
            type="text"
            value={displayName}
            onInputChange={handleDisplayNameChange}
            className={styles.displayNameInput}
            testID="display-name-input"
          />
          <p className={styles.helperText}>
            {translate(`${TRANSLATION_PREFIX}.display-name-helper-text`)}
          </p>
        </div>

        <div className={styles.formItem}>
          <label>{translate(`${TRANSLATION_PREFIX}.email`)}</label>
          <Input
            type="email"
            value={email}
            disabled
            className={styles.emailInput}
            clearIcon={false}
          />
        </div>
        <Button
          appearance="neutral"
          mode="filled"
          size="medium"
          onClick={updateDisplayName}
          className={styles.updateButton}
        >
          {translate(`${TRANSLATION_PREFIX}.update`)}
        </Button>
      </div>
    </div>
  );
}

export default Account;
