import React from 'react';
import {Body, Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './ChartLayout.module.scss';

const TRANSLATION_PREFIX = 'components.chart-layout';

interface ChartLayoutProps {
  title: string | React.ReactNode;
  chart: React.ReactNode;
  showEmptyState?: boolean;
}

function EmptyState() {
  return (
    <div className={styles.emptyState}>
      <Body size="lg">{translate(`${TRANSLATION_PREFIX}.empty-state`)}</Body>
    </div>
  );
}

function ChartLayout(props: ChartLayoutProps) {
  const {title, chart, showEmptyState} = props;

  return (
    <div className={styles.chartLayoutContainer}>
      <div className={styles.title}>
        {typeof title === 'string' ? (
          <Headline size="xxs">{title}</Headline>
        ) : (
          title
        )}
      </div>
      <div className={styles.chartContainer}>
        {showEmptyState ? <EmptyState /> : chart}
      </div>
    </div>
  );
}

export default ChartLayout;
