import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Campaigns from '@/views/campaigns';
import Brief from '@/views/campaigns/brief';
import BriefWizardView from '@/views/campaigns/brief/brief-ai-wizard-view';
import BriefBuilderView from '@/views/campaigns/brief/brief-builder-view';

export const NEW_CAMPAIGNS_ROUTES = {
  BASE: '/new-campaigns',
  MAIN: '',
  BRIEF: '',
  WIZARD: 'brief-ai/wizard',
  BRIEF_EDIT: ':campaignId/brief/edit',
};

function NewCampaignsRoutes() {
  return (
    <Routes>
      <Route path={NEW_CAMPAIGNS_ROUTES.MAIN} element={<Campaigns />}>
        <Route path={NEW_CAMPAIGNS_ROUTES.BRIEF} element={<Brief />}>
          <Route
            path={NEW_CAMPAIGNS_ROUTES.BRIEF_EDIT}
            element={<BriefBuilderView />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default NewCampaignsRoutes;
