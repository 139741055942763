import {useMutation} from '@tanstack/react-query';
import queryClient from '@/utils/reactQueryClient';
import emailPreferenceUpdaters from '@/api/updaters/emailPreferences';

function useUpdateEmailPreferences() {
  return useMutation({
    mutationKey: ['emailPreferences', 'update'],
    mutationFn: emailPreferenceUpdaters.bulkUpdate,
    onSuccess: () => {
      queryClient.invalidateQueries(['emailPreferences']);
    },
  });
}

export default useUpdateEmailPreferences;
