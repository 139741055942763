import React from 'react';
import {Body} from '@lightricks/react-design-system';
import numberFormat from '@/utils/numberFormat';
import styles from './FollowerChangeIndication.module.scss';

export interface FollowerChangeIndicationProps {
  followerChangeLabel: string;
  followerChange: number | null;
  followerChangePercentage: string | null;
}

function FollowerChangeIndication(props: FollowerChangeIndicationProps) {
  const {followerChangeLabel, followerChange, followerChangePercentage} = props;
  if (!followerChange) {
    return null;
  }

  const hasPositiveFollowerCountChange = (followerChange || 0) > 0;
  const absoluteFourWeekFollowerChange = Math.abs(followerChange);

  return (
    <div className={styles.followerChangeIndicationContainer}>
      <Body
        size="md"
        className={`${styles.number} ${
          hasPositiveFollowerCountChange ? styles.increase : styles.decrease
        }`}
      >
        <i
          className={`${styles.icon} ${
            hasPositiveFollowerCountChange
              ? styles.caretUpGreen
              : styles.caretDownRed
          }`}
        />
        {numberFormat(absoluteFourWeekFollowerChange)}
      </Body>
      <Body
        size="md"
        className={`${
          hasPositiveFollowerCountChange
            ? styles.percentageIncrease
            : styles.percentageDecrease
        }`}
      >
        ({hasPositiveFollowerCountChange ? '+' : ''}
        {followerChangePercentage}%)
      </Body>
      <Body size="md">{followerChangeLabel}</Body>
    </div>
  );
}

export default FollowerChangeIndication;
