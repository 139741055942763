const isMobile = (customMaxResolution = 768) => {
  const resolution = window.innerWidth;
  const isMobileSize = resolution >= 320 && resolution <= customMaxResolution;

  const mobile = Boolean(
    navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );

  return mobile || isMobileSize;
};

export default isMobile;
