import {useMutation} from '@tanstack/react-query';
import aiContentUpdaters from '@/api/updaters/aiContent';

function useCreateAiContent() {
  return useMutation({
    mutationKey: ['aiContent', 'createAiContent'],
    mutationFn: aiContentUpdaters.createAiContent,
  });
}

export default useCreateAiContent;
