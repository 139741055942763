import {camelCase} from 'lodash';
import PERMISSIONS from '@/config/permissions';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';

function findPathInNestedObject(
  obj: any,
  value: any,
  path: string[] = []
): string[] | undefined {
  return Object.keys(obj).reduce(
    (result: string[] | undefined, key: string) => {
      if (result !== undefined) {
        return result; // Early return if path is already found
      }

      const currentPath = [...path, key];

      if (obj[key] === value) {
        return currentPath; // Found the desired value
      }

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        return findPathInNestedObject(obj[key], value, currentPath); // Recursively search nested object
      }

      return undefined;
    },
    undefined
  );
}

type PermissionObject = {
  subjectClass: string;
  action: string;
};

type PermissionArray = PermissionObject[];

export type RequestedPermission = PermissionArray | PermissionObject;

function permissionComparator(
  dashboardUserPermissions: any[],
  requestedPermission: PermissionObject
) {
  return dashboardUserPermissions.find(
    (dashboardUserPermission: any) =>
      dashboardUserPermission.subjectClass ===
        requestedPermission.subjectClass &&
      dashboardUserPermission.action === requestedPermission.action
  );
}

function useDashboardUserPermissions(
  requestedPermissions: Array<RequestedPermission>,
  dashboardUserPermissions?: Array<PermissionObject>
) {
  const {data: dashboardUserData}: any = useDashboardUserQuery({
    enabled: !!dashboardUserPermissions,
  });
  if (!dashboardUserData && !dashboardUserPermissions) {
    return {};
  }

  const getDashboardUserPermissions = () => {
    if (dashboardUserPermissions) {
      return dashboardUserPermissions;
    }
    if (dashboardUserData) {
      return dashboardUserData.permissions;
    }
    return null;
  };

  const availablePermissions = getDashboardUserPermissions();

  if (!availablePermissions) {
    return {};
  }

  return requestedPermissions.reduce(
    (acc: any, permission: RequestedPermission) => {
      const permissionPath =
        findPathInNestedObject(PERMISSIONS, permission) || [];
      const permissionKey = camelCase(permissionPath.reverse().join('-'));
      if (!permissionKey) {
        return acc;
      }
      if (Array.isArray(permission)) {
        acc[permissionKey] = permission.every((permissionItem) =>
          permissionComparator(availablePermissions, permissionItem)
        );
      } else {
        acc[permissionKey] = !!permissionComparator(
          availablePermissions,
          permission
        );
      }
      return acc;
    },
    {} as any
  );
}

export default useDashboardUserPermissions;
