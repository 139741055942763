import {Metadata} from '@/types/models/search-creators/searchCreators';
import searchCreatorsFetchers from '@/api/fetchers/creators/search/searchCreators';
import useReactQuery from '@/hooks/use-react-query';

function useSearchCreatorsMetadataQuery() {
  const response = useReactQuery<Metadata>({
    queryKey: ['searchCreatorsMetadata'],
    queryFn: searchCreatorsFetchers.metadata,
  });

  const {data: metadata}: {data: Metadata | undefined} = response;
  return {
    data: metadata,
    isError: response.isError,
    isLoading: response.isLoading,
    isFetched: response.isFetched,
    refetch: response.refetch,
  };
}

export default useSearchCreatorsMetadataQuery;
