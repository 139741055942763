import React, {useEffect} from 'react';
import {Body, Button, Headline, Icon} from '@lightricks/react-design-system';
import {
  buttonNames,
  screenNames,
  dismissedReasons,
  reasons,
} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import {
  handleScreenDismissedEvent,
  handleScreenPresentedEvent,
  handleSubscriptionButtonPressedEvent,
} from '@/components/modal-subscription/AnalyticsEventsHandler';
import useModalSubscriptionStore, {
  modalSubscriptionActions,
} from '@/stores/modalSubscriptionStore';
import styles from './Result.module.scss';

const TRANSLATION_PREFIX = 'components.modal-subscription.content-right.result';

function Result() {
  const {result} = useModalSubscriptionStore();

  useEffect(() => {
    handleScreenPresentedEvent({
      screenName: screenNames.SUBSCRIPTION_MODAL.ALL_SET,
    });
  }, []);

  if (!result) return null;

  const handleClick = () => {
    handleSubscriptionButtonPressedEvent({
      screenName: screenNames.SUBSCRIPTION_MODAL.ALL_SET,
      buttonName: buttonNames.SUBSCRIPTION_MODAL.DONE,
    });
    if (result.state === 'success') {
      handleScreenDismissedEvent({
        screenName: screenNames.SUBSCRIPTION_MODAL.ALL_SET,
        dismissedReason: dismissedReasons.SUBSCRIPTION_MODAL.CONTINUE,
      });
      modalSubscriptionActions.close({
        isFlowEnded: true,
        reason: reasons.SUBSCRIPTION_MODAL.SUCCESS,
        currentScreenName: screenNames.SUBSCRIPTION_MODAL.ALL_SET,
      });
    } else if (result.state === 'failure') {
      modalSubscriptionActions.tryAgain();
    }
  };

  const iconAppearance = result.state === 'success' ? 'brand' : 'danger';
  const iconName = result.state === 'success' ? 'Accept' : 'Cancel';
  const buttonLabel =
    result.state === 'success'
      ? translate(`${TRANSLATION_PREFIX}.submit-label.success`)
      : translate(`${TRANSLATION_PREFIX}.submit-label.failure`);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Icon
          name={`Actions-Patch-${iconName}-Line`}
          size="large"
          appearance={iconAppearance}
        />
        <div className={styles.text}>
          <Headline size="lg">{result.headline}</Headline>
          <Body size="lg" className={styles.bodyText}>
            {result.body}
          </Body>
        </div>
      </div>
      <div className={styles.fixedButton}>
        <Button
          appearance="brand"
          mode="filled"
          size="medium"
          onClick={handleClick}
          className={styles.button}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

export default Result;
