import React from 'react';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import truncateText from '@/utils/truncateText';
import styles from './TotalResults.module.scss';

const TRANSLATION_PREFIX = 'components.total-results';
const MAX_SEARCH_TERM_LENGTH = 20;

function TotalResults({
  amount,
  searchTerm,
}: {
  amount: number;
  searchTerm?: string;
}) {
  if (!amount) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Body
        size="lg"
        color={designSystemToken('semantic.fg.inverse-secondary')}
      >
        {translate(`${TRANSLATION_PREFIX}.results`, {
          amount: amount.toLocaleString(),
        })}
      </Body>
      {searchTerm ? (
        <span className={styles.for}>
          <Body
            size="lg"
            color={designSystemToken('semantic.fg.inverse-secondary')}
          >
            {translate(`${TRANSLATION_PREFIX}.for`)}
          </Body>
          <i>
            <Body
              size="lg"
              color={designSystemToken('semantic.fg.inverse-secondary')}
            >
              &nbsp;&quot;
            </Body>
          </i>
          <Body size="lg" color={designSystemToken('semantic.fg.secondary')}>
            {truncateText(searchTerm, MAX_SEARCH_TERM_LENGTH)}
          </Body>
          <i>
            <Body
              size="lg"
              color={designSystemToken('semantic.fg.inverse-secondary')}
            >
              &quot;
            </Body>
          </i>
        </span>
      ) : null}
    </div>
  );
}

export default TotalResults;
