import {useMemo} from 'react';
import mapIdentityPost from '@/utils/mapIdentityPost';
import {Post} from '@/types/post';
import identityFetcher from '@/api/fetchers/identity';
import useDataQuery from '@/hooks/use-data-query';

function useIdentityPostsQuery({
  identityId,
  enabled = true,
  queryParams = {},
}: {
  identityId?: string;
  enabled?: boolean;
  queryParams?: {[key: string]: string | number | boolean};
}) {
  const response = useDataQuery<Post[]>({
    queryKey: ['identityPosts', identityId, queryParams],
    queryFn: identityFetcher.posts,
    enabled: enabled && !!identityId,
    refetchOnWindowFocus: false,
    meta: {
      identityId,
      ...queryParams,
    },
  });

  const posts = useMemo(() => {
    if (!response.data) {
      return [];
    }
    return response.data.map(mapIdentityPost);
  }, [response.data]);

  return {...response, data: posts};
}

export default useIdentityPostsQuery;
