import React from 'react';
import {Button, Label} from '@lightricks/react-design-system';
import styles from './CreatorCardCta.module.scss';

function CreatorCardCta({
  buttonText,
  onClick,
  isDisabled = false,
}: {
  buttonText: string;
  onClick?: (event: React.MouseEvent) => void;
  isDisabled?: boolean | undefined;
}) {
  return (
    <Button
      className={`${styles.ctaButton} ${isDisabled ? styles.disabled : ''}`}
      appearance="neutral"
      mode="tinted"
      size="medium"
      onClick={onClick}
      disabled={isDisabled}
    >
      <Label size="md">{buttonText}</Label>
    </Button>
  );
}

export default CreatorCardCta;
