import {Chart, registerables} from 'chart.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './src/config/init';
import App from './App';
import './src/config/analyticsInit';
import './src/config/fortressInit';
import './src/config/pendoInit';
import './src/config/pixelsInit';
import './src/config/sentryInit';

Chart.register(...registerables);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
