import React from 'react';
import {Body} from '@lightricks/react-design-system';
import styles from './LocationsList.module.scss';

interface Location {
  state?: string;
  country?: string;
  city?: string;
  percentage: number;
}

interface LocationsListProps {
  locations: Location[];
  accessorKey: 'state' | 'country' | 'city';
  getLocationName?: (location: string) => string;
}

function LocationsList({
  locations,
  accessorKey,
  getLocationName,
}: LocationsListProps) {
  return (
    <div className={styles.locationsListContainer}>
      {locations.map((location) => {
        const locationString =
          getLocationName?.(location[accessorKey] as string) ||
          location[accessorKey];
        return (
          <div key={locationString} className={styles.row}>
            <Body size="md" className={styles.title}>
              {locationString}
            </Body>
            <Body size="md" className={styles.value}>
              {location.percentage}%
            </Body>
          </div>
        );
      })}
    </div>
  );
}

export default LocationsList;
