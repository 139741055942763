import React from 'react';
import {designSystemToken, Icon, Label} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import styles from './PreviewOverlay.module.scss';

interface PreviewOverlayProps {
  className?: string;
  iconName: IconName;
  iconSize?: 'small' | 'medium' | 'large';
  label: string;
  labelSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
}

function PreviewOverlay(props: PreviewOverlayProps) {
  const {
    className,
    iconName,
    label,
    iconSize = 'medium',
    labelSize = 'sm',
  } = props;
  return (
    <div className={`${styles.previewOverlayContainer} ${className}`}>
      <Icon
        size={iconSize}
        appearance="neutral"
        name={iconName}
        color={designSystemToken('semantic.fg.secondary')}
      />
      <Label
        size={labelSize}
        color={designSystemToken('semantic.fg.secondary')}
        className={styles.previewOverlayText}
      >
        {label}
      </Label>
    </div>
  );
}

export default PreviewOverlay;
