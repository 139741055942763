import React, {useRef, useState, useEffect, useLayoutEffect} from 'react';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import styles from './CollapsibleContainer.module.scss';

const MEASURE_ON_CLICK_TIMEOUT_MS = 100;

export type CollapsibleContainerProps = {
  testID?: string;
  controlledIsOpen?: boolean;
  alwaysOpen?: boolean;
  className?: string;
  style?: object;
  headerClassName?: string;
  headerStyle?: object;
  headerLabel?: string;
  renderHeaderLabel?: (isOpen: boolean) => React.ReactNode;
  headerLabelStyle?: object;
  headerLabelContainerClassName?: string;
  headerActionComponent?: React.ReactNode;
  children: React.ReactNode;
  stickyHeader?: boolean;
  stickyHeaderOffset?: number;
  withAnimation?: boolean;
  initialIsOpen?: boolean;
  toggleCollapsibleRef?: React.MutableRefObject<any>;
  customOpenIndicator?: React.ReactNode;
  customClosedIndicator?: React.ReactNode;
  childrenRef?: React.MutableRefObject<any>;
  measureHeightOnClick?: boolean;
};

function CollapsibleContainer(props: CollapsibleContainerProps) {
  const {
    testID = 'collapsible-container',
    controlledIsOpen,
    alwaysOpen,
    children,
    className,
    style,
    headerClassName,
    headerStyle = {},
    headerLabel,
    renderHeaderLabel,
    headerLabelStyle,
    headerLabelContainerClassName,
    headerActionComponent,
    stickyHeader,
    stickyHeaderOffset,
    withAnimation = true,
    initialIsOpen = false,
    toggleCollapsibleRef,
    customOpenIndicator,
    customClosedIndicator,
    childrenRef,
    measureHeightOnClick = false,
  } = props;
  const [isOpen, setIsOpen] = useState(!!alwaysOpen);
  const [height, setHeight] = useState(0);
  const [isAnimated, setIsAnimated] = useState(withAnimation);
  const contentRef = useRef<HTMLInputElement>(null);

  const measureRef = childrenRef || contentRef;

  const measureHeight = () => {
    setHeight(isOpen ? measureRef?.current?.scrollHeight || 0 : 0);
    if (!isOpen && !isAnimated && withAnimation) {
      setIsAnimated(withAnimation);
    }
  };

  useLayoutEffect(() => {
    if (toggleCollapsibleRef?.current) {
      toggleCollapsibleRef.current = {
        toggleIsOpen,
      };
    }

    if (initialIsOpen) {
      setIsOpen(true);
      setIsAnimated(false);
    }
  }, []);

  useEffect(() => {
    if (alwaysOpen) {
      return;
    }
    if (typeof controlledIsOpen === 'boolean') {
      setIsOpen(controlledIsOpen);
    }
  }, [controlledIsOpen]);

  useLayoutEffect(() => {
    measureHeight();
  }, [isOpen, children]);

  useEffect(() => {
    let measureListener: any;
    const measure = () => {
      setTimeout(() => {
        setHeight(measureRef?.current?.scrollHeight || 0);
      }, MEASURE_ON_CLICK_TIMEOUT_MS);
    };

    if (measureHeightOnClick) {
      measureListener = measureRef?.current.addEventListener('click', measure);
    }
    return () => {
      if (measureListener) {
        measureRef?.current.removeEventListener('click', measure);
      }
    };
  }, []);

  const toggleIsOpen = (forceIsOpen?: boolean | undefined) => {
    if (alwaysOpen) {
      return;
    }

    if (typeof forceIsOpen === 'boolean') {
      setIsAnimated(false);
      setIsOpen(forceIsOpen);
      return;
    }
    setIsOpen(!isOpen);
  };

  const getHeaderStyle = () => {
    const headerStyleObject: React.CSSProperties = {...headerStyle};
    if (stickyHeader) {
      headerStyleObject.position = 'sticky';
      headerStyleObject.top = stickyHeaderOffset || 0;
      headerStyleObject.backgroundColor = designSystemToken(
        'semantic.bg.primary'
      );
      headerStyleObject.paddingTop = 5;
      headerStyleObject.paddingBottom = 5;
    }

    return headerStyleObject;
  };

  const getHeaderOpenIndicator = (state: 'open' | 'closed') => {
    switch (state) {
      case 'open':
        if (customOpenIndicator) {
          return customOpenIndicator;
        }
        return (
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Arrow-Down-Large"
          />
        );
      case 'closed':
        if (customClosedIndicator) {
          return customClosedIndicator;
        }
        return (
          <Icon
            size="large"
            appearance="neutral"
            name="Actions-Arrow-Forward-Small"
          />
        );
      default:
        return null;
    }
  };

  const getHeaderLabel = () => {
    if (renderHeaderLabel) {
      return renderHeaderLabel(isOpen);
    }
    if (headerLabel) {
      return (
        <label
          className={alwaysOpen ? '' : styles.label}
          style={headerLabelStyle}
        >
          {headerLabel}
        </label>
      );
    }
    return null;
  };

  return (
    <div
      className={`${styles.container} ${className} ${
        isOpen ? 'open' : 'closed'
      }`}
      data-testid={testID}
      style={style}
    >
      <div
        className={`${styles.header} ${headerClassName} ${
          isOpen ? 'open' : 'closed'
        }`}
        style={getHeaderStyle()}
      >
        <div
          role="button"
          tabIndex={0}
          onClick={() => toggleIsOpen()}
          className={`${
            alwaysOpen ? '' : styles.labelContainer
          } ${headerLabelContainerClassName}`}
          data-testid={`${testID}-toggle`}
        >
          {getHeaderLabel()}
          {alwaysOpen ? null : (
            <span className={styles.caret}>
              {isOpen
                ? getHeaderOpenIndicator('open')
                : getHeaderOpenIndicator('closed')}
            </span>
          )}
        </div>
        {headerActionComponent}
      </div>
      <div
        className={`${styles.contentParent} ${
          alwaysOpen || isOpen ? 'open' : 'closed'
        }`}
        ref={childrenRef ? null : measureRef}
        style={{
          height: alwaysOpen ? '100%' : height,
          transition: isAnimated ? 'height ease 0.6s' : 'none',
        }}
        data-testid={`${testID}-content-parent`}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

export default CollapsibleContainer;
