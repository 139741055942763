/// <reference types="aws-sdk" />
import Sentry from '@/services/sentry/Sentry';
import urlToFile from '@/utils/urlToFile';
import {getEnv} from '@/config/environment';
import api from '@/api';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const {AWS} = window;

const CONFIG_REGION = 'us-east-1';
const COGNITO_REFRESH_INTERVAL = 50 * 60 * 1000; // 50 minutes; expiration is 60 minutes
const COGNITO_URL = '/dashboard_user/cognito_identity';

type LocalFile = {
  name: string;
  size: number;
  type: string;
  blob?: Blob;
};

type Settings = {
  cognitoRefreshInterval: number;
  cognitoURL: string;
  maxRetries: number;
  poolId: string;
};

interface S3Setting extends Settings {
  buckets: {
    [key: string]: string | undefined;
  };
}

class AwsService {
  AWS_MAX_RETRIES: number | null = null;

  COGNITO_REFRESH_INTERVAL: number | null = null;

  COGNITO_URL: string | null = null;

  POOL_ID: string | null = null;

  loaded = 0;

  total = 0;

  request: AWS.S3.ManagedUpload | null = null;

  cognitoRefreshHandle: NodeJS.Timeout | null = null;

  constructor() {
    this.loadAwsConfig(AwsService.s3Settings);
  }

  static get s3Settings(): S3Setting {
    return {
      buckets: {
        campaignBrief: getEnv().VITE_S3_BUCKET_CONTENT_SUBMISSIONS,
        contentSubmission: getEnv().VITE_S3_BUCKET_CONTENT_SUBMISSIONS,
        messageAttachments: getEnv().VITE_S3_BUCKET_MESSAGE_ATTACHMENTS,
      },
      cognitoRefreshInterval: COGNITO_REFRESH_INTERVAL,
      cognitoURL: COGNITO_URL,
      poolId: getEnv().VITE_COGNITO_POOL_ID,
      maxRetries: 8,
    };
  }

  get percent(): number {
    if (this.total === 0) {
      return 0;
    }
    return Math.round((this.loaded / this.total) * 100);
  }

  private disableRefreshedCognito() {
    if (this.cognitoRefreshHandle) {
      clearInterval(this.cognitoRefreshHandle);
    }
  }

  private maintainRefreshedCognito() {
    this.cognitoRefreshHandle = setInterval(
      () => AwsService.refresh(),
      this.COGNITO_REFRESH_INTERVAL as number
    );
  }

  private uploadToAWS(
    bucketName: string,
    fileParams: AWS.S3.PutObjectRequest
  ): Promise<AWS.S3.ManagedUpload.SendData> {
    const bucket = new AWS.S3({
      params: {Bucket: bucketName},
      maxRetries: this.AWS_MAX_RETRIES as number,
    });

    return new Promise((resolve, reject) => {
      const request = bucket.upload(fileParams);

      request.on('httpUploadProgress', (event) => {
        this.total = event.total;
        this.loaded = event.loaded;
      });

      request.send((err, s3File) => {
        this.request = null;
        if (err) {
          reject(err);
        } else {
          resolve(s3File);
        }
      });

      this.request = request;
    });
  }

  abort() {
    if (this.request) {
      this.request.abort();
    }
  }

  loadAwsConfig(settings: Settings) {
    const {cognitoRefreshInterval, cognitoURL, maxRetries, poolId} = settings;

    Object.assign(this, {
      AWS_MAX_RETRIES: maxRetries,
      COGNITO_REFRESH_INTERVAL: cognitoRefreshInterval,
      COGNITO_URL: cognitoURL,
      POOL_ID: poolId,
    });
  }

  static refresh() {
    if (AWS.config.credentials) {
      (AWS.config.credentials as AWS.CognitoIdentityCredentials)?.refresh?.(
        (error: any) => {
          if (error) {
            Sentry.captureMessage('AWS token refresh failure', error);
          }
        }
      );
    } else {
      const error = new Error('Cannot refresh non-existent token');
      Sentry.captureMessage('AWS token refresh failure', error);
    }
  }

  async setCognitoIdentity(): Promise<AWS.CognitoIdentityCredentials> {
    const response: any = await api.get(`${this.COGNITO_URL}`);
    const {id, token} = response.data;
    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.POOL_ID as string,
      IdentityId: id,
      Logins: {
        'cognito-identity.amazonaws.com': token,
      },
    });

    AWS.config.credentials = credentials;
    AWS.config.region = CONFIG_REGION;

    return credentials;
  }

  async uploadToBucket(bucketName: string, localFile: LocalFile) {
    this.maintainRefreshedCognito();
    this.loaded = 0;
    this.total = 0;

    try {
      const credentials = await this.setCognitoIdentity();

      const fileName = `${credentials.identityId}/${encodeURIComponent(
        localFile.name
      )}`;
      const fileParams: AWS.S3.PutObjectRequest = {
        Bucket: bucketName,
        Key: fileName,
        ContentType: localFile.type,
        Body: localFile.blob ? localFile.blob : localFile,
      };

      const s3File = await this.uploadToAWS(bucketName, fileParams);
      return s3File.Location;
    } catch (error: any) {
      Sentry.captureException(error, {message: 'S3 upload failure'});
      throw error;
    } finally {
      this.disableRefreshedCognito();
    }
  }

  async uploadToBucketFromUrl(bucketName: string, url: string) {
    try {
      const fileName = url.split('?')[0].split('/').pop() || '';
      const file = await urlToFile(url, fileName);

      return await this.uploadToBucket(bucketName, file);
    } catch (error: any) {
      Sentry.captureException(error, {message: 'S3 upload from URL failure'});
      throw error;
    }
  }
}

export default AwsService;
