import {IdentityPostEmbedding} from '@/types/identityPostEmbedding';
import vettingFetchers from '@/api/fetchers/vetting';
import useDataQuery from '@/hooks/use-data-query';

function useIdentityPostEmbeddingQuery({
  provider,
  url,
  enabled = true,
}: {
  provider: string;
  url: string;
  enabled?: boolean;
}) {
  return useDataQuery<IdentityPostEmbedding>({
    queryKey: ['socialNetworkPostEmbedding', provider, url],
    queryFn: vettingFetchers.embedding,
    enabled: enabled && !!provider && !!url,
    meta: {provider, url},
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

export default useIdentityPostEmbeddingQuery;
