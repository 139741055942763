import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {ROUTES} from '@/config/routesConstants';
import usePrevious from '@/hooks/use-previous';

const IGNORE_ROUTES_EVENTS = [ROUTES.SIGN_UP_REACT, ROUTES.SIGN_UP_REACT_OLD];

function useDeltaEventScreenChanged() {
  const location = useLocation();
  const previousLocation: any = usePrevious(location);

  useEffect(() => {
    if (IGNORE_ROUTES_EVENTS.includes(location.pathname)) {
      return;
    }
    (async () => {
      if (previousLocation?.pathname) {
        await AnalyticsService.screenDismissed(
          `${previousLocation.pathname}${previousLocation.search}`,
          `${location.pathname}${location.search}`
        );
      }
      await AnalyticsService.screenPresented(
        `${location.pathname}${location.search}`,
        `${window.location.pathname}${window.location.search}`
      );
    })();
  }, [location.pathname]);

  return null;
}

export default useDeltaEventScreenChanged;
