import dayjs from 'dayjs';
import translate from '@/utils/translate';
import {DefinedPreset} from './types';
import {isSameRange} from './utils';

const getDefaultPresets = (
  date: Date,
  minDate?: Date,
  maxDate?: Date
): DefinedPreset[] => {
  const presets = [
    {
      id: 'today',
      label: translate('Today'),
      startDate: dayjs(date).toDate(),
      endDate: dayjs(date).toDate(),
    },
    {
      id: 'yesterday',
      label: translate('Yesterday'),
      startDate: dayjs(date).subtract(1, 'day').toDate(),
      endDate: dayjs(date).subtract(1, 'day').toDate(),
    },
    {
      id: 'this-week',
      label: translate('This Week'),
      startDate: dayjs(date).startOf('week').toDate(),
      endDate: dayjs(date).endOf('week').toDate(),
    },
    {
      id: 'last-week',
      label: translate('Last Week'),
      startDate: dayjs(date).subtract(1, 'week').startOf('week').toDate(),
      endDate: dayjs(date).subtract(1, 'week').endOf('week').toDate(),
    },
    {
      id: 'last-7-days',
      label: translate('Last 7 Days'),
      startDate: dayjs(date).subtract(1, 'week').toDate(),
      endDate: dayjs(date).toDate(),
    },
    {
      id: 'this-month',
      label: translate('This Month'),
      startDate: dayjs(date).startOf('month').toDate(),
      endDate: dayjs(date).endOf('month').toDate(),
    },
    {
      id: 'last-month',
      label: translate('Last Month'),
      startDate: dayjs(date).subtract(1, 'month').startOf('month').toDate(),
      endDate: dayjs(date).subtract(1, 'month').endOf('month').toDate(),
    },
    {
      id: 'this-year',
      label: translate('This Year'),
      startDate: dayjs(date).startOf('year').toDate(),
      endDate: dayjs(date).endOf('year').toDate(),
    },
    {
      id: 'last-year',
      label: translate('Last Year'),
      startDate: dayjs(date).subtract(1, 'year').startOf('year').toDate(),
      endDate: dayjs(date).subtract(1, 'year').endOf('year').toDate(),
    },
  ];

  return presets.filter((preset) => {
    if (minDate && maxDate) {
      if (isSameRange(preset, {startDate: minDate, endDate: maxDate})) {
        return true;
      }
      return (
        dayjs(preset.startDate).isAfter(minDate) &&
        dayjs(preset.endDate).isBefore(maxDate)
      );
    }
    if (minDate) {
      return dayjs(preset.startDate).isAfter(minDate);
    }
    if (maxDate) {
      return dayjs(preset.endDate).isBefore(maxDate);
    }
    return true;
  });
};

export default getDefaultPresets;
