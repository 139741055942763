import React from 'react';
import styles from './InfiniteLoader.module.scss';

const loadingIcon = '/assets/svg/icon-loading-circle-black.svg';

export interface InfiniteLoaderProps {
  containerClassName?: string;
  isLoading: boolean;
}

function InfiniteLoader(props: InfiniteLoaderProps) {
  const {containerClassName = '', isLoading} = props;

  if (!isLoading) {
    return null;
  }

  return (
    <div className={`${styles.infiniteLoaderContainer} ${containerClassName}`}>
      <div className={styles.loadingIcon}>
        <img src={loadingIcon} alt="loading circle icon" />
      </div>
    </div>
  );
}

export default InfiniteLoader;
