import React from 'react';
import {Divider, Menu, MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import PoppaysLoader from '@/components/PoppaysLoader';
import {CreatorCardProps} from '@/components/creator-card/CreatorCard';
import {CreatorMenuItemProps} from '@/components/creator-card/CreatorOverflowMenuButton';
import useCreatorCardOverflowMenuItems from '@/hooks/queries/use-creator-card-overflow-menu-items-query';

interface CreatorOverflowMenuWrapperProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  menuOpen: boolean;
  creator: CreatorCardProps;
  creatorCardOverflowMenuItemsCallBack: (
    creator: CreatorCardProps
  ) => Promise<CreatorMenuItemProps[]>;
}

function CreatorOverflowMenuWrapper({
  anchorEl,
  setAnchorEl,
  menuOpen,
  creator,
  creatorCardOverflowMenuItemsCallBack,
}: CreatorOverflowMenuWrapperProps): JSX.Element {
  const {
    data: menuItems,
    isLoading,
    isError,
  } = useCreatorCardOverflowMenuItems({
    creator,
    creatorCardOverflowMenuItemsCallBack,
  });

  return (
    <Menu
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
          minWidth: '343px',
          paddingInline: '8px',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={menuOpen}
      onClose={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
      }}
      anchorEl={anchorEl}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '16px',
            height: '70px',
          }}
        >
          <PoppaysLoader absolute />
        </div>
      )}

      {isError && (
        <div style={{padding: '16px'}}>
          <Typography color="error">
            {translate('components.creator-card.overflow-menu.error-message')}
          </Typography>
        </div>
      )}

      {!isLoading &&
        !isError &&
        menuItems.map((item, index) => (
          <div key={item.title}>
            <CreatorMenuItem
              menuItem={{
                ...item,
                onClick: () => {
                  item.onClick();
                  setAnchorEl(null);
                },
              }}
            />
            {index === menuItems.length - 1 ? null : <Divider />}
          </div>
        ))}
    </Menu>
  );
}

function CreatorMenuItem({menuItem}: {menuItem: CreatorMenuItemProps}) {
  return (
    <MenuItem
      sx={{
        'display': 'flex',
        'gap': '12px',
        'padding': '16px 12px 16px 12px',
        '&:hover': {
          background: designSystemToken('semantic.bg.tertiary'),
          borderRadius: '16px',
        },
      }}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        menuItem.onClick();
      }}
    >
      <Icon appearance="neutral" size="medium" name={menuItem.iconName} />
      {menuItem.title}
    </MenuItem>
  );
}

export default CreatorOverflowMenuWrapper;
