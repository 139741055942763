import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import styles from './ArrowsNavigation.module.scss';

interface ArrowsNavigationProps {
  testID?: string;
  onPrevious?: () => void;
  onNext?: () => void;
}

function ArrowsNavigation(props: ArrowsNavigationProps) {
  const {testID = 'arrows-navigation', onPrevious, onNext} = props;
  return (
    <div className={styles.navigationControls}>
      <Button
        testID={`${testID}--back-button`}
        className={onPrevious ? '' : styles.hidden}
        onClick={onPrevious}
        appearance="overlay"
        mode="filled"
        size="medium"
        icon={
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Navigation-Back-Arrow"
          />
        }
      />
      <Button
        testID={`${testID}--forward-button`}
        className={onNext ? '' : styles.hidden}
        onClick={onNext}
        appearance="overlay"
        mode="filled"
        size="medium"
        icon={
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Navigation-Forward-Arrow"
          />
        }
      />
    </div>
  );
}

export default ArrowsNavigation;
