import React from 'react';
import {Headline} from '@lightricks/react-design-system';
import {HeadlineProps} from '@lightricks/react-design-system/dist/components/headline/Headline';
import translate from '@/utils/translate';
import SearchInput from '@/components/search-input';
import styles from './SearchTopBar.module.scss';

const TRANSLATION_PREFIX = 'views.creators';

type TopBarProps = {
  searchQuery?: string;
  onSubmitSearchInput: (value: string) => void;
  titleProps: HeadlineProps;
};

function SearchTopBar({
  searchQuery,
  onSubmitSearchInput,
  titleProps,
}: TopBarProps) {
  return (
    <div className={styles.topBarContainer}>
      <div className={styles.topBarLeft}>
        <Headline {...titleProps}>
          {translate(`${TRANSLATION_PREFIX}.title`)}
        </Headline>
      </div>
      <SearchInput
        value={searchQuery || ''}
        onSubmit={onSubmitSearchInput}
        className={styles.searchInput}
      />
    </div>
  );
}

export default SearchTopBar;
