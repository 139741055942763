function injectArrayDelimiter(array: any[], delimiter: any): any[] {
  if (!Array.isArray(array) || array.length === 0) {
    return [];
  }

  const [firstElement, ...rest] = array;

  return rest.reduce(
    (acc, curr, index) =>
      acc.concat(
        typeof delimiter === 'function' ? delimiter(index) : delimiter,
        curr
      ),
    [firstElement]
  );
}

export default injectArrayDelimiter;
