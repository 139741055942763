import React from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Radio, RadioProps} from '@mui/material';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import DropdownButton from '@/components/dropdown-button';
import {Option} from '@/components/dropdown-button/DropdownButton';
import SocialNetworkIcon from '@/components/social-network-icon';
import useVettingHubStore, {vettingHubActions} from '@/stores/vettingHubStore';
import styles from './NetworkDropdown.module.scss';

const NETWORK_OPTIONS: Option<NetworkOption>[] = [
  {id: 'instagram', label: 'Instagram'},
  {id: 'tiktok', label: 'TikTok'},
];

type NetworkOption = 'tiktok' | 'instagram';

type NetworkDropdownProps = {
  options?: Option<NetworkOption>[];
};

function NetworkDropdown(props: NetworkDropdownProps) {
  const {options = NETWORK_OPTIONS} = props;
  const {setSearchNetwork} = vettingHubActions;
  const searchNetwork = useVettingHubStore(
    useShallow((state) => state.searchNetwork)
  );

  const onChange = (val: Option<NetworkOption>) => {
    setSearchNetwork(val.id);
  };

  return (
    <DropdownButton<NetworkOption>
      label={<SocialNetworkIcon width={28} height={28} name={searchNetwork} />}
      menuWidth={300}
      menuProps={{classes: {paper: styles.menu, list: styles.list}}}
      menuItemProps={{classes: {root: styles.option}}}
      options={options}
      onChange={onChange}
      renderMenuItem={(option) => (
        <NetworkDropdownOption
          option={option}
          isSelected={searchNetwork === option.id}
        />
      )}
      buttonClassName={styles.button}
    />
  );
}

type NetworkDropdownOptionProps = {
  option: Option<NetworkOption>;
  isSelected: boolean;
};

function NetworkDropdownOption(props: NetworkDropdownOptionProps) {
  const {option, isSelected} = props;

  return (
    <>
      <SocialNetworkIcon name={option.id} width={32} height={32} />
      <Body size="lg" className={styles.optionLabel}>
        {option.label}
      </Body>
      <Radio checked={isSelected} sx={{p: 0}} />
    </>
  );
}

export default NetworkDropdown;
