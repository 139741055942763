import {useMemo} from 'react';
import mapCreatorProfileResponse from '@/utils/mapCreatorProfileResponse';
import {ProfileProps} from '@/types/creatorProfile';
import creatorProfileFetcher, {
  CreatorProfileResponse,
} from '@/api/fetchers/creatorProfile';
import useDataQuery from '@/hooks/use-data-query';

interface UseCreatorProfileQueryParams {
  profileId: string | null;
  brandId?: string;
  include?: string[];
  enabled?: boolean;
  isPublic?: boolean;
  queryParams?: {[key: string]: string | number | boolean};
}

function useSharedListCreatorProfileQuery({
  profileId,
  brandId,
  include,
  enabled = true,
  queryParams = {},
}: UseCreatorProfileQueryParams) {
  const queryKey = [
    'sharedListCreatorProfile',
    profileId,
    brandId,
    queryParams,
  ];
  if (include) {
    queryKey.push(include.join(','));
  }

  const response = useDataQuery<CreatorProfileResponse>({
    queryKey,
    queryFn: creatorProfileFetcher.sharedListCreator,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
    meta: {
      id: profileId,
      include,
      ...queryParams,
    },
  });

  const profile: ProfileProps | null = useMemo(() => {
    if (!response.data) {
      return null;
    }
    return mapCreatorProfileResponse(response.data as ProfileProps);
  }, [response.data]);

  return {
    ...response,
    profile,
  };
}

export default useSharedListCreatorProfileQuery;
