import {capitalize} from 'lodash';
import {useCallback, useMemo} from 'react';
import campaignFetchers from '@/api/fetchers/campaign';
import {CAMPAIGN_STATE} from '@/views/campaigns/brief/constants';
import useDataQuery from '@/hooks/use-data-query';

type UseCampaignQueryProps = {
  campaignId?: string;
  include?: string[];
  enabled?: boolean;
  retry?: boolean;
  refetchOnWindowFocus?: boolean;
  staleTime?: number;
  onError?: (error: any) => void;
};

function useCampaignQuery({
  campaignId,
  include,
  enabled = true,
  retry = true,
  refetchOnWindowFocus = false,
  staleTime = 0,
  onError,
}: UseCampaignQueryProps) {
  const queryKey = include
    ? ['campaign', campaignId, ...include]
    : ['campaign', campaignId];
  const response: any = useDataQuery({
    queryKey,
    queryFn: campaignFetchers.campaign,
    enabled: !!campaignId && enabled,
    retry,
    onError,
    staleTime,
    refetchOnWindowFocus,
    meta: {
      id: campaignId,
      include,
    },
  });

  const campaign = useCallback(() => {
    return response.data || {};
  }, [response.data]);

  const showCampaignDetails = () => {
    const {state} = campaign();
    const blacklist = [
      CAMPAIGN_STATE.UNDER_CONSTRUCTION,
      CAMPAIGN_STATE.DRAFT,
      CAMPAIGN_STATE.UNDER_REVIEW,
    ];

    if (!state) {
      return false;
    }

    return !blacklist.includes(state);
  };

  const networkDasherized = () => {
    const campaignNetwork = campaign().network;
    if (campaignNetwork) {
      return campaignNetwork.replace('_', '-');
    }

    return campaignNetwork;
  };

  const isBcaCompatible = () => {
    const campaignNetwork = campaign().network;

    if (!campaignNetwork) {
      return true;
    }
    return campaignNetwork === 'instagram' || campaignNetwork === 'facebook';
  };

  const campaignNetworkPrettyName = () => {
    const campaignNetwork = campaign().network;

    switch (campaignNetwork) {
      case 'youtube':
        return 'YouTube';
      case 'tiktok':
        return 'TikTok';
      case 'tiktok_creator_marketplace':
        return 'Tiktok Creator Marketplace';
      case 'tiktok_v2':
        return 'TikTok';
      default:
        return campaignNetwork ? capitalize(campaignNetwork) : campaignNetwork;
    }
  };

  const invitesRemaining = () => {
    const {maxInviteCount = 0, currentInviteCount = 0} = campaign();
    return maxInviteCount - currentInviteCount;
  };

  const validForActionItems = () => {
    return ['accepting_proposals', 'closed_to_new_proposals'].includes(
      campaign().state
    );
  };

  const totalFeePercentage = () => {
    const {
      otherFeePercentage = 0,
      serviceFeePercentage = 0,
      fixedPercentageMargin = 0,
    } = campaign();

    return fixedPercentageMargin || otherFeePercentage + serviceFeePercentage;
  };

  const result = useMemo(
    () => ({
      ...response,
      campaign: {
        ...campaign(),
        loaded: enabled && !!campaignId && response.isFetched,
        showCampaignDetails: showCampaignDetails(),
        networkDasherized: networkDasherized(),
        isBcaCompatible: isBcaCompatible(),
        campaignNetworkPrettyName: campaignNetworkPrettyName(),
        invitesRemaining: invitesRemaining(),
        validForActionItems: validForActionItems(),
        totalFeePercentage: totalFeePercentage(),
      },
    }),
    [response.data]
  );

  return result;
}
export default useCampaignQuery;
