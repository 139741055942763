import {useEffect} from 'react';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import getCookieValue from '@/utils/getCookieValue';
import {getEnv} from '@/config/environment';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';

function useAnalyticsLtxTeamIdentifier(isAuthenticated: boolean) {
  const isLtxTeamByCookie = getCookieValue('is_lt_team');
  const isLtxTeamByEnv = getEnv().MODE !== 'production';

  const {data: dashboardUserData}: any = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  useEffect(() => {
    const userEmail = dashboardUserData?.email?.toLowerCase();
    const isLtxTeamByEmail = ['lightricks', 'popularpays'].some(
      (providerName) => userEmail?.includes?.(providerName)
    );

    if (isLtxTeamByEnv || isLtxTeamByCookie || isLtxTeamByEmail) {
      AnalyticsService.setIsLtxTeamUser(true);
    }
  }, [dashboardUserData]);

  return null;
}

export default useAnalyticsLtxTeamIdentifier;
