import React, {useEffect, useMemo} from 'react';
import {Outlet} from 'react-router-dom';
import AiStudioMobileNotSupportedPlaceholder from '@/views/ai-content-generator/components/ai-studio-mobile-not-supported-placeholder';
import useImagesCount from '@/hooks/queries/capsule-ai/use-images-count/useImagesCount';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import {useContentGenerationStore} from '@/contexts/ContentGenerationStore';
import styles from './AiContentGenerator.module.scss';

function AiContentGenerator() {
  const {state, dispatch} = useContentGenerationStore();
  const brandId = useBrandId();
  const {dashboardUser}: any = useDashboardUserQuery({});

  const {data: {imagesCount = 0} = {}, isFetched: imagesCountFetched} =
    useImagesCount(brandId, dashboardUser?.id);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (state.brandId !== brandId) {
      dispatch({
        type: 'SET_IMAGES_COUNT',
        imagesListCounter: imagesCount,
      });
    }
  }, [imagesCountFetched, imagesCount, brandId]);
  if (isMobile) {
    return <AiStudioMobileNotSupportedPlaceholder />;
  }

  if (!imagesCountFetched) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Outlet context={{imagesCount}} />
    </div>
  );
}

export default AiContentGenerator;
