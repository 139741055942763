import React from 'react';
import {Stack} from '@mui/material';
import {
  Avatar,
  designSystemToken,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './AvatarsStack.module.scss';

const AVATARS_STACK_SPACING = -13;
const MAX_AVATARS = 3;

type AvatarsStackProps = {
  avatarUrls: string[];
  hideLabel?: boolean;
  count?: number;
};

function AvatarsStack(props: AvatarsStackProps) {
  const {avatarUrls, hideLabel = false, count} = props;

  return (
    <div className={styles.container}>
      <Stack
        className={styles.avatarsStack}
        direction="row"
        spacing={`${AVATARS_STACK_SPACING}px`}
      >
        {avatarUrls.slice(0, MAX_AVATARS).map((avatarUrl, index) => (
          <Avatar
            className={styles.avatar}
            size="sm"
            type={avatarUrl ? 'picture' : 'placeholder'}
            src={avatarUrl}
            key={avatarUrl || index}
            background={designSystemToken('semantic.bg.tertiary')}
          />
        ))}
        {avatarUrls.length === 0 && (
          <Avatar
            className={styles.avatar}
            size="sm"
            type="placeholder"
            key={0}
            background={designSystemToken('semantic.bg.tertiary')}
          />
        )}
        <Avatar
          className={styles.avatar}
          size="sm"
          type="initials"
          initials={`${count ?? avatarUrls.length}`}
          key="totalAvatars"
          background={designSystemToken('semantic.bg.tertiary')}
        />
      </Stack>
      {!hideLabel && (
        <Label className={styles.avatarsStackLabel} size="md">
          {translate(
            'views.creators.components.creators-actions-bar.creators-selected',
            {count: avatarUrls.length}
          )}
        </Label>
      )}
    </div>
  );
}

export default AvatarsStack;
