import React from 'react';
import translate from '@/utils/translate';
import styles from './LdaCompliantSummary.module.scss';

const TRANSLATION_PREFIX = 'components.lda-compliant-summary';

function LdaCompliantSummary({standing}: {standing: string | null}) {
  const isCompliant = standing === 'compliant';

  const isProbablyCompliant = standing === 'probably_compliant';

  return (
    <div
      className={`${styles.ldaCompliantSummaryContainer} ${
        isCompliant ? styles.compliant : ''
      } ${isProbablyCompliant ? styles.probablyCompliant : ''}`}
    >
      <span>{translate(`${TRANSLATION_PREFIX}.header.${standing}`)}</span>
      <p>{translate(`${TRANSLATION_PREFIX}.body.${standing}`)}</p>
    </div>
  );
}

export default LdaCompliantSummary;
