import {ColumnSort} from '@tanstack/table-core';
import getParamFromSortingState from '@/utils/getParamFromSortingState';
import QueryContext from '@/api/QueryContext';
import api from '@/api';

const creatorGroupsFetchers = {
  creatorGroups: (context: QueryContext) =>
    api.get(`/brands/${context.meta?.brandId}/creator_groups`),
  featuresCreatorGroups: (context: QueryContext) => api.get('/featured_groups'),
  labels: (context: QueryContext) =>
    api.get(`/creator_groups/${context.meta?.creatorGroupId}/labels`),
  memberships: (context: QueryContext) =>
    api.get(`/creator_groups/${context.meta?.creatorGroupId}/memberships`, {
      params: {
        page: context.pageParam,
        per_page: context.meta?.perPage || 35,
        include:
          context.meta?.include ||
          'profile.identities,profile.current_brand_collaboration_conversation,labels',
        q: context.meta?.q || '',
        ...(context.meta?.labelIds
          ? {label_ids: (context.meta.labelIds as string[]).join(',')}
          : {}),
        ...(context.meta?.unlabeled ? {unlabeled: true} : {}),
        ...(context.meta?.sorting
          ? getParamFromSortingState(context.meta.sorting as ColumnSort[])
          : {}),
      },
    }),
};

export default creatorGroupsFetchers;
