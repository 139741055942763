import CreatorGroupLabel from '@/types/creatorGroupLabel';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import api from '../index';

const creatorGroupUpdaters = {
  createLabel: async (data: {
    creatorGroupId?: string;
    name: string;
    organizationLevel: boolean;
  }) => {
    return api.post(
      `/creator_groups/${data.creatorGroupId}/labels`,
      {
        name: data.name,
        organization_level: data.organizationLevel,
        creator_count: null,
        actionable_creator_count: null,
      },
      {
        customSerializer: (_data: object) => {
          return {
            ..._data,
            type: 'labels',
            relationships: {
              creator_group: {
                data: {
                  id: data.creatorGroupId,
                  type: 'creator_groups',
                },
              },
            },
          };
        },
      }
    );
  },
  updateLabelAttributes: async (data: {
    label: CreatorGroupLabel;
    newValues: Partial<CreatorGroupLabel>;
  }) =>
    api.put(`/labels/${data.label.id}`, {
      ...data.label,
      ...data.newValues,
    }),
  deleteLabel: async (labelId: string) => api.delete(`/labels/${labelId}`),
  inviteCreator: async (creatorGroupId: string, email: string) =>
    api.post(`/creator_groups/${creatorGroupId}/memberships`, {email}),
  bulkUpdateMemberships: async ({
    memberships,
  }: {
    memberships: CreatorGroupMembership[];
  }) => api.put(`/memberships/bulk_update`, {memberships}),
  updateMembershipLabels: async (data: {
    membership: CreatorGroupMembership;
    labels: CreatorGroupLabel[];
  }) => {
    return api.put(
      `/memberships/${data.membership.id}`,
      {},
      {
        customSerializer: (_data: object) => {
          return {
            data: {
              ..._data,
              type: 'memberships',
              relationships: {
                labels: {data: data.labels},
              },
            },
          };
        },
      }
    );
  },
};

export default creatorGroupUpdaters;
