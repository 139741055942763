import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Vetting from '@/views/vetting';
import VettingHub from '@/views/vetting/vetting-hub';
import VettingReport from '@/views/vetting/vetting-report';

export const VETTING_ROUTES = {
  MAIN: '',
  HUB: 'hub',
  REPORT: 'creators/:creatorId/report/:reportId',
};

function SearchRoutes() {
  return (
    <Routes>
      <Route key="vetting" path={VETTING_ROUTES.MAIN} element={<Vetting />}>
        <Route path={VETTING_ROUTES.HUB} element={<VettingHub />} />
        <Route path={VETTING_ROUTES.REPORT} element={<VettingReport />} />
      </Route>
    </Routes>
  );
}

export default SearchRoutes;
