import React from 'react';
import {Box, Stack, styled} from '@mui/material';
import {Checkbox, Icon, Label} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import {FilterProps} from '../FilterProps';

export interface SingleSelectionOptionsRadioButtonsListWithIconProps
  extends FilterProps {
  options: {
    id: string;
    localeLabelKey: string;
    iconName?: IconName;
    iconStyle?: object;
    value: string;
  }[];
  selectedOption?: BaseFilterOption;
  onChange: (value: BaseFilterOption) => void;
}

const OptionContainer = styled(Box)`
  &.MuiBox-root {
    cursor: pointer;
    display: flex;
    margin: 12px 0;
    justify-content: space-between;

    .MuiFormHelperText-root {
      display: none;
    }

    .MuiFormControlLabel-root {
      margin: 0;

      .MuiButtonBase-root {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

const IconLabelContainer = styled(Box)`
  &.MuiBox-root {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

function SingleSelectionOptionsRadioButtonsListWithIcon(
  props: SingleSelectionOptionsRadioButtonsListWithIconProps
) {
  const {options, selectedOption, onChange} = props;

  return (
    <Stack>
      {options.map((option) => (
        <OptionContainer
          key={option.id}
          onClick={() => {
            if (option.id !== selectedOption?.id) {
              onChange(option);
            }
          }}
        >
          <IconLabelContainer>
            {option.iconName && (
              <Icon
                name={option.iconName}
                style={option.iconStyle}
                size="medium"
                appearance="primary"
              />
            )}
            <Label size="md">{translate(option.localeLabelKey)}</Label>
          </IconLabelContainer>
          <Checkbox
            radio
            testID={`single-selection-options-radio-buttons-list-with-icon--${option.value}`}
            onChange={() => {}}
            checked={option.id === selectedOption?.id}
          />
        </OptionContainer>
      ))}
    </Stack>
  );
}

export default SingleSelectionOptionsRadioButtonsListWithIcon;
