import {snakeCase} from 'lodash';
import mapKeysToCase from '@/utils/mapKeysToCase';
import QueryContext from '../QueryContext';
import api from '../index';

const DEFAULT_ASSETS_INCLUDE = ['profile.identities', 'campaign'];

const assetFetchers = {
  campaignAssets: (context: QueryContext) =>
    api.get(`/campaigns/${context.meta?.campainId}/assets`),
  brandAssets: (context: QueryContext) => {
    const {pageParam} = context;
    const {identityId, include, ...rest} = context.meta || {};
    return api.get(`/brands/${context.meta?.brandId}/assets`, {
      params: {
        include: include || DEFAULT_ASSETS_INCLUDE,
        page: pageParam,
        ...mapKeysToCase(rest, snakeCase),
      },
    });
  },
};

export default assetFetchers;
