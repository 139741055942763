import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {
  Body,
  Button,
  Checkbox,
  designSystemToken,
  Input,
} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import Fortress from '@/services/fortress/Fortress';
import {
  buttonNames,
  flowNames,
  reasons,
  screenNames,
} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import emailValidator from '@/utils/validators/email';
import auth from '@/api/auth';
import {
  FormValidatorsMapProps,
  InputFieldsMap,
  SignupFormProps,
  ValidatorEntry,
  ValidatorValue,
} from '@/components/signup-form/SignupFormProps';
import styles from './SignupForm.module.scss';

const formValidatorsMap: () => FormValidatorsMapProps = () => ({
  firstName: [
    {
      validator: (firstName: string) => firstName !== '',
      errorMessage: translate('components.auth.signup-form.errors.first-name'),
    },
  ],
  lastName: [
    {
      validator: (lastName: string) => lastName !== '',
      errorMessage: translate('components.auth.signup-form.errors.last-name'),
    },
  ],
  brandName: [
    {
      validator: (brandName: string) => brandName !== '',
      errorMessage: translate('components.auth.signup-form.errors.brand-name'),
    },
  ],
  email: [
    {
      validator: (email: string) => email !== '',
      errorMessage: translate('components.auth.signup-form.errors.email'),
    },
    {
      validator: emailValidator,
      errorMessage: translate(
        'components.auth.signup-form.errors.email-invalid'
      ),
    },
  ],
  isTermsChecked: [
    {
      validator: (isTermsChecked: boolean) => isTermsChecked,
      errorMessage: translate('components.auth.signup-form.errors.terms'),
    },
  ],
  isTestBrandChecked: [
    {
      validator: (isTestBrandChecked: boolean) => true,
      errorMessage: '123',
    },
  ],
});

function SignupForm(props: SignupFormProps) {
  const {
    form,
    setForm,
    isLoading,
    setIsLoading,
    setGeneralError,
    setIsModalOpen,
    isMobile,
    disableEmail = false,
    testID = 'signup-form',
  } = props;
  const [errors, setErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    brandName: '',
    isTermsChecked: '',
    isTestBrandChecked: '',
  });
  const [internalEmail, setInternalEmail] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState<ReactNode>('');
  const [brandNameExistsError, setBrandNameExistsError] =
    useState<ReactNode>('');
  const firstInputRef = useRef<HTMLInputElement | null>(null);
  const numberOfFields = 5;
  const formValidators = formValidatorsMap();

  useEffect(() => {
    if (firstInputRef.current && !isMobile) {
      firstInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (
      form.email.endsWith('@lightricks.com') ||
      form.email.endsWith('@popularpays.com')
    ) {
      setInternalEmail(true);
      setFormValue('isTestBrandChecked', true);
    } else {
      setInternalEmail(false);
      setFormValue('isTestBrandChecked', false);
    }
  }, [form.email]);

  const inputFields: InputFieldsMap = {
    firstName: () => ({
      label: translate('components.auth.signup-form.firstname-label'),
      value: form.firstName,
      validatorOptions: {
        onChangeText: (value: string) => setFormValue('firstName', value),
        validators: formValidators.firstName,
        overrideError: errors.firstName,
      },
      inputRef: firstInputRef,
      testID: `${testID}--firstname-input`,
    }),
    lastName: () => ({
      label: translate('components.auth.signup-form.lastname-label'),
      value: form.lastName,
      validatorOptions: {
        onChangeText: (value: string) => setFormValue('lastName', value),
        validators: formValidators.lastName,
        overrideError: errors.lastName,
      },
      testID: `${testID}--lastname-input`,
    }),
    brandName: () => ({
      label: translate('components.auth.signup-form.brand-name-label'),
      value: form.brandName,
      validatorOptions: {
        onChangeText: (value: string) => setFormValue('brandName', value),
        validators: formValidators.brandName,
        overrideError: brandNameExistsError || errors.brandName,
      },
      testID: `${testID}--brand-name-input`,
    }),
    email: () => ({
      label: translate('components.auth.signup-form.email-label'),
      value: form.email,
      validatorOptions: {
        onChangeText: (value: string) => setFormValue('email', value),
        validators: formValidators.email,
        overrideError: emailExistsError || errors.email,
      },
      testID: `${testID}--email-input`,
    }),
  };

  const setFormValue = (key: string, value: string | boolean) => {
    setForm({
      ...form,
      [key]: value,
    });
    setErrors({...errors, [key]: ''});
    setEmailExistsError(null);
  };

  const validateField = (
    value: ValidatorValue,
    validators: ValidatorEntry<ValidatorValue>[]
  ) => {
    let fieldErrors = '';

    validators.forEach((validator) => {
      if (!fieldErrors.length && !validator.validator(value)) {
        fieldErrors = validator.errorMessage;
      }
    });

    return fieldErrors.length ? fieldErrors : undefined;
  };

  const validateForm = () => {
    const formErrors: Record<string, string> = {};

    Object.entries(formValidators).forEach(([fieldName, validators]) => {
      const value = form[fieldName as keyof typeof form];
      const fieldErrors = validateField(value, validators);
      if (fieldErrors) {
        formErrors[fieldName] = fieldErrors;
      }
    });

    return {formErrors};
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setGeneralError({title: '', message: ''});

    const signupFlowEvent = AnalyticsService.getFlow(flowNames.AUTH.NEW_SIGNUP);
    AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
      button_name: buttonNames.SIGNUP.CREATE_YOUR_ACCOUNT,
      screen_name: screenNames.AUTH.NEW_SIGNUP,
      flow_id: signupFlowEvent?.flow_id,
      flow_name: signupFlowEvent?.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id:
        AnalyticsService.getActiveScreenPresented().screen_presentation_id,
      campaign_id: '',
      creator_id: '',
      tab: '',
    });

    const {formErrors} = validateForm();
    const numberOfFieldsCompleted =
      numberOfFields - Object.values(formErrors).length;

    const processId = uuid();
    const emailSuffix = form.email.substring(form.email.lastIndexOf('@') + 1);
    const stepDateEvent = {
      flow_id: signupFlowEvent?.flow_id,
      process_id: processId,
      signup_provider: emailSuffix,
      request_type: 'send_otp',
      input_value: [
        form.email,
        form.brandName,
        form.firstName,
        form.lastName,
      ].join(', '),
      number_of_required_fields: numberOfFields,
      number_of_fields_completed: numberOfFieldsCompleted,
    };

    AnalyticsService.dispatchEvent(
      eventNames.SIGNUP_STEP_STARTED,
      stepDateEvent
    );

    if (Object.keys(formErrors).length) {
      setErrors({...errors, ...formErrors});
      setIsLoading(false);
      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.SIGNUP.FAILURE,
        error: Object.values(formErrors).join(', '),
      });
      return;
    }
    try {
      const {email, brandName} = form;
      const {brand_name_exists, email_address_exists} =
        await auth.checkBrandNameOrEmailExists(email, brandName);
      if (email_address_exists || brand_name_exists) {
        const emailError = email_address_exists
          ? buildFieldExistsError(
              translate(
                'components.auth.signup-form.errors.email-exist.first-plain-text'
              ),
              translate(
                'components.auth.signup-form.errors.email-exist.link-url'
              ),
              translate(
                'components.auth.signup-form.errors.email-exist.link-text'
              ),
              translate(
                'components.auth.signup-form.errors.email-exist.second-plain-text'
              )
            )
          : '';
        const emailErrorForEvents = email_address_exists
          ? translate(
              'components.auth.signup-form.errors.email-exist-for-events'
            )
          : '';
        const brandNameError = brand_name_exists
          ? buildFieldExistsError(
              translate(
                'components.auth.signup-form.errors.brand-name-exist.first-plain-text'
              ),
              translate(
                'components.auth.signup-form.errors.brand-name-exist.link-url'
              ),
              translate(
                'components.auth.signup-form.errors.brand-name-exist.link-text'
              ),
              translate(
                'components.auth.signup-form.errors.brand-name-exist.second-plain-text'
              )
            )
          : '';
        const brandNameErrorForEvents = brand_name_exists
          ? translate(
              'components.auth.signup-form.errors.brand-name-exist-for-events'
            )
          : '';

        setEmailExistsError(emailError);
        setBrandNameExistsError(brandNameError);
        onSubmitError(
          `${emailErrorForEvents}${
            emailErrorForEvents ? ',' : ''
          } ${brandNameErrorForEvents}`,
          stepDateEvent
        );
        setIsLoading(false);
        return;
      }
      await Fortress.generateOTP(form.email);
      setIsModalOpen(true);
      setIsLoading(false);

      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.AUTH.SUCCESS,
        error: null,
      });

      AnalyticsService.screenDismissed(
        screenNames.AUTH.NEW_SIGNUP,
        screenNames.AUTH.NEW_SIGNUP_OTP
      );
      AnalyticsService.screenPresented(
        screenNames.AUTH.NEW_SIGNUP_OTP,
        screenNames.AUTH.NEW_SIGNUP
      );
    } catch (err: any) {
      const error =
        err?.message ||
        err?.errors?.map((errr: any) => errr?.detail || '').join(', ') ||
        translate('views.auth.signup.general-error-message');
      setGeneralError({
        title: translate('views.auth.signup.general-error'),
        message: error,
      });
      onSubmitError(error, stepDateEvent);
    }
  };

  const onSubmitError = (
    error: string,
    stepDateEvent: Record<string, unknown>
  ) => {
    setIsLoading(false);

    AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
      ...stepDateEvent,
      reason: reasons.SIGNUP.FAILURE,
      error,
    });
  };

  const buildFieldExistsError = (
    firstPlainText: string,
    linkUrl: string,
    linkText: string,
    secondPlainText: string
  ) => {
    return (
      <span className={styles.linkFieldError}>
        {firstPlainText}
        <a href={linkUrl} target="_blank" rel="noreferrer">
          {linkText}
        </a>
        {secondPlainText}
      </span>
    );
  };

  const renderTermsAndPrivacy = () => {
    return (
      <Body
        size="md"
        color={designSystemToken('semantic.fg.secondary')}
        dangerouslyInnerHTML={translate(
          'components.auth.signup-form.terms-label'
        )}
      />
    );
  };

  const renderTestBrandText = () => {
    return (
      <Body
        size="md"
        color={designSystemToken('semantic.fg.secondary')}
        dangerouslyInnerHTML={translate(
          'components.auth.signup-form.test-brand-label'
        )}
      />
    );
  };

  const renderFormInput = (inputNameKey: string, disabled = false) => {
    const {
      label,
      value,
      validatorOptions,
      testID: inputTestID,
      inputRef,
    } = inputFields[inputNameKey as keyof InputFieldsMap]();
    return (
      <Input
        label={label}
        value={value}
        validatorOptions={validatorOptions}
        testID={inputTestID}
        inputRef={inputRef}
        disabled={disabled}
      />
    );
  };

  return (
    <form className={styles.container} onSubmit={onSubmit} data-testid={testID}>
      <div className={styles.inputGroup}>
        <div>{renderFormInput('firstName')}</div>
        <div>{renderFormInput('lastName')}</div>
      </div>
      <div>{renderFormInput('brandName')}</div>
      <div>{renderFormInput('email', disableEmail)}</div>
      {internalEmail && (
        <div className={styles.testBrandCheckbox}>
          <Checkbox
            label={renderTestBrandText()}
            checked={form.isTestBrandChecked}
            onChange={(value: boolean) => {
              setFormValue('isTestBrandChecked', value);
            }}
            error={errors.isTestBrandChecked}
          />
        </div>
      )}
      <div className={styles.signupCheckbox}>
        <Checkbox
          label={renderTermsAndPrivacy()}
          checked={form.isTermsChecked}
          onChange={(value: boolean) => {
            setFormValue('isTermsChecked', value);
          }}
          error={errors.isTermsChecked}
        />
      </div>
      <Button
        testID={`${testID}--submit-button`}
        appearance="brand"
        mode="filled"
        size="medium"
        type="submit"
        isLoading={isLoading}
      >
        {translate('components.auth.signup-form.submit-button')}
      </Button>
    </form>
  );
}

export default SignupForm;
