import React from 'react';
import {Stack} from '@mui/material';
import styles from '@/components/creator-card/ImageStack.module.scss';

type ImagesStackProps = {
  images: [string?, string?, string?];
};

function ImagesStack({images}: ImagesStackProps) {
  return (
    <Stack display="flex" direction="row" className={styles.imagesContainer}>
      {Array.from({length: 3}).map((_, index) => {
        const image = images[index];
        return image ? (
          <img
            key={image}
            src={image}
            alt="creator images"
            className={styles.creatorImage}
          />
        ) : (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`placeholder-${index}`}
            className={styles.creatorImagePlaceholder}
          />
        );
      })}
      <div className={styles.gradientOverlay} />
    </Stack>
  );
}

export default ImagesStack;
