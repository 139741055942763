const LEGACY_COLORS = {
  GREEN: '#07c98f',
  YELLOW: '#fdc030',
  GRAY_LIGHTER: '#f4f4f4',
  RED: '#ff5151',
  BLUE: '#3894fd',
  BLUE_DARK: '#3991fb',
  PURPLE: '#974fef',

  GRAY_MEDIUM_DARK: '#757575',
  GRAY_MEDIUM: '#a5a5a5',
  GRAY_LIGHT: '#d9d9d9',
};

export default LEGACY_COLORS;
