import {TooltipItem} from 'chart.js';
import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import numeral from 'numeral';
import React, {useMemo} from 'react';
import getChartLabelAbbreviation from '@/utils/getChartLabelAbbreviation';
import getPrecisePercentage from '@/utils/getPrecisePercentage';
import getTickValues from '@/utils/getTickValues';
import isNumber from '@/utils/isNumber';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_DAY_SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import {TiktokSocialHealthChartProps} from '@/components/creator-profile/components/tiktok-social-health/tiktokSocialHealthChart';
import {
  ADDITIONAL_OPTIONS_BASE,
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';

const TRANSLATION_PREFIX =
  'components.creator-profile.identity-social-health.charts.followers-vs-engagement';

function FollowersCountVsEngagementRateChart(
  props: TiktokSocialHealthChartProps
) {
  const {audiences} = props;
  const sortedAudiences = useMemo(
    () => sortBy(audiences, (audience) => dayjs(audience.createdAt).unix()),
    [audiences]
  );

  const averageFollowers = sortedAudiences.map(
    ({followersCount}) => followersCount
  );
  const averageEngagementRates = sortedAudiences.map(
    (audience) => audience.engagementRate * 100
  );
  const dates = sortedAudiences.map(({createdAt}) => createdAt);

  const followersWithOutNulls = averageFollowers.filter(isNumber);
  const engagementRateWithoutNulls = averageEngagementRates.filter(isNumber);
  const followerTickValues = getTickValues(followersWithOutNulls);

  let engagementRateTickValues = getTickValues(engagementRateWithoutNulls);

  if (
    engagementRateTickValues.min === 0 &&
    engagementRateTickValues.max === 0
  ) {
    engagementRateTickValues = {
      min: -1,
      max: 1,
    };
  }

  const chart = (
    <LineChart
      datasets={[
        {
          label: translate(`${TRANSLATION_PREFIX}.followers`),
          fill: false,
          data: averageFollowers,
          yAxisID: 'y1',
          backgroundColor: LEGACY_COLORS.BLUE,
          borderColor: LEGACY_COLORS.BLUE,
          borderWidth: 2,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: translate(`${TRANSLATION_PREFIX}.engagement-rate`),
          fill: false,
          data: averageEngagementRates,
          yAxisID: 'y2',
          backgroundColor: LEGACY_COLORS.YELLOW,
          borderColor: LEGACY_COLORS.YELLOW,
          borderWidth: 2,
          cubicInterpolationMode: 'monotone',
        },
      ]}
      additionalOptions={{
        ...ADDITIONAL_OPTIONS_BASE,
        scales: {
          x: getDefaultScaleX(dates),
          y1: getDefaultScaleY(
            translate(`${TRANSLATION_PREFIX}.followers`),
            followerTickValues,
            (label) => getChartLabelAbbreviation(label as number),
            false,
            'left'
          ),
          y2: getDefaultScaleY(
            translate(`${TRANSLATION_PREFIX}.engagement-rate`),
            engagementRateTickValues,
            (label) => getPrecisePercentage(label as number),
            false,
            'right'
          ),
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            intersect: false,
            callbacks: {
              label(tooltipItem: TooltipItem<'line'>) {
                const datasetLabel = tooltipItem.dataset.label;
                const yValue = tooltipItem.raw as number;

                let label = datasetLabel ? `${datasetLabel}: ` : '';
                if (tooltipItem.datasetIndex === 0) {
                  const numOfFollowers = Math.round(yValue * 100) / 100;
                  label += numeral(numOfFollowers).format('0,0');
                  return label;
                }

                label += Math.round(yValue * 100) / 100;
                label += '%';

                return label;
              },
              title([tooltipItem]) {
                return dayjs(dates[tooltipItem.dataIndex]).format(
                  SHORT_DAY_SHORT_MONTH_YEAR_FORMAT
                );
              },
            },
          },
        },
      }}
      labels={dates}
    />
  );

  return (
    <ChartLayout
      title={translate(`${TRANSLATION_PREFIX}.followers-vs-engagement`)}
      chart={chart}
      showEmptyState={audiences.length === 0}
    />
  );
}

export default FollowersCountVsEngagementRateChart;
