import React from 'react';
import translate from '@/utils/translate';
import {HealthEvaluation} from '@/types/healthEvaluation';
import styles from './HealthScores.module.scss';
import Rating from './Rating';
import StatisticPercentilePlot from './StatisticPercentilePlot';

const TRANSLATION_PREFIX =
  'components.social-network-identity-summary.health-scores';

interface SocialScoresProps {
  testID?: string;
  healthEvaluation: HealthEvaluation;
}

function HealthScores(props: SocialScoresProps) {
  const {testID = 'health-scores', healthEvaluation} = props;

  return (
    <div data-testid={testID} className={styles.healthScoresContainer}>
      <StatisticPercentilePlot
        title={translate(`${TRANSLATION_PREFIX}.engagement-rate`)}
        healthEvaluation={healthEvaluation}
        statistic="engagement_rate"
        value={healthEvaluation.engagementRate}
        percentile={healthEvaluation.engagementRatePercentile}
      />
      <StatisticPercentilePlot
        title={translate(`${TRANSLATION_PREFIX}.impressions-to-followers`)}
        healthEvaluation={healthEvaluation}
        statistic="impressions_to_follower_count"
        value={healthEvaluation.impressionsToFollowerCount}
        percentile={healthEvaluation.impressionsToFollowerCountPercentile}
      />
      <Rating healthEvaluation={healthEvaluation} />
    </div>
  );
}

export default HealthScores;
