import {TooltipItem} from 'chart.js';
import numeral from 'numeral';

function defaultLineChartTooltipLabel(
  tooltipItem: TooltipItem<'line'>
): string {
  const {label: datasetLabel} = tooltipItem.dataset;
  const yValue = tooltipItem.raw as number;

  let label = datasetLabel ? `${datasetLabel}: ` : '';
  if (tooltipItem.datasetIndex === 0) {
    const numOfFollowers = Math.round(yValue * 100) / 100;
    label += numeral(numOfFollowers).format('0,0');
    return label;
  }

  label += Math.round(yValue * 100) / 100;
  label += '%';

  return label;
}

export default defaultLineChartTooltipLabel;
