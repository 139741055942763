import {Column} from '@/types/models/search-creators/column';
import ColumnId from '@/types/models/search-creators/columnId';

const TRANSLATION_PREFIX = 'views.creators.components.table.columns';

export const nameColumn: Column = {
  id: ColumnId.name,
  localeLabelKey: `${TRANSLATION_PREFIX}.name`,
  visible: true,
  sortable: false,
};

export const engagementRateColumn: Column = {
  id: ColumnId.engagementRate,
  localeLabelKey: `${TRANSLATION_PREFIX}.engagement-rate`,
  visible: true,
  sortable: true,
};

export const followersColumn: Column = {
  id: ColumnId.followers,
  localeLabelKey: `${TRANSLATION_PREFIX}.followers`,
  visible: true,
  sortable: true,
};
