import React from 'react';
import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

export default function useListProps() {
  const selectedLists = useListCreatorsStore((state) => state.selectedLists);

  return React.useCallback(
    (group: CreatorGroupLabel) => {
      return {
        group,
        isSelected: selectedLists.has(group.id),
        onClick: () => {
          listCreatorsActions.toggleList(group.id);
        },
      };
    },
    [selectedLists]
  );
}
