import {partition} from 'lodash';
import React from 'react';
import {Box, Popover, styled} from '@mui/material';
import {Checkbox, Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import CreatorGroupMembership from '@/types/creatorGroupMembership';
import usePreferredCreatorGroupLabels from '@/views/creators/lists/hooks/usePreferredCreatorGroupLabels';
import {
  ListDrawerAccordion,
  NewListButton,
} from '@/components/list-drawer/components';
import useUpdateMembershipLabels from '@/hooks/mutations/use-update-membership-labels';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandId from '@/hooks/use-brand-id';
import styles from './ChangeListPopover.module.scss';

const TRANSLATION_PREFIX = 'components.list-creators-table.change-list-popover';

type ChangeListPopoverProps = {
  membership: CreatorGroupMembership;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
};

function ChangeListPopover({
  membership,
  open,
  onClose,
  anchorEl,
}: ChangeListPopoverProps) {
  const updateMembershipLablesMutation = useUpdateMembershipLabels();
  const {labels} = usePreferredCreatorGroupLabels();

  const brandId = useBrandId();
  const {currentBrand, data} = useAgencyQuery({brandId});
  const isSharedList = ({organizationLevel}: CreatorGroupLabel) =>
    organizationLevel === true;

  const [sharedLists, brandLists] = partition(labels, isSharedList);

  const Container = styled(Box)`
    .MuiFormHelperText-root {
      display: none;
    }
  `;

  const checked = (label: CreatorGroupLabel) =>
    membership.labels.map(({id}) => id).includes(label.id);

  const onChange = (label: CreatorGroupLabel) => async () => {
    if (membership.labels.length === 1 && checked(label)) return;

    const updatedLabels = checked(label)
      ? membership.labels.filter((memberLabel) => memberLabel.id !== label.id)
      : [...membership.labels, label];

    await updateMembershipLablesMutation.mutateAsync({
      membership,
      labels: updatedLabels,
    });
  };

  return (
    <Popover
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      open={open}
      classes={{paper: styles.paper}}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
      }}
    >
      <Container>
        <Headline size="sm" className={styles.headline}>
          {translate(`${TRANSLATION_PREFIX}.title`)}
        </Headline>
        <div className={styles.listsContainer}>
          {sharedLists.length ? (
            <>
              <ListDrawerAccordion
                label={
                  data?.name
                    ? translate('shared lists', {
                        agency: data.name,
                      })
                    : translate('shared-lists-fallback')
                }
              >
                {sharedLists.map((label) => (
                  <ChangeListOption
                    label={label}
                    checked={checked}
                    onChange={onChange}
                    key={label.id}
                  />
                ))}
              </ListDrawerAccordion>
              <ListDrawerAccordion
                label={translate(`${TRANSLATION_PREFIX}.brand-lists`, {
                  brandName:
                    currentBrand?.name ??
                    translate(
                      `${TRANSLATION_PREFIX}.brand-lists-brand-name-fallback`
                    ),
                })}
                preIconName="Actions-Lock-Line"
              >
                {brandLists.map((label) => (
                  <ChangeListOption
                    label={label}
                    checked={checked}
                    onChange={onChange}
                    key={label.id}
                  />
                ))}
              </ListDrawerAccordion>
            </>
          ) : (
            <>
              {brandLists.map((label) => (
                <ChangeListOption
                  label={label}
                  checked={checked}
                  onChange={onChange}
                  key={label.id}
                />
              ))}
            </>
          )}
        </div>
        <hr />
        <div className={styles.newListButtonContainer}>
          <NewListButton isListItem={false} />
        </div>
      </Container>
    </Popover>
  );
}

function ChangeListOption({
  label,
  checked,
  onChange,
}: {
  label: CreatorGroupLabel;
  checked: (label: CreatorGroupLabel) => boolean;
  onChange: (label: CreatorGroupLabel) => () => void;
}) {
  const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
  const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

  return (
    <Box sx={{px: 2, py: 1.5}} key={label.id}>
      <Checkbox
        customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
        customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
        checked={checked(label)}
        onChange={onChange(label)}
        label={
          <Box>
            {label.name}{' '}
            <span className={styles.listCount}>({label.creatorCount})</span>
          </Box>
        }
      />
    </Box>
  );
}

export default ChangeListPopover;
