import clsx from 'clsx';
import React, {useEffect, useMemo, useRef} from 'react';
import styles from './SegmentedControl.module.scss';

type Segment = {
  id: string;
  label: string;
};

export type SegmentedControlProps = {
  testID?: string;
  segments: Segment[];
  selectedSegmentId?: string;
  onChange: (id: string) => void;
  classes?: {
    segment?: string;
    activeSegment?: string;
    control?: string;
  };
};

function getActiveIndex(segments: Segment[], selectedSegmentId?: string) {
  return segments.findIndex(
    (segment: Segment) => segment.id === selectedSegmentId
  );
}

function SegmentedControl(props: SegmentedControlProps) {
  const {
    testID = 'segmented-control',
    segments,
    onChange,
    selectedSegmentId,
    classes = {},
  } = props;

  const activeIndex = useMemo(
    () => getActiveIndex(segments, selectedSegmentId),
    [segments, selectedSegmentId]
  );

  const controlRef = useRef<HTMLDivElement>(null);
  const componentReady = useRef<boolean>(false);

  const segmentsWithRefs = useMemo(() => {
    return segments.map((segment) => ({
      ...segment,
      ref: React.createRef<HTMLDivElement>(),
    }));
  }, [segments]);

  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    if (activeIndex === -1) {
      return;
    }
    const activeSegmentRef = segmentsWithRefs[activeIndex].ref;
    if (!activeSegmentRef.current || !controlRef.current) {
      return;
    }
    const {style} = controlRef.current;

    const highlightSidePadding = 2;
    const highlightWidth =
      controlRef.current.offsetWidth / segments.length -
      highlightSidePadding * 2;
    const highlightXPos =
      highlightWidth * activeIndex +
      (highlightSidePadding + activeIndex * highlightSidePadding * 2);
    style.setProperty('--highlight-width', `${highlightWidth}px`);
    style.setProperty('--highlight-x-pos', `${highlightXPos}px`);
  }, [selectedSegmentId, onChange, controlRef, segments]);

  const onInputChange = (id: string) => {
    onChange(id);
  };

  return (
    <div data-testid={testID} className={styles.container} ref={controlRef}>
      <div
        className={clsx(styles.controls, classes.control, {
          [styles.ready]: componentReady.current,
        })}
      >
        {segmentsWithRefs?.map((item) => (
          <div
            key={item.id}
            className={clsx(styles.segment, classes.segment, {
              [styles.active]: item.id === selectedSegmentId,
              [classes.activeSegment || '']: item.id === selectedSegmentId,
            })}
            ref={item.ref}
          >
            <input
              type="radio"
              className={styles.button}
              value={item.id}
              id={item.label}
              onChange={() => onInputChange(item.id)}
              checked={item.id === selectedSegmentId}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SegmentedControl;
