import {FullStory, init as initFullStory} from '@fullstory/browser';
import {useEffect} from 'react';
import getCookieValue from '@/utils/getCookieValue';
import {getEnv} from '@/config/environment';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';

const LT_ID_COOKIE_NAME = getEnv().MODE === 'staging' ? 'stg__lt_id' : 'lt_id';

function useFullStoryUser() {
  const {data: dashboardUserData}: any = useDashboardUserQuery({});
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({
    brandId,
  });

  useEffect(() => {
    const deltaAnonymousId = getCookieValue('anonymous_id');
    const isLtxTeam = !!getCookieValue('is_lt_team');
    const isDashboardUserDataPresent = !!dashboardUserData?.id;
    const {subscriptionType} = subscription;

    if (!getEnv().VITE_FULLSTORY_ORG_ID) {
      return;
    }

    initFullStory({
      orgId: getEnv().VITE_FULLSTORY_ORG_ID,
      debug: getEnv().MODE !== 'production',
    });

    if (isDashboardUserDataPresent) {
      const ltId = getCookieValue(LT_ID_COOKIE_NAME);
      FullStory('setIdentity', {
        uid: ltId,
        properties: {
          id: dashboardUserData.id,
          lt_id: ltId,
          anonymous_id: deltaAnonymousId,
          is_lt_team: isLtxTeam,
          subscription_type: subscriptionType,
        },
      });
    } else {
      FullStory('setIdentity', {
        anonymous: true,
        properties: {
          anonymous_id: deltaAnonymousId,
          is_lt_team: isLtxTeam,
        },
      });
    }
  }, [dashboardUserData]);
}

export default useFullStoryUser;
