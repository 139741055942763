import {CampaignBriefCaptionGuideline, CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefItem from './BriefItem';

class CaptionGuidelinesBriefItem extends BriefItem {
  #campaignBriefCaptionGuidelines: CampaignBriefCaptionGuideline[];

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(
      briefFieldManager,
      campaignData,
      key,
      index,
      id,
      title,
      contentHtml,
      highlighted,
      clientBriefTemplateId
    );
    this.#campaignBriefCaptionGuidelines =
      campaignData.campaignBriefCaptionGuidelines;
  }

  set campaignBriefCaptionGuidelines(value: CampaignBriefCaptionGuideline[]) {
    this.#campaignBriefCaptionGuidelines = value;
  }

  get toContentHtml() {
    if (!this.#campaignBriefCaptionGuidelines) {
      return this.contentHtml;
    }

    if (!this.#campaignBriefCaptionGuidelines.length) {
      return '';
    }

    return `
        <div>
        ${this.#campaignBriefCaptionGuidelines
          .map(
            (guideline: CampaignBriefCaptionGuideline) =>
              `<div>
                 <h3>
                 ${guideline.kind}
                 </h3>
                <span>${guideline.content}</span>
            </div>`
          )
          .join('')}
        </div>
    `;
  }
}

export default CaptionGuidelinesBriefItem;
