import {CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';

const KEY_NOT_SET = 'KEY_NOT_SET';

class BriefField {
  #key: string;

  #value: any;

  #agentKey?: string;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    value: any
  ) {
    this.#key = key;
    this.#value = value;
  }

  get key(): any {
    return this.#key;
  }

  set value(value: any) {
    this.#value = value;
  }

  get value(): any {
    return this.#value;
  }

  // eslint-disable-next-line class-methods-use-this
  get index(): number {
    return 0;
  }

  set agentKey(agentKey: string) {
    this.#agentKey = agentKey;
  }

  get agentKey(): string {
    return this.#agentKey || KEY_NOT_SET;
  }
}

export default BriefField;
