import {useInfiniteQuery} from '@tanstack/react-query';

/**
 * flattens the data and returns additional meta data that returns from the original response
 * @param options
 */
function useInfiniteDataQuery(options: object) {
  const response = useInfiniteQuery({...options, structuralSharing: false});

  const {data} = response;

  return {...response, ...(data || {})};
}

export default useInfiniteDataQuery;
