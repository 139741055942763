import React, {useCallback, useEffect} from 'react';
import {Box, Stack} from '@mui/material';
import {Headline, Modal} from '@lightricks/react-design-system';

// subtract 80px for padding
const MODAL_WIDTH = 600 - 80;

type CreatorActionModalProps = {
  title: React.ReactElement | string;
  creatorAction: string;
  content: React.ReactElement | null;
  onClose: (actionName: string) => void;
  onPresentedOrDismissed?: (
    presentedOrDismissed: 'presented' | 'dismissed',
    name: string
  ) => void;
};

function CreatorActionModal(props: CreatorActionModalProps) {
  const {content, title, creatorAction, onClose, onPresentedOrDismissed} =
    props;

  useEffect(() => {
    if (content && creatorAction) {
      onPresentedOrDismissed?.('presented', creatorAction);
    }

    return () => {
      if (!!content && creatorAction) {
        onPresentedOrDismissed?.('dismissed', creatorAction);
      }
    };
  }, [!!content, creatorAction]);
  const renderTitle = useCallback(() => {
    const isString = (value: unknown) => typeof value === 'string';
    return (
      <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
        {isString(title) ? <Headline size="md">{title}</Headline> : title}
      </Box>
    );
  }, [title]);

  return (
    <Modal
      open={!!content}
      showCloseButton
      handleClose={() => onClose(creatorAction)}
    >
      <Stack width={MODAL_WIDTH}>
        {renderTitle()}
        <Box>{content}</Box>
      </Stack>
    </Modal>
  );
}

export default CreatorActionModal;
