import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import WeightSelectionDropDown from '@/views/creators/components/actions-drawer/filters/weight-selection/drop-down';
import SegmentedControl from '@/components/segmented-control';
import {WeightedFilterProps} from '../FilterProps';
import styles from './WeightedSingleSelectionOptionsSegmentedControl.module.scss';

export interface WeightedSingleSelectionOptionsSegmentedControlProps
  extends WeightedFilterProps {
  options: BaseFilterOption[];
  weights: string[];
  selectedOption?: BaseFilterOption;
  selectedWeightId?: string;
  weightPlaceholder?: string;
  weightDisabled?: boolean;
  onChange: (option: BaseFilterOption) => void;
  onUpdateWeight: (optionId: string, weightId: string) => void;
}

function WeightedSingleSelectionOptionsSegmentedControl(
  props: WeightedSingleSelectionOptionsSegmentedControlProps
) {
  const {
    options,
    weights,
    selectedOption,
    selectedWeightId,
    weightPlaceholder,
    weightDisabled,
    onChange,
    onUpdateWeight,
  } = props;

  const segments = useMemo(() => {
    return options.map((option) => ({
      id: option.id,
      label: translate(option.localeLabelKey),
    }));
  }, [options]);

  return (
    <div>
      <SegmentedControl
        onChange={(id) => {
          const option = options.find((_option) => _option.id === id);
          if (option) {
            onChange(option);
          }
        }}
        selectedSegmentId={selectedOption?.id}
        segments={segments}
      />
      <WeightSelectionDropDown
        containerClassName={styles.selectContainer}
        selectedOptionId={selectedOption?.id}
        weights={weights}
        selectedWeightId={selectedWeightId}
        onUpdateWeight={onUpdateWeight}
        placeholder={weightPlaceholder}
        weightDisabled={weightDisabled}
      />
    </div>
  );
}

export default WeightedSingleSelectionOptionsSegmentedControl;
