import {create} from 'zustand';
import {
  FlowEndedEventData,
  FlowStartedEventData,
  handleFlowEndedEvent,
  handleFlowStartedEvent,
} from '@/components/modal-subscription/AnalyticsEventsHandler';

export type ModalState = 'entry' | 'payment' | 'result';

export const DEFAULT_PLAN_NAME = 'Enterprise';
const DEFAULT_MODAL_STATE: ModalState = 'entry';

type ModalSubscriptionStateDefinition = {
  open: boolean;
  loading: boolean;
  state: ModalState;
  result?: {
    state: 'success' | 'failure';
    headline: string;
    body: string;
  };
  featureName?: string;
  planName?: string;
};

const modalSubscriptionStore = create<ModalSubscriptionStateDefinition>(() => ({
  open: false,
  loading: false,
  state: DEFAULT_MODAL_STATE,
  featureName: undefined,
  planName: undefined,
}));

const open = ({
  featureName,
  planName,
  state = DEFAULT_MODAL_STATE,
  eventData,
}: {
  featureName?: string;
  planName?: string;
  state?: ModalState;
  eventData: FlowStartedEventData;
}) => {
  handleFlowStartedEvent(eventData);
  modalSubscriptionStore.setState({
    open: true,
    featureName,
    planName,
    state,
  });
};

const close = (eventData: FlowEndedEventData) => {
  handleFlowEndedEvent(eventData);
  return modalSubscriptionStore.setState({open: false, state: 'entry'});
};

const showPayment = () => modalSubscriptionStore.setState({state: 'payment'});

type ResultAttributes = {
  headline: string;
  body: string;
};

const showSuccess = ({headline, body}: ResultAttributes) =>
  modalSubscriptionStore.setState({
    state: 'result',
    result: {state: 'success', headline, body},
  });

const showFailure = ({headline, body}: ResultAttributes) =>
  modalSubscriptionStore.setState({
    state: 'result',
    result: {state: 'failure', headline, body},
  });

const tryAgain = () =>
  modalSubscriptionStore.setState({state: 'entry', result: undefined});

const setLoading = (loading: boolean) =>
  modalSubscriptionStore.setState({loading});

export const modalSubscriptionActions = {
  open,
  close,
  showPayment,
  showSuccess,
  showFailure,
  tryAgain,
  setLoading,
};

export default modalSubscriptionStore;
