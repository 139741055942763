import React, {useCallback} from 'react';
import {Dialog, DialogContent} from '@mui/material';
import {Button, Icon} from '@lightricks/react-design-system';
import ArrowsNavigation from '../arrows-navigation';
import styles from './ModalNavigation.module.scss';

interface ModalProps {
  testID?: string;
  open: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}

function ModalNavigation(props: ModalProps) {
  const {
    open,
    onClose,
    onPrevious,
    onNext,
    children,
    title,
    testID = 'modal-navigation',
  } = props;
  const handleOverlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowRight') {
        onNext?.();
      }
      if (event.key === 'ArrowLeft') {
        onPrevious?.();
      }
    },
    [onNext, onPrevious]
  );
  return (
    <Dialog
      data-testid={testID}
      open={open}
      onClose={onClose}
      fullScreen
      onKeyDown={handleKeyDown}
      classes={{paper: styles.dialog}}
    >
      <DialogContent
        className={styles.dialogContent}
        onClick={handleOverlayClick}
        data-testid={`${testID}-overlay`}
      >
        <div className={styles.overlayHeader} onClick={handleOverlayClick}>
          <div className={styles.titleWrapper} onClick={handleOverlayClick}>
            <span>{title}</span>
          </div>
          <div
            className={styles.closeButtonWrapper}
            onClick={handleOverlayClick}
          >
            <Button
              onClick={onClose}
              testID={`${testID}-close-button`}
              appearance="overlay"
              mode="filled"
              size="medium"
              icon={
                <Icon
                  size="medium"
                  appearance="neutral"
                  name="Actions-Close-Normal"
                />
              }
            />
          </div>
        </div>

        <div className={styles.mainContent} onClick={handleOverlayClick}>
          {children}
        </div>

        <div className={styles.navigationControls}>
          <ArrowsNavigation
            testID={`${testID}--arrows-navigation`}
            onPrevious={onPrevious}
            onNext={onNext}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalNavigation;
