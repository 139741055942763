import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import SegmentedControl from '@/components/segmented-control';
import {FilterProps} from '../FilterProps';

export interface SingleSelectionOptionsSegmentedControlProps
  extends FilterProps {
  options: BaseFilterOption[];
  selectedOption?: BaseFilterOption;
  onChange: (option: BaseFilterOption) => void;
}

function SingleSelectionOptionsSegmentedControl(
  props: SingleSelectionOptionsSegmentedControlProps
) {
  const {options, selectedOption, onChange} = props;

  const segments = useMemo(() => {
    return options.map((option) => ({
      ...option,
      id: option.id,
      label: translate(option.localeLabelKey),
    }));
  }, [options]);

  return (
    <div>
      <SegmentedControl
        onChange={(id) => {
          const option = options.find((_option) => _option.id === id);
          if (option) {
            onChange(option);
          }
        }}
        selectedSegmentId={selectedOption?.id}
        segments={segments}
      />
    </div>
  );
}

export default SingleSelectionOptionsSegmentedControl;
