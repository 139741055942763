import axiosWithAuth from '@/utils/axiosWithAuth';
import {HTTP_STATUS} from '@/config/constants';

const CREATORS_PATH = 'creators';

const creatorsFetchers = {
  checkInvitationStatusBatch: async (data: {campaignId: string}) => {
    try {
      const result = await axiosWithAuth.get(
        `${CREATORS_PATH}/invitation_status`,
        {
          params: {campaign_id: data.campaignId},
        }
      );

      if (
        result.status >= HTTP_STATUS.OK &&
        result.status < HTTP_STATUS.BAD_REQUEST
      ) {
        return result.data;
      }

      return [];
    } catch (error) {
      console.error('Error fetching invitation statuses', error);
      return [];
    }
  },
};

export default creatorsFetchers;
