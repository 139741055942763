import React from 'react';
import {Stack} from '@mui/material';
import {Button, Headline, Icon} from '@lightricks/react-design-system';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {MembershipLabel} from '@/types/creatorProfile';
import useNavigation from '@/hooks/use-navigation';

const TRANSLATION_PREFIX = 'views.creators';
const MAX_LIST_NAMES = 2;

function useRaiseCreatorAddedToListSuccess() {
  const {navigate} = useNavigation();
  function raiseCreatorAddedToListSuccess(lists: MembershipLabel[]) {
    const listIds = lists.map((list) => list.id);
    const listNames = lists.map((list) => list.label);
    raiseFlashMessage({
      status: 'success',
      mode: 'light',
      icon: (
        <Icon
          size="large"
          appearance="brand"
          name="Actions-Patch-Accept-Line"
        />
      ),
      message: (
        <Stack
          direction="row"
          display="flex"
          width="100%"
          justifyContent="space-between"
        >
          <Headline size="xs">
            {translate(`${TRANSLATION_PREFIX}.creator-added-to-list`, {
              listName: listNames.slice(0, MAX_LIST_NAMES).join(', '),
            })}
          </Headline>
          <Button
            appearance="neutral"
            mode="tinted"
            size="small"
            onClick={() =>
              navigate(`/creators?selectedLists=${listIds.join(',')}`)
            }
          >
            {translate(`${TRANSLATION_PREFIX}.creator-added-to-list-button`)}
          </Button>
        </Stack>
      ),
      withCloseButton: false,
    });
  }

  return raiseCreatorAddedToListSuccess;
}

export default useRaiseCreatorAddedToListSuccess;
