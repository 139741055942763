import React from 'react';
import {Icon, Input} from '@lightricks/react-design-system';
import {InputProps} from '@lightricks/react-design-system/src/components/input/InputProps';
import getInputStyle from '@/views/campaigns/brief/utils/getInputStyle';
import styles from './BriefFieldInput.module.scss';

interface BriefFieldInputProps extends InputProps {
  testID?: string;
  onInputChange?: (value: any) => void;
  hasErrors?: boolean;
  labelSize?: 'lg' | 'md' | 'sm';
  hideRemoveIcon?: boolean;
  onRemove?: () => void;
  handleOnFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isFocused?: boolean;
  readOnly?: boolean;
}

function BriefFieldInput(props: BriefFieldInputProps) {
  const {
    testID = 'brief-field-input',
    onInputChange,
    hasErrors = false,
    labelSize = 'sm',
    clearIcon = false,
    className,
    disabled,
    onBlur,
    onFocus,
    handleOnFocus,
    isFocused = false,
    readOnly = false,
    inputProps = {},
    endIcon,
    hideRemoveIcon,
    onRemove,
    ...rest
  } = props;

  // eslint-disable-next-line no-underscore-dangle
  const _handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);
    handleOnFocus?.(event);
    inputProps?.onFocus?.(event);
  };

  // eslint-disable-next-line no-underscore-dangle
  const _handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
    inputProps?.onBlur?.(event);
  };

  const renderEndIcon = () => {
    if (endIcon) {
      return endIcon;
    }

    return !hideRemoveIcon || !disabled
      ? onRemove && (
          <div
            className={styles.deleteIcon}
            onMouseDown={() => onRemove()}
            data-testid={`${testID}--delete-icon`}
          >
            <Icon size="medium" appearance="secondary" name="Actions-Remove" />
          </div>
        )
      : undefined;
  };

  return (
    <Input
      onInputChange={readOnly ? undefined : onInputChange}
      labelSize={labelSize}
      clearIcon={clearIcon}
      className={`${styles.briefFieldInput} ${className || ''}`}
      disabled={disabled}
      testID={testID}
      {...getInputStyle({hasErrors, isFocused, readOnly})}
      {...rest}
      inputProps={{
        ...inputProps,
        onFocus: _handleOnFocus,
        onBlur: _handleOnBlur,
      }}
      endIcon={renderEndIcon()}
    />
  );
}

export default BriefFieldInput;
