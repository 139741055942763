function getInitials(fullName: string): string {
  if (!fullName || fullName.trim().length === 0) {
    return '';
  }

  const [firstName, middleName, lastName] = fullName.trim().split(' ');

  return `${firstName[0]}${middleName?.[0] || ''}${lastName?.[0] || ''}`;
}

export default getInitials;
