import dayjs from 'dayjs';
import {DAY_HOUR_MINUTE_FORMAT, MONTH_DAY_FORMAT} from '@/config/timeFormats';

export function formatBasedOnTimeSince(timestamp: number | Date) {
  const time = dayjs(timestamp);
  const oneDayAgo = dayjs().subtract(1, 'days');
  const oneWeekAgo = dayjs().subtract(7, 'days');

  if (time > oneDayAgo) {
    return time.fromNow();
  }
  if (time > oneWeekAgo) {
    return time.format(DAY_HOUR_MINUTE_FORMAT);
  }

  return time.format(MONTH_DAY_FORMAT);
}

function formatBasedOnProvidedFormat(
  timestamp: number | Date,
  formatString?: string
) {
  const time = dayjs(timestamp);
  return time.format(formatString);
}

export function formattedTimestamp(
  timestamp: number | Date,
  formatString?: string
) {
  if (!timestamp) {
    return '';
  }
  if (formatString) {
    return formatBasedOnProvidedFormat(timestamp, formatString);
  }
  return formatBasedOnTimeSince(timestamp);
}

export default formattedTimestamp;
