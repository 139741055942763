import React from 'react';
import {Body} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import BriefItem from '@/views/campaigns/brief/components/campaign-brief/components/brief-item';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {SectionProps} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import QuestionsForCreator from '@/views/campaigns/brief/components/campaign-brief/section/additional-information/questions-for-creator';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import removeBriefItemSuffix from '@/views/campaigns/brief/utils/removeBriefItemSuffix';
import styles from './AdditionalInformation.module.scss';

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.additional-information';

const URL_PREFIX = 'https://';

function AdditionalInformation({
  editable,
  updateBriefForm,
  saveCampaign,
  briefFieldManager,
  errors,
  setShowFormErrors,
  campaign,
}: SectionProps) {
  const urlToPromote = (
    briefFieldManager.fields.urlToPromote.value || ''
  ).replace(new RegExp(`^${URL_PREFIX}`, 'i'), '');

  const onUrlToPromoteChange = (value: string) =>
    updateBriefForm?.(
      `urlToPromote`,
      value ? `${URL_PREFIX}${value}` : value,
      'urlToPromote'
    );

  return (
    <div className={styles.container}>
      <BriefItem
        editable={editable}
        optional
        briefItem={briefFieldManager.fields.brandStyleGuideBriefItem?.value}
        saveCampaign={saveCampaign}
        onChange={(value) =>
          updateBriefForm?.(
            `campaignPaidGigBriefItems.${briefFieldManager.fields.brandStyleGuideBriefItem?.index}.contentHtml`,
            value,
            'brandStyleGuideBriefItem'
          )
        }
        placeholder={translate(
          `${TRANSLATION_PREFIX}.brand-style-guide-placeholder`
        )}
        campaignId={campaign.id}
        fieldName={removeBriefItemSuffix(
          briefFieldManager.fields.brandStyleGuideBriefItem?.key
        )}
      />
      {!briefFieldManager.fields.sectionsToHide.value?.urlToPromote ? (
        <ValidatedField errors={errors?.urlToPromote}>
          <BriefFieldInput
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.ADDITIONAL_INFORMATION__URL_TO_PROMOTE_INPUT
            }
            className={styles.urlToPromoteInput}
            label={translate(`${TRANSLATION_PREFIX}.url-to-promote`)}
            disabled={!editable}
            value={urlToPromote}
            placeholder={translate(
              `${TRANSLATION_PREFIX}.url-to-promote-placeholder`
            )}
            onInputChange={onUrlToPromoteChange}
            inputProps={{
              value: urlToPromote,
              onBlur: () => {
                setShowFormErrors(true);
                saveCampaign?.({
                  campaignId: campaign.id,
                  fieldName: briefFieldManager.fields.urlToPromote.key,
                  fieldValue: urlToPromote,
                });
              },
            }}
            startIcon={
              <Body
                size="md"
                className={`${styles.urlToPromotePrefix} ${
                  !urlToPromote ? styles.empty : ''
                }`}
              >
                {URL_PREFIX}
              </Body>
            }
          />
        </ValidatedField>
      ) : null}
      <BriefItem
        editable={editable}
        optional
        briefItem={
          briefFieldManager.fields.eligibilityRequirementsBriefItem?.value
        }
        saveCampaign={saveCampaign}
        onChange={(value) =>
          updateBriefForm?.(
            `campaignPaidGigBriefItems.${briefFieldManager.fields.eligibilityRequirementsBriefItem?.index}.contentHtml`,
            value,
            'eligibilityRequirementsBriefItem'
          )
        }
        placeholder={translate(
          `${TRANSLATION_PREFIX}.eligibility-requirements-placeholder`
        )}
        campaignId={campaign.id}
        fieldName={removeBriefItemSuffix(
          briefFieldManager.fields.eligibilityRequirementsBriefItem?.key
        )}
      />
      <QuestionsForCreator
        updateBriefForm={updateBriefForm}
        questions={briefFieldManager.fields.questions.value}
        editable={editable}
      />
    </div>
  );
}

export default AdditionalInformation;
