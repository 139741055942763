import React from 'react';

export interface ConditionalProps {
  condition: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

function Conditional(props: ConditionalProps) {
  const {condition, children, fallback = null} = props;

  if (!condition) {
    return fallback;
  }
  return children;
}

export default Conditional;
