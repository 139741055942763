import {STALE_TIME} from '@/utils/reactQueryClient';
import capsuleAiFetchers from '@/api/fetchers/capsuleAi';
import useInfiniteDataQuery from '@/hooks/use-infinite-data-query';

type UseImagesQueryParams = {
  projectId?: string;
  brandId?: string;
  dashboardUserId?: string;
  enabled?: boolean;
  perPage?: number;
  page?: number;
};
function useImagesQuery({
  projectId,
  brandId,
  dashboardUserId,
  enabled = true,
  perPage = 16,
  page,
}: UseImagesQueryParams) {
  const response = useInfiniteDataQuery({
    queryKey: ['capsuleAiImages', projectId, brandId, dashboardUserId],
    queryFn: ({pageParam = page || 1, ...rest}) =>
      capsuleAiFetchers.images({pageParam, ...rest}),
    keepPreviousData: true,
    enabled,
    staleTime: STALE_TIME,
    meta: {
      projectId,
      brandId,
      dashboardUserId,
      perPage,
      page,
    },
    getNextPageParam: (lastPage: {
      data: {
        meta: {currentPage: number; totalPages: number};
      };
    }) => {
      if (!lastPage?.data?.meta) {
        return undefined;
      }
      const {
        data: {meta},
      } = lastPage;
      return meta.currentPage < meta.totalPages
        ? meta.currentPage + 1
        : undefined;
    },
  });

  const {data = {}} = response;
  const {pages = []}: any = data || {};
  const meta = pages[pages.length - 1]?.data?.meta;

  return {
    ...response,
    data: pages.flatMap((_page: {data: {images: []}}) => _page?.data?.images),
    meta,
  };
}

export default useImagesQuery;
