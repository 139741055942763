import agencyFetchers from '@/api/fetchers/agency';
import useDataQuery from '@/hooks/use-data-query';

function useAgencyQuery({brandId}: {brandId: string}) {
  const response: any = useDataQuery({
    queryKey: ['agency', brandId],
    queryFn: agencyFetchers.agency,
    enabled: !!brandId,
    refetchOnWindowFocus: false,
    cacheTime: 1800, // 30 minutes
    staleTime: 600, // 10 minutes
    meta: {
      brandId,
    },
  });

  const currentBrand = response.data?.brands?.find(
    (brand: {id: string}) => String(brand.id) === String(brandId)
  );
  const allowEditCreatorBid = response.data?.allowEditCreatorBid;

  return {
    ...response,
    currentBrand,
    allowEditCreatorBid,
    brands: response.data?.brands || [],
  };
}

export default useAgencyQuery;
