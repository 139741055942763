import CreatorGroupMembership from '@/types/creatorGroupMembership';

function getCreatorIdsForAnalytics(
  memberships: CreatorGroupMembership[]
): Record<string, string> {
  return memberships.reduce((acc: Record<string, string>, membership) => {
    acc[membership.userId] = (membership.labels || [])
      .map((label) => label.name)
      .join(',');
    return acc;
  }, {});
}

export default getCreatorIdsForAnalytics;
