import QueryContext from '@/api/QueryContext';
import api from '../index';

type BrandsParams = {
  brandId?: string;
  query?: string;
  pageParam?: number;
};

const brandFetchers = {
  brands: ({pageParam, query, brandId}: BrandsParams) =>
    api.get('/brands', {
      params: {
        query: query || '',
        brand_id: brandId || '',
        page: pageParam || 1,
      },
    }),
  brandCampaigns: (context: QueryContext) =>
    api.get(`/brands/${context.meta?.brandId}/campaigns`, {
      params: {
        state: context.meta?.state,
      },
    }),
  brandCreatorGroups: (context: QueryContext) =>
    api.get(`/brands/${context.meta?.brandId}/creator_groups`),
};

export default brandFetchers;
