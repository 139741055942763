import React from 'react';
import {Stack} from '@mui/material';
import {Button, Headline, Icon} from '@lightricks/react-design-system';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';

const TRANSLATION_PREFIX = 'views.creators.flash-messages';

function useRaiseMessageSentToCreatorSuccess() {
  return function raiseMessageSentToCreatorSuccess() {
    return raiseFlashMessage({
      status: 'success',
      mode: 'light',
      icon: (
        <Icon
          size="medium"
          appearance="brand"
          name="Actions-Patch-Accept-Line"
        />
      ),
      message: (
        <Stack
          direction="row"
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Headline size="xs">
            {translate(`${TRANSLATION_PREFIX}.message-creator-title`)}
          </Headline>
          <Button appearance="neutral" mode="tinted" size="small">
            {translate(`${TRANSLATION_PREFIX}.banner-button`)}
          </Button>
        </Stack>
      ),
      withCloseButton: false,
    });
  };
}

export default useRaiseMessageSentToCreatorSuccess;
