function getTickValues(listOfValues: number[]) {
  const minValue = Math.min(...listOfValues);
  const maxValue = Math.max(...listOfValues);
  const max = maxValue * 1.25;
  const tempMin = minValue - maxValue * 0.25;
  const min = tempMin < 0 ? 0 : tempMin;

  return {
    max,
    min,
  };
}

export default getTickValues;
