import React, {useCallback, useEffect, useState} from 'react';

function useFittedElementsInContainer(
  containerRef: React.RefObject<HTMLElement>,
  elementSize: number,
  gapBetweenElements = 0,
  defaultElementsCount = 1,
  minElementsCount = 1
) {
  const [fittedElements, setFittedElements] = useState(defaultElementsCount);

  const calculateFittedElement = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const elementSizeWithGap = elementSize + gapBetweenElements;
      const calculatedAmount = containerWidth / elementSizeWithGap;

      setFittedElements(
        Math.floor(
          Math.max(
            Math.min(calculatedAmount, defaultElementsCount),
            minElementsCount
          )
        )
      );
    }
  }, [
    containerRef.current?.offsetWidth,
    elementSize,
    gapBetweenElements,
    defaultElementsCount,
  ]);

  useEffect(() => {
    calculateFittedElement();
  }, [
    containerRef.current?.offsetWidth,
    elementSize,
    gapBetweenElements,
    defaultElementsCount,
  ]);

  useEffect(() => {
    window.addEventListener('resize', calculateFittedElement);
    return () => {
      window.removeEventListener('resize', calculateFittedElement);
    };
  }, [calculateFittedElement]);

  return fittedElements;
}

export default useFittedElementsInContainer;
