import api from '../index';

const agencyUpdaters = {
  createAgency: async (agency: {
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    jwtToken: string;
    isTermsChecked: boolean;
    requestedPlanId?: string;
    isTestBrandChecked: boolean;
  }) => {
    return api.post(
      `/agencies`,
      {
        email: agency.email,
        name: agency.name,
        firstName: agency.firstName,
        lastName: agency.lastName,
        jwtToken: agency.jwtToken,
        acceptedTerms: agency.isTermsChecked,
        requestedPlanId: agency.requestedPlanId,
        isTestBrand: agency.isTestBrandChecked,
      },
      {
        customSerializer: (data: any) => {
          const serializedData = {
            ...data,
          };
          serializedData.data.attributes.jwtToken =
            serializedData.data.attributes.jwt_token;
          delete serializedData.data.attributes.jwt_token;
          return serializedData;
        },
      }
    );
  },
  createOnboardingAnswers: async (data: {
    agencyId: string;
    answers: string[];
  }) => {
    return api.put(`/agencies/${data.agencyId}/onboarding_questionnaire`, {
      question_option_ids: data.answers,
    });
  },
};

export default agencyUpdaters;
