import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {v4 as uuid} from 'uuid';
import Skeleton from '@mui/material/Skeleton';
import {Button, Headline, Input, Icon} from '@lightricks/react-design-system';
import {AI_STUDIO_ROUTES} from '@/routes/ai-content-generator/AiContentGenerator';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import getCapsuleAiImageUrl from '@/utils/getCapsuleAiImageUrl';
import getImageDimensions from '@/utils/getImageDimensions';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';
import ImageUploader from '@/views/ai-content-generator/components/image-uploader';
import Spinner from '@/views/ai-content-generator/components/spinner';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useImageCompression from '@/hooks/use-image-compression';
import useNavigation from '@/hooks/use-navigation';
import {useBannerStore} from '@/contexts/BannerContext';
import {
  getSessionId,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './ImageUploadProcess.module.scss';
import item1 from './images/item1.jpg';
import item2 from './images/item2.jpg';
import item3 from './images/item3.jpg';
import VIcon from './images/v.svg';
import XIcon from './images/x.svg';

const IMAGE_UPLOADER_TRANSLATION_PREFIX =
  'views.ai-content-generator.components.image-uploader';

const GENERAL_ERROR = {
  title: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.errors.general-error-title`,
  message: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.errors.general-error-message`,
};

const ERROR_MESSAGES: {[id: string]: string} = {
  fileSize: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.errors.file-size-message`,
  fileType: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.errors.file-type-message`,
};

export const INPUT_VALIDATION_MESSAGES: {[id: string]: string} = {
  emptyDescription: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.input-validations.empty-description-message`,
  imageProcessing: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.input-validations.image-processing-message`,
  imageUpload: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.input-validations.image-upload-message`,
};
interface ServerError {
  response?: {
    status?: number;
    data?: {
      detail?: string;
    };
  };
}

function isServerError(error: unknown): error is ServerError {
  return (error as ServerError).response !== undefined;
}
function UploadImageInstructions() {
  return (
    <div className={styles.uploadImageInstructions}>
      <Headline size="xxs" className={styles.uploadImageInstructionsTitle}>
        {translate(
          `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.upload-image-instructions.title`
        )}
      </Headline>
      <div className={styles.uploadImageInstructionsText}>
        {translate(
          `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.upload-image-instructions.text`
        )}
      </div>
      <ul className={styles.uploadImageInstructionsIcon}>
        <li>
          <img src={item1} alt="product_demo" className={styles.image} />
          <img src={VIcon} alt="v" className={styles.icon} />
        </li>
        <li>
          <img src={item2} alt="product_demo" className={styles.image} />
          <img src={VIcon} alt="v" className={styles.icon} />
        </li>
        <li>
          <img src={item3} alt="product_demo" className={styles.image} />
          <img src={XIcon} alt="x" className={styles.icon} />
        </li>
      </ul>
    </div>
  );
}

type UploadBoxProps = {
  setInputErrorMessage: (newValue: string | null) => void;
  imageErrorMessage: string | null;
  setImageErrorMessage: (newValue: string | null) => void;
  flowId: string | undefined;
  isLoading: boolean;
  setIsLoading: (newValue: boolean) => void;
  transparentImageFetched?: boolean;
  transparentImageUrl: string;
  setTransparentImageUrl: (newValue: string) => void;
  sessionId: number;
  setSessionId: (newValue: number) => void;
  setTransparentImageEventData: Dispatch<
    SetStateAction<{width: string; height: string; id: string}>
  >;
  testID?: string;
};
function UploadBox(props: UploadBoxProps) {
  const {
    setInputErrorMessage,
    imageErrorMessage,
    setImageErrorMessage,
    flowId,
    isLoading,
    setIsLoading,
    transparentImageFetched,
    transparentImageUrl,
    setTransparentImageUrl,
    sessionId,
    setSessionId,
    setTransparentImageEventData,
    testID = 'upload-box',
  } = props;
  const [originalLocalFile, setOriginalLocalFile] = useState('');
  const [selectedUploadFile, setSelectedUploadFile] = useState<File | null>(
    null
  );
  const [uploadEventInteractionId, setUploadEventInteractionId] = useState('');

  const {state, dispatch} = useContentGenerationStore();
  const {dispatch: bannerDispatch} = useBannerStore();
  const brandId = useBrandId();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {compressedFile, isCompressing} =
    useImageCompression(selectedUploadFile);
  const {dashboardUser}: any = useDashboardUserQuery({});

  const showUploadBoxEmptyState =
    !transparentImageUrl && !originalLocalFile && transparentImageFetched;
  const showUploadBoxWithImage =
    !showUploadBoxEmptyState && transparentImageFetched;
  const showUploadBoxWithOriginalImage = originalLocalFile?.length > 0;
  const showUploadBoxWithTransparentImage = !!(
    transparentImageUrl && !originalLocalFile
  );

  const resetImageState = () => {
    setOriginalLocalFile('');
    setSelectedUploadFile(null);
    dispatch({
      type: 'UPDATE_TRANSPARENT_REMOTE_FILE',
      transparentImagePath: '',
      transparentImageId: '',
    });
  };
  const uploadImage = async (file: File) => {
    setInputErrorMessage(null);
    setImageErrorMessage(null);
    setSelectedUploadFile(null);
    setIsLoading(true);
    const analyticEventNameKey = `clicked_${
      transparentImageUrl ? 'replace' : 'upload'
    }`;
    const processId = uuid();

    const {width: uploadedWidth, height: uploadedHeight} =
      await getImageDimensions(file);

    AnalyticsService.dispatchEvent(
      EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_STARTED,
      {
        flow_id: flowId,
        process_source: analyticEventNameKey,
        asset_type: 'photo',
        asset_size: `${uploadedWidth}x${uploadedHeight}`,
        process_id: processId,
        interaction_id: uploadEventInteractionId,
        process_details: '',
      }
    );

    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('uuid', dashboardUser.id);
      formData.append('horizontal_alignment', 'default');
      formData.append('vertical_alignment', 'default');
      formData.append('frame_width', '0');
      formData.append('frame_height', '0');
      formData.append('brand_id', brandId);

      await capsuleAiUpdaters.uploadImage(formData).then((response) => {
        const {
          transparentImageUrl: transparentImageUrlRes,
          width,
          height,
          id,
        } = response.data.data;
        setOriginalLocalFile('');
        setTransparentImageUrl(getCapsuleAiImageUrl(transparentImageUrlRes));
        setTransparentImageEventData({
          width: String(width),
          height: String(height),
          id: String(id),
        });

        setSessionId(response.data.data.sessionId);
        setIsLoading(false);
        AnalyticsService.dispatchEvent(
          EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
          {
            flow_id: flowId,
            process_id: processId,
            result: 'success',
            process_details: '',
            error: null,
          }
        );
      });
    } catch (error: unknown) {
      resetImageState();
      console.error(error);
      let errorMessage = GENERAL_ERROR.message;
      if (isServerError(error)) {
        if (error?.response?.status === 413) {
          errorMessage = translate(ERROR_MESSAGES.fileSize);
          setImageErrorMessage(errorMessage);
        } else if (error?.response?.status === 406) {
          errorMessage = translate(ERROR_MESSAGES.fileType);
          setImageErrorMessage(errorMessage);
        } else {
          errorMessage = error?.response?.data?.detail || GENERAL_ERROR.message;
          bannerDispatch({
            type: 'SET_BANNER',
            title: translate(GENERAL_ERROR.title),
            message: translate(GENERAL_ERROR.message),
            severity: 'warning',
          });
        }
      } else {
        bannerDispatch({
          type: 'SET_BANNER',
          title: translate(GENERAL_ERROR.title),
          message: translate(errorMessage),
          severity: 'warning',
        });
      }

      AnalyticsService.dispatchEvent(
        EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
        {
          flow_id: flowId,
          process_id: processId,
          result: 'failed',
          process_details: '',
          error: translate(errorMessage),
        }
      );
    }
  };

  useEffect(() => {
    if (compressedFile) {
      uploadImage(compressedFile).catch((error) => console.error(error));
    }
  }, [compressedFile]);

  useEffect(() => {
    if (selectedUploadFile) {
      setOriginalLocalFile(URL.createObjectURL(selectedUploadFile));
    }
  }, [selectedUploadFile]);

  const onUpload = () => {
    fileInputRef?.current?.click();

    const interactionId = uuid();
    setUploadEventInteractionId(interactionId);
    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_INTERACTION, {
      interaction_name: showUploadBoxEmptyState ? 'upload' : 'replace',
      interaction_type: 'click',
      interaction_location: state.showUploadModal
        ? 'ai_studio_modal'
        : 'ai_studio_welcome_screen',
      interaction_details: '',
      interaction_id: interactionId,
    });
  };

  return (
    <div className={styles.uploadBox}>
      {!transparentImageFetched ? (
        <Skeleton
          variant="rounded"
          data-testid={`${testID}--image-input-skeleton`}
        />
      ) : null}
      {showUploadBoxWithImage && (
        <div className={styles.uploadBoxWithImage}>
          <div
            className={`${styles.imageContainer} ${
              showUploadBoxWithTransparentImage
                ? styles.transparentImageBackground
                : ''
            }`}
          >
            <img
              src={
                showUploadBoxWithOriginalImage
                  ? originalLocalFile
                  : transparentImageUrl
              }
              alt="Preview"
            />
            {isLoading || isCompressing ? (
              <div className={styles.spinningLoaderWrapper}>
                <Spinner size={52} />
              </div>
            ) : null}
          </div>
        </div>
      )}
      {showUploadBoxEmptyState && (
        <div className={styles.emptyStateContainer} onClick={onUpload}>
          <div className={styles.emptyStateInstructions}>
            <Button
              icon={
                <Icon
                  size="medium"
                  appearance="neutral"
                  name="Actions-Add-Small1"
                />
              }
              appearance="neutral"
              mode="tinted"
              size="small"
              testID={`${testID}--empty-state-button`}
            />
            <p
              className={styles.emptyStateText}
              data-testid={`${testID}--empty-state-text`}
            >
              {translate(
                `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.upload-box.empty-state.text`
              )}
            </p>
          </div>
        </div>
      )}
      <ImageUploader
        onClick={onUpload}
        fileInputRef={fileInputRef}
        setSelectedUploadFile={setSelectedUploadFile}
      />
      {showUploadBoxWithTransparentImage && (
        <div className={styles.replaceImageSection}>
          <Button
            icon={
              <Icon
                size="medium"
                appearance="neutral"
                name="Actions-Add-Small1"
              />
            }
            onClick={onUpload}
            appearance="neutral"
            mode="tinted"
            size="small"
            className={styles.replaceImageButton}
            testID={`${testID}--replace-image-button`}
          />
          <div className={styles.replaceImageText}>
            {' '}
            {translate(
              `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.upload-box.replace-button.text`
            )}
          </div>
        </div>
      )}
      <div
        className={styles.uploadImageError}
        data-testid={`${testID}--upload-image-error-message`}
      >
        {imageErrorMessage}
      </div>
    </div>
  );
}

export type ChildRef = {
  handleSubmit: () => void;
};

type ImageUploadProcessProps = {
  imageUploadProcessRef?: any;
  transparentImageFetched: boolean;
  transparentImageUrl: string;
  transparentImageId: string;
  transparentImageDescription: string;
  transparentImageSessionId: number;
  containerStyle?: React.CSSProperties;
  source: string;
  testID?: string;
};
function ImageUploadProcess(props: ImageUploadProcessProps) {
  const {
    imageUploadProcessRef,
    transparentImageFetched,
    transparentImageUrl: latestTransparentImageUrl,
    transparentImageId: latestTransparentImageId,
    transparentImageDescription,
    transparentImageSessionId,
    containerStyle,
    source,
    testID = 'image-upload-process',
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState<string | null>(
    null
  );
  const [imageErrorMessage, setImageErrorMessage] = useState<string | null>(
    null
  );
  const [transparentImageUrl, setTransparentImageUrl] = useState(
    latestTransparentImageUrl
  );
  const [sessionId, setSessionId] = useState(transparentImageSessionId);
  const [transparentImageEventData, setTransparentImageEventData] = useState({
    width: '0',
    height: '0',
    id: '',
  });

  const brandId = useBrandId();
  const navigation = useNavigation();
  const {dispatch: bannerDispatch} = useBannerStore();
  const {state, dispatch} = useContentGenerationStore();
  const [productName, setProductName] = useState('');

  const aiContentFlow = AnalyticsService.getFlow(FLOW_NAMES.AI_STUDIO);
  const flowId = aiContentFlow?.flow_id;

  useEffect(() => {
    if (transparentImageFetched) {
      setTransparentImageUrl(getCapsuleAiImageUrl(latestTransparentImageUrl));
      setProductName(transparentImageDescription || '');
      setSessionId(transparentImageSessionId);
      dispatch({
        type: 'UPDATE_PRODUCT_NAME_INPUT',
        productName: transparentImageDescription || '',
      });
    }
  }, [transparentImageFetched]);

  useImperativeHandle(imageUploadProcessRef, () => ({
    handleSubmit,
  }));

  const detectInputChanges = () => {
    const imageInputChanged =
      transparentImageUrl.length > 0 &&
      getCapsuleAiImageUrl(latestTransparentImageUrl) !== transparentImageUrl;
    const productInputChanged =
      productName.length > 0 && transparentImageDescription !== productName;

    return imageInputChanged || productInputChanged;
  };
  const inputValidation = () => {
    setInputErrorMessage(null);
    setImageErrorMessage(null);
    if (productName.length > 0 && transparentImageUrl && !isLoading) {
      return true;
    }
    if (productName.length === 0) {
      setInputErrorMessage(
        translate(INPUT_VALIDATION_MESSAGES.emptyDescription)
      );
    }
    if (isLoading) {
      setImageErrorMessage(
        translate(INPUT_VALIDATION_MESSAGES.imageProcessing)
      );
    } else if (!transparentImageUrl) {
      setImageErrorMessage(translate(INPUT_VALIDATION_MESSAGES.imageUpload));
    }
    return false;
  };
  const handleSubmit = async (): Promise<void> => {
    if (inputValidation()) {
      if (!detectInputChanges()) {
        dispatch({
          type: 'UPDATE_SHOW_UPLOAD_MODAL',
          showUploadModal: false,
        });
        AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_IMPORTED, {
          flow_id: aiContentFlow.flow_id,
          import_source: source,
          asset_type: 'photo',
          asset_id: transparentImageEventData?.id || latestTransparentImageId,
          asset_details: JSON.stringify({
            image_url: transparentImageUrl,
            product_name: productName,
          }),
          result: 'success',
          error: null,
        });
        navigation.navigate(
          `${AI_STUDIO_ROUTES.BASE}/${AI_STUDIO_ROUTES.DASHBOARD}`
        );
      } else {
        try {
          await capsuleAiUpdaters.updateImageDescription({
            sessionId,
            imageDescription: productName,
          });
          dispatch({
            type: 'UPDATE_SESSION_ID',
            sessionId,
          });
          dispatch({
            type: 'UPDATE_TRANSPARENT_REMOTE_FILE',
            transparentImagePath: getCapsuleAiImageUrl(transparentImageUrl),
            transparentImageId:
              transparentImageEventData?.id || latestTransparentImageId,
          });

          const transparentImage = {
            imageUrl: transparentImageUrl,
            imageDescription: productName,
            sessionId: getSessionId(state),
          };

          queryClient.setQueryData(['transparentImage', brandId], {
            data: {transparentImage},
          });

          dispatch({
            type: 'UPDATE_PRODUCT_NAME_INPUT',
            productName,
          });
          dispatch({
            type: 'UPDATE_SHOW_UPLOAD_MODAL',
            showUploadModal: false,
          });
          dispatch({
            type: 'UPDATE_TRANSPARENT_IMAGE_SIZE',
            transparentImageWidth: transparentImageEventData.width,
            transparentImageHeight: transparentImageEventData.height,
          });
          AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_IMPORTED, {
            flow_id: aiContentFlow.flow_id,
            import_source: source,
            asset_type: 'photo',
            asset_id: transparentImageEventData?.id || latestTransparentImageId,
            asset_details: JSON.stringify({
              image_url: transparentImageUrl,
              product_name: productName,
            }),
            result: 'success',
            error: null,
          });
          navigation.navigate(
            `${AI_STUDIO_ROUTES.BASE}/${AI_STUDIO_ROUTES.DASHBOARD}`
          );
        } catch (error: any) {
          bannerDispatch({
            type: 'SET_BANNER',
            title: translate(GENERAL_ERROR.title),
            message: translate(GENERAL_ERROR.message),
            severity: 'warning',
          });

          AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_IMPORTED, {
            flow_id: aiContentFlow.flow_id,
            import_source: source,
            asset_type: 'photo',
            asset_id: transparentImageEventData?.id || latestTransparentImageId,
            asset_details: JSON.stringify({
              image_url: transparentImageUrl,
              product_name: productName,
            }),
            result: 'failed',
            error: error?.response?.data?.detail || GENERAL_ERROR.message,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (brandId) {
      setInputErrorMessage(null);
      setImageErrorMessage(null);
    }
  }, [brandId]);
  return (
    <div className={styles.imageUploadProcessContainer} style={containerStyle}>
      <div className={styles.leftSide}>
        <UploadBox
          setInputErrorMessage={setInputErrorMessage}
          imageErrorMessage={imageErrorMessage}
          setImageErrorMessage={setImageErrorMessage}
          flowId={flowId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          transparentImageFetched={transparentImageFetched}
          transparentImageUrl={transparentImageUrl}
          setTransparentImageUrl={setTransparentImageUrl}
          sessionId={sessionId}
          setSessionId={setSessionId}
          setTransparentImageEventData={setTransparentImageEventData}
        />
      </div>
      <div className={styles.rightSide}>
        <label
          className={styles.imageDescriptionSection}
          htmlFor="product_name"
        >
          <span className={styles.imageDescriptionLabel}>
            {translate(
              `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.image-description.label`
            )}
          </span>
          {!transparentImageFetched ? (
            <Skeleton
              variant="text"
              data-testid={`${testID}--text-input-skeleton`}
            />
          ) : (
            <Input
              className={styles.imageDescriptionInput}
              placeholder={translate(
                `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.image-description.input-placeholder`
              )}
              id="product_name"
              onInputChange={(newValue: string) => {
                setProductName(newValue);
                setInputErrorMessage(null);
              }}
              value={transparentImageDescription}
              testID={`${testID}--product-input`}
            />
          )}
          <div
            className={styles.inputError}
            data-testid={`${testID}--input-error-message`}
          >
            {inputErrorMessage}
          </div>
        </label>
        <UploadImageInstructions />
      </div>
    </div>
  );
}

export default ImageUploadProcess;
