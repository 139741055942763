import {
  Severity,
  SEVERITY_THRESHOLDS,
} from '@/components/creator-vetting-report/constants';

function getSeverityFromFlagScore(flagScore: number): Severity {
  if (flagScore < SEVERITY_THRESHOLDS[Severity.LOW]) {
    return Severity.LOW;
  }
  if (
    flagScore >= SEVERITY_THRESHOLDS[Severity.LOW] &&
    flagScore < SEVERITY_THRESHOLDS[Severity.MEDIUM]
  ) {
    return Severity.MEDIUM;
  }
  return Severity.HIGH;
}

export default getSeverityFromFlagScore;
