import {BrandProfileNote} from '@/types/brandProfileNote';
import api from '../index';

const brandProfileNoteUpdaters = {
  update: async (brandProfileNote: BrandProfileNote) => {
    return api.put(`/brand_profile_notes/${brandProfileNote.id}`, {
      bodyRaw: brandProfileNote.bodyRaw,
      bodyHtml: brandProfileNote.bodyHtml,
    });
  },
};

export default brandProfileNoteUpdaters;
