import React from 'react';
import {Box} from '@mui/material';
import {Label, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './PostModalTitle.module.scss';

function PostModalTitle(props: {
  currentIndex: number;
  totalItems: number;
  categoryName: string;
}) {
  const {currentIndex, totalItems, categoryName} = props;
  return (
    <Box className={styles.postVettingTitleContainer}>
      <Label size="lg" color={designSystemToken('semantic.fg.inverse')}>{`${
        currentIndex + 1
      } ${translate('out-of')} ${totalItems} in`}</Label>
      <Label
        size="lg"
        color={designSystemToken('semantic.fg.inverse')}
        className={styles.categoryName}
      >
        {categoryName}
      </Label>
    </Box>
  );
}

export default PostModalTitle;
