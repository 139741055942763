import urlValidator from '@/utils/validators/url';
import {CampaignBriefDeliverableItem, CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefField from '@/views/campaigns/brief/brief-field-manager/brief-field/BriefField';
import BriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/BriefItem';
import CampaignBriefCaptionGuidelines from '@/views/campaigns/brief/brief-field-manager/brief-field/CampaignBriefCaptionGuidelines';
import CampaignBriefDeliverableItems from '@/views/campaigns/brief/brief-field-manager/brief-field/CampaignBriefDeliverableItems';
import CaptionGuidelinesBriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/CaptionGuidelinesBriefItem';
import ContentAgreementBriefItem, {
  DEFAULT_CONTENT_AGREEMENT_TIME_PERIOD_IN_MONTHS,
} from '@/views/campaigns/brief/brief-field-manager/brief-field/ContentAgreementBriefItem';
import ContentAgreementTerms from '@/views/campaigns/brief/brief-field-manager/brief-field/ContentAgreementTerms';
import ContentAgreementTimePeriodInMonths from '@/views/campaigns/brief/brief-field-manager/brief-field/ContentAgreementTimePeriodInMonths';
import DaysToPostAfterDeliverableFulfilled from '@/views/campaigns/brief/brief-field-manager/brief-field/DaysToPostAfterDeliverableFulfilled';
import DeliverablesBriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/DeliverablesBriefItem';
import HashtagBriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/HashtagBriefItem';
import MinimumChildContentSubmissionCount from '@/views/campaigns/brief/brief-field-manager/brief-field/MinimumChildContentSubmissionCount';
import QuestionsForCreator from '@/views/campaigns/brief/brief-field-manager/brief-field/QuestionsForCreator';
import SwapMatchKeywords from '@/views/campaigns/brief/brief-field-manager/brief-field/SwapMatchKeywords';
import TermsAndConditionsBriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/TermsAndConditionsBriefItem';
import TimelineBriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/TimelineBriefItem';
import {SECTION_ID_MAP} from '@/views/campaigns/brief/components/campaign-brief/sections';
import {
  CREATOR_COMPENSATION_TYPES,
  SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET,
} from '@/views/campaigns/brief/constants';

const TRANSLATION_PREFIX = 'views.campaigns.brief-field-manager';
const TRANSLATION_PREFIX_ERRORS = 'components.campaign-brief.errors';

export const BRIEF_FIELD_TYPE = {
  BRIEF_ITEM: 'BRIEF_ITEM',
  PRIMITIVE: 'PRIMITIVE',
};

export const BRIEF_FIELD_TYPES = [
  {
    key: 'id',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.BASIC_DETAILS,
  },
  {
    key: 'brand',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.BASIC_DETAILS,
  },
  {
    key: 'name',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.BASIC_DETAILS,
    agentKey: 'campaign_name',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !!value,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
    ],
  },
  {
    key: 'imageUrl',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.BASIC_DETAILS,
    agentKey: 'logo_url',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !!value,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.image`,
      },
    ],
  },
  {
    key: 'verticalTags',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.BASIC_DETAILS,
    agentKey: 'campaign_verticals',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !!value?.length,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.vertical-tags`,
      },
    ],
  },
  {
    key: 'descriptionHtml',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.BASIC_DETAILS,
    agentKey: 'about_your_brand_and_campaign',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !!value,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
    ],
  },
  {
    key: 'estimatedBudget',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
  },
  {
    key: 'suggestedCostForCreationsMin',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
    agentKey: 'creator_cost_range',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean =>
          briefFieldManager.fields.creatorCompensationType.value ===
          CREATOR_COMPENSATION_TYPES.RANGE
            ? typeof value === 'number' && value > 0
            : true,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean => {
          if (briefFieldManager.fields.fixedCostForCreations.value) {
            return true;
          }
          const bothValuesAreFilled =
            typeof briefFieldManager.fields.suggestedCostForCreationsMin
              .value === 'number' &&
            typeof briefFieldManager.fields.suggestedCostForCreations.value ===
              'number' &&
            briefFieldManager.fields.suggestedCostForCreationsMin.value !== 0 &&
            briefFieldManager.fields.suggestedCostForCreations.value !== 0;
          return bothValuesAreFilled
            ? value <
                (briefFieldManager.fields.suggestedCostForCreations.value || 0)
            : true;
        },
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.min-larger-than-max`,
      },
    ],
  },
  {
    key: 'suggestedCostForCreations',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
    agentKey: 'creator_cost_range',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean =>
          briefFieldManager.fields.creatorCompensationType.value ===
          CREATOR_COMPENSATION_TYPES.RANGE
            ? typeof value === 'number' && value > 0
            : true,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean => {
          if (briefFieldManager.fields.fixedCostForCreations.value) {
            return true;
          }
          const bothValuesAreFilled =
            typeof briefFieldManager.fields.suggestedCostForCreationsMin
              .value === 'number' &&
            typeof briefFieldManager.fields.suggestedCostForCreations.value ===
              'number' &&
            briefFieldManager.fields.suggestedCostForCreationsMin.value !== 0 &&
            briefFieldManager.fields.suggestedCostForCreations.value !== 0;
          return bothValuesAreFilled
            ? value >
                (briefFieldManager.fields.suggestedCostForCreationsMin.value ||
                  0)
            : true;
        },
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.min-larger-than-max`,
      },
    ],
  },
  {
    key: 'fixedCostForCreations',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
    agentKey: 'creator_cost_range',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean =>
          briefFieldManager.fields.creatorCompensationType.value ===
          CREATOR_COMPENSATION_TYPES.FIXED
            ? typeof value === 'number'
            : true,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean =>
          briefFieldManager.fields.creatorCompensationType.value ===
            CREATOR_COMPENSATION_TYPES.FIXED &&
          !SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET.includes(
            briefFieldManager.fields.subscriptionType.value
          ) &&
          typeof value === 'number'
            ? value > 0
            : true,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.value-must-be-larger-than-zero`,
      },
    ],
  },
  {
    key: 'deliverablesType',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
    agentKey: 'product_fulfillment',
  },
  {
    key: 'estimatedFulfillmentValue',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
    agentKey: 'product_fulfillment',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean =>
          briefFieldManager.fields.deliverablesType.value ? !!value : true,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
    ],
  },
  {
    key: 'network',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.GENERAL,
    agentKey: 'network',
  },
  {
    key: 'unlocksAt',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_DETAILS,
    agentKey: 'creator_followers_count',
  },
  {
    key: 'locksAbove',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_DETAILS,
    agentKey: 'creator_followers_count',
  },
  {
    key: 'preferredCreatorGenders',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_DETAILS,
    agentKey: 'creator_gender',
  },
  {
    key: 'preferredCreatorAgeDetail',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_DETAILS,
    agentKey: 'creator_age_range',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean => !!value.minAge,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ): boolean => {
          const bothValuesAreFilled =
            value.minAge &&
            typeof Number(value.minAge) === 'number' &&
            value.maxAge &&
            typeof Number(value.maxAge) === 'number';

          return bothValuesAreFilled
            ? Number(value.minAge) <= Number(value.maxAge)
            : true;
        },
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.min-age-larger-than-max-age`,
      },
    ],
  },
  {
    key: 'preferredCreatorStates',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_DETAILS,
    agentKey: 'creator_states',
  },
  {
    key: 'requiredFeedPostsCount',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'requiredReelsPostsCount',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'requiredStoryPostsCount',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'videosToUpload',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'photosToUpload',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'mixedMediaToUpload',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'minimumChildContentSubmissionCount',
    Class: MinimumChildContentSubmissionCount,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
  },
  {
    key: 'campaignBriefDeliverableItems',
    Class: CampaignBriefDeliverableItems,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
    agentKey: 'required_deliverables',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !!value.length,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.campaign-paid-gig-deliverable-items`,
      },
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) =>
          value.every(
            (item: CampaignBriefDeliverableItem) => !!item.description
          ),
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.add-a-description`,
      },
    ],
  },
  {
    key: 'swapMatchKeywords',
    Class: SwapMatchKeywords,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
    agentKey: 'campaign_hashtag',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => {
          return (
            (Array.isArray(value) &&
              value.length === 1 &&
              value[0].length > 0) ||
            ['tiktok_v2', null, undefined].includes(
              briefFieldManager.fields.network.value
            )
          );
        },
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.campaign-hashtag`,
      },
    ],
  },
  {
    key: 'campaignBriefCaptionGuidelines',
    Class: CampaignBriefCaptionGuidelines,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.DELIVERABLES,
    agentKey: 'campaign_brief_caption_guidelines',
  },
  {
    key: 'daysToPostAfterDeliverableFulfilled',
    Class: DaysToPostAfterDeliverableFulfilled,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.TIMELINE,
    agentKey: 'days_to_post_after_deliverable_fulfilled',
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !!value,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.value-must-be-larger-than-zero`,
      },
    ],
  },
  {
    key: 'questions',
    Class: QuestionsForCreator,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.ADDITIONAL_INFORMATION,
  },
  {
    key: 'urlToPromote',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.ADDITIONAL_INFORMATION,
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => !value || urlValidator(value),
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.url-to-promote`,
      },
    ],
  },
  {
    key: 'contentAgreementTerms',
    Class: ContentAgreementTerms,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CONTENT_AGREEMENT,
    agentKey: 'content_agreement_terms',
    extractValue: (campaignData: CampaignProps) =>
      campaignData.contentAgreementTerms || [],
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => (value?.length || 0) > 0,
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.select-at-least-one`,
      },
    ],
  },
  {
    key: 'contentAgreementTimePeriodInMonths',
    Class: ContentAgreementTimePeriodInMonths,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CONTENT_AGREEMENT,
    agentKey: 'time_period_months',
    extractValue: (campaignData: CampaignProps) =>
      campaignData.contentAgreementTimePeriodInMonths ||
      DEFAULT_CONTENT_AGREEMENT_TIME_PERIOD_IN_MONTHS,
  },
  {
    key: 'idealCreatorBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.ideal-creator`,
    Class: BriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.CREATOR_DETAILS,
    agentKey: 'ideal_creator',
    weight: 1,
  },
  {
    key: 'contentMandatoriesBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.content-mandatories`,
    Class: BriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.DELIVERABLES,
    agentKey: 'content_mandatories',
    weight: 3,
  },
  {
    key: 'productToFeatureBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.product-to-feature`,
    Class: BriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.DELIVERABLES,
    agentKey: 'product_to_feature',
    weight: 4,
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => {
          return !!value?.contentHtml;
        },
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
    ],
  },
  {
    key: 'postingInstructionsBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.posting-instructions`,
    Class: BriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.DELIVERABLES,
    agentKey: 'posting_instructions',
    weight: 5,
    validators: [
      {
        validator: (
          briefFieldManager: BriefFieldManagerAttributes,
          value: any
        ) => {
          return !!value?.contentHtml;
        },
        errorMessageLocaleKey: `${TRANSLATION_PREFIX_ERRORS}.required-field`,
      },
    ],
  },
  {
    key: 'captionGuidelinesBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.caption-guidelines`,
    Class: CaptionGuidelinesBriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.DELIVERABLES,
    weight: 7,
  },
  {
    key: 'deliverablesBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.deliverables`,
    Class: DeliverablesBriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.DELIVERABLES,
    weight: 2,
  },
  {
    key: 'timelineBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.timeline`,
    Class: TimelineBriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.TIMELINE,
    weight: 8,
  },
  {
    key: 'campaignHashtagBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.campaign-hashtag`,
    Class: HashtagBriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.DELIVERABLES,
    weight: 6,
  },
  {
    key: 'contentAgreementBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.content-agreement`,
    Class: ContentAgreementBriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.CONTENT_AGREEMENT,
    weight: 11,
  },
  {
    key: 'termsAndConditionsBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.terms-and-conditions`,
    Class: TermsAndConditionsBriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.TERMS_AND_CONDITIONS,
    weight: 12,
  },
  {
    key: 'brandStyleGuideBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.brand-style-guide`,
    Class: BriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.ADDITIONAL_INFORMATION,
    weight: 9,
  },
  {
    key: 'eligibilityRequirementsBriefItem',
    titleLocaleKey: `${TRANSLATION_PREFIX}.titles.eligibility-requirements`,
    Class: BriefItem,
    shouldGenerateIfMissing: true,
    type: BRIEF_FIELD_TYPE.BRIEF_ITEM,
    section: SECTION_ID_MAP.ADDITIONAL_INFORMATION,
    weight: 10,
  },
  {
    key: 'isProcessing',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.GENERAL,
    extractValue: (campaignData: CampaignProps) =>
      campaignData.isProcessing || false,
  },
  {
    key: 'isPlaceholder',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.GENERAL,
    extractValue: (campaignData: CampaignProps) =>
      campaignData.isPlaceholder || false,
  },
  {
    key: 'sectionsToHide',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.GENERAL,
    extractValue: (campaignData: CampaignProps) => ({
      followersCount: !campaignData.network,
      postingInstructions: !campaignData.network,
      campaignHashtag: !campaignData.network,
      urlToPromote: !campaignData.network,
    }),
  },
  {
    key: 'creatorCompensationType',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.CREATOR_PAYMENTS,
  },
  {
    key: 'subscriptionType',
    Class: BriefField,
    type: BRIEF_FIELD_TYPE.PRIMITIVE,
    section: SECTION_ID_MAP.GENERAL,
  },
];

export type BriefFieldsKeys = {
  [K in (typeof BRIEF_FIELD_TYPES)[number]['key']]: BriefField | BriefItem;
};
