import {useNavigate} from 'react-router-dom';
import getUrlWithBrandId from '@/utils/getUrlWithBrandId';

// TODO: make improvements
//  https://reactrouter.com/en/main/hooks/use-navigate
function useNavigation() {
  const navigateProxy = useNavigate();

  const navigate = (to: string, params?: any, keepBrandIdIfPresent = true) => {
    let processedTo = to;
    if (keepBrandIdIfPresent) {
      processedTo = getUrlWithBrandId(to);
    }
    const isExternalUrl = /^https?:\/\//i.test(processedTo);
    if (isExternalUrl && !processedTo.startsWith(window.location.origin)) {
      window.location.href = processedTo;
      return null;
    }
    return navigateProxy(processedTo, params);
  };

  const goBack = () => {
    return navigateProxy(-1);
  };

  return {navigate, goBack};
}

export default useNavigation;
