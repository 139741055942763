import React from 'react';
import {designSystemToken} from '@lightricks/react-design-system';

type DividerProps = {
  margin?: number;
  vertical?: boolean;
  height?: number | string;
  color?: string;
};

function Divider({
  margin = 32,
  vertical = false,
  height,
  color = designSystemToken('semantic.bg.neutral-secondary'),
}: DividerProps) {
  const getVerticalStyle = () => {
    if (vertical) {
      return {
        display: 'flex',
        width: 1,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: margin - 8,
        marginRight: margin - 8,
        height: height || '100%',
      };
    }
    return {};
  };
  return (
    <div
      style={{
        height: 1,
        width: '100%',
        backgroundColor: color,
        marginTop: margin,
        marginBottom: margin,
        ...getVerticalStyle(),
      }}
    />
  );
}

export default Divider;
