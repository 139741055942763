import {useMutation} from '@tanstack/react-query';
import creatorsUpdaters from '@/api/updaters/creators';

function useAddToLists() {
  return useMutation({
    mutationKey: ['creators', 'addToLists'],
    mutationFn: creatorsUpdaters.addToLists,
  });
}

export default useAddToLists;
