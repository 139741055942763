import {snakeCase} from 'lodash';
import mapKeysToCase from '@/utils/mapKeysToCase';
import QueryContext from '../QueryContext';
import api from '../index';

export const DEFAULT_IDENTITY_HEALTH_EVALUATION_INCLUDE = [
  'scores',
  'data_sets.sections',
];

const identityFetcher = {
  healthEvaluation: (context: QueryContext) => {
    const {identityId, include} = context.meta || {};

    return api.get(`/identities/${identityId}/health`, {
      params: {
        include: include || DEFAULT_IDENTITY_HEALTH_EVALUATION_INCLUDE,
      },
    });
  },
  posts: (context: QueryContext) => {
    const {identityId, include, ...rest} = context.meta || {};
    return api.get(`/identities/${identityId}/posts`, {
      params: {
        include,
        ...mapKeysToCase(rest, snakeCase),
      },
    });
  },
  paginatedPosts: (context: QueryContext) => {
    const {pageParam} = context;
    const {identityId, include, ...rest} = context.meta || {};
    return api.get(`/identities/${identityId}/posts`, {
      params: {
        include,
        page: pageParam,
        ...mapKeysToCase(rest, snakeCase),
      },
    });
  },
};

export default identityFetcher;
