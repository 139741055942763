import React from 'react';
import {Box, Card, CardContent} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';
import Skeleton from '@/components/skeleton';

const ANIMATION_TYPE = 'pulse';
const CARD_HEIGHT = 496;

function CreatorCardSkeleton() {
  const skeletonBackgroundColor = designSystemToken('semantic.bg.tertiary');
  return (
    <Card
      sx={{
        height: CARD_HEIGHT,
        borderRadius: 4,
        marginInline: '12px',
      }}
    >
      <Box display="flex" justifyContent="center" gap={0.1} ml={-1} mr={-1}>
        {/* Images */}
        <Skeleton
          animation={ANIMATION_TYPE}
          backgroundColor={skeletonBackgroundColor}
          variant="rectangular"
          width="100%"
          height={200}
        />
      </Box>
      <CardContent
        sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{'.MuiSkeleton-root': {border: '1px solid white'}}}
          mt={-5}
        >
          {/* Avatar */}
          <Skeleton
            animation={ANIMATION_TYPE}
            backgroundColor={skeletonBackgroundColor}
            variant="circular"
            width={80}
            height={80}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          mt={1.5}
        >
          {/* Creator name */}
          <Skeleton
            variant="rectangular"
            animation={ANIMATION_TYPE}
            backgroundColor={skeletonBackgroundColor}
            width={134}
            height={14}
          />
          {/* Location */}
          <Skeleton
            variant="rectangular"
            animation={ANIMATION_TYPE}
            backgroundColor={skeletonBackgroundColor}
            width={108}
            height={14}
          />
        </Box>
        <Box textAlign="center" mt={4}>
          {/* Categories */}
          <Skeleton
            variant="rectangular"
            animation={ANIMATION_TYPE}
            backgroundColor={skeletonBackgroundColor}
            width={182}
            height={17}
          />
        </Box>
        <Box textAlign="center" mt={9}>
          <Skeleton
            variant="rounded"
            borderRadius={999}
            animation={ANIMATION_TYPE}
            backgroundColor={skeletonBackgroundColor}
            width={272}
            height={40}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default CreatorCardSkeleton;
