export type ConditionsAndTermsSectionSubSection = {
  key: string;
  collapsible: boolean;
  weight: number;
};
export const BASE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS: ConditionsAndTermsSectionSubSection[] =
  [
    {key: 'content-mandatories', collapsible: false, weight: 1},
    {key: 'age-availability', collapsible: false, weight: 2},
    {
      key: 'product-to-feature-and-compensation-details',
      collapsible: true,
      weight: 6,
    },
    {key: 'content-approval', collapsible: true, weight: 7},
    {key: 'timeline-overview', collapsible: true, weight: 8},
    {key: 'FTC-guidelines', collapsible: true, weight: 9},
  ];

export const TIKTOK_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS: ConditionsAndTermsSectionSubSection[] =
  [
    ...BASE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
    {key: 'caption-guidelines', collapsible: true, weight: 3},
    {key: 'posting-instructions-tiktok', collapsible: true, weight: 4},
    {key: 'TT-music', collapsible: true, weight: 5},
  ];

export const INSTAGRAM_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS: ConditionsAndTermsSectionSubSection[] =
  [
    ...BASE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
    {key: 'caption-guidelines', collapsible: true, weight: 3},
    {key: 'posting-instructions-instagram', collapsible: true, weight: 5},
  ];

export const YOUTUBE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS: ConditionsAndTermsSectionSubSection[] =
  [
    ...BASE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
    {key: 'caption-guidelines', collapsible: true, weight: 3},
  ];

export const TERMS_AND_CONDITIONS_BY_NETWORK: {
  [key: string]: ConditionsAndTermsSectionSubSection[];
} = {
  tiktok_v2: TIKTOK_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
  instagram: INSTAGRAM_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
  youtube: YOUTUBE_TERMS_AND_CONDITIONS_SECTION_SUB_SECTIONS,
};
