import React from 'react';
import {Stack} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';
import getEngagementRateString from '@/utils/getEngagementRateString';
import getFollowersCountString from '@/utils/getFollowersCountString';
import translate from '@/utils/translate';
import {SocialNetworkInfo} from '@/types/socials';
import SocialStat from '@/components/creator-card/SocialStat';

type SocialInfoProps = {
  leadPlatformInfo: SocialNetworkInfo | undefined;
};

function CreatorSocialInfo({leadPlatformInfo}: SocialInfoProps) {
  return (
    <Stack direction="row" spacing={2}>
      <SocialStat
        title={translate(`components.creator-card.followers`)}
        value={leadPlatformInfo?.followersCount}
        titleColor={designSystemToken('semantic.fg.primary')}
        valueColor={designSystemToken('semantic.fg.primary')}
        formatingFunc={(value: number) => getFollowersCountString(value)}
      />
      <SocialStat
        title={translate(`components.creator-card.eng-rate`)}
        value={leadPlatformInfo?.engagementRate}
        titleColor={designSystemToken('semantic.fg.primary')}
        valueColor={designSystemToken('semantic.fg.primary')}
        formatingFunc={getEngagementRateString}
      />
    </Stack>
  );
}

export default CreatorSocialInfo;
