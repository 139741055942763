import capsuleAiApi from '@/api/capsuleApi';

const capsuleAiUpdaters = {
  uploadImage: async (formData: FormData) => {
    return capsuleAiApi.post(`/api/upload`, formData);
  },
  uploadReferenceImage: async (formData: FormData) => {
    return capsuleAiApi.post(`/api/upload_reference_image`, formData);
  },
  createUser: async (token: any) =>
    capsuleAiApi.post(`/api/users`, {params: {uuid: token}}),
  replaceBackground: async (params: any) =>
    capsuleAiApi.post(`/api/bgreplace`, null, {params}),
  replaceBackgroundWithReferenceImage: async (params: any) =>
    capsuleAiApi.post(`/api/bgreplace_with_reference_image`, null, {params}),
  prompt: async (params: any) =>
    capsuleAiApi.post(`/api/prompt`, null, {params}),
  createSession: async (params: any) =>
    capsuleAiApi.post(`/api/sessions`, null, {params}),
  updateImageDescription: async (params: any) =>
    capsuleAiApi.put(`/api/image_description`, null, {params}),
  updateIsSavedToLibrary: async (params: any) =>
    capsuleAiApi.put(`/api/save_to_library`, null, {params}),
};

export default capsuleAiUpdaters;
