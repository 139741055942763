import dayjs from 'dayjs';
import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Label,
} from '@lightricks/react-design-system';
import getPlatformFromIdentityProvider from '@/utils/getIPlatformFromIdentityProvider';
import translate from '@/utils/translate';
import {US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR} from '@/config/timeFormats';
import {SocialNetwork} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import styles from './ScannedPlatforms.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.scanned-platforms';

interface Platform extends SocialNetwork {
  trackAction?: (actionName: string, actionValue: string) => void;
}

interface ScannedPlatformsProps {
  platforms: Platform[];
  trackAction: (actionName: string, actionValue: string) => void;
}

const BACKGROUND_CHECK = {
  provider: 'google',
  handle: 'Found on Google',
  profilePageUrl: '',
  unlinkedAt: null,
};

const SOCIAL_NETWORK_ICON_SIZE = 24;

function ScannedPlatformIcon({id}: {id: string}) {
  return (
    <div className={styles.scannedPlatformIconContainer}>
      <SocialNetworkIcon
        name={id}
        width={SOCIAL_NETWORK_ICON_SIZE}
        height={SOCIAL_NETWORK_ICON_SIZE}
      />
    </div>
  );
}

function ScannedPlatform(props: Platform) {
  const {provider, handle, unlinkedAt, profilePageUrl, trackAction} = props;

  const getDisconnectDate = (date: Date) => {
    return (
      <Label size="sm" color={designSystemToken('semantic.fg.secondary')}>
        {translate(`${TRANSLATION_PREFIX}.creator-disconnected`, {
          date: dayjs(date).format(
            US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR
          ),
        })}
      </Label>
    );
  };

  const getHandleLabel = () => {
    const label = (
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {handle}
      </Body>
    );
    if (profilePageUrl) {
      return (
        <Link
          keepBrandIdIfPresent={false}
          to={profilePageUrl}
          target="_blank"
          onClick={() =>
            trackAction?.('Open social network page', profilePageUrl)
          }
        >
          {label}
        </Link>
      );
    }
    return label;
  };

  return (
    <div
      className={`${styles.scannedPlatform} ${
        unlinkedAt ? styles.alignTop : ''
      }`}
    >
      <div className={styles.platformIcon}>
        <ScannedPlatformIcon id={provider} />
      </div>
      <div className={styles.platformInfo}>
        <div className={`${styles.handle} ${profilePageUrl ? styles.url : ''}`}>
          {getHandleLabel()}
        </div>
        <Conditional condition={!!unlinkedAt}>
          {getDisconnectDate(unlinkedAt as Date)}
        </Conditional>
      </div>
    </div>
  );
}

function ScannedPlatforms(props: ScannedPlatformsProps) {
  const {platforms, trackAction} = props;
  const platformsWithBackgroundCheck = platforms.concat(BACKGROUND_CHECK);
  return (
    <div
      className={styles.scannedPlatformsContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.SCANNED_PLATFORMS}
    >
      <Headline size="xs">{translate(`${TRANSLATION_PREFIX}.title`)}</Headline>
      <div className={styles.platformsContainer}>
        {platformsWithBackgroundCheck.map((platform) => (
          <ScannedPlatform
            key={platform.provider}
            {...platform}
            provider={getPlatformFromIdentityProvider(platform.provider)}
            trackAction={trackAction}
          />
        ))}
      </div>
    </div>
  );
}

export default ScannedPlatforms;
