import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {
  Headline,
  Body,
  Label,
  designSystemToken,
  Banner,
} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import Sentry from '@/services/sentry/Sentry';
import {
  flowNames,
  reasons,
  resultFlows,
  screenNames,
} from '@/lib/delta/deltaConstants.js';
import signupPixelEvents from '@/lib/marketing-events/signupEvents';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import authRequests from '@/api/auth';
import Link from '@/components/link';
import OtpVerificationModal from '@/components/otp-verification-modal';
import SignupForm from '@/components/signup-form';
import {PopularPaysLogoFull} from '@/components/unusual-icons';
import useCreateAgency from '@/hooks/mutations/use-create-agency';
import useIsMobile from '@/hooks/use-is-mobile';
import useLtxToken from '@/hooks/use-ltx-token';
import useNavigation from '@/hooks/use-navigation';
import useParams from '@/hooks/use-params';
import useToken from '@/hooks/use-token';
import styles from './Signup.module.scss';

const INVALID_BRAND_EMAIL_ERROR_MESSAGE =
  'A business email address is required to register';

export type SignUpProps = {
  testID?: string;
};

export type SignUpForm = {
  email: string;
  brandName: string;
  firstName: string;
  lastName: string;
  isTermsChecked: boolean;
  requestedPlanId: string;
  isTestBrandChecked: boolean;
};

const SIGNUP_MOBILE_WIDTH = 1100;

const MARKETING_EXPERIMENT_GOOGLE_CAMPAIGN_IDS = [
  20350428894, 20601153252, 20607237560,
];

const MARKETING_EXPERIMENT_SUBSCRIPTION_URL =
  'https://payments.popularpays.com/purchase/v1/popularpays_super/after_signup/how-trial-works';

function Signup(props: SignUpProps) {
  const {testID = 'signup'} = props;
  const location = useLocation();
  const initialEmail = new URLSearchParams(location.search).get('email') || '';
  const requestedPlanId =
    new URLSearchParams(location.search).get('rpid') || '';
  const {utm_campaign, utm_wpflow, ...restParams} = useParams();
  const [isMarketingExperiment, setIsMarketingExperiment] = useState(false);
  const [enableAutoRedirectToHP, setEnableAutoRedirectToHP] = useState(true);
  const [form, setForm] = useState<SignUpForm>({
    email: initialEmail,
    firstName: '',
    lastName: '',
    brandName: '',
    isTermsChecked: false,
    requestedPlanId,
    isTestBrandChecked: false,
  });
  const [isSubmittingLoading, setIsSubmittingLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    title: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigation = useNavigation();
  const {setLtxToken} = useLtxToken();
  const {setToken} = useToken();
  const isTablet = useIsMobile(SIGNUP_MOBILE_WIDTH);
  const isMobile = useIsMobile();
  const createAgency = useCreateAgency();
  const {isAuthenticated} = useToken(true);

  useEffect(() => {
    const signupFlow = AnalyticsService.createFlow(flowNames.AUTH.NEW_SIGNUP);
    AnalyticsService.startFlow(flowNames.AUTH.NEW_SIGNUP);
    AnalyticsService.screenPresented(screenNames.AUTH.NEW_SIGNUP, '', {
      trigger_flow_id: signupFlow.flow_id,
      trigger_flow_name: signupFlow.flow_name,
    });
  }, []);

  useEffect(() => {
    setIsMarketingExperiment(utm_wpflow === 'true');
  }, [utm_wpflow]);

  // The enableAutoRedirectToHP flag is used to prevent automatic redirection to the home page during marketing experiments when a user creates an account.
  // This ensures that the home page is not displayed to the user before redirecting them to the web payments subscription page.
  useEffect(() => {
    if (isAuthenticated && enableAutoRedirectToHP) {
      navigation.navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isMarketingExperiment) {
      setEnableAutoRedirectToHP(false);
    }
  }, [isMarketingExperiment]);

  const onCreateUser = async (
    token: string,
    stepDateEvent: Record<string, unknown>
  ) => {
    setError({title: '', message: ''});
    setIsSubmittingLoading(true);
    const registerFormData = {
      email: form.email,
      firstName: form.firstName,
      lastName: form.lastName,
      name: form.brandName,
      jwtToken: token,
      isTermsChecked: form.isTermsChecked,
      requestedPlanId: form.requestedPlanId,
      isTestBrandChecked: form.isTestBrandChecked,
    };
    try {
      const data = await createAgency.mutateAsync(registerFormData);
      const sessionData = await authRequests.login({
        email: registerFormData.email,
        password: registerFormData.jwtToken,
      });

      const tokenSaved = await setToken(sessionData);
      if (!tokenSaved) {
        AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
          ...stepDateEvent,
          reason: reasons.AUTH.FAILURE,
          error: 'Token not saved',
        });
        return;
      }
      setLtxToken(registerFormData.jwtToken);

      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.AUTH.SUCCESS,
        error: null,
      });
      AnalyticsService.screenDismissed(screenNames.AUTH.NEW_SIGNUP, '');
      AnalyticsService.endFlow(flowNames.AUTH.NEW_SIGNUP, {
        result: resultFlows.GENERIC.SUCCESS,
      });
      await AnalyticsService.dischargeEventBatch();

      if (isMarketingExperiment) {
        const restParamsStr = Object.entries({...restParams})
          .map(([key, value]) => {
            if (key === '*') return '';
            return `&${key}=${value}`;
          })
          .join('');
        navigation.navigate(
          `${MARKETING_EXPERIMENT_SUBSCRIPTION_URL}?utm_campaign=${utm_campaign}&utm_wpflow=true${restParamsStr}`,
          null,
          false
        );
      } else {
        navigation.navigate('/');
      }

      await signupPixelEvents();
    } catch (e: any) {
      const errorMessage =
        e?.errors?.map((err: any) => err?.detail || '').join(', ') ||
        e?.message ||
        translate('views.auth.signup.general-error-message');
      const title = translate('views.auth.signup.general-error');

      setError({title, message: errorMessage});
      setIsModalOpen(false);
      setIsSubmittingLoading(false);

      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.AUTH.FAILURE,
        error: errorMessage,
      });
      Sentry.captureException(e, {
        title: 'Create Agency Sign Up Error - new signup',
        email: form.email,
        errorMessage,
      });
    }
  };

  const onNavigateToLogin = () => {
    AnalyticsService.screenDismissed(
      screenNames.AUTH.NEW_SIGNUP,
      screenNames.LOGIN.MAIN_LOGIN_SCREEN
    );
    AnalyticsService.endFlow(flowNames.AUTH.NEW_SIGNUP, {
      result: resultFlows.GENERIC.CANCELLED,
    });
  };

  let title: string;
  if (initialEmail) {
    title = translate('views.auth.signup.alt-title');
  } else {
    title = translate(
      isMarketingExperiment
        ? 'views.auth.signup.marketing-experiment-title'
        : 'views.auth.signup.title'
    );
  }

  return (
    <div className={styles.container} data-testid={testID}>
      <OtpVerificationModal
        isOpen={isModalOpen}
        email={form.email}
        setIsModalOpen={setIsModalOpen}
        authenticateCallback={onCreateUser}
      />
      {isTablet ? null : (
        <div className={styles.imageContainer}>
          <img src="/assets/images/welcome.png" alt="" />
        </div>
      )}
      <div className={styles.formContainer}>
        {error.message && (
          <Banner
            title={error.title}
            onActionClick={() => setError({title: '', message: ''})}
          >
            {error.message}
          </Banner>
        )}
        <div className={styles.formBox}>
          {isTablet && (
            <div>
              <PopularPaysLogoFull />
            </div>
          )}
          <div className={styles.header}>
            <Headline
              size={isMobile ? 'lg' : 'xl'}
              className={styles.formTitle}
            >
              {title}
            </Headline>
            <Body
              size={isMobile ? 'md' : 'xl'}
              color={designSystemToken('semantic.fg.secondary')}
            >
              {!initialEmail && translate('views.auth.signup.subtitle')}
            </Body>
          </div>
          <SignupForm
            form={form}
            setForm={setForm}
            setIsModalOpen={setIsModalOpen}
            isLoading={isSubmittingLoading}
            setIsLoading={setIsSubmittingLoading}
            setGeneralError={setError}
            isMobile={isTablet}
            disableEmail={!!initialEmail}
          />
          <div className={styles.loginLink}>
            <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
              {translate('views.auth.signup.login-link-text')}
            </Body>
            <Link
              to={ROUTES.LOGIN}
              onClick={onNavigateToLogin}
              keepBrandIdIfPresent={false}
            >
              <Label size="sm" color={designSystemToken('semantic.fg.brand')}>
                {translate('views.auth.signup.login-link-button')}
              </Label>
            </Link>
          </div>
          <Body
            className={styles.termsFootnote}
            size="sm"
            color={designSystemToken('semantic.fg.secondary')}
          >
            {translate('views.auth.signup.terms')}
          </Body>
        </div>
      </div>
      {isTablet ? (
        <div className={styles.imageContainer}>
          <img src="/assets/images/welcome-mobile.png" alt="signup welcome" />
        </div>
      ) : null}
    </div>
  );
}

export default Signup;
