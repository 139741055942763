import React, {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  useMemo,
} from 'react';
import {AlertColor} from '@mui/material';
import {AlertMode} from '@lightricks/react-design-system/src/components/banner/BannerProps';

export type BannerSeverity = AlertColor;

export type BannerMode = AlertMode;

interface BannerStateDefinition {
  showBanner: boolean;
  title: string;
  message: string;
  severity: BannerSeverity;
  mode: BannerMode;
  className: string;
  icon: ReactNode | undefined;
}

const initialState: BannerStateDefinition = {
  showBanner: false,
  title: '',
  message: '',
  severity: 'success',
  mode: 'light',
  className: '',
  icon: undefined,
};

type Action =
  | {
      type: 'SET_BANNER';
      title: string;
      message: string;
      severity?: BannerSeverity;
      mode?: BannerMode;
      className?: string;
      icon?: ReactNode;
    }
  | {type: 'CLEAR_BANNER'};

const bannerReducer = (
  state: BannerStateDefinition,
  action: Action
): BannerStateDefinition => {
  switch (action.type) {
    case 'SET_BANNER':
      return {
        ...state,
        showBanner: true,
        title: action.title,
        message: action.message,
        severity: action.severity || 'success',
        mode: action.mode || 'light',
        className: action.className || '',
        icon: action?.icon,
      };
    case 'CLEAR_BANNER':
      return {
        ...state,
        showBanner: false,
        title: '',
        message: '',
        severity: 'success',
        mode: 'light',
        className: '',
        icon: undefined,
      };
    default:
      return state;
  }
};

interface StoreBannerContextType {
  state: BannerStateDefinition;
  dispatch: React.Dispatch<Action>;
}

const BannerContext = createContext<StoreBannerContextType | undefined>(
  undefined
);

export const useBannerStore = (): StoreBannerContextType => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error('useBannerStore must be used within a BannerProvider');
  }
  return context;
};

export const getBannerData = (state: BannerStateDefinition) => ({
  ...state,
});

interface StoreProviderProps {
  children: ReactNode;
}

export function BannerProvider({children}: StoreProviderProps) {
  const [state, dispatch] = useReducer(bannerReducer, initialState);

  const contextValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return (
    <BannerContext.Provider value={contextValue}>
      {children}
    </BannerContext.Provider>
  );
}
