import React, {useMemo} from 'react';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import Select from '@/components/select';
import {FilterProps} from '../FilterProps';
import styles from './SingleSelectionOptionsDropDown.module.scss';

interface SingleSelectionOptionsDropDownProps extends FilterProps {
  options: BaseFilterOption[];
  selectedOption?: BaseFilterOption;
  onChange: (option: BaseFilterOption) => void;
  placeholder?: string;
}

function SingleSelectionOptionsDropDown(
  props: SingleSelectionOptionsDropDownProps
) {
  const {options, selectedOption, onChange, placeholder} = props;

  const mappedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: translate(option.localeLabelKey),
      })),
    [options]
  );

  return (
    <Select
      containerClassName={styles.container}
      immediateOnChange
      options={mappedOptions}
      selectedValueIds={selectedOption ? [selectedOption.id] : []}
      renderSelectedLabel={(selectedOptions) => {
        if (selectedOptions.length > 0) {
          return selectedOptions[0].label;
        }
        if (placeholder) {
          return (
            <Body
              size="lg"
              color={designSystemToken('semantic.fg.inverse-secondary')}
            >
              {placeholder}
            </Body>
          );
        }

        return '';
      }}
      onChange={(value: string | string[]) => {
        const option = options.find((_option) => _option.id === value);
        if (option) {
          onChange(option);
        }
      }}
      onClear={() => selectedOption && onChange(selectedOption)}
    />
  );
}

export default SingleSelectionOptionsDropDown;
