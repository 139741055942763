const REDIRECT_PARAM = 'creatorProfileIdRedirect';
const PARAM = 'creatorProfileId';

function getCreatorProfileModalUrl(
  creatorProfileId: string,
  withRedirectOnMount = true
): string {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.append(
    withRedirectOnMount ? REDIRECT_PARAM : PARAM,
    creatorProfileId
  );
  return `${window.location.search}${searchParams.toString()}`;
}

export default getCreatorProfileModalUrl;
