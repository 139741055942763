import React, {useMemo} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {uuidRegex} from '@/config/constants';
import LaunchCampaignModal from '@/views/campaigns/brief/components/launch-campaign-modal';
import useCampaignQuery from '@/hooks/queries/use-campaign-query';
import useBrandId from '@/hooks/use-brand-id';

const campaignRouteRegex = new RegExp(`/campaigns/${uuidRegex}`, 'i');
const campaignIdRegex = new RegExp(`/campaigns/(${uuidRegex})`, 'i');

function CampaignIsLiveModal() {
  const brandId = useBrandId();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const isCampaignRoute = useMemo(
    () => campaignRouteRegex.test(location.pathname),
    [location.pathname]
  );

  const campaignId = useMemo(
    () =>
      isCampaignRoute
        ? location.pathname.match(campaignIdRegex)?.[1] || ''
        : '',
    [location.pathname, isCampaignRoute]
  );

  const {campaign} = useCampaignQuery({
    campaignId,
    enabled: !!campaignId,
    include: ['social_network'],
  });

  if (
    isCampaignRoute &&
    campaign.loaded &&
    searchParams.get('showCampaignIsLiveModal') === 'true'
  ) {
    return (
      <LaunchCampaignModal
        showCampaignIsLive
        campaignData={campaign}
        brandId={brandId}
        onSubmit={() => Promise.resolve()}
        onClose={() => {
          searchParams.delete('showCampaignIsLiveModal');
          setSearchParams(searchParams);
        }}
      />
    );
  }

  return null;
}

export default CampaignIsLiveModal;
