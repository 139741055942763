import * as SentryBrowser from '@sentry/browser';
import * as SentryDriver from '@sentry/react';
import {getEnv} from '@/config/environment';
import Sentry from '../services/sentry/Sentry';

const DEBUG_MODE = false;
Sentry.setDriver(SentryDriver, DEBUG_MODE);
Sentry.init({
  dsn: getEnv().VITE_SENTRY_DSN,
  environment: getEnv().MODE,
  integrations: [new SentryBrowser.BrowserTracing()],
  tracesSampleRate: 0.2,
  initialScope: {
    tags: {platform: 'React-AWA'},
  },
});
