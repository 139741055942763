import translate from '@/utils/translate';
import {CampaignBriefDeliverableItem, CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefItem from './BriefItem';

class DeliverablesBriefItem extends BriefItem {
  #campaignBriefDeliverableItems: CampaignBriefDeliverableItem[];

  #minimumChildContentSubmissionCount: number;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(
      briefFieldManager,
      campaignData,
      key,
      index,
      id,
      title,
      contentHtml,
      highlighted,
      clientBriefTemplateId
    );
    this.#campaignBriefDeliverableItems =
      campaignData.campaignBriefDeliverableItems;
    this.#minimumChildContentSubmissionCount =
      campaignData.minimumChildContentSubmissionCount || 0;
  }

  set campaignBriefDeliverableItems(value: CampaignBriefDeliverableItem[]) {
    this.#campaignBriefDeliverableItems = value;
  }

  set minimumChildContentSubmissionCount(value: number) {
    this.#minimumChildContentSubmissionCount = value;
  }

  get toContentHtml() {
    if (!this.#campaignBriefDeliverableItems) {
      return this.contentHtml;
    }

    return `
        <div>
        ${this.#campaignBriefDeliverableItems
          .map(
            (deliverable: CampaignBriefDeliverableItem) =>
              `<div>
                 <h3>
                    ${translate(
                      `components.campaign-brief.sections.deliverables.deliverable.deliverable-type-${deliverable.itemType}`,
                      {
                        count: this.#minimumChildContentSubmissionCount || 0,
                      }
                    )}
                 </h3>
                <span>${deliverable.description}</span>
            </div>`
          )
          .join('')}
        </div>
    `;
  }
}

export default DeliverablesBriefItem;
