import {useMemo} from 'react';
import {CampaignListItem} from '@/hooks/queries/use-brand-campaigns-query/useBrandCampaignsQuery';

export interface SimplifiedCampaign {
  id: string;
  label: string;
  [key: string]: any;
}

const useSortedCampaigns = (brandCampaigns: CampaignListItem[]) => {
  const sortedCampaigns = useMemo(() => {
    if (!brandCampaigns) return [];

    return brandCampaigns
      .map((singleCampaign: CampaignListItem) => ({
        id: singleCampaign.id,
        label: singleCampaign.name,
        submittedOn: singleCampaign?.underReviewSubmissionInfo?.submittedOn,
      }))
      .sort((a, b) => {
        if (!a.submittedOn) return 1;
        if (!b.submittedOn) return -1;
        return (
          new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime()
        );
      })
      .map(({id, label}: {id: string; label: string}) => ({id, label}));
  }, [brandCampaigns]);

  return sortedCampaigns;
};

export default useSortedCampaigns;
