import axiosCapsuleAi from '@/utils/axiosCapsuleAi';

/**
 * API convention is based on Axios client
 * https://axios-http.com/docs/api_intro
 */
const capsuleAiApi = {
  get: <T>(path: string, config: any = {}) => axiosCapsuleAi.get(path, config),
  put: <T>(path: string, data: any, config: any = {}) =>
    axiosCapsuleAi.put(path, data, config),
  post: <T>(path: string, data: any, config: any = {}) =>
    axiosCapsuleAi.post(path, data, config),
  delete: <T>(path: string, config: any = {}) =>
    axiosCapsuleAi.delete(path, config),
};

export default capsuleAiApi;
