import React, {useEffect, useState} from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import ImageViewer from '@/views/ai-content-generator/components/image-viewer';
import {
  getsavedImagesToLibrary,
  getSelectedImageId,
  getSelectedImageUrl,
  ImageSavedItemProps,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './ImageGalleryView.module.scss';

interface ImageItemProps {
  id: string | number;
  isSavedToLibrary: boolean;
  imageUrl: string;
}

export type Direction = 'left' | 'right';

type ImageViewProps = {
  images: ImageItemProps[];
  onActiveIndexChange: (index: number, direction: Direction) => void;
  customArrowsContainerStyle?: React.CSSProperties;
  onImageClose?: () => void;
};

function ImageGalleryView(props: ImageViewProps) {
  const {
    images,
    onActiveIndexChange,
    customArrowsContainerStyle,
    onImageClose,
  } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const {state} = useContentGenerationStore();

  const savedImagesToLibrary: ImageSavedItemProps[] =
    getsavedImagesToLibrary(state);
  useEffect(() => {
    const selectedImageIndex = images.findIndex(
      (image) => image.imageUrl === getSelectedImageUrl(state)
    );
    setActiveIndex(selectedImageIndex);
  }, [state.selectedImageUrl]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === 'ArrowLeft') {
        onArrowsPress('left');
      } else if (e.key === 'ArrowRight') {
        onArrowsPress('right');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex, images]);

  const onArrowsPress = (direction: Direction) => {
    let newActiveIndex = activeIndex;
    if (direction === 'right') {
      if (newActiveIndex === images.length - 1) {
        return;
      }
      newActiveIndex += 1;
    } else {
      if (newActiveIndex === 0) {
        return;
      }
      newActiveIndex -= 1;
    }
    setActiveIndex(newActiveIndex);
    onActiveIndexChange(newActiveIndex, direction);
  };

  const renderArrowButton = (direction: Direction, isDisable: boolean) => {
    return (
      <Button
        appearance="neutral"
        mode="elevated"
        size="large"
        disabled={isDisable}
        icon={
          <Icon
            size="large"
            appearance="neutral"
            name={
              direction === 'left'
                ? 'Actions-Arrow-Back-Small'
                : 'Actions-Arrow-Forward-Small'
            }
          />
        }
        onClick={() => onArrowsPress(direction)}
      />
    );
  };

  return (
    <div className={styles.container}>
      <ImageViewer
        controls
        url={getSelectedImageUrl(state)}
        isSavedToLibrary={
          images.filter((image) => image.id === getSelectedImageId(state))[0]
            ?.isSavedToLibrary ||
          savedImagesToLibrary.filter(
            (image) => image.id === getSelectedImageId(state)
          )[0]?.isSavedToLibrary
        }
        onClose={onImageClose}
      />
      <div
        className={`${styles.arrowsContainer} ${customArrowsContainerStyle}`}
      >
        {renderArrowButton('left', activeIndex === 0)}
        {renderArrowButton('right', activeIndex === images.length - 1)}
      </div>
    </div>
  );
}

export default ImageGalleryView;
