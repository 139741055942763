import {useMemo} from 'react';
import {getSupportedNetworks} from '@/utils/utils';

function useSortedSocialNetworks<T extends {provider: string}>(
  socialNetworks: T[]
) {
  return useMemo(() => {
    const socialNetworkOrder = getSupportedNetworks();
    return socialNetworks.slice().sort((a, b) => {
      return (
        socialNetworkOrder.indexOf(a.provider) -
        socialNetworkOrder.indexOf(b.provider)
      );
    });
  }, [socialNetworks]);
}

export default useSortedSocialNetworks;
