import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {
  dismissedReasons,
  reasons,
  screenNames,
} from '@/lib/delta/deltaConstants.js';
import momentSecondsSince from '@/utils/momentSecondsSince';
import {ROUTES} from '@/config/routesConstants';
import {ModalState} from '@/stores/modalSubscriptionStore';

export type FlowStartedEventData = {
  isNewFlowStarted: boolean;
  reason?: string;
};

export type FlowEndedEventData = {
  isFlowEnded: boolean;
  reason?: string;
  currentScreenName: string;
  isPricingPage?: boolean;
  error?: string;
  subscription_id?: string;
};
export function getScreenName(state: ModalState, paymentType?: string) {
  if (state === 'payment') {
    return screenNames.SUBSCRIPTION_MODAL.CREDIT_DETAILS;
  }
  if (state === 'result') {
    return screenNames.SUBSCRIPTION_MODAL.ALL_SET;
  }
  if (state === 'entry') {
    if (paymentType === 'invoice') {
      return screenNames.SUBSCRIPTION_MODAL.CONTACT_SALES;
    }
    return screenNames.SUBSCRIPTION_MODAL.UPGRADE_OFFER;
  }
  return '';
}

export function isPricingPage({pathname}: {pathname: string}) {
  return pathname === ROUTES.PRICING;
}

export function handleFlowStartedEvent(eventData: FlowStartedEventData) {
  if (eventData.isNewFlowStarted) {
    const subscriptionFlow = AnalyticsService.getFlow('subscription');
    if (subscriptionFlow) {
      AnalyticsService.dispatchEvent(eventNames.SUBSCRIPTION_FLOW_STARTED, {
        flow_id: subscriptionFlow.flow_id,
        flow_name: '',
        reason: eventData?.reason || '',
      });
    }
  }
}

export function handleFlowEndedEvent(eventData: FlowEndedEventData) {
  const subscriptionFlow = AnalyticsService.getFlow('subscription');
  if (!subscriptionFlow) {
    return;
  }

  if (
    eventData.reason !== reasons.SUBSCRIPTION_MODAL.SUCCESS &&
    eventData.reason !== reasons.SUBSCRIPTION_MODAL.SEE_ALL_PLANES
  ) {
    handleScreenDismissedEvent({
      screenName: eventData.currentScreenName,
      dismissedReason: dismissedReasons.SUBSCRIPTION_MODAL.CLOSED,
    });
  }

  if (eventData?.isPricingPage) {
    handleScreenPresentedEvent({
      screenName: screenNames.PRICING.PRICING_PAGE,
    });
  }

  if (eventData.isFlowEnded) {
    AnalyticsService.dispatchEvent(eventNames.SUBSCRIPTION_FLOW_ENDED, {
      flow_id: subscriptionFlow.flow_id,
      flow_name: '',
      reason: eventData?.reason || '',
      error: '',
      subscription_id: '',
    });
  }
}

export function handleScreenPresentedEvent({screenName}: {screenName: string}) {
  const subscriptionFlow = AnalyticsService.getFlow('subscription');
  if (subscriptionFlow) {
    AnalyticsService.screenPresented(
      window.location.pathname,
      window.location.pathname
    );
    AnalyticsService.dispatchEvent(eventNames.SUBSCRIPTION_SCREEN_PRESENTED, {
      flow_id: subscriptionFlow.flow_id,
      screen_presentation_id:
        AnalyticsService.getActiveScreenPresented().screen_presentation_id,
      presented_reason: '',
      subscription_screen_name: screenName,
      presented_options: null,
    });
  }
}
export function handleScreenDismissedEvent({
  screenName,
  dismissedReason,
}: {
  screenName: string;
  dismissedReason: string;
}) {
  const subscriptionFlow = AnalyticsService.getFlow('subscription');
  if (subscriptionFlow) {
    AnalyticsService.screenDismissed(
      window.location.pathname,
      window.location.pathname
    );
    const activeScreenPresented = AnalyticsService.getActiveScreenPresented();
    AnalyticsService.dispatchEvent(eventNames.SUBSCRIPTION_SCREEN_DISMISSED, {
      flow_id: subscriptionFlow.flow_id,
      screen_presentation_id: activeScreenPresented.screen_presentation_id,
      session_duration_since_presented:
        activeScreenPresented.presented_at_timestamp
          ? momentSecondsSince(
              activeScreenPresented.presented_at_timestamp
            ).toString()
          : '',
      subscription_screen_name: screenName,
      dismissed_reason: dismissedReason,
      presented_reason: '',
    });
  }
}

export function handleSubscriptionProductPresented({
  productId,
  productPrice,
  currency,
}: {
  productId: string;
  productPrice: number;
  currency: string;
}) {
  const subscriptionFlow = AnalyticsService.getFlow('subscription');
  if (!subscriptionFlow) {
    return;
  }
  const activeScreenPresented = AnalyticsService.getActiveScreenPresented();

  AnalyticsService.dispatchEvent(eventNames.SUBSCRIPTION_PRODUCT_PRESENTED, {
    flow_id: subscriptionFlow.flow_id,
    screen_presentation_id: activeScreenPresented.screen_presentation_id,
    product_id: productId,
    product_price: productPrice,
    currency,
  });
}

export function handleSubscriptionButtonPressedEvent({
  screenName,
  buttonName,
}: {
  screenName: string;
  buttonName: string;
}) {
  const subscriptionFlow = AnalyticsService.getFlow('subscription');
  if (subscriptionFlow) {
    const activeScreenPresented = AnalyticsService.getActiveScreenPresented();

    AnalyticsService.dispatchEvent(eventNames.SUBSCRIPTION_BUTTON_PRESSED, {
      button_name: buttonName,
      screen_name: screenName,
      tab: '',
      flow_id: subscriptionFlow.flow_id,
      flow_name: '',
      triggered_flow_id: '',
      triggered_flow_name: '',
      campaign_id: '',
      creator_id: '',
      screen_presentation_id: activeScreenPresented.screen_presentation_id,
    });
  }
}
