import api from '../index';

type ExternalProfileUpdaterArgs = {
  create: {
    brandId: string;
    handle: string;
    network: string;
    email: string;
    displayName: string;
    profileImageUrl?: string;
    modashId: string;
  };
};

const externalProfileUpdaters = {
  create: async (args: ExternalProfileUpdaterArgs['create']) => {
    const {
      brandId,
      handle,
      network,
      email,
      displayName,
      profileImageUrl,
      modashId,
    } = args;

    return api.post<{profileId: string}>(
      `/external_profiles`,
      {
        handle,
        network,
        email,
        display_name: displayName,
        profile_image_url: profileImageUrl,
        modash_id: modashId,
      },
      {
        params: {
          brand_id: brandId,
        },
      }
    );
  },
};

export default externalProfileUpdaters;
