import React from 'react';
import translate from '@/utils/translate';
import {ErrorMessages} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {SectionProps} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {DEFAULT_SAVE_CAMPAIGN_DELAY} from '@/views/campaigns/brief/constants';
import IncrementableField from '@/components/incrementable-field';
import BriefSubSection from '../../components/brief-sub-section';
import styles from './Timeline.module.scss';

const TRANSLATION_PREFIX = 'components.campaign-brief.sections.timeline';

type ContentCreationDurationProps = {
  editable?: boolean;
  daysToPostAfterDeliverableFulfilled?: number | null;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  errors?: ErrorMessages;
};

function ContentCreationDuration({
  editable,
  daysToPostAfterDeliverableFulfilled,
  updateBriefForm,
  errors,
}: ContentCreationDurationProps) {
  if (
    daysToPostAfterDeliverableFulfilled === undefined ||
    daysToPostAfterDeliverableFulfilled === null
  ) {
    return null;
  }

  return (
    <BriefSubSection
      disabled={!editable}
      title={translate(`${TRANSLATION_PREFIX}.content-creation-duration`)}
    >
      <ValidatedField errors={errors?.daysToPostAfterDeliverableFulfilled}>
        <div className={styles.container}>
          <IncrementableField
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.TIMELINE__DAYS_TO_POST_AFTER_DELIVERABLE_FULFILLED
            }
            disabled={!editable}
            value={daysToPostAfterDeliverableFulfilled}
            label={translate(`${TRANSLATION_PREFIX}.label`, {
              count: daysToPostAfterDeliverableFulfilled,
            })}
            onChange={(value) =>
              updateBriefForm?.(
                'daysToPostAfterDeliverableFulfilled',
                value,
                'daysToPostAfterDeliverableFulfilled',
                DEFAULT_SAVE_CAMPAIGN_DELAY
              )
            }
          />
        </div>
      </ValidatedField>
    </BriefSubSection>
  );
}

function Timeline(props: SectionProps) {
  const {updateBriefForm, editable, errors, briefFieldManager} = props;

  return (
    <div className={styles.container}>
      <ContentCreationDuration
        editable={editable}
        errors={errors}
        daysToPostAfterDeliverableFulfilled={
          briefFieldManager.fields.daysToPostAfterDeliverableFulfilled.value
        }
        updateBriefForm={updateBriefForm}
      />
    </div>
  );
}

export default Timeline;
