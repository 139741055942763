import React, {useMemo} from 'react';
import Autocomplete from '@/components/autocomplete';
import {Option} from '@/components/autocomplete/Autocomplete';
import useParams from '@/hooks/use-params';
import styles from './CampaignFilter.module.scss';

export type CampaignFilterProps = {
  testID?: string;
  options: Option[];
  withAllOption?: boolean;
  onChange: (event: any, selectedOption: any | null) => void;
};

const ALL_OPTION = {id: 'all', label: 'All Campaigns'};
const NOT_SELECTED_OPTION = {id: '', label: ''};
function CampaignFilter(props: CampaignFilterProps) {
  const {campaign} = useParams();

  const {
    testID = 'campaign-filter',
    options,
    withAllOption = true,
    onChange,
  } = props;

  const campaignOptions = useMemo(() => {
    if (withAllOption) {
      return [ALL_OPTION, ...options];
    }
    return options;
  }, [options, withAllOption]);

  const selectedOption = useMemo(() => {
    if (campaign) {
      const campaignOption = campaignOptions.find(
        (option: Option) =>
          option.id === String(campaign) || option.label === String(campaign)
      );
      if (campaignOption) {
        return campaignOption;
      }
    }
    if (withAllOption) {
      return ALL_OPTION;
    }
    return NOT_SELECTED_OPTION;
  }, [campaign, options]);

  return (
    <div className={styles.container} data-testid={testID}>
      <Autocomplete
        testID={`${testID}--autocomplete`}
        disableClearable
        adaptiveWidth
        options={campaignOptions}
        showLabel={false}
        customPopperStyle={styles.popper}
        value={selectedOption.label}
        selectedOptionId={selectedOption.id}
        onChange={onChange}
        slotProps={{popper: {placement: 'bottom-start'}}}
        filterSelectedOptions={false}
        sx={{
          '.MuiFormControl-root': {
            background: '#f4f4f4',
            paddingInline: 1.5,
            borderRadius: 15,
          },
        }}
        listboxProps={{'data-testid': `${testID}--list-box`}}
      />
    </div>
  );
}

export default CampaignFilter;
