import React from 'react';
import {
  Chip as ChipMui,
  ChipProps as ChipMuiProps,
  styled,
} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';

const StyledChip = styled(ChipMui)`
  border-radius: 24px;
  border: 1px solid ${() => designSystemToken('semantic.bg.neutral-inverse')};
  padding: 8px 8px 8px 4px;
  background-color: transparent;

  .MuiChip-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .MuiChip-icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin: 0;
  }

  .MuiChip-deleteIcon {
    margin: 0;
  }
`;

interface ChipProps extends ChipMuiProps {
  testID?: string;
  handleDelete?: () => void;
  handleClick?: () => void;
  chipRef?: React.RefObject<HTMLDivElement> | null;
}

function Chip(props: ChipProps) {
  const {
    testID = 'chip',
    icon,
    label,
    handleClick,
    handleDelete,
    chipRef,
    ...rest
  } = props;

  return (
    <StyledChip
      data-testid={testID}
      ref={chipRef}
      icon={icon}
      label={label}
      onClick={handleClick}
      onDelete={handleDelete}
      {...rest}
    />
  );
}

export default Chip;
