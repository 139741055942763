import type {QueryKey} from '@tanstack/query-core';
import {UseQueryOptions} from '@tanstack/react-query';
import objectHash from 'object-hash';
import {useEffect, useState} from 'react';
import useReactQuery from '@/hooks/use-react-query';

function useDebouncedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    delay: number;
  }
) {
  const [debouncedOptions, setDebouncedOptions] = useState(options);
  const {delay, ...debouncedQueryOptions} = debouncedOptions;

  const optionsHash = objectHash(options);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedOptions(options);
    }, delay);

    return () => clearTimeout(timeout);
  }, [optionsHash]);

  return useReactQuery(debouncedQueryOptions);
}

export default useDebouncedQuery;
