import api from '../index';

const aiContentUpdaters = {
  createAiContent: async (aiContent: {
    url: string;
    kind: string;
    merchantId: string;
    capsuleAiContentId: string | number;
  }) => {
    return api.post(`/ai_contents`, {
      url: aiContent.url,
      kind: aiContent.kind,
      merchantId: aiContent.merchantId,
      capsuleAiContentId: aiContent.capsuleAiContentId,
    });
  },
};

export default aiContentUpdaters;
