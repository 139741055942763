import {TooltipItem} from 'chart.js';
import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import formattedPercentage from '@/utils/formattedPrecentage';
import getTickValues from '@/utils/getTickValues';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_DAY_SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import {TiktokSocialHealthChartProps} from '@/components/creator-profile/components/tiktok-social-health/tiktokSocialHealthChart';
import {
  ADDITIONAL_OPTIONS_BASE,
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';

const TRANSLATION_PREFIX =
  'components.creator-profile.tiktok-social-health.charts.views-per-follower';

function ViewsPerFollowerChart(props: TiktokSocialHealthChartProps) {
  const {audiences} = props;
  const sortedAudiences = useMemo(
    () => sortBy(audiences, (audience) => dayjs(audience.createdAt).unix()),
    [audiences]
  );

  const viewsPerFollowerData = sortedAudiences.map(
    ({viewsToFollowersCount}) => viewsToFollowersCount
  );
  const dates = sortedAudiences.map(({createdAt}) => createdAt);

  let tickValues = getTickValues(viewsPerFollowerData);

  if (tickValues.min === 0 && tickValues.max === 0) {
    tickValues = {
      min: -1,
      max: 1,
    };
  }

  const chart = (
    <LineChart
      datasets={[
        {
          label: translate(
            `${TRANSLATION_PREFIX}.average-views-to-follower-ratio`
          ),
          fill: false,
          data: viewsPerFollowerData,
          yAxisID: 'y',
          backgroundColor: LEGACY_COLORS.BLUE,
          borderColor: LEGACY_COLORS.BLUE,
          borderWidth: 2,
          cubicInterpolationMode: 'monotone',
        },
      ]}
      additionalOptions={{
        ...ADDITIONAL_OPTIONS_BASE,
        scales: {
          x: getDefaultScaleX(dates),
          y: getDefaultScaleY(
            translate(`${TRANSLATION_PREFIX}.average-views-to-follower-ratio`),
            tickValues,
            (label) => formattedPercentage(label as number, '0%'),
            false
          ),
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            intersect: false,
            callbacks: {
              label(tooltipItem: TooltipItem<'line'>) {
                const {dataset} = tooltipItem;
                let label = dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += formattedPercentage(tooltipItem.raw as number, '0.0%');
                return label;
              },
              title([tooltipItem]) {
                return dayjs(dates[tooltipItem.dataIndex]).format(
                  SHORT_DAY_SHORT_MONTH_YEAR_FORMAT
                );
              },
            },
          },
        },
      }}
      labels={dates}
    />
  );

  return (
    <ChartLayout
      title={translate(`${TRANSLATION_PREFIX}.views-to-follower-ratio`)}
      chart={chart}
      showEmptyState={audiences.length === 0}
    />
  );
}

export default ViewsPerFollowerChart;
