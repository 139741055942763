import {useEffect} from 'react';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';

function useSyncDeltaBaseAttributes(isAuthenticated: boolean) {
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({
    brandId,
    enabled: isAuthenticated,
  });

  useEffect(() => {
    AnalyticsService.setRegularAttribute('brand_id', brandId);
  }, [brandId]);

  useEffect(() => {
    AnalyticsService.setRegularAttribute(
      'subscription_plan',
      subscription.subscriptionType
    );
  }, [subscription]);

  return null;
}

export default useSyncDeltaBaseAttributes;
