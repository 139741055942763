import React from 'react';
import styles from './Payment.module.scss';

type PaymentSourceExistsProps = {
  paymentSource: {billingSource: {summary: string; expiration: string}};
};

function MockAdyenForm({paymentSource}: PaymentSourceExistsProps) {
  return (
    <div className="adyen-checkout__loading-input__form">
      <MockAdyenFormInput
        label="Card Number"
        value={`•••• •••• •••• ${paymentSource.billingSource.summary}`}
      />
      <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
        <MockAdyenFormInput
          label="Expiration Date"
          value={paymentSource.billingSource.expiration}
        />
        <MockAdyenFormInput label="CVV" value="•••" />
      </div>
    </div>
  );
}

function MockAdyenFormInput({label, value}: {label: string; value: string}) {
  return (
    <div className="adyen-checkout__field adyen-checkout__field--valid">
      <label className="adyen-checkout__label">
        <span className="adyen-checkout__label__text">{label}</span>
      </label>
      <div className="adyen-checkout__input-wrapper">
        <input
          disabled
          className="adyen-checkout__input adyen-checkout__input--text adyen-checkout__input--disabled"
          type="text"
          value={value}
        />
      </div>
      <span className="adyen-checkout__error-text" />
    </div>
  );
}

export default MockAdyenForm;
