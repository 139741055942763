import {useEffect, useState} from 'react';
import Sentry from '@/services/sentry/Sentry';
import {Feature} from '@/types/feature';
import {Lever} from '@/types/lever';
import {Subscription} from '@/types/subscription';
import EmberIframe from '@/components/ember-iframe';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';

function leverCheck(lever: Lever) {
  return lever.usedCount < lever.thresholdCount;
}

function featureCheck(
  subscription: Subscription,
  featureName: Feature['name']
) {
  const foundFeature = subscription.plan.features.find(
    (feature: Feature) => feature.name === featureName
  );

  if (!foundFeature) return false;

  switch (foundFeature.featureType) {
    case 'feature_lever': {
      const matchesLeverType = (lever: Lever) => lever.type === featureName;
      const subscriptionFeatureLever =
        subscription.levers.find(matchesLeverType);

      if (!subscriptionFeatureLever) {
        Sentry.captureMessage(
          `Failed to find lever ${featureName} for subscription ${subscription.id}`
        );
        return false;
      }
      return leverCheck(subscriptionFeatureLever);
    }
    case 'feature_toggle':
      return true;
    default:
      Sentry.captureMessage(
        `Failed to detect if feature ${featureName} is a toggle or lever`
      );
      return false;
  }
}

export function useEmberPaidFeatureCheck() {
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const [checkQueue, setCheckQueue] = useState<Feature['name'][]>([]);

  useEffect(() => {
    return EmberIframe.listen('checkPaidFeature', (featureName) => {
      setCheckQueue((prevQueue) => [...prevQueue, featureName]);
    });
  }, []);

  useEffect(() => {
    if (!checkQueue.length || !subscription.loaded || !subscription.plan) {
      return;
    }

    const currentCheck = checkQueue[0];

    EmberIframe.send('checkPaidFeature', {
      featureName: currentCheck,
      value: featureCheck(subscription, currentCheck),
    });

    setCheckQueue((prevQueue) => prevQueue.slice(1));
  }, [checkQueue, subscription]);
}

export function usePaidFeatureCheck(featureName: Feature['name']) {
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});

  return featureCheck(subscription, featureName);
}
