import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import parseJwt from '@lightricks/fortress-web-client/dist/helpers/jwt-parser';
import Fortress from '@/services/fortress/Fortress';
import queryClient from '@/utils/reactQueryClient';
import {ROUTES, UNAUTHENTICATED_ROUTES} from '@/config/routesConstants';
import authRequests from '@/api/auth';
import useNavigation from '@/hooks/use-navigation';
import useToken from '@/hooks/use-token';
import {redirectAfterAuthActions} from '@/stores/redirectAfterAuthStore';

function useRevalidateSession() {
  const {isAuthenticated, initialized, setToken, resetToken} = useToken(true);
  const [isRevalidatingSession, setIsRevalidatingSession] =
    React.useState(false);
  const location = useLocation();
  const navigation = useNavigation();
  const isRevalidatingSessionRef = useRef(false);

  const clearToken = async () => {
    Fortress.clearCookieTokens();
    await resetToken();
    queryClient.clear();
  };

  const setRedirectUrlAfterAuth = () => {
    redirectAfterAuthActions.setRedirectUrlAfterAuth(
      `${location.pathname}${location.search}`
    );
  };

  useEffect(() => {
    (async () => {
      if (
        initialized &&
        !isRevalidatingSession &&
        !isRevalidatingSessionRef.current
      ) {
        setIsRevalidatingSession(true);
        isRevalidatingSessionRef.current = true;
        try {
          const fortressToken = await Fortress.getAccessToken();
          if (fortressToken && !isAuthenticated) {
            const {email} = parseJwt(fortressToken);
            const authToken = await authRequests.login({
              email,
              password: fortressToken,
            });
            await setToken(authToken);
          } else if (!fortressToken && isAuthenticated) {
            await clearToken();
            setRedirectUrlAfterAuth();
            navigation.navigate(ROUTES.LOGIN, undefined, false);
          } else {
            const isUnauthenticatedRoute = !!UNAUTHENTICATED_ROUTES.find(
              (route) => new RegExp(route, 'i').test(location.pathname)
            );
            if (!fortressToken && !isAuthenticated && !isUnauthenticatedRoute) {
              await clearToken();
              setRedirectUrlAfterAuth();
              navigation.navigate(ROUTES.LOGIN, undefined, false);
            }
          }
        } catch (e: any) {
          const urlParams = new URLSearchParams(window.location.search);
          const isSignUpReactWithEmail =
            location.pathname.includes(ROUTES.SIGN_UP_REACT) &&
            urlParams.has('email');

          // This is a special case where a user is prepaid using web payment flow and he's coming to the sign up page
          // with the auth cookies although its user is not exists on our end so he needs to sign up in our end as well.
          // in that case, we don't want to redirect him to the login but keep him in the sign up page but clear the cookies.
          if (!isSignUpReactWithEmail) {
            await clearToken();
            setRedirectUrlAfterAuth();
            navigation.navigate(ROUTES.LOGIN, undefined, false);
          } else if (e.response?.status === 401) {
            clearToken();
            setRedirectUrlAfterAuth();
            // We reload the page because otherwise we are being redirected back to login after signing up
            window.location.reload();
          }
        }
        isRevalidatingSessionRef.current = false;
        setIsRevalidatingSession(false);
      }
    })();
  }, [initialized, isAuthenticated, location.pathname]);

  return isRevalidatingSession;
}

export default useRevalidateSession;
