import numeral from 'numeral';

export default function getFollowersCountString(
  followersCount: number | undefined,
  fallbackString = '-'
): string {
  if (followersCount === undefined) {
    return fallbackString;
  }
  let format = '0.0a';

  if (followersCount < 1000) format = '0,0';
  if (followersCount > 10000) format = '0a';
  if (followersCount > 1000000) format = '0.00a';

  return numeral(followersCount).format(format).toUpperCase();
}
