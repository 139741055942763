import React from 'react';
import {Input} from '@lightricks/react-design-system';
import {useContentGenerationStore} from '@/contexts/ContentGenerationStore';
import styles from './PromptInput.module.scss';

type InputTypeProp = 'positive' | 'negative';

type PromptInputProps = {
  promptValue: string;
  updaterType: 'UPDATE_USER_PROMPT' | 'UPDATE_USER_NEGATIVE_PROMPT';
  startIcon: React.ReactNode;
  placeholder: string;
  maxLength: number;
  inputType: InputTypeProp;
  setActiveInput: (inputType: InputTypeProp | '') => void;
  inputRef?: React.Ref<HTMLInputElement | null>;
};

function PromptInput(props: PromptInputProps) {
  const {
    promptValue,
    updaterType,
    startIcon,
    placeholder,
    maxLength,
    inputType,
    setActiveInput,
    inputRef,
  } = props;

  const {dispatch} = useContentGenerationStore();

  const onInputChange = (inputValue: string) => {
    const numberOfLineBreaks = (inputValue.match(/\n/g) || []).length;
    if (numberOfLineBreaks >= 1) {
      return;
    }

    dispatch({
      type: updaterType,
      promptValue: inputValue,
    });
  };

  return (
    <div className={styles.inputContainer}>
      <Input
        inputRef={inputRef}
        multiline
        value={promptValue}
        onInputChange={onInputChange}
        startIcon={startIcon}
        placeholder={placeholder}
        className={styles.promptInput}
        onFocus={() => setActiveInput(inputType)}
        inputProps={{
          maxLength,
          value: promptValue,
          onBlur: () => setActiveInput(''),
        }}
      />
    </div>
  );
}

export default PromptInput;
