import isUuidFormat from '@/utils/isUuidFormat';

/**
 * return brandId by prioritization:
 * destinationUrlBrandId has higher priority
 * urlParamsBrandId is the fallback
 *
 * if neither is in a valid uuid format => return null
 * @param destinationUrlBrandId
 * @param urlParamsBrandId
 */
function getPrioritizedValidBrandId(
  destinationUrlBrandId?: string,
  urlParamsBrandId?: string
) {
  if (destinationUrlBrandId && isUuidFormat(destinationUrlBrandId)) {
    return destinationUrlBrandId;
  }
  if (urlParamsBrandId && isUuidFormat(urlParamsBrandId)) {
    return urlParamsBrandId;
  }

  return null;
}

export default getPrioritizedValidBrandId;
