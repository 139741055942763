import {v4 as uuid} from 'uuid';
import {
  WizardFormData,
  CampaignProps,
  RequiredDeliverableCounts,
} from '@/types/campaign';

export default function getEmptyDeliverableItemsFromWizardForm(
  campaignBriefData: CampaignProps,
  wizardFormData?: WizardFormData | null
) {
  if (!wizardFormData) {
    return [];
  }

  if (campaignBriefData.campaignBriefDeliverableItems.length) {
    return [];
  }

  const deliverableFillArrayMapper: {
    [key in keyof RequiredDeliverableCounts]: string;
  } = {
    story_post: 'story_post',
    feed_post_photo: 'feed_post_photo',
    reel_post: 'reel_post',
    tiktok_video: 'tiktok_video',
    youtube_short_form_video: 'youtube_short_form_video',
    youtube_long_form_video: 'youtube_long_form_video',
    youtube_short: 'youtube_short',
    content_standard_photo: 'content_standard_photo',
    content_standard_video: 'content_standard_video',
    content_premium_photo: 'content_premium_photo',
    content_premium_video: 'content_premium_video',
  };

  const deliverableTypesStack = Object.keys(deliverableFillArrayMapper)
    .map((deliverable) => {
      const deliverableKey = deliverable as keyof RequiredDeliverableCounts;
      return Array(
        wizardFormData.required_deliverables?.[deliverableKey] || 0
      ).fill(deliverableFillArrayMapper[deliverableKey]);
    })
    .flatMap((deliverableKey) => deliverableKey);

  return deliverableTypesStack.map((deliverableType, index) => ({
    id: uuid(),
    itemType: deliverableType,
    description: '',
    weight: index + 1,
  }));
}
