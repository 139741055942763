import axios from 'axios';
import {partial, uniq} from 'lodash';
import Fortress from '@/services/fortress/Fortress';

// A lightweight client to contact the Griffin payment API: https://github.com/Lightricks/griffin
// Most requests are proxied via PPSuite but this client can be used for quick read requests.

export const GRIFFIN_REQUIRED_PARAMS = {
  app: 'com.lightricks.popular-pays.brands',
  plt: 'w',
  pltv: 0,
  cv: 1,
  cvc: 0,
};

export const GRIFFIN_BASE_URL =
  'https://api.griffin-ww-prd.lightricks.com:443/';

export const PAYMENT_CAPTURE_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const griffinApiRequest = async (
  path: string,
  params?: object
): Promise<any> => {
  const url = new URL(path, GRIFFIN_BASE_URL);

  Object.entries({...params, ...GRIFFIN_REQUIRED_PARAMS}).forEach(
    ([key, value]) => {
      url.searchParams.append(key, value.toString());
    }
  );

  url.searchParams.sort();

  try {
    const token = await Fortress.getAccessToken();
    return await axios.get(url.toString(), {
      headers: {
        'x-lightricks-auth-token': token,
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    console.error(e);
  }
  return null;
};

const Griffin = {
  // https://github.com/Lightricks/griffin/blob/develop/docs/api/market/adyencc/get_payment_methods.md
  getPaymentMethods: partial(
    griffinApiRequest,
    'market/adyencc/payment-methods'
  ),

  // https://github.com/Lightricks/griffin/blob/develop/docs/api/cashier/redemption/get.md
  getRedemptionById: (redemptionId: string) =>
    griffinApiRequest(`cashier/redemption/${redemptionId}`),

  // https://github.com/Lightricks/griffin/blob/develop/docs/api/market/get_payment_source.md
  getPaymentSourceById: (paymentSourceId: string, params: object) =>
    griffinApiRequest(`/market/payment-source/id/${paymentSourceId}`, params),

  // https://github.com/Lightricks/griffin/blob/develop/docs/api/market/get_invoices_by_payment_id.md
  getInvoice: (paymentId: string) =>
    griffinApiRequest(`/market/invoices/${paymentId}`),

  // https://github.com/Lightricks/griffin/blob/develop/docs/api/market/adyencc/get_quotes.md
  getQuotes: (quoteId: string) =>
    griffinApiRequest(`/market/quotes`, {id: quoteId}),

  // https://github.com/Lightricks/griffin/blob/develop/docs/api/cashier/redemption/list.md
  listRedemptions: () =>
    griffinApiRequest(`/cashier/redemptions`, {onlyMatches: 1}),

  listAllPayments: async () => {
    const {redemptions} = await Griffin.listRedemptions();
    const paymentSourceIds = redemptions.map(
      (redemption: any) => redemption.paymentSourceId
    );
    const paymentSources = await Promise.all(
      uniq(paymentSourceIds).map((paymentSourceId: any) =>
        Griffin.getPaymentSourceById(paymentSourceId, {includePaymentsInfo: 1})
      )
    );

    return paymentSources
      .map((paymentSource: any) => paymentSource.payments)
      .flat();
  },
};

export default Griffin;
