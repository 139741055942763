function getImageDimensionsFromUrl(
  url: string
): Promise<{width: number; height: number}> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({width: img.width, height: img.height});
    };
    img.onerror = reject;
    img.src = url;
  });
}

export default getImageDimensionsFromUrl;
