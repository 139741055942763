import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';
import {Paper, Grid} from '@mui/material';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import {
  NUMERIC_DAY_MONTH_YEAR_FORMAT,
  SHORT_DAY_FORMAT,
} from '@/config/timeFormats';
import {NavigationAction, DateRange} from '../../types';
import {
  chunks,
  getDaysInMonth,
  isStartOfRange,
  isEndOfRange,
  inDateRange,
  isRangeSameDay,
  isStartOfWeek,
  isEndOfWeek,
  isStartOfMonth,
  isEndOfMonth,
} from '../../utils';
import Day from '../day';
import Header from '../header';

dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  hoverDay?: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
}

function Month(props: MonthProps) {
  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    setValue: setDate,
    minDate,
    maxDate,
    hoverDay,
    navState,
  } = props;

  const weekStartsOn = 0; // Adjust if needed
  const WEEK_DAYS = [...Array(7)].map((_, i) =>
    dayjs()
      .weekday((i + weekStartsOn) % 7)
      .format(SHORT_DAY_FORMAT)
  );
  const [back, forward] = navState;

  return (
    <Paper square elevation={0} sx={{width: 290}}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() =>
            handlers.onMonthNavigate(marker, NavigationAction.Previous)
          }
          onClickNext={() =>
            handlers.onMonthNavigate(marker, NavigationAction.Next)
          }
          minDate={minDate}
          maxDate={maxDate}
        />

        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            marginTop: '10px',
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
        >
          {WEEK_DAYS.map((day) => (
            <Body
              size="md"
              key={day}
              color={designSystemToken('semantic.fg.secondary')}
            >
              {day.charAt(0)}
            </Body>
          ))}
        </Grid>

        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          gap="2px"
          sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: '15px',
            marginBottom: '20px',
          }}
        >
          {chunks(getDaysInMonth(date), 7).map((week, dayIndex) => (
            <Grid
              key={`${week[dayIndex].toDateString()}`}
              container
              direction="row"
              justifyContent="center"
            >
              {week.map((day) => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isWeekStart = isStartOfWeek(day);
                const isWeekEnd = isEndOfWeek(day);
                const isMonthStart = isStartOfMonth(day);
                const isMonthEnd = isEndOfMonth(day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted =
                  inDateRange(dateRange, day) || helpers.inHoverRange(day);
                const isHighlightedEnd =
                  highlighted && dayjs(day).diff(dayjs(hoverDay)) === 0;

                return (
                  <Day
                    testID={`day--${dayjs(day).format(
                      NUMERIC_DAY_MONTH_YEAR_FORMAT
                    )}`}
                    key={dayjs(day).format(NUMERIC_DAY_MONTH_YEAR_FORMAT)}
                    filled={isStart || isEnd}
                    outlined={dayjs(day).isToday()}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={
                      !dayjs(date).isSame(day, 'month') ||
                      !dayjs(day).isBetween(minDate, maxDate, 'day', '[]')
                    }
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    startOfSubRange={isWeekStart || isMonthStart}
                    endOfSubRange={isWeekEnd || isMonthEnd}
                    isHighlightedEnd={isHighlightedEnd}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={dayjs(day).date()}
                  />
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Month;
