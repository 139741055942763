import React, {useRef} from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import ImageUploadProcess from '@/views/ai-content-generator/components/image-upload-process';
import {ChildRef} from '@/views/ai-content-generator/components/image-upload-process/ImageUploadProcess';
import {
  useContentGenerationStore,
  getTransparentRemoteFile,
  getProductName,
  getSessionId,
} from '@/contexts/ContentGenerationStore';
import styles from './UploadImagePopupContent.module.scss';

function UploadImagePopupContent() {
  const {state, dispatch} = useContentGenerationStore();
  const {transparentImagePath: transparentRemoteFile, transparentImageId} =
    getTransparentRemoteFile(state);

  const handleCloseButtonClick = () => {
    dispatch({
      type: 'UPDATE_SHOW_UPLOAD_MODAL',
      showUploadModal: false,
    });
  };

  const childComponentRef = useRef<ChildRef | null>(null);

  const handleContinueClick = () => {
    if (childComponentRef.current) {
      // Call the submit function in the child component
      childComponentRef.current.handleSubmit();
    }
  };

  return (
    <div className={styles.uploadModal}>
      <div className={styles.uploadModalCloseButtonContainer}>
        <Button
          appearance="neutral"
          mode="plain"
          size="small"
          className={styles.uploadModalCloseButton}
          onClick={handleCloseButtonClick}
          icon={
            <Icon
              size="large"
              appearance="neutral"
              name="Actions-Close-Small"
            />
          }
        />
      </div>
      <div className={styles.uploadSection}>
        <ImageUploadProcess
          imageUploadProcessRef={childComponentRef}
          transparentImageFetched={
            !!transparentRemoteFile && !!getProductName(state)
          }
          transparentImageUrl={transparentRemoteFile}
          transparentImageId={transparentImageId}
          transparentImageDescription={getProductName(state)}
          transparentImageSessionId={getSessionId(state)}
          source="ai_studio_replace_modal"
        />
      </div>
      <div className={styles.uploadModalContinueButtonContainer}>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          onClick={handleContinueClick}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
export default UploadImagePopupContent;
