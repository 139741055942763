import {useCallback} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';

type RecruitmentActionFlowStartedEventParams = {
  action_name: string;
  screen_name: string;
  recruitment_search_id: string;
  creator_id: string;
  flow_name?: string;
  source?: string;
};

type RecruitmentActionFlowEndedEventParams = {
  action_id: string;
  screen_name: string;
  recruitment_search_id: string;
  creator_id: string;
  reason: string;
  flow_name?: string;
  campaign_id?: string;
  source?: string;
};

function useTrackRecruitmentActionFlowEvent() {
  const started = useCallback(
    ({
      action_name,
      screen_name,
      recruitment_search_id,
      creator_id,
      flow_name,
      source = '',
    }: RecruitmentActionFlowStartedEventParams) => {
      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );
      AnalyticsService.dispatchEvent(
        eventNames.RECRUITMENT_ACTION_FLOW_STARTED,
        {
          flow_id: recruitmentSearchFlow.flow_id,
          screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
          action_name,
          screen_name,
          recruitment_portfolio_presentation_id: '',
          recruitment_search_id,
          creator_id,
          flow_name: flow_name || recruitmentSearchFlow.flow_name,
          campaign_id: '',
          source:
            source || AnalyticsService.getActiveScreenPresented()?.source || '',
        }
      );
    },
    []
  );

  const ended = useCallback(
    ({
      action_id,
      screen_name,
      recruitment_search_id,
      creator_id,
      reason,
      flow_name,
      campaign_id = '',
      source = '',
    }: RecruitmentActionFlowEndedEventParams) => {
      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );
      AnalyticsService.dispatchEvent(eventNames.RECRUITMENT_ACTION_FLOW_ENDED, {
        flow_id: recruitmentSearchFlow.flow_id,
        screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
        action_id,
        screen_name,
        recruitment_portfolio_presentation_id: '',
        recruitment_search_id,
        creator_id,
        flow_name: flow_name || recruitmentSearchFlow.flow_name,
        campaign_id: campaign_id || '',
        reason,
        source:
          source || AnalyticsService.getActiveScreenPresented()?.source || '',
      });
    },
    []
  );

  return {started, ended};
}

export default useTrackRecruitmentActionFlowEvent;
