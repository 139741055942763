import QueryContext from '../QueryContext';
import api from '../index';

const brandProfileNoteFetchers = {
  note: (context: QueryContext) =>
    api.get(`/brand_profile_note`, {
      params: {
        brand_id: context.meta?.brandId,
        profile_id: context.meta?.profileId,
      },
    }),
};

export default brandProfileNoteFetchers;
