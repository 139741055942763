import React, {useEffect} from 'react';
import useIsMobile from '@/hooks/use-is-mobile';
import useEmberIframeStyleStore from '@/stores/emberIframeStyleStore';

function useSetEmberIframeStyles(
  emberIframeRef: React.RefObject<HTMLIFrameElement>,
  iframeLoaded: boolean,
  location: {pathname: string},
  additionalHeaderHeight = 0
) {
  const isMobile = useIsMobile();

  const emberIframeStyleState = useEmberIframeStyleStore();
  const {headerHeight, menuHeight, sidebarWidth} = emberIframeStyleState;

  const headerHeightIncludingAdditional = headerHeight + additionalHeaderHeight;

  useEffect(() => {
    const emberDocument = emberIframeRef.current?.contentDocument;
    const emberApplicationElement =
      emberIframeRef.current?.contentDocument?.getElementsByClassName(
        'ember-application'
      )?.[0] as HTMLBodyElement;
    const protectedElement =
      emberIframeRef.current?.contentDocument?.getElementsByClassName(
        'protected'
      )?.[0] as HTMLDivElement;

    document?.documentElement?.style?.setProperty(
      '--brand-header-height',
      `${headerHeightIncludingAdditional}px`
    );
    document?.documentElement?.style?.setProperty(
      '--brand-menu-height',
      `${menuHeight}px`
    );
    document?.documentElement?.style?.setProperty(
      '--brand-sidenav-width',
      `${sidebarWidth}px`
    );

    if (emberDocument) {
      emberDocument?.documentElement?.style?.setProperty(
        '--iframe-padding-top',
        `${headerHeightIncludingAdditional}px`
      );
      emberDocument?.documentElement?.style?.setProperty(
        '--iframe-padding-left',
        `${sidebarWidth}px`
      );
    }
    const iframeOutsideElementsHeight = isMobile
      ? menuHeight + headerHeightIncludingAdditional
      : headerHeightIncludingAdditional;

    if (protectedElement) {
      protectedElement.style.paddingTop = `${headerHeightIncludingAdditional}px`;
      protectedElement.style.height = `calc(100% - ${iframeOutsideElementsHeight}px)`;
      emberApplicationElement.style.paddingTop = '0px';
    } else if (emberApplicationElement) {
      emberApplicationElement.style.paddingTop = `${headerHeightIncludingAdditional}px`;
    }
  }, [
    emberIframeStyleState,
    additionalHeaderHeight,
    iframeLoaded,
    location,
    isMobile,
  ]);
}

export default useSetEmberIframeStyles;
