import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {
  Button,
  Label,
  designSystemToken,
} from '@lightricks/react-design-system';

export type ButtonWithNumberProps = {
  testID?: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  number?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

function ButtonWithNumber(props: ButtonWithNumberProps) {
  const {
    testID = 'button-with-number',
    number,
    label,
    active,
    disabled,
    onClick,
  } = props;

  const buttonStyle = useMemo(() => {
    let borderColor = 'semantic.bg.neutral-inverse';
    if (disabled) {
      borderColor = 'semantic.fg.disabled';
    } else if (active) {
      borderColor = 'semantic.fg.primary';
    }

    return {
      border: `1px solid ${designSystemToken(borderColor)}`,
      padding: typeof number === 'number' ? '0 8px' : '0 10px',
    };
  }, [active, disabled, number]);

  const labelStyle = useMemo(() => {
    let color = 'semantic.fg.primary';
    if (disabled) {
      color = 'semantic.fg.disabled';
    }

    return {
      color: designSystemToken(color),
    };
  }, [disabled]);

  const numberStyle = useMemo(() => {
    let background = 'semantic.fg.primary';
    if (disabled) {
      background = 'semantic.fg.disabled';
    }

    return {
      width: 16,
      height: 16,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: '100%',
      background: designSystemToken(background),
      color: designSystemToken('semantic.bg.primary'),
    };
  }, [disabled]);

  return (
    <Button
      testID={testID}
      size="small"
      mode="plain"
      appearance="neutral"
      disabled={disabled}
      onClick={onClick}
      sx={buttonStyle}
    >
      <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center'}}>
        <Label size="md" sx={labelStyle}>
          {label}
        </Label>
        {typeof number === 'number' && number > 0 ? (
          <Label size="sm" sx={numberStyle}>
            {number}
          </Label>
        ) : null}
      </Box>
    </Button>
  );
}

export default ButtonWithNumber;
