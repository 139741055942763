import {ChartOptions, ScaleChartOptions} from 'chart.js';
import dayjs from 'dayjs';
import {SHORT_DAY_SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';

export const ADDITIONAL_OPTIONS_BASE: Partial<ChartOptions<'line'>> = {
  spanGaps: true,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
  },
};

export function getDefaultScaleX(
  dates: string[],
  format?: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): Partial<ScaleChartOptions<'line'>>['x'] {
  return {
    ticks: {
      callback: (_: string, index: number) => {
        return dayjs(dates[index]).format(
          format || SHORT_DAY_SHORT_MONTH_YEAR_FORMAT
        );
      },
    },
  };
}

export function getDefaultScaleY(
  title: string,
  tickValues: {max: number; min: number} | null,
  tickCallback: (label: unknown) => string,
  showGrid?: boolean,
  position?: 'left' | 'right'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): Partial<ScaleChartOptions<'line'>>['y'] {
  return {
    type: 'linear',
    display: true,
    position,
    grid: {display: !!showGrid},
    title: {
      display: true,
      text: title,
    },
    ticks: {
      callback: (label: unknown) => {
        return tickCallback(label);
      },
    },
    ...tickValues,
  };
}
