import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';

export enum GeneratedStyle {
  'none',
  'preset',
  'reference',
}
export type ImageItemProps = {
  id: string | number;
  isSavedToLibrary: boolean;
  sessionId?: number;
  tempId?: string;
  imageUrl: string;
};

export type ImageSavedItemProps = {
  id: string | number;
  isSavedToLibrary: boolean;
};

export type DummyItemProps = {
  id: string;
  sessionId: number;
  tempId?: string;
};

export interface ContentGenerationStateDefinition {
  showUploadModal: boolean;
  transparentImagePath: string;
  transparentImageId: string;
  sessionId: number;
  selectedPreset: string;
  presetThumbnailPath: string;
  selectedImageUrl: string;
  currentlyProcessedImages: number;
  presetPromptLength: number;
  userPrompt: string;
  presetNegativePromptLength: number;
  userNegativePrompt: string;
  productName: string;
  styleType: GeneratedStyle;
  selectedImageId: string | number;
  imageWidth: number;
  imageHeight: number;
  newGeneratedImages: ImageItemProps[];
  savedImages: ImageSavedItemProps[];
  dummiesStack: DummyItemProps[];
  isGeneratingImages: boolean;
  imagesListCounter: number;
  transparentImageWidth: string;
  transparentImageHeight: string;
  referenceImage: File | null;
  referenceImageWeight: number;
  brandId?: string;
}

const initialState: ContentGenerationStateDefinition = {
  showUploadModal: false,
  transparentImagePath: '',
  transparentImageId: '',
  sessionId: 0,
  selectedPreset: '',
  presetThumbnailPath: '',
  selectedImageUrl: '',
  currentlyProcessedImages: 0,
  presetPromptLength: 0,
  userPrompt: '',
  presetNegativePromptLength: 0,
  userNegativePrompt: '',
  styleType: GeneratedStyle.preset,
  productName: '',
  imageWidth: 1080,
  imageHeight: 1080,
  selectedImageId: '',
  newGeneratedImages: [],
  savedImages: [],
  dummiesStack: [],
  isGeneratingImages: false,
  imagesListCounter: 0,
  transparentImageWidth: '0',
  transparentImageHeight: '0',
  referenceImage: null,
  referenceImageWeight: 0.45,
  brandId: '',
};

type Action =
  | {type: 'SET_BRAND_ID'; brandId: string}
  | {type: 'SET_REFERENCE_IMAGE'; referenceImage: File | null}
  | {type: 'SET_REFERENCE_IMAGE_WEIGHT'; referenceImageWeight: number}
  | {type: 'RESET_REFERENCE_IMAGE_WEIGHT'}
  | {type: 'REMOVE_DUMMY_FROM_STACK'}
  | {type: 'SET_IMAGES_COUNT'; imagesListCounter: number}
  | {type: 'START_GENERATING'}
  | {type: 'GENERATION_ENDED'}
  | {type: 'PUSH_DUMMY_TO_STACK'; dummy: DummyItemProps}
  | {type: 'POP_DUMMY_FROM_STACK'}
  | {type: 'SET_NEW_GENERATED_IMAGES'; images: ImageItemProps[]}
  | {type: 'RESET_NEW_GENERATED_IMAGES'}
  | {type: 'REPLACE_DUMMY_WITH_IMAGE'; image: ImageItemProps}
  | {type: 'UPDATE_SELECTED_IMAGE_ID'; id: string | number}
  | {
      type: 'UPDATE_IMAGES_WAS_SAVED_TO_LIBRARY';
      savedImagesToLibrary: ImageSavedItemProps[];
    }
  | {type: 'UPDATE_SHOW_UPLOAD_MODAL'; showUploadModal: boolean}
  | {
      type: 'UPDATE_TRANSPARENT_REMOTE_FILE';
      transparentImagePath: string;
      transparentImageId: string;
    }
  | {type: 'UPDATE_SESSION_ID'; sessionId: number}
  | {
      type: 'UPDATE_PRESET';
      selectedPreset: string;
      presetThumbnailPath: string;
      promptValue: string;
      negativePromptValue: string;
      presetPromptLength: number;
      presetNegativePromptLength: number;
    }
  | {
      type: 'RESET_PRESET';
      promptValue: string;
      negativePromptValue: string;
      styleType: GeneratedStyle;
    }
  | {type: 'UPDATE_USER_PROMPT'; promptValue: string}
  | {type: 'UPDATE_USER_NEGATIVE_PROMPT'; promptValue: string}
  | {type: 'UPDATE_SELECTED_IMAGE_URL'; selectedImage: string}
  | {type: 'UPDATE_PRODUCT_NAME_INPUT'; productName: string}
  | {type: 'UPDATE_IMAGE_SIZE'; imageWidth: number; imageHeight: number}
  | {type: 'UPDATE_STYLE_TYPE'; styleType: GeneratedStyle}
  | {
      type: 'UPDATE_TRANSPARENT_IMAGE_SIZE';
      transparentImageWidth: string;
      transparentImageHeight: string;
    };

const reducer = (
  state: ContentGenerationStateDefinition,
  action: Action
): ContentGenerationStateDefinition => {
  switch (action.type) {
    case 'SET_BRAND_ID':
      return {...state, brandId: action.brandId};
    case 'SET_REFERENCE_IMAGE_WEIGHT':
      return {...state, referenceImageWeight: action.referenceImageWeight};
    case 'RESET_REFERENCE_IMAGE_WEIGHT':
      return {
        ...state,
        referenceImageWeight: initialState.referenceImageWeight,
      };
    case 'SET_REFERENCE_IMAGE':
      return {...state, referenceImage: action.referenceImage};
    case 'REMOVE_DUMMY_FROM_STACK': {
      const {id} = state.dummiesStack[0];

      const newGeneratedImages = state.newGeneratedImages.filter(
        (image) => image.tempId !== id
      );
      return {
        ...state,
        newGeneratedImages,
        dummiesStack: state.dummiesStack.slice(1),
        imagesListCounter: state.imagesListCounter - 1,
      };
    }
    case 'SET_IMAGES_COUNT':
      return {
        ...state,
        imagesListCounter: action.imagesListCounter,
      };
    case 'START_GENERATING':
      return {
        ...state,
        isGeneratingImages: true,
      };
    case 'GENERATION_ENDED':
      return {
        ...state,
        isGeneratingImages: false,
      };
    case 'PUSH_DUMMY_TO_STACK':
      return {...state, dummiesStack: [...state.dummiesStack, action.dummy]};
    case 'POP_DUMMY_FROM_STACK':
      return {
        ...state,
        dummiesStack: state.dummiesStack.slice(0, -1),
      };
    case 'SET_NEW_GENERATED_IMAGES':
      return {
        ...state,
        newGeneratedImages: [...action.images, ...state.newGeneratedImages],
      };
    case 'RESET_NEW_GENERATED_IMAGES':
      return {
        ...state,
        newGeneratedImages: [],
      };
    case 'REPLACE_DUMMY_WITH_IMAGE': {
      const {id} = state.dummiesStack[0];

      return {
        ...state,
        newGeneratedImages: state.newGeneratedImages.map((image) => {
          if (image.tempId === id) {
            return action.image;
          }
          return image;
        }),
        dummiesStack: state.dummiesStack.slice(1),
        imagesListCounter: state.imagesListCounter + 1,
      };
    }
    case 'UPDATE_SHOW_UPLOAD_MODAL':
      return {...state, showUploadModal: action.showUploadModal};
    case 'UPDATE_TRANSPARENT_REMOTE_FILE':
      return {
        ...state,
        transparentImagePath: action.transparentImagePath,
        transparentImageId: action.transparentImageId,
      };
    case 'UPDATE_SESSION_ID':
      return {...state, sessionId: action.sessionId};
    case 'UPDATE_PRESET':
      return {
        ...state,
        selectedPreset: action.selectedPreset,
        presetPromptLength: action.presetPromptLength,
        presetNegativePromptLength: action.presetNegativePromptLength,
        presetThumbnailPath: action.presetThumbnailPath,
        userPrompt: action.promptValue,
        userNegativePrompt: action.negativePromptValue,
      };
    case 'RESET_PRESET':
      return {
        ...state,
        selectedPreset: '',
        presetPromptLength: 0,
        presetNegativePromptLength: 0,
        presetThumbnailPath: '',
        userPrompt: action.promptValue,
        userNegativePrompt: action.negativePromptValue,
        styleType: action.styleType,
      };
    case 'UPDATE_USER_PROMPT':
      return {...state, userPrompt: action.promptValue};
    case 'UPDATE_USER_NEGATIVE_PROMPT':
      return {...state, userNegativePrompt: action.promptValue};
    case 'UPDATE_SELECTED_IMAGE_URL':
      return {...state, selectedImageUrl: action.selectedImage};
    case 'UPDATE_SELECTED_IMAGE_ID':
      return {...state, selectedImageId: action.id};
    case 'UPDATE_IMAGES_WAS_SAVED_TO_LIBRARY':
      return {
        ...state,
        savedImages: [...state.savedImages, ...action.savedImagesToLibrary],
      };
    case 'UPDATE_STYLE_TYPE':
      return {...state, styleType: action.styleType};
    case 'UPDATE_PRODUCT_NAME_INPUT':
      return {...state, productName: action.productName};
    case 'UPDATE_IMAGE_SIZE':
      return {
        ...state,
        imageWidth: action.imageWidth,
        imageHeight: action.imageHeight,
      };
    case 'UPDATE_TRANSPARENT_IMAGE_SIZE':
      return {
        ...state,
        transparentImageWidth: action.transparentImageWidth,
        transparentImageHeight: action.transparentImageHeight,
      };
    default:
      return state;
  }
};

interface StoreContextType {
  state: ContentGenerationStateDefinition;
  dispatch: React.Dispatch<Action>;
}

const StoreContext = createContext<StoreContextType | undefined>(undefined);

// Create a custom hook to access the context
export const useContentGenerationStore = (): StoreContextType => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error(
      'useContentGenerationStore must be used within a ContentGenerationStoreProvider'
    );
  }
  return context;
};

export const getTransparentRemoteFile = (
  state: ContentGenerationStateDefinition
) => ({
  transparentImagePath: state.transparentImagePath,
  transparentImageId: state.transparentImageId,
});
export const getSessionId = (state: ContentGenerationStateDefinition) =>
  state.sessionId;
export const getsavedImagesToLibrary = (
  state: ContentGenerationStateDefinition
) => state.savedImages;
export const getSelectedImageUrl = (state: ContentGenerationStateDefinition) =>
  state.selectedImageUrl;
export const getSelectedImageId = (state: ContentGenerationStateDefinition) =>
  state.selectedImageId;
export const getProductName = (state: ContentGenerationStateDefinition) =>
  state.productName;
export const getPresetData = (state: ContentGenerationStateDefinition) => {
  return {
    selectedPreset: state.selectedPreset,
    presetPromptLength: state.presetPromptLength,
    presetNegativePromptLength: state.presetNegativePromptLength,
    presetThumbnailPath: state.presetThumbnailPath,
  };
};

export const getUserPrompt = (state: ContentGenerationStateDefinition) => {
  return state.userPrompt;
};
export const getUserNegativePrompt = (
  state: ContentGenerationStateDefinition
) => state.userNegativePrompt;
export const getStyleType = (state: ContentGenerationStateDefinition) =>
  state.styleType;

export const getImageDimensions = (
  state: ContentGenerationStateDefinition
) => ({
  height: state.imageHeight,
  width: state.imageWidth,
});

export const getReferenceImage = (state: ContentGenerationStateDefinition) =>
  state.referenceImage;

export const getTransparentImageDimensions = (
  state: ContentGenerationStateDefinition
) => ({
  transparentImageHeight: state.transparentImageHeight,
  transparentImageWidth: state.transparentImageWidth,
});

export const getReferenceImageWeight = (
  state: ContentGenerationStateDefinition
) => state.referenceImageWeight;

interface StoreProviderProps {
  children: ReactNode;
}
export function ContentGenerationStoreProvider({children}: StoreProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
}
