import React, {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import translate from '@/utils/translate';
import agencyFetchers from '@/api/fetchers/agency';
import BreadcrumbLabel from '@/components/breadcrumb-label';
import BreadcrumbSeparator from '@/components/breadcrumb-separator/BreadcrumbSeparator';
import CampaignFilter from '@/components/campaign-filter';
import useBrandId from '@/hooks/use-brand-id';
import useDataQuery from '@/hooks/use-data-query';
import useParams from '@/hooks/use-params';
import styles from './Stats.module.scss';

function Stats() {
  const {campaign} = useParams();
  const brandId = useBrandId();
  const [searchParams, setSearchParams] = useSearchParams();

  const breadcrumbLink = campaign ? `/stats/insights?brandId=${brandId}` : null;

  const {data: campaigns}: any = useDataQuery({
    queryKey: ['brandStatsFilterableCampaigns', {brandId}],
    queryFn: agencyFetchers.brandStatsFilterableCampaigns,
    meta: {
      brandId,
    },
  });

  const campaignOptions = useMemo(() => {
    const campaignsData = Array.isArray(campaigns) ? campaigns : [];
    return campaignsData.map((campaignOption: any) => ({
      id: campaignOption.id,
      label: campaignOption.name,
    }));
  }, [campaigns]);

  return (
    <div className={styles.container}>
      <CampaignFilter
        testID="stats--campaign-filter"
        withAllOption
        options={campaignOptions}
        onChange={(event: any, selectedOption: any | null) => {
          if (selectedOption?.id === 'all') {
            searchParams.delete('campaign');
          } else {
            searchParams.set('campaign', selectedOption.id);
          }
          setSearchParams(searchParams, {replace: true});
        }}
      />
    </div>
  );
}

export default Stats;
