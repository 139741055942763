import React from 'react';
import {Headline} from '@lightricks/react-design-system';
import Conditional from '@/components/conditional';
import CreatorProfileAvatar from '@/components/creator-profile-avatar';
import VettingStatusButton from '@/components/creator-vetting-report/components/vetting-status-button';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import styles from './ProfileVettingInfo.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-info';

interface ProfileVettingInfoProps {
  profileImageUrl: string | null;
  profileDisplayName: string | null;
  showProfileInfo?: boolean;
  vettingStatus: string | null; // TODO: replace with union of string values
  onVettingStatusChanged: (status: string) => void;
}

function ProfileVettingInfo(props: ProfileVettingInfoProps) {
  const {
    profileImageUrl,
    profileDisplayName,
    showProfileInfo = true,
    vettingStatus,
    onVettingStatusChanged,
  } = props;

  return (
    <div
      className={styles.profileVettingInfoContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.PROFILE_VETTING_INFO}
    >
      <div className={styles.profileInfoAndStatus}>
        <Conditional condition={showProfileInfo}>
          <div className={styles.profileInfo}>
            <CreatorProfileAvatar
              className={styles.profileAvatar}
              profileImageUrl={profileImageUrl || ''}
              profileDisplayName={profileDisplayName || ''}
              size="3xl"
              allowEnlarge={false}
            />
            <Headline size="lg">{profileDisplayName}</Headline>
          </div>
        </Conditional>
        <div className={styles.statusButtonContainer}>
          <VettingStatusButton
            status={vettingStatus || ''}
            onStatusChange={onVettingStatusChanged}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileVettingInfo;
