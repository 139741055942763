import React from 'react';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import styles from './CreatorCategories.module.scss';

type CreatorCategoriesProps = {
  categories: string[];
};

function CreatorCategories({categories}: CreatorCategoriesProps) {
  const categoriesString = categories
    ? categories.slice(0, 3).join(' . ') + (categories.length > 3 ? ' ...' : '')
    : ''; // TODO: ask Batya about the ...
  return (
    <Body
      size="lg"
      color={designSystemToken('semantic.fg.secondary')}
      className={styles.creatorCategory}
    >
      {categoriesString}
    </Body>
  );
}

export default CreatorCategories;
