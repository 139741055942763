import {useMutation} from '@tanstack/react-query';
import creatorsUpdaters from '@/api/updaters/creators';

function useStartConversation() {
  return useMutation({
    mutationKey: ['creators', 'startConversation'],
    mutationFn: creatorsUpdaters.startConversation,
  });
}

export default useStartConversation;
