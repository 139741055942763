import {useMutation} from '@tanstack/react-query';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';

function useReplaceBgWithReferenceImage({tempId}: {tempId?: string}) {
  return useMutation({
    mutationKey: ['replaceBackgroundWithReferenceImage', tempId],
    mutationFn: capsuleAiUpdaters.replaceBackgroundWithReferenceImage,
  });
}

export default useReplaceBgWithReferenceImage;
