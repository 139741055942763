// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

function loadScriptSync(src: string): void {
  const script = document.createElement('script');
  script.src = src;

  document.head.appendChild(script);
}

/**
 * Bing Pixel
 */

(function (w, d, t, r, u) {
  var f, n, i;
  (w[u] = w[u] || []),
    (f = function () {
      var o = {ti: '109000771'};
      (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
    }),
    (n = d.createElement(t)),
    (n.src = r),
    (n.async = 1),
    (n.onload = n.onreadystatechange =
      function () {
        var s = this.readyState;
        (s && s !== 'loaded' && s !== 'complete') ||
          (f(), (n.onload = n.onreadystatechange = null));
      }),
    (i = d.getElementsByTagName(t)[0]),
    i.parentNode.insertBefore(n, i);
})(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');

/**
 * Facebook Pixel
 */

!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(
  window,
  document,
  'script',
  'https://connect.facebook.net/en_US/fbevents.js'
);
fbq('init', '677415637491821');

/**
 * Google Ads Pixel
 */

loadScriptSync('https://www.googletagmanager.com/gtag/js?id=AW-11244051408');
(function (w) {
  w.dataLayer = w.dataLayer || [];
  function gtag() {
    w.dataLayer.push(arguments);
  }
  w.gtag = gtag;
  w.gtag('js', new Date());
  w.gtag('config', 'AW-11244051408');
})(window);

/**
 * LinkedIn Pixel
 */

const _linkedin_partner_id = '5140801';

window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];

window._linkedin_data_partner_ids.push(_linkedin_partner_id);

(function (l) {
  if (!l) {
    window.lintrk = function (a, b) {
      window.lintrk.q.push([a, b]);
    };

    window.lintrk.q = [];
  }

  var s = document.getElementsByTagName('script')[0];

  var b = document.createElement('script');

  b.type = 'text/javascript';
  b.async = true;

  b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

  s.parentNode.insertBefore(b, s);
})(window.lintrk);

/**
 * Taboola Pixel
 */

window._tfa = window._tfa || [];
!(function (t, f, a, x) {
  if (!document.getElementById(x)) {
    t.async = 1;
    t.src = a;
    t.id = x;
    f.parentNode.insertBefore(t, f);
  }
})(
  document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/1548556/tfa.js',
  'tb_tfa_script'
);

/**
 * Twitter Pixel
 */

!(function (e, t, n, s, u, a) {
  e.twq ||
    ((s = e.twq =
      function () {
        s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
      }),
    (s.version = '1.1'),
    (s.queue = []),
    (u = t.createElement(n)),
    (u.async = !0),
    (u.src = 'https://static.ads-twitter.com/uwt.js'),
    (a = t.getElementsByTagName(n)[0]),
    a.parentNode.insertBefore(u, a));
})(window, document, 'script');
twq('config', 'odoo1');

export {};
