import React from 'react';
import {Outlet} from 'react-router-dom';

/**
 * Shared route for the vetting hub
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Vetting() {
  // TODO: add checks for permissions and render accordingly
  return <Outlet />;
}

export default Vetting;
