import {ProfileImage} from '@/types/models/search-creators/searchCreators';

function getProfileImageUrl(profileImage?: ProfileImage) {
  return (
    profileImage?.imageVersions?.thumbnail ??
    profileImage?.imageVersions?.full ??
    profileImage?.imageVersions?.original ??
    profileImage?.socialPlatformsImages?.at(0) ??
    ''
  );
}

export default getProfileImageUrl;
