import React from 'react';
import {
  getStyleType,
  GeneratedStyle,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './PresetSingleThumb.module.scss';

interface PresetSingleThumbProps {
  handleClick: () => void;
  image: string;
  isSelected: boolean;
  name: string;
}

function PresetSingleThumb({
  isSelected,
  name,
  image,
  handleClick,
}: PresetSingleThumbProps) {
  const {state} = useContentGenerationStore();
  const isActive = isSelected && getStyleType(state) === GeneratedStyle.preset;
  const contentClass = `${styles.content} ${
    isActive ? styles.active : styles.inactive
  } ${getStyleType(state) === GeneratedStyle.none ? styles.initial : ''}`;

  return (
    <div className={`${styles.buttonContainer}`} onClick={() => handleClick()}>
      <div className={contentClass}>
        <img src={image} className={styles.beforeImage} alt={name} />
        <div className={styles.singleEnvButtonName}>{name}</div>
      </div>
    </div>
  );
}

export default PresetSingleThumb;
