import {stringToNumber} from '@/views/campaigns/brief/components/brief-wizard/validators';
import {SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET} from '@/views/campaigns/brief/constants';
import {
  BriefAiWizardFormErrors,
  BriefAiWizardFormProps,
} from './BriefAiWizardProps';
import Step from './step';
import BRIEF_WIZARD_TEST_IDS from './testIds';

const TRANSLATION_PREFIX = 'views.campaigns.components.wizard';

// step validator functions => true if step is valid, false if not
const STEP_VALIDATORS = {
  budgetAndNetwork: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) => {
    const {budget, customBudget, network, subscriptionType} = formData;
    let isBudgetValid = !!budget || !!customBudget;

    if (
      subscriptionType &&
      SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET.includes(subscriptionType)
    ) {
      isBudgetValid =
        isBudgetValid || (!!customBudget && stringToNumber(customBudget) === 0);
    }
    return isBudgetValid && !!network && !formErrors.budget;
  },
  deliverables: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) => {
    const {requiredDeliverables} = formData;
    return !!requiredDeliverables.length && !formErrors.requiredDeliverables;
  },
  influencerPackage: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) => {
    const {creatorType} = formData;
    return !!creatorType;
  },
  product: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) => {
    const {
      product,
      sendProductToCreators,
      sendProductToCreatorsMethod,
      sendProductToCreatorsCashValue,
    } = formData;

    return (
      !!product &&
      (!sendProductToCreators ||
        (sendProductToCreators &&
          !!sendProductToCreatorsMethod &&
          !!sendProductToCreatorsCashValue))
    );
  },
  guidelines: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) => {
    return true;
  },
};

type ValidateStepsProps = {
  validators: ((
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) => boolean)[];
  formData: BriefAiWizardFormProps;
  formErrors: BriefAiWizardFormErrors;
};

const validateSteps = ({
  validators,
  formData,
  formErrors,
}: ValidateStepsProps): boolean => {
  return validators.every(
    (
      validator: (
        formData: BriefAiWizardFormProps,
        formErrors: BriefAiWizardFormErrors
      ) => boolean
    ) => validator(formData, formErrors)
  );
};

const budgetAndNetworkStep = {
  testID: BRIEF_WIZARD_TEST_IDS.STEP__BUDGET_AND_NETWORK,
  id: 'budgetAndNetwork',
  labelLocaleKey: `${TRANSLATION_PREFIX}.steps.budget-and-network.label`,
  Component: Step.BudgetAndNetwork,
  errorCheck: (formData: BriefAiWizardFormProps) => false,
  canContinueToStep: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) =>
    validateSteps({
      validators: [STEP_VALIDATORS.budgetAndNetwork],
      formData,
      formErrors,
    }),
};

const deliverablesStep = {
  testID: BRIEF_WIZARD_TEST_IDS.STEP__DELIVERABLES,
  id: 'deliverables',
  labelLocaleKey: `${TRANSLATION_PREFIX}.steps.deliverables.label`,
  Component: Step.Deliverables,
  errorCheck: (formData: BriefAiWizardFormProps) => false,
  canContinueToStep: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) =>
    validateSteps({
      validators: [
        STEP_VALIDATORS.budgetAndNetwork,
        STEP_VALIDATORS.deliverables,
      ],
      formData,
      formErrors,
    }),
};

const influencerPackageStep = {
  testID: BRIEF_WIZARD_TEST_IDS.STEP__INFLUENCER_PACKAGE,
  id: 'influencerPackage',
  labelLocaleKey: `${TRANSLATION_PREFIX}.steps.influencer-package.label`,
  Component: Step.InfluencerPackage,
  errorCheck: (formData: BriefAiWizardFormProps) => false,
  canContinueToStep: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) =>
    validateSteps({
      validators: [
        STEP_VALIDATORS.budgetAndNetwork,
        STEP_VALIDATORS.deliverables,
        STEP_VALIDATORS.influencerPackage,
      ],
      formData,
      formErrors,
    }),
};

const productStep = {
  testID: BRIEF_WIZARD_TEST_IDS.STEP__PRODUCT,
  id: 'product',
  labelLocaleKey: `${TRANSLATION_PREFIX}.steps.product.label`,
  Component: Step.Product,
  errorCheck: (formData: BriefAiWizardFormProps) => false,
  canContinueToStep: (
    formData: BriefAiWizardFormProps,
    formErrors: BriefAiWizardFormErrors
  ) =>
    validateSteps({
      validators: [
        STEP_VALIDATORS.budgetAndNetwork,
        STEP_VALIDATORS.deliverables,
        STEP_VALIDATORS.influencerPackage,
        STEP_VALIDATORS.product,
      ],
      formData,
      formErrors,
    }),
};
export const BRAND_AWARENESS_CAMPAIGN_STEPS = [
  budgetAndNetworkStep,
  deliverablesStep,
  influencerPackageStep,
  productStep,
];

export const CONTENT_CREATION_CAMPAIGN_STEPS = [
  budgetAndNetworkStep,
  deliverablesStep,
  {
    ...productStep,
    canContinueToStep: (
      formData: BriefAiWizardFormProps,
      formErrors: BriefAiWizardFormErrors
    ) =>
      validateSteps({
        validators: [
          STEP_VALIDATORS.budgetAndNetwork,
          STEP_VALIDATORS.deliverables,
          STEP_VALIDATORS.product,
        ],
        formData,
        formErrors,
      }),
  },
];
