import imageCompression from 'browser-image-compression';
import {useEffect, useState} from 'react';

const MAX_FILE_SIZE = 1; // MB
const FULL_PROGRESS = 100; // %

function bytesToMB(bytes: number) {
  return bytes / 1024 / 1024;
}

function blobToFile(theBlob: Blob, fileName: string): File {
  return new File(
    [theBlob as any], // cast as any
    fileName,
    {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    }
  );
}

function useImageCompression(
  imageFile: File | null,
  maxSizeMB = MAX_FILE_SIZE
) {
  const [isCompressing, setIsCompressing] = useState(false);
  const [compressedFile, setCompressedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function applyCompression(image: File) {
    try {
      const options = {
        maxSizeMB,
        onProgress: (progress: number) =>
          setIsCompressing(progress < FULL_PROGRESS),
      };
      const blobCompressedFile = await imageCompression(image, options);
      const convertedFile = blobToFile(blobCompressedFile, image.name);
      setCompressedFile(convertedFile);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  useEffect(() => {
    if (imageFile) {
      if (bytesToMB(imageFile.size) <= MAX_FILE_SIZE) {
        setCompressedFile(imageFile);
      } else {
        applyCompression(imageFile).catch((error) => console.error(error));
      }
    }
  }, [imageFile]);

  return {isCompressing, compressedFile, errorMessage};
}
export default useImageCompression;
