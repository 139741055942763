import React, {useState} from 'react';
import {Box, Stack, styled} from '@mui/material';
import {
  Button,
  Input,
  SwitchButton,
  Body,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import SubmitButtonContainer from '@/views/creators/components/creator-action/action/SubmitButtonContainer';
import useCreateCreatorGroupLabel, {
  maxNameLength,
} from '@/hooks/mutations/use-create-creator-group-label';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useBrandId from '@/hooks/use-brand-id';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './AddList.module.scss';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.add-to-my-creators';

const NewListContent = styled('div')`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-root {
    padding: 12px 0 0 16px;
  }
  .MuiCheckbox-root {
    margin: 0;
  }
  .MuiFormHelperText-root {
    top: 90% !important;
  }
`;

function AddList() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [isSharedList, setIsSharedList] = useState(false);
  const brandId = useBrandId();

  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';

  const createCreatorGroupLabel = useCreateCreatorGroupLabel(creatorGroupId);
  const handleCreateNewList = async () => {
    if (creatorGroupId) {
      await createCreatorGroupLabel.mutateAsync({
        creatorGroupId,
        name: newListName,
        organizationLevel: isSharedList,
      });
      setNewListName('');
      listCreatorsActions.setActiveListAction(null);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await handleCreateNewList();
    setIsSubmitting(false);
  };

  return (
    <Stack sx={{width: '100%'}}>
      <NewListContent>
        <Input
          autoFocus
          value={newListName}
          clearIcon={false}
          sx={{'.MuiInputBase-root': {minHeight: 56}}}
          validatorOptions={{
            onChangeText: (value: string) => setNewListName(value),
            validators: [
              {
                validator: (val: string) => val.length < maxNameLength,
                errorMessage: translate(
                  `${TRANSLATION_PREFIX}.new-list-name-validation`,
                  {count: maxNameLength}
                ),
              },
            ],
          }}
        />
        <div className={styles.organizationVisibility}>
          <Body size="lg" className={styles.description}>
            {translate(`${TRANSLATION_PREFIX}.organization-visibility`)}
          </Body>
          <SwitchButton
            checked={isSharedList}
            onChange={() => setIsSharedList((prev) => !prev)}
            className={`${styles.switchButton} ${
              isSharedList ? styles.checked : ''
            }`}
          />
        </div>
      </NewListContent>
      <SubmitButtonContainer>
        <Box display="flex" gap={1}>
          <Button
            appearance="neutral"
            mode="filled"
            size="large"
            disabled={
              !newListName || isSubmitting || newListName.length > maxNameLength
            }
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            {translate(`${TRANSLATION_PREFIX}.submit-button-save`)}
          </Button>
        </Box>
      </SubmitButtonContainer>
    </Stack>
  );
}

AddList.textAlignment = 'left' as const;
AddList.footerAlignment = 'right' as const;

export default AddList;
