import {snakeCase} from 'lodash';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {ProfileProps} from '@/types/creatorProfile';
import QueryContext from '../QueryContext';
import api from '../index';

export type CreatorProfileResponse = {
  data?: ProfileProps;
};

export const DEFAULT_CREATOR_PROFILE_INCLUDE = [
  'content_preferences',
  'identities.audience',
  'identities.social_network',
  'portfolio_media_items',
  'profile_previous_campaigns',
  'tags',
  'current_brand_collaboration_conversation',
  'current_brand_membership.labels',
  'current_brand_membership.creator_group',
];

const DEFAULT_PUBLIC_CREATOR_PROFILE_INCLUDE = [
  'content_preferences',
  'tags',
  'identities.audience',
  'identities.social_network',
  'portfolio_media_items',
];

const DEFAULT_SHARED_LIST_CREATOR_PROFILE_INCLUDE = [
  'content_preferences',
  'identities.audience',
  'identities.social_network',
  'portfolio_media_items',
];

const creatorProfileFetcher = {
  creatorProfile: (context: QueryContext) =>
    api.get(`/profiles/${context.meta?.id}`, {
      params: {
        brand_id: context?.meta?.brandId,
        include: context?.meta?.include || DEFAULT_CREATOR_PROFILE_INCLUDE,
      },
    }),
  publicCreatorProfile: (context: QueryContext) =>
    api.get(`/public_creator_profiles/${context.meta?.id}`, {
      params: {
        brand_id: context?.meta?.brandId,
        include:
          context?.meta?.include || DEFAULT_PUBLIC_CREATOR_PROFILE_INCLUDE,
      },
    }),
  sharedListCreators: (context: QueryContext) => {
    const {include, ...rest} = context.meta || {};
    return api.get(`/shared_list_creators`, {
      params: {
        per_page: 99999,
        ...mapKeysToCase(rest, snakeCase),
      },
    });
  },
  sharedListCreator: (context: QueryContext) => {
    const {include, ...rest} = context.meta || {};
    return api.get(`/creators/${context.meta?.id}`, {
      params: {
        include: include || DEFAULT_SHARED_LIST_CREATOR_PROFILE_INCLUDE,
        ...mapKeysToCase(rest, snakeCase),
      },
    });
  },
};

export default creatorProfileFetcher;
