import React from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {Radio, RadioProps} from '@mui/material';
import AnimatedCheckmarkIcon from './animated-checkmark-icon';

type CheckmarkRadioButtonProps = Omit<RadioProps, 'icon' | 'checkedIcon'>;

function CheckmarkRadioButton(props: CheckmarkRadioButtonProps) {
  return (
    <Radio
      icon={<RadioButtonUncheckedIcon fontSize="large" />}
      checkedIcon={<AnimatedCheckmarkIcon fontSize="large" />}
      {...props}
    />
  );
}

export default CheckmarkRadioButton;
