import React, {useEffect, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Modal} from '@lightricks/react-design-system';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES} from '@/lib/analytics/analyticsConstants';
import ImageGalleryView from '@/components/image-gallery-view';
import {useContentGenerationStore} from '@/contexts/ContentGenerationStore';
import styles from './ImageModalView.module.scss';

interface ImageItemProps {
  id: string | number;
  isSavedToLibrary: boolean;
  imageUrl: string;
}

type ImageViewProps = {
  images: ImageItemProps[];
};

function ImageModalView(props: ImageViewProps) {
  const {images} = props;
  const [showSingleImageModal, setShowSingleImageModal] = useState(false);
  const {state, dispatch} = useContentGenerationStore();

  useEffect(() => {
    setShowSingleImageModal(!!state.selectedImageUrl);
  }, [state.selectedImageUrl]);

  const onImageClose = () => {
    setShowSingleImageModal(false);
    dispatch({type: 'UPDATE_SELECTED_IMAGE_URL', selectedImage: ''});
    dispatch({type: 'UPDATE_SELECTED_IMAGE_ID', id: ''});

    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_INTERACTION, {
      interaction_name: 'closed_image_preview',
      interaction_type: 'click',
      interaction_location: 'image_preview',
      interaction_details: JSON.stringify({
        image_url: state.selectedImageUrl,
      }),
      interaction_id: uuid(),
    });
  };

  const onImageChange = (index: number, direction: string) => {
    dispatch({
      type: 'UPDATE_SELECTED_IMAGE_URL',
      selectedImage: images[index].imageUrl,
    });
    dispatch({type: 'UPDATE_SELECTED_IMAGE_ID', id: images[index].id});

    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_INTERACTION, {
      interaction_name: `navigate_${direction}_image`,
      interaction_type: 'click',
      interaction_location: 'image_preview',
      interaction_details: JSON.stringify({
        image_url: images[index].imageUrl,
        direction,
      }),
      interaction_id: uuid(),
    });
  };

  return (
    <Modal
      open={showSingleImageModal}
      handleClose={onImageClose}
      className={styles.singleImageModal}
    >
      <div id="ai-content-generator-image" className={styles.container}>
        <ImageGalleryView
          images={images}
          onActiveIndexChange={onImageChange}
          customArrowsContainerStyle={styles.arrowsContainer}
          onImageClose={onImageClose}
        />
      </div>
    </Modal>
  );
}

export default ImageModalView;
