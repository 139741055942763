import BudgetAndNetwork from './budget-and-network';
import Deliverables from './deliverables';
import InfluencerPackage from './influencer-package';
import Product from './product';

function Step() {}

Step.BudgetAndNetwork = BudgetAndNetwork;
Step.Deliverables = Deliverables;
Step.InfluencerPackage = InfluencerPackage;
Step.Product = Product;

export default Step;
