import numeral from 'numeral';

const defaultFormat = '0.0%';

function formattedPercentage(number: number | null, format?: string) {
  if (typeof number !== 'number' && !number) {
    return null;
  }

  return numeral(number).format(format || defaultFormat);
}

export default formattedPercentage;
