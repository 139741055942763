import React from 'react';
import styles from './Spinner.module.scss';

const STROKE_WIDTH = 4;
const CIRCLE_RADIUS = 20;

function Spinner({size}: {size: number}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={getViewBoxSize(STROKE_WIDTH, CIRCLE_RADIUS)}
      xmlns="http://www.w3.org/2000/svg"
      className={styles.spinner}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth={STROKE_WIDTH}>
          <circle
            strokeOpacity=".5"
            cx="0"
            cy="0"
            r={CIRCLE_RADIUS}
            strokeWidth={STROKE_WIDTH}
            className={styles.circle}
          />
          <path d={getPath(CIRCLE_RADIUS)}>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 0 0"
              to="360 0 0"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
}

const getViewBoxSize = (strokeWidth: number, radius: number): string => {
  const startingPoint = -radius - strokeWidth / 2 + 1;
  const endpoint = radius * 2 + strokeWidth;
  return [startingPoint, startingPoint, endpoint, endpoint].join(' ');
};

const getPath = (radius: number): string => {
  return [`M${String(radius)} 0c0-9.94-8.06`, radius, radius, radius].join('-');
};

export default Spinner;
