import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {
  Icon,
  Headline,
  designSystemToken,
} from '@lightricks/react-design-system';
import styles from './ListDrawerAccordion.module.scss';

export default function ListDrawerAccordion({
  label,
  preIconName,
  children,
}: {
  label: string;
  preIconName?: Parameters<typeof Icon>[0]['name'];
  children: React.ReactNode;
}) {
  return (
    <Accordion
      classes={{root: styles.container}}
      disableGutters
      elevation={0}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={
          <Icon
            name="Actions-Arrow-Down-Large"
            size="small"
            appearance="primary"
          />
        }
      >
        <div className={styles.summaryContent}>
          {preIconName && (
            <Icon name={preIconName} size="medium" appearance="primary" />
          )}
          <Headline size="xs" color={designSystemToken('semantic.fg.primary')}>
            {label}
          </Headline>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{root: styles.details}}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
