import {capitalize} from 'lodash';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import injectArrayDelimiter from '@/utils/injectArrayDelimiter';
import numberAbbreviation from '@/utils/numberAbbreviation';
import translate from '@/utils/translate';
import BreadcrumbLabel from '@/components/breadcrumb-label';
import BreadcrumbSeparator from '@/components/breadcrumb-separator/BreadcrumbSeparator';
import EmberIframe from '@/components/ember-iframe';
import SocialNetworkIcon from '@/components/social-network-icon';
import useCampaignQuery from '@/hooks/queries/use-campaign-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import styles from './Campaigns.module.scss';

type CampaignDetailsSectionProps = {
  testID?: string;
  name: string;
  totalLabel: string;
  sectionLabel: string;
  tooltipLabel: string;
};

function CampaignDetailsSection(props: CampaignDetailsSectionProps) {
  const {testID, name, totalLabel, sectionLabel, tooltipLabel} = props;

  return (
    <div key={name}>
      <div id={name} className={styles.campaignTotal}>
        <strong
          className={styles.campaignDetailsSectionLabel}
          data-testid={testID}
        >
          {totalLabel}
        </strong>
        <label className={styles.campaignDetailsSectionLabel}>
          {sectionLabel}
        </label>
      </div>
      <ReactTooltip
        id={`${name}-tooltip`}
        anchorSelect={`#${name}`}
        place="bottom"
        className={styles.tooltip}
      >
        <label className={styles.tooltipLabel}>{tooltipLabel}</label>
      </ReactTooltip>
    </div>
  );
}

type CampaignDetailsProps = {
  campaign: any;
};

function CampaignDetails(props: CampaignDetailsProps) {
  const {campaign} = props;
  const components = [];
  if (!campaign.showCampaignDetails) {
    return null;
  }

  if (
    campaign.requiredFeedPostsCount &&
    campaign.shortlistedProposalsSocialReach
  ) {
    components.push(
      <CampaignDetailsSection
        testID="shortlisted-proposals-social-reach"
        key="shortlisted-reach"
        name="shortlisted-reach"
        totalLabel={`${numberAbbreviation(
          campaign.shortlistedProposalsSocialReach
        )}`}
        sectionLabel={translate(
          'components.brand-header.campaigns.shortlisted-reach'
        )}
        tooltipLabel={translate(
          'components.brand-header.tooltips.shortlisted-reach'
        )}
      />
    );
  }

  if (campaign.acceptedContractsSocialReach) {
    components.push(
      <CampaignDetailsSection
        testID="accepted-contracts-social-reach"
        key="accepted-reach"
        name="accepted-reach"
        totalLabel={numberAbbreviation(campaign.acceptedContractsSocialReach)}
        sectionLabel={translate(
          'components.brand-header.campaigns.accepted-reach',
          {
            reachLabel: capitalize(campaign.socialNetwork.reachLabel),
          }
        )}
        tooltipLabel={translate(
          'components.brand-header.tooltips.accepted-reach'
        )}
      />
    );
  }

  if (campaign.isOpenMarket) {
    if (campaign.shortlistedProposalsTotalPrice) {
      components.push(
        <CampaignDetailsSection
          testID="shortlisted-proposals-total-price"
          key="shortlisted-cost"
          name="shortlisted-cost"
          totalLabel={numberAbbreviation(
            campaign.shortlistedProposalsTotalPrice,
            '$0,0.00'
          )}
          sectionLabel={translate(
            'components.brand-header.campaigns.shortlisted-cost'
          )}
          tooltipLabel={translate(
            'components.brand-header.tooltips.shortlisted-cost'
          )}
        />
      );
    }
    if (campaign.acceptedTotalPrice) {
      components.push(
        <CampaignDetailsSection
          testID="accepted-contracts-total-price"
          key="accepted-cost"
          name="accepted-cost"
          totalLabel={numberAbbreviation(
            campaign.acceptedTotalPrice,
            '$0,0.00'
          )}
          sectionLabel={translate(
            'components.brand-header.campaigns.accepted-cost'
          )}
          tooltipLabel={translate(
            'components.brand-header.tooltips.accepted-cost'
          )}
        />
      );
    }
  }

  return components?.length ? (
    <div
      className={styles.campaignTotals}
      data-test-brand-header-campaign-totals-container
    >
      {injectArrayDelimiter(components, (index: number) => (
        <span key={`delimiter-${String(index)}`} className={styles.separator}>
          &#124;
        </span>
      ))}
    </div>
  ) : null;
}

function Campaigns() {
  const brandId = useBrandId();
  const location = useLocation();
  const campaignId = location.pathname.split('/')[2];
  const {campaign}: any = useCampaignQuery({
    campaignId,
    include: ['social_network'],
  });
  const {navigate} = useNavigation();

  useEffect(() => {
    EmberIframe.listen('routeToBriefAiWizard', () => {
      navigate(`/new-campaigns/brief-ai/wizard?brandId=${brandId}`);
    });
  }, []);

  const breadcrumbLink = campaign?.id ? `/campaigns?brandId=${brandId}` : null;

  const getCampaignName = () => {
    if (campaign.loaded) {
      return (
        <>
          <BreadcrumbLabel
            isFocused
            testID="breadcrumb-label--campaign-name"
            label={campaign.name}
          />
          <span className={styles.socialNetworkIcon}>
            <SocialNetworkIcon
              width={18}
              height={18}
              name={campaign.networkDasherized}
            />
          </span>
        </>
      );
    }
    return null;
  };

  const getCampaignDetails = () => {
    if (campaign.loaded && campaign.showCampaignDetails) {
      return <CampaignDetails campaign={campaign} />;
    }
    return null;
  };

  return (
    <div className={styles.container}>
      {getCampaignName()}
      <div className={styles.spacer} />
      {getCampaignDetails()}
    </div>
  );
}

export default Campaigns;
