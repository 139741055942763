import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import {ROUTES} from '@/config/routesConstants';
import usePrevious from '@/hooks/use-previous';
import {
  getNewCampaignCampaignId,
  getNewCampaignIsSubmitted,
  useNewCampaignFormDataStore,
} from '@/contexts/NewCampaignContext';

const AI_BRIEF_ROUTES_EVENTS = [ROUTES.NEW_CAMPAIGN_BRIEF];

function useCampaignCreationScreenChangedEvent() {
  const location = useLocation();
  const previousLocation: {pathname: string} = usePrevious(location);
  const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
    FLOW_NAMES.CAMPAIGN_CREATION
  );
  const {
    state: newCampaignFormDataState,
    dispatch: newCampaignFormDataDispatch,
  } = useNewCampaignFormDataStore();
  const isSubmitted = getNewCampaignIsSubmitted(newCampaignFormDataState);
  const campaignId = getNewCampaignCampaignId(newCampaignFormDataState);

  useEffect(() => {
    if (
      AI_BRIEF_ROUTES_EVENTS.find((route) =>
        new RegExp(route, 'i').test(previousLocation?.pathname || '')
      )
    ) {
      if (!isSubmitted && campaignCreationFlow) {
        AnalyticsService.dispatchEvent(
          eventNames.CAMPAIGN_CREATION_FLOW_ENDED,
          {
            flow_id: campaignCreationFlow.flow_id,
            reason: 'abandoned',
            current_step: 'draft_review',
            campaign_details: '',
            campaign_creation_end_destination: location.pathname,
            error: '',
            campaign_id: campaignId,
          }
        );

        AnalyticsService.endFlow(FLOW_NAMES.CAMPAIGN_CREATION);
      }
      newCampaignFormDataDispatch({
        type: 'SET_NEW_CAMPAIGN_CAMPAIGN_ID',
        campaignId: '',
      });
      newCampaignFormDataDispatch({
        type: 'SET_NEW_CAMPAIGN_IS_SUBMITTED',
        isSubmitted: false,
      });
    }
  }, [location.pathname]);

  return null;
}

export default useCampaignCreationScreenChangedEvent;
