import {useEffect, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';

/**
 * clears unwanted search params from the URL
 *
 * Usage: useWhitelistedSearchParams(['filter', 'type']);
 *
 * @param whitelistedParams - array of whitelisted search params
 * @param dependencies - optional array of dependencies to re-trigger the effect
 */
function useWhitelistedSearchParams(
  whitelistedParams: string[],
  dependencies: unknown[] = []
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const deps = useRef(dependencies);

  useEffect(() => {
    let deleted = false;
    searchParams.forEach((value, key) => {
      if (!whitelistedParams.includes(key)) {
        deleted = true;
        searchParams.delete(key);
      }
    });
    if (deleted) {
      setSearchParams(searchParams);
    }
  }, [whitelistedParams, ...deps.current]);
}

export default useWhitelistedSearchParams;
