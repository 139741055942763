import dashboardUserFetchers from '@/api/fetchers/dashboardUser';
import useDataQuery from '@/hooks/use-data-query';

function useDashboardUserCurrentBrandQuery({
  brandId,
  enabled = true,
}: {
  brandId: string | undefined;
  enabled?: boolean;
}) {
  const response: any = useDataQuery({
    queryKey: ['dashboardUserCurrentBrand', brandId],
    queryFn: dashboardUserFetchers.dashboardUserCurrentBrand,
    enabled: !!brandId && enabled,
    meta: {
      brandId,
    },
  });
  const {hasUnreadCollaborations, hasUnreadConversations, hasBrandActionItems} =
    response.data || {};

  return {
    ...response,
    currentBrand: {
      hasUnreadCollaborations,
      hasUnreadConversations,
      hasBrandActionItems,
    },
  };
}
export default useDashboardUserCurrentBrandQuery;
