import contentAgreementTermFetchers from '@/api/fetchers/contentAgreementTerm';
import useDataQuery from '@/hooks/use-data-query';

function useContentAgreementTermsQuery() {
  return useDataQuery({
    queryKey: ['contentAgreementTerms'],
    queryFn: contentAgreementTermFetchers.contentAgreementTerms,
  });
}

export default useContentAgreementTermsQuery;
