export const TABS = {
  OVERVIEW: {
    id: 'overview',
    localeLabelKey: 'tabs.overview.title',
    lockedLabelKey: '',
    publicLocked: false,
  },
  AUDIENCE: {
    id: 'audience',
    localeLabelKey: 'tabs.audience.title',
    lockedLabelKey: 'tabs.audience.locked',
    publicLocked: true,
  },
  ANALYTICS_AND_AUDIENCE: {
    id: 'analyticsAndAudience',
    localeLabelKey: 'tabs.analytics-and-audience.title',
    lockedLabelKey: 'tabs.analytics-and-audience.locked',
    publicLocked: true,
  },
  POSTS: {
    id: 'posts',
    localeLabelKey: 'tabs.posts.title',
    lockedLabelKey: 'tabs.posts.locked',
    publicLocked: true,
  },
  PAST_CAMPAIGNS: {
    id: 'pastCampaigns',
    localeLabelKey: 'tabs.past-campaigns.title',
    lockedLabelKey: '',
    publicLocked: true,
  },
  NOTES: {
    id: 'notes',
    localeLabelKey: 'tabs.notes.title',
    lockedLabelKey: 'tabs.notes.locked',
    publicLocked: true,
  },
};

export const ALL_TABS = [
  TABS.OVERVIEW,
  TABS.AUDIENCE,
  TABS.ANALYTICS_AND_AUDIENCE,
  TABS.POSTS,
  TABS.PAST_CAMPAIGNS,
  TABS.NOTES,
];

export const DEFAULT_TAB_ID = TABS.OVERVIEW.id;

export const TIKTOK_IDENTITY_IDS = ['tiktok_v2', 'tiktok_creator_marketplace'];

export const AUDIENCE_RATING_UNHEALTHY_MIN = 25;
export const AUDIENCE_RATING_INVESTIGATE_MIN = AUDIENCE_RATING_UNHEALTHY_MIN;
export const AUDIENCE_RATING_INVESTIGATE_MAX = 60;

export const GENDER_PERCENTAGE_MOSTLY_THRESHOLD = 55;
