import React from 'react';
import {IconButton, Box} from '@mui/material';
import {Body, designSystemToken} from '@lightricks/react-design-system';

interface DayProps {
  testID: string;
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  startOfSubRange?: boolean;
  endOfSubRange?: boolean;
  isHighlightedEnd?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
}

function Day({
  testID,
  startOfRange,
  endOfRange,
  startOfSubRange,
  endOfSubRange,
  isHighlightedEnd,
  disabled,
  highlighted,
  outlined,
  filled,
  onClick,
  onHover,
  value,
}: DayProps) {
  const containerStyle = {
    display: 'flex',
    borderRadius: () => {
      if ((startOfRange || startOfSubRange) && !isHighlightedEnd) {
        return '50% 0 0 50%';
      }
      if (endOfRange || endOfSubRange || isHighlightedEnd) {
        return '0 50% 50% 0';
      }

      return undefined;
    },
    backgroundColor: () =>
      !disabled && highlighted
        ? designSystemToken('semantic.bg.brand-secondary')
        : undefined,
  };

  const labelStyle = {
    lineHeight: 1.6,
    color: () => {
      if (!disabled) {
        return filled
          ? designSystemToken('semantic.fg.white')
          : designSystemToken('semantic.fg.primary');
      }
      return designSystemToken('semantic.fg.disabled');
    },
  };

  return (
    <Box sx={containerStyle}>
      <IconButton
        data-testid={testID}
        sx={{
          height: '36px',
          width: '36px',
          padding: 0,
          border: () =>
            !disabled && outlined
              ? `1px solid ${designSystemToken('semantic.fg.brand-secondary')}`
              : undefined,
          ...(!disabled && filled
            ? {
                '&:hover': {
                  backgroundColor: () => designSystemToken('semantic.bg.brand'),
                },
                'backgroundColor': () => designSystemToken('semantic.bg.brand'),
              }
            : {}),
        }}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
      >
        <Body size="md" sx={labelStyle}>
          {value}
        </Body>
      </IconButton>
    </Box>
  );
}

export default Day;
