import React from 'react';
import {
  Slider as DiscreteSlider,
  SliderProps as DiscreteSliderProps,
  styled,
} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';

const StyledDiscreteSlider = styled(DiscreteSlider)`
  margin-top: 4px;
  margin-bottom: 16px;

  &.Mui-disabled {
    color: ${() => designSystemToken('semantic.bg.neutral-inverse')};

    .MuiSlider-track {
      background-color: ${() =>
        designSystemToken('semantic.bg.neutral-inverse')};
      height: 2px;
    }

    .MuiSlider-mark {
      background-color: ${() =>
        designSystemToken('semantic.bg.neutral-inverse')};
      height: 8px;
      width: 2px;
      margin-top: 0;

      &.MuiSlider-markActive {
        background-color: ${() =>
          designSystemToken('semantic.bg.neutral-inverse')};
      }
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
      color: #ffffff;
      outline: 2px solid #ffffff;
      border: 2px solid
        ${() => designSystemToken('semantic.bg.neutral-inverse')};
      padding: 2px;
    }
  }

  .MuiSlider-rail {
    background-color: ${() => designSystemToken('semantic.bg.neutral-inverse')};
    height: 2px;
  }

  .MuiSlider-track {
    background-color: ${() => designSystemToken('semantic.fg.primary')};
    height: 2px;
    border: 0;
  }

  .MuiSlider-thumb {
    width: 18px;
    height: 18px;
    color: #ffffff;
    outline: 2px solid #ffffff;
    border: 2px solid #000000;
    padding: 2px;

    &::before {
      box-shadow: none;
    }
  }

  .MuiSlider-mark {
    background-color: ${() => designSystemToken('semantic.bg.neutral-inverse')};
    height: 8px;
    width: 2px;
    margin-top: 0;

    &.MuiSlider-markActive {
      background-color: ${() => designSystemToken('semantic.fg.primary')};
    }
  }
`;

interface SliderProps extends DiscreteSliderProps {
  testID?: string;
  sliderRef?: React.RefObject<HTMLDivElement> | null;
}

function Slider(props: SliderProps) {
  const {testID = 'slider', sliderRef, ...rest} = props;

  return (
    <StyledDiscreteSlider
      data-testid={testID}
      ref={sliderRef}
      marks
      disabled
      {...rest}
    />
  );
}

export default Slider;
