import React from 'react';
import {Menu, MenuItem} from '@mui/material';
import {Icon, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './ListMenu.module.scss';

const TRANSLATION_PREFIX = 'components.list-drawer';

type ListMenuProps = {
  group: CreatorGroupLabel;
  open: boolean;
  onClose: (e: React.MouseEvent) => void;
  anchorEl: null | HTMLElement;
};

function ListMenu(props: ListMenuProps) {
  const {group, open, onClose, anchorEl} = props;
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({
    brandId,
  });

  const buttonProps = {
    appearance: 'secondary' as const,
    size: 'medium' as const,
    color: designSystemToken('semantic.fg.neutral'),
  };

  return (
    <Menu
      slotProps={{
        paper: {className: styles.menuPaper},
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <MenuItem
        sx={{display: 'flex', gap: 1}}
        onClick={() => {
          listCreatorsActions.setActiveListAction('edit-list', group.id);
        }}
      >
        <Icon name="Navigation-Edit" {...buttonProps} />
        {translate(`${TRANSLATION_PREFIX}.list-menu.edit`)}
      </MenuItem>
      <MenuItem
        sx={{display: 'flex', gap: 1}}
        onClick={() => {
          listCreatorsActions.setActiveListAction('delete-list', group.id);
        }}
      >
        <Icon name="Actions-Remove" {...buttonProps} />
        {translate(`${TRANSLATION_PREFIX}.list-menu.delete`)}
      </MenuItem>
      {!subscription.isSelfServe && (
        <MenuItem
          sx={{display: 'flex', gap: 1}}
          onClick={() => {
            listCreatorsActions.setActiveListAction('share-list', group.id);
          }}
        >
          <Icon name="Actions-Share" {...buttonProps} />
          {translate(`${TRANSLATION_PREFIX}.list-menu.share`)}
        </MenuItem>
      )}
    </Menu>
  );
}

export default ListMenu;
