import React, {useState} from 'react';
import {
  Checkbox,
  Body,
  Label,
  designSystemToken,
  Tooltip,
  Icon,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import useClickOutside from '@/hooks/use-click-outside/useClickOutside';
import {useContentGenerationStore} from '@/contexts/ContentGenerationStore';
import styles from './FormatSelector.module.scss';

interface FrameSize {
  name: string;
  width: number;
  height: number;
  isCustomSize?: boolean;
}

interface OptionProps {
  option: FrameSize;
  children?: React.ReactNode;
  customWidth?: string | number;
  customHeight?: string | number;
  showSizesLabel?: boolean;
}

function FormatSelector({
  flowId,
  disabled = false,
}: {
  flowId: string;
  disabled: boolean;
}) {
  const options: FrameSize[] = [
    {name: 'Square', width: 1080, height: 1080},
    {name: 'Vertical', width: 1080, height: 1920},
    {name: 'Widescreen', width: 1920, height: 1080},
    {name: 'Custom Size', isCustomSize: true, width: 1080, height: 1080},
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<FrameSize>(options[0]);
  const [customWidth, setCustomWidth] = useState<string | number>('');
  const [customHeight, setCustomHeight] = useState<string | number>('');
  const {dispatch} = useContentGenerationStore();

  const handleClickOutside = () => {
    if (isOptionCustomSize(selectedOption)) {
      const imageWidth = customWidth
        ? Number(customWidth)
        : selectedOption.width;
      const imageHeight = customHeight
        ? Number(customHeight)
        : selectedOption.height;
      dispatch({
        type: 'UPDATE_IMAGE_SIZE',
        imageWidth,
        imageHeight,
      });
      setCustomHeight(imageHeight);
      setCustomWidth(imageWidth);
    }
    setIsOpen(false);
  };

  const dropdownRef = useClickOutside(handleClickOutside);

  const handleOptionClick = (option: FrameSize) => {
    const isCustomSizeOption = isOptionCustomSize(option);

    const imageWidth =
      isCustomSizeOption && !!customWidth ? Number(customWidth) : option.width;
    const imageHeight =
      isCustomSizeOption && !!customHeight
        ? Number(customHeight)
        : option.height;

    dispatch({
      type: 'UPDATE_IMAGE_SIZE',
      imageWidth,
      imageHeight,
    });

    setSelectedOption(option);
    if (isCustomSizeOption && (!customWidth || !customHeight)) {
      setCustomWidth(imageWidth);
      setCustomHeight(imageHeight);
    }
  };

  return (
    <div
      className={`${styles.dropdownContainer} ${
        disabled ? styles.dropdownContainerDisabled : ''
      }`}
      ref={dropdownRef}
    >
      <div className={styles.titleDropdown}>
        {translate('views.ai-content-generator.components.aspect-ratio.title')}
      </div>
      <div
        className={styles.selectedOption}
        data-testid="format-selector"
        onClick={() => setIsOpen(!isOpen && !disabled)}
      >
        <Option
          option={selectedOption}
          customWidth={customWidth}
          customHeight={customHeight}
        />
        <Icon
          size="small"
          appearance="secondary"
          name="Actions-Arrow-Down-Large"
        />
      </div>
      {isOpen && (
        <ul className={styles.optionsList}>
          {options.map((option) => (
            <li key={option.name}>
              <button
                data-testid={`option-${option.name}`}
                type="button"
                onClick={() => handleOptionClick(option)}
              >
                <Checkbox
                  type="checkbox"
                  checked={selectedOption.name === option.name}
                  readOnly
                  onChange={() => {}}
                />
                <Option
                  option={option}
                  customWidth={customWidth}
                  customHeight={customHeight}
                  showSizesLabel={!option.isCustomSize}
                >
                  {isOptionCustomSize(option) && (
                    <div className={styles.customSizeInputs}>
                      <Label size="xs" className={styles.customSizeLabel}>
                        Width
                      </Label>
                      <input
                        id="customWidth"
                        data-testid="custom-width"
                        type="number"
                        placeholder="1080"
                        value={customWidth}
                        onChange={(e) => setCustomWidth(e.target.value)}
                      />
                      <Label size="xs" className={styles.customSizeLabel}>
                        Height
                      </Label>
                      <input
                        id="customHeight"
                        data-testid="custom-height"
                        type="number"
                        placeholder="1080"
                        value={customHeight}
                        onChange={(e) => setCustomHeight(e.target.value)}
                      />
                    </div>
                  )}
                </Option>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

const isOptionCustomSize = (option: FrameSize) => {
  return !!option.isCustomSize;
};
function Option({
  option,
  children,
  customHeight,
  customWidth,
  showSizesLabel = true,
}: OptionProps) {
  const className = `option-${option.name.toLowerCase().replace(' ', '-')}`;
  const isCustomSizeOption: boolean = isOptionCustomSize(option);
  const width =
    option.width && !isCustomSizeOption ? option.width : customWidth;
  const height =
    option.height && !isCustomSizeOption ? option.height : customHeight;

  return (
    <div className={styles[className]}>
      <div className={styles.figureDiv}>
        <figure />
      </div>
      <div>
        <Body size="sm">{option.name}</Body>
        {showSizesLabel && (
          <Label
            size="xs"
            style={{color: designSystemToken('semantic.fg.tertiary')}}
          >
            {width} x {height}px
          </Label>
        )}
      </div>
      {children}
    </div>
  );
}

export default FormatSelector;
