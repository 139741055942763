import React from 'react';
import {Box, styled} from '@mui/material';

const StyledButtonContainer = styled(Box)`
  padding-top: 16px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
function SubmitButtonContainer({children}: {children: React.ReactNode}) {
  return <StyledButtonContainer>{children}</StyledButtonContainer>;
}

export default SubmitButtonContainer;
