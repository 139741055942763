import planFetchers from '@/api/fetchers/plan';
import useDataQuery from '@/hooks/use-data-query';

function usePlansQuery() {
  const response: any = useDataQuery({
    queryKey: ['plans'],
    queryFn: planFetchers.plans,
    refetchOnWindowFocus: false,
    cacheTime: 86400, // 24 hours
    staleTime: 43200, // 12 hours
  });

  const plansData = response.data;

  return {...response, plansData, isPlansDataFetched: response.isFetched};
}

export default usePlansQuery;
