import React from 'react';
import styles from './BriefSubSection.module.scss';

type BriefSubSectionProps = {
  testID?: string;
  title: string;
  titleClassName?: string;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

function BriefSubSection(props: BriefSubSectionProps) {
  const {
    testID = 'editable-section',
    title,
    titleClassName,
    disabled = false,
    className,
    children,
  } = props;

  const getTitle = () => {
    return (
      <label
        className={`${styles.title} ${titleClassName || ''} ${
          disabled ? styles.disabled : ''
        }`}
      >
        {title}
      </label>
    );
  };

  return (
    <div
      className={`${styles.container} ${className || ''}`}
      data-testid={testID}
    >
      <div className={styles.titleContainer}>{getTitle()}</div>
      {children}
    </div>
  );
}

export default BriefSubSection;
