import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  designSystemToken,
  Icon,
  Label,
  SwitchButton,
  Tooltip,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import PromptInput from '@/views/ai-content-generator/components/prompt-input';
import StyleChip from '@/views/ai-content-generator/components/style-chip/StyleChip';
import {useContentGenerationStore} from '@/contexts/ContentGenerationStore';
import styles from './PromptInputs.module.scss';

type PromptInputsProps = {
  selectedPreset: string;
  prompt: string;
  negativePrompt: string;
  productName: string;
  userPromptLimit: number;
  negativePromptLimit: number;
  isGenerateDisabled: boolean;
  isLoading: boolean;
  onGenerateClick: (numberOfImages?: number) => void;
  isReferenceImageSelected: boolean;
};

function PromptInputs(props: PromptInputsProps) {
  const {
    selectedPreset,
    productName,
    prompt,
    userPromptLimit,
    negativePromptLimit,
    negativePrompt,
    onGenerateClick,
    isGenerateDisabled,
    isLoading,
    isReferenceImageSelected,
  } = props;

  const [activePromptInputType, setActivePromptInputType] = useState<
    'positive' | 'negative' | ''
  >('');
  const [characterLimit, setCharacterLimit] = useState(0);
  const [showNegativePrompt, setShowNegativePrompt] = useState(false);
  const negativeInputRef = useRef<HTMLInputElement | null>(null);

  const {dispatch} = useContentGenerationStore();

  useEffect(() => {
    if (activePromptInputType === 'positive') {
      const CharacterCounter = userPromptLimit - prompt.length;
      setCharacterLimit(CharacterCounter);
    } else if (activePromptInputType === 'negative') {
      const CharacterCounter = negativePromptLimit - negativePrompt.length;
      setCharacterLimit(CharacterCounter);
    } else {
      setCharacterLimit(0);
    }
  }, [activePromptInputType, prompt, negativePrompt]);

  useEffect(() => {
    const inputRef = negativeInputRef?.current;
    if (inputRef && showNegativePrompt) {
      inputRef.focus();
    }
  }, [showNegativePrompt]);

  const toggleNegativePrompt = (isOpen: boolean) => {
    setShowNegativePrompt(isOpen);
    if (!isOpen) {
      setActivePromptInputType('');
      dispatch({
        type: 'UPDATE_USER_NEGATIVE_PROMPT',
        promptValue: '',
      });
    } else {
      setActivePromptInputType('negative');
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.generateActionsContainer}>
          <div className={styles.promptInputContainer}>
            <PromptInput
              inputType="positive"
              promptValue={prompt}
              updaterType="UPDATE_USER_PROMPT"
              startIcon={
                <span className={styles.promptInputStartAdornment}>
                  {productName}:{' '}
                </span>
              }
              placeholder={translate(
                'views.ai-content-generator.dashboard.prompt-input-placeholder'
              )}
              maxLength={userPromptLimit}
              setActiveInput={setActivePromptInputType}
            />

            {showNegativePrompt && (
              <PromptInput
                inputRef={negativeInputRef}
                inputType="negative"
                promptValue={negativePrompt}
                updaterType="UPDATE_USER_NEGATIVE_PROMPT"
                startIcon={
                  <span className={styles.promptInputStartAdornment}>
                    {translate(
                      'views.ai-content-generator.dashboard.negative-prompt-input-start'
                    )}
                    :{' '}
                  </span>
                }
                placeholder={translate(
                  'views.ai-content-generator.dashboard.negative-prompt-input-placeholder'
                )}
                maxLength={negativePromptLimit}
                setActiveInput={setActivePromptInputType}
              />
            )}

            {selectedPreset || isReferenceImageSelected ? (
              <StyleChip
                isReferenceImageSelected={isReferenceImageSelected}
                userPrompt={prompt}
                negativeUserPrompt={negativePrompt}
              />
            ) : null}
          </div>

          <div className={styles.actionsContainer}>
            <Label
              className={styles.characterLimit}
              size="md"
              color={designSystemToken('semantic.fg.neutral-secondary')}
            >
              {!!activePromptInputType && characterLimit}
            </Label>
            <div className={styles.buttonContainer}>
              <div className={styles.switchButtonContainer}>
                <SwitchButton
                  onChange={() => toggleNegativePrompt(!showNegativePrompt)}
                  label={translate(
                    'views.ai-content-generator.dashboard.exclusions'
                  )}
                  checked={showNegativePrompt}
                />
              </div>
              <Tooltip
                title={translate(
                  'views.ai-content-generator.dashboard.example-prompt'
                )}
                arrow
                placement="top"
              >
                <div className={styles.questionMarkTooltip}>
                  <Icon
                    size="medium"
                    appearance="neutral"
                    name="Navigation-Question-Line"
                    color={designSystemToken('semantic.fg.secondary')}
                  />
                </div>
              </Tooltip>
              <Button
                isLoading={isLoading}
                disabled={isGenerateDisabled}
                appearance="neutral"
                mode="filled"
                size="medium"
                onClick={() => !isLoading && onGenerateClick()}
                icon={
                  <Icon
                    size="medium"
                    appearance="tertiary"
                    name="StateBar-Effects"
                  />
                }
              >
                {translate(
                  'views.ai-content-generator.dashboard.generate-button'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromptInputs;
