import emailPreferencesFetchers from '@/api/fetchers/emailPreferences';
import useDataQuery from '@/hooks/use-data-query';

function useEmailPreferencesQuery() {
  const response = useDataQuery({
    queryKey: ['emailPreferences'],
    queryFn: emailPreferencesFetchers.emailPreferences,
    enabled: true,
    meta: {},
  });

  return {
    ...response,
    emailPreferences: response.data,
  };
}

export default useEmailPreferencesQuery;
