import React from 'react';
import {Box, Stack} from '@mui/material';
import {Body, Button, Headline, Modal} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

const TRANSLATION_PREFIX = 'views.creators.components.creator-action';

function SuccessModal({
  title,
  subtitle,
  isOpen,
  close,
}: {
  title: string;
  subtitle: string;
  isOpen: boolean;
  close: () => void;
}) {
  return (
    <Modal open={isOpen} handleClose={close} showCloseButton size="small">
      <Stack gap={3} width="100%">
        <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
          <Headline size="md">{title}</Headline>
        </Box>
        <Body
          size="lg"
          sx={{paddingInline: 2, whiteSpace: 'pre-wrap', textAlign: 'start'}}
        >
          {subtitle}
        </Body>
        <Button
          appearance="neutral"
          mode="filled"
          size="medium"
          onClick={close}
        >
          {translate(`${TRANSLATION_PREFIX}.success-button`)}
        </Button>
      </Stack>
    </Modal>
  );
}

export default SuccessModal;
