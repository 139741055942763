import React from 'react';
import styles from './TabWrapper.module.scss';

interface TabContainerProps {
  testID?: string;
  className?: string;
  children: React.ReactNode;
}

function TabWrapper(props: TabContainerProps) {
  const {testID = 'tab-wrapper', className, children} = props;
  return (
    <div
      className={`${styles.tabWrapperContainer} ${className}`}
      data-testid={testID}
    >
      {children}
    </div>
  );
}

export default TabWrapper;
