import clsx from 'clsx';
import {MRT_ColumnDef} from 'material-react-table';
import React from 'react';
import translate from '@/utils/translate';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import Table from '@/components/table';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import styles from './ListCreatorsTable.module.scss';
import {
  CreatorNameCell,
  CreatorNameHeader,
  DateAddedCell,
  ListsCell,
  StatusCell,
} from './components/table-cells';

const TRANSLATION_PREFIX = 'components.list-creators-table';

type ListCreatorsTableProps = {
  data: CreatorGroupMembership[];
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
};

function ListCreatorsTable(props: ListCreatorsTableProps) {
  const {data, isLoading, isFetching, fetchNextPage} = props;
  const sorting = useListCreatorsStore((state) => state.sorting);
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const tableColumns: MRT_ColumnDef<CreatorGroupMembership>[] = [
    {
      header: translate(`${TRANSLATION_PREFIX}.header.name`),
      Cell: (_props) => <CreatorNameCell {..._props} />,
      accessorKey: 'creator_name',
      Header: <CreatorNameHeader memberships={data} isLoading={isLoading} />,
    },
    {
      header: translate(`${TRANSLATION_PREFIX}.header.date`),
      Cell: DateAddedCell,
      accessorKey: 'created_at',
      enableSorting: true,
      maxSize: 100,
    },
    {
      header: translate(`${TRANSLATION_PREFIX}.header.status`),
      Cell: StatusCell,
      accessorKey: 'status',
      maxSize: 100,
    },
    {
      header: translate(`${TRANSLATION_PREFIX}.header.lists`),
      Cell: ListsCell,
      accessorKey: 'label',
    },
  ];

  const selectedCreatorsMap = Object.fromEntries(
    Array.from(selectedCreators, ([key]) => [key, true])
  );

  return (
    <Table
      classes={{
        container: clsx(styles.container, isFetching && styles.loading),
        footer: selectedCreators.size > 0 ? styles.highFooter : '',
      }}
      columns={tableColumns}
      data={data ?? []}
      isLoadingInitial={isLoading}
      showProgressBars={false} // TODO: use isRefetching
      visibleColumns={{creatorName: true}}
      rowSelection={selectedCreatorsMap}
      sorting={sorting}
      onSorting={listCreatorsActions.setSorting}
      isLoading={isFetching}
      onEndReached={() => {
        fetchNextPage();
      }}
      enableVirtualization
      enableSorting
    />
  );
}

export default ListCreatorsTable;
