import React, {useMemo} from 'react';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import {designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {HorizontalLineRounded} from '@/components/unusual-icons';
import styles from './StepperProgress.module.scss';

const TRANSLATION_PREFIX = 'components.stepper-progress';

export type StepperProgressProps = {
  testID?: string;
  activeStep?: number;
  steps: number;
  stepperWidth?: number;
};

function StepperProgress(props: StepperProgressProps) {
  const {
    testID = 'stepper-progress',
    activeStep = 0,
    steps,
    stepperWidth = 112,
  } = props;

  const stepperGap = 4;
  const stepWidth = (stepperWidth - stepperGap * (steps - 1)) / steps;

  const stepsArray = useMemo(
    () =>
      Array(steps)
        .fill(null)
        .map((_, index) => index),
    [steps]
  );

  return (
    <div className={styles.container} style={{width: stepperWidth}}>
      <Stepper
        activeStep={activeStep}
        connector={null}
        data-testid={testID}
        style={{display: 'flex', gap: stepperGap, height: 8}}
      >
        {stepsArray.map((index) => (
          <Step key={`stepper-progress-${index}`} className={styles.step}>
            <HorizontalLineRounded
              color={
                activeStep >= index
                  ? designSystemToken('reference.purple.50')
                  : designSystemToken('reference.purple.10')
              }
              width={stepWidth}
              height={8}
              className={styles.horizontalLineRoundedIcon}
            />
          </Step>
        ))}
      </Stepper>
      <Typography
        className={styles.progressIndicator}
        variant="subtitle2"
        component="span"
      >
        {translate(`${TRANSLATION_PREFIX}.indicator`, {
          activeStep: activeStep + 1,
          steps,
        })}
      </Typography>
    </div>
  );
}

export default StepperProgress;
