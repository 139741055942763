import clsx from 'clsx';
import React from 'react';
import Link from '@/components/link';
import styles from './BreadcrumbLabel.module.scss';

type BreadcrumbLabelProps = {
  testID?: string;
  label: string | React.ReactNode;
  link?: string | undefined | null;
  isFocused?: boolean;
  className?: string;
};

function BreadcrumbLabel(props: BreadcrumbLabelProps) {
  const {
    testID = 'breadcrumb-label',
    label,
    link,
    isFocused,
    className,
  } = props;

  const classes = clsx([
    link ? styles.labelLink : styles.label,
    className,
    isFocused && styles.labelFocused,
  ]);

  const Content = (
    <span data-testid={testID} className={classes}>
      {label}
    </span>
  );

  return link ? (
    <Link testID={`${testID}--link`} to={link}>
      {Content}
    </Link>
  ) : (
    Content
  );
}

export default BreadcrumbLabel;
