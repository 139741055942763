import {ColumnSort, SortingState} from '@tanstack/table-core';

const getParamFromSortingState = (sorting: SortingState) => {
  const sort = sorting
    .map((column: ColumnSort) => (column.desc ? `-${column.id}` : column.id))
    .join(',');

  return {sort};
};

export default getParamFromSortingState;
