export enum SortField {
  engagementRate = 'engagementRate',
  followers = 'followers',
  relevancy = 'relevancy',
  rating = 'rating',
}

export const DefaultSortField = SortField.rating;
export const DefaultSortDirection = 'DESC';
export default SortField;
