import {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {UNAUTHENTICATED_ROUTES} from '@/config/routesConstants';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useToken from '@/hooks/use-token';

function useSetBrandIdFromLoggedInDashboardUser() {
  const {isAuthenticated, initialized} = useToken(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const brandId = useBrandId();
  const location = useLocation();

  const {dashboardUser}: any = useDashboardUserQuery({
    overrideEnabled: isAuthenticated,
    include: [],
  });

  useEffect(() => {
    const isUnauthenticatedRoute = UNAUTHENTICATED_ROUTES.find((route) =>
      new RegExp(route, 'i').test(location.pathname)
    );

    if (
      isAuthenticated &&
      initialized &&
      !brandId &&
      dashboardUser?.currentBrandId &&
      !isUnauthenticatedRoute
    ) {
      searchParams.set('brandId', dashboardUser?.currentBrandId);
      setSearchParams(searchParams, {replace: true});
    }
  }, [isAuthenticated, initialized, dashboardUser, brandId, location.pathname]);
}

export default useSetBrandIdFromLoggedInDashboardUser;
