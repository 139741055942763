import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Search from '@/views/creators/search';

export const SEARCH_ROUTES = {
  MAIN: '',
};

function SearchRoutes() {
  return (
    <Routes>
      <Route path={SEARCH_ROUTES.MAIN} element={<Search />} />
    </Routes>
  );
}

export default SearchRoutes;
