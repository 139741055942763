import translate from '@/utils/translate';
import {SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET} from '@/views/campaigns/brief/constants';
import {
  BriefAiWizardFormProps,
  FormValidatorsMapProps,
  RequiredDeliverable,
} from './BriefAiWizardProps';

const TRANSLATION_PREFIX = 'views.campaigns.components.wizard';
const MIN_BUDGET = 1000;
const MAX_REQUIRED_DELIVERABLES = 10;

export function stringToNumber(value: string | number) {
  if (typeof value === 'number') {
    return value;
  }

  return Number.isNaN(Number(value)) ? 0 : Number(value);
}

const formValidatorsMap: () => FormValidatorsMapProps = () => ({
  budget: [
    {
      validator: (_budget: number, formData: BriefAiWizardFormProps) => {
        const budgetNumber = stringToNumber(formData.budget || 0);
        const customBudgetNumber = stringToNumber(formData.customBudget || 0);

        const isBudgetValid =
          budgetNumber >= MIN_BUDGET || customBudgetNumber >= MIN_BUDGET;

        const zeroBudgetAllowed =
          !!formData.subscriptionType &&
          SUBSCRIPTION_TYPES_ALLOWED_ZERO_BUDGET.includes(
            formData.subscriptionType
          );

        return isBudgetValid || zeroBudgetAllowed;
      },
      errorMessage: translate(`${TRANSLATION_PREFIX}.errors.budget`),
    },
  ],

  requiredDeliverables: [
    {
      validator: (
        requiredDeliverables: RequiredDeliverable[],
        formData: BriefAiWizardFormProps,
        creatorTypes: any
      ) => {
        if (
          requiredDeliverables.length === 0 ||
          (formData.customBudget && stringToNumber(formData.customBudget) === 0)
        ) {
          return true;
        }
        return creatorTypes.some(
          (creatorType: any) => creatorType.totalCreators > 0
        );
      },
      errorMessage: translate(
        `${TRANSLATION_PREFIX}.errors.required-deliverables-budget`
      ),
    },
    {
      validator: (
        requiredDeliverables: RequiredDeliverable[],
        formData: BriefAiWizardFormProps,
        creatorTypes: any
      ) => {
        return requiredDeliverables.length <= MAX_REQUIRED_DELIVERABLES;
      },
      errorMessage: translate(
        `${TRANSLATION_PREFIX}.errors.required-deliverables-limit`
      ),
    },
  ],
  // creatorType: [
  //   {
  //     validator: (creatorType: string) => !!creatorType,
  //     errorMessage: translate(`${TRANSLATION_PREFIX}.errors.creator-type`)
  //   }
  // ],
  // sendProductToCreators: [
  //   {
  //     validator: (sendProductToCreators: boolean, formData: WizardFormProps) =>
  //       !sendProductToCreators ||
  //       (sendProductToCreators &&
  //         !!formData.sendProductToCreatorsMethod &&
  //         !!formData.sendProductToCreatorsCashValue),
  //     errorMessage: translate(`${TRANSLATION_PREFIX}.errors.send-product-to-creators`)
  //   }
  // ]
});

export default formValidatorsMap;
