export const VETTING_CATEGORY = {
  SEXUAL: 'SEXUAL',
  ADDICTIVE: 'ADDICTIVE',
  VIOLENCE_AND_DANGER: 'VIOLENCE_AND_DANGER',
  SENSITIVE: 'SENSITIVE',
  LEGAL: 'LEGAL',
  OFFENSIVE_LANGUAGE: 'OFFENSIVE_LANGUAGE',
};

export const VETTING_FLAGS_TO_CATEGORY_MAPPING = {
  sexualImagery: VETTING_CATEGORY.SEXUAL,
  sexualLanguage: VETTING_CATEGORY.SEXUAL,
  tobaccoAndNicotine: VETTING_CATEGORY.ADDICTIVE,
  drugs: VETTING_CATEGORY.ADDICTIVE,
  alcohol: VETTING_CATEGORY.ADDICTIVE,
  physicalViolence: VETTING_CATEGORY.VIOLENCE_AND_DANGER,
  harmAndInjury: VETTING_CATEGORY.VIOLENCE_AND_DANGER,
  dangerousStuntsAndPranks: VETTING_CATEGORY.VIOLENCE_AND_DANGER,
  politics: VETTING_CATEGORY.SENSITIVE,
  religiousContent: VETTING_CATEGORY.SENSITIVE,
  hateAndDiscrimination: VETTING_CATEGORY.OFFENSIVE_LANGUAGE,
  controversialTopics: VETTING_CATEGORY.SENSITIVE,
  gambling: VETTING_CATEGORY.ADDICTIVE,
  fraudAndLegalRisks: VETTING_CATEGORY.LEGAL,
  profanity: VETTING_CATEGORY.OFFENSIVE_LANGUAGE,
} as {[key: string]: string};

export enum Severity {
  ALL = 'ALL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const SEVERITY_THRESHOLDS = {
  [Severity.HIGH]: 1,
  [Severity.MEDIUM]: 0.66,
  [Severity.LOW]: 0.33,
};

export const FAILED_SCAN_FLAG = 'FailedScan';

export enum VettingStatus {
  approved = 'approved',
  declined = 'declined',
  ready_for_review = 'ready_for_review',
  invited = 'invited',
  in_progress = 'in_progress',
}

export const CONTENT_MODAL_ANALYTICS_PREFIX = 'Content Modal:';
