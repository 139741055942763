import React, {useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import {CreatorProfileAbilities, ProfileProps} from '@/types/creatorProfile';
import {DashboardUser} from '@/types/dashboardUser';
import PoppaysLoader from '@/components/PoppaysLoader';
import Conditional from '@/components/conditional';
import CreatorProfileTabContent from '@/components/creator-profile/components/creator-profile-tab-content';
import CreatorProfileTabs from '@/components/creator-profile/components/creator-profile-tabs';
import {DEFAULT_TAB_ID, TABS} from '@/components/creator-profile/constants';
import useCreatorProfileAbilities from '@/components/creator-profile/hooks/use-creator-profile-abilities';
import useCreatorProfileTabs from '@/components/creator-profile/hooks/use-creator-profile-tabs';
import CREATOR_PROFILE_TEST_IDS from '@/components/creator-profile/testIds';
import useTrackButtonEvent from '@/hooks/analytics/use-track-button-event';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useBrandProfileNoteQuery from '@/hooks/queries/use-brand-profile-note-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useBrandId from '@/hooks/use-brand-id';
import styles from './CreatorProfile.module.scss';
import Header from './components/header';

export interface CreatorProfileProps {
  testID?: string;
  creatorProfileId?: string;
  containerClassName?: string;
  isLoading?: boolean;
  profile: ProfileProps;
  dashboardUser?: DashboardUser;
  displayPublicDataOnly?: boolean;
  componentAllowsAddMembership: boolean;
  componentAllowsCollaboration: boolean;
  componentAllowsInviteToCampaign: boolean;
  canViewAudience?: boolean;
  canViewSocialHealth?: boolean;
  canViewPortfolio?: boolean;
  canViewPosts?: boolean;
  canViewProfileNotes?: boolean;
  showApplicationAnswers?: boolean;
  isModal?: boolean;
  isSharedList?: boolean;
  loaderContainerClassName?: string;
}

interface SharedListTabPriority {
  key: keyof CreatorProfileAbilities;
  tabId: string;
  applyParam: boolean;
}

const SHARED_LIST_TAB_PRIORITY: SharedListTabPriority[] = [
  {key: 'canViewPortfolio', tabId: TABS.OVERVIEW.id, applyParam: false},
  {
    key: 'canViewAudience',
    tabId: TABS.ANALYTICS_AND_AUDIENCE.id,
    applyParam: true,
  },
  {key: 'canViewPosts', tabId: TABS.POSTS.id, applyParam: true},
];

function usePrefetchRequiredData(
  profileId: string | undefined,
  enabled: boolean
) {
  const brandId = useBrandId();
  // prefetch brand profile note
  useBrandProfileNoteQuery({
    brandId,
    profileId,
    enabled: enabled && !!brandId,
  });

  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId, enabled});
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  useCreatorGroupsLabelsQuery({creatorGroupId, enabled});
}

function CreatorProfile(props: CreatorProfileProps) {
  const {
    testID = CREATOR_PROFILE_TEST_IDS.MAIN,
    creatorProfileId,
    containerClassName = '',
    profile,
    isLoading,
    dashboardUser,
    displayPublicDataOnly = false,
    componentAllowsAddMembership,
    componentAllowsCollaboration,
    componentAllowsInviteToCampaign,
    canViewAudience = true,
    canViewSocialHealth = true,
    canViewPortfolio = true,
    canViewPosts = true,
    canViewProfileNotes = true,
    showApplicationAnswers = false,
    isModal = false,
    isSharedList = false,
    loaderContainerClassName = '',
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsActiveTabId = searchParams.get('activeTabId');
  const trackButtonEvent = useTrackButtonEvent();

  const creatorProfileAbilities = useCreatorProfileAbilities({
    profile,
    dashboardUserPermissions: dashboardUser?.permissions || [],
    displayPublicDataOnly,
    componentAllowsAddMembership,
    componentAllowsCollaboration,
    componentAllowsInviteToCampaign,
    canViewAudience,
    canViewSocialHealth,
    canViewPortfolio,
    canViewPosts,
    canViewProfileNotes,
    showApplicationAnswers,
  });

  usePrefetchRequiredData(
    profile?.id,
    !!profile && !creatorProfileAbilities.displayPublicDataOnly
  );

  /* ====== Analytics functions ====== */
  const trackButtonPressed = (buttonName: string, overrideParams = {}) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    trackButtonEvent.pressed({
      button_name: buttonName,
      screen_name: screenName,
      creator_id: profile?.id || creatorProfileId || '',
      tab: activeTabId,
      ...overrideParams,
    });
  };

  /* ====== Handlers ====== */
  const onTabChange = (tabId: string) => {
    if (tabId === DEFAULT_TAB_ID) {
      searchParams.delete('activeTabId');
    } else {
      searchParams.set('activeTabId', tabId);
    }
    setSearchParams(searchParams);
  };

  /* ====== Side-effects ====== */
  useEffect(() => {
    if (
      creatorProfileAbilities.displayPublicDataOnly &&
      !!searchParamsActiveTabId
    ) {
      onTabChange(DEFAULT_TAB_ID);
    }
  }, [creatorProfileAbilities.displayPublicDataOnly, searchParamsActiveTabId]);

  useEffect(() => {
    if (!isSharedList) {
      return;
    }
    const firstTab = SHARED_LIST_TAB_PRIORITY.find(
      (tab) => creatorProfileAbilities[tab.key]
    );

    if (firstTab) {
      onTabChange(firstTab.tabId);
    }
  }, []);

  const activeTabId = useMemo(() => {
    if (displayPublicDataOnly) {
      return DEFAULT_TAB_ID;
    }
    return searchParamsActiveTabId || DEFAULT_TAB_ID;
  }, [creatorProfileAbilities.displayPublicDataOnly, searchParamsActiveTabId]);

  const tabs = useCreatorProfileTabs({
    profile,
    creatorProfileAbilities,
  });

  if (isLoading || !profile) {
    return (
      <div className={loaderContainerClassName} style={{position: 'relative'}}>
        <PoppaysLoader absolute className={styles.loader} />
      </div>
    );
  }

  return (
    <div
      className={`${styles.creatorProfileContainer} ${containerClassName} ${
        isModal ? styles.isModal : ''
      }  ${isSharedList ? styles.noBackground : ''}`}
      data-testid={testID}
    >
      <Header
        profile={profile}
        creatorProfileAbilities={creatorProfileAbilities}
        trackButtonPressed={trackButtonPressed}
        isModal={isModal}
      />
      <Conditional condition={creatorProfileAbilities.showTabs}>
        <CreatorProfileTabs
          tabs={tabs}
          activeTabId={activeTabId}
          creatorProfileAbilities={creatorProfileAbilities}
          isSharedList={isSharedList}
          onChange={onTabChange}
          trackButtonPressed={trackButtonPressed}
        />
      </Conditional>
      <div className={styles.tabContentContainer}>
        <CreatorProfileTabContent
          activeTabId={activeTabId}
          profile={profile}
          creatorProfileAbilities={creatorProfileAbilities}
          isSharedList={isSharedList}
          trackButtonPressed={trackButtonPressed}
        />
      </div>
    </div>
  );
}

export default CreatorProfile;
