import {create, StateCreator} from 'zustand';
import {persist} from 'zustand/middleware';

type RedirectAfterAuthState = {
  redirectUrlAfterAuth: string | null;
};

const redirectSlice: StateCreator<
  RedirectAfterAuthState,
  [['zustand/persist', unknown]]
> = () => ({
  redirectUrlAfterAuth: null,
});

const redirectAfterAuthStore = create<RedirectAfterAuthState>()(
  persist(redirectSlice, {
    name: 'redirectAfterAuthStore',
  })
);

const setRedirectUrlAfterAuth = (redirectUrlAfterAuth: string | null) =>
  redirectAfterAuthStore.setState({redirectUrlAfterAuth});

export const redirectAfterAuthActions = {
  setRedirectUrlAfterAuth,
};

export default redirectAfterAuthStore;
