import React, {useEffect, useState} from 'react';
import {
  Button,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import isMobile from '@/utils/identifyDevice';
import styles from './WorkflowCard.module.scss';

const SearchCreatorImageSrc = '/assets/images/search-creators-desktop.png';
const SearchCreatorHoverImageSrc = '/assets/images/search-creators-desktop.png';
const SearchCreatorMobileImageSrc = '/assets/images/search-creators-mobile.png';
const StartCampaignImageSrc = '/assets/images/start-campaign-desktop.png';
const StartCampaignHoverImageSrc = '/assets/images/start-campaign-desktop.png';
const StartCampaignMobileImageSrc = '/assets/images/start-campaign-mobile.png';
export const TRANSLATION_PREFIX = 'views.home.workflows.content';

const LG_WIDTH = 1023;
const XL_WIDTH = 1279;
export type WorkflowCardProps = {
  testID?: string;
  title: string;
  description: string;
  buttonLabel: string;
  action: () => void;
  modalComponent?: React.FC<{
    open: boolean;
    onClose: () => void;
    centered?: boolean;
  }>;
  modalComponentProps?: object;
  svgVariant: 'green' | 'blue';
  backgroundColor: keyof typeof backgroundColors;
};

function WorkflowCard(props: WorkflowCardProps) {
  const {
    testID = 'workflow-card',
    title,
    description,
    buttonLabel,
    action,
    modalComponent,
    modalComponentProps = {},
    svgVariant,
    backgroundColor,
  } = props;
  const [isScreenLG, setIsScreenLG] = useState(isMobile(LG_WIDTH));
  const [isScreenXL, setIsScreenXL] = useState(isMobile(XL_WIDTH));
  const [tempSize, setTempSize] = useState<'lg' | 'xl'>(
    isScreenXL ? 'lg' : 'xl'
  );
  const sizeTitle = isScreenLG ? 'md' : tempSize;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const images = {
    green: isScreenLG ? StartCampaignMobileImageSrc : StartCampaignImageSrc,
    blue: isScreenLG ? SearchCreatorMobileImageSrc : SearchCreatorImageSrc,
  };
  const [imageSrc, setImageSrc] = useState(images[backgroundColor]);

  useEffect(() => {
    function handleResize() {
      setIsScreenLG(isMobile(LG_WIDTH));
      setIsScreenXL(isMobile(XL_WIDTH));
      setImageSrc(images[backgroundColor]);
    }

    window.addEventListener('resize', handleResize);

    handleResize();
  }, []);
  const onClick = () => {
    if (modalComponent) setIsModalOpen(true);
    action();
  };
  const [activeHover, setActiveHover] = useState(false);

  useEffect(() => {
    setImageSrc(
      activeHover ? imagesHover[backgroundColor] : images[backgroundColor]
    );
  }, [activeHover]);
  return (
    <>
      <div
        className={styles.container}
        data-testid={testID}
        onClick={onClick}
        onMouseEnter={() => setActiveHover(true)}
        onMouseLeave={() => setActiveHover(false)}
      >
        <div
          className={`${styles.workflowCard}`}
          style={
            isScreenLG || activeHover
              ? {background: backgroundColors[backgroundColor]}
              : {}
          }
        >
          <div className="empty" />

          <div className={styles.innerContent}>
            <div className={styles.imageContainer}>
              <div className={styles.bgOverflowContainer}>
                <div
                  className={styles.bgContainer}
                  style={{
                    backgroundImage: `url(${
                      isScreenLG ? images[backgroundColor] : imageSrc
                    })`,
                  }}
                />
                <img
                  src={isScreenLG ? images[backgroundColor] : imageSrc}
                  alt={title}
                />
              </div>
            </div>

            <div className={styles.content}>
              <div className={styles.text}>
                <Headline size={sizeTitle}>{title}</Headline>
                <Icon
                  size={isScreenXL ? 'small' : 'large'}
                  appearance="black"
                  name="Actions-Arrow-Forward-Small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen &&
        modalComponent &&
        React.createElement(modalComponent, {
          ...modalComponentProps,
          open: isModalOpen,
          onClose: () => setIsModalOpen(false),
        })}
    </>
  );
}

export default WorkflowCard;

const backgroundColors = {
  green: 'var(--semantic-bg-brand-secondary, #E0FAF5)',
  blue: 'var(--comp-button-bg-danger-plain-hover,#E5F2FF)',
};

const imagesHover = {
  green: StartCampaignHoverImageSrc,
  blue: SearchCreatorHoverImageSrc,
};
