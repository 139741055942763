import {useEffect, useState} from 'react';
import checkIsMobile from '@/utils/identifyDevice';

function useIsMobile(customMaxWidth?: number | undefined) {
  const [isMobile, setIsMobile] = useState(checkIsMobile(customMaxWidth));

  useEffect(() => {
    function onResize() {
      setIsMobile(checkIsMobile(customMaxWidth));
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return isMobile;
}

export default useIsMobile;
