import React, {useEffect, useState} from 'react';

const CARD_WIDTH_MIN = 296 + 24; // Adding the inline margin that the shadow adds
const DEFAULT_CARD_HEIGHT = 496;
const FILTERED_PLATFORM_CARD_HEIGHT = 526;
const VERTICAL_GAP = 24;
const HORIZONTAL_GAP = 0; // The shadow adds margin of 12px to the card, so the gap is 24

interface VirtualizedGridContainerProps {
  gap: {x: number; y: number};
  columns: number;
  itemSize: {width: number; height: number};
}

function useCreatorsVirtualizedGridProps(
  virtualizedGridContainerRef: React.RefObject<HTMLDivElement>,
  isPlatformFiltered: boolean,
  dependencies: unknown[] = []
): VirtualizedGridContainerProps {
  const [virtualizedGridProps, setVirtualizedGridProps] = useState({
    gap: {x: HORIZONTAL_GAP, y: VERTICAL_GAP},
    columns: 1,
    itemSize: {
      width: CARD_WIDTH_MIN,
      height: isPlatformFiltered
        ? FILTERED_PLATFORM_CARD_HEIGHT
        : DEFAULT_CARD_HEIGHT,
    },
  });

  useEffect(() => {
    function onResize() {
      if (!virtualizedGridContainerRef?.current) {
        return;
      }
      const containerWidth = virtualizedGridContainerRef.current.clientWidth;
      if (containerWidth) {
        const columns = Math.max(
          Math.floor(containerWidth / (CARD_WIDTH_MIN + HORIZONTAL_GAP)),
          1
        );
        const itemWidth =
          (containerWidth - HORIZONTAL_GAP * (columns - 1)) / columns;

        setVirtualizedGridProps({
          gap: {x: HORIZONTAL_GAP, y: VERTICAL_GAP},
          columns,
          itemSize: {
            width: itemWidth,
            height: isPlatformFiltered
              ? FILTERED_PLATFORM_CARD_HEIGHT
              : DEFAULT_CARD_HEIGHT,
          },
        });
      }
    }
    onResize(); // Call onResize for initial setup
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [
    virtualizedGridContainerRef?.current?.clientWidth,
    isPlatformFiltered,
    ...dependencies,
  ]);

  return virtualizedGridProps;
}

export default useCreatorsVirtualizedGridProps;
