import numeral from 'numeral';

const PRIMARY_DEFAULT_FORMAT = '0.0a';

function numberAbbreviation(number: number, format?: string) {
  let secondaryDefaultFormat = format;
  if (number < 1000) {
    secondaryDefaultFormat = '0,0';
  }
  if (number > 100000) {
    secondaryDefaultFormat = '0.00a';
  }

  return numeral(number).format(
    format || secondaryDefaultFormat || PRIMARY_DEFAULT_FORMAT
  );
}

export default numberAbbreviation;
