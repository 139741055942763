import React from 'react';
import {Checkbox, Input} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import SendProductToCreators from '../../components/send-product-to-creators';
import StepContainer from '../../components/step-container';
import StepSectionContainer from '../../components/step-section-container';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import {StepProps} from '../StepProps';
import styles from './Product.module.scss';

const TRANSLATION_PREFIX = 'views.campaigns.components.wizard.steps.product';
const SEND_PRODUCT_TRANSLATION_PREFIX = `${TRANSLATION_PREFIX}.send-product-to-creators`;

function Product(props: StepProps) {
  const {
    testID,
    formData,
    formErrors,
    updateFormData,
    onClickNext,
    canContinue,
    onSubmit,
  } = props;

  return (
    <div className={styles.container}>
      <StepContainer
        onClickContinue={onSubmit}
        continueButtonDisabled={!canContinue}
        testID={testID}
        continueButtonLabel={translate(
          `${TRANSLATION_PREFIX}.continue-button-label`
        )}
      >
        <StepSectionContainer
          title={translate(`${TRANSLATION_PREFIX}.product-label`)}
        >
          <div className={styles.productInputContainer}>
            <Input
              testID={BRIEF_WIZARD_TEST_IDS.PRODUCT__PRODUCT_NAME_INPUT}
              value={formData.product}
              autoFocus
              inputProps={{
                placeholder: translate(
                  `${TRANSLATION_PREFIX}.product-input-placeholder`
                ),
              }}
              onInputChange={(value: string) =>
                updateFormData?.({product: value})
              }
              sx={{
                '.MuiInputBase-root': {
                  border: 'solid 2px rgba(44, 45, 48, 0.12156862745098039)',
                },
                '.Mui-focused': {
                  '&.MuiInputBase-root': {
                    border: 'solid 2px black',
                  },
                },
              }}
            />
          </div>
        </StepSectionContainer>
        <StepSectionContainer
          title={translate(
            `${SEND_PRODUCT_TRANSLATION_PREFIX}.send-product-to-creators-label`
          )}
          containerClassName={styles.sendProductToCreatorsContainer}
        >
          <div className={styles.sendProductToCreators}>
            <label className={styles.sendProductToCreatorsDescription}>
              {translate(
                `${SEND_PRODUCT_TRANSLATION_PREFIX}.send-product-to-creators-description`
              )}
            </label>
            <SendProductToCreators
              sendProductToCreators={formData.sendProductToCreators}
              sendProductToCreatorsMethod={formData.sendProductToCreatorsMethod}
              sendProductToCreatorsCashValue={
                formData.sendProductToCreatorsCashValue
              }
              onChange={(sendProductToCreatorsData) =>
                updateFormData?.(sendProductToCreatorsData)
              }
            />
          </div>
        </StepSectionContainer>
      </StepContainer>
    </div>
  );
}

export default Product;
