import React from 'react';
import {Outlet, NavLink, useLocation} from 'react-router-dom';
import Page from '@/components/page';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useToken from '@/hooks/use-token';
import translate from '../../utils/translate';
import styles from './Preferences.module.scss';

function Preferences() {
  const {isAuthenticated} = useToken(true);
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  const isAdmin = dashboardUser?.isAdmin;
  const TRANSLATION_PREFIX = 'components.preferences';
  const location = useLocation();

  return (
    <Page
      id="preferences"
      className={styles.container}
      childrenClassName={styles.content}
    >
      <div>
        <nav className={styles.tabNavigation}>
          <NavLink
            to="/preferences/account"
            className={({isActive}) =>
              isActive || location.pathname === '/preferences'
                ? styles.activeLink
                : undefined
            }
          >
            {translate(`${TRANSLATION_PREFIX}.tabs.account`)}
          </NavLink>
          {!isAdmin && (
            <NavLink
              to="/preferences/notifications"
              className={({isActive}) =>
                isActive ? styles.activeLink : undefined
              }
            >
              {translate(`${TRANSLATION_PREFIX}.tabs.notifications`)}
            </NavLink>
          )}
        </nav>
        <Outlet />
      </div>
    </Page>
  );
}

export default Preferences;
