import {ROUTES} from '@/config/routesConstants';

export function getShareMediaLink(
  contentSubmissionId: string,
  kind: 'photo' | 'video' | 'blog'
): string {
  const getPreviewsRoute = (mediaKind: string) => {
    if (mediaKind === 'video') {
      return ROUTES.VIDEO_PREVIEWS;
    }
    return ROUTES.PHOTO_PREVIEWS;
  };
  const previewsRoute = getPreviewsRoute(kind);
  const {host, protocol} = window.location;
  return `${protocol}//${host}${previewsRoute}/${contentSubmissionId}`;
}

export default getShareMediaLink;
