import {useState} from 'react';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {v4 as uuid} from 'uuid';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import {getEnv} from '@/config/environment';
import {MessageProps} from '@/components/chat/Chat';
import useLtxAuthToken from '@/hooks/use-ltx-auth-token';

const VALID_JSON_REGEX =
  '((\\[[^\\}]+)?\\{s*[^\\}\\{]{3,}?:.*\\}([^\\{]+\\])?)';
const WS_URL = getEnv().VITE_AI_AGENT_WEBSOCKET_URL || 'ws://localhost:8000/ws';

export const AGENT_SENDER_ID = 'AGENT_ASSISTANT';
const AGENT_SENDER_NAME = 'Agent Assistant';
export const END_WRITE_BRIEF_MESSAGE = 'END_WRITE_BRIEF';
export const WAITING_USER_REPLY = 'WAITING_USER_REPLY';
export const START_WRITE_BRIEF_MESSAGE =
  'I am working on generating your brief section by section...';
export const BRIEF_AI_AGENT_READY_MESSAGE = 'BRIEF_AI_AGENT_READY';
export const BRIEF_INFORMATION_NOT_FOUND_MESSAGE =
  'BRIEF_AI_INFORMATION_NOT_FOUND';

const MOCK_BRIEF_FORM_DATA = {
  brand_name: 'Valley Rays',
  website_url: 'https://www.valleyrays.com/',
  budget: '20000',
  product: 'sedona',
  campaign_guidelines:
    'make sure to include the brand name and the product name in the hashtag',
  network: 'instagram',
  required_deliverables: {
    story_post: 1,
    reel_post: 2,
    feed_post_photo: 3,
    story_post_number_of_frames: 3,
  },
  creator_type: 'Nano Creators 1k-20k followers | Price range: $50-$250',
  creator_cost_range: '$50-$250',
  send_product_to_creators: 'Yes',
  send_product_to_creators_method: 'gift_codes',
  send_product_to_creators_cash_value: 100,
  creator_followers_count: '1000-20000',
  product_fulfillment: {
    method: 'gift_codes',
    cash_value: 100,
  },
};

export const USER_ACTION = {
  PROMPT: 'PROMPT',
  SELECTION: 'SELECTION',
  APPROVAL: 'APPROVAL',
  WAIT_FOR_BRIEF: 'WAIT_FOR_BRIEF',
  NONE: 'NONE',
};

export type RawMessageProps = {
  request_id?: string;
  message?: string;
  brief?: any;
  information?: string;
  error?: string;
};

function useBriefAiAgentMessages(
  getAdditionalData?: () => Promise<any>,
  connect = true
) {
  const {ltxAuthToken} = useLtxAuthToken();

  const [websocketReady, setWebsocketReady] = useState<boolean>(false);
  // rawMessages: all messages received from the agent
  const [rawMessages, setRawMessages] = useState<RawMessageProps[]>([]);
  const [rawHelperMessages, setRawHelperMessages] = useState<{
    [key: string]: RawMessageProps[];
  }>({});
  // messages: only messages received from the agent with the following structure => { "message": "Some message from agent" }
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [awaitingUserAction, setAwaitingUserAction] = useState<string>(
    USER_ACTION.NONE
  );
  const [initialBriefGenerationCompleted, setInitialBriefGenerationCompleted] =
    useState<boolean>(false);
  const [hasAutofillError, setHasAutofillError] = useState(false);
  const [totalFieldsToAutofill, setTotalFieldsToAutofill] = useState<
    number | null
  >(null);

  function addNewMessage(
    messageText: string,
    senderId: string,
    senderName: string,
    isError = false
  ) {
    setMessages((currentMessages) => [
      ...currentMessages,
      {
        id: uuid(),
        senderId,
        direction: senderId === AGENT_SENDER_ID ? 'incoming' : 'outgoing',
        text: messageText,
        createdAt: new Date(),
        sender: senderName,
        isError,
      },
    ]);
  }

  const activateAgent = async (wizardFormData: object) => {
    let additionalData = {};
    if (getAdditionalData) {
      additionalData = await getAdditionalData();
    }

    sendJsonMessage({
      ...additionalData,
      brief_form_data: {...wizardFormData, product_fulfillment: undefined},
      fortress_token: ltxAuthToken,
    });
  };

  const initializeAgent = async (wizardFormData: any) => {
    await activateAgent(wizardFormData);
    setAwaitingUserAction(USER_ACTION.NONE);
  };

  const onMessage = (event: MessageEvent<any>) => {
    const {data} = event;

    if (data?.match(VALID_JSON_REGEX)) {
      const parsedData = JSON.parse(data);
      const {request_id, information, message, error} = parsedData;

      if (information) {
        if (information === BRIEF_AI_AGENT_READY_MESSAGE) {
          setWebsocketReady(true);
          return;
        }
        if (information === BRIEF_INFORMATION_NOT_FOUND_MESSAGE) {
          // TODO: implement reporting
          return;
        }
        if (
          request_id === 'autofill' &&
          information === END_WRITE_BRIEF_MESSAGE &&
          !initialBriefGenerationCompleted
        ) {
          setInitialBriefGenerationCompleted(true);
        }
        if (
          request_id === 'autofill' &&
          typeof information === 'object' &&
          information !== null &&
          'total_fields' in information
        ) {
          setTotalFieldsToAutofill(information.total_fields);
        }
      }

      if (request_id?.startsWith('helper')) {
        setRawHelperMessages((currentRawHelperMessages) => ({
          ...currentRawHelperMessages,
          [parsedData?.request_id]: [
            ...(currentRawHelperMessages[parsedData?.request_id] || []),
            parsedData,
          ],
        }));
      } else {
        setRawMessages((currentRawMessages) => [
          ...currentRawMessages,
          parsedData,
        ]);
      }

      if (error) {
        setRawMessages((currentRawMessages) => [
          ...currentRawMessages,
          {information: END_WRITE_BRIEF_MESSAGE, request_id},
        ]);
        if (request_id === 'autofill') {
          raiseFlashMessageError();
          setHasAutofillError(true);
        }
      }

      if (message) {
        if (message === START_WRITE_BRIEF_MESSAGE) {
          setAwaitingUserAction(USER_ACTION.WAIT_FOR_BRIEF);
        }
        addNewMessage(message, AGENT_SENDER_ID, AGENT_SENDER_NAME);
      }
    }
  };

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(
    WS_URL,
    {
      retryOnError: false,
      shouldReconnect: (closeEvent) => {
        return false;
      },
      onOpen: () => {
        console.log('Websocket opened');
      },
      onClose: () => {
        console.log('Websocket closed');
      },
      onMessage,
    },
    connect && !!ltxAuthToken
  );

  const autofilledFields = rawMessages.reduce((acc, curr) => {
    if (curr.request_id === 'autofill' && curr.brief) {
      acc.add(Object.keys(curr.brief)[0]);
    }
    return acc;
  }, new Set());

  const autofilledFieldsCount = autofilledFields.size;

  return {
    rawMessages,
    rawHelperMessages,
    setRawHelperMessages,
    messages,
    addNewMessage,
    awaitingUserAction,
    setAwaitingUserAction,
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
    initializeAgent,
    initialBriefGenerationCompleted,
    websocketReady,
    websocketClosed: readyState === ReadyState.CLOSED,
    autofillError: hasAutofillError,
    totalFieldsToAutofill,
    autofilledFieldsCount,
  };
}

export default useBriefAiAgentMessages;
