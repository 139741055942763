import {designSystemToken} from '@lightricks/react-design-system';

function getBorderStyle({hasErrors = false, isFocused = false}) {
  if (isFocused) {
    return `1px solid ${designSystemToken('semantic.fg.primary')}`;
  }
  if (hasErrors) {
    return `1px solid ${designSystemToken('semantic.fg.danger')}`;
  }

  return undefined;
}

function getInputStyle({
  hasErrors = false,
  isFocused = false,
  readOnly = false,
}) {
  return {
    labelStyle: {
      '.MuiTypography-root': {
        fontWeight: 500,
        fontSize: 12,
        letterSpacing: -0.5,
      },
    },
    sx: {
      'caretColor': readOnly ? 'transparent' : undefined,
      '.MuiFormLabel-root': {
        fontSize: '12px',
      },
      '.MuiInputBase-root': {
        'height': '48px !important',
        'marginBottom': 0,
        'border': getBorderStyle({hasErrors, isFocused}),
        '&.Mui-focused': {
          border: `1px solid ${
            !hasErrors
              ? designSystemToken('semantic.fg.primary')
              : designSystemToken('semantic.fg.danger')
          }`,
        },
      },
      '.MuiInput-input': {
        'fontSize': '14px !important',
        'fontWeight': '400 !important',
        'lineHeight': '18px !important',
        'letterSpacing': '-0.25px !important',
        '::placeholder': {
          color: designSystemToken('semantic.fg.tertiary'),
          fontSize: 14,
        },
      },
      '.Mui-disabled': {
        background: 'unset !important',
      },
    },
  };
}

export default getInputStyle;
