import isUuidFormat from '@/utils/isUuidFormat';
import useParams from '@/hooks/use-params';

function useBrandId() {
  const {brandId} = useParams();

  return isUuidFormat(brandId) ? brandId : null;
}

export default useBrandId;
