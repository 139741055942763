import {useMutation} from '@tanstack/react-query';
import queryClient from '@/utils/reactQueryClient';
import dashboardUserUpdaters from '@/api/updaters/dashboardUser';

/**
 * @name useUpdateDashboardUser
 * @description React hook that updates dashboard user
 * @returns {Object} An object containing the mutation state and mutation function
 */
function useUpdateDashboardUser() {
  return useMutation({
    mutationKey: ['dashboardUser', 'update'],
    mutationFn: dashboardUserUpdaters.dashboardUser,
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardUser']);
    },
  });
}

export default useUpdateDashboardUser;
