import {FiltersGroup} from '@/types/models/search-creators/filter';
import {
  PlatformFilterId,
  FiltersGroupId,
  CreatorDetailsFilterId,
  FilterIds,
  KeywordsFilterId,
} from '@/types/models/search-creators/filterId';
import {
  ageFilter,
  audienceAgeFilter,
  categoryFilter,
  platformMetricsFilter,
  genderFilter,
  keywordsFilter,
  locationFilter,
  platformFilter,
  weightedGenderFilter,
  audienceLocationFilter,
  searchBioFilter,
} from '@/stores/search-creators/filterInstance';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer.title';

const creatorDetailsFiltersGroup: FiltersGroup<CreatorDetailsFilterId> = {
  isCollapsable: true,
  localeLabelKey: `${TRANSLATION_PREFIX}.creator-details`,
  filters: {
    category: categoryFilter,
    gender: genderFilter,
    age: ageFilter,
    location: locationFilter,
    searchBio: searchBioFilter,
  },
};

const platformsFiltersGroup: FiltersGroup<PlatformFilterId> = {
  isCollapsable: true,
  localeLabelKey: `${TRANSLATION_PREFIX}.platforms`,
  filters: {
    platform: platformFilter,
    platformMetrics: platformMetricsFilter,
    gender: weightedGenderFilter,
    age: audienceAgeFilter,
    location: audienceLocationFilter,
  },
};

const keywordsFiltersGroup: FiltersGroup<KeywordsFilterId> = {
  isCollapsable: false,
  localeLabelKey: `${TRANSLATION_PREFIX}.keywords`,
  filters: {keyword: keywordsFilter},
};

const initialFiltersGroups: Record<FiltersGroupId, FiltersGroup<FilterIds>> = {
  creatorDetails: creatorDetailsFiltersGroup,
  platforms: platformsFiltersGroup,
  keywords: keywordsFiltersGroup,
};

export default initialFiltersGroups;
