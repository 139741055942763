import React, {useEffect, useMemo, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Button, Modal} from '@lightricks/react-design-system';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import getCapsuleAiImageUrl from '@/utils/getCapsuleAiImageUrl';
import getImageDimensionsFromUrl from '@/utils/getImageDimensionsFromUrl';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import FormatSelector from '@/views/ai-content-generator/components/format-selector';
import GeneratedImage from '@/views/ai-content-generator/components/generated-image';
import LookAndFeel from '@/views/ai-content-generator/components/look-and-feel';
import {Preset} from '@/views/ai-content-generator/components/look-and-feel/LookAndFeel';
import PromptInputs from '@/views/ai-content-generator/components/prompt-inputs';
import Spinner from '@/views/ai-content-generator/components/spinner';
import UploadImagePopupContent from '@/views/ai-content-generator/components/upload-image-popup-content';
import ImageModalView from '@/views/ai-content-generator/generated-content/image-modal-view';
import PoppaysLoader from '@/components/PoppaysLoader';
import GridGallery from '@/components/grid-gallery';
import useCreateSession from '@/hooks/mutations/capsule-ai/use-create-session';
import useImagesCount from '@/hooks/queries/capsule-ai/use-images-count/useImagesCount';
import useImagesQuery from '@/hooks/queries/capsule-ai/use-images-query';
import usePresetQuery from '@/hooks/queries/capsule-ai/use-preset-query';
import useTransparentImageQuery from '@/hooks/queries/capsule-ai/use-transparent-image-query';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useElementDimensions from '@/hooks/use-element-dimensions';
import {useBannerStore} from '@/contexts/BannerContext';
import {
  GeneratedStyle,
  getImageDimensions,
  getPresetData,
  getProductName,
  getReferenceImage,
  getReferenceImageWeight,
  getStyleType,
  getTransparentRemoteFile,
  getUserNegativePrompt,
  getUserPrompt,
  ImageItemProps,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './Dashboard.module.scss';

const NUM_OF_IMAGES_TO_GENERATE_IN_FIRST_BATCH = 4;
const DEFAULT_NUM_OF_IMAGES_TO_GENERATE = 4;

const LIMIT_PROMPT_INPUT = 330;
const PRODUCT_PREFIX = 'Product';
export const SEPARATOR_CHARS_LENGTH = 2;

function Dashboard() {
  const [isFirstBatch, setIsFirstBatch] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isFirstScrollToBottom, setIsFirstScrollToBottom] = useState(false);
  const [eventInteractionId, setEventInteractionId] = useState(uuid());
  const [generateEventDetails, setGenerateEventDetails] = useState('');
  const [imageViewerList, setImageViewerList] = useState<ImageItemProps[]>([]);
  const [isReferenceImageLoading, setIsReferenceImageLoading] = useState(false);
  const {state, dispatch} = useContentGenerationStore();
  const {dispatch: bannerDispatch} = useBannerStore();
  const brandId = useBrandId();
  const {dashboardUser}: any = useDashboardUserQuery({});
  const {data: {imagesCount = 0} = {}, isFetched: imagesCountFetched} =
    useImagesCount(brandId, dashboardUser?.id);
  const {
    data: {presets = [], defaultNegativePromptLength = 0} = {},
    isFetched: isPresetsFetched,
  } = usePresetQuery();
  const {
    data: {transparentImage = {}} = {},
    isFetched: transparentImageFetched,
  } = useTransparentImageQuery(brandId);
  const userPrompt = getUserPrompt(state);
  const userNegativePrompt = getUserNegativePrompt(state);
  const productName = getProductName(state);
  const referenceImageWeight = getReferenceImageWeight(state);
  const {selectedPreset, presetPromptLength, presetNegativePromptLength} =
    getPresetData(state);
  const {width: imageWidth, height: imageHeight} = getImageDimensions(state);
  const [styleType, setStyleType] = useState(getStyleType(state));
  const [referenceImage, setRefernceImage] = useState(getReferenceImage(state));

  const productNamePrefixSubtract =
    (productName.length > PRODUCT_PREFIX.length
      ? productName.length
      : PRODUCT_PREFIX.length) + 1;
  const aiContentFlow = AnalyticsService.getFlow(FLOW_NAMES.AI_STUDIO);
  const flowId = aiContentFlow?.flow_id;
  const defaultImagePresets: any[] | {[key: number]: Preset} = useMemo(() => {
    if (!presets) {
      return [];
    }
    return {
      0: presets[0],
      1: presets[1],
      2: presets[2],
      3: presets[3],
    };
  }, [presets]);

  const ERROR_TYPES = {
    general: {
      title: translate(
        'views.ai-content-generator.dashboard.errors.general-error-title'
      ),
      message: translate(
        'views.ai-content-generator.dashboard.errors.general-error-message'
      ),
    },
  };
  const handleReplaceImagePress = () => {
    const interactionId = uuid();
    setEventInteractionId(interactionId);

    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_INTERACTION, {
      interaction_name: 'clicked_replace_product',
      interaction_type: 'click',
      interaction_location: 'gallery',
      interaction_details: '',
      interaction_id: interactionId,
    });
    dispatch({
      type: 'UPDATE_SHOW_UPLOAD_MODAL',
      showUploadModal: true,
    });
  };
  const {elementRef: gridWrapperRef} = useElementDimensions();

  const createSession = useCreateSession(brandId, dashboardUser.id);

  const {
    data: images,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetched,
  } = useImagesQuery({
    brandId,
    dashboardUserId: dashboardUser.id,
    enabled: !!dashboardUser?.id && !!brandId,
  });

  const [isReferenceImageSelected, setIsReferenceImageSelected] = useState(
    getReferenceImage(state) && getStyleType(state) === GeneratedStyle.reference
  );

  useEffect(() => {
    dispatch({
      type: 'RESET_NEW_GENERATED_IMAGES',
    });
  }, [brandId, dashboardUser?.id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'RESET_PRESET',
        promptValue: userPrompt,
        negativePromptValue: userNegativePrompt,
        styleType: GeneratedStyle.none,
      });
    };
  }, [queryClient]);
  const scrollGridToTop = () => {
    const element = gridWrapperRef.current;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  const currentTransparentImageUrl = getCapsuleAiImageUrl(
    transparentImage?.imageUrl || ''
  );

  const currentTransparentImageThumbUrl = getCapsuleAiImageUrl(
    transparentImage?.imageThumbUrl || ''
  );

  useEffect(() => {
    if (transparentImageFetched) {
      dispatch({
        type: 'UPDATE_SESSION_ID',
        sessionId: transparentImage?.sessionId,
      });
      dispatch({
        type: 'UPDATE_PRODUCT_NAME_INPUT',
        productName: transparentImage?.imageDescription,
      });
      dispatch({
        type: 'UPDATE_TRANSPARENT_REMOTE_FILE',
        transparentImagePath: transparentImage?.imageUrl || '',
        transparentImageId: String(transparentImage?.id || ''),
      });
      (async () => {
        if (transparentImage?.imageUrl) {
          const {width, height} = await getImageDimensionsFromUrl(
            getCapsuleAiImageUrl(transparentImage.imageUrl)
          );
          dispatch({
            type: 'UPDATE_TRANSPARENT_IMAGE_SIZE',
            transparentImageWidth: String(width),
            transparentImageHeight: String(height),
          });
        }
      })();
    }
  }, [transparentImageFetched]);

  useEffect(() => {
    return () => {
      queryClient.refetchQueries(['capsuleAiImages']);
    };
  }, []);

  useEffect(() => {
    if (isFirstBatch && !!isFetched) {
      onGenerateClick(NUM_OF_IMAGES_TO_GENERATE_IN_FIRST_BATCH)
        .then(() => {
          setIsFirstBatch(false);
          setShowLoader(false);
        })
        .catch(() => {
          setShowLoader(false);
        });
    }
  }, [isFirstBatch, isFetched]);

  useEffect(() => {
    if (state.isGeneratingImages && state.dummiesStack.length === 0) {
      dispatch({
        type: 'GENERATION_ENDED',
      });
    }
  }, [state.dummiesStack]);

  const imagesData = useMemo(() => {
    if (!images) {
      return [];
    }

    return images.map((image: any) => ({
      ...image,
      imageUrl: getCapsuleAiImageUrl(image.imageUrl),
    }));
  }, [images]);

  useEffect(() => {
    if (!imagesCountFetched) return;

    if (
      imagesData.length > 0 ||
      imagesCount > 0 ||
      state.imagesListCounter > 0 ||
      state.newGeneratedImages.length > 0
    ) {
      setShowLoader(false);
    } else if (
      imagesData.length === 0 &&
      imagesCount === 0 &&
      state.newGeneratedImages.length === 0 &&
      state.imagesListCounter === 0 &&
      currentTransparentImageUrl !== ''
    ) {
      setIsFirstBatch(true);
    }
  }, [imagesData, imagesCount, imagesCountFetched]);

  useEffect(() => {
    setImageViewerList([
      ...state.newGeneratedImages.map((image: any) => ({
        ...image,
        imageUrl: getCapsuleAiImageUrl(image.imageUrl),
      })),
      ...imagesData,
    ]);
  }, [state.newGeneratedImages]);

  useEffect(() => {
    setRefernceImage(getReferenceImage(state));
    setStyleType(getStyleType(state));
    if (
      getReferenceImage(state) &&
      getStyleType(state) === GeneratedStyle.reference
    ) {
      dispatch({
        type: 'RESET_PRESET',
        promptValue: userPrompt,
        negativePromptValue: userNegativePrompt,
        styleType: GeneratedStyle.reference,
      });
      setIsReferenceImageSelected(true);
    } else {
      setIsReferenceImageSelected(false);
    }
  }, [state.referenceImage, state.styleType]);

  const addDummyImages = (sessionId: number, numberOfDummyImages = 4) => {
    const dummyImages: ImageItemProps[] = [...Array(numberOfDummyImages)].map(
      (_, index) => {
        const tempId = uuid();

        const promptParams = getPromptParams(sessionId, index);

        dispatch({
          type: 'PUSH_DUMMY_TO_STACK',
          dummy: {
            id: tempId,
            tempId,
            sessionId,
          },
        });
        return {
          id: 0,
          imageUrl: '',
          isSavedToLibrary: false,
          tempId,
          promptParams,
          index,
        };
      }
    );

    dispatch({
      type: 'SET_NEW_GENERATED_IMAGES',
      images: dummyImages,
    });

    setTimeout(() => {
      scrollGridToTop();
    }, 100);
  };

  const onGenerateClick = async (
    numberOfImages = DEFAULT_NUM_OF_IMAGES_TO_GENERATE
  ) => {
    try {
      dispatch({
        type: 'START_GENERATING',
      });
      const {
        data: {
          session: {id},
        },
      } = await createSession.mutateAsync({
        brand_id: brandId,
      });

      addDummyImages(id, numberOfImages);
      if (!isFirstBatch) {
        const interactionId = uuid();
        setEventInteractionId(interactionId);
        let selectedStyleType = 'none';
        if (state.styleType === GeneratedStyle.preset) {
          selectedStyleType = 'preset';
        } else if (state.styleType === GeneratedStyle.reference) {
          selectedStyleType = 'reference';
        }
        const interactionDetails = JSON.stringify({
          preset_name: selectedPreset,
          product_name: productName,
          prompt: userPrompt,
          negative_prompt: userNegativePrompt,
          style_type: selectedStyleType,
          width: imageWidth,
          height: imageHeight,
        });
        setGenerateEventDetails(interactionDetails);
        AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_INTERACTION, {
          flow_id: flowId,
          interaction_name: 'clicked_generate',
          interaction_type: 'click',
          interaction_location: 'gallery',
          interaction_details: interactionDetails,
          interaction_id: interactionId,
        });
      }
    } catch (error: any) {
      console.log('error', error);
      dispatch({
        type: 'GENERATION_ENDED',
      });

      onGenerateImageError();
    }
  };

  function getPromptParams(sessionId: number, index: number | undefined) {
    const imageIndex = index || 0;
    const defaultPreset =
      defaultImagePresets[
        imageIndex % (NUM_OF_IMAGES_TO_GENERATE_IN_FIRST_BATCH / 2)
      ];

    const isFirstGenerate =
      imageIndex < NUM_OF_IMAGES_TO_GENERATE_IN_FIRST_BATCH &&
      imagesCount + state.imagesListCounter <
        NUM_OF_IMAGES_TO_GENERATE_IN_FIRST_BATCH;

    return {
      presetName: isFirstGenerate ? defaultPreset.name : selectedPreset,
      productName: isFirstGenerate ? PRODUCT_PREFIX : productName,
      userPrompt,
      userNegativePrompt,
      categoryId: undefined,
      sessionId,
      uuid: dashboardUser.id,
      transparentImageUrl: currentTransparentImageUrl,
      width: imageWidth,
      height: imageHeight,
      brand_id: brandId,
      style_type: styleType,
      referenceImage,
      ref_image_weight: referenceImageWeight,
    };
  }

  const onGenerateImageError = () => {
    bannerDispatch({
      type: 'SET_BANNER',
      title: ERROR_TYPES.general.title,
      message: ERROR_TYPES.general.message,
      severity: 'warning',
      className: styles.errorBanner,
    });
  };

  const handleModalClose = () => {
    dispatch({
      type: 'UPDATE_SHOW_UPLOAD_MODAL',
      showUploadModal: false,
    });
  };

  return (
    <>
      {showLoader ? <PoppaysLoader /> : null}
      <div id="ai-content-generator-dashboard" className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.gridGalleryContainer} ref={gridWrapperRef}>
            <GridGallery
              images={imagesData}
              renderImage={(image, index) =>
                isPresetsFetched && (
                  <GeneratedImage
                    key={image.tempId || image.id}
                    brandId={brandId}
                    transparentImageUrl={currentTransparentImageUrl}
                    onGenerateImageError={onGenerateImageError}
                    flowId={flowId}
                    eventInteractionId={eventInteractionId}
                    generateEventDetails={generateEventDetails}
                    {...image}
                  />
                )
              }
              onEndReached={hasNextPage ? fetchNextPage : undefined}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
          <PromptInputs
            selectedPreset={selectedPreset}
            productName={productName}
            prompt={userPrompt}
            negativePrompt={userNegativePrompt}
            userPromptLimit={
              LIMIT_PROMPT_INPUT -
              presetPromptLength -
              productNamePrefixSubtract
            }
            negativePromptLimit={
              LIMIT_PROMPT_INPUT -
              (presetNegativePromptLength || defaultNegativePromptLength) -
              SEPARATOR_CHARS_LENGTH
            }
            isGenerateDisabled={
              !(
                (userPrompt && state.styleType === GeneratedStyle.none) ||
                (presetPromptLength &&
                  state.styleType === GeneratedStyle.preset) ||
                (!isReferenceImageLoading &&
                  state.styleType === GeneratedStyle.reference &&
                  referenceImage)
              )
            }
            isReferenceImageSelected={!!isReferenceImageSelected}
            onGenerateClick={onGenerateClick}
            isLoading={state.isGeneratingImages}
          />
        </div>
        <div className={styles.rightSide}>
          <div>
            <div className={styles.productContainer}>
              <div className={styles.titleProduct}>
                {translate(
                  'views.ai-content-generator.components.your-product.title'
                )}
              </div>
              <div className={styles.imageSettingsContainer}>
                <div className={styles.transparentImageContainer}>
                  {currentTransparentImageUrl ? (
                    <img
                      className={styles.transparentImage}
                      src={
                        currentTransparentImageThumbUrl ||
                        currentTransparentImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <div className={styles.transparentImageSpinner}>
                      <Spinner size={24} />
                    </div>
                  )}
                </div>
                <div className={styles.uploadContainer}>
                  <Button
                    type="button"
                    onClick={handleReplaceImagePress}
                    appearance="neutral"
                    mode="tinted"
                    size="small"
                  >
                    {translate(
                      'views.ai-content-generator.components.your-product.button'
                    )}
                  </Button>
                  <div className={styles.uploadText}>
                    {translate(
                      'views.ai-content-generator.components.your-product.content'
                    )}
                  </div>
                </div>
              </div>
            </div>
            <FormatSelector
              flowId={flowId}
              disabled={!!isReferenceImageSelected}
            />
          </div>
          <div className={styles.lookAndFeelContainer}>
            <LookAndFeel
              isPresetsFetched={isPresetsFetched}
              presets={presets}
              userPrompt={userPrompt}
              negativeUserPrompt={userNegativePrompt}
              limitPromptInput={LIMIT_PROMPT_INPUT}
              productNamePrefixSubtract={productNamePrefixSubtract}
              setIsReferenceImageLoading={setIsReferenceImageLoading}
              setIsReferenceImageSelected={setIsReferenceImageSelected}
              isReferenceImageSelected={!!isReferenceImageSelected}
            />
          </div>
        </div>
        <ImageModalView
          images={imageViewerList.length ? imageViewerList : imagesData}
        />
      </div>
      <Modal open={state.showUploadModal} handleClose={handleModalClose}>
        <UploadImagePopupContent />
      </Modal>
    </>
  );
}

export default Dashboard;
