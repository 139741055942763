import React from 'react';
import {v4 as uuid} from 'uuid';
import {Icon, Tooltip} from '@lightricks/react-design-system';
import {IconProps} from '@lightricks/react-design-system/dist/components/icon/IconProps';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import downloadImageFromUrl from '@/utils/downloadImageFromUrl';
import translate from '@/utils/translate';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';
import useCreateAiContent from '@/hooks/mutations/capsule-ai/use-create-ai-content';
import useBrandId from '@/hooks/use-brand-id';
import {BannerSeverity, useBannerStore} from '@/contexts/BannerContext';
import {
  getSelectedImageId,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './ImageViewer.module.scss';

const TRANSLATION_PREFIX = 'views.ai-content-generator.components.image-viewer';

type ImageViewerProps = {
  url: string;
  isSavedToLibrary: boolean;
  controls?: boolean;
  onClose?: () => void;
};

type ImageViewerControlsProps = {
  onClose: () => void;
  isSavedToLibrary: boolean;
  onSaveToLibrary?: () => void;
  onDownload: () => void;
};

function ControlButton(props: {
  id: string;
  tooltipText?: string;
  disabled?: boolean;
  iconName: IconProps['name'];
  iconSize: IconProps['size'];
  onClick?: () => void;
}) {
  const {id, tooltipText, disabled, iconName, iconSize, onClick} = props;
  return (
    <Tooltip type="dark" title={tooltipText} arrow placement="right">
      <div
        id={id}
        className={`${styles.controlButton} ${disabled ? styles.disabled : ''}`}
        onClick={disabled ? () => {} : () => onClick?.()}
      >
        <Icon
          size={iconSize}
          appearance={disabled ? 'white' : 'neutral'}
          name={iconName}
        />
      </div>
    </Tooltip>
  );
}

function Controls(props: ImageViewerControlsProps) {
  const {onClose, isSavedToLibrary, onSaveToLibrary, onDownload} = props;
  return (
    <div className={styles.controls}>
      <div>
        <ControlButton
          id="close"
          iconName="Actions-Close-Small"
          iconSize="large"
          onClick={onClose}
        />
      </div>
      <div className={styles.controlsBottom}>
        <ControlButton
          id="save-to-content-library"
          tooltipText={
            isSavedToLibrary
              ? translate(
                  `${TRANSLATION_PREFIX}.save-to-library.tooltip-title.already-saved`
                )
              : translate(
                  `${TRANSLATION_PREFIX}.save-to-library.tooltip-title.save-to-library`
                )
          }
          disabled={isSavedToLibrary}
          iconName={
            isSavedToLibrary ? 'Actions-LooksOn' : 'Navigation-Photos-Options'
          }
          iconSize="medium"
          onClick={onSaveToLibrary}
        />
        <ControlButton
          id="download-image"
          tooltipText={translate(
            `${TRANSLATION_PREFIX}.download.tooltip-title`
          )}
          iconName="Navigation-Download"
          iconSize="medium"
          onClick={onDownload}
        />
      </div>
    </div>
  );
}

function ImageViewer(props: ImageViewerProps) {
  const {url, isSavedToLibrary, controls, onClose} = props;

  const {state, dispatch} = useContentGenerationStore();
  const brandId = useBrandId();
  const createAiContent = useCreateAiContent();
  const {dispatch: bannerDispatch} = useBannerStore();

  const aiContentFlow = AnalyticsService.getFlow(FLOW_NAMES.AI_STUDIO);
  const flowId = aiContentFlow?.flow_id;

  const showBannerMessage = (
    message: string,
    severity: BannerSeverity,
    icon: React.ReactNode | undefined = undefined
  ) => {
    bannerDispatch({
      type: 'SET_BANNER',
      title: '',
      message,
      severity,
      mode: 'light',
      icon,
    });
  };

  const onSaveToLibrary = async () => {
    const interactionId = uuid();
    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_INTERACTION, {
      interaction_name: 'clicked_save_to_library',
      interaction_type: 'click',
      interaction_location: 'image_preview',
      interaction_details: JSON.stringify({
        image_url: url,
      }),
      interaction_id: interactionId,
    });
    const processId = uuid();
    AnalyticsService.dispatchEvent(
      EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_STARTED,
      {
        flow_id: flowId,
        process_source: 'clicked_save_to_library_image_preview',
        asset_type: 'photo',
        asset_size: '',
        process_details: JSON.stringify({
          image_url: url,
        }),
        process_id: processId,
        interaction_id: interactionId,
      }
    );

    try {
      await createAiContent.mutateAsync({
        url,
        merchantId: brandId,
        kind: 'photo',
        capsuleAiContentId: getSelectedImageId(state),
      });
      await capsuleAiUpdaters.updateIsSavedToLibrary({
        id: getSelectedImageId(state),
        isSavedToLibrary: true,
      });
      dispatch({
        type: 'UPDATE_IMAGES_WAS_SAVED_TO_LIBRARY',
        savedImagesToLibrary: [
          {id: getSelectedImageId(state), isSavedToLibrary: true},
        ],
      });
      showBannerMessage(
        translate(
          'views.ai-content-generator.components.image-viewer.save-to-library.success'
        ),
        'success',
        <Icon size="large" appearance="brand" name="Actions-LooksOn" />
      );
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
        {
          flow_id: flowId,
          result: 'success',
          process_details: '',
          process_id: processId,
          error: null,
        }
      );
    } catch (e: any) {
      let errorMessage = '';
      if (
        e.status === '422' &&
        e.message.includes('Capsule ai content has already been taken')
      ) {
        errorMessage = translate(
          'views.ai-content-generator.components.image-viewer.save-to-library.already-exist'
        );
      } else if (e.status === '400') {
        errorMessage = e.message || e?.response?.data?.message;
      } else {
        errorMessage = translate(
          'views.ai-content-generator.components.image-viewer.save-to-library.add-image-error'
        );
      }
      showBannerMessage(errorMessage, 'warning');
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
        {
          flow_id: flowId,
          result: 'failed',
          process_details: '',
          process_id: processId,
          error: errorMessage,
        }
      );
    }
  };

  const onDownload = async () => {
    await downloadImageFromUrl(url);

    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_INTERACTION, {
      interaction_name: 'downloaded_image',
      interaction_type: 'click',
      interaction_location: 'image_preview',
      interaction_details: JSON.stringify({
        image_url: url,
      }),
      interaction_id: uuid(),
    });
  };

  return (
    <div className={styles.container}>
      <img className={styles.image} src={url} alt="" />
      {controls ? (
        <Controls
          onClose={() => onClose?.()}
          isSavedToLibrary={isSavedToLibrary}
          onSaveToLibrary={onSaveToLibrary}
          onDownload={onDownload}
        />
      ) : null}
    </div>
  );
}

export default ImageViewer;
