import React, {useEffect} from 'react';
import checkImageValid from '@/utils/checkImageValid';
import getInitials from '@/utils/getInitials';

function useAvatarImageOrInitials(imageUrl: string, name: string) {
  const [initials, setInitials] = React.useState('');
  const [imageUrlValid, setImageUrlValid] = React.useState(false);

  useEffect(() => {
    setInitials(getInitials(name).toUpperCase());
    checkImageValid(imageUrl, setImageUrlValid);
  }, [imageUrl, name]);

  return {
    imageUrl: imageUrlValid ? imageUrl : '',
    initials,
  };
}

export default useAvatarImageOrInitials;
