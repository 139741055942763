import {capitalize} from 'lodash';
import React from 'react';
import {Body} from '@lightricks/react-design-system';
import {Identity} from '@/types/identity';
import styles from './SocialNetworkSelector.module.scss';

interface SocialNetworkSelectorProps {
  socialNetworks: Identity[];
  activeSocialNetworkProvider: string;
  onSocialNetworkClick: (socialNetworks: Identity) => void;
}

function SocialNetworkSelector(props: SocialNetworkSelectorProps) {
  const {socialNetworks, activeSocialNetworkProvider, onSocialNetworkClick} =
    props;
  return (
    <div className={styles.socialNetworksSelectorContainer}>
      {socialNetworks.map((socialNetwork) => (
        <div
          key={socialNetwork.provider}
          className={`${styles.socialNetworkItem} ${
            socialNetwork.provider === activeSocialNetworkProvider
              ? styles.active
              : ''
          }`}
          onClick={() => onSocialNetworkClick(socialNetwork)}
        >
          <Body size="lg">
            {socialNetwork.socialNetwork?.name ||
              capitalize(socialNetwork.provider)}
          </Body>
        </div>
      ))}
    </div>
  );
}

export default SocialNetworkSelector;
