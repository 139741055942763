import React, {useEffect} from 'react';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import {JsonApiError} from '@/utils/jsonapiClient';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import {ProfileProps} from '@/types/creatorProfile';
import PoppaysLoader from '@/components/PoppaysLoader';
import CreatorProfile from '@/components/creator-profile';
import CREATOR_PROFILE_TEST_IDS from '@/components/creator-profile/testIds';
import Link from '@/components/link';
import Page from '@/components/page';
import useTrackButtonEvent from '@/hooks/analytics/use-track-button-event';
import useCreatorProfileQuery from '@/hooks/queries/use-creator-profile-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useParams from '@/hooks/use-params';
import useWhitelistedSearchParams from '@/hooks/use-whitelisted-search-params';
import styles from './PublicProfile.module.scss';

const TRANSLATION_PREFIX = 'views.public-profile';

function HeaderAndBanner({profile}: {profile: ProfileProps}) {
  const trackButtonEvent = useTrackButtonEvent();
  const logInClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    trackButtonEvent.pressed({
      button_name: (event.target as HTMLAnchorElement).innerText,
      screen_name: screenName,
      creator_id: profile?.id || '',
      tab: '',
    });
  };

  const signUpClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    trackButtonEvent.pressed({
      button_name: (event.target as HTMLAnchorElement).innerText,
      screen_name: screenName,
      creator_id: profile?.id || '',
      tab: '',
    });
  };

  return (
    <div
      data-testid={CREATOR_PROFILE_TEST_IDS.PUBLIC_PROFILE__UPSELL_BANNER}
      className={styles.headerAndBanner}
    >
      <Link
        to="https://popularpays.com/request-demo/"
        target="_blank"
        rel="noopener noreferrer"
        keepBrandIdIfPresent={false}
      >
        <div className={styles.popPaysLogo} />
      </Link>
      <div className={styles.headerSeparator} />
      <div className={styles.upsellBlock}>
        <div className={styles.icon} />
        {translate(`${TRANSLATION_PREFIX}.upsell.1`, {
          displayName: profile?.displayName || '',
        })}
        <Link
          to={`${ROUTES.CREATOR_PROFILE}/${profile?.id || ''}`}
          role="button"
          onClick={logInClicked}
        >
          {translate(`${TRANSLATION_PREFIX}.upsell.2`)}
        </Link>
        {translate(`${TRANSLATION_PREFIX}.upsell.3`)}
        <Link
          to="https://popularpays.com/request-demo/"
          role="button"
          onClick={signUpClicked}
        >
          {translate(`${TRANSLATION_PREFIX}.upsell.4`)}
        </Link>
        {translate(`${TRANSLATION_PREFIX}.upsell.5`)}
      </div>
    </div>
  );
}

function PublicProfile() {
  const {creatorId} = useParams();
  const navigation = useNavigation();

  useWhitelistedSearchParams([]);

  const brandId = useBrandId();
  const {
    profile,
    error,
    isLoading,
    isError: isErrorProfile,
  } = useCreatorProfileQuery({
    profileId: creatorId,
    brandId,
    isPublic: true,
  });

  useEffect(() => {
    if (error) {
      const jsonApiError = error as JsonApiError;
      switch (Number(jsonApiError.status)) {
        case 404: {
          navigation.navigate(ROUTES.NOT_FOUND);
          return;
        }
        default: {
          raiseFlashMessageError();
        }
      }
    }
  }, [error]);

  if (isLoading) {
    return <PoppaysLoader />;
  }

  if (isErrorProfile) {
    return null;
  }

  return (
    <Page
      id="creator-public-profile"
      childrenClassName={styles.publicProfileWrapper}
    >
      <HeaderAndBanner profile={profile as ProfileProps} />
      <CreatorProfile
        testID={CREATOR_PROFILE_TEST_IDS.MAIN__PUBLIC}
        profile={profile as ProfileProps}
        displayPublicDataOnly
        componentAllowsAddMembership={false}
        componentAllowsCollaboration={false}
        componentAllowsInviteToCampaign={false}
        canViewAudience={false}
        canViewSocialHealth={false}
        canViewPortfolio
        canViewPosts={false}
        canViewProfileNotes={false}
      />
    </Page>
  );
}

export default PublicProfile;
