import React from 'react';
import {Button} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './PricingTierButtons.module.scss';

const TRANSLATION_PREFIX = 'components.pricing.buttons';

type ContactSalesButtonProps = {onContactSalesClick: () => void};

export function ContactSalesButton({
  onContactSalesClick,
}: ContactSalesButtonProps) {
  return (
    <Button
      appearance="brand"
      mode="filled"
      size="medium"
      onClick={onContactSalesClick}
    >
      {translate(`${TRANSLATION_PREFIX}.contact-sales`)}
    </Button>
  );
}

type SubscribeButtonProps = {
  onClick?: () => void;
  isTrialTier: boolean;
};

export function SubscribeButton({onClick, isTrialTier}: SubscribeButtonProps) {
  return (
    <Button appearance="brand" mode="filled" size="medium" onClick={onClick}>
      {isTrialTier
        ? translate(`${TRANSLATION_PREFIX}.start-free-trial`)
        : translate(`${TRANSLATION_PREFIX}.subscribe`)}
    </Button>
  );
}

export function CurrentPlanButton() {
  return (
    <Button
      appearance="brand"
      mode="tinted"
      size="medium"
      disabled
      className={styles.currentPlanButton}
    >
      {translate(`${TRANSLATION_PREFIX}.your-plan`)}
    </Button>
  );
}
