import {useMemo} from 'react';
import {Asset} from '@/types/asset';
import QueryContext from '@/api/QueryContext';
import assetFetcher from '@/api/fetchers/asset';
import useInfiniteDataQuery from '@/hooks/use-infinite-data-query';

interface Page {
  data: Asset[];
  meta: {
    pagination: {currentPage: number; totalPages: number; totalEntries: number};
  };
}

interface AssetsResponse {
  data?: {pages: Page[]};
}
function useAssetsQuery({
  brandId,
  queryParams = {},
}: {
  brandId?: string;
  queryParams?: {[key: string]: string | number};
}) {
  const response = useInfiniteDataQuery({
    queryKey: ['assets', brandId, queryParams],
    queryFn: ({pageParam = 1, ...rest}: QueryContext) =>
      assetFetcher.brandAssets({pageParam, ...rest}),
    refetchOnWindowFocus: false,
    enabled: !!brandId,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
    meta: {
      brandId,
      ...queryParams,
    },
    getNextPageParam: (lastPage: {
      meta: {pagination: {currentPage: number; totalPages: number}};
    }) => {
      if (!lastPage.meta) {
        return undefined;
      }
      const {meta} = lastPage;
      return meta.pagination.currentPage < meta.pagination.totalPages
        ? meta.pagination.currentPage + 1
        : undefined;
    },
  });

  const {data = {}} = response;
  const {pages = []}: {pages?: Page[]} = data || {};
  const meta = pages[pages.length - 1]?.meta;
  const assets = useMemo(() => {
    if (!response.data) {
      return [];
    }
    return pages.flatMap((page: {data: Asset[]}) => page?.data);
  }, [response.data]);
  return {
    ...response,
    data: assets,
    meta,
  };
}

export default useAssetsQuery;
