import React, {HTMLAttributeAnchorTarget} from 'react';

function AnchorText({
  text,
  href,
  target = '_blank',
  rel = 'noopener noreferrer',
  className,
}: {
  text: string;
  href: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  rel?: string | undefined;
  className?: string | undefined;
}) {
  return (
    <a href={href} target={target} rel={rel} className={className}>
      {text}
    </a>
  );
}
export default AnchorText;
