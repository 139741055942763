import React from 'react';
import {Button} from '@lightricks/react-design-system';
import styles from './CollaborateButton.module.scss';

const iconChatSrc = '/assets/svg/icon-chat.svg';

function CollaborateButton({
  testID = 'collaborate-button',
  onClick,
}: {
  testID?: string;
  onClick: () => void;
}) {
  return (
    <div className={styles.buttonContainer} data-testid={testID}>
      <Button appearance="neutral" mode="plain" size="medium" onClick={onClick}>
        <img src={iconChatSrc} alt="" />
      </Button>
    </div>
  );
}

export default CollaborateButton;
