import {CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefField from './BriefField';

class BriefItem extends BriefField {
  #index: number;

  id: string;

  title: string;

  contentHtml: string;

  highlighted: string;

  clientBriefTemplateId: string;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(briefFieldManager, campaignData, key, null);
    this.#index = index;
    this.id = id;
    this.title = title;
    this.contentHtml = contentHtml;
    this.highlighted = highlighted;
    this.clientBriefTemplateId = clientBriefTemplateId;
  }

  get index() {
    return this.#index;
  }

  set value(value: any) {
    this.contentHtml = value;
  }

  get value() {
    return {
      id: this.id,
      title: this.title,
      contentHtml: this.toContentHtml,
      highlighted: this.highlighted,
      clientBriefTemplateId: this.clientBriefTemplateId,
    };
  }

  get toContentHtml() {
    return this.contentHtml;
  }
}

export default BriefItem;
