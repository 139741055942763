import React from 'react';
import translate from '@/utils/translate';
import WelcomeVideo from '@/views/ai-content-generator/components/welcome-frame/assets/videos/230928-PP-AI- Capsule-Intro-Video_02.mp4';
import Page from '@/components/page';
import styles from './AiStudioMobileNotSupportedPlaceholder.module.scss';

const TRANSLATION_PREFIX = 'components.mobile-not-supported-placeholder';

function AiStudioMobileNotSupportedPlaceholder() {
  return (
    <Page id="ai-studio-mobile-not-supported" className={styles.container}>
      <div className={styles.box}>
        <div className={styles.textContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {translate(`${TRANSLATION_PREFIX}.title`)}
            </div>
          </div>
          <div
            className={`${styles.text} ${styles.subtitle}`}
            dangerouslySetInnerHTML={{
              __html: translate(`${TRANSLATION_PREFIX}.subtitle`),
            }}
          />
          <div className={`${styles.text} ${styles.subtitleBold}`}>
            {translate(`${TRANSLATION_PREFIX}.subtitle-bold`)}
          </div>
        </div>
        <div className={styles.welcomeVideoContainer}>
          <video autoPlay loop muted>
            <source src={WelcomeVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </Page>
  );
}

export default AiStudioMobileNotSupportedPlaceholder;
