import dayjs from 'dayjs';
import {Post} from '@/types/post';

const DAY_FORMAT = 'MM/DD/YYYY';
const MONTH_FORMAT = 'YYYY-MM';
const WEEK_FORMAT = 'MM/DD/YYYY';
const PAID_KEYWORDS = ['paid', 'ad', 'sponsored'];

function mapIdentityPost(post: Post): Post {
  const {createdAt, tags} = post;

  return {
    ...post,
    createdAtDay: dayjs(createdAt).format(DAY_FORMAT),
    createdAtMonth: dayjs(createdAt).format(MONTH_FORMAT),
    createdAtWeek: dayjs(createdAt).startOf('week').format(WEEK_FORMAT),
    cachedAccountFollowerCount: post.cachedAccountFollowerCount || 0,
    isPaid: (tags || []).some((tag) => PAID_KEYWORDS.includes(tag)),
  };
}

export default mapIdentityPost;
