import deepMapKeys from 'deep-map-keys';
import {camelCase, cloneDeep} from 'lodash';

/**
 * helper function to convert object keys from one case to another
 * ex.
 * given object
 * data = {foo_bar: {baz_raz: 42}}
 * mapKeysToCase(data) => {fooBar: {bazRaz: 42}}
 * @param obj
 * @param toCaseFunc
 */
function mapKeysToCase<T extends object | null>(
  obj: T,
  toCaseFunc: (key: string) => string = camelCase
): T {
  if (obj) {
    return deepMapKeys(cloneDeep(obj), toCaseFunc) as T;
  }
  return null as T;
}

export default mapKeysToCase;
