import {Player} from '@lottiefiles/react-lottie-player';
import React, {useEffect, useRef, useState} from 'react';
import {
  Body,
  Button,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import finalAnimationData from '@/assets/lotties/auto-fill-banner-animation_final.json';
import initialAnimationData from '@/assets/lotties/auto-fill-banner-animation_initial.json';
import disabledBannerAnimationData from '@/assets/lotties/auto-fill-banner-animation_initial_disabled.json';
import completedLoadingAnimationData from '@/assets/lotties/auto-fill-banner-animation_middle-loop-full.json';
import uncompletedLoadingAnimationData from '@/assets/lotties/auto-fill-banner-animation_middle-loop-outline.json';
import translate from '@/utils/translate';
import {
  AUTOFILL_BANNER_STATES,
  AUTOFILL_LOADING_PERCENTAGE,
} from '@/views/campaigns/brief/constants';
import styles from './AutoFillBanner.module.scss';

const TRANSLATION_PREFIX =
  'components.campaign-brief.components.auto-fill-banner';

type AutoFillBannerProps = {
  testID?: string;
  onAutofillClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  autofillProgressPercentage?: number;
};

type InitialStateProps = {
  testID: string;
  initialAnimationPlayerRef: React.RefObject<Player>;
  shouldPlayInitialAnimation: boolean;
  onEvent: (event: string) => void;
  onClick: () => void;
  disabled: boolean;
};

type LoadingProgressAnimationProps = {
  autofillProgressPercentage: number;
  onLoadingAnimationComplete: () => void;
};

type AutoFillBannerState =
  (typeof AUTOFILL_BANNER_STATES)[keyof typeof AUTOFILL_BANNER_STATES];

function InitialState({
  testID,
  initialAnimationPlayerRef,
  shouldPlayInitialAnimation,
  onEvent,
  onClick,
  disabled,
}: InitialStateProps) {
  return (
    <div className={styles.relativePosition}>
      {disabled ? (
        <Player src={disabledBannerAnimationData} className={styles.flex} />
      ) : (
        <Player
          ref={initialAnimationPlayerRef}
          src={initialAnimationData}
          autoplay={shouldPlayInitialAnimation}
          keepLastFrame
          className={`${styles.flex} ${styles.lottiePlayer}`}
          onEvent={onEvent}
        />
      )}
      {!shouldPlayInitialAnimation ? (
        <div>
          <div
            className={`${styles.overlayContent} ${styles.initialStateContentContainer}`}
          >
            <div>
              <Headline
                size="md"
                color={
                  disabled
                    ? designSystemToken('semantic.fg.disabled')
                    : '#9747FF'
                }
                textAlign="center"
              >
                {translate(`${TRANSLATION_PREFIX}.initial.title`)}
              </Headline>
              <Body
                size="md"
                color={
                  disabled
                    ? designSystemToken('semantic.fg.disabled')
                    : '#9747FF'
                }
                textAlign="center"
              >
                {translate(`${TRANSLATION_PREFIX}.initial.subtitle`)}
              </Body>
            </div>
            <div className={`${styles.actionButton} ${styles.disabled}`}>
              <Button
                testID={`${testID}--action-button`}
                appearance="neutral"
                mode="tinted"
                size="medium"
                onClick={onClick}
                disabled={disabled}
                icon={
                  <Icon
                    size="medium"
                    appearance="neutral"
                    name="Features-MagicPen"
                    color={
                      disabled
                        ? 'rgba(163, 166, 172, 0.50)'
                        : designSystemToken('semantic.fg.white')
                    }
                  />
                }
              >
                <div
                  className={`${styles.label} ${
                    disabled ? styles.disabled : ''
                  }`}
                >
                  {translate(`${TRANSLATION_PREFIX}.initial.button`)}
                </div>
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function LoadingState({
  autofillProgressPercentage,
  onLoadingAnimationComplete,
}: LoadingProgressAnimationProps) {
  const [maskWidth, setMaskWidth] = useState(0);

  /**
   * @param percent the width percentage the mask is currently in
   * @returns the inverse percentage of the mask's child such that their multiplication is the entire width of the mask's father in the DOM.
   */
  function getInverseChildPercent(percent: number) {
    if (percent === AUTOFILL_LOADING_PERCENTAGE.START) {
      return 0;
    }
    return 10000 / percent;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setMaskWidth((prevWidth) => {
        if (prevWidth < autofillProgressPercentage) {
          return prevWidth + 1;
        }
        return prevWidth;
      });
    }, 20);

    return () => clearInterval(interval);
  }, [autofillProgressPercentage, maskWidth]);

  useEffect(() => {
    if (maskWidth === 100) {
      onLoadingAnimationComplete();
    }
  }, [maskWidth]);

  return (
    <div className={styles.loadingStateContainer}>
      <div className={styles.mask} style={{width: `100%`}}>
        <div className={styles.completedAnimationLayoutContainer}>
          <div
            className={styles.completedAnimationLayout}
            style={{
              width: `${maskWidth}%`,
            }}
          >
            <div className={styles.mask} style={{width: 1000}}>
              <Player
                src={completedLoadingAnimationData}
                className={styles.flex}
                autoplay
                style={{width: `${getInverseChildPercent(maskWidth)}%`}}
                loop
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.purpleBorderOverlay}>
        <Player
          src={uncompletedLoadingAnimationData}
          className={`${styles.baseLayer} ${styles.flex}`}
          autoplay
          loop
        />
      </div>
    </div>
  );
}

function CompletedState({testID}: {testID: string}) {
  return (
    <div
      data-testid={`${testID}--completed`}
      className={styles.initialOrFinalStatesLayout}
    >
      <Player
        src={finalAnimationData}
        autoplay
        keepLastFrame
        className={styles.flex}
      />
      <div className={styles.overlayContent}>
        <Headline size="md" textAlign="center">
          {translate(`${TRANSLATION_PREFIX}.completed.title`)}
        </Headline>
        <Body size="md" textAlign="center" className={styles.completedSubtitle}>
          <span>
            {translate(`${TRANSLATION_PREFIX}.completed.subtitle.part1`)}
          </span>
          <span>
            {translate(`${TRANSLATION_PREFIX}.completed.subtitle.part2`)}
          </span>
        </Body>
      </div>
    </div>
  );
}

function AutoFillBanner({
  testID = 'auto-fill-banner',
  onAutofillClick,
  disabled = false,
  loading = false,
  autofillProgressPercentage = 0,
}: AutoFillBannerProps) {
  const [currentState, setCurrentState] = useState<AutoFillBannerState>(
    AUTOFILL_BANNER_STATES.INITIAL
  );

  const [loadingAnimationCompleted, setLoadingAnimationCompleted] =
    useState(false);

  const [shouldPlayInitialAnimation, setShouldPlayInitialAnimation] =
    useState(false);

  const initialAnimationPlayerRef = useRef<Player>(null);

  const onInitialAnimationComplete = () => {
    setCurrentState(AUTOFILL_BANNER_STATES.LOADING);
    setShouldPlayInitialAnimation(false);
  };

  useEffect(() => {
    if (loadingAnimationCompleted) {
      setCurrentState(AUTOFILL_BANNER_STATES.COMPLETED);
    }
  }, [loadingAnimationCompleted]);

  useEffect(() => {
    if (loading) {
      setShouldPlayInitialAnimation(true);
      if (initialAnimationPlayerRef.current) {
        initialAnimationPlayerRef.current.play();
      }
    }
  }, [loading]);

  return (
    <div className={styles.container} data-testid={testID}>
      <div
        className={`${styles.bannerContainer} ${
          currentState === AUTOFILL_BANNER_STATES.INITIAL && !disabled
            ? styles.purpleBorderDirect
            : ''
        } ${disabled ? styles.disabledBorder : ''}`}
      >
        {currentState === AUTOFILL_BANNER_STATES.INITIAL && (
          <InitialState
            testID={testID}
            initialAnimationPlayerRef={initialAnimationPlayerRef}
            shouldPlayInitialAnimation={shouldPlayInitialAnimation}
            onEvent={(event) => {
              if (event === 'complete') {
                onInitialAnimationComplete();
              }
            }}
            onClick={onAutofillClick}
            disabled={disabled}
          />
        )}
        {currentState === AUTOFILL_BANNER_STATES.LOADING && (
          <LoadingState
            autofillProgressPercentage={autofillProgressPercentage}
            onLoadingAnimationComplete={() =>
              setLoadingAnimationCompleted(true)
            }
          />
        )}
        {currentState === AUTOFILL_BANNER_STATES.COMPLETED && (
          <CompletedState testID={testID} />
        )}
      </div>
      <div
        className={styles.disclaimer}
        dangerouslySetInnerHTML={{
          __html: translate(`${TRANSLATION_PREFIX}.disclaimer`),
        }}
      />
    </div>
  );
}

export default AutoFillBanner;
