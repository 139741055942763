import {TooltipItem} from 'chart.js';
import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import numeral from 'numeral';
import React, {useMemo} from 'react';
import getChartLabelAbbreviation from '@/utils/getChartLabelAbbreviation';
import getTickValues from '@/utils/getTickValues';
import isNumber from '@/utils/isNumber';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_DAY_SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import {TiktokSocialHealthChartProps} from '@/components/creator-profile/components/tiktok-social-health/tiktokSocialHealthChart';
import {
  ADDITIONAL_OPTIONS_BASE,
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';

const TRANSLATION_PREFIX =
  'components.creator-profile.tiktok-social-health.charts.views';

function ViewsChart(props: TiktokSocialHealthChartProps) {
  const {audiences} = props;
  const sortedAudiences = useMemo(
    () => sortBy(audiences, (audience) => dayjs(audience.createdAt).unix()),
    [audiences]
  );

  const averageViewsData = sortedAudiences.map(
    ({averageViews}) => averageViews
  );

  const dates = sortedAudiences.map(({createdAt}) => createdAt);
  const viewsWithoutNulls = averageViewsData.filter(isNumber);
  const viewTickValues = getTickValues(viewsWithoutNulls);

  const chart = (
    <LineChart
      datasets={[
        {
          label: translate(`${TRANSLATION_PREFIX}.average-views`),
          fill: false,
          data: averageViewsData,
          yAxisID: 'y',
          backgroundColor: LEGACY_COLORS.BLUE,
          borderColor: LEGACY_COLORS.BLUE,
          borderWidth: 2,
          cubicInterpolationMode: 'monotone',
        },
      ]}
      additionalOptions={{
        ...ADDITIONAL_OPTIONS_BASE,
        scales: {
          x: getDefaultScaleX(dates),
          y: getDefaultScaleY(
            translate(`${TRANSLATION_PREFIX}.views`),
            viewTickValues,
            (label) => getChartLabelAbbreviation(label as number)
          ),
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            intersect: false,
            callbacks: {
              label(tooltipItem: TooltipItem<'line'>) {
                const datasetLabel = tooltipItem.dataset.label;
                const yValue = tooltipItem.raw as number;

                let label = datasetLabel ? `${datasetLabel}: ` : '';
                if (tooltipItem.datasetIndex === 0) {
                  const numOfFollowers = Math.round(yValue * 100) / 100;
                  label += numeral(numOfFollowers).format('0,0');
                  return label;
                }

                label += Math.round(yValue * 100) / 100;
                label += '%';

                return label;
              },
              title([tooltipItem]) {
                return dayjs(dates[tooltipItem.dataIndex]).format(
                  SHORT_DAY_SHORT_MONTH_YEAR_FORMAT
                );
              },
            },
          },
        },
      }}
      labels={dates}
    />
  );

  return (
    <ChartLayout
      title={translate(`${TRANSLATION_PREFIX}.average-views`)}
      chart={chart}
      showEmptyState={viewsWithoutNulls.length === 0}
    />
  );
}

export default ViewsChart;
