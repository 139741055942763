import React, {useRef} from 'react';
import translate from '@/utils/translate';
import BriefFieldHelperPopper from '@/views/campaigns/brief/components/brief-field-helper-popper';
import BriefItem from '@/views/campaigns/brief/components/campaign-brief/components/brief-item';
import Divider from '@/views/campaigns/brief/components/campaign-brief/components/divider';
import {SectionProps} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CaptionGuidelines from '@/views/campaigns/brief/components/campaign-brief/section/deliverables/caption-guidelines';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import removeBriefItemSuffix from '@/views/campaigns/brief/utils/removeBriefItemSuffix';
import styles from './Deliverables.module.scss';
import CampaignDeliverables from './campaign-deliverables';

const TRANSLATION_PREFIX = 'components.campaign-brief.sections.deliverables';

function Deliverables(props: SectionProps) {
  const {
    updateBriefForm,
    editable,
    errors,
    saveCampaign,
    briefFieldManager,
    campaign,
    viewPresentationId,
  } = props;
  const onProductToFeatureChangeRef = useRef<any>(null);
  onProductToFeatureChangeRef.current = (value: string) =>
    updateBriefForm?.(
      `campaignPaidGigBriefItems.${briefFieldManager.fields.productToFeatureBriefItem?.index}.contentHtml`,
      value,
      'productToFeatureBriefItem'
    );

  return (
    <div className={styles.container}>
      <CampaignDeliverables
        briefFieldManager={briefFieldManager}
        errors={errors}
        editable={editable}
        network={briefFieldManager.fields.network.value}
        minimumChildContentSubmissionCount={
          briefFieldManager.fields.minimumChildContentSubmissionCount.value
        }
        campaignBriefDeliverableItems={
          briefFieldManager.fields.campaignBriefDeliverableItems.value
        }
        updateBriefForm={updateBriefForm}
        saveCampaign={saveCampaign}
        campaignId={campaign.id}
        viewPresentationId={viewPresentationId}
      />
      <Divider />
      <div className={styles.rowGap}>
        {briefFieldManager.fields.productToFeatureBriefItem ? (
          <BriefFieldHelperPopper
            fieldName={
              briefFieldManager.fields.productToFeatureBriefItem?.agentKey
            }
            fieldValue={
              briefFieldManager.fields.productToFeatureBriefItem?.value
                ?.contentHtml
            }
            viewPresentationId={viewPresentationId}
            onChange={onProductToFeatureChangeRef.current}
            saveCampaign={saveCampaign}
            disabled={!editable}
            campaignId={campaign.id}
          >
            {(childrenProps) => (
              <BriefItem
                {...childrenProps}
                testID={
                  CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__PRODUCT_TO_FEATURE_INPUT
                }
                briefItem={
                  briefFieldManager.fields.productToFeatureBriefItem?.value
                }
                editable={editable}
                onChange={onProductToFeatureChangeRef.current}
                placeholder={translate(
                  `${TRANSLATION_PREFIX}.product-to-feature.placeholder`
                )}
                saveCampaign={saveCampaign}
                errors={errors?.productToFeatureBriefItem}
                campaignId={campaign.id}
                fieldName={
                  briefFieldManager.fields.productToFeatureBriefItem?.agentKey
                }
              />
            )}
          </BriefFieldHelperPopper>
        ) : null}
        <BriefItem
          testID={
            CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__CONTENT_MANDATORIES_INPUT
          }
          briefItem={
            briefFieldManager.fields.contentMandatoriesBriefItem?.value
          }
          editable={editable}
          optional
          onChange={(value) =>
            updateBriefForm?.(
              `campaignPaidGigBriefItems.${briefFieldManager.fields.contentMandatoriesBriefItem?.index}.contentHtml`,
              value,
              'contentMandatoriesBriefItem'
            )
          }
          placeholder={translate(
            `${TRANSLATION_PREFIX}.content-mandatories.placeholder`
          )}
          saveCampaign={saveCampaign}
          errors={errors?.postingInstructionsBriefItem}
          campaignId={campaign.id}
          fieldName={removeBriefItemSuffix(
            briefFieldManager.fields.contentMandatoriesBriefItem?.key
          )}
        />
        {!briefFieldManager.fields.sectionsToHide.value?.postingInstructions ? (
          <BriefItem
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__POSTING_INSTRUCTIONS_INPUT
            }
            briefItem={
              briefFieldManager.fields.postingInstructionsBriefItem?.value
            }
            editable={editable}
            onChange={(value) =>
              updateBriefForm?.(
                `campaignPaidGigBriefItems.${briefFieldManager.fields.postingInstructionsBriefItem?.index}.contentHtml`,
                value,
                'postingInstructionsBriefItem'
              )
            }
            saveCampaign={saveCampaign}
            errors={errors?.postingInstructionsBriefItem}
            campaignId={campaign.id}
            fieldName={
              briefFieldManager.fields.postingInstructionsBriefItem?.agentKey
            }
          />
        ) : null}
        {!briefFieldManager.fields.sectionsToHide.value?.campaignHashtag ? (
          <CaptionGuidelines
            briefFieldManager={briefFieldManager}
            testID={CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__CAPTION_GUIDELINES}
            updateBriefForm={updateBriefForm}
            editable={editable}
            saveCampaign={saveCampaign}
            errors={errors}
            swapMatchKeywords={briefFieldManager.fields.swapMatchKeywords.value}
            captionGuidelines={
              briefFieldManager.fields.campaignBriefCaptionGuidelines.value
            }
            campaignId={campaign.id}
            viewPresentationId={viewPresentationId}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Deliverables;
