import getScoreFromSeverity from '@/components/creator-vetting-report/utils/getScoreFromSeverity';
import getSeverityFromFlagScore from '@/components/creator-vetting-report/utils/getSeverityFromFlagScore';

function getRiskLevelFromPercentage(percentage: number): number {
  if (percentage === 0) {
    return 0;
  }
  return getScoreFromSeverity(getSeverityFromFlagScore(percentage / 100)) * 100;
}

export default getRiskLevelFromPercentage;
