import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import CheckboxLabel from '@/views/creators/components/checkbox-label';
import {FilterProps} from '../FilterProps';
import styles from './MultiSelectionOptionsList.module.scss';

interface MultiSelectionOptionsListProps extends FilterProps {
  options: BaseFilterOption[];
  selectedOptions?: BaseFilterOption[];
  onChange: (option: BaseFilterOption) => void;
}

function MultiSelectionOptionsList(props: MultiSelectionOptionsListProps) {
  const {options, selectedOptions = [], onChange} = props;

  const selectedOptionsMap = useMemo(
    () =>
      selectedOptions.reduce(
        (acc: {[key: string]: boolean}, curr: BaseFilterOption) => {
          acc[curr.id] = true;
          return acc;
        },
        {}
      ),
    [selectedOptions]
  );

  const handleCheckboxChange = (option: BaseFilterOption) => {
    onChange(option);
  };

  return (
    <div className={styles.container}>
      {options.map((option) => (
        <div key={option.id} className={styles.creatorCategory}>
          <CheckboxLabel
            checked={!!selectedOptionsMap[option.id]}
            onChange={(checked: boolean) => handleCheckboxChange(option)}
            label={translate(option.localeLabelKey)}
          />
        </div>
      ))}
    </div>
  );
}

export default MultiSelectionOptionsList;
