/* eslint-disable react/no-unknown-property */
import {MessageInput} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import React, {useEffect, useMemo, useState} from 'react';
import {designSystemToken, Icon, Tag} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import AnimatedLinearGradient from '@/views/campaigns/brief/components/animated-linear-gradient';
import styles from './BriefFieldHelper.module.scss';

const TRANSLATION_PREFIX = 'views.campaigns.components.brief-field-helper';
const PRESET_LIST = [
  {id: 'AUTO_FILL', prompt: 'Auto-fill', showOnEmpty: true},
  {id: 'RE_DO', prompt: 'Re-do', showOnEmpty: false},
  {id: 'WRITE_IT_BETTER', prompt: 'Write it better', showOnEmpty: false},
  {id: 'MAKE_IT_SHORTER', prompt: 'Make it shorter', showOnEmpty: false},
  {id: 'ADD_MORE_DETAILS', prompt: 'Add more details', showOnEmpty: false},
];

export type BriefFieldHelperProps = {
  testID?: string;
  value?: string;
  fieldValue?: string;
  onChange?: (value: string) => void;
  loading?: boolean;
  showError?: boolean;
  submit?: (message: string) => void;
};

function ThreeDotsLoader() {
  return (
    <div className={styles.loader}>
      <span className={styles.loaderDot}>•</span>
      <span className={styles.loaderDot}>•</span>
      <span className={styles.loaderDot}>•</span>
    </div>
  );
}

function BriefFieldHelper(props: BriefFieldHelperProps) {
  const {
    testID = 'brief-field-helper',
    value = '',
    fieldValue = '',
    onChange,
    loading = false,
    showError = false,
    submit,
  } = props;
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleOnChange = (newValue: string) => {
    setInputValue(newValue);
    onChange?.(newValue);
  };

  const handleSubmit = (message: string) => {
    if (message.trim() === '') {
      return;
    }
    submit?.(message);
  };

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.magicPen}>
          <Icon
            size="medium"
            appearance="neutral"
            color="white"
            name="Features-MagicPen"
          />
        </div>
        <label>{translate(`${TRANSLATION_PREFIX}.title`)}</label>
      </div>
    );
  };

  const presetsList = useMemo(
    () =>
      PRESET_LIST.filter((preset) =>
        preset.showOnEmpty ? !fieldValue : fieldValue
      ),
    [fieldValue]
  );

  const renderPresets = () => {
    // TODO: disabled for the time being
    return null;
    return (
      <div className={styles.presets}>
        {presetsList.map((preset) => {
          const isSelected = preset.prompt === inputValue;
          const disabled = loading || isSelected;

          return (
            <Tag
              key={preset.id}
              text={preset.prompt}
              textColor={
                !disabled
                  ? designSystemToken('semantic.fg.primary')
                  : designSystemToken('semantic.fg.disabled')
              }
              backgroundColor={
                !disabled
                  ? designSystemToken('semantic.bg.neutral-inverse')
                  : designSystemToken('semantic.bg.disabled-secondary')
              }
              onClick={
                !disabled ? () => handleSubmit(preset.prompt) : undefined
              }
            />
          );
        })}
      </div>
    );
  };

  const renderInputField = () => {
    return (
      // eslint-disable-next-line react/no-unknown-property,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div className={styles.inputArea} as={MessageInput}>
        <MessageInput
          data-testid={`${testID}--message-input`}
          value={inputValue}
          onChange={(newValue: string) => handleOnChange(newValue)}
          onSend={(message: string) => handleSubmit(message)}
          className={styles.messageInput}
          disabled={loading}
          attachButton={false}
          sendButton={false}
          placeholder={translate(`${TRANSLATION_PREFIX}.placeholder`)}
        />

        <div
          onClick={() => handleSubmit(inputValue)}
          className={`${styles.actionArea} ${loading ? styles.loading : ''}`}
          data-testid={`${testID}--send-button`}
        >
          {loading ? (
            <ThreeDotsLoader />
          ) : (
            <div
              className={`${styles.customSendButton} ${
                inputValue ? '' : styles.disabledCursor
              }`}
            >
              <Icon
                size="medium"
                appearance={inputValue ? 'neutral' : 'disabled'}
                color={
                  inputValue
                    ? designSystemToken('reference.purple.60')
                    : undefined
                }
                name="Navigation-Share"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className={styles.error}>
          <label
            dangerouslySetInnerHTML={{
              __html: translate(`${TRANSLATION_PREFIX}.error-message`),
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container} data-testid={testID}>
      {loading ? (
        <AnimatedLinearGradient />
      ) : (
        <div className={styles.gradientPlaceholder} />
      )}
      <div className={styles.content}>
        {renderHeader()}
        {renderPresets()}
        <div className={styles.input}>{renderInputField()}</div>
        {renderError()}
      </div>
    </div>
  );
}

export default BriefFieldHelper;
