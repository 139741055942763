import {CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefField from '@/views/campaigns/brief/brief-field-manager/brief-field/BriefField';
import CaptionGuidelinesBriefItem from '@/views/campaigns/brief/brief-field-manager/brief-field/CaptionGuidelinesBriefItem';

class CampaignBriefCaptionGuidelines extends BriefField {
  briefFieldManager: BriefFieldManagerAttributes;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    value: any
  ) {
    super(briefFieldManager, campaignData, key, value);
    this.briefFieldManager = briefFieldManager;
  }

  set value(value: any) {
    super.value = value;
    if (this.briefFieldManager.fields.captionGuidelinesBriefItem) {
      (
        this.briefFieldManager.fields
          .captionGuidelinesBriefItem as CaptionGuidelinesBriefItem
      ).campaignBriefCaptionGuidelines = value;
    }
  }

  get value() {
    return super.value;
  }
}

export default CampaignBriefCaptionGuidelines;
