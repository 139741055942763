import React, {useMemo} from 'react';
import {Audience} from '@/types/audience';
import {CreatorProfileAbilities} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import {Post} from '@/types/post';
import Conditional from '@/components/conditional';
import AudienceGraphics from '@/components/creator-profile/components/identity-social-health/components/audience-graphics';
import FollowersCountChart from '@/components/creator-profile/components/identity-social-health/components/followers-count-chart';
import FollowersCountVsEngagementRateChart from '@/components/creator-profile/components/identity-social-health/components/followers-count-vs-engagement-rate-chart';
import HealthScores from '@/components/creator-profile/components/identity-social-health/components/health-scores';
import ImpressionsChart from '@/components/creator-profile/components/identity-social-health/components/impressions-chart';
import ImpressionsPerFollowerChart from '@/components/creator-profile/components/identity-social-health/components/impressions-per-follower-chart';
import PaidVsOrganicChart from '@/components/creator-profile/components/identity-social-health/components/paid-vs-organic-chart';
import useIdentityHealthEvaluationQuery from '@/hooks/queries/use-identity-health-evaluation-query';
import styles from './IdentitySocialHealth.module.scss';

export interface IdentitySocialHealthProps {
  testID?: string;
  creatorProfileAbilities: CreatorProfileAbilities;
  identity: Identity;
  posts: Post[];
  postsIncludingDeleted: Post[];
  isLoadingPosts: boolean;
  isLoadingPostsIncludingDeleted: boolean;
}

function IdentitySocialHealth(props: IdentitySocialHealthProps) {
  const {
    testID = 'identity-social-health',
    creatorProfileAbilities,
    identity,
    posts,
    postsIncludingDeleted,
    isLoadingPosts,
    isLoadingPostsIncludingDeleted,
  } = props;

  const {healthEvaluation, isLoading: socialEvaluationIsLoading} =
    useIdentityHealthEvaluationQuery({
      identityId: identity.id,
    });

  const showHealthScores = useMemo(() => {
    return (
      identity.provider === 'instagram' &&
      creatorProfileAbilities.showSocialHealth
    );
  }, [identity.provider, creatorProfileAbilities.showSocialHealth]);

  const showAudienceGraphics = useMemo(() => {
    return (
      identity.provider === 'instagram' && creatorProfileAbilities.showAudience
    );
  }, [identity.provider, creatorProfileAbilities.showAudience]);

  return (
    <div className={styles.identitySocialHealthContainer} data-testid={testID}>
      <Conditional
        condition={
          showHealthScores &&
          !socialEvaluationIsLoading &&
          'dataSets' in healthEvaluation &&
          'scores' in healthEvaluation
        }
      >
        <HealthScores
          testID={`${testID}--health-scores`}
          healthEvaluation={healthEvaluation}
        />
      </Conditional>
      <Conditional condition={showAudienceGraphics && !!identity.audience}>
        <AudienceGraphics
          testID={`${testID}--audience-graphics`}
          identity={identity}
          audience={identity.audience as Audience}
        />
      </Conditional>
      <Conditional condition={creatorProfileAbilities.showSocialHealth}>
        <div
          data-testid={`${testID}--social-health-graphs`}
          className={styles.socialHealthCharts}
        >
          <FollowersCountChart
            identity={identity}
            posts={posts}
            isLoadingPosts={isLoadingPosts}
          />
          <FollowersCountVsEngagementRateChart
            identity={identity}
            posts={posts}
            isLoadingPosts={isLoadingPosts}
          />
          <ImpressionsChart
            identity={identity}
            posts={posts}
            isLoadingPosts={isLoadingPosts}
          />
          <ImpressionsPerFollowerChart
            identity={identity}
            posts={posts}
            isLoadingPosts={isLoadingPosts}
          />
          <PaidVsOrganicChart
            identity={identity}
            posts={postsIncludingDeleted}
            isLoadingPosts={isLoadingPostsIncludingDeleted}
          />
        </div>
      </Conditional>
    </div>
  );
}

export default IdentitySocialHealth;
