import React from 'react';
import translate from '@/utils/translate';
import Link from '@/components/link';
import styles from './NotFound.module.scss';

function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img src="/assets/svg/logo-p-green.svg" alt="" />
        </div>

        <div className={styles.title}>
          {translate('components.not-found.error-code')}
        </div>

        <p className={styles.body}>{translate('components.not-found.body')}</p>

        <Link to="/" className={styles.link}>
          {translate('components.not-found.home-link')}
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
