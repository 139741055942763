import React from 'react';
import {Icon} from '@lightricks/react-design-system';
import styles from './FlashMessage.module.scss';

type FlashMessageProps = {
  testID?: string;
  message?: string | React.ReactNode;
  onCloseCallback?: () => void;
  style?: object;
  icon?: React.ReactNode;
  withCloseButton?: boolean;
  mode?: 'dark' | 'light';
};

function FlashMessage(props: FlashMessageProps) {
  const {
    testID = 'flash-message-item',
    message,
    onCloseCallback,
    style = {},
    icon,
    withCloseButton = true,
    mode = 'dark',
  } = props;

  const getCloseIconColor = () => {
    if (mode === 'dark') {
      return 'neutral-secondary';
    }
    return 'secondary';
  };
  return (
    <div className={`${styles.container} ${mode}`} style={style}>
      <div className={styles.messageContainer}>
        {icon ? <div className={styles.iconContainer}>{icon}</div> : null}
        <span className={styles.message}>{message}</span>
      </div>
      {withCloseButton ? (
        <div className={styles.closeButtonContainer}>
          <div
            data-testid={`${testID}--close-button`}
            role="button"
            aria-label="close-button"
            tabIndex={0}
            className={styles.closeButton}
            onClick={onCloseCallback}
          >
            <Icon
              size="large"
              appearance={getCloseIconColor()}
              name="Actions-Close-Small"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

function FlashMessageBanner({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <div className={styles.banner}>
      <label className={styles.title}>{title}</label>
      <label className={styles.subtitle}>{subtitle}</label>
    </div>
  );
}

FlashMessage.Banner = FlashMessageBanner;

export default FlashMessage;
