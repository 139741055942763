import dayjs from 'dayjs';
import {MRT_Row} from 'material-react-table';
import React from 'react';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';

function DateAddedCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  return <>{dayjs(row.original.createdAt).format('M.DD.YY')}</>;
}

export default DateAddedCell;
