import {orderBy} from 'lodash';
import React, {useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import getRiskLevelFromPercentage from '@/components/creator-vetting-report/utils/getRiskLevelFromPercentage';
import Divider from '@/components/divider';
import GradientProgressBar from '@/components/gradient-progress-bar';
import InfoTooltip from '@/components/info-tooltip';
import {
  VettingCategories,
  VettingCategory,
} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import styles from './ProfileVettingItemsFlagged.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-items-flagged';

const COLUMN_WIDTHS = {
  CATEGORY_NAME: 136,
  FLAG_NAME: '100%',
  RISK_LEVEL: '100%',
  POSTS: 100,
};

interface ProfileVettingItemsFlaggedProps {
  totalItemsScanned: number;
  totalItemsFlagged: number;
  vettingCategories: VettingCategories;
}

interface ColumnProps {
  id: string;
  width: number | string;
  content: React.ReactNode;
}

interface RowProps {
  columns: ColumnProps[];
  className?: string;
}

function PostsAmountLabel({amount}: {amount: number}) {
  return amount === 0 ? (
    <Label
      size="sm"
      className={styles.postsAmount}
      color={designSystemToken('semantic.fg.primary')}
    >
      <Icon
        size="small"
        appearance="brand"
        name="Navigation-Shopbag-Shield-Filled"
      />
      {translate(`${TRANSLATION_PREFIX}.clear`)}
    </Label>
  ) : (
    <Body
      size="sm"
      className={styles.postsAmount}
      color={designSystemToken('semantic.fg.primary')}
    >
      {amount}
    </Body>
  );
}

function Row(props: RowProps) {
  const {columns, className = ''} = props;
  return (
    <div className={`${styles.row} ${className}`}>
      {columns.map((column) => {
        return (
          <div
            key={column.id}
            className={styles.column}
            style={{width: column.width}}
          >
            {column.content}
          </div>
        );
      })}
    </div>
  );
}

interface RiskLevelPercentageProps {
  percentage: number;
}

function RiskLevel({percentage}: RiskLevelPercentageProps) {
  return (
    <div className={styles.riskLevelContainer}>
      <div className={styles.progressBarContainer}>
        <GradientProgressBar value={percentage} />
      </div>
    </div>
  );
}

function CategoryTooltip({category}: {category: VettingCategory}) {
  return (
    <div className={styles.categoryTooltip}>
      <Row
        columns={[
          {
            id: 'flagName',
            width: COLUMN_WIDTHS.FLAG_NAME,
            content: (
              <Label size="md" className={styles.categoryHeaderColumn}>
                {category.name}
              </Label>
            ),
          },
          {
            id: 'posts',
            width: COLUMN_WIDTHS.POSTS,
            content: (
              <Label size="md" className={styles.categoryHeaderColumn}>
                {translate(`${TRANSLATION_PREFIX}.posts-amount`)}
              </Label>
            ),
          },
        ]}
      />
      <div className={styles.flagRows}>
        {orderBy(category.flags, ['postsAmount'], ['desc']).map((flag) => (
          <Row
            key={flag.id}
            className={styles.flagRow}
            columns={[
              {
                id: 'flagName',
                width: COLUMN_WIDTHS.FLAG_NAME,
                content: (
                  <Body
                    size="md"
                    color={designSystemToken('semantic.fg.secondary')}
                  >
                    {flag.name}
                  </Body>
                ),
              },
              {
                id: 'flagPosts',
                width: COLUMN_WIDTHS.POSTS,
                content: (
                  <Body
                    className={styles.flagPosts}
                    size="sm"
                    color={designSystemToken('semantic.fg.primary')}
                  >
                    {flag.postsAmount}
                  </Body>
                ),
              },
            ]}
          />
        ))}
      </div>
    </div>
  );
}

function VettingItem({item}: {item: VettingCategory}) {
  const {name, percentage, postsAmount} = item;

  const categoryName = (
    <Body
      size="md"
      className={styles.categoryName}
      color={designSystemToken('semantic.fg.secondary')}
    >
      {name}
    </Body>
  );

  const riskLevel = (
    <RiskLevel percentage={getRiskLevelFromPercentage(percentage)} />
  );
  const posts = <PostsAmountLabel amount={postsAmount} />;

  return (
    <Tooltip
      followCursor
      title={<CategoryTooltip category={item} />}
      className={styles.tooltip}
    >
      <div>
        <Row
          columns={[
            {
              id: 'categoryName',
              width: COLUMN_WIDTHS.CATEGORY_NAME,
              content: categoryName,
            },
            {
              id: 'riskLevel',
              width: COLUMN_WIDTHS.RISK_LEVEL,
              content: riskLevel,
            },
            {id: 'posts', width: COLUMN_WIDTHS.POSTS, content: posts},
          ]}
        />
      </div>
    </Tooltip>
  );
}

function ProfileVettingItemsFlagged(props: ProfileVettingItemsFlaggedProps) {
  const {totalItemsScanned, totalItemsFlagged, vettingCategories} = props;

  const itemsFlaggedPercentage =
    totalItemsScanned === 0
      ? 0
      : Math.ceil((totalItemsFlagged / totalItemsScanned) * 100);

  const headerColumns = useMemo(
    () => [
      {
        id: 'categoryName',
        width: COLUMN_WIDTHS.CATEGORY_NAME,
        content: (
          <Label size="md" className={styles.categoryName}>
            {translate(`${TRANSLATION_PREFIX}.category`)}
          </Label>
        ),
      },
      {
        id: 'riskLevel',
        width: COLUMN_WIDTHS.RISK_LEVEL,
        content: (
          <div className={styles.riskLevelColumnHeader}>
            <Label size="md">
              {translate(`${TRANSLATION_PREFIX}.risk-level`)}
            </Label>
            <InfoTooltip title={translate('risk-level-tooltip')} />
          </div>
        ),
      },
      {
        id: 'posts',
        width: COLUMN_WIDTHS.POSTS,
        content: (
          <Label size="md" className={styles.postsAmount}>
            {translate(`${TRANSLATION_PREFIX}.posts-amount`)}
          </Label>
        ),
      },
    ],
    []
  );

  return (
    <div
      className={styles.profileVettingItemsFlaggedContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY}
    >
      <div
        className={styles.header}
        data-testid={
          CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY__HEADER
        }
      >
        <Headline size="xl" color={designSystemToken('semantic.fg.primary')}>
          {itemsFlaggedPercentage}%
        </Headline>
        <Label size="xl" color={designSystemToken('semantic.fg.primary')}>
          {translate(`${TRANSLATION_PREFIX}.subtitle`)}
        </Label>
      </div>
      <div
        className={styles.itemsContainer}
        data-testid={
          CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY__FLAGGED_ITEMS
        }
      >
        <Row columns={headerColumns} className={styles.itemsContainerHeader} />
        <div className={styles.items}>
          {vettingCategories.flagged.map((flaggedItem) => (
            <VettingItem key={flaggedItem.id} item={flaggedItem} />
          ))}
        </div>
      </div>
      <Conditional
        condition={
          vettingCategories.flagged.length > 0 &&
          vettingCategories.cleared.length > 0
        }
      >
        <Divider margin={0} />
      </Conditional>
      <Conditional condition={vettingCategories.cleared.length > 0}>
        <div
          className={styles.itemsContainer}
          data-testid={
            CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT_SUMMARY__CLEARED_ITEMS
          }
        >
          <div className={styles.items}>
            {vettingCategories.cleared.map((clearedItem) => (
              <VettingItem key={clearedItem.id} item={clearedItem} />
            ))}
          </div>
        </div>
      </Conditional>
    </div>
  );
}

export default ProfileVettingItemsFlagged;
