import React, {useEffect, useRef, useState} from 'react';
import {Button} from '@lightricks/react-design-system';
import getCapsuleAiImageUrl from '@/utils/getCapsuleAiImageUrl';
import getImageDimensionsFromUrl from '@/utils/getImageDimensionsFromUrl';
import translate from '@/utils/translate';
import ImageUploadProcess from '@/views/ai-content-generator/components/image-upload-process';
import {ChildRef} from '@/views/ai-content-generator/components/image-upload-process/ImageUploadProcess';
import UploadImagePolicy from '@/views/ai-content-generator/components/upload-image-policy';
import useImagesCount from '@/hooks/queries/capsule-ai/use-images-count/useImagesCount';
import useTransparentImageQuery from '@/hooks/queries/capsule-ai/use-transparent-image-query';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import {useContentGenerationStore} from '@/contexts/ContentGenerationStore';
import styles from './WelcomeFrame.module.scss';

function WelcomeFrame() {
  const {state, dispatch} = useContentGenerationStore();
  const brandId = useBrandId();
  const {dashboardUser}: any = useDashboardUserQuery({});

  const {data: {imagesCount = -1} = {}, isFetched: imagesCountFetched} =
    useImagesCount(brandId, dashboardUser?.id);

  const {
    data: {transparentImage = {}} = {},
    isFetched: transparentImageFetched,
  } = useTransparentImageQuery(brandId);

  const childComponentRef = useRef<ChildRef | null>(null);

  const handleLetsGoClick = () => {
    if (childComponentRef.current) {
      // Call the submit function in the child component
      childComponentRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    (async () => {
      if (transparentImage?.imageUrl) {
        const {width, height} = await getImageDimensionsFromUrl(
          getCapsuleAiImageUrl(transparentImage.imageUrl)
        );
        dispatch({
          type: 'UPDATE_TRANSPARENT_IMAGE_SIZE',
          transparentImageWidth: String(width),
          transparentImageHeight: String(height),
        });
      }
    })();
  }, [transparentImage]);

  useEffect(() => {
    if (state.brandId !== brandId) {
      dispatch({
        type: 'RESET_NEW_GENERATED_IMAGES',
      });
      dispatch({
        type: 'SET_BRAND_ID',
        brandId,
      });
      dispatch({
        type: 'SET_IMAGES_COUNT',
        imagesListCounter: 0,
      });
    }
  }, [brandId]);

  return (
    <div className={styles.welcomeFrameContainer}>
      <div className={styles.welcomeSection}>
        <div className={styles.welcomeText}>
          <div className={styles.welcomeTitle}>
            {translate(
              'views.ai-content-generator.components.welcome-frame.title'
            )}
          </div>
          <label
            className={styles.welcomeSubtitle}
            dangerouslySetInnerHTML={{
              __html: translate(
                'views.ai-content-generator.components.welcome-frame.subtitle'
              ),
            }}
          />
        </div>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          className={styles.welcomeButton}
          onClick={handleLetsGoClick}
          disabled={!imagesCountFetched}
          testID="start-generating--button"
        >
          {translate(
            'views.ai-content-generator.components.welcome-frame.button-label'
          )}
        </Button>
      </div>
      <div className={styles.uploadImageSection}>
        <ImageUploadProcess
          key={brandId}
          imageUploadProcessRef={childComponentRef}
          transparentImageFetched={transparentImageFetched}
          transparentImageUrl={transparentImage?.imageUrl}
          transparentImageId={String(transparentImage?.id)}
          transparentImageDescription={transparentImage?.imageDescription}
          transparentImageSessionId={transparentImage?.sessionId}
          source="ai_studio_welcome_screen"
        />
      </div>
      <div className={styles.uploadPolicySection}>
        <UploadImagePolicy />
      </div>
    </div>
  );
}

export default WelcomeFrame;
