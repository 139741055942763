import React, {useMemo} from 'react';
import AnimateHeight from 'react-animate-height';
import {Checkbox} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {
  BriefFieldManagerAttributes,
  ErrorMessages,
} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import BriefSubSection from '@/views/campaigns/brief/components/campaign-brief/components/brief-sub-section';
import Divider from '@/views/campaigns/brief/components/campaign-brief/components/divider';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {
  SaveCampaign,
  SectionProps,
} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  CREATOR_COMPENSATION_TYPES,
  DEFAULT_SAVE_CAMPAIGN_DELAY,
} from '@/views/campaigns/brief/constants';
import ButtonGroup from '@/components/button-group';
import OptionButton from '@/components/option-button';
import styles from './CreatorPayments.module.scss';

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.creator-payments';

const DELIVERABLES_TYPE_OPTIONS = [
  {
    value: null,
    labelLocaleKeyTitle: `${TRANSLATION_PREFIX}.not-relevant`,
    labelLocaleKeySubtitle: `${TRANSLATION_PREFIX}.not-relevant-info`,
  },
  {
    value: 'gift_codes',
    labelLocaleKeyTitle: `${TRANSLATION_PREFIX}.discount-code`,
    labelLocaleKeySubtitle: `${TRANSLATION_PREFIX}.discount-code-info`,
  },
  {
    value: 'shipments',
    labelLocaleKeyTitle: `${TRANSLATION_PREFIX}.ship-product`,
    labelLocaleKeySubtitle: `${TRANSLATION_PREFIX}.ship-product-info`,
  },
];

const updateCreatorCompensationType = (value: string, updateBriefForm: any) => {
  updateBriefForm?.(
    'creatorCompensationType',
    value,
    'creatorCompensationType',
    DEFAULT_SAVE_CAMPAIGN_DELAY
  );
};

function CreatorCompensationRadioOption({
  checked,
  disabled,
  title,
  info,
  onClick,
  children,
}: {
  checked: boolean;
  disabled: boolean;
  title: string;
  info: string;
  onClick: (value: boolean) => void;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.creatorCompensationRadioOption}>
      <div className={styles.checkbox}>
        <Checkbox
          radio
          disabled={disabled}
          checked={checked}
          onChange={onClick}
          label={
            <div
              className={`${styles.creatorCompensationRadioOptionHeader} ${
                disabled ? styles.disabled : ''
              }`}
              onClick={!disabled ? () => onClick(true) : undefined}
            >
              <label className={styles.title}>{title}</label>
              <label className={styles.info}>{info}</label>
            </div>
          }
        />
      </div>
      <AnimateHeight
        className={styles.creatorCompensationRadioOptionContent}
        duration={500}
        height={checked ? 'auto' : 0}
      >
        {children}
      </AnimateHeight>
    </div>
  );
}

function CreatorCompensation({
  editable,
  budgetMin,
  budgetMax,
  budgetFixed,
  updateBriefForm,
  saveCampaign,
  onChange,
  errors,
  briefFieldManager,
  campaignId,
}: {
  editable?: boolean;
  budgetMin?: number | null;
  budgetMax?: number | null;
  budgetFixed?: number | null;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  saveCampaign?: SaveCampaign;
  onChange?: (path: string, value: any) => void;
  errors?: ErrorMessages;
  briefFieldManager: any;
  campaignId: string;
}) {
  const budgetRangeData = useMemo(() => {
    return {min: budgetMin, max: budgetMax, fixed: budgetFixed};
  }, [budgetMin, budgetMax, budgetFixed]);

  const getCashValue = (
    label: string,
    path: string,
    value: number | null | undefined,
    testID: string
  ) => {
    function getErrors(returnErrorsIfBothValuesAreFilled = false) {
      const bothValuesAreFilled =
        typeof budgetMin === 'number' && typeof budgetMax === 'number';
      switch (path) {
        case 'suggestedCostForCreationsMin':
          if (!value) {
            return errors?.[path];
          }
          return bothValuesAreFilled || returnErrorsIfBothValuesAreFilled
            ? errors?.[path]
            : [];
        case 'suggestedCostForCreations':
          if (!value) {
            return errors?.[path];
          }
          return returnErrorsIfBothValuesAreFilled ? errors?.[path] : [];
        case 'fixedCostForCreations':
          return errors?.[path];
        default:
          return errors?.[path];
      }
    }

    function getOnInputChange() {
      switch (path) {
        case 'suggestedCostForCreationsMin':
          return (_value: string | number) => {
            onChange?.('fixedCostForCreations', '');
            onChange?.(path, _value ? Number(_value) : _value);
          };
        case 'suggestedCostForCreations':
          return (_value: string | number) => {
            onChange?.('fixedCostForCreations', '');
            onChange?.(path, _value ? Number(_value) : _value);
          };
        case 'fixedCostForCreations':
          return (_value: string | number) => {
            onChange?.('suggestedCostForCreationsMin', '');
            onChange?.('suggestedCostForCreations', '');
            onChange?.(path, _value ? Number(_value) : _value);
          };
        default:
          return (_value: string | number) =>
            onChange?.(path, _value ? Number(_value) : _value);
      }
    }

    function getFieldName() {
      switch (path) {
        case 'suggestedCostForCreationsMin':
          return briefFieldManager.fields.suggestedCostForCreationsMin.key;
        case 'suggestedCostForCreations':
          return `${briefFieldManager.fields.suggestedCostForCreations.key}Max`;
        case 'fixedCostForCreations':
          return briefFieldManager.fields.fixedCostForCreations.key;
        default:
          return '';
      }
    }

    function getFieldValue() {
      switch (path) {
        case 'suggestedCostForCreationsMin':
          return briefFieldManager.fields.suggestedCostForCreationsMin.value;
        case 'suggestedCostForCreations':
          return briefFieldManager.fields.suggestedCostForCreations.value;
        case 'fixedCostForCreations':
          return briefFieldManager.fields.fixedCostForCreations.value;
        default:
          return '';
      }
    }

    let inputValue;

    if (path === 'fixedCostForCreations') {
      if (value !== null && value !== undefined) {
        inputValue = value;
      } else {
        inputValue = '';
      }
    } else {
      inputValue = value || '';
    }

    return (
      <ValidatedField errors={getErrors()}>
        <BriefFieldInput
          testID={testID}
          label={label}
          disabled={!editable}
          placeholder="0.00"
          value={inputValue}
          onInputChange={getOnInputChange()}
          endIcon={
            <label className={styles.currency}>
              {translate(`${TRANSLATION_PREFIX}.currency`)}
            </label>
          }
          inputProps={{
            type: 'number',
            min: 0,
            value: inputValue,
            onBlur: () =>
              saveCampaign?.({
                campaignId,
                fieldName: getFieldName(),
                fieldValue: getFieldValue(),
              }),
          }}
          hasErrors={!!getErrors(true)?.length}
        />
      </ValidatedField>
    );
  };

  return (
    <BriefSubSection
      disabled={!editable}
      title={translate(`${TRANSLATION_PREFIX}.creator-compensation`)}
      titleClassName={styles.title}
    >
      <div className={styles.creatorCompensationRangeContainer}>
        <label
          className={styles.info}
          dangerouslySetInnerHTML={{
            __html: translate(
              `${TRANSLATION_PREFIX}.creator-compensation-info`
            ),
          }}
        />
        <CreatorCompensationRadioOption
          checked={
            briefFieldManager.fields.creatorCompensationType.value ===
            CREATOR_COMPENSATION_TYPES.RANGE
          }
          disabled={!editable}
          title={translate(`${TRANSLATION_PREFIX}.suggested-range`)}
          info={translate(`${TRANSLATION_PREFIX}.suggested-range-info`)}
          onClick={() =>
            updateCreatorCompensationType(
              CREATOR_COMPENSATION_TYPES.RANGE,
              updateBriefForm
            )
          }
        >
          <div className={`${styles.row} ${styles.rowGap}`}>
            <div className={styles.column}>
              <div className={`${styles.cashValue} ${styles.big}`}>
                {getCashValue(
                  translate(`${TRANSLATION_PREFIX}.min`),
                  'suggestedCostForCreationsMin',
                  budgetRangeData.min,
                  CAMPAIGN_BRIEF_TEST_IDS.CREATOR_PAYMENTS__COMPENSATION_MIN
                )}
              </div>
            </div>
            <div className={styles.column}>
              <div className={`${styles.cashValue} ${styles.big}`}>
                {getCashValue(
                  translate(`${TRANSLATION_PREFIX}.max`),
                  'suggestedCostForCreations',
                  budgetRangeData.max,
                  CAMPAIGN_BRIEF_TEST_IDS.CREATOR_PAYMENTS__COMPENSATION_MAX
                )}
              </div>
            </div>
          </div>
        </CreatorCompensationRadioOption>
        <CreatorCompensationRadioOption
          checked={
            briefFieldManager.fields.creatorCompensationType.value ===
            CREATOR_COMPENSATION_TYPES.FIXED
          }
          disabled={!editable}
          title={translate(`${TRANSLATION_PREFIX}.fixed-price`)}
          info={translate(`${TRANSLATION_PREFIX}.fixed-price-info`)}
          onClick={() =>
            updateCreatorCompensationType(
              CREATOR_COMPENSATION_TYPES.FIXED,
              updateBriefForm
            )
          }
        >
          <div className={`${styles.row} ${styles.rowGap}`}>
            <div className={styles.column}>
              <div className={`${styles.cashValue} ${styles.big}`}>
                {getCashValue(
                  '',
                  'fixedCostForCreations',
                  budgetRangeData.fixed,
                  CAMPAIGN_BRIEF_TEST_IDS.CREATOR_PAYMENTS__COMPENSATION_FIXED
                )}
              </div>
            </div>
          </div>
        </CreatorCompensationRadioOption>
      </div>
    </BriefSubSection>
  );
}

function ProductShipping({
  editable,
  onCashValueChange,
  onShippingMethodChange,
  deliverablesType,
  estimatedFulfillmentValue,
  saveCampaign,
  errors,
  campaignId,
  briefFieldManager,
}: {
  editable?: boolean;
  onCashValueChange?: (value: any) => void;
  onShippingMethodChange?: (value: any) => void;
  deliverablesType?: 'shipments' | 'gift_codes' | null;
  estimatedFulfillmentValue?: number | null;
  saveCampaign?: SaveCampaign;
  errors?: ErrorMessages;
  campaignId: string;
  briefFieldManager: BriefFieldManagerAttributes;
}) {
  const getCashValueContent = () => {
    return (
      <div className={styles.cashValueContainer}>
        <ValidatedField errors={errors?.estimatedFulfillmentValue}>
          <BriefFieldInput
            testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_PAYMENTS__CASH_VALUE_INPUT}
            label={translate(`${TRANSLATION_PREFIX}.cash-value`)}
            placeholder="0.00"
            disabled={!deliverablesType || !editable}
            className={styles.cashValueInput}
            value={estimatedFulfillmentValue || ''}
            onInputChange={(value: string) =>
              onCashValueChange?.(value ? Number(value) : value)
            }
            endIcon={
              <label
                className={`${styles.currency} ${
                  !deliverablesType ? styles.disabled : ''
                }`}
              >
                {translate(`${TRANSLATION_PREFIX}.currency`)}
              </label>
            }
            inputProps={{
              type: 'number',
              min: 0,
              value: estimatedFulfillmentValue || '',
              onBlur: () =>
                saveCampaign?.({
                  campaignId,
                  fieldName:
                    briefFieldManager.fields.estimatedFulfillmentValue.key,
                  fieldValue:
                    briefFieldManager.fields.estimatedFulfillmentValue.value,
                }),
            }}
            hasErrors={!!errors?.estimatedFulfillmentValue}
          />
        </ValidatedField>
      </div>
    );
  };

  const getProductShippingContent = () => {
    const selectedOption = DELIVERABLES_TYPE_OPTIONS.find(
      (option) => option.value === deliverablesType
    );

    return (
      <ButtonGroup
        readOnly={!editable}
        testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_PAYMENTS__SHIPPING_METHOD_INPUT}
        containerClassName={styles.customButtonGroupStyle}
        buttons={DELIVERABLES_TYPE_OPTIONS.map((option) => ({
          ...option,
          title: translate(option.labelLocaleKeyTitle),
          subtitle: translate(option.labelLocaleKeySubtitle),
        }))}
        selectedButtonValue={selectedOption?.value}
        onChange={(button: any) => onShippingMethodChange?.(button.value)}
        renderButton={(button: any, isActive, onClick) => (
          <OptionButton
            button={button}
            className={styles.sendProductButtonContainer}
            onClick={onClick}
            isActive={isActive}
            disabled={!editable}
          >
            <div className={styles.sendProductButton}>
              <div className={styles.checkbox}>
                <Checkbox radio checked={isActive} onChange={() => {}} />
              </div>
              <div className={styles.info}>
                <label
                  data-testid={
                    isActive
                      ? CAMPAIGN_BRIEF_TEST_IDS.CREATOR_PAYMENTS__SHIPPING_METHOD
                      : undefined
                  }
                  className={styles.title}
                >
                  {button.title}
                </label>
                <label className={styles.subtitle}>{button.subtitle}</label>
              </div>
            </div>
          </OptionButton>
        )}
      />
    );
  };

  return (
    <BriefSubSection
      disabled={!editable}
      title={translate(`${TRANSLATION_PREFIX}.product-details`)}
      titleClassName={styles.title}
    >
      <div className={styles.productShippingContainer}>
        <label className={styles.info}>
          {translate(`${TRANSLATION_PREFIX}.product-details-info`)}
        </label>
        <div className={styles.productShipping}>
          <div className={styles.shippingMethod}>
            {getProductShippingContent()}
          </div>
        </div>
        <div className={styles.productCashValue}>
          <div className={styles.cashValue}>{getCashValueContent()}</div>
        </div>
      </div>
    </BriefSubSection>
  );
}

function CreatorPayments(props: SectionProps) {
  const {
    updateBriefForm,
    editable,
    saveCampaign,
    briefFieldManager,
    errors,
    campaign,
  } = props;

  return (
    <div className={styles.container}>
      <CreatorCompensation
        editable={editable}
        budgetMin={briefFieldManager.fields.suggestedCostForCreationsMin.value}
        budgetMax={briefFieldManager.fields.suggestedCostForCreations.value}
        budgetFixed={briefFieldManager.fields.fixedCostForCreations.value}
        onChange={(path: string, value: number) => {
          updateBriefForm?.(path, value, path);
        }}
        saveCampaign={saveCampaign}
        errors={errors}
        briefFieldManager={briefFieldManager}
        updateBriefForm={updateBriefForm}
        campaignId={campaign.id}
      />
      <Divider margin={32} />
      <ProductShipping
        editable={editable}
        onShippingMethodChange={(value) => {
          if (!value) {
            updateBriefForm?.(
              'estimatedFulfillmentValue',
              '',
              'estimatedFulfillmentValue'
            );
          }
          updateBriefForm?.(
            'deliverablesType',
            value,
            'deliverablesType',
            DEFAULT_SAVE_CAMPAIGN_DELAY
          );
        }}
        onCashValueChange={(value) =>
          updateBriefForm?.(
            'estimatedFulfillmentValue',
            value,
            'estimatedFulfillmentValue'
          )
        }
        deliverablesType={
          briefFieldManager.fields.deliverablesType.value || null
        }
        estimatedFulfillmentValue={
          briefFieldManager.fields.estimatedFulfillmentValue.value || ''
        }
        saveCampaign={saveCampaign}
        errors={errors}
        campaignId={campaign.id}
        briefFieldManager={briefFieldManager}
      />
    </div>
  );
}

export default CreatorPayments;
