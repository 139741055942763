import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import PaymentMethodsResponse from '@/types/paymentMethodsResponse';

// This component mounts the Adyen Web Components, which render encrypted payment input field in iframes.
// See: https://docs.adyen.com/online-payments/web-components/additional-use-cases/advanced-flow

const translations = {
  en_US: {
    'creditCard.expiryDateField.title': 'Expiration Date',
    'creditCard.holderName': 'Name on Card',
    'creditCard.holderName.placeholder': '',
    'creditCard.numberField.title': 'Card Number',
    'creditCard.numberField.placeholder': '',
    'creditCard.cvcField.title': 'Security Code',
    'creditCard.cvcField.placeholder': 'CVV',
    'creditCard.cvcField.placeholder.3digits': 'CVV',
    'billingAddress': 'Address',
    'zipCode': 'Zip Code',
  },
};

export type AdyenOnSubmitState = {
  data: {
    paymentMethod: {
      encryptedCardNumber: string;
      encryptedExpiryMonth: string;
      encryptedExpiryYear: string;
      encryptedSecurityCode: string;
    };
  };
};

function getConfig(
  paymentMethodsResponse: PaymentMethodsResponse,
  onSubmit: (state: AdyenOnSubmitState) => void
) {
  const {isSandbox, originKey, adyenPaymentMethods} =
    paymentMethodsResponse.data;

  return {
    locale: 'en_US',
    translations,
    environment: isSandbox ? 'test' : 'live',
    clientKey: originKey,
    analytics: {enabled: true},
    onSubmit,
    paymentMethodsResponse: adyenPaymentMethods,
  };
}

type MountWebComponentsArgs = {
  element: HTMLElement | null;
  isFromUnitedStates: boolean;
  paymentMethodsResponse: PaymentMethodsResponse;
  onSubmit: (state: AdyenOnSubmitState) => void;
};

async function mountWebComponents({
  element,
  isFromUnitedStates,
  paymentMethodsResponse,
  onSubmit,
}: MountWebComponentsArgs) {
  if (!element) return false;

  const checkout = await AdyenCheckout(
    getConfig(paymentMethodsResponse, onSubmit)
  );

  const unitedStatesSpecificFormSettings = !isFromUnitedStates
    ? {}
    : {
        billingAddressAllowedCountries: ['US'],
        // This is the part of the config that requires the user to select a state:
        data: {
          billingAddress: {
            country: 'US',
          },
        },
      };

  const cardConfig = {
    showBrandIcon: true,
    showBrandsUnderCardNumber: false,
    showFormInstruction: false,
    hasHolderName: true,
    holderNameRequired: true,
    positionHolderNameOnTop: true,
    billingAddressRequired: false,
    billingAddressMode: 'full',
    ...unitedStatesSpecificFormSettings,
  };

  return checkout.create('card', cardConfig).mount(element);
}

export default {mountWebComponents};
