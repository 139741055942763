import {BackgroundCheck} from '@/types/backgroundCheck';
import vettingFetchers from '@/api/fetchers/vetting';
import useDataQuery from '@/hooks/use-data-query';

interface BrandSafetyReportBackgroundCheckQueryParams {
  creatorId: string;
  reportId: string;
  url: string;
}

function useBrandSafetyReportBackgroundCheckQuery({
  creatorId,
  reportId,
  url,
}: BrandSafetyReportBackgroundCheckQueryParams) {
  return useDataQuery<BackgroundCheck>({
    queryKey: ['backgroundCheck', url],
    queryFn: vettingFetchers.backgroundCheck,
    enabled: !!creatorId && !!reportId && !!url,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    meta: {
      creatorId,
      reportId,
      url,
    },
  });
}

export default useBrandSafetyReportBackgroundCheckQuery;
