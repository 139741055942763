import {useEffect, useState} from 'react';

function useIsKeyPressed(keys: string[]): boolean {
  const [isPressed, setIsPressed] = useState(false);
  const onKeyDown = (event: KeyboardEvent) => {
    const wasAnyKeyPressed = keys.some((key) => event.key === key);
    if (wasAnyKeyPressed) {
      event.preventDefault();
      setIsPressed(true);
    }
  };

  const onKeyUp = () => {
    setIsPressed(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  return isPressed;
}

export default useIsKeyPressed;
