import {CampaignPaidGigBriefItem} from '@/types/campaign';

function getBriefItemAndIndexByTitle(
  campaignBriefItems: CampaignPaidGigBriefItem[],
  title: string
) {
  const briefItemIndex = campaignBriefItems.findIndex(
    (item) => item.title?.toLowerCase() === title.toLowerCase()
  );
  return {
    briefItem: campaignBriefItems[briefItemIndex],
    briefItemIndex,
  };
}

export default getBriefItemAndIndexByTitle;
