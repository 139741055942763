import React, {useRef} from 'react';
import {Icon} from '@lightricks/react-design-system';
import CollapsibleContainer from '@/components/collapsible-container';
import styles from './CollapsibleSection.module.scss';

type CollapsibleSectionProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  initialIsOpen?: boolean;
  hasErrors?: boolean;
};

function CollapsibleSection({
  title,
  subtitle,
  children,
  initialIsOpen,
  hasErrors,
}: CollapsibleSectionProps) {
  const childrenRef = useRef(null);

  const getHeaderLabel = (isOpen: boolean) => {
    if (subtitle && isOpen) {
      return (
        <div>
          <label>{title}</label>
          <div
            className={styles.subtitle}
            dangerouslySetInnerHTML={{__html: subtitle}}
          />
        </div>
      );
    }
    return <label>{title}</label>;
  };
  return (
    <CollapsibleContainer
      initialIsOpen={initialIsOpen}
      headerLabel={title}
      renderHeaderLabel={getHeaderLabel}
      measureHeightOnClick
      stickyHeaderOffset={-10}
      className={styles.container}
      headerClassName={styles.header}
      headerLabelContainerClassName={`${styles.sectionHeader} ${
        hasErrors ? styles.error : ''
      }`}
      customOpenIndicator={
        <Icon
          size="medium"
          appearance="neutral"
          name="Actions-Arrow-Down-Large"
        />
      }
      customClosedIndicator={
        <div style={{transform: 'rotate(-90deg)', transformOrigin: 'center'}}>
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Arrow-Down-Large"
          />
        </div>
      }
      childrenRef={childrenRef}
    >
      <div ref={childrenRef}>{children}</div>
    </CollapsibleContainer>
  );
}

export default CollapsibleSection;
