import {useEffect} from 'react';
import Pendo from '@/services/pendo/Pendo';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';

function usePendo() {
  const {data: dashboardUserData}: any = useDashboardUserQuery({});

  useEffect(() => {
    if (dashboardUserData) {
      Pendo.initialize(dashboardUserData, dashboardUserData.currentBrand);

      Pendo.identify(dashboardUserData, dashboardUserData.currentBrand);
    }
  }, [dashboardUserData]);
}

export default usePendo;
