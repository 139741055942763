import {ROUTES} from '@/config/routesConstants';

function getAnalyticsScreenName(pathname: string) {
  // reverse the order to make HOME route the last one to match
  const matchedRoute = Object.entries(ROUTES)
    .slice()
    .reverse()
    .find(([_, routePathname]) =>
      new RegExp(routePathname, 'i').test(pathname)
    );

  return matchedRoute ? matchedRoute[0] : pathname;
}

export default getAnalyticsScreenName;
