import {set} from 'lodash/fp';
import React, {useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import translate from '@/utils/translate';
import {
  BriefFieldManagerAttributes,
  ErrorMessages,
} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefFieldHelperPopper from '@/views/campaigns/brief/components/brief-field-helper-popper';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {SaveCampaign} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY} from '@/views/campaigns/brief/constants';
import TagSelector from '@/components/tag-selector';
import styles from './CaptionGuidelines.module.scss';

type CaptionGuidelineKind = 'caption' | 'mention' | 'hashtag';

export type CaptionGuideline = {
  id: string;
  kind: CaptionGuidelineKind;
  content: string;
};

type CaptionGuidelinesProps = {
  testID?: string;
  briefFieldManager: BriefFieldManagerAttributes;
  swapMatchKeywords?: string[];
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  errors?: ErrorMessages;
  captionGuidelines: CaptionGuideline[];
  editable?: boolean;
  saveCampaign?: SaveCampaign;
  campaignId: string;
  viewPresentationId?: string;
};

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.deliverables.caption-guidelines';

const GUIDELINE_KINDS: Record<string, CaptionGuidelineKind> = {
  CAPTION: 'caption',
  MENTION: 'mention',
  HASHTAG: 'hashtag',
};

const GUIDELINE_OPTIONS = [
  {id: GUIDELINE_KINDS.CAPTION, labelLocaleKey: 'options.caption'},
  {id: GUIDELINE_KINDS.MENTION, labelLocaleKey: 'options.mention'},
  {id: GUIDELINE_KINDS.HASHTAG, labelLocaleKey: 'options.hashtag'},
];

function CaptionGuidelines({
  briefFieldManager,
  swapMatchKeywords,
  updateBriefForm,
  editable,
  errors,
  testID,
  captionGuidelines,
  saveCampaign,
  campaignId,
  viewPresentationId,
}: CaptionGuidelinesProps) {
  const swapsHashtag = swapMatchKeywords?.[0];

  const [newGuideline, setNewGuideline] = useState<CaptionGuideline | null>(
    null
  );
  const [isTypingNewGuideline, setIsTypingNewGuideline] = useState(false);

  const handleChangeSwapsHashtagRef = useRef<any>(null);
  handleChangeSwapsHashtagRef.current = (value: string) => {
    updateBriefForm?.(
      'swapMatchKeywords',
      [getCleanValue(value, 'hashtag')],
      'swapMatchKeywords'
    );
  };

  const getStartIcon = (kind: CaptionGuidelineKind) => {
    let startIcon = null;
    if (kind === GUIDELINE_KINDS.HASHTAG) {
      startIcon = <span className={styles.startIcon}>#</span>;
    } else if (kind === GUIDELINE_KINDS.MENTION) {
      startIcon = <span className={styles.startIcon}>@</span>;
    }
    return startIcon;
  };

  const getCleanValue = (value: string, kind: CaptionGuidelineKind) => {
    let cleanValue = value;
    if ([GUIDELINE_KINDS.HASHTAG, GUIDELINE_KINDS.MENTION].includes(kind)) {
      cleanValue = value.replace(/[\s@#,]/g, '');
    }
    return cleanValue;
  };

  const handleTagSelection = (selectedValueId: string | string[]) => {
    if (isTypingNewGuideline || !newGuideline) {
      setNewGuideline({
        id: uuid(),
        content: '',
        kind: selectedValueId as CaptionGuidelineKind,
      });
      setIsTypingNewGuideline(false);
      updateBriefForm?.(
        'campaignBriefCaptionGuidelines',
        captionGuidelines,
        'campaignBriefCaptionGuidelines'
      );
    }
  };

  const handleRemoveExistingGuideline = (index: number) => {
    updateBriefForm?.(
      'campaignBriefCaptionGuidelines',
      captionGuidelines.filter((_, gIndex) => gIndex !== index),
      'campaignBriefCaptionGuidelines',
      IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
    );
  };

  const handleChangeExistingGuideline = (index: number, newContent: string) => {
    const updatedGuidelines: CaptionGuideline[] = set(
      `${index}.content`,
      newContent,
      captionGuidelines
    );
    updateBriefForm?.(
      'campaignBriefCaptionGuidelines',
      updatedGuidelines,
      'campaignBriefCaptionGuidelines'
    );
  };

  const handleBlurExistingGuideline = (index: number, newContent: string) => {
    if (newContent === '') {
      handleRemoveExistingGuideline(index);
    } else {
      saveCampaign?.({
        campaignId,
        fieldName:
          briefFieldManager.fields.campaignBriefCaptionGuidelines?.agentKey,
        fieldValue: briefFieldManager.fields.captionGuidelines?.value,
      });
    }
  };

  const handleChangeNewGuideline = (newBody: string) => {
    setNewGuideline({...newGuideline!, content: newBody});
    setIsTypingNewGuideline(newBody !== '');
  };

  const handleBlurNewGuideline = (newBody: string) => {
    if (newBody !== '') {
      updateBriefForm?.(
        'campaignBriefCaptionGuidelines',
        [...captionGuidelines, {...newGuideline!, content: newBody}],
        'campaignBriefCaptionGuidelines',
        IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
      );
      setNewGuideline(null);
    }
  };

  const renderSwapsRequiredHashtag = () => {
    const kind = GUIDELINE_KINDS.HASHTAG;
    return (
      <ValidatedField errors={errors?.swapMatchKeywords}>
        <BriefFieldHelperPopper
          fieldName={briefFieldManager.fields.swapMatchKeywords?.agentKey}
          fieldValue={swapsHashtag || ''}
          onChange={handleChangeSwapsHashtagRef.current}
          saveCampaign={saveCampaign}
          disabled={!editable}
          offsetY={0}
          campaignId={campaignId}
          viewPresentationId={viewPresentationId}
        >
          {(childrenProps) => (
            <BriefFieldInput
              {...childrenProps}
              key="swapsHashtag"
              testID={
                CAMPAIGN_BRIEF_TEST_IDS.DELIVERABLES__CAMPAIGN_HASHTAG_INPUT
              }
              disabled={!editable}
              startIcon={getStartIcon(kind)}
              value={swapsHashtag}
              placeholder={kind}
              hideRemoveIcon
              onInputChange={handleChangeSwapsHashtagRef.current}
              hasErrors={!!errors?.swapMatchKeywords}
              inputProps={{value: swapsHashtag || ''}}
              onBlur={() =>
                saveCampaign?.({
                  campaignId,
                  fieldName:
                    briefFieldManager.fields.swapMatchKeywords?.agentKey,
                  fieldValue: swapsHashtag,
                })
              }
            />
          )}
        </BriefFieldHelperPopper>
      </ValidatedField>
    );
  };

  const renderExistingCaptionGuidelines = () => {
    return captionGuidelines.map((guideline, index) => {
      return (
        <BriefFieldInput
          key={guideline.id}
          testID={`${testID}--caption-guideline`}
          disabled={!editable}
          startIcon={getStartIcon(guideline.kind)}
          value={guideline.content}
          placeholder={guideline.kind}
          onBlur={({target: {value: newBody}}) =>
            handleBlurExistingGuideline(index, newBody)
          }
          onInputChange={(value) =>
            handleChangeExistingGuideline(
              index,
              getCleanValue(value, guideline.kind)
            )
          }
          inputProps={{value: guideline.content || ''}}
          onRemove={() => handleRemoveExistingGuideline(index)}
        />
      );
    });
  };

  const renderNewCaptionGuideline = () => {
    if (!newGuideline) {
      return null;
    }
    return (
      <BriefFieldInput
        key={`new-${newGuideline.kind}-guideline`}
        disabled={!editable}
        startIcon={getStartIcon(newGuideline.kind)}
        placeholder={newGuideline.kind}
        autoFocus
        onBlur={({target: {value: newBody}}) => handleBlurNewGuideline(newBody)}
        onRemove={() => setNewGuideline(null)}
        onInputChange={(value) =>
          handleChangeNewGuideline(getCleanValue(value, newGuideline.kind))
        }
        inputProps={{value: newGuideline.content || ''}}
        testID={`${testID}--new-guideline`}
      />
    );
  };

  return (
    <div className={styles.container} data-testid={testID}>
      <span className={styles.title}>
        {translate(`${TRANSLATION_PREFIX}.title`)}
      </span>
      <div className={styles.guidelinesList}>
        {renderSwapsRequiredHashtag()}
        {renderExistingCaptionGuidelines()}
        {renderNewCaptionGuideline()}
      </div>
      <TagSelector
        disabled={!editable || (newGuideline !== null && !isTypingNewGuideline)}
        addChipLabel={translate(`${TRANSLATION_PREFIX}.add-button`)}
        selectedValueIds={[]}
        options={GUIDELINE_OPTIONS.map((option) => ({
          ...option,
          label: translate(`${TRANSLATION_PREFIX}.${option.labelLocaleKey}`),
        }))}
        onChange={handleTagSelection}
        hideSelectedOptions
        testID={`${testID}--add-button`}
      />
    </div>
  );
}

export default CaptionGuidelines;
