import dayjs from 'dayjs';
import {YEAR_MONTH_FORMAT} from '@/config/timeFormats';
import {Post} from '@/types/post';

export interface DataByMonth<T> {
  month: string;
  data: T;
}

function defaultDataByMonth<T>(
  filteredPosts: Post[],
  monthlyPostsDataExtractor: (posts: Post[]) => T
): DataByMonth<T>[] {
  if (!filteredPosts || !filteredPosts.length) {
    return [];
  }
  const results: DataByMonth<T>[] = [];
  const postsByMonth: {[key: string]: Post[]} = {};

  filteredPosts.forEach((post) => {
    if (!postsByMonth[post.createdAtMonth]) {
      postsByMonth[post.createdAtMonth] = [];
    }

    postsByMonth[post.createdAtMonth].push(post);
  });

  const oldestPost = filteredPosts[0];

  let monthIterator = dayjs(oldestPost.createdAt).startOf('month');

  while (monthIterator <= dayjs().startOf('month')) {
    const monthFormatted = monthIterator.format(YEAR_MONTH_FORMAT);
    const postsForMonth = postsByMonth[monthFormatted];

    results.push({
      month: monthFormatted,
      data: monthlyPostsDataExtractor(postsForMonth),
    });

    monthIterator = monthIterator.add(1, 'M');
  }

  return results;
}

export default defaultDataByMonth;
