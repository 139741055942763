import React, {useState, useMemo, useEffect} from 'react';
import {InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import IconComponent from '@/components/select/icon-component';
import {Option, SelectVariant} from '../SelectProps';
import styles from './SingleSelect.module.scss';

const TRANSLATION_PREFIX = 'components.select';

const DEFAULT_VARIANT_PROPS = (onClear?: () => void) => ({
  inputProps: {
    'aria-label': translate(`${TRANSLATION_PREFIX}.select-one`),
  },
  displayEmpty: true,
  endAdornment: onClear ? (
    <div className={styles.clearIcon} onClick={onClear}>
      <Icon
        size="medium"
        appearance="neutral"
        name="Actions-Close-Small"
        color={designSystemToken('semantic.fg.secondary')}
      />
    </div>
  ) : null,
  IconComponent: ({className}: {className: string}) => (
    <IconComponent
      className={className}
      containerStyle={styles.iconComponent}
    />
  ),
  MenuProps: {
    PaperProps: {
      sx: {
        marginTop: '8px',
        maxHeight: '40vh',
        borderRadius: '24px',
      },
    },
  },
});

export type SingleSelectProps = {
  label?: string;
  labelId?: string;
  options: Option[];
  optionLabelKey?: string;
  variant?: SelectVariant;
  onChange?: (value: string) => void;
  selectedValueIds?: string[];
  renderSelectedLabel?: (options: Option[]) => string | React.ReactNode;
  immediateOnChange?: boolean;
  onClose?: () => void;
  onClear?: () => void;
  disabled?: boolean;
  containerClassName?: string;
  allowReSelect?: boolean;
};

function inputLabel(label: string, labelId: string, variant: SelectVariant) {
  if (!label) return null;

  const variantLabelProps = {
    default: {},
    onboarding: {
      shrink: false,
    },
  };

  return (
    <InputLabel
      id={label ? labelId : undefined}
      className={`${styles[variant]} ${styles.label}`}
      {...variantLabelProps[variant]}
    >
      {label}
    </InputLabel>
  );
}

function SingleSelect(props: SingleSelectProps) {
  const {
    label = '',
    labelId = '',
    options,
    optionLabelKey = 'label',
    onChange,
    variant = 'default',
    selectedValueIds = [''],
    renderSelectedLabel,
    immediateOnChange,
    onClose,
    onClear,
    disabled,
    containerClassName,
    allowReSelect = false,
  } = props;
  const [selectedOption, setSelectedOption] = useState<string>(
    selectedValueIds?.[0] || ''
  );

  useEffect(() => {
    if (immediateOnChange) {
      setSelectedOption(selectedValueIds?.[0] || '');
    }
  }, [selectedValueIds, immediateOnChange]);

  const variantProps = useMemo(
    () => ({
      default: DEFAULT_VARIANT_PROPS(
        selectedOption && !disabled ? onClear : undefined
      ),
      onboarding: DEFAULT_VARIANT_PROPS(
        selectedOption && !disabled ? onClear : undefined
      ),
    }),
    [selectedOption]
  );

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: {value},
    } = event;

    setSelectedOption(value as string);
    onChange?.(value as string);
  };

  const handleItemClick = (optionId: string) => {
    if (!allowReSelect) return;
    if (optionId === selectedOption) {
      onChange?.(optionId);
    }
  };

  const handleRender = (selected: string) => {
    if (!selected) {
      if (!renderSelectedLabel) {
        return (
          <span className={`${styles[variant]} ${styles.placeholder}`}>
            {translate(`${TRANSLATION_PREFIX}.select-one`)}
          </span>
        );
      }
      return renderSelectedLabel([]);
    }

    const activeSelection = options.filter(
      (option) => option.id === selected
    )[0];

    if (renderSelectedLabel && activeSelection) {
      return renderSelectedLabel([activeSelection]);
    }

    return options.filter((option) => option.id === selected)[0]?.[
      optionLabelKey
    ];
  };

  return (
    <>
      {inputLabel(label, labelId, variant)}
      <Select
        className={`${styles[variant]} ${styles.container} ${
          containerClassName || ''
        }`}
        labelId={label ? labelId : undefined}
        label={label}
        value={selectedOption}
        onChange={handleChange}
        renderValue={handleRender}
        onClose={onClose}
        disabled={disabled}
        {...variantProps[variant]}
      >
        {options.map((option) => (
          <MenuItem
            key={`menu-item-${option.id}`}
            value={option.id}
            focusVisibleClassName={styles.optionFocused}
            className={`${styles[variant]} ${styles.option}`}
            onClick={() => handleItemClick(option.id)}
          >
            {option[optionLabelKey]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default SingleSelect;
