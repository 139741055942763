import {useCallback} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';

type BrandSafetyCreatorActionEventParams = {
  flow_id?: string;
  screen_presentation_id?: string;
  brand_safety_screen_name?: string;
  tab?: string;
  creator_id: string;
  action_name: string;
  action_value: string;
};

function useTrackBrandSafetyCreatorActionEvent(flowName?: string) {
  const action = useCallback(
    ({
      flow_id = '',
      screen_presentation_id = '',
      brand_safety_screen_name = '',
      tab = '',
      creator_id,
      action_name = '',
      action_value = '',
    }: BrandSafetyCreatorActionEventParams) => {
      try {
        const flow = flowName ? AnalyticsService.getFlow(flowName) : undefined;
        AnalyticsService.dispatchEvent(eventNames.BRAND_SAFETY_CREATOR_ACTION, {
          flow_id: flow?.flow_id || flow_id,
          screen_presentation_id:
            flow?.screen_presentation_id ||
            screen_presentation_id ||
            AnalyticsService.getActiveScreenPresented().screen_presentation_id,
          brand_safety_screen_name,
          tab,
          creator_id,
          action_name,
          action_value,
        });
      } catch (error) {
        console.error(
          'Error tracking brand safety creator action event',
          error
        );
      }
    },
    []
  );

  return {action};
}

export default useTrackBrandSafetyCreatorActionEvent;
