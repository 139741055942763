import capsuleAiFetchers from '@/api/fetchers/capsuleAi';
import useDataQuery from '@/hooks/use-data-query';

function usePresetQuery() {
  const response: any = useDataQuery({
    queryKey: ['presets'],
    queryFn: capsuleAiFetchers.presets,
  });

  return {...response};
}

export default usePresetQuery;
