import DOMPurify from 'dompurify';
import React from 'react';
import {Headline, Label} from '@lightricks/react-design-system';
import {CreatorProfileApplicationAnswer} from '@/types/creatorProfile';
import styles from './ApplicationQuestions.module.scss';

function ApplicationQuestions({
  title,
  items,
}: {
  title: string;
  items: CreatorProfileApplicationAnswer[];
}) {
  return (
    <div className={styles.applicationAnswersContainer}>
      <Headline size="sm">{title}</Headline>
      <div className={styles.applicationQuestions}>
        {items.map((item) => (
          <div key={item.question} className={styles.applicationQuestion}>
            <Headline size="xs">{item.question}</Headline>
            <Label
              className={styles.answer}
              size="md"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.answer),
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ApplicationQuestions;
