import axiosWithAuth from '@/utils/axiosWithAuth';
import {HTTP_STATUS} from '@/config/constants';

const CREATORS_PATH = 'creators';

const creatorsUpdaters = {
  startConversation: async (data: {
    brandId: string;
    creatorId: string;
    initialMessage: string;
  }) => {
    const result = await axiosWithAuth.post(`${CREATORS_PATH}/conversation`, {
      brand_id: data.brandId,
      creator_id: data.creatorId,
      initial_message: data.initialMessage,
    });
    return (
      result.status >= HTTP_STATUS.OK && result.status < HTTP_STATUS.BAD_REQUEST
    );
  },
  addToLists: async (data: {
    creatorGroupId: string;
    creatorIds: string[];
    labelIds: string[];
  }) => {
    const result = await axiosWithAuth.put(`${CREATORS_PATH}/creator_list`, {
      creator_group_id: data.creatorGroupId,
      creator_ids: data.creatorIds,
      label_ids: data.labelIds,
    });
    return (
      result.status >= HTTP_STATUS.OK && result.status < HTTP_STATUS.BAD_REQUEST
    );
  },
  addToCampaign: async (data: {
    creatorGroupId: string;
    creatorIds: string[];
    campaignId: string;
  }) => {
    const result = await axiosWithAuth.put(
      `${CREATORS_PATH}/campaign_invitation`,
      {
        creator_group_id: data.creatorGroupId,
        creator_ids: data.creatorIds,
        campaign_id: data.campaignId,
      }
    );
    return (
      result.status >= HTTP_STATUS.OK && result.status < HTTP_STATUS.BAD_REQUEST
    );
  },
};

export default creatorsUpdaters;
