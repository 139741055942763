import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import {SHORT_MONTH_FORMAT} from '@/config/timeFormats';

dayjs.extend(localizedFormat);

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  minDate: Date;
  maxDate: Date;
}

const generateYearsFromRange = (minDate: Date, maxDate: Date) => {
  const minYear = dayjs(minDate).year();
  const maxYear = dayjs(maxDate).year();
  return Array.from({length: maxYear - minYear + 1}, (_, i) => minYear + i);
};

function Header({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  minDate,
  maxDate,
}: HeaderProps) {
  const MONTHS = [...Array(12).keys()].map((d) =>
    dayjs().month(d).format(SHORT_MONTH_FORMAT)
  );

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    const month = parseInt(event.target.value as string, 10);
    setDate(dayjs(date).month(month).toDate());
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const year = parseInt(event.target.value as string, 10);
    setDate(dayjs(date).year(year).toDate());
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{padding: '5px'}}>
        <IconButton
          sx={{
            'padding': '10px',
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Arrow-Back-Small"
            color={
              prevDisabled
                ? designSystemToken('semantic.fg.disabled')
                : undefined
            }
          />
        </IconButton>
      </Grid>
      <Grid item>
        <FormControl variant="standard">
          <Select
            value={dayjs(date).month()}
            onChange={handleMonthChange}
            MenuProps={{disablePortal: true}}
            disableUnderline
          >
            {MONTHS.map((month, idx) => (
              <MenuItem
                key={month}
                value={idx}
                disabled={
                  dayjs(`${dayjs(date).year()}-${idx + 1}`).isBefore(
                    dayjs(minDate).startOf('month')
                  ) ||
                  dayjs(`${dayjs(date).year()}-${idx + 1}`).isAfter(
                    dayjs(maxDate).endOf('month')
                  )
                }
              >
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl variant="standard">
          <Select
            value={dayjs(date).year()}
            onChange={handleYearChange}
            MenuProps={{disablePortal: true}}
            disableUnderline
          >
            {generateYearsFromRange(minDate, maxDate).map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{padding: '5px'}}>
        <IconButton
          sx={{
            'padding': '10px',
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Arrow-Forward-Small"
            color={
              nextDisabled
                ? designSystemToken('semantic.fg.disabled')
                : undefined
            }
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default Header;
