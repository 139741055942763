import {TooltipItem} from 'chart.js';
import dayjs from 'dayjs';
import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {Post} from '@/types/post';
import PoppaysLoader from '@/components/PoppaysLoader';
import ChartLayout from '@/components/chart-layout';
import BarChart from '@/components/charts/bar-chart';
import Conditional from '@/components/conditional';
import {
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';
import {SocialHealthChartProps} from '../../socialHealthChart';

const TRANSLATION_PREFIX =
  'components.creator-profile.identity-social-health.charts.paid-vs-organic';

function getPostsPerMonth(posts: Post[]) {
  const seenMonths: string[] = [];
  const organicPostsByMonth: {[key: string]: number} = {};
  const paidPostsByMonth: {[key: string]: number} = {};

  posts.forEach((post) => {
    if (!organicPostsByMonth[post.createdAtMonth]) {
      organicPostsByMonth[post.createdAtMonth] = 0;
    }

    if (!paidPostsByMonth[post.createdAtMonth]) {
      paidPostsByMonth[post.createdAtMonth] = 0;
    }

    if (post.isPaid) {
      paidPostsByMonth[post.createdAtMonth] += 1;
    } else {
      organicPostsByMonth[post.createdAtMonth] += 1;
    }

    seenMonths.push(post.createdAtMonth);
  });

  return {
    organic: Object.values(organicPostsByMonth).reverse(),
    paid: Object.values(paidPostsByMonth).reverse(),
    months: [...new Set(seenMonths).values()].reverse(),
  };
}

function PaidVsOrganicChart(props: SocialHealthChartProps) {
  const {posts, isLoadingPosts} = props;

  const postsPerMonth = useMemo(() => getPostsPerMonth(posts), [posts]);

  const {months} = postsPerMonth;

  const chart = (
    <Conditional
      condition={!isLoadingPosts}
      fallback={<PoppaysLoader absolute />}
    >
      <BarChart
        datasets={[
          {
            label: translate(`${TRANSLATION_PREFIX}.organic`),
            backgroundColor: LEGACY_COLORS.YELLOW,
            data: Object.values(postsPerMonth.organic),
          },
          {
            label: translate(`${TRANSLATION_PREFIX}.sponsored`),
            backgroundColor: LEGACY_COLORS.BLUE,
            data: Object.values(postsPerMonth.paid),
          },
        ]}
        additionalOptions={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
          },
          scales: {
            x: getDefaultScaleX(months, SHORT_MONTH_YEAR_FORMAT),
            y: getDefaultScaleY(
              translate(`${TRANSLATION_PREFIX}.posts`),
              null,
              (label) => label as string,
              true
            ),
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              intersect: false,
              callbacks: {
                label(tooltipItem: TooltipItem<'bar'>) {
                  const {dataset} = tooltipItem;
                  const totalAtIndex =
                    postsPerMonth.organic[tooltipItem.dataIndex] +
                    postsPerMonth.paid[tooltipItem.dataIndex];

                  const value = (tooltipItem.raw || 0) as number;
                  let label = dataset.label || '';
                  if (label) {
                    label += ': ';
                  }

                  label += Math.round(value * 100) / 100;

                  label += ' (';
                  const percent = value / (totalAtIndex || 1);
                  label += Math.round(percent * 100);
                  label += '%)';

                  return label;
                },
                title([tooltipItem]) {
                  return dayjs(months[tooltipItem.dataIndex]).format(
                    SHORT_MONTH_YEAR_FORMAT
                  );
                },
              },
            },
          },
        }}
        labels={months}
      />
    </Conditional>
  );

  return (
    <ChartLayout
      title={translate(`${TRANSLATION_PREFIX}.paid-vs-organic`)}
      chart={chart}
      showEmptyState={posts.length === 0 && !isLoadingPosts}
    />
  );
}

export default PaidVsOrganicChart;
