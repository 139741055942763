import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import {CreatorCardProps} from '@/components/creator-card/CreatorCard';
import CreatorOverflowMenuWrapper from '@/components/creator-card/CreatorOverflowMenuWrapper';
import styles from './CreatorOverflowMenuButton.module.scss';

type CreatorOverflowMenuButtonProps = {
  isEnabled?: boolean;
  creator: CreatorCardProps;
  classes?: {container?: string; button?: string};
  creatorCardOverflowMenuItemsCallBack: (
    creator: CreatorCardProps
  ) => Promise<CreatorMenuItemProps[]>;
};

export type CreatorMenuItemProps = {
  iconName: IconName;
  title: string;
  onClick: () => void;
};

function CreatorOverflowMenuButton({
  classes = {},
  isEnabled,
  creator,
  creatorCardOverflowMenuItemsCallBack,
}: CreatorOverflowMenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <div className={classes.container}>
      {isEnabled && (
        <Button
          className={`${styles.overflowMenuButton} ${classes.button} ${
            menuOpen ? styles.menuOpen : ''
          }`}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
          icon={<Icon name="Actions-More" appearance="inverse" size="medium" />}
          appearance="overlay"
          size="medium"
          mode="filled"
        />
      )}
      {menuOpen && (
        <CreatorOverflowMenuWrapper
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuOpen={menuOpen}
          creator={creator}
          creatorCardOverflowMenuItemsCallBack={
            creatorCardOverflowMenuItemsCallBack
          }
        />
      )}
    </div>
  );
}

export default CreatorOverflowMenuButton;
