import React from 'react';
import {ButtonProps} from '@/components/button-group/ButtonGroup';
import styles from './OptionButton.module.scss';

type OptionButtonProps = {
  testID?: string;
  button?: ButtonProps;
  children?: React.ReactNode;
  className?: string;
  onClick: () => void;
  isActive: boolean;
  disabled?: boolean;
};
function OptionButton(props: OptionButtonProps) {
  const {
    testID = 'option-button',
    button,
    children,
    className,
    onClick,
    isActive,
    disabled,
  } = props;
  const getButtonLabel = () => {
    return children || <label className={styles.label}>{button?.label}</label>;
  };

  return (
    <button
      data-testid={testID}
      type="button"
      className={`${styles.container} ${className || ''} ${
        isActive ? styles.active : ''
      }`}
      onClick={onClick}
      disabled={button?.disabled || disabled}
    >
      {getButtonLabel()}
    </button>
  );
}

export default OptionButton;
