import React from 'react';
import {AI_STUDIO_ROUTES} from '@/routes/ai-content-generator/AiContentGenerator';
import LibraryButton from '@/views/ai-content-generator/components/library-button';
import BrandHeaderChild from './child-components';

export default function useChildComponents() {
  return [
    {matcher: '/campaigns', component: BrandHeaderChild.Campaigns},
    {matcher: '/stats', component: BrandHeaderChild.Stats},
    {
      matcher: '/brief-ai',
      component: () => <BrandHeaderChild.Generic />,
      hideBrandElement: true,
    },
    {
      matcher: `ai-studio/generated-content`,
      hideBrandElement: true,
      secondaryComponent: LibraryButton,
      logoLinkOverride: AI_STUDIO_ROUTES.BASE,
    },
  ];
}
