import React from 'react';
import {useSearchParams} from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Button} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Modal from '@/components/modal';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useParams from '@/hooks/use-params';
import useToken from '@/hooks/use-token';
import styles from './ModalLeverLimit.module.scss';

const TRANSLATION_PREFIX = 'components.modal-lever-limit';

export type ModalLeverLimitProps = {
  testID?: string;
};

function ModalLeverLimit(props: ModalLeverLimitProps) {
  const {testID = 'modal-lever-limit'} = props;
  const navigation = useNavigation();
  const {showLeverLimitModal} = useParams();
  const brandId = useBrandId();
  const {isAuthenticated} = useToken(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  const onClickUpgrade = () => {
    navigation.navigate(`/upgrade?brandId=${brandId}`);
  };

  const onClose = () => {
    searchParams.delete('showLeverLimitModal');
    setSearchParams(searchParams);
  };

  if (!dashboardUser?.loaded) return null;

  const open = showLeverLimitModal === 'true';

  return (
    <Modal
      testID={testID}
      showCloseButton
      open={open}
      onClose={onClose}
      size="tiny"
    >
      <div className={styles.container}>
        <ErrorOutlineIcon />
        <h1>{translate(`${TRANSLATION_PREFIX}.h1`)}</h1>
        <h2>{translate(`${TRANSLATION_PREFIX}.h2`)}</h2>
        {dashboardUser.hasPaymentManagerRole ? (
          <SubscribeButton onClick={onClickUpgrade} />
        ) : (
          <ContractOrgOwner />
        )}
      </div>
    </Modal>
  );
}

function SubscribeButton({onClick}: {onClick: () => void}) {
  return (
    <Button
      testID="modal-lever-limit__button"
      appearance="neutral"
      size="medium"
      mode="filled"
      onClick={onClick}
    >
      {translate(`${TRANSLATION_PREFIX}.button-text`)}
    </Button>
  );
}

function ContractOrgOwner() {
  return (
    <h3 data-testid="modal-lever-limit__not-org-owner">
      {translate(`${TRANSLATION_PREFIX}.not-org-owner`)}
    </h3>
  );
}

export default ModalLeverLimit;
