import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import {negate} from 'lodash';
import queryClient from '@/utils/reactQueryClient';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useBrandId from '@/hooks/use-brand-id';

function useDeleteLabel() {
  const brandId = useBrandId();
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';

  return useMutation({
    mutationKey: ['creatorGroupsLabels', 'delete'],
    mutationFn: creatorGroupUpdaters.deleteLabel,
    onMutate: async (targetLabelId) => {
      const previousData = await queryClient.getQueryData([
        'creatorGroupsLabels',
        creatorGroupId,
      ]);

      const isTargetLabel = (_label: CreatorGroupLabel) =>
        _label.id === targetLabelId;

      queryClient.setQueryData(
        ['creatorGroupsLabels', creatorGroupId],
        (old: any) => {
          if (!old.data.some(isTargetLabel)) {
            throw new Error('Label not found');
          }

          return {
            ...old,
            data: old.data.filter(negate(isTargetLabel)),
          };
        }
      );

      return {previousData};
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData(
        ['creatorGroupsLabels', creatorGroupId],
        context
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useDeleteLabel;
