import React, {useCallback, useRef} from 'react';
import {Asset} from '@/types/asset';
import useContentLibraryVirtualizedGridProps from '@/views/content-library/hooks/use-content-library-virtualized-grid-props/useContentLibraryVirtualizedGridProps';
import PoppaysLoader from '@/components/PoppaysLoader';
import AssetCard from '@/components/asset-card';
import Page from '@/components/page';
import VirtualizedGrid from '@/components/virtualized-grid';
import useAssetsQuery from '@/hooks/queries/use-assets-query';
import useBrandId from '@/hooks/use-brand-id';
import useEmberIframeStyleStore from '@/stores/emberIframeStyleStore';
import styles from './ContentLibrary.module.scss';

const GRID_SCROLL_TOP_KEY = 'contentLibraryGridScrollTop';

function ContentLibrary() {
  const brandId = useBrandId();
  const {data, fetchNextPage, isFetchingNextPage, isLoading, isFetching} =
    useAssetsQuery({brandId});
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridScrollRef = useRef<HTMLDivElement>(null);
  const {sidebarWidth} = useEmberIframeStyleStore();
  const renderAssetCard = useCallback((asset: Asset) => {
    return (
      <AssetCard
        key={asset.id}
        id={asset.id}
        downloadUrl={asset.downloadUrl}
        isAiContent={asset.isAiContent}
        kind={asset.kind}
        thumbnailUrl={asset.thumbnailUrl}
        url={asset.url}
        profile={asset.profile}
        campaign={asset.campaign}
      />
    );
  }, []);
  const contentLibraryVirtualizedGridProps =
    useContentLibraryVirtualizedGridProps(gridContainerRef, [sidebarWidth]);

  return (
    <Page id="content-library" className={styles.container}>
      {isLoading ? <PoppaysLoader className={styles.loader} /> : null}
      <div ref={gridContainerRef}>
        <VirtualizedGrid
          {...contentLibraryVirtualizedGridProps}
          containerClassName={`${styles.gridContainer} ${
            isLoading || isFetching ? styles.loading : ``
          }`}
          data={data}
          onEndReached={fetchNextPage}
          isInitialLoading={isLoading}
          showLoader={isFetchingNextPage}
          skeletonsAmount={contentLibraryVirtualizedGridProps.columns * 2}
          renderItemSkeleton={(index: number) => <div key={index} />}
          gridScrollRef={gridScrollRef}
          gridScrollTopKey={GRID_SCROLL_TOP_KEY}
          renderItem={renderAssetCard}
        />
      </div>
    </Page>
  );
}

export default ContentLibrary;
