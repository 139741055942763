import React from 'react';

interface ProviderProps {
  children: React.ReactNode;
}
interface ContextProviderComposerProps {
  contexts: React.ComponentType<ProviderProps>[];
  children: React.ReactNode;
}

const ContextProviderComposer: React.FC<ContextProviderComposerProps> = ({
  contexts,
  children,
}) => {
  return contexts.reduceRight((kids, ParentProvider) => {
    return <ParentProvider>{kids}</ParentProvider>;
  }, children);
};

export default ContextProviderComposer;
