import type {QueryKey} from '@tanstack/query-core';
import {useQuery} from '@tanstack/react-query';

/**
 * flattens the data and returns additional meta data that returns from the original response
 * @param options
 */
function useDataQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: object) {
  const response = useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    structuralSharing: false, // prevents circular dependencies => see https://tanstack.com/query/v4/docs/react/reference/useQuery
  });

  const {data} = response;

  return {...response, ...(data || {})};
}

export default useDataQuery;
