import React from 'react';
import AiStudioMobileNotSupportedPlaceholder from '@/views/ai-content-generator/components/ai-studio-mobile-not-supported-placeholder';
import WelcomeFrame from '@/views/ai-content-generator/components/welcome-frame';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './Welcome.module.scss';

function Welcome() {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <AiStudioMobileNotSupportedPlaceholder />;
  }

  return (
    <div className={styles.container}>
      <WelcomeFrame />
    </div>
  );
}

export default Welcome;
