import {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {tokenSerializer} from '@/utils/token';

export function addGeneralRequestInterceptor(
  instance: AxiosInstance,
  headersConfiguration: (headers: AxiosHeaders) => Promise<AxiosHeaders> = (
    headers
  ) => Promise.resolve(headers)
) {
  instance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const headers = await headersConfiguration(
        AxiosHeaders.from({
          ...(await tokenSerializer()),
          ...(config.headers || {}),
        })
      );

      return {
        ...config,
        headers,
      };
    },
    (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error);
    }
  );
}

export function addGeneralResponseInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response) => {
      return {...response, data: mapKeysToCase(response.data)};
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
