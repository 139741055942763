import {Lever, LeverVariant} from '@/types/lever';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';

function useSubscriptionLevers() {
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});

  if (!subscription?.loaded) return null;

  const byType = (type: LeverVariant) => (lever: Lever) => lever.type === type;

  const isMaxed = (type: LeverVariant): boolean => {
    const foundLever = subscription.levers.find(byType(type));
    if (!foundLever) {
      console.error(`Lever of type ${type} not found`);
      return false;
    }

    return foundLever.usedCount >= foundLever.thresholdCount;
  };

  const has = (type: LeverVariant) => subscription.levers.some(byType(type));

  return {has, isMaxed};
}

export default useSubscriptionLevers;
