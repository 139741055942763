import {CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import getTermsAndConditionsSectionBriefItems from '@/views/campaigns/brief/components/campaign-brief/section/terms-and-conditions/getTermsAndConditionsSectionBriefItems';
import BriefItem from './BriefItem';

class TermsAndConditionsBriefItem extends BriefItem {
  #brandName: string;

  #network: string;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(
      briefFieldManager,
      campaignData,
      key,
      index,
      id,
      title,
      contentHtml,
      highlighted,
      clientBriefTemplateId
    );
    this.#brandName = campaignData.brand?.name || '';
    this.#network = campaignData.network || '';
  }

  get toContentHtml() {
    return `
      <div>
           ${getTermsAndConditionsSectionBriefItems(
             this.#brandName,
             this.#network
           )
             .map(
               (briefItem) => `
                <div>
                    <h3>${briefItem.title}</h3>
                    <span>${briefItem.contentHtml}</span>
                </div>
            `
             )
             .join('')}
      </div>
    `;
  }
}

export default TermsAndConditionsBriefItem;
