import {CreatorGroup} from '@/types/campaign';
import brandFetchers from '@/api/fetchers/brand';
import useDataQuery from '@/hooks/use-data-query';

type UseBrandCreatorGroupsQueryParams = {
  brandId?: string;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
};

function useBrandCreatorGroupsQuery({
  brandId,
  refetchOnWindowFocus = true,
  enabled = true,
}: UseBrandCreatorGroupsQueryParams) {
  const response = useDataQuery({
    queryKey: ['brandCreatorGroups', brandId],
    queryFn: brandFetchers.brandCreatorGroups,
    enabled: enabled && !!brandId,
    meta: {brandId},
    refetchOnWindowFocus,
  });

  return {
    ...response,
    creatorGroups: (response.data || []) as CreatorGroup[],
  };
}

export default useBrandCreatorGroupsQuery;
