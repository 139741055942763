import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import MobileNotSupportedPlaceholder from '@/components/mobile-not-supported-placeholder';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';

function Brief() {
  const brandId = useBrandId();
  const {currentBrand} = useAgencyQuery({brandId});

  const [wizardFormData, setWizardFormData] = useState(null);

  const isMobile = useIsMobile();

  if (isMobile) {
    return <MobileNotSupportedPlaceholder />;
  }

  if (!currentBrand) {
    return null;
  }

  return (
    <div>
      <Outlet
        context={{wizardFormData, setWizardFormData, brand: currentBrand}}
      />
    </div>
  );
}

export default Brief;
