import axios, {AxiosHeaders} from 'axios';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import Fortress from '@/services/fortress/Fortress';
import {
  addGeneralRequestInterceptor,
  addGeneralResponseInterceptor,
} from '@/utils/axiosInterceptors';
import {getEnv} from '@/config/environment';

const instance = axios.create({
  baseURL: getEnv().VITE_API_HOST || 'http://api.test.popularpays.com:3000',
});

addGeneralResponseInterceptor(instance);

addGeneralRequestInterceptor(instance, async (headers: AxiosHeaders) => {
  const ltxAuthToken = await Fortress.getAccessToken();
  headers.set('Accept', 'application/json;version=5');
  if (ltxAuthToken) {
    headers.set('x-lightricks-auth-token', ltxAuthToken);
  }
  if (AnalyticsService.getProvider('delta')) {
    headers.set(
      'browser-delta-attributes-json',
      JSON.stringify(AnalyticsService.getBrowserBaseAttributes())
    );
  }
  return headers;
});

export default instance;
