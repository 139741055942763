import {snakeCase} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {getEnv} from '@/config/environment';
import useBrandId from '@/hooks/use-brand-id';
import useEmberModalOpener from '@/hooks/use-ember-modal-opener';
import useNavigation from '@/hooks/use-navigation';
import {useEmberPaidFeatureCheck} from '@/hooks/use-paid-feature-check';
import styles from './EmberIframe.module.scss';

type EmberIframeProps = {
  src?: string;
  iframeRef?: React.Ref<HTMLIFrameElement>;
  setIframeLoaded?: (loaded: boolean) => void;
};

const requestIds: {[key: string]: boolean} = {};

function useEmberUrlChange() {
  const navigation = useNavigation();
  const brandId = useBrandId();

  useEffect(() => {
    const addBrandIdToUriIfNotExists = (uri: string) => {
      let uriOutput = uri;
      if (brandId && !uri.includes('brandId')) {
        uriOutput += uri.includes('?') ? '&' : '?';
        uriOutput += `brandId=${brandId}`;
      }
      return uriOutput;
    };

    const removeChangeUrlListener = EmberIframe.listen('changeUrl', (uri) => {
      const finalUri = addBrandIdToUriIfNotExists(uri);
      // This only change the URL in the browser but doesn't trigger any component changes
      window.history.replaceState(null, '', finalUri);
      // If the uri is defined by React Route then we also want to navigate to there
      navigation.navigate(finalUri, {replace: true});
    });

    return removeChangeUrlListener;
  }, [brandId, navigation]);
}

function useEmberRequestDelegation() {
  useEffect(() => {
    const removeAjaxRequestListener = EmberIframe.listen(
      'ajaxRequest',
      async (data) => {
        const {url, type, options, headers, requestId} = JSON.parse(data);
        if (requestIds[requestId]) {
          return;
        }
        requestIds[requestId] = true;
        const requestURL =
          type === 'GET' && options?.data
            ? `${url}?${new URLSearchParams(options.data)}`
            : url;

        const response = await fetch(requestURL, {
          method: type,
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            ...headers,
          },
          body:
            type !== 'GET' && options?.data
              ? JSON.stringify(options.data)
              : undefined,
        });

        let responseData = null;
        const {status, headers: responseHeaders} = response;
        try {
          responseData = await response.json();
        } catch (e) {
          console.error('response.json() error:', {e});
        }

        EmberIframe.send(
          `ajaxResponse:${requestId}`,
          JSON.stringify({
            data: mapKeysToCase(responseData, snakeCase),
            status,
            headers: responseHeaders,
          })
        );
      }
    );
    return removeAjaxRequestListener;
  }, []);
}

function EmberIframe(props: EmberIframeProps) {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isEmberAppLoaded, setIsEmberAppLoaded] = useState(false);
  const navigation = useNavigation();
  const {src, iframeRef, setIframeLoaded} = props;

  useEffect(() => {
    const sendDeltaEventListener = EmberIframe.listen(
      'sendDeltaEvent',
      ({
        eventName,
        event,
        flowName,
      }: {
        eventName: string;
        event: any;
        flowName?: string;
      }) => {
        if (flowName) {
          const flow = AnalyticsService.getFlow(flowName);
          if (flow && flow.flow_id) {
            event.flow_id = flow.flow_id;
          }
        }
        AnalyticsService.dispatchEvent(eventName, event);
      }
    );

    return sendDeltaEventListener;
  }, []);

  useEffect(() => {
    const emberAppLoadedEventListener = EmberIframe.listen(
      'emberAppLoaded',
      () => {
        setIsEmberAppLoaded(true);
      }
    );

    return emberAppLoadedEventListener;
  }, []);

  useEffect(() => {
    const redirectToNewCampaignBriefListener = EmberIframe.listen(
      'redirectToNewCampaignBrief',
      (campaignId) => {
        navigation.navigate(`/new-campaigns/${campaignId}/brief/edit`);
      }
    );

    return redirectToNewCampaignBriefListener;
  }, []);

  useEmberUrlChange();
  useEmberRequestDelegation();
  useEmberPaidFeatureCheck();
  useEmberModalOpener();

  const location = useLocation();

  useEffect(() => {
    if (isIframeLoaded) {
      EmberIframe.send('changeUrl', location);
    }
  }, [location, isIframeLoaded]);

  const onLoad = () => {
    setIsIframeLoaded(true);
    setIframeLoaded?.(true);
  };

  const IframeSingleton = useMemo(
    () => (
      <iframe
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name={`ember-iframe${window.Cypress ? '-cypress' : ''}?parentHostname=${
          window.location.origin
        }`}
        id="ember-iframe"
        data-cy="ember-iframe"
        className={styles.iframe}
        onLoad={onLoad}
        src={
          src ||
          `${getEnv().VITE_DOMAIN_HOST}/ember${location.pathname}${
            location.search
          }`
        }
        title="Ember iFrame"
        ref={iframeRef}
      />
    ),
    []
  );

  return IframeSingleton;
}

const EMBER_URL = getEnv().VITE_DOMAIN_HOST_IFRAME;

EmberIframe.send = (subject: string, message: any) => {
  const iframe: HTMLIFrameElement | null =
    document.querySelector('#ember-iframe');
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage({subject, message}, EMBER_URL);
  } else {
    console.error('No ember iframe found.');
  }
};

EmberIframe.listen = (
  acceptedEventName: string,
  callback: (data: any) => void
) => {
  const onMessage = (e: MessageEvent) => {
    const eventName = e.data.subject;
    const eventData = e.data.message;

    if (e.origin === EMBER_URL && eventName === acceptedEventName) {
      callback(eventData);
    }
  };

  window.addEventListener('message', onMessage, false);
  return () => window.removeEventListener('message', onMessage);
};

export default EmberIframe;
