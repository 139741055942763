import React from 'react';
import {Route} from 'react-router-dom';
import {ROUTES} from '@/config/routesConstants';
import PublicProfile from '@/views/creators/public-profile';
import SharedList from '@/views/creators/shared-list';
import NotFound from '@/views/not-found';
import UnderMaintenance from '@/views/under-maintenance';

function PublicAccessRoutes() {
  return [
    <Route
      key="not-found-route"
      path={ROUTES.NOT_FOUND}
      element={<NotFound />}
    />,
    <Route
      key="under-maintenance"
      path={ROUTES.MAINTENANCE}
      element={<UnderMaintenance />}
    />,
    <Route
      key="creator-public-profile"
      path={`${ROUTES.PUBLIC_CREATOR_PROFILE}/:creatorId`}
      element={<PublicProfile />}
    />,
    <Route
      key="shared-list"
      path={`${ROUTES.SHARED_LIST}/:creatorProfileId?`}
      element={<SharedList />}
    />,
  ];
}

export default PublicAccessRoutes;
