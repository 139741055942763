import React from 'react';
import {Body, Headline, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './UpgradeDetails.module.scss';
import bg from './background.svg';

const TRANSLATION_PREFIX = 'components.modal-subscription.content-left.upgrade';

export type UpgradeProps = {
  testID?: string;
  upsellFeatures: string[];
  planName: string;
  priceLabel?: string;
  priceFootnote?: string;
  heading: JSX.Element | null;
};

function ListItem({children}: {children: React.ReactNode}) {
  return (
    <li>
      <Icon name="Actions-Accept" size="small" appearance="primary" />
      <Body size="md">{children}</Body>
    </li>
  );
}

function Upgrade(props: UpgradeProps) {
  const {
    testID = 'modal-subscription-upgrade',
    upsellFeatures,
    planName,
    priceLabel,
    priceFootnote,
    heading,
  } = props;

  const isMobile = useIsMobile();

  return (
    <div
      style={!isMobile ? {backgroundImage: `url(${bg})`} : {}}
      className={styles.container}
      data-testid={testID}
    >
      <div className={styles.top}>
        <Headline size="md" className={styles.headline}>
          {translate(`${TRANSLATION_PREFIX}.title`)}{' '}
          <UpgradeLabel>{planName}</UpgradeLabel>
        </Headline>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.price}>
          <Body size="md" className={styles.price}>
            {priceLabel}
          </Body>
          <Body size="sm" className={styles.priceNote}>
            {priceFootnote}
          </Body>
        </div>
      </div>

      <ul>
        {upsellFeatures?.map((feature) => (
          <ListItem key={feature}>{feature}</ListItem>
        ))}
      </ul>
    </div>
  );
}

function UpgradeLabel({children}: {children: React.ReactNode}) {
  return <span className={styles.upgradeLabel}>{children}</span>;
}

export default Upgrade;
