import {useMemo} from 'react';
import formattedPercentage from '@/utils/formattedPrecentage';
import numberAbbreviation from '@/utils/numberAbbreviation';
import {TiktokAccount, TiktokAudience} from '@/types/TiktokAccount';
import tiktokAccountFetcher from '@/api/fetchers/tiktokAccount';
import {
  AUDIENCE_RATING_INVESTIGATE_MAX,
  AUDIENCE_RATING_INVESTIGATE_MIN,
  AUDIENCE_RATING_UNHEALTHY_MIN,
  GENDER_PERCENTAGE_MOSTLY_THRESHOLD,
} from '@/components/creator-profile/constants';
import useDataQuery from '@/hooks/use-data-query';

function mapTiktokAudience(tiktokAudience: TiktokAudience) {
  function getFormattedFollowersCount() {
    return numberAbbreviation(tiktokAudience.followersCount);
  }

  function getFormattedAverageLikes() {
    return numberAbbreviation(tiktokAudience.averageLikes || 0);
  }

  function getFormattedEngagementRate() {
    return formattedPercentage(tiktokAudience.engagementRate);
  }

  function getFormattedAverageViews() {
    return numberAbbreviation(tiktokAudience.averageViews);
  }

  function getTopAgeGroup() {
    if (!tiktokAudience.ages.length) {
      return null;
    }
    return tiktokAudience.ages.reduce((highest, current) =>
      current.percentage > highest.percentage ? current : highest
    );
  }

  function getViewsToFollowersCount() {
    return tiktokAudience.averageViews / tiktokAudience.followersCount;
  }

  const maleGenderPercentage = tiktokAudience.genders.male || 0;
  const femaleGenderPercentage = tiktokAudience.genders.female || 0;
  const isMostlyMale =
    maleGenderPercentage > GENDER_PERCENTAGE_MOSTLY_THRESHOLD;
  const isMostlyFemale =
    femaleGenderPercentage > GENDER_PERCENTAGE_MOSTLY_THRESHOLD;

  return {
    ...tiktokAudience,
    formattedFollowersCount: getFormattedFollowersCount(),
    formattedAverageLikes: getFormattedAverageLikes(),
    formattedEngagementRate: getFormattedEngagementRate(),
    formattedAverageViews: getFormattedAverageViews(),
    topAgeGroup: getTopAgeGroup(),
    viewsToFollowersCount: getViewsToFollowersCount(),
    maleGenderPercentage,
    femaleGenderPercentage,
    isMostlyMale,
    isMostlyFemale,
  };
}

function useTiktokAccountQuery({
  accountId,
  enabled,
  include,
}: {
  accountId: string;
  enabled: boolean;
  include?: string[];
}) {
  const response = useDataQuery<TiktokAccount>({
    queryKey: ['tiktokAccount', accountId, include],
    queryFn: tiktokAccountFetcher.account,
    enabled,
    refetchOnWindowFocus: false,
    meta: {
      accountId,
      include,
    },
  });

  const tiktokAccount = useMemo(() => {
    if (!response.data) {
      return null;
    }

    const data = response.data as TiktokAccount;

    function getTopCountry() {
      if (!data.countries.length) {
        return null;
      }
      const firstCountry = data.countries[0];
      return firstCountry.country === 'Other'
        ? data.countries[1]
        : firstCountry;
    }

    function getAudienceUsageRating() {
      const activeLevel = data.usage?.active;

      let rating;
      if (activeLevel < AUDIENCE_RATING_UNHEALTHY_MIN) {
        rating = 'unhealthy';
      } else if (
        activeLevel >= AUDIENCE_RATING_INVESTIGATE_MIN &&
        activeLevel <= AUDIENCE_RATING_INVESTIGATE_MAX
      ) {
        rating = 'investigate';
      } else if (activeLevel === null || activeLevel === undefined) {
        rating = 'unknown';
      } else {
        rating = 'healthy';
      }

      return rating;
    }

    return {
      ...data,
      audiences: data.audiences ? data.audiences.map(mapTiktokAudience) : [],
      currentAudience: data.currentAudience
        ? mapTiktokAudience(data.currentAudience)
        : null,
      needsRelink: !data?.currentAudience,
      topCountry: getTopCountry(),
      audienceActivity: (data.usage.active || 0) / 100.0,
      audienceUsageRating: getAudienceUsageRating(),
    };
  }, [response.data]);

  return {
    ...response,
    tiktokAccount,
  };
}

export default useTiktokAccountQuery;
