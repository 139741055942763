import AudienceAge from './audience-age/AudienceAge';
import MultiSelectionOptionsAutocompleteAsync from './multi-selection-options-autocopmplete-async';
import MultiSelectionOptionsDropDown from './multi-selection-options-drop-down';
import MultiSelectionOptionsList from './multi-selection-options-list';
import MultiUserInputWithChips from './multi-user-input-with-chips';
import PlatformFiltersGroup from './platform-filters-group';
import RangeSelectionSliderAndInput from './range-selection-slider-and-input';
import SingleSelectionOptionsDropDown from './single-selection-options-drop-down';
import SingleSelectionOptionsGridWithIcon from './single-selection-options-grid-with-icon';
import SingleSelectionOptionsRadioButtonsListWithIcon from './single-selection-options-radio-buttons-list-with-icon';
import SingleSelectionOptionsSegmentedControl from './single-selection-options-segmented-control';
import UserInput from './user-input';
import WeightedSingleSelectionOptionsSegmentedControl from './weighted-single-selection-options-segmented-control';

function Filters() {}

Filters.MultiSelectionOptionsDropDown = MultiSelectionOptionsDropDown;
Filters.MultiSelectionOptionsList = MultiSelectionOptionsList;
Filters.SingleSelectionOptionsSegmentedControl =
  SingleSelectionOptionsSegmentedControl;
Filters.SingleSelectionOptionsDropDown = SingleSelectionOptionsDropDown;
Filters.WeightedSingleSelectionOptionsSegmentedControl =
  WeightedSingleSelectionOptionsSegmentedControl;
Filters.SingleSelectionOptionsGridWithIcon = SingleSelectionOptionsGridWithIcon;
Filters.SingleSelectionOptionsRadioButtonsListWithIcon =
  SingleSelectionOptionsRadioButtonsListWithIcon;
Filters.PlatformFiltersGroup = PlatformFiltersGroup;
Filters.AudienceAge = AudienceAge;
Filters.RangeSelectionSliderAndInput = RangeSelectionSliderAndInput;
Filters.MultiSelectionOptionsAutocompleteAsync =
  MultiSelectionOptionsAutocompleteAsync;
Filters.MultiUserInputWithChips = MultiUserInputWithChips;
Filters.UserInput = UserInput;

export default Filters;
