import {create} from 'zustand';

const DEFAULT_HEADER_HEIGHT = 64;
const DEFAULT_MENU_HEIGHT = 68;
const DEFAULT_SIDEBAR_WIDTH = 272;

type EmberIFrameStyleStateDefinition = {
  headerHeight: number;
  menuHeight: number;
  sidebarWidth: number;
};

const emberIframeStyleStore = create<EmberIFrameStyleStateDefinition>(() => ({
  headerHeight: DEFAULT_HEADER_HEIGHT,
  menuHeight: DEFAULT_MENU_HEIGHT,
  sidebarWidth: DEFAULT_SIDEBAR_WIDTH,
}));

const setDependantDimensions = (
  dimensions: Partial<EmberIFrameStyleStateDefinition>
) => emberIframeStyleStore.setState(dimensions);

export const emberIframeStyleActions = {
  setDependantDimensions,
};

export default emberIframeStyleStore;
