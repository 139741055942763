import React from 'react';
import {Body} from '@lightricks/react-design-system';
import calculateAge from '@/utils/calculateAge';
import {ProfileProps} from '@/types/creatorProfile';

function CreatorProfileInfo({
  testID = 'profile-info',
  profile,
}: {
  testID?: string;
  profile: ProfileProps;
}) {
  const age = calculateAge(profile.birthDate);
  const {gender, location} = profile;

  return (
    <Body data-testid={testID} size="lg">
      {[age, gender, location].filter(Boolean).join('. ')}
    </Body>
  );
}

export default CreatorProfileInfo;
