import numeral from 'numeral';

export function numberFormat(number: number | null, format?: string) {
  if (number == null) {
    return '';
  }

  return numeral(number).format(format);
}

export default numberFormat;
