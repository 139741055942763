import React, {useRef} from 'react';
import {
  Autocomplete as AutocompleteMui,
  AutocompleteClasses as AutocompleteMuiClasses,
  FormControl,
  TextField,
} from '@mui/material';
import {Icon} from '@lightricks/react-design-system';
import styles from './Autocomplete.module.scss';

const ADAPTIVE_WIDTH_EXTRA_PADDING = 35;

export type Option = {
  id?: string | number;
  label?: string;
};

export interface AutocompleteProps {
  testID?: string;
  options: Option[];
  value?: string | number | Option | Option[];
  className?: string;
  disableClearable?: boolean;
  inputLabel?: string;
  renderInput?: any;
  renderOption?: (
    props: any,
    option: any,
    baseStyle: string
  ) => React.ReactNode;
  selectedOptionId?: string | number;
  onChange?: (event: any, selectedOption: any) => void;
  onInputChange?: (event: any, value: string, reason: string) => void;
  adaptiveWidth?: boolean;
  showLabel?: boolean;
  filterSelectedOptions?: boolean;
  customPopperStyle?: string;
  sx?: object;
  slotProps?: object;
  listboxProps?: object;
  multiple?: boolean;
  defaultValue?: Option[];
  overrideDefaultPopperStyle?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  forcePopupIcon?: boolean;
  ChipProps?: object;
  classes?: Partial<AutocompleteMuiClasses>;
}

function Autocomplete(props: AutocompleteProps) {
  const {
    testID = 'autocomplete',
    inputLabel,
    selectedOptionId,
    value,
    adaptiveWidth = true,
    showLabel = true,
    filterSelectedOptions = true,
    customPopperStyle,
    sx = {},
    listboxProps,
    multiple,
    defaultValue,
    overrideDefaultPopperStyle = false,
    placeholder,
    readOnly,
    forcePopupIcon,
    ChipProps,
    classes,
    renderOption,
    ...rest
  } = props;

  const shadowElementRef = useRef<HTMLDivElement>(null);

  const getElementWidth = () => {
    if (shadowElementRef.current && adaptiveWidth) {
      return (
        shadowElementRef.current.offsetWidth + ADAPTIVE_WIDTH_EXTRA_PADDING
      );
    }
    return undefined;
  };

  const handleRenderOption = (optionProps: any, option: any) => {
    if (renderOption) {
      return renderOption(optionProps, option, styles.option);
    }

    return (
      <li
        {...optionProps}
        className={`${optionProps.className} ${styles.option}`}
        key={option.id}
      >
        {option.label}
      </li>
    );
  };

  return (
    <div data-testid={testID}>
      {adaptiveWidth && !multiple ? (
        <div ref={shadowElementRef} className={styles.shadowElement}>
          {value as string | number}
        </div>
      ) : null}
      <FormControl fullWidth>
        <AutocompleteMui
          filterSelectedOptions={filterSelectedOptions}
          fullWidth
          classes={{
            popper: `${
              overrideDefaultPopperStyle ? '' : styles.popper
            } ${customPopperStyle}`,
            ...classes,
          }}
          ListboxProps={listboxProps}
          popupIcon={
            <Icon
              size="small"
              appearance="neutral"
              name="Actions-Arrow-Down-Large"
            />
          }
          renderOption={handleRenderOption}
          filterOptions={(options, state) =>
            options.filter((option) =>
              option.label
                ?.toLowerCase()
                .includes((state.inputValue || '').toLowerCase())
            )
          }
          renderInput={({inputProps, ...params}) => (
            <TextField
              {...params}
              placeholder={placeholder}
              inputProps={{...inputProps, 'data-testid': `${testID}--input`}}
              label={showLabel && inputLabel ? inputLabel : undefined}
              variant="standard"
              sx={{
                'input': {
                  padding: '4px 0 4px 0 !important',
                  color: '#404040 !important',
                },
                '.MuiInput-root::before': {
                  border: 'unset !important',
                },
                '.MuiInput-root::after': {
                  border: 'unset !important',
                },
                '.MuiFormLabel-root': {
                  color: '#404040 !important',
                },
              }}
            />
          )}
          sx={{
            minWidth: 50,
            width: getElementWidth(),
            ...sx,
          }}
          isOptionEqualToValue={(option, _value: Option) =>
            multiple ? option.id === _value?.id : option.id === selectedOptionId
          }
          value={value as any}
          defaultValue={defaultValue}
          multiple={multiple}
          readOnly={readOnly}
          forcePopupIcon={forcePopupIcon}
          ChipProps={ChipProps}
          {...rest}
        />
      </FormControl>
    </div>
  );
}

export default Autocomplete;
