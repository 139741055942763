import React from 'react';
import {Body} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './FailedScanNote.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.failed-scan-note';

function FailedScanNote() {
  return (
    <div className={styles.noteContainer}>
      <Body size="md" className={styles.body}>
        {translate(`${TRANSLATION_PREFIX}.body`)}
      </Body>
    </div>
  );
}

export default FailedScanNote;
