import {useCallback, useEffect, useState} from 'react';
import Adyen from '@/utils/adyen';
import Griffin from '@/utils/griffin';

function useAdyenPaymentForm(
  iframeTargetRef: React.RefObject<HTMLDivElement>,
  onSubmit: (state: any) => void
) {
  const [card, setCard] = useState<any>(null);

  const loadAdyen = useCallback(async () => {
    const paymentMethodsResponse = await Griffin.getPaymentMethods({
      currency: 'usd',
      country: 'us',
      domain: window.location.origin,
      micros_value: 0,
    });

    const mountedCard = await Adyen.mountWebComponents({
      paymentMethodsResponse,
      isFromUnitedStates: true,
      element: iframeTargetRef.current,
      onSubmit,
    });

    setCard(mountedCard);
  }, [iframeTargetRef, onSubmit]);

  useEffect(() => {
    if (iframeTargetRef.current && !card) {
      loadAdyen();
    }
  }, [iframeTargetRef, loadAdyen, card]);

  return card;
}

export default useAdyenPaymentForm;
