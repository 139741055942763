import {useMutation} from '@tanstack/react-query';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';

function useCreateSession(brandId: string, dashboard_user_id: string) {
  return useMutation({
    mutationKey: ['createSession', brandId],
    mutationFn: capsuleAiUpdaters.createSession,
    meta: {
      brandId,
      dashboard_user_id,
    },
  });
}

export default useCreateSession;
