const PERMISSIONS = {
  AGENCY_BRAND: {
    UPDATE: {
      subjectClass: 'AgencyBrand',
      action: 'update',
    },
  },
  AGENCY_CREATOR_PROFILE: {
    VIEW_AVERAGE_BID: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'view_average_bid',
    },
    VIEW_EMAIL: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'view_email',
    },
    VIEW_LAST_ACTIVE_AT: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'view_last_active_at',
    },
    VIEW_SOCIAL_HEALTH: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'view_social_health',
    },
    VIEW: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'show',
    },
  },
  AGENCY_CREATOR_PROFILES: {
    VIEW: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'index',
    },
    VIEW_RECRUITS: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'view_recruits',
    },
    VIEW_INVITES: {
      subjectClass: 'AgencyCreatorProfile',
      action: 'view_invites',
    },
  },
  AUDIENCE: {
    VIEW: {
      subjectClass: 'Audience',
      action: 'visible',
    },
  },
  CAMPAIGN: {
    CLONE: {
      subjectClass: 'Campaign',
      action: 'clone',
    },
    CLOSE: {
      subjectClass: 'Campaign',
      action: 'close',
    },
    CLOSE_PROPOSAL_QUEUE: {
      subjectClass: 'Campaign',
      action: 'close_proposal_queue',
    },
    CREATE: {
      subjectClass: 'Campaign',
      action: 'upsert',
    },
    DELETE: {
      subjectClass: 'Campaign',
      action: 'destroy',
    },
    EDIT: {
      subjectClass: 'Campaign',
      action: 'upsert',
    },
    INVITE: {
      subjectClass: 'Campaign',
      action: 'invite',
    },
    RECEIVE_LAUNCH_MAILER: {
      subjectClass: 'Campaign',
      action: 'receive_launch_mailer',
    },
    RECRUIT: {
      subjectClass: 'Campaign',
      action: 'recruit',
    },
    REOPEN_PROPOSAL_QUEUE: {
      subjectClass: 'Campaign',
      action: 'reopen_proposal_queue',
    },
    RECEIVE_ACTION_NUDGE_MAILER: {
      subjectClass: 'Campaign',
      action: 'receive_action_nudge_mailer',
    },
    REVERT_TO_DRAFT: {
      subjectClass: 'Campaign',
      action: 'revert_to_draft',
    },
  },
  CAMPAIGN_BRAND_ACTION_ITEMS: {
    VIEW: {
      subjectClass: 'CampaignBrandActionItems',
      action: 'show',
    },
  },
  CAMPAIGN_CREATOR_MATCH: {
    INVITE: {
      subjectClass: 'CampaignCreatorMatch',
      action: 'invite',
    },
    DISMISS: {
      subjectClass: 'CampaignCreatorMatch',
      action: 'dismiss',
    },
  },
  CAMPAIGN_TIMELINE: {
    UPDATE: {
      subjectClass: 'CampaignTimeline',
      action: 'update',
    },
    VIEW: {
      subjectClass: 'CampaignTimeline',
      action: 'index',
    },
  },
  CAMPAIGNS: {
    VIEW: {
      subjectClass: 'Campaign',
      action: 'index',
    },
  },
  COLLABORATIONS: {
    VIEW: {
      subjectClass: 'Conversation',
      action: 'index',
    },
    CREATE: {
      subjectClass: 'Conversation',
      action: 'create',
    },
  },
  CONTENT_LIBRARY: {
    VIEW: {
      subjectClass: 'BrandAsset',
      action: 'index',
    },
  },
  CONTENT_SUBMISSION: {
    SEND_EVENT: {
      subjectClass: 'ContentSubmission',
      action: 'send_event',
    },
    DOWNLOAD_WIP: {
      subjectClass: 'ContentSubmission',
      action: 'download_wip',
    },
  },
  CONTRACT: {
    UPDATE: {
      subjectClass: 'Contract',
      action: 'update',
    },
    COMPLETE: {
      subjectClass: 'Contract',
      action: 'complete',
    },
    CANCEL: {
      subjectClass: 'Contract',
      action: 'cancel',
    },
  },
  CONVERSATIONS: {
    VIEW: {
      subjectClass: 'ConversationMessage',
      action: 'index',
    },
  },
  CREATOR_GROUPS: {
    VIEW: {
      subjectClass: 'BrandCreatorGroup',
      action: 'index',
    },
  },
  CREATORS: {
    VIEW: [
      {
        subjectClass: 'BrandCreatorGroup',
        action: 'index',
      },
      {
        subjectClass: 'CreatorGroupMembership',
        action: 'index',
      },
    ],
  },
  DASHBOARD_USER: {
    RECEIVE_DIGEST_MAILER: {
      subjectClass: 'DashboardUser',
      action: 'receive_digest_mailer',
    },
    RECEIVE_INCOMPLETE_BRIEF_MAILER: {
      subjectClass: 'Campaign',
      action: 'upsert',
    },
    SEARCH_FOR_LEGACY_TIKTOK: {
      subjectClass: 'DashboardUser',
      action: 'search_legacy_tiktok',
    },
  },
  LABEL: {
    CREATE: {
      subjectClass: 'CreatorGroupLabel',
      action: 'create',
    },
    EDIT: {
      subjectClass: 'Label',
      action: 'update',
    },
  },
  LABELS: {
    VIEW: {
      subjectClass: 'CreatorGroupLabel',
      action: 'index',
    },
  },
  MEMBERSHIP: {
    UPDATE: {
      subjectClass: 'Membership',
      action: 'update',
    },
    CREATE: {
      subjectClass: 'CreatorGroupMembership',
      action: 'create',
    },
  },
  OFF_PLATFORM_PROFILES: {
    VIEW: {
      subjectClass: 'OffPlatformProfile',
      action: 'index',
    },
  },
  PAID_GIG: {
    ACCEPT: {
      subjectClass: 'PaidGig',
      action: 'accept_and_reject',
    },
    REJECT: {
      subjectClass: 'PaidGig',
      action: 'accept_and_reject',
    },
    VIEW_PRICE: {
      subjectClass: 'PaidGig',
      action: 'view_price',
    },
  },
  POSTS: {
    VIEW: {
      subjectClass: 'Post',
      action: 'view_identity_posts',
    },
  },
  STATS: {
    VIEW: {
      subjectClass: 'Stat',
      action: 'index',
    },
  },
  VETTING: {
    VIEW: {
      subjectClass: 'Vetting',
      action: 'view',
    },
    CREATE: {
      subjectClass: 'Vetting',
      action: 'create',
    },
    UPDATE: {
      subjectClass: 'Vetting',
      action: 'update',
    },
  },
};

export default PERMISSIONS;
