import React, {useState, useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import translate from '@/utils/translate';
import CreatorActionModal from '@/views/creators/components/creator-action-modal';
import {
  ActionProps,
  CreatorProps,
} from '@/views/creators/components/creator-action/action/ActionProps';
import NoActiveCampaignsModal from '@/views/creators/components/no-active-campaigns-modal';
import useBrandCampaignsQuery from '@/hooks/queries/use-brand-campaigns-query';
import useBrandId from '@/hooks/use-brand-id';
import SuccessModal from './SuccessModal';
import Action from './action';

const TRANSLATION_PREFIX = 'views.creators.components.creator-action';

export const CREATOR_ACTION_KEYS = {
  ADD_TO_MY_CREATORS: 'add-to-my-creators',
  REMOVE_FROM_MY_CREATORS: 'remove-from-my-creators',
  ADD_TO_CAMPAIGN: 'add-to-campaign',
  SHARE_CREATOR: 'share-creator',
  INVITE_CREATOR: 'invite-creator',
  MESSAGE_CREATOR: 'message-creator',
} as const;

export type CreatorActionKey =
  (typeof CREATOR_ACTION_KEYS)[keyof typeof CREATOR_ACTION_KEYS];

export type ActiveActionKeyAndCreators = {
  actionKey: CreatorActionKey;
  creators: CreatorProps[];
};

type CreatorActionContent = {
  titleLocaleKey: string;
  contentComponent: (
    props: ActionProps & {setHasActiveCampaigns: (hasActive: boolean) => void}
  ) => React.JSX.Element;
};

type CreatorActionSuccessContent = {
  titleLocaleKey: string;
  subtitleLocaleKey: string;
};

type CloseReason = 'success' | 'cancelled';

export type InitState = {
  [key in CreatorActionKey]?: any;
};

type CreatorActionProps = {
  creators: CreatorProps[];
  activeCreatorAction: CreatorActionKey | null;
  selectedCampaignId?: string;
  initState?: InitState;
  close: (
    reason: CloseReason,
    creators: CreatorProps[],
    actionName?: string,
    result?: unknown
  ) => void;
  onPresentedOrDismissed?: (
    presentedOrDismissed: 'presented' | 'dismissed',
    name: string,
    creators: CreatorProps[]
  ) => void;
};

function Todo() {
  return <div>TODO</div>;
}

const baseCreatorActionMap: Partial<
  Record<CreatorActionKey, CreatorActionContent>
> = {
  [CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS]: {
    titleLocaleKey: `${TRANSLATION_PREFIX}.add-to-my-creators.title`,
    contentComponent: Action.AddToMyCreators,
  },
  [CREATOR_ACTION_KEYS.SHARE_CREATOR]: {
    titleLocaleKey: `${TRANSLATION_PREFIX}.share-creator.title`,
    contentComponent: Todo,
  },
  [CREATOR_ACTION_KEYS.INVITE_CREATOR]: {
    titleLocaleKey: `${TRANSLATION_PREFIX}.invite-creator.title`,
    contentComponent: Todo,
  },
  [CREATOR_ACTION_KEYS.MESSAGE_CREATOR]: {
    titleLocaleKey: '',
    contentComponent: Action.MessageCreator as any,
  },
  [CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS]: {
    titleLocaleKey: '',
    contentComponent: Action.RemoveFromMyCreators,
  },
};

const creatorActionSuccessMap: Partial<
  Record<CreatorActionKey, CreatorActionSuccessContent | null>
> = {
  [CREATOR_ACTION_KEYS.INVITE_CREATOR]: {
    titleLocaleKey: `${TRANSLATION_PREFIX}.invite-creator.success-title`,
    subtitleLocaleKey: `${TRANSLATION_PREFIX}.invite-creator.success-subtitle`,
  },
  [CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS]: null,
  [CREATOR_ACTION_KEYS.SHARE_CREATOR]: null,
};

function CreatorAction(props: CreatorActionProps) {
  const {
    creators,
    activeCreatorAction: propActiveCreatorAction,
    selectedCampaignId,
    initState,
    close,
    onPresentedOrDismissed,
  } = props;
  const [successModalSettings, setSuccessModalSettings] = useState<{
    title: string;
    subtitle: string;
  } | null>(null);
  const [hasActiveCampaigns, setHasActiveCampaigns] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeCreatorAction, setActiveCreatorAction] =
    useState<CreatorActionKey | null>(propActiveCreatorAction);

  const brandId = useBrandId();
  const {
    brandCampaigns: campaignsAcceptingProposals,
    isLoading: isCampaignsLoading,
  } = useBrandCampaignsQuery({
    brandId,
    states: ['accepting_proposals'],
  });

  useEffect(() => {
    setActiveCreatorAction(propActiveCreatorAction);
  }, [propActiveCreatorAction]);

  useEffect(() => {
    if (!isCampaignsLoading) {
      setHasActiveCampaigns(campaignsAcceptingProposals.length > 0);
    }
  }, [isCampaignsLoading, campaignsAcceptingProposals]);

  const creatorActionMap = useMemo(() => {
    const map = {...baseCreatorActionMap};
    if (hasActiveCampaigns) {
      map[CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN] = {
        titleLocaleKey: `${TRANSLATION_PREFIX}.add-to-campaign.title`,
        contentComponent: Action.AddToCampaign as any,
      };
    }
    return map;
  }, [hasActiveCampaigns]);

  const getCreatorAction = () => {
    if (!activeCreatorAction) {
      return null;
    }

    return creatorActionMap[activeCreatorAction];
  };

  const getCreatorActionContent = () => {
    const creatorAction = getCreatorAction();
    if (!creatorAction) {
      return null;
    }

    const CreatorActionComponent = creatorAction.contentComponent;
    return (
      <CreatorActionComponent
        creators={creators}
        selectedCampaignId={selectedCampaignId}
        initState={initState?.[activeCreatorAction as CreatorActionKey]}
        setHasActiveCampaigns={setHasActiveCampaigns}
        onSubmitSuccess={(result: unknown) => {
          if (activeCreatorAction) {
            const successSettings =
              creatorActionSuccessMap[activeCreatorAction];
            if (successSettings) {
              setSuccessModalSettings({
                title: translate(successSettings.titleLocaleKey),
                subtitle: translate(successSettings.subtitleLocaleKey),
              });
            }
          }
          close('success', creators, activeCreatorAction || undefined, result);
        }}
      />
    );
  };

  if (
    !hasActiveCampaigns &&
    activeCreatorAction === CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN
  ) {
    return (
      <NoActiveCampaignsModal
        open
        close={() => close('cancelled', creators, activeCreatorAction)}
        onSaveToListClick={() => {
          setActiveCreatorAction(CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS);
        }}
        onCreateCampaignClick={() => {
          close('cancelled', creators, activeCreatorAction);
          searchParams.set('showNewCampaignWizardModal', 'true');
          setSearchParams(searchParams);
        }}
        showSaveToListButton={false}
      />
    );
  }

  return (
    <>
      <CreatorActionModal
        title={
          translate(getCreatorAction()?.titleLocaleKey || '') ?? (
            <div>Render Title</div> // TODO: What should be rendered here?
          )
        }
        creatorAction={activeCreatorAction || ''}
        content={getCreatorActionContent()}
        onClose={(actionName: string) =>
          close('cancelled', creators, actionName)
        }
        onPresentedOrDismissed={(
          presentedOrDismissed: 'presented' | 'dismissed',
          name: string
        ) => onPresentedOrDismissed?.(presentedOrDismissed, name, creators)}
      />
      <SuccessModal
        title={successModalSettings?.title || ''}
        subtitle={successModalSettings?.subtitle || ''}
        isOpen={!!successModalSettings}
        close={() => setSuccessModalSettings(null)}
      />
    </>
  );
}

export default CreatorAction;
