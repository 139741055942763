import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import '../styles/mediaInit';
import '../types/images';
import Storage from '../utils/wrappers/Storage';

dayjs.extend(duration);
dayjs.extend(relativeTime);
Storage.setDriver(window.localStorage);
