import React, {useEffect, useMemo} from 'react';
import {
  Body,
  Checkbox,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ViewType} from '@/types/models/search-creators/searchCreatorsStore';
import SortField, {
  DefaultSortField,
} from '@/types/models/search-creators/sortField';
import {SortingState} from '@/types/models/search-creators/sorting';
import ActionDrawerButtons from '@/views/creators/components/action-drawer-buttons';
import getAnalyticsSearchScreenName from '@/views/creators/search/utils/getAnalyticsSearchScreenName';
import DropdownButton from '@/components/dropdown-button';
import TotalResults from '@/components/total-results';
import ViewTypeSelector from '@/components/view-type-selector';
import useTrackSearchButtonEvent from '@/hooks/analytics/use-track-search-button-event';
import {
  searchCreatorsActions,
  VIEW_TYPE_GRID,
  VIEW_TYPE_TABLE,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './SearchControls.module.scss';

const TRANSLATION_PREFIX = 'views.creators.search.components.search-controls';

type SearchControlsProps = {
  totalResults: number;
  searchQuery?: string;
  viewType: ViewType;
  onActionDrawerButtonClick?: (label: string) => void;
  sorting?: SortingState;
};

const gridIcon = '/assets/svg/grid.svg';
const listIcon = '/assets/svg/list.svg';
const sortIcon = '/assets/svg/icon-sort.svg';

const VIEW_TYPES = [
  {
    id: 'grid',
    icon: gridIcon,
  },
  {
    id: 'table',
    icon: listIcon,
  },
];

const SORT_TYPES = [
  {
    id: SortField.rating,
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.rating`,
  },
  {
    id: SortField.followers,
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.followers`,
  },
  {
    id: SortField.engagementRate,
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.engagement-rate`,
  },
  {
    id: SortField.relevancy,
    labelLocaleKey: `${TRANSLATION_PREFIX}.sorting.options.relevancy`,
  },
];

const analyticsButtonName = {
  [VIEW_TYPE_TABLE]: 'cards-view',
  [VIEW_TYPE_GRID]: 'list-view',
};

function SearchControls({
  totalResults,
  searchQuery,
  viewType,
  onActionDrawerButtonClick,
  sorting,
}: SearchControlsProps) {
  const trackSearchButtonEvent = useTrackSearchButtonEvent();
  const sortByLabel = useMemo(() => {
    if (sorting) {
      const selectedSortType = SORT_TYPES.find(
        (sortType) => sortType.id === sorting.field
      );
      if (selectedSortType) {
        return `${translate(selectedSortType.labelLocaleKey)}`;
      }
    }
    return translate(`${TRANSLATION_PREFIX}.sorting.menu-title`);
  }, [sorting]);

  const options = useMemo(() => {
    return SORT_TYPES.filter((sortType) =>
      searchQuery ? true : sortType.id !== 'relevancy'
    ).map((sortType) => ({
      ...sortType,
      label: translate(sortType.labelLocaleKey),
    }));
  }, [searchQuery]);

  useEffect(() => {
    // edge case handling => if sorting field is not in the options, reset to default
    if (sorting && !options.find((option) => option.id === sorting?.field)) {
      searchCreatorsActions.changeSorting(DefaultSortField);
    }
  }, [sorting, options]);

  const renderMenuTitle = () => {
    return (
      <div className={styles.menuTitle}>
        <Headline size="sm">
          {translate(`${TRANSLATION_PREFIX}.sorting.menu-title`)}
        </Headline>
      </div>
    );
  };

  const renderMenuItem = (option: {label: string; icon?: string}) => {
    return (
      <div className={styles.menuItem}>
        <Body size="lg" color={designSystemToken('semantic.fg.primary')}>
          {option.label}
        </Body>
        <Checkbox radio checked={option.label === sortByLabel} />
      </div>
    );
  };

  return (
    <div className={styles.searchControls}>
      <div className={styles.left}>
        <ActionDrawerButtons
          onActionDrawerButtonClick={onActionDrawerButtonClick}
        />
      </div>
      <div className={styles.right}>
        <TotalResults amount={totalResults} searchTerm={searchQuery} />
        <DropdownButton
          buttonClassName={styles.sortButton}
          label={sortByLabel}
          options={options}
          selectedOptionIds={sorting ? [sorting.field] : undefined}
          onChange={(option) => {
            if (option.id !== sorting?.field) {
              trackSearchButtonEvent.pressed({
                button_name: `Sort By: ${option.label}`,
                screen_name: getAnalyticsSearchScreenName(viewType),
              });
              searchCreatorsActions.changeSorting(option.id as string);
            }
          }}
          startIcon={<img src={sortIcon} alt="sort-icon" />}
          renderMenuTitle={renderMenuTitle}
          renderMenuItem={renderMenuItem}
          menuWidth={320}
          menuProps={{transformOrigin: {vertical: -8, horizontal: 'center'}}}
          menuItemProps={{classes: {selected: styles.selectedMenuItem}}}
        />
        <ViewTypeSelector
          viewTypes={VIEW_TYPES}
          selectedViewTypeId={viewType}
          onChange={(viewTypeId) => {
            trackSearchButtonEvent.pressed({
              button_name: analyticsButtonName[viewType],
              screen_name: getAnalyticsSearchScreenName(viewType),
            });
            searchCreatorsActions.changeViewType(viewTypeId as ViewType);
          }}
        />
      </div>
    </div>
  );
}

export default SearchControls;
