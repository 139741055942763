import copy from 'copy-to-clipboard';
import React, {useState} from 'react';
import {
  Avatar,
  Body,
  Headline,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import {getShareMediaLink} from '@/utils/shareUtils';
import translate from '@/utils/translate';
import {Asset} from '@/types/asset';
import Profile from '@/types/profile';
import Link from '@/components/link';
import MediaFileViewer from '@/components/media-file-viewer';
import ProfilePopup from '@/components/profile/ProfilePopup';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import styles from './AssetCard.module.scss';

function AssetCard(props: Asset) {
  const {
    id,
    downloadUrl,
    isAiContent,
    kind,
    thumbnailUrl,
    url,
    profile,
    campaign,
  } = props;
  return (
    <div className={styles.assetCardContainer}>
      <div className={styles.content} data-testid="asset-card">
        <div className={styles.imageWrapper}>
          <MediaFileViewer
            thumbnailUrl={thumbnailUrl}
            kind={kind}
            url={url}
            renderOverlay={(setOpen) => (
              <AssetOverlay
                id={id}
                downloadUrl={downloadUrl}
                kind={kind}
                setOpen={setOpen}
              />
            )}
          />
        </div>
        {profile && <ProfileInfo profile={profile} kind={kind} />}
        {campaign && (
          <CampaignLink
            id={campaign.id as string}
            campaignName={campaign.name}
          />
        )}
      </div>
    </div>
  );
}

type AssetOverlayProps = {
  id: string;
  downloadUrl: string | null;
  kind: 'photo' | 'video' | 'blog';
  setOpen: () => void;
};

function AssetOverlay({id, downloadUrl, kind, setOpen}: AssetOverlayProps) {
  const shareMediaLink = getShareMediaLink(id, kind);
  const saveShareMediaLinkToClipboard = () => {
    copy(shareMediaLink);
    raiseFlashMessage({
      status: 'success',
      mode: 'light',
      message: (
        <Headline size="xs" width="100%" display="flex" justifyContent="center">
          {translate('Link copied to clipboard!')}
        </Headline>
      ),
      withCloseButton: false,
      style: {justifyContent: 'center'},
    });
  };

  return (
    <div className={styles.assetCardOverlay}>
      <OverlayActionRow
        iconName="Actions-Fullscreen-Enter"
        actionName={translate(`View ${kind}`)}
        clickAction={setOpen}
      />
      <OverlayActionRow
        iconName="Navigation-Download"
        actionName={translate('Download')}
        clickAction={
          downloadUrl ? () => window.open(downloadUrl, '_blank') : () => {}
        }
      />
      <OverlayActionRow
        iconName="Navigation-Link"
        actionName={translate('Copy share link')}
        clickAction={saveShareMediaLinkToClipboard}
      />
    </div>
  );
}

type OverlayActionRowProps = {
  iconName: IconName;
  actionName: string;
  clickAction: () => void;
};

function OverlayActionRow({
  iconName,
  actionName,
  clickAction,
}: OverlayActionRowProps) {
  return (
    <div className={styles.assetCardOverlayAction} onClick={clickAction}>
      <Icon size="medium" appearance="white" name={iconName} />
      <Body size="lg" className={styles.overlayActionLink}>
        {actionName}
      </Body>
    </div>
  );
}

type ProfileInfoProps = {
  profile: Profile;
  kind: 'photo' | 'video' | 'blog';
};

function ProfileInfo(props: ProfileInfoProps) {
  const {profile, kind} = props;
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setIsHovered(false);
  };
  const {imageUrl: profileImageUrl, initials} = useAvatarImageOrInitials(
    profile.imageUrl,
    profile.displayName
  );
  return (
    <div key={profile.id} className={styles.profile}>
      <div
        className={styles.profileHoverContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Avatar
          className={styles.profileAvatar}
          size="sm"
          src={profileImageUrl}
          type={profileImageUrl ? 'picture' : 'placeholder'}
          initials={initials}
          variant="square"
          background="transparent"
        />
        <Label size="md">{profile.displayName}</Label>
        {profile && (
          <ProfilePopup
            isVisible={isHovered}
            profile={profile}
            anchorEl={anchorEl}
            onClose={handleMouseLeave}
            email={profile.email}
          />
        )}
      </div>
      <div className={`${styles.kind} ${styles[kind]}`} />
    </div>
  );
}

type CampaignInfoProps = {
  id: string;
  campaignName: string;
};

function CampaignLink({id, campaignName}: CampaignInfoProps) {
  return (
    <Link key={id} to={`/campaigns/${id}/content-downloads`} target="_blank">
      <Body size="md" className={styles.campaignLink}>
        {campaignName}
      </Body>
    </Link>
  );
}

export default AssetCard;
