import {EmailPreference} from '@/types/emailPreference';

export const handleCheckboxChange = (
  preferenceName: string,
  brandDisplayName: string,
  emailPreferences: EmailPreference[],
  setEmailPreferences: React.Dispatch<React.SetStateAction<EmailPreference[]>>,
  setChangedPreferences: React.Dispatch<React.SetStateAction<EmailPreference[]>>
) => {
  const updatedPreferences = emailPreferences.map((preference) =>
    preference.name === preferenceName &&
    preference.brandDisplayName === brandDisplayName
      ? {...preference, enabled: !preference.enabled}
      : preference
  );
  setEmailPreferences(updatedPreferences);

  const changedPreference = updatedPreferences.find(
    (preference) =>
      preference.name === preferenceName &&
      preference.brandDisplayName === brandDisplayName
  );

  if (changedPreference) {
    setChangedPreferences((prev) => {
      const exists = prev.find(
        (pref) =>
          pref.name === preferenceName &&
          pref.brandDisplayName === brandDisplayName
      );
      if (exists) {
        return prev.map((pref) =>
          pref.name === preferenceName &&
          pref.brandDisplayName === brandDisplayName
            ? changedPreference
            : pref
        );
      }
      return [...prev, changedPreference];
    });
  }
};

export const updateFrequency = (
  item: EmailPreference,
  frequency: number | null,
  emailPreferences: EmailPreference[],
  setEmailPreferences: React.Dispatch<React.SetStateAction<EmailPreference[]>>,
  setChangedPreferences: React.Dispatch<React.SetStateAction<EmailPreference[]>>
) => {
  const updatedPreferences = emailPreferences.map((preference) =>
    preference.name === item.name &&
    preference.brandDisplayName === item.brandDisplayName
      ? {
          ...preference,
          frequency: frequency !== null ? frequency : undefined,
          enabled: frequency !== null,
        }
      : preference
  );
  setEmailPreferences(updatedPreferences);

  const changedPreference = updatedPreferences.find(
    (preference) =>
      preference.name === item.name &&
      preference.brandDisplayName === item.brandDisplayName
  );

  if (changedPreference) {
    setChangedPreferences((prev) => {
      const exists = prev.find(
        (pref) =>
          pref.name === item.name &&
          pref.brandDisplayName === item.brandDisplayName
      );
      if (exists) {
        return prev.map((pref) =>
          pref.name === item.name &&
          pref.brandDisplayName === item.brandDisplayName
            ? changedPreference
            : pref
        );
      }
      return [...prev, changedPreference];
    });
  }
};

export const getUniqueBrands = (emailPreferences: EmailPreference[]) => {
  return Array.from(
    new Set(emailPreferences.map((item) => item.brandDisplayName))
  ).sort();
};
