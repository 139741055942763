import React from 'react';
import {Avatar} from '@lightricks/react-design-system';
import {MediaFile} from '@/types/mediaFile';
import MediaFileViewer from '@/components/media-file-viewer';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import styles from './CreatorProfileAvatar.module.scss';

interface CreatorProfileAvatarProps {
  className?: string;
  profileImageUrl: string;
  profileDisplayName: string;
  size: '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs' | '3xs';
  onClick?: (mediaFile: MediaFile) => void;
  allowEnlarge?: boolean;
}

function CreatorProfileAvatar({
  className = '',
  profileImageUrl,
  profileDisplayName,
  size,
  onClick,
  allowEnlarge = true,
}: CreatorProfileAvatarProps) {
  const {imageUrl, initials} = useAvatarImageOrInitials(
    profileImageUrl,
    profileDisplayName
  );

  if (imageUrl && allowEnlarge) {
    return (
      <div
        className={`${styles.creatorProfileAvatar} ${styles.round} ${className}`}
      >
        <MediaFileViewer
          url={imageUrl}
          kind="photo"
          thumbnailUrl={imageUrl}
          onClick={onClick}
        />
      </div>
    );
  }

  return (
    <Avatar
      className={`${styles.creatorProfileAvatar} ${className}`}
      size={size}
      src={imageUrl}
      type={imageUrl ? 'picture' : 'initials'}
      initials={initials}
      background="#333"
    />
  );
}

export default CreatorProfileAvatar;
