import React, {useCallback, useEffect, useRef, useState} from 'react';
import QuickPinchZoom, {make3dTransformValue} from 'react-quick-pinch-zoom';
import {designSystemToken, Modal} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {MediaFile} from '@/types/mediaFile';
import VideoPlayer from '@/components/video-player';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './MediaFileViewer.module.scss';

const TRANSLATION_PREFIX = 'components.media-file-viewer';

export interface MediaFileViewerProps extends MediaFile {
  testID?: string;
  onClick?: (mediaItem: MediaFile) => void;
  renderOverlay?: (setOpen: () => void) => void;
}

type LargeMediaViewerProps = {
  isVideo: boolean;
  url: string;
  thumbnailUrl: string;
  allowZoomImage?: boolean;
};

function ZoomableImage(props: {
  url: string;
  thumbnailUrl: string;
  isPreLoading: boolean;
}) {
  const {url, thumbnailUrl, isPreLoading} = props;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const onUpdate = useCallback(
    ({x, y, scale}: {x: number; y: number; scale: number}) => {
      const {current: img} = imgRef;

      if (img) {
        const value = make3dTransformValue({x, y, scale});

        img.style.setProperty('transform', value);
      }
    },
    []
  );

  return (
    <QuickPinchZoom onUpdate={onUpdate}>
      <img
        ref={imgRef}
        src={isPreLoading ? thumbnailUrl : url}
        className={`${styles.image} ${isPreLoading ? styles.blurry : ''}`}
        alt={url}
      />
    </QuickPinchZoom>
  );
}

function LargeMediaViewer(props: LargeMediaViewerProps) {
  const isMobile = useIsMobile();
  const {isVideo, thumbnailUrl, url, allowZoomImage = true} = props;
  const [isPreLoading, setIsPreLoading] = useState(true);

  useEffect(() => {
    if (isVideo) {
      return;
    }
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsPreLoading(false);
    };
  }, [isVideo, url]);

  if (isVideo) {
    return (
      <VideoPlayer
        playerWrapperClassName={styles.videoPlayer}
        url={url}
        playerStyle={{
          backgroundColor: designSystemToken('semantic.bg.neutral'),
          minWidth: isMobile ? '100vw' : 'auto',
          minHeight: isMobile ? undefined : 'auto',
        }}
      />
    );
  }

  if (allowZoomImage && !isPreLoading) {
    return (
      <ZoomableImage
        url={url}
        thumbnailUrl={thumbnailUrl}
        isPreLoading={isPreLoading}
      />
    );
  }
  return (
    <img
      src={isPreLoading ? thumbnailUrl : url}
      className={`${styles.image} ${isPreLoading ? styles.blurry : ''}`}
      alt={url}
    />
  );
}

function MediaFileViewer(props: MediaFileViewerProps) {
  const {
    testID = 'media-file-viewer',
    id,
    url,
    kind,
    thumbnailUrl,
    onClick,
    renderOverlay,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const isVideo = kind === 'video';

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
    onClick?.({id, url, kind, thumbnailUrl});
  };

  return (
    <>
      <Modal
        showCloseButton
        open={isOpen}
        handleClose={onClose}
        className={`${styles.mediaModalContainer} ${
          isVideo ? styles.isVideo : styles.isPhoto
        }`}
      >
        <LargeMediaViewer
          isVideo={isVideo}
          thumbnailUrl={thumbnailUrl}
          url={url}
        />
      </Modal>
      <div
        className={`${styles.mediaFileViewerContainer} ${
          isVideo ? styles.video : styles.photo
        }`}
        data-testid={testID}
      >
        <div className={styles.mediaContainer} onClick={onOpen}>
          <img
            id={id}
            src={thumbnailUrl}
            alt={translate(`${TRANSLATION_PREFIX}.thumbnail`)}
          />
        </div>
      </div>
      {renderOverlay?.(onOpen)}
    </>
  );
}

export default MediaFileViewer;
