import React, {useMemo, useState} from 'react';
import {
  Box,
  ClickAwayListener,
  Dialog,
  Popover,
  PopoverProps,
} from '@mui/material';
import {Avatar, Icon} from '@lightricks/react-design-system';
import Fortress from '@/services/fortress/Fortress';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import BrandElement from '@/components/brand-element';
import Link from '@/components/link';
import MENU_ITEMS from '@/components/menu/menuItems';
import NavLink from '@/components/nav-link';
import NewCampaignButton from '@/components/new-campaign-button';
import ReportInappropriateContent from '@/components/report-inappropriate-content';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import useToken from '@/hooks/use-token';
import styles from './UserMenu.module.scss';

type Role = {
  displayName: string;
};

export type User = {
  email: string;
  roles: Role[];
  firstName: string;
  lastName: string;
};

export type UserMenuProps = {
  testID?: string;
  user: User;
  locationAi?: boolean;
};

type MenuProps = {
  testID: string;
  user: User;
  isMobile: boolean;
  isOrgOwner: boolean;
  setAnchorEl: (element: null | HTMLElement) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  logout: () => void;
};

function Menu({
  testID,
  user,
  isMobile,
  isOrgOwner,
  setAnchorEl,
  handleClick,
  logout,
}: MenuProps) {
  const [modalReportIsOpen, setModalReportIsOpen] = useState(false);

  const openReportModal = () => {
    setModalReportIsOpen(true);
  };

  const closeReportModal = () => {
    setModalReportIsOpen(false);
  };
  const getTopSectionForMobile = () => {
    return (
      <div className={styles.topSection}>
        <BrandElement />
        <div onClick={handleClick}>
          <Icon size="large" appearance="neutral" name="Actions-Close-Small" />
        </div>
      </div>
    );
  };

  const getSubmenuForMobile = () => {
    return (
      <div>
        <div onClick={handleClick}>
          <NewCampaignButton user={user} />
        </div>
        {MENU_ITEMS.filter(
          (menuItem) => menuItem.mobileLocation === 'submenu'
        ).map((item) => (
          <div key={item.id}>
            <NavLink
              onClick={handleClick}
              to={item.link}
              className={styles.link}
              testID={`${testID}--mobile-menu--${item.id}`}
            >
              {translate(item.localeLabelKey)}
            </NavLink>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box className={styles.boxMenu}>
        <div className={styles.userMenu} data-testid={`${testID}--popup`}>
          {isMobile ? getTopSectionForMobile() : null}
          <div className={styles.subMenu}>
            {isMobile ? getSubmenuForMobile() : null}
            <section>
              <h3 className={styles.sectionLabel}>
                <i>
                  <Icon
                    size="medium"
                    appearance="neutral"
                    name="Navigation-Settings"
                  />
                </i>
                {translate('components.user-menu.settings-label')}
              </h3>
              <Link
                onClick={handleClick}
                to={ROUTES.PREFERENCES}
                className={styles.link}
                testID={`${testID}--account-settings`}
              >
                {translate('components.user-menu.account-settings')}
              </Link>
              {isOrgOwner ? (
                <Link
                  onClick={handleClick}
                  to="/organization-settings/brands"
                  className={styles.link}
                  testID={`${testID}--organization-settings`}
                >
                  {translate('components.user-menu.organization-settings')}
                </Link>
              ) : null}
            </section>
            <section>
              <h3 className={styles.sectionLabel}>
                <i>
                  <Icon
                    size="medium"
                    name="Navigation-Question-Line"
                    appearance="neutral"
                  />
                </i>
                {translate('components.user-menu.help-label')}
              </h3>
              <a
                onClick={handleClick}
                href="https://popularpays.zendesk.com/hc/en-us/articles/360037045852-How-to-Get-Started-on-Pop-Pays"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                data-testid={`${testID}--getting-started`}
              >
                {translate('components.user-menu.getting-started')}
              </a>
              <a
                id="user-menu--support"
                onClick={handleClick}
                href="https://popularpays.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                data-testid={`${testID}--support`}
              >
                {translate('components.user-menu.support')}
              </a>
              <Link
                onClick={handleClick}
                to="?showContactSalesModal=true"
                className={styles.link}
                replace
                data-testid={`${testID}--contact-sales`}
              >
                {translate('components.user-menu.contact-sales')}
              </Link>
              <span
                onClick={openReportModal}
                className={styles.link}
                data-testid={`${testID}--report`}
              >
                {translate('components.user-menu.report')}
              </span>
              <ReportInappropriateContent
                isOpen={modalReportIsOpen}
                onClose={closeReportModal}
                testID="report-inappropriate-content"
              />
            </section>
            <section>
              <div
                id="user-menu--logout"
                role="button"
                tabIndex={0}
                onClick={logout}
                className={styles.link}
                data-testid={`${testID}--logout`}
              >
                {translate('components.user-menu.logout')}
              </div>
            </section>
          </div>
        </div>
      </Box>
    </ClickAwayListener>
  );
}

Menu.displayName = 'Menu';

function UserMenu(props: UserMenuProps) {
  const isMobile = useIsMobile();
  const navigation = useNavigation();

  const {resetToken} = useToken();
  const {testID = 'user-menu', user, locationAi} = props;
  const {email, roles} = user;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOrgOwner = useMemo(() => {
    const roleNames = (roles || []).map((role) => role.displayName);
    return (
      roleNames.includes('Organization Owner') ||
      roleNames.includes('Platform Admin')
    );
  }, [roles]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const logout = async () => {
    setAnchorEl(null);
    Fortress.clearCookieTokens();
    await resetToken();
    queryClient.clear();
    navigation.navigate(ROUTES.LOGIN, undefined, false);
  };

  const open = !!anchorEl;
  const id = open ? 'simple-popper' : undefined;

  const popoverProps: PopoverProps = {
    id,
    open,
    anchorEl,
    PaperProps: {
      className: styles.userMenuPopover,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    onClose: () => setAnchorEl(null),
  };

  const getMenu = () => {
    return (
      <Menu
        testID={testID}
        user={user}
        isMobile={isMobile}
        isOrgOwner={isOrgOwner}
        setAnchorEl={setAnchorEl}
        handleClick={handleClick}
        logout={logout}
      />
    );
  };

  const getInitials = () => {
    if (user.firstName && user.lastName) {
      return user.firstName.charAt(0) + user.lastName.charAt(0);
    }
    return (email || '').charAt(0);
  };

  return (
    <div className={styles.container} data-testid={testID}>
      <Avatar
        className={locationAi ? styles.avatarAi : styles.avatar}
        onClick={handleClick}
        data-testid={`${testID}--button`}
        size="sm"
        type="initials"
        initials={getInitials()}
      />
      {isMobile ? (
        <Dialog open={open} fullScreen>
          {getMenu()}
        </Dialog>
      ) : (
        <Popover {...popoverProps}>{getMenu()}</Popover>
      )}
    </div>
  );
}

export default UserMenu;
