import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import React from 'react';
import getDefaultPresets from '@/components/date-range-picker/defaults';
import {Marker, MARKERS} from '../../markers';
import {DateRange, DefinedPreset, NavigationAction} from '../../types';
import {getValidatedMonths, parseOptionalDate} from '../../utils';
import Menu from '../menu';

dayjs.extend(isBetween);
dayjs.extend(minMax);

interface DateRangeProps {
  testID: string;
  initialDateRange?: DateRange;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (dateRange: DateRange) => void;
  setIsDirty?: (isDirty: boolean) => void;
  showPresets?: boolean;
  definedPresets?: DefinedPreset[];
}

function DateRangeElement(props: DateRangeProps) {
  const today = new Date();

  const {
    testID,
    onChange,
    initialDateRange,
    minDate,
    maxDate,
    onClose,
    onSubmit,
    setIsDirty,
    showPresets,
    definedPresets = getDefaultPresets(
      new Date(),
      minDate ? dayjs(minDate).toDate() : undefined,
      maxDate ? dayjs(maxDate).toDate() : undefined
    ),
  } = props;

  const minDateValid = parseOptionalDate(
    minDate,
    dayjs(today).add(-10, 'year').toDate()
  );
  const maxDateValid = parseOptionalDate(
    maxDate,
    dayjs(today).add(10, 'year').toDate()
  );
  const [initialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange || {},
    minDateValid,
    maxDateValid
  );

  const [dateRange, setDateRange] = React.useState<DateRange>({
    ...initialDateRange,
  });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(
    initialFirstMonth || today
  );
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    initialSecondMonth || dayjs(firstMonth).add(1, 'month').toDate()
  );

  const {startDate, endDate} = dateRange;

  // handlers
  const setFirstMonthValidated = (date: Date) => {
    if (dayjs(date).isBefore(secondMonth)) {
      const monthsDiff = dayjs(date).diff(firstMonth, 'month');
      if (monthsDiff !== 0) {
        const monthDiffFromPrev = dayjs(date).diff(firstMonth, 'month');
        const anchorDate = dateRange.startDate
          ? dayjs(dateRange.startDate).add(monthDiffFromPrev, 'month').toDate()
          : date;
        setDateRangeValidated({startDate: anchorDate, endDate: secondMonth});
      }
      setFirstMonth(date);
    }
  };

  const setSecondMonthValidated = (date: Date) => {
    if (dayjs(date).isAfter(firstMonth)) {
      const monthsDiff = dayjs(date).diff(firstMonth, 'month');
      if (monthsDiff !== 0) {
        const monthDiffFromPrev = dayjs(date).diff(secondMonth, 'month');
        const anchorDate = dateRange.endDate
          ? dayjs(dateRange.endDate).add(monthDiffFromPrev, 'month').toDate()
          : date;
        setDateRangeValidated({startDate: firstMonth, endDate: anchorDate});
      }
      setSecondMonth(date);
    }
  };

  const setDateRangeValidated = (range: DateRange) => {
    let {startDate: newStart, endDate: newEnd} = range;

    if (newStart && newEnd) {
      newStart = (
        dayjs.max(dayjs(newStart), dayjs(minDateValid)) as dayjs.Dayjs
      ).toDate();
      range.startDate = newStart;
      newEnd = (
        dayjs.min(dayjs(newEnd), dayjs(maxDateValid)) as dayjs.Dayjs
      ).toDate();
      range.endDate = newEnd;

      setDateRange(range);
      setIsDirty?.(true);

      setFirstMonth(newStart);
      setSecondMonth(
        dayjs(newStart).isSame(newEnd, 'month')
          ? dayjs(newStart).add(1, 'month').toDate()
          : newEnd
      );
    } else {
      const emptyRange = {};

      setDateRange(emptyRange);
      onChange(emptyRange);

      setFirstMonth(today);
      setSecondMonth(dayjs(firstMonth).add(1, 'month').toDate());
    }
  };

  const onDayClick = (day: Date) => {
    if (startDate && !endDate && !dayjs(day).isBefore(startDate)) {
      const newRange = {startDate, endDate: dayjs(day).endOf('day').toDate()};
      setDateRange(newRange);
    } else {
      setDateRange({startDate: day, endDate: undefined});
    }
    setIsDirty?.(true);
    setHoverDay(day);
  };

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (marker === MARKERS.FIRST_MONTH) {
      const firstNew = dayjs(firstMonth).add(action, 'month').toDate();
      if (dayjs(firstNew).isBefore(secondMonth)) {
        setFirstMonth(firstNew);
      }
    } else {
      const secondNew = dayjs(secondMonth).add(action, 'month').toDate();
      if (dayjs(firstMonth).isBefore(secondNew)) {
        setSecondMonth(secondNew);
      }
    }
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !dayjs(date).isSame(hoverDay, 'day')) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day: Date) =>
    (startDate &&
      !endDate &&
      hoverDay &&
      dayjs(hoverDay).isAfter(startDate) &&
      dayjs(day).isBetween(startDate, hoverDay, 'day', '[]')) as boolean;

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  return (
    <Menu
      testID={testID}
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      hoverDay={hoverDay}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      setDateRange={setDateRangeValidated}
      helpers={helpers}
      handlers={handlers}
      onClose={onClose}
      onSubmit={onSubmit}
      showPresets={showPresets}
      definedPresets={definedPresets}
    />
  );
}

export default DateRangeElement;
