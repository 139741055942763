import dayjs from 'dayjs';
import React from 'react';
import {Box} from '@mui/material';
import {
  Body,
  Button,
  Headline,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import usePreferredCreatorGroupMemberships from '@/views/creators/lists/hooks/usePreferredCreatorGroupMemberships';
import useBulkUpdateMemberships from '@/hooks/mutations/creators/use-bulk-update-memberships';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import {ActionProps} from './ActionProps';

const TRANSLATION_PREFIX =
  'views.creators.components.creator-action.remove-from-my-creators';

function RemoveFromMyCreators(props: ActionProps) {
  const {onSubmitSuccess} = props;
  const {membershipQueryKey} = usePreferredCreatorGroupMemberships();
  const updateMemberships = useBulkUpdateMemberships(membershipQueryKey);

  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const onClickSubmit = async () => {
    await updateMemberships.mutateAsync({
      memberships: [...selectedCreators.values()].map((membership) => ({
        ...membership,
        removedAt: dayjs().toISOString(),
      })),
    });
    listCreatorsActions.deselectAllCreators();
    onSubmitSuccess();
  };

  const resourceLabel =
    selectedCreators.size === 1
      ? [...selectedCreators.values()][0].profile.displayName
      : translate(`${TRANSLATION_PREFIX}.bulkLabel`, {
          count: selectedCreators.size,
        });

  return (
    <Box sx={{display: 'flex', flexFlow: 'column', gap: 1}}>
      <Headline size="md">
        {translate(`${TRANSLATION_PREFIX}.title`, {resourceLabel})}
      </Headline>
      <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
        {translate(`${TRANSLATION_PREFIX}.body`)}
      </Body>
      <Box sx={{display: 'flex', mt: 2, gap: 1, justifyContent: 'center'}}>
        <Button
          appearance="neutral"
          mode="tinted"
          size="large"
          onClick={() => {
            onSubmitSuccess();
          }}
        >
          {translate(`${TRANSLATION_PREFIX}.cancel`)}
        </Button>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          onClick={onClickSubmit}
        >
          {translate(`${TRANSLATION_PREFIX}.remove`)}
        </Button>
      </Box>
    </Box>
  );
}

export default RemoveFromMyCreators;
