import {SortingState, Updater} from '@tanstack/table-core';
import clsx from 'clsx';
import {throttle} from 'lodash';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_RowData,
  MRT_RowVirtualizer,
} from 'material-react-table';
import React, {RefObject, useCallback, useRef} from 'react';
import {ArrowDownward} from '@mui/icons-material';
import {designSystemToken} from '@lightricks/react-design-system';
import {SearchDisplayCreator} from '@/types/models/search-creators/searchCreators';
import InfiniteLoader from '@/components/infinite-loader';
import styles from './Table.module.scss';

const END_REACHED_THRESHOLD = 100;

const styleDefinitions = {
  muiTableProps: {
    className: styles.tableContainer,
  },
  muiTableHeadProps: {
    className: styles.tableHead,
  },
  muiTableHeadRowProps: {
    className: styles.tableHeadRow,
  },
  muiTableHeadCellProps: {
    className: styles.tableHeadCell,
  },
  muiTableBodyProps: {
    className: styles.tableBody,
  },
  muiTableBodyRowProps: {
    className: styles.tableBodyRow,
    disabled: styles.tableBodyRowDisabled,
  },
  muiTableBodyCellProps: {
    className: styles.tableBodyCell,
  },
};

type TableClasses = {
  container?: string;
  paper?: string;
  footer?: string;
};

type TableProps<T extends MRT_RowData> = {
  data: T[];
  columns: MRT_ColumnDef<T>[];
  isLoadingInitial?: boolean;
  isLoading?: boolean;
  showProgressBars: boolean;
  visibleColumns: Record<string, boolean>;
  rowSelection: Record<string, boolean>;
  sorting: SortingState;
  onSorting?: (sorting: SortingState) => void;
  onRowClick?: (row: T) => void;
  isRowDisabled?: (row: T) => boolean;
  testID?: string;
  classes?: TableClasses;
  onEndReached?: () => void;
  enableVirtualization?: boolean;
  enableSorting?: boolean;
  manualSorting?: boolean;
  rowVirtualizerRef?: RefObject<MRT_RowVirtualizer>;
  tableScrollTopKey?: string;
  tableRef?: RefObject<HTMLTableElement>;
};

export function applyUpdater<T>(currentState: T, updater: Updater<T>): T {
  let newState: T;
  if (typeof updater === 'function') {
    const updateFn = updater as (old: T) => T;
    newState = updateFn(currentState);
  } else {
    newState = updater;
  }
  return newState;
}

function Table<T extends MRT_RowData>(props: TableProps<T>) {
  const {
    data,
    columns,
    rowSelection,
    isLoadingInitial,
    isLoading,
    showProgressBars,
    visibleColumns,
    sorting,
    onSorting,
    onRowClick,
    isRowDisabled,
    testID = 'table',
    classes = {},
    onEndReached,
    enableVirtualization = false,
    enableSorting = false,
    manualSorting = true,
    rowVirtualizerRef,
    tableScrollTopKey = 'tableScrollTop',
    tableRef,
  } = props;

  const showLoader = !isLoadingInitial && isLoading;
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const newRowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const rowVirtualizerInstanceRef =
    rowVirtualizerRef || newRowVirtualizerInstanceRef;

  const handleOnScroll = useCallback(
    (event?: React.UIEvent<HTMLElement>) => {
      if (event?.target) {
        const {scrollHeight, scrollTop, clientHeight} =
          event.target as HTMLDivElement;
        if (enableVirtualization && tableScrollTopKey) {
          sessionStorage.setItem(tableScrollTopKey, scrollTop.toString());
        }
        // once the user has scrolled within a threshold of the end of the table, call onEndReached if it exists
        if (
          onEndReached &&
          scrollHeight - scrollTop - clientHeight < END_REACHED_THRESHOLD
        ) {
          onEndReached();
        }
      }
    },
    [onEndReached]
  );

  const onSortingChange = (updater: Updater<SortingState>) => {
    const newSortingState = applyUpdater(sorting, updater);
    onSorting?.(newSortingState);
  };

  const defaultOptions = {
    enableGlobalFilterModes: false,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    manualPagination: true,
    mrtTheme: (theme: any) => ({
      baseBackgroundColor: designSystemToken('semantic.bg.primary'),
      selectedRowBackgroundColor: designSystemToken('semantic.bg.tertiary'),
    }),
    muiTablePaperProps: {elevation: 0},
    icons: {
      SyncAltIcon: () =>
        enableSorting ? (
          <div className={styles.syncIcon}>
            <ArrowDownward fontSize="inherit" />
          </div>
        ) : (
          <div />
        ),
    },
  };

  const table = useMaterialReactTable({
    columns,
    data,
    getRowId: (row) => row?.id,
    state: {
      isLoading: isLoadingInitial,
      showProgressBars,
      columnVisibility: visibleColumns,
      rowSelection,
      sorting,
    },
    onSortingChange,
    manualSorting,
    layoutMode: 'grid',
    ...defaultOptions,
    ...styleDefinitions,
    muiTableFooterProps: {
      className: classes.footer,
    },
    muiTableBodyRowProps: ({row}) => {
      const {className, disabled} = styleDefinitions.muiTableBodyRowProps;
      const isDisabled = isRowDisabled?.((row as MRT_RowData).original);
      return {
        'id': row.id,
        'className': clsx(className, isDisabled && disabled),
        'aria-disabled': isDisabled,
        'onClick': () => {
          onRowClick?.((row as MRT_RowData).original);
        },
      };
    },
    muiTablePaperProps: {
      classes: {root: classes.paper},
      ...defaultOptions.muiTablePaperProps,
    },
    muiTableContainerProps: {
      classes: {root: classes.container},
      ref: tableContainerRef,
      sx: {maxHeight: '100%'},
      onScroll: throttle(handleOnScroll, 100),
    },
    muiTableProps: {
      ...styleDefinitions.muiTableProps,
      'ref': tableRef,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      'data-testid': testID,
    },
    enableRowVirtualization: enableVirtualization,
    enablePagination: !enableVirtualization,
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: enableVirtualization
      ? {
          initialOffset: Number(sessionStorage.getItem(tableScrollTopKey)) || 0,
          // initialRect is used to apply an initial element size for the virtualized area
          // if this is not passed OR height is passed as 0 (default) then on unmount and re-mount the table will be empty
          // as the virtualized area will have a height of 0 which will be deemed as infinite
          initialRect: {
            height: 1,
            width: 0,
          },
        }
      : undefined,
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <InfiniteLoader isLoading={!!showLoader} />
    </>
  );
}

export default Table;
