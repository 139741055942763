import QueryContext from '../QueryContext';
import api from '../index';

const emailPreferencesFetchers = {
  emailPreferences: (context: QueryContext) =>
    api
      .get('/dashboard_user/visible_brand_email_preferences')
      .then((response) => response.data),
};

export default emailPreferencesFetchers;
