import React from 'react';
import {Headline, Body, Button} from '@lightricks/react-design-system';
import styles from './EmptyState.module.scss';

type EmptyStateProps = {
  message: string;
  description: string;
  buttonText: string;
  buttonLink: string;
};

function EmptyState(props: EmptyStateProps) {
  const {message, description, buttonText, buttonLink} = props;
  return (
    <div className={styles.emptyStateContainer}>
      <img src="/assets/svg/creators-empty-state.svg" alt="No lists yet" />
      <Headline size="lg">{message}</Headline>
      <Body size="lg">{description}</Body>
      <Button appearance="neutral" mode="filled" size="large" href={buttonLink}>
        {buttonText}
      </Button>
    </div>
  );
}

export default EmptyState;
