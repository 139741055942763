import {TooltipItem} from 'chart.js';
import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import formattedPercentage from '@/utils/formattedPrecentage';
import getTickValues from '@/utils/getTickValues';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {SHORT_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {Post} from '@/types/post';
import PoppaysLoader from '@/components/PoppaysLoader';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import Conditional from '@/components/conditional';
import {
  ADDITIONAL_OPTIONS_BASE,
  getDefaultScaleX,
  getDefaultScaleY,
} from '@/components/creator-profile/constants/chartPresets';
import {SocialHealthChartProps} from '../../socialHealthChart';

const TRANSLATION_PREFIX =
  'components.creator-profile.identity-social-health.charts.impressions-per-follower';

function getFilteredPosts(posts: Post[]) {
  if (!posts) {
    return [];
  }

  const postsWithData = posts.filter((post, index) => {
    const createdAt = post.createdAtDay;
    const previousPost = posts[index - 1];
    const previousCreatedAt = previousPost ? previousPost.createdAtDay : null;
    const previousCachedAccountFollowerCount = previousPost
      ? previousPost.cachedAccountFollowerCount
      : null;
    const {cachedAccountFollowerCount, impressionCount} = post;

    return (
      impressionCount &&
      cachedAccountFollowerCount &&
      cachedAccountFollowerCount !== previousCachedAccountFollowerCount &&
      createdAt !== previousCreatedAt
    );
  });

  if (postsWithData.length < 2) {
    return [];
  }

  return sortBy(postsWithData, (post) => dayjs(post.createdAtDay).unix());
}

function getDataByMonth(filteredPosts: Post[]) {
  const postsByMonth = filteredPosts.reduce(
    (acc: {[key: string]: Post[]}, post) => {
      if (!acc[post.createdAtMonth]) {
        acc[post.createdAtMonth] = [];
      }

      acc[post.createdAtMonth].push(post);

      return acc;
    },
    {}
  );

  return Object.keys(postsByMonth).map((dateKey) => {
    const postsOfMonth = postsByMonth[dateKey];

    const totalMonthlyImpressions = postsOfMonth.reduce(
      (acc, post) => acc + (post.impressionCount || 0),
      0
    );
    const totalMonthlyFollowers = postsOfMonth.reduce(
      (acc, post) => acc + post.cachedAccountFollowerCount,
      0
    );

    const avgMonthlyImpressions = totalMonthlyImpressions / postsOfMonth.length;
    const avgMonthlyFollowers = totalMonthlyFollowers / postsOfMonth.length;

    const avgMonthlyImpressionsPerFollower = parseFloat(
      (avgMonthlyImpressions / avgMonthlyFollowers).toFixed(3)
    );

    return {
      month: dateKey,
      data: {
        avgMonthlyImpressionsPerFollower,
      },
    };
  });
}

function ImpressionsPerFollowerChart(props: SocialHealthChartProps) {
  const {posts, isLoadingPosts} = props;

  const filteredPosts = useMemo(() => getFilteredPosts(posts), [posts]);

  const dataByMonth = useMemo(
    () => getDataByMonth(filteredPosts),
    [filteredPosts]
  );

  const avgMonthlyImpressionsPerFollowerData = dataByMonth.map(
    ({data: {avgMonthlyImpressionsPerFollower}}) =>
      avgMonthlyImpressionsPerFollower
  );

  const months = dataByMonth.map(({month}) => month);

  const impressionTickValues = getTickValues(
    avgMonthlyImpressionsPerFollowerData
  );

  const chart = (
    <Conditional
      condition={!isLoadingPosts}
      fallback={<PoppaysLoader absolute />}
    >
      <LineChart
        datasets={[
          {
            label: translate(
              `${TRANSLATION_PREFIX}.average-impressions-to-follower-ratio`
            ),
            fill: false,
            data: avgMonthlyImpressionsPerFollowerData,
            yAxisID: 'y',
            backgroundColor: LEGACY_COLORS.BLUE,
            borderColor: LEGACY_COLORS.BLUE,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
          },
        ]}
        additionalOptions={{
          ...ADDITIONAL_OPTIONS_BASE,
          scales: {
            x: getDefaultScaleX(months, SHORT_MONTH_YEAR_FORMAT),
            y: getDefaultScaleY(
              translate(`${TRANSLATION_PREFIX}.impressions-to-follower-ratio`),
              impressionTickValues,
              (label) => formattedPercentage(label as number, '0%')
            ),
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              intersect: false,
              callbacks: {
                label(tooltipItem: TooltipItem<'line'>) {
                  const {dataset} = tooltipItem;
                  let label = dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  label += formattedPercentage(
                    tooltipItem.raw as number,
                    '0.0%'
                  );
                  return label;
                },
                title([tooltipItem]) {
                  return dayjs(months[tooltipItem.dataIndex]).format(
                    SHORT_MONTH_YEAR_FORMAT
                  );
                },
              },
            },
          },
        }}
        labels={months}
      />
    </Conditional>
  );

  return (
    <ChartLayout
      title={translate(`${TRANSLATION_PREFIX}.impressions-to-follower-ratio`)}
      chart={chart}
      showEmptyState={filteredPosts.length === 0 && !isLoadingPosts}
    />
  );
}

export default ImpressionsPerFollowerChart;
