import React, {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  useMemo,
} from 'react';

export type Network =
  | 'instagram'
  | 'tiktok_v2'
  | 'no-preference'
  | string
  | null;

export type NewCampaignFormData = {
  brandName?: string;
  budget: number;
  network: Network;
  requiredDeliverables: SerializedRequiredDeliverables;
  product: string;
  creatorType: string;
  creatorCostRange: string | null;
  creatorFollowersCount: string | null;
  sendProductToCreators: 'Yes' | 'No' | null;
  sendProductToCreatorsMethod?:
    | 'shipments'
    | 'gift_codes'
    | string
    | null
    | undefined;
  sendProductToCreatorsCashValue?: number | null | undefined;
  productFulfillment?:
    | {method: 'shipments' | 'gift_codes' | null; cashValue: number | null}
    | null
    | undefined;
} | null;

export type SerializedRequiredDeliverables = {
  storyPost?: number | null;
  storyPostNumberOfFrames?: number | null;
  feedPostPhoto?: number | null;
  reelPost?: number | null;
  tiktokVideo?: number | null;
} | null;

interface NewCampaignFormDataStateDefinition {
  formData: NewCampaignFormData;
  campaignId: string;
  isSubmitted: boolean;
}

const initialState: NewCampaignFormDataStateDefinition = {
  formData: null,
  campaignId: '',
  isSubmitted: false,
};

type Action =
  | {
      type: 'SET_NEW_CAMPAIGN_FORM_DATA';
      formData: NewCampaignFormData;
    }
  | {
      type: 'SET_NEW_CAMPAIGN_CAMPAIGN_ID';
      campaignId: string;
    }
  | {
      type: 'SET_NEW_CAMPAIGN_IS_SUBMITTED';
      isSubmitted: boolean;
    };
const newCampaignFormDataReducer = (
  state: NewCampaignFormDataStateDefinition,
  action: Action
): NewCampaignFormDataStateDefinition => {
  switch (action.type) {
    case 'SET_NEW_CAMPAIGN_FORM_DATA':
      return {
        ...state,
        formData: action.formData,
      };
    case 'SET_NEW_CAMPAIGN_CAMPAIGN_ID':
      return {
        ...state,
        campaignId: action.campaignId,
      };
    case 'SET_NEW_CAMPAIGN_IS_SUBMITTED':
      return {
        ...state,
        isSubmitted: action.isSubmitted,
      };
    default:
      return state;
  }
};

interface StoreNewCampaignFormDataContextType {
  state: NewCampaignFormDataStateDefinition;
  dispatch: React.Dispatch<Action>;
}

const NewCampaignFormDataContext = createContext<
  StoreNewCampaignFormDataContextType | undefined
>(undefined);

export const useNewCampaignFormDataStore =
  (): StoreNewCampaignFormDataContextType => {
    const context = useContext(NewCampaignFormDataContext);
    if (!context) {
      throw new Error(
        'useNewCampaignFormDataStore must be used within a NewCampaignFormDataProvider'
      );
    }
    return context;
  };

export const getNewCampaignFormData = (
  state: NewCampaignFormDataStateDefinition
) => state.formData;

export const getNewCampaignCampaignId = (
  state: NewCampaignFormDataStateDefinition
) => state.campaignId;

export const getNewCampaignIsSubmitted = (
  state: NewCampaignFormDataStateDefinition
) => state.isSubmitted;

interface StoreProviderProps {
  children: ReactNode;
}

export function NewCampaignFormDataProvider({children}: StoreProviderProps) {
  const [state, dispatch] = useReducer(
    newCampaignFormDataReducer,
    initialState
  );

  const contextValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return (
    <NewCampaignFormDataContext.Provider value={contextValue}>
      {children}
    </NewCampaignFormDataContext.Provider>
  );
}
