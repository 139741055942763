import {isEqual} from 'lodash';

interface Dictionary {
  [key: string]: any;
}

function buildDiffObject(
  oldObj: Dictionary,
  newObj: Dictionary,
  parentKey = '',
  level = 2,
  currentLevel = 1
): Dictionary {
  let diffs: Dictionary = {};

  // Traverse newObj to find added or changed keys
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in newObj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;

    if (!(key in oldObj)) {
      diffs[currentKey] = newObj[key];
    } else if (
      typeof newObj[key] === 'object' &&
      typeof oldObj[key] === 'object' &&
      currentLevel < level
    ) {
      const nestedDiff = buildDiffObject(
        oldObj[key],
        newObj[key],
        currentKey,
        level,
        currentLevel + 1
      );
      if (Object.keys(nestedDiff).length > 0) {
        diffs = {...diffs, ...nestedDiff};
      }
    } else if (!isEqual(newObj[key], oldObj[key])) {
      diffs[currentKey] = newObj[key];
    }
  }

  // Traverse oldObj to find removed keys
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in oldObj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    if (!(key in newObj)) {
      diffs[currentKey] = 'REMOVED';
    }
  }

  return diffs;
}

type FlattenedObject = {[key: string]: any};

function flattenObject(
  obj: {[key: string]: any},
  parentKey = '',
  sep = '.',
  level = 2,
  currentLevel = 1
): FlattenedObject {
  let result: FlattenedObject = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}${sep}${key}` : key;
      const value = obj[key];

      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        currentLevel < level &&
        !key.includes('.')
      ) {
        const nestedResult = flattenObject(
          value,
          newKey,
          sep,
          level,
          currentLevel + 1
        );
        result = {...result, ...nestedResult};
      } else {
        result[newKey] = value;
      }
    }
  }

  return result;
}

function getObjectDiff(oldObj: Dictionary, newObj: Dictionary): Dictionary {
  return flattenObject(buildDiffObject(oldObj, newObj));
}

export default getObjectDiff;
