import React from 'react';
import {Headline, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import BreadcrumbLabel from '@/components/breadcrumb-label';
import Link from '@/components/link';
import styles from './BriefHeader.module.scss';

const TRANSLATION_PREFIX = 'views.campaigns.components.brief-header';

function BriefHeader({name}: {name?: string}) {
  return (
    <div className={styles.container}>
      <Link to="/campaigns" className={styles.linkContainer}>
        <Icon
          size="medium"
          appearance="disabled"
          name="Actions-Arrow-Back-Small"
        />
        <BreadcrumbLabel
          testID="breadcrumb-label--generic"
          label={translate(`${TRANSLATION_PREFIX}.breadcrumbs.my-campaigns`)}
          className={styles.breadcrumbLabelLink}
        />
      </Link>
      <Headline size="xl">
        {name || translate(`${TRANSLATION_PREFIX}.placeholder-campaign-name`)}
      </Headline>
    </div>
  );
}

export default BriefHeader;
