import React from 'react';
import {Banner as BannerDS} from '@lightricks/react-design-system';
import {getBannerData, useBannerStore} from '@/contexts/BannerContext';

function Banner() {
  const {state, dispatch} = useBannerStore();

  const {showBanner, title, message, severity, mode, className, icon} =
    getBannerData(state);

  const handleClose = () => {
    dispatch({type: 'CLEAR_BANNER'});
  };

  return (
    <BannerDS
      isOpen={showBanner}
      title={title}
      mode={mode}
      severity={severity}
      onActionClick={handleClose}
      className={className}
      icon={icon}
    >
      {message}
    </BannerDS>
  );
}

export default Banner;
