import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {Button} from '@lightricks/react-design-system';
import {ButtonProps} from '@lightricks/react-design-system/dist/components/button/ButtonProps';
import {ActionItem, ActionsBar} from './ActionsBar';
import AvatarsStack from './AvatarsStack';
import styles from './CreatorsActionsBar.module.scss';

export type CreatorsActionsProps = {
  avatarUrls: string[];
  actionItems: ActionItem[];
  closeButtonProps: ButtonProps;
  closeButtonText: string;
  closeButtonOnClick: () => void;
  onPresentedOrDismissed?: (
    presentedOrDismissed: 'presented' | 'dismissed',
    name: string
  ) => void;
};

function CreatorsActionsBar({
  avatarUrls,
  actionItems,
  closeButtonProps,
  closeButtonText,
  closeButtonOnClick,
  onPresentedOrDismissed,
}: CreatorsActionsProps) {
  useEffect(() => {
    onPresentedOrDismissed?.('presented', 'selected-creators-action-bar');
    return () => {
      onPresentedOrDismissed?.('dismissed', 'selected-creators-action-bar');
    };
  }, []);
  return (
    <div className={styles.creatorsActionsBar}>
      <Box sx={{width: '100%'}}>
        <AvatarsStack avatarUrls={avatarUrls} />
      </Box>
      <ActionsBar actionItems={actionItems} />
      <div className={styles.closeButtonContainer}>
        <Button {...closeButtonProps} onClick={closeButtonOnClick}>
          {closeButtonText}
        </Button>
      </div>
    </div>
  );
}

export default CreatorsActionsBar;
