import {BaseFilterOption} from '@/types/models/search-creators/filter';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer.filters';

export const defaultAgeFilterOptions: BaseFilterOption[] = [
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.age-options.13-17`,
    id: '13-17',
    value: {from_age: 13, to_age: 17},
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.age-options.18-24`,
    id: '18-24',
    value: {from_age: 18, to_age: 24},
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.age-options.25-34`,
    id: '25-34',
    value: {from_age: 25, to_age: 34},
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.age-options.35-44`,
    id: '35-44',
    value: {from_age: 35, to_age: 44},
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.age-options.45-64`,
    id: '45-64',
    value: {from_age: 45, to_age: 64},
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.age-options.65-plus`,
    id: '65+',
    value: {from_age: 65},
  },
];

export const defaultGenderFilterOptions: BaseFilterOption[] = [
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.gender-options.all`,
    id: 'all',
    value: null,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.gender-options.female`,
    id: 'female',
    value: 'female',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.gender-options.male`,
    id: 'male',
    value: 'male',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.gender-options.other`,
    id: 'other',
    value: 'other',
  },
];

export const engagementRateFilterOptions: BaseFilterOption[] = [
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.1`,
    id: '1%',
    value: 0.01,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.2`,
    id: '2%',
    value: 0.02,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.3`,
    id: '3%',
    value: 0.03,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.4`,
    id: '4%',
    value: 0.04,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.5`,
    id: '5%',
    value: 0.05,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.6`,
    id: '6%',
    value: 0.06,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.7`,
    id: '7%',
    value: 0.07,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.8`,
    id: '8%',
    value: 0.08,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.9`,
    id: '9%',
    value: 0.09,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.10`,
    id: '10%',
    value: 0.1,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.11`,
    id: '11%',
    value: 0.11,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.12`,
    id: '12%',
    value: 0.12,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.13`,
    id: '13%',
    value: 0.13,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.14`,
    id: '14%',
    value: 0.14,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.15`,
    id: '15%',
    value: 0.15,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.16`,
    id: '16%',
    value: 0.16,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.17`,
    id: '17%',
    value: 0.17,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.18`,
    id: '18%',
    value: 0.18,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.19`,
    id: '19%',
    value: 0.19,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.engage-rate-options.20`,
    id: '20%',
    value: 0.2,
  },
];

export const defaultPlatformFilterOptions: BaseFilterOption[] = [
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-all`,
    id: 'all',
    value: null,
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-instagram`,
    id: 'instagram',
    value: 'instagram',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-tiktok`,
    id: 'tiktok',
    value: 'tiktok',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-youtube`,
    id: 'youtube',
    value: 'youtube',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-facebook`,
    id: 'facebook',
    value: 'facebook',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-pinterest`,
    id: 'pinterest',
    value: 'pinterest',
  },
  {
    localeLabelKey: `${TRANSLATION_PREFIX}.platform-twitter`,
    id: 'twitter',
    value: 'twitter',
  },
];
