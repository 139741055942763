const CREATOR_VETTING_REPORT_TEST_IDS = {
  MAIN: 'creator-vetting-report',
  MAIN__POST_MODAL: 'creator-vetting-report__post-modal',
  PROFILE_VETTING_INFO: 'profile-vetting-info',
  PROFILE_VETTING_TRACKING: 'profile-vetting-tracking',
  SCANNED_PLATFORMS: 'scanned-platforms',
  PROFILE_LINK: 'vetting-report-profile-link',
  CONTENT_SCANNED: 'content-scanned',
  CONTENT_SCANNED__HEADER: 'content-scanned__header',
  CONTENT_SCANNED__ITEMS: 'content-scanned__items',
  FLAGGED_CONTENT_SUMMARY: 'flagged-content-summary',
  FLAGGED_CONTENT_SUMMARY__HEADER: 'flagged-content-summary__header',
  FLAGGED_CONTENT_SUMMARY__FLAGGED_ITEMS:
    'flagged-content-summary__flagged-items',
  FLAGGED_CONTENT_SUMMARY__CLEARED_ITEMS:
    'flagged-content-summary__cleared-items',
  VETTING_SUMMARY: 'vetting-summary',
  BACKGROUND_CHECK: 'background-check',
  BACKGROUND_CHECK__ITEMS: 'background-check__items',
  FLAGGED_CONTENT: 'flagged-content',
  FLAGGED_CONTENT__HEADER: 'flagged-content__header',
  FLAGGED_CONTENT__POSTS: 'flagged-content__posts',
  FAILED_CONTENT__POSTS: 'failed-content__posts',
  PROFILE_VETTING_ACTIONS: 'profile-vetting-actions',
  FILTERS__DATE_RANGE: 'filters__date-range',
  FILTERS__SEVERITY: 'filters__severity',
};

export default CREATOR_VETTING_REPORT_TEST_IDS;
