import {ApiClient, QueryArg} from 'jsonapi-react';
import {snakeCase} from 'lodash';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import Fortress from '@/services/fortress/Fortress';
import Sentry from '@/services/sentry/Sentry';
import queryClient from '@/utils/reactQueryClient';
import Storage from '@/utils/wrappers/Storage';
import {getEnv} from '@/config/environment';
import {BASE_CONFIG} from '@/config/http';
import {ROUTES} from '@/config/routesConstants';
import schema from '../schema';
import mapKeysToCase from './mapKeysToCase';
import {STORAGE_TOKEN_KEY, tokenSerializer, updateToken} from './token';

export class JsonApiError extends Error {
  status: number | null;

  code: number | null;

  constructor(error: any) {
    super(
      typeof error === 'object'
        ? error.message || error.detail || error.title
        : error
    );
    this.name = 'JsonApiError';
    this.status = typeof error === 'object' ? error.status : null;
    this.code = typeof error === 'object' ? error.status : null;

    Object.setPrototypeOf(this, JsonApiError.prototype);
  }
}

async function fetchWithToken(path: string, config: RequestInit) {
  const ltxAuthTokenObject: any = {};
  const ltxAuthToken = await Fortress.getAccessToken();
  if (ltxAuthToken) {
    ltxAuthTokenObject['x-lightricks-auth-token'] = ltxAuthToken;
  }
  const browserSpecificDeltaBaseAttributesHeader: any = {};

  if (AnalyticsService.getProvider('delta')) {
    browserSpecificDeltaBaseAttributesHeader['browser-delta-attributes-json'] =
      JSON.stringify(AnalyticsService.getBrowserBaseAttributes());
  }

  const newConfig = {
    ...config,
    headers: {
      platform: 'React-AWA',
      ...config.headers,
      ...BASE_CONFIG.headers,
      ...(await tokenSerializer()),
      ...ltxAuthTokenObject,
      ...browserSpecificDeltaBaseAttributesHeader,
    },
  };

  return fetchWithStatusCheck(path, newConfig);
}

const SAMPLING_RATE = 0.1; // 10% for both 401 and 403 errors

// Implementing a randomness-based sampling strategy to selectively log 401/403 errors.
// This approach helps in reducing the volume of logged errors to Sentry, preventing
// the cluttering of logs with frequent, expected unauthorized access errors (like expired sessions),
// while still providing a representative sample for monitoring and analysis purposes.
function logErrorWithSampling(status: number, errorDetails: RequestInit): void {
  if (Math.random() < SAMPLING_RATE) {
    Sentry.captureException(
      new JsonApiError({
        status,
        message: `Received ${status} from server`,
      }),
      {...errorDetails, body: undefined, headers: undefined}
    );
  }
}
// This is so the HTTP status code can be used in application logic. The promise returned by this function will be rejected
// if the HTTP status code is 400 or higher.
// See https://github.com/aribouius/jsonapi-react/blob/025e7f64eb106de2ab1d41cb9812e5cc1a4b294a/src/client.js#L377
async function fetchWithStatusCheck(path: string, config: RequestInit) {
  const r = await fetch(path, config);

  const deviseToken = r.headers.get('Access-Token');
  await updateToken(deviseToken);

  if (r.status >= 400) {
    const errorMessageBody = await r.text();
    // Extract error message from JSON response or plain text response
    let errorMessage;
    try {
      const errorMessageJson = JSON.parse(errorMessageBody);
      errorMessage = errorMessageJson.errors[0].detail;
    } catch (e) {
      errorMessage = errorMessageBody;
    }
    if (r.status === 401) {
      logErrorWithSampling(r.status, r);

      // clear cookies + token and force redirect to login page
      Fortress.clearCookieTokens();
      await Storage.removeItem(STORAGE_TOKEN_KEY);
      queryClient.clear();

      window.location.href = `${window.location.origin}${ROUTES.LOGIN}`;
    }
    if (r.status === 403) {
      logErrorWithSampling(r.status, r);

      // force redirect to 404 page
      // window.location.href = `${window.location.origin}${ROUTES.NOT_FOUND}`;
    }
    // Pass error message and status to JsonApiError, so it can be used in application logic
    throw new JsonApiError({status: r.status, message: errorMessage});
  }
  return r;
}

const jsonApiClient = new ApiClient({
  url: getEnv().VITE_API_HOST || 'http://api.test.popularpays.com:3000',
  schema,
  fetch: fetchWithToken,
});

export async function jsonApiFetch(queryArg: QueryArg, config = {}) {
  const results = await jsonApiClient.fetch(queryArg, config);
  if (results?.error) {
    throw new JsonApiError(results.error);
  }
  return structuredClone(mapKeysToCase(results));
}

export async function jsonApiMutate(
  queryArg: QueryArg,
  data: any,
  config = {}
) {
  const serializedData: any = mapKeysToCase(data, snakeCase);
  const results = await jsonApiClient.mutate(queryArg, serializedData, config);
  if (results?.error) {
    throw new JsonApiError(results.error);
  }
  return structuredClone(mapKeysToCase(results));
}

export async function jsonApiDelete(queryArg: QueryArg, config = {}) {
  const results = await jsonApiClient.delete(queryArg, config);
  if (results?.error) {
    throw new JsonApiError(results.error);
  }
  return structuredClone(mapKeysToCase(results));
}

export default jsonApiClient;
