import {useMutation} from '@tanstack/react-query';
import creatorsUpdaters from '@/api/updaters/creators';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useBrandId from '@/hooks/use-brand-id';

function useInviteCreatorsToCampaign() {
  const brandId = useBrandId();
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';

  const mutation = useMutation({
    mutationKey: ['creators', 'addToCampaign'],
    mutationFn: creatorsUpdaters.addToCampaign,
  });

  const invite = ({
    creatorIds,
    campaignId,
  }: {
    creatorIds: string[];
    campaignId: string;
  }) => {
    return mutation.mutateAsync({
      creatorGroupId,
      creatorIds,
      campaignId,
    });
  };

  return {invite};
}

export default useInviteCreatorsToCampaign;
