import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import styles from './ProfileVettingSummary.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-summary';

interface ProfileVettingSummaryProps {
  summary: string;
}

const BULLETED_SUMMARY_INDICATOR = 'analysis shows:';
const SEPARATOR = '\n- ';

function SummaryBullets({bullets}: {bullets: string[]}) {
  return (
    <ul className={styles.summaryBullets}>
      {bullets.map((bullet) => (
        <li key={bullet}>
          <Body size="md">{bullet}</Body>
        </li>
      ))}
    </ul>
  );
}

/**
 * Extracts the bullets from the summary string and returns them as an array.
 * the first element is a template title ("<creatorName>'s analysis shows:") which we ignore.
 * @param summary
 * @returns {string[]}
 */
function getBullets(summary: string): string[] {
  return summary.split(SEPARATOR).slice(1);
}

function ProfileVettingSummary({summary}: ProfileVettingSummaryProps) {
  const isBulletedSummary = summary.includes(BULLETED_SUMMARY_INDICATOR);
  const nonBulletedSummary = (
    <Body
      size="md"
      className={styles.nonBulletedSummary}
      color={designSystemToken('semantic.fg.primary')}
    >
      {summary}
    </Body>
  );

  return (
    <div
      className={styles.profileVettingSummaryContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.VETTING_SUMMARY}
    >
      <Headline
        size="sm"
        className={styles.title}
        color={designSystemToken('semantic.fg.primary')}
      >
        {translate(`${TRANSLATION_PREFIX}.vetting-summary`)}
      </Headline>
      <Conditional condition={isBulletedSummary} fallback={nonBulletedSummary}>
        <SummaryBullets bullets={getBullets(summary)} />
      </Conditional>
    </div>
  );
}

export default ProfileVettingSummary;
