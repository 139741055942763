import capsuleAiFetchers from '@/api/fetchers/capsuleAi';
import useDataQuery from '@/hooks/use-data-query';

function useImagesCountQuery(brandId: string, dashboardUserId: string) {
  const response: any = useDataQuery({
    queryKey: ['imageCount'],
    queryFn: capsuleAiFetchers.imagesCount,
    enabled: !!brandId,
    cacheTime: 0,
    staleTime: 0,
    meta: {
      brandId,
      dashboardUserId,
    },
  });

  return {...response};
}

export default useImagesCountQuery;
