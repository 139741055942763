import {set} from 'lodash/fp';
import React, {useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Button, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {Question as QuestionType} from '@/types/campaign';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import {IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY} from '@/views/campaigns/brief/constants';
import styles from './QuestionsForCreator.module.scss';

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.additional-information.questions-for-creator';

type QuestionsForCreatorProps = {
  testID?: string;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number
  ) => void;
  questions: QuestionType[];
  editable?: boolean;
};

function QuestionsForCreator({
  testID = 'questions-for-creator',
  updateBriefForm,
  questions,
  editable,
}: QuestionsForCreatorProps) {
  const [newQuestion, setNewQuestion] = useState<QuestionType | null>(null);
  const [isTypingNewQuestion, setIsTypingNewQuestion] = useState(false);

  const handleRemoveExistingQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, qIndex) => qIndex !== index);
    updateBriefForm?.(
      'questions',
      updatedQuestions,
      'questions',
      IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
    );
  };

  const handleBlurExistingQuestion = (
    question: QuestionType,
    index: number,
    newBody: string
  ) => {
    if (newBody.trim() === '') {
      handleRemoveExistingQuestion(index);
    } else if (newBody !== question.body) {
      const updatedQuestions = set(`${index}.body`, newBody, questions);
      updateBriefForm?.(
        'questions',
        updatedQuestions,
        'questions',
        IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
      );
    }
  };

  const handleBlurNewQuestion = (newBody: string) => {
    if (newBody.trim() !== '') {
      const updatedQuestions = [
        ...questions,
        {id: newQuestion!.id, body: newBody},
      ];
      updateBriefForm?.(
        'questions',
        updatedQuestions,
        'questions',
        IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
      );
      setNewQuestion(null);
    }
  };

  const handleAddButtonClick = () => {
    if (isTypingNewQuestion || !newQuestion) {
      setNewQuestion({id: uuid(), body: ''});
      setIsTypingNewQuestion(false);
    }
  };

  const handleNewQuestionChange = (value: string) => {
    setIsTypingNewQuestion(value.trim() !== '');
  };

  return (
    <div className={styles.container} data-testid={testID}>
      <label className={styles.title}>
        {translate(`${TRANSLATION_PREFIX}.title`)}
      </label>
      <div className={styles.questions}>
        {questions.map((question, index) => (
          <BriefFieldInput
            testID={`${testID}--question`}
            key={question.id}
            disabled={!editable}
            value={question.body}
            onRemove={() => handleRemoveExistingQuestion(index)}
            onBlur={({target: {value: newBody}}) =>
              handleBlurExistingQuestion(question, index, newBody)
            }
            placeholder={translate(
              `${TRANSLATION_PREFIX}.question-placeholder`
            )}
          />
        ))}

        {newQuestion ? (
          <BriefFieldInput
            testID={`${testID}--new-question`}
            key={newQuestion.id}
            value={newQuestion.body}
            disabled={!editable}
            onRemove={() => setNewQuestion(null)}
            onBlur={({target: {value: newBody}}) =>
              handleBlurNewQuestion(newBody)
            }
            placeholder={translate(
              `${TRANSLATION_PREFIX}.question-placeholder`
            )}
            onInputChange={(value) => handleNewQuestionChange(value)}
            autoFocus
          />
        ) : null}
      </div>

      <Button
        testID={`${testID}--add-question-button`}
        appearance="neutral"
        mode="tinted"
        size="small"
        style={{width: 'fit-content', padding: '8px 12px 8px 6px'}}
        disabled={(newQuestion !== null && !isTypingNewQuestion) || !editable}
        onClick={() => handleAddButtonClick()}
      >
        <div className={styles.addButton}>
          <Icon
            appearance={
              (newQuestion && !isTypingNewQuestion) || !editable
                ? 'disabled'
                : 'neutral'
            }
            size="medium"
            name="Actions-Add-Small1"
          />
          <span
            className={`${styles.label} ${newQuestion ? styles.active : ''}`}
          >
            {translate(`${TRANSLATION_PREFIX}.add-question`)}
          </span>
        </div>
      </Button>
    </div>
  );
}

export default QuestionsForCreator;
