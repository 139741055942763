import React, {useCallback} from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import styles from './IncrementableField.module.scss';

export type IncrementableFieldProps = {
  testID?: string;
  value: number;
  min?: number;
  max?: number;
  label?: string | null;
  onChange: (value: number) => void;
  disabled?: boolean;
  hideButtons?: boolean;
};

const incrementableFieldButtonProps = {
  appearance: 'neutral',
  mode: 'plain',
  size: 'small',
} as const;

function IncrementableField(props: IncrementableFieldProps) {
  const {
    testID = 'incrementable-field',
    value,
    min = 0,
    max = Number.MAX_VALUE,
    label,
    onChange,
    disabled,
    hideButtons,
  } = props;

  const handleDecrement = useCallback(() => {
    if (value > min) {
      onChange(value - 1);
    }
  }, [value, min, onChange]);

  const handleIncrement = useCallback(() => {
    if (value < max) {
      onChange(value + 1);
    }
  }, [value, max, onChange]);

  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.controls}>
        <Button
          testID={`${testID}--button-decrement`}
          onClick={handleDecrement}
          className={`${styles.button} ${styles.decrement}`}
          disabled={disabled}
          aria-label="decrement"
          style={{visibility: hideButtons ? 'hidden' : 'visible'}}
          {...incrementableFieldButtonProps}
        >
          <DecrementSVG />
        </Button>
        <span data-testid={`${testID}--value`} className={styles.value}>
          {value}
        </span>
        <Button
          testID={`${testID}--button-increment`}
          onClick={handleIncrement}
          className={`${styles.button} ${styles.increment}`}
          disabled={disabled}
          aria-label="increment"
          style={{visibility: hideButtons ? 'hidden' : 'visible'}}
          {...incrementableFieldButtonProps}
        >
          <IncrementSVG />
        </Button>
      </div>
      <label className={styles.label}>{label}</label>
    </div>
  );
}

export default IncrementableField;

function DecrementSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="5.14286"
        y="11.1431"
        width="13.7143"
        height="1.71429"
        rx="0.857143"
        fill="#161719"
      />
    </svg>
  );
}

function IncrementSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.8571 6.00021C12.8571 5.52682 12.4734 5.14307 12 5.14307C11.5266 5.14307 11.1429 5.52682 11.1429 6.00021V11.1431H6C5.52662 11.1431 5.14286 11.5268 5.14286 12.0002C5.14286 12.4736 5.52662 12.8574 6 12.8574H11.1429V18.0002C11.1429 18.4736 11.5266 18.8574 12 18.8574C12.4734 18.8574 12.8571 18.4736 12.8571 18.0002V12.8574H18C18.4734 12.8574 18.8571 12.4736 18.8571 12.0002C18.8571 11.5268 18.4734 11.1431 18 11.1431H12.8571V6.00021Z"
        fill="#161719"
      />
    </svg>
  );
}
