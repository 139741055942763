import React, {useMemo} from 'react';
import {
  Link as LinkReactRouterDOM,
  LinkProps as LinkPropsReactRouterDOM,
} from 'react-router-dom';
import getUrlWithBrandId from '@/utils/getUrlWithBrandId';
import useBrandId from '@/hooks/use-brand-id';

export type LinkProps = {
  testID?: string;
  keepBrandIdIfPresent?: boolean;
} & LinkPropsReactRouterDOM;

function Link(props: LinkProps) {
  const {testID = 'link', to, keepBrandIdIfPresent = true, ...rest} = props;
  const brandId = useBrandId();

  const finalizeTo = useMemo(() => {
    return to && keepBrandIdIfPresent ? getUrlWithBrandId(String(to)) : to;
  }, [to, keepBrandIdIfPresent, brandId]);

  return <LinkReactRouterDOM {...rest} to={finalizeTo} data-testid={testID} />;
}

export default Link;
