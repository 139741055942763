import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Tooltip,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {HealthEvaluation} from '@/types/healthEvaluation';
import styles from './Rating.module.scss';

const TRANSLATION_PREFIX =
  'components.social-network-identity-summary.health-scores';

function Rating({healthEvaluation}: {healthEvaluation: HealthEvaluation}) {
  const renderTooltip = () => {
    return (
      <div className={styles.tooltip}>
        {healthEvaluation.scores.map((score) => (
          <Body
            key={score.id}
            size="sm"
            className={`${styles.score} ${styles[score.level]}`}
            color={designSystemToken('semantic.fg.white')}
          >
            {translate(`${TRANSLATION_PREFIX}.${score.message}`)}
          </Body>
        ))}
      </div>
    );
  };
  return (
    <div className={styles.rating}>
      <Tooltip title={renderTooltip()} type="dark" placement="bottom" arrow>
        <div>
          <Headline
            size="xl"
            className={`${styles.title} ${styles[healthEvaluation.rating]}`}
            color={designSystemToken('semantic.fg.neutral')}
          >
            {translate(`${healthEvaluation.ratingStringKey}`)}
          </Headline>
          <div>
            <Body
              size="md"
              className={styles.subtitle}
              color={designSystemToken('semantic.fg.neutral')}
            >
              {translate(`${healthEvaluation.ratingStringKey}-sub`)}
            </Body>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default Rating;
