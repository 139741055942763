import numeral from 'numeral';

export const formatNumber = (value: number | undefined): string => {
  if (value === undefined || value === null) return '';
  return value > 999
    ? numeral(value).format('0.0a')
    : numeral(value).format('0,0');
};

export const formatEngagementRate = (value: number | undefined): string => {
  if (value === undefined || value === null) return '';
  return `${numeral(value * 100).format('0.0')}%`;
};
