import axios from '../../utils/axios';
import {
  getSerializedToken,
  getSessionDataFromHeaders,
  Token,
} from '../../utils/token';

const REGISTER_URL = '/agencies';
const LOGIN_URL = '/agents/sign_in';
const CHECK_BRAND_NAME_OR_EMAIL_EXIST_URL =
  '/auth/agents/brand_name_or_email_exists';
const CHECK_EMAIL_EXIST_URL = '/auth/agents/email_exists';

type RegisterFormData = {
  allow_edit_creator_bid: boolean;
  allow_internal_publication: boolean;
  auth_logo_url: string | null;
  email: string;
  is_whitelabeled: boolean;
  jwtToken: string;
  firstName: string;
  lastName: string;
  logo_url: string | null;
  name: string;
  isTermsChecked: boolean;
  requestedPlanId: string;
};

type LoginFormData = {
  email: string;
  password: string;
};

const authRequests = {
  login: async (loginFormData: LoginFormData) => {
    const data = {
      agent: {
        email: loginFormData.email,
        password: loginFormData.password,
      },
    };

    const loginResponse = await axios.post(LOGIN_URL, data);
    return getSessionDataFromHeaders(loginResponse.headers);
  },
  register: async (registerFormData: RegisterFormData) => {
    const data = {
      attributes: {
        ...registerFormData,
        allow_edit_creator_bid:
          registerFormData.allow_edit_creator_bid || false,
        allow_internal_publication:
          registerFormData.allow_internal_publication || false,
        auth_logo_url: registerFormData.auth_logo_url || null,
        is_whitelabeled: registerFormData.is_whitelabeled || false,
        logo_url: registerFormData.logo_url || null,
      },
      type: 'agencies',
    };

    const registerResponse = await axios.post(REGISTER_URL, data);
    return getSessionDataFromHeaders(registerResponse.headers);
  },
  authenticateToken: async (
    token: Token,
    authenticationEndPoint = '/dashboard_user'
  ) => {
    const URL = authenticationEndPoint;
    try {
      const response = await axios.get(URL, {
        headers: {...getSerializedToken(token)},
      });
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return false;
      }
      throw error;
    }
    return true;
  },
  checkBrandNameOrEmailExists: async (
    email: string,
    name: string
  ): Promise<{brand_name_exists: boolean; email_address_exists: boolean}> => {
    const URL = `${CHECK_BRAND_NAME_OR_EMAIL_EXIST_URL}?email=${encodeURIComponent(
      email
    )}&name=${encodeURIComponent(name)}`;
    const checkEmailExistsResponse = await axios.get(URL);
    return checkEmailExistsResponse.data;
  },
  checkEmailExists: async (email: string): Promise<boolean> => {
    const URL = `${CHECK_EMAIL_EXIST_URL}?email=${encodeURIComponent(email)}`;
    const checkEmailExistsResponse = await axios.get(URL);
    const {email_address_exists} = checkEmailExistsResponse.data;
    return email_address_exists;
  },
};

export default authRequests;
