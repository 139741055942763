import React, {useState} from 'react';
import Dropzone, {
  Accept,
  DropEvent,
  ErrorCode,
  FileRejection,
} from 'react-dropzone';
import formatBytes from '@/utils/formatBytes';
import translate from '@/utils/translate';
import SpinningLoader from '@/components/spinning-loader';
import styles from './FileSelector.module.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
type FileType = 'images' | 'videos' | 'documents' | 'audio';

function convertToFileTypes(acceptTypes?: FileType[]): Accept {
  const FILE_TYPES_MAP = {
    images: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
      'image/svg+xml': ['.svg'],
    },
    // Support for these types can be added in the future if needed
    videos: {},
    documents: {},
    audio: {},
  };

  return (acceptTypes || []).reduce((acc, type) => {
    if (FILE_TYPES_MAP[type]) {
      return {...acc, ...FILE_TYPES_MAP[type]};
    }
    return acc;
  }, {} as Accept);
}

type FileSelectorProps = {
  testID?: string;
  title?: string;
  description?: string;
  onFilesSelected: (
    acceptedFiles: File[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => Promise<void>;
  PreviewIcon: React.ReactNode;
  maxFiles?: number;
  multiple?: boolean;
  acceptTypes?: FileType[];
  maxSize?: number;
  hasError?: boolean;
  disabled?: boolean;
};

function FileSelector(props: FileSelectorProps) {
  const {
    testID = 'file-selector',
    title,
    description,
    onFilesSelected,
    PreviewIcon,
    maxFiles,
    multiple = false,
    acceptTypes,
    maxSize,
    hasError = false,
    disabled = false,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    setIsUploading(true);
    setErrorMessage(null);
    if (acceptedFiles.length > 0) {
      await onFilesSelected(acceptedFiles, fileRejections, event);
    }
    if (fileRejections.length > 0) {
      fileRejections.forEach(({errors}) => {
        errors.forEach((error) => {
          if (error.code === ErrorCode.FileInvalidType) {
            setErrorMessage(
              translate('components.file-selector.invalid-file-type', {
                types: acceptTypes?.join(' or '),
              })
            );
          } else if (error.code === ErrorCode.FileTooLarge) {
            setErrorMessage(
              translate('components.file-selector.invalid-file-size', {
                maxSize: maxSize ? formatBytes(maxSize) : '',
              })
            );
          }
        });
      });
    }
    setIsUploading(false);
  };

  return (
    <div className={styles.container}>
      <div
        data-testid={testID}
        className={`${styles.dropzoneContainer} ${
          isUploading || disabled ? styles.disabled : ''
        } ${errorMessage ? styles.failedUpload : ''}`}
      >
        <Dropzone
          disabled={isUploading || disabled}
          onDrop={onDrop}
          maxFiles={maxFiles}
          multiple={multiple}
          maxSize={maxSize}
          accept={convertToFileTypes(acceptTypes)}
        >
          {({getRootProps, getInputProps}) => (
            <section className={styles.dropzone}>
              <div {...getRootProps()} className={styles.dropzoneContent}>
                <input {...getInputProps()} />
                <div className={styles.row}>
                  <div
                    className={`${styles.previewContainer} ${
                      hasError && !isUploading
                        ? styles.previewContainerError
                        : ''
                    }`}
                  >
                    {isUploading ? (
                      <SpinningLoader color="black" containerHeight={28} />
                    ) : (
                      PreviewIcon
                    )}
                  </div>
                  {title || description ? (
                    <div className={styles.titleAndDescription}>
                      {title && <span className={styles.title}>{title}</span>}
                      {description && (
                        <span className={styles.subtitle}>{description}</span>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      {errorMessage ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : null}
    </div>
  );
}

export default FileSelector;
