import {useState, useEffect} from 'react';

const DEFAULT_SIZE = {screenWidth: 1024, screenHeight: 1000};

function getScreenDimensions() {
  if (typeof window !== 'undefined') {
    const {innerWidth: screenWidth, innerHeight: screenHeight} = window;
    return {
      screenWidth,
      screenHeight,
    };
  }
  return DEFAULT_SIZE;
}

function useScreenDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getScreenDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getScreenDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default useScreenDimensions;
