import React, {useCallback, useMemo} from 'react';
import {
  Body,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {
  DataSet,
  DataSetSection,
  HealthEvaluation,
} from '@/types/healthEvaluation';
import styles from './StatisticPercentilePlot.module.scss';

const TRANSLATION_PREFIX =
  'components.social-network-identity-summary.health-scores';

const PLOT_FULL_LENGTH = 200;

interface StatisticPercentilePlotProps {
  title: string;
  healthEvaluation: HealthEvaluation;
  statistic: string;
  percentile: number;
  value: number;
}

function StatisticPercentilePlot({
  title,
  healthEvaluation,
  statistic,
  percentile,
  value,
}: StatisticPercentilePlotProps) {
  const dataSet = useMemo(
    () =>
      healthEvaluation.dataSets.find(
        (elem) => elem.statistic === statistic
      ) as DataSet,
    [healthEvaluation.dataSets]
  );

  const translatePercentileToPixel = useCallback(
    (input: number) => {
      let numberOfSectionsInto = 0;

      const sectionContainingInput = dataSet.sections.find((section, i) => {
        if (!section) {
          return false;
        }
        if (section.range.includes(input)) {
          numberOfSectionsInto = i;
          return true;
        }
        return false;
      }) as DataSetSection;

      if (!sectionContainingInput) {
        return 0;
      }

      const numOfSections = dataSet.sections.length;
      const sectionStartsAtPixel =
        (numberOfSectionsInto / numOfSections) * PLOT_FULL_LENGTH;
      const percentThruSection =
        (sectionContainingInput.range.indexOf(input) + 1) /
        sectionContainingInput.range.length;
      const sectionPixelWidth = (1 / numOfSections) * PLOT_FULL_LENGTH;

      const pixelsThruSection = percentThruSection * sectionPixelWidth;

      return sectionStartsAtPixel + pixelsThruSection;
    },
    [dataSet.sections]
  );

  const meanPixel = translatePercentileToPixel(49) - 2;

  const markerPixel = translatePercentileToPixel(percentile) - 5;

  const valueAsPercentage = `${((value || 0) * 100).toFixed(2)}%`;

  const valueCommunityAverageDiffPercent = ((value || 0) - dataSet.mean) * 100;

  const valueCommunityAverageDiffPercentFormatted = () => {
    let formattedVal = valueCommunityAverageDiffPercent.toFixed(2);
    let output = translate(`${TRANSLATION_PREFIX}.community-average.above`, {
      percentage: formattedVal,
    });

    if (valueCommunityAverageDiffPercent < 0) {
      formattedVal = formattedVal.substring(1); // remove leading negative sign
      output = translate(`${TRANSLATION_PREFIX}.community-average.below`, {
        percentage: formattedVal,
      });
    }

    return output;
  };

  const sectionSizes = dataSet.sections.map((section, i, arr) => {
    return {
      section,
      length: (1 / arr.length) * PLOT_FULL_LENGTH,
    };
  });

  return (
    <div className={styles.statisticPercentilePlot}>
      <div className={styles.valueContainer}>
        <Headline size="xl" color={designSystemToken('semantic.fg.neutral')}>
          {valueAsPercentage}
        </Headline>
        <Body size="md" color={designSystemToken('semantic.fg.neutral')}>
          {title}
        </Body>
        <Body size="md" color={designSystemToken('semantic.fg.neutral')}>
          {valueCommunityAverageDiffPercentFormatted()}
        </Body>
      </div>
      <div className={styles.baselineContainer}>
        {sectionSizes.map((section) => (
          <div
            key={section.section.id}
            className={`${styles.section} ${styles[section.section.level]}`}
            style={{width: `${section.length}px`}}
          />
        ))}
        <div style={{left: `${meanPixel}px`}} className={styles.mean} />
        <div style={{left: `${markerPixel}px`}} className={styles.marker} />
      </div>
    </div>
  );
}

export default StatisticPercentilePlot;
