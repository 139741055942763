import AiContentGeneratorRoutes from '@/routes/ai-content-generator';
import CreatorProfileRoutes from '@/routes/creator-profile/creatorProfile';
import NewCampaignsRoutes from '@/routes/new-campaigns';
import PreferenceRoutes from '@/routes/preferences';
import SearchRoutes from '@/routes/search';
import Vetting from '@/routes/vetting';
import {ROUTES} from '@/config/routesConstants';
import ContentLibrary from '@/views/content-library';
import CreatorLists from '@/views/creators/lists';
import Home from '@/views/home';
import PricingPage from '@/views/pricing';
import MobileNotSupportedPlaceholder from '@/components/mobile-not-supported-placeholder';

const MAIN_ROUTES_LIST = [
  {name: 'home', path: '/', Component: Home},
  {
    name: 'ai-studio',
    path: '/ai-studio/*',
    Component: AiContentGeneratorRoutes,
  },
  {
    name: 'new-campaigns',
    path: '/new-campaigns/*',
    Component: NewCampaignsRoutes,
  },
  {
    name: 'mobile-not-supported',
    path: ROUTES.MOBILE_NOT_SUPPORTED,
    Component: MobileNotSupportedPlaceholder,
  },
  {
    name: 'pricing',
    path: '/pricing/*',
    Component: PricingPage,
  },
  {
    name: 'preferences',
    path: '/preferences/*',
    Component: PreferenceRoutes,
  },
  {
    name: 'search-creators',
    path: ROUTES.SEARCH,
    Component: SearchRoutes,
  },
  {
    name: 'list-creators',
    path: ROUTES.MY_CREATORS,
    Component: CreatorLists,
  },
  {
    name: 'creator-profile',
    path: `${ROUTES.CREATOR_PROFILE}/*`,
    Component: CreatorProfileRoutes,
  },
  {
    name: 'content-library',
    path: ROUTES.CONTENT_LIBRARY,
    Component: ContentLibrary,
  },
  {
    name: 'vetting',
    path: `${ROUTES.VETTING}/*`,
    Component: Vetting,
  },
];

export default MAIN_ROUTES_LIST;
