import React from 'react';
import {Body, designSystemToken, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import Breadcrumbs from '@/components/breadcrumbs';
import styles from './ProfileVettingBreadcrumbs.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-breadcrumbs';

function ProfileVettingBreadcrumbs() {
  return (
    <div className={styles.profileVettingBreadcrumbsContainer}>
      <Breadcrumbs
        breadcrumbs={[
          {
            label: (
              <div className={styles.breadcrumb}>
                <Icon
                  size="medium"
                  appearance="neutral"
                  name="Actions-Arrow-Back-Small"
                  color={designSystemToken('semantic.fg.secondary')}
                />
                <Body
                  size="md"
                  color={designSystemToken('semantic.fg.secondary')}
                >
                  {translate(`${TRANSLATION_PREFIX}.back-to-vetting`)}
                </Body>
              </div>
            ),
            link: `${ROUTES.VETTING}/hub`,
          },
        ]}
      />
    </div>
  );
}

export default ProfileVettingBreadcrumbs;
