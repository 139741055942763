import React, {useMemo} from 'react';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {VettingStatus} from '@/components/creator-vetting-report/constants';
import DropdownButton from '@/components/dropdown-button';
import styles from './VettingStatusButton.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.vetting-status-button';

interface VettingStatusButtonProps {
  status: string;
  onStatusChange: (status: string) => void;
}

const OPTIONS = [
  {
    id: VettingStatus.approved,
    localeLabelKey: `${TRANSLATION_PREFIX}.options.approved`,
  },
  {
    id: VettingStatus.ready_for_review,
    localeLabelKey: `${TRANSLATION_PREFIX}.options.ready-for-review`,
  },
  {
    id: VettingStatus.declined,
    localeLabelKey: `${TRANSLATION_PREFIX}.options.declined`,
  },
];

function VettingStatusButton(props: VettingStatusButtonProps) {
  const {status, onStatusChange} = props;

  const options = useMemo(() => {
    const availableOptions = OPTIONS.map((option) => ({
      id: option.id,
      label: translate(option.localeLabelKey),
    }));

    return availableOptions;
  }, [OPTIONS, status]);

  const selectedOption = options.find((option) => option.id === status);

  const getIcon = (statusName: string) => {
    return <div className={`${styles.statusIcon} ${styles[statusName]}`} />;
  };

  const renderMenuItem = (option: {id: string; label: string}) => {
    return (
      <div className={styles.menuItem}>
        {getIcon(option.id)}
        <Body size="lg">{option.label}</Body>
      </div>
    );
  };

  return (
    <DropdownButton
      label={
        selectedOption
          ? selectedOption.label
          : translate(`${TRANSLATION_PREFIX}.placeholder`)
      }
      options={options}
      selectedOptionIds={[status]}
      onChange={(option) => onStatusChange(option.id)}
      appearance="neutral"
      mode="filled"
      size="medium"
      caretColor={designSystemToken('semantic.fg.neutral-secondary')}
      menuWidth={263}
      renderMenuItem={renderMenuItem}
      startIcon={getIcon(status ?? '')}
      menuProps={{transformOrigin: {vertical: -8, horizontal: 'left'}}}
      menuItemProps={{classes: {selected: styles.selectedMenuItem}}}
    />
  );
}

export default VettingStatusButton;
