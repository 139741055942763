import {useMutation} from '@tanstack/react-query';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';

function useReplaceImageBackground({tempId}: {tempId?: string}) {
  return useMutation({
    mutationKey: ['replaceBackground', tempId],
    mutationFn: capsuleAiUpdaters.replaceBackground,
  });
}

export default useReplaceImageBackground;
