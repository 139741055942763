import {useMutation} from '@tanstack/react-query';
import campaignUpdaters from '@/api/updaters/campaign';

/**
 * @name useSubmitCampaignForReview
 * @description React hook that submits a campaign for review
 * @returns {Object} An object containing the mutation state and mutation function
 */
function useSubmitCampaignForReview({campaignId}: {campaignId: string}) {
  return useMutation({
    mutationKey: ['campaign', 'submitForReview', campaignId],
    mutationFn: campaignUpdaters.submitForReview,
    meta: {
      campaignId,
    },
  });
}

export default useSubmitCampaignForReview;
