import clsx from 'clsx';
import React from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Button, Icon, Label} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {buttonNames, screenNames} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import styles from './ListButtons.module.scss';

const TRANSLATION_PREFIX = 'components.list-drawer';

const handleClick = () => {
  listCreatorsActions.setActiveListAction('add-list');

  AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
    button_name: buttonNames.MY_CREATORS.NEW_LIST,
    screen_name: screenNames.MY_CREATORS.MY_CREATORS,
    flow_id: '',
    flow_name: '',
    triggered_flow_id: '',
    triggered_flow_name: '',
    screen_presentation_id:
      AnalyticsService.getActiveScreenPresented().screen_presentation_id,
    campaign_id: '',
    creator_id: '',
    tab: '',
  });
};

export function NewListButton({isListItem = true}: {isListItem?: boolean}) {
  return (
    <Button
      onClick={handleClick}
      className={isListItem ? styles.container : null}
      appearance="neutral"
      mode="plain"
      size="large"
    >
      <Button
        component="div"
        icon={
          <Icon name="Actions-Add-Small1" appearance="primary" size="medium" />
        }
        appearance="neutral"
        size="small"
        mode="tinted"
      />
      <Label size="md">{translate(`${TRANSLATION_PREFIX}.new-list`)}</Label>
    </Button>
  );
}

export function AllCreatorsButton({
  numberOfCreators,
}: {
  numberOfCreators: number;
}) {
  const selectedLists = useListCreatorsStore(
    useShallow((state) => state.selectedLists)
  );

  const isSelected = selectedLists.size === 0;

  return (
    <Button
      onClick={() => listCreatorsActions.deselectAllLists()}
      appearance="neutral"
      mode="plain"
      size="large"
      className={clsx(styles.container, isSelected && styles.selected)}
    >
      <div className={styles.spaceBetween}>
        <Label size="md">
          {translate(`${TRANSLATION_PREFIX}.all-creators`)}
        </Label>
        <Label className={styles.counter} size="md">
          {numberOfCreators}
        </Label>
      </div>
    </Button>
  );
}
