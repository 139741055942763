import {BriefData} from '@/types/campaign';

function removePseudoEmptyValues(briefForAgent: BriefData): BriefData {
  const correctedBriefForAgent = {...briefForAgent};
  if (correctedBriefForAgent.campaign_hashtag === '#') {
    correctedBriefForAgent.campaign_hashtag = '';
  }
  if (correctedBriefForAgent.creator_age_range === '13-') {
    correctedBriefForAgent.creator_age_range = '';
  }

  return correctedBriefForAgent;
}

export default removePseudoEmptyValues;
