import clsx from 'clsx';
import React, {useRef, useState, useEffect} from 'react';
import {Button, Headline, Label} from '@lightricks/react-design-system';
import Sentry from '@/services/sentry/Sentry';
import {
  buttonNames,
  screenNames,
  dismissedReasons,
} from '@/lib/delta/deltaConstants.js';
import {AdyenOnSubmitState} from '@/utils/adyen';
import translate from '@/utils/translate';
import subscriptionUpdaters from '@/api/updaters/subscription';
import {
  handleScreenDismissedEvent,
  handleScreenPresentedEvent,
  handleSubscriptionButtonPressedEvent,
} from '@/components/modal-subscription/AnalyticsEventsHandler';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useModalSubscriptionStore, {
  modalSubscriptionActions,
} from '@/stores/modalSubscriptionStore';
import MockAdyenForm from './MockAdyenForm';
import styles from './Payment.module.scss';
import useAdyenPaymentForm from './useAdyenPaymentForm';

const TRANSLATION_PREFIX =
  'components.modal-subscription.content-right.payment';

export type PaymentProps = {
  testID?: string;
};

function Payment(props: PaymentProps) {
  const planName = useModalSubscriptionStore((store) => store.planName);
  const {testID = 'modal-subscription-state-payment'} = props;
  const brandId = useBrandId();
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();
  const {subscription} = useSubscriptionQuery({brandId});
  const [hasPaymentSource, setHasPaymentSource] = useState(
    Boolean(subscription?.paymentSource)
  );

  const onSubmit = async (state: AdyenOnSubmitState) => {
    await addCreditCard(state);
    await createSubscription();
  };

  const addCreditCard = async (state: AdyenOnSubmitState) => {
    setLoading(true);
    try {
      const card = state.data.paymentMethod;
      const redirectUri = window.location.href;

      await subscriptionUpdaters.addCreditCard(subscription.id, {
        card,
        redirectUri,
      });
      modalSubscriptionActions.showSuccess({
        headline: translate(`${TRANSLATION_PREFIX}.success.headline`),
        body: translate(`${TRANSLATION_PREFIX}.success.body`),
      });
    } catch (error: any) {
      Sentry.captureException(error);
      modalSubscriptionActions.showFailure({
        headline: translate(`${TRANSLATION_PREFIX}.failure.headline`),
        body: translate(`${TRANSLATION_PREFIX}.failure.body`, {
          action: translate(`${TRANSLATION_PREFIX}.action.creditCard`),
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const createSubscription = async () => {
    setLoading(true);
    try {
      await subscriptionUpdaters.create({planName});

      modalSubscriptionActions.showSuccess({
        headline: translate(`${TRANSLATION_PREFIX}.success.headline`),
        body: translate(`${TRANSLATION_PREFIX}.success.body`),
      });
    } catch (error: any) {
      Sentry.captureException(error);
      modalSubscriptionActions.showFailure({
        headline: translate(`${TRANSLATION_PREFIX}.failure.headline`),
        body: translate(`${TRANSLATION_PREFIX}.failure.body`, {
          action: translate(`${TRANSLATION_PREFIX}.action.subscription`),
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const iframeTargetRef = useRef<HTMLDivElement>(null);
  const adyenPaymentForm = useAdyenPaymentForm(iframeTargetRef, onSubmit);

  const onSubmitClick = async () => {
    handleSubscriptionButtonPressedEvent({
      screenName: screenNames.SUBSCRIPTION_MODAL.CREDIT_DETAILS,
      buttonName: buttonNames.SUBSCRIPTION_MODAL.CLOSE_MODAL,
    });
    handleScreenDismissedEvent({
      screenName: screenNames.SUBSCRIPTION_MODAL.CREDIT_DETAILS,
      dismissedReason: dismissedReasons.SUBSCRIPTION_MODAL.CONTINUE,
    });
    if (hasPaymentSource) {
      await createSubscription();
    } else {
      await adyenPaymentForm.submit();
    }
  };

  useEffect(() => {
    handleScreenPresentedEvent({
      screenName: screenNames.SUBSCRIPTION_MODAL.CREDIT_DETAILS,
    });
  }, []);

  return (
    <div className={styles.container} data-testid={testID}>
      <Headline size="sm">
        {hasPaymentSource
          ? translate(`${TRANSLATION_PREFIX}.headline.existing`)
          : translate(`${TRANSLATION_PREFIX}.headline.add`)}
      </Headline>
      <div className={styles.formSection}>
        {hasPaymentSource ? (
          <MockAdyenForm
            {...{testID, onSubmitClick, loading}}
            paymentSource={subscription?.paymentSource}
          />
        ) : (
          <div ref={iframeTargetRef} />
        )}
      </div>
      <Label className={styles.disclaimer} size="xs">
        {translate(`${TRANSLATION_PREFIX}.disclaimer`)}
      </Label>
      <div className={clsx(styles.buttons, isMobile ? styles.fixedButton : '')}>
        {hasPaymentSource && (
          <Button
            className={styles.submitButton}
            appearance="neutral"
            mode="tinted"
            size="large"
            onClick={() => {
              setHasPaymentSource(false);
            }}
          >
            {translate(`${TRANSLATION_PREFIX}.button.edit`)}
          </Button>
        )}
        <Button
          className={styles.submitButton}
          appearance="brand"
          mode="filled"
          size="large"
          onClick={onSubmitClick}
          isLoading={loading}
        >
          {translate(`${TRANSLATION_PREFIX}.button.submit`)}
        </Button>
      </div>
    </div>
  );
}

export default Payment;
