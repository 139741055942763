import React from 'react';
import {Label} from '@lightricks/react-design-system';
import styles from './PlatformFilterWrapper.module.scss';

type PlatformFilterProps = {
  id: string;
  title: string;
  Component: React.ReactNode;
};

type PlatformFiltersGroupProps = {
  platformFilterProps: PlatformFilterProps[];
};

function PlatformFiltersGroup({
  platformFilterProps,
}: PlatformFiltersGroupProps) {
  return (
    <div className="platform-filter-wrapper">
      {platformFilterProps.map((platformFilterProp) => (
        <div className={styles.container} key={platformFilterProp.id}>
          <Label size="md" className={styles.label}>
            {platformFilterProp.title}
          </Label>
          {platformFilterProp.Component}
        </div>
      ))}
    </div>
  );
}

export default PlatformFiltersGroup;
