import React from 'react';
import {Body, designSystemToken, Label} from '@lightricks/react-design-system';
import Conditional from '@/components/conditional';
import styles from './ProfileVettingEmptyState.module.scss';

interface ProfileVettingEmptyStateProps {
  title?: string;
  subtitle?: string;
}

function ProfileVettingEmptyState(props: ProfileVettingEmptyStateProps) {
  const {title, subtitle} = props;

  return (
    <div className={styles.profileVettingEmptyStateContainer}>
      <Conditional condition={!!title}>
        <Label size="lg" color={designSystemToken('semantic.fg.secondary')}>
          {title}
        </Label>
      </Conditional>
      <Conditional condition={!!subtitle}>
        <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
          {subtitle}
        </Body>
      </Conditional>
    </div>
  );
}

export default ProfileVettingEmptyState;
