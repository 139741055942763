import {create} from 'zustand';

/**
 * Profile store - tracks settings for each profile
 */
const DEFAULT_PROFILE_SETTINGS = {
  showAllTags: false,
  showMoreBio: false,
  openSocialNetworks: {
    instagram: true,
  },
};

type ProfileSettings = {
  showAllTags: boolean;
  showMoreBio: boolean;
  openSocialNetworks: {[key: string]: boolean};
};

export type ProfileStoreState = {
  profiles: {[key: string]: ProfileSettings};
};

const useProfileStore = create<ProfileStoreState>(() => ({
  profiles: {},
}));

const getProfileSettings = (profileId: string) => {
  return (
    useProfileStore.getState().profiles[profileId] || {
      ...DEFAULT_PROFILE_SETTINGS,
    }
  );
};

const setShowAllTags = (profileId: string, showAllTags: boolean) => {
  useProfileStore.setState((state) => {
    const profile = getProfileSettings(profileId);
    return {
      profiles: {
        ...state.profiles,
        [profileId]: {
          ...profile,
          showAllTags,
        },
      },
    };
  });
};

const setShowMoreBio = (profileId: string, showMoreBio: boolean) => {
  useProfileStore.setState((state) => {
    const profile = getProfileSettings(profileId);
    return {
      profiles: {
        ...state.profiles,
        [profileId]: {
          ...profile,
          showMoreBio,
        },
      },
    };
  });
};

const setOpenSocialNetwork = (
  profileId: string,
  socialNetworkId: string,
  isOpen: boolean
) => {
  useProfileStore.setState((state) => {
    const profile = getProfileSettings(profileId);
    return {
      profiles: {
        ...state.profiles,
        [profileId]: {
          ...profile,
          openSocialNetworks: {
            ...profile.openSocialNetworks,
            [socialNetworkId]: isOpen,
          },
        },
      },
    };
  });
};

export const profileGetters = {
  getProfileSettings,
};

export const profileActions = {
  setShowMoreBio,
  setShowAllTags,
  setOpenSocialNetwork,
};

export default useProfileStore;
