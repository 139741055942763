import React, {useEffect, useMemo} from 'react';
import AnimateHeight from 'react-animate-height';
import {Headline, Body, Icon, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {
  ContactSalesButton,
  SubscribeButton,
  CurrentPlanButton,
} from '@/views/pricing/components/pricing-tier/PricingTierButtons';
import {handleSubscriptionProductPresented} from '@/components/modal-subscription/AnalyticsEventsHandler';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './PricingTier.module.scss';

const TRANSLATION_PREFIX = 'components.pricing.pricing-tier';

export type PricingTierProps = {
  id: string;
  name: string;
  price: number | undefined;
  currency: string;
  description: string;
  featuresHeader: string;
  features: string[];
  isTrialTier: boolean;
  isActiveTier: boolean;
  planDurationInDays?: number | null;
  onCTAClick?: () => void;
  onContactSalesClick: () => void;
  priceFootnote: string;
  paymentType: string;
  hideSubscriptionButton: boolean;
  hideContactSalesButton: boolean;
};

function PricingTier(props: PricingTierProps) {
  const {
    id,
    name,
    description,
    price,
    currency,
    featuresHeader,
    features,
    isTrialTier,
    isActiveTier,
    planDurationInDays,
    priceFootnote,
    paymentType,
    hideSubscriptionButton,
    hideContactSalesButton,
    onCTAClick,
    onContactSalesClick,
  } = props;

  const priceCurrency = useMemo(() => {
    return paymentType === 'invoice'
      ? ''
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
        }).format(price || 0);
  }, [price]);

  const isMobile = useIsMobile();

  const [showFeatures, setShowFeatures] = React.useState(false);

  useEffect(() => {
    handleSubscriptionProductPresented({
      productId: id,
      productPrice: paymentType === 'invoice' ? -1 : price || 0,
      currency,
    });
  }, []);

  const renderPlanFeatures = useMemo(() => {
    return (
      <div className={styles.features}>
        <Headline size="xxs">{featuresHeader}</Headline>
        {features.map((feature) => (
          <div key={feature} className={styles.feature}>
            <Icon name="Actions-Accept" size="small" appearance="primary" />
            <Body key={feature} size="md">
              {feature}
            </Body>
          </div>
        ))}
      </div>
    );
  }, [features, featuresHeader]);

  const renderButton = useMemo(() => {
    if (isActiveTier) {
      return <CurrentPlanButton />;
    }
    if (
      (!hideSubscriptionButton && !hideContactSalesButton) ||
      !hideSubscriptionButton
    ) {
      return <SubscribeButton onClick={onCTAClick} isTrialTier={isTrialTier} />;
    }
    if (!hideContactSalesButton) {
      return <ContactSalesButton onContactSalesClick={onContactSalesClick} />;
    }
    return null;
  }, [hideContactSalesButton, hideSubscriptionButton]);

  const renderPriceSection = useMemo(
    () => (
      <div className={styles.price}>
        <Headline size="xl">{priceCurrency}</Headline>
        <Body size="xl" className={styles.period}>
          {priceCurrency &&
            translate(
              `${TRANSLATION_PREFIX}.pay-period-monthly${
                isMobile ? '-mobile' : ''
              }`
            )}
        </Body>
      </div>
    ),
    [priceCurrency, isMobile]
  );

  return (
    <div className={styles.container}>
      <div
        className={`${styles.stickyBanner} ${
          isTrialTier ? styles.trialTier : ''
        } ${isActiveTier ? styles.activeTier : ''}`}
      >
        {isTrialTier
          ? planDurationInDays && (
              <Headline size="sm">
                {translate('components.pricing.recommended', {
                  days: planDurationInDays,
                })}
              </Headline>
            )
          : null}
      </div>
      <div
        className={`${styles.pricingTier} ${
          isActiveTier ? styles.activeTier : ''
        } ${isTrialTier ? styles.trialTier : ''}`}
      >
        <div className={styles.tierHeader}>
          <div className={styles.mobileHeading}>
            <Headline size="lg">{name}</Headline>
            {isMobile ? renderPriceSection : null}
          </div>
          <Body size="sm" className={styles.description}>
            {description}
          </Body>
        </div>
        <div className={styles.priceSection}>
          {priceCurrency && !isMobile ? renderPriceSection : null}
          <Body
            size="md"
            className={`${styles.priceFootnoteText} ${
              !priceCurrency ? styles.blackText : ''
            }`}
          >
            {priceFootnote}
          </Body>
        </div>
        <div className={styles.buttons}>{renderButton}</div>
        <div className={styles.collapsibleSection}>
          {isMobile ? (
            <>
              <div
                className={styles.showFeaturesButton}
                onClick={() => setShowFeatures(!showFeatures)}
              >
                <Label size="md">
                  {!showFeatures
                    ? translate(`${TRANSLATION_PREFIX}.show-features`)
                    : translate(`${TRANSLATION_PREFIX}.hide-features`)}
                </Label>
                <Icon
                  name={
                    !showFeatures
                      ? 'Actions-Arrow-Forward-Small'
                      : 'Actions-Arrow-Down-Small'
                  }
                  size="small"
                  appearance="primary"
                />
              </div>
              <AnimateHeight duration={500} height={showFeatures ? 'auto' : 0}>
                {renderPlanFeatures}
              </AnimateHeight>
            </>
          ) : (
            renderPlanFeatures
          )}
        </div>
      </div>
    </div>
  );
}

export default PricingTier;
