import React from 'react';
import {PlacesType, Tooltip as ReactTooltip} from 'react-tooltip';
import {Button, designSystemToken} from '@lightricks/react-design-system';
import styles from './ButtonGroup.module.scss';

const DEFAULT_TOOLTIP_PLACE = 'top';

export type ButtonProps = {
  value: any;
  label?: string | React.ReactElement;
  tooltip?: string;
  tooltipPlace?: PlacesType | string;
  disabled?: boolean;
};

export type ButtonGroupProps = {
  testID?: string;
  label?: string;
  containerClassName?: string;
  buttonClassName?: string;
  buttons: ButtonProps[];
  renderButton?: (
    button: ButtonProps,
    isActive: boolean,
    onClick: () => void
  ) => React.ReactNode;
  selectedButtonValue?: string | null;
  onChange?: (selection: ButtonProps | ButtonProps[], index?: number) => void;
  readOnly?: boolean;
  hideUnselectedButtons?: boolean;
  multiple?: boolean;
  selectedButtonValues?: any[] | null;
  error?: string;
};

function ButtonGroup(props: ButtonGroupProps) {
  const {
    testID = 'button-group',
    label,
    containerClassName = '',
    buttonClassName = '',
    buttons,
    renderButton,
    selectedButtonValue,
    onChange,
    readOnly = false,
    hideUnselectedButtons = false,
    multiple = false,
    selectedButtonValues,
    error,
  } = props;

  const getButtonOptions = () => {
    if (hideUnselectedButtons) {
      if (multiple) {
        return buttons.filter((button) =>
          selectedButtonValues?.includes(button.value)
        );
      }
      return buttons.filter((button) => button.value === selectedButtonValue);
    }
    return buttons;
  };

  const handleButtonChange = (button: ButtonProps, index: number) => {
    if (readOnly || button.disabled) {
      return;
    }

    if (multiple) {
      let newSelectedButtonValues: string[] = [];
      if (selectedButtonValues?.includes(button.value)) {
        newSelectedButtonValues = selectedButtonValues.filter(
          (value) => value !== button.value
        );
      } else {
        newSelectedButtonValues = [
          ...(selectedButtonValues || []),
          button.value,
        ];
      }

      const newSelectedButtons = buttons.filter((buttonOption) =>
        newSelectedButtonValues.includes(buttonOption.value)
      );
      onChange?.(newSelectedButtons, index);
    } else {
      onChange?.(button, index);
    }
  };

  const isButtonActive = (button: ButtonProps) => {
    if (multiple) {
      return !!selectedButtonValues?.includes(button.value);
    }
    return button.value === selectedButtonValue;
  };

  const getActiveStyle = (button: ButtonProps) => {
    if (multiple) {
      return selectedButtonValues?.includes(button.value) ? styles.active : '';
    }
    return button.value === selectedButtonValue ? styles.active : '';
  };

  return (
    <div className={`${styles.container}`} data-testid={testID}>
      {label ? <label className={styles.label}>{label}</label> : null}
      <div className={`${styles.buttonsContainer} ${containerClassName}`}>
        {getButtonOptions().map((button, index) => (
          <div key={button.value} data-testid={`${testID}--${button.value}`}>
            {renderButton ? (
              <div id={button.value}>
                {renderButton(button, isButtonActive(button), () =>
                  handleButtonChange(button, index)
                )}
              </div>
            ) : (
              <Button
                id={button.value}
                className={`${styles.button} ${buttonClassName} ${
                  readOnly ? styles.readOnly : ''
                } ${getActiveStyle(button)} ${
                  button.disabled ? styles.disabled : ''
                }`}
                appearance="neutral"
                mode="tinted"
                size="small"
                onClick={() => handleButtonChange(button, index)}
              >
                {button.label}
              </Button>
            )}
            {button.tooltip ? (
              <ReactTooltip
                id={`${button.value}-tooltip`}
                anchorSelect={`#${button.value}`}
                place={
                  (button.tooltipPlace || DEFAULT_TOOLTIP_PLACE) as PlacesType
                }
                className={styles.tooltip}
                style={{
                  borderRadius: 10,
                  background: designSystemToken('semantic.bg.inverse'),
                  color: designSystemToken('semantic.fg.inverse'),
                  fontSize: 14,
                  zIndex: 100,
                }}
              >
                <label className={styles.tooltipLabel}>{button.tooltip}</label>
              </ReactTooltip>
            ) : null}
          </div>
        ))}
      </div>
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
}

export default ButtonGroup;
