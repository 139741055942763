import React from 'react';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import ProfileVettingEmptyState from '@/components/creator-vetting-report/components/profile-vetting-empty-state';
import {FlagWithCategory} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import ProfileVettingContentItem from '../profile-vetting-content-item';

interface ProfileVettingFlaggedContentProps {
  flaggedContent: FlagWithCategory[];
  onPostClick: (flag: string, postId: string) => void;
  trackAction: (actionName: string, actionValue: string) => void;
}

function ProfileVettingFlaggedContent(
  props: ProfileVettingFlaggedContentProps
) {
  const {flaggedContent, onPostClick, trackAction} = props;
  return (
    <Conditional
      condition={flaggedContent.length > 0}
      fallback={
        <ProfileVettingEmptyState
          subtitle={translate('no-flagged-content-for-filters')}
        />
      }
    >
      <div data-testid={CREATOR_VETTING_REPORT_TEST_IDS.FLAGGED_CONTENT}>
        {flaggedContent.map((item, index) => (
          <ProfileVettingContentItem
            key={item.id}
            item={item}
            isLast={index === flaggedContent.length - 1}
            onPostClick={onPostClick}
            trackAction={trackAction}
          />
        ))}
      </div>
    </Conditional>
  );
}

export default ProfileVettingFlaggedContent;
