import {CreatorSearchResult} from '@/types/models/search-creators/searchCreators';

function mapCreatorsForAnalytics(creators: CreatorSearchResult[]) {
  return creators.map((creator) => ({
    name: creator.fullName,
    username: creator.username,
    is_onboarded: creator.isOnboarded,
  }));
}

export default mapCreatorsForAnalytics;
