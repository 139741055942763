import React from 'react';
import {
  DialogProps as ModalPropsMui,
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import styles from './ModalAuth.module.scss';

export interface ModalAuthProps extends ModalPropsMui {
  open: boolean;
  children?: React.ReactNode;
  scroll?: 'paper' | 'body';
  actions?: JSX.Element;
  label?: string;
  modalTitle?: JSX.Element | string;
  onClose?: () => void;
  dividers?: boolean;
  testID?: string;
}

const StyledDialogContent = styled(DialogContent)`
  ${(props) => props.theme.breakpoints.down('mobile')} {
    background-color: #fcfcfd;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  margin-top: 70px;

  ${(props) => props.theme.breakpoints.down('mobile')} {
    margin-top: 40px;
  }
`;

const StyledDialog = styled(DialogMui)`
  .MuiPaper-root {
    width: 700px;
    overflow: hidden;
    border-radius: 35px;

    ${(props) => props.theme.breakpoints.down('mobile')} {
      margin: 0;
      align-self: end;
      max-height: calc(100% - 53px);
      border-radius: 10px;
    }
  }
`;

function ModalAuth(props: ModalAuthProps) {
  const {
    dividers,
    modalTitle,
    actions,
    onClose,
    open,
    children,
    testID = 'modal-auth',
    ...rest
  } = props;
  const testIDProps = {
    'data-testid': testID,
  };
  return (
    <StyledDialog {...testIDProps} {...rest} open={open} onClose={onClose}>
      {modalTitle && (
        <StyledDialogTitle className={styles.DialogTitle}>
          {modalTitle}
        </StyledDialogTitle>
      )}
      <StyledDialogContent dividers={dividers}>{children}</StyledDialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </StyledDialog>
  );
}

export default ModalAuth;
