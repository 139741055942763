import React, {useEffect, useMemo, useRef} from 'react';
import styles from './ViewTypeSelector.module.scss';

interface ViewTypeProps {
  id: string;
  icon: React.ReactElement | string;
}

export interface ViewTypeSelectorProps {
  testID?: string;
  viewTypes: ViewTypeProps[];
  selectedViewTypeId: string;
  onChange: (id: string) => void;
}

function getActiveIndex(
  viewTypes: ViewTypeProps[],
  selectedViewTypeId?: string
) {
  return viewTypes.findIndex(
    (viewType: ViewTypeProps) => viewType.id === selectedViewTypeId
  );
}

function ViewTypeSelector(props: ViewTypeSelectorProps) {
  const {
    testID = 'view-type-selector',
    viewTypes,
    selectedViewTypeId,
    onChange,
  } = props;

  const controlRef = useRef<HTMLDivElement>(null);

  const activeIndex = useMemo(
    () => getActiveIndex(viewTypes, selectedViewTypeId),
    [viewTypes, selectedViewTypeId]
  );

  const viewTypesWithRefs = useMemo(() => {
    return viewTypes.map((viewType) => ({
      ...viewType,
      ref: React.createRef<HTMLDivElement>(),
    }));
  }, [viewTypes]);

  useEffect(() => {
    if (activeIndex === -1) {
      return;
    }
    const activeViewTypeRef = viewTypesWithRefs[activeIndex].ref;
    if (!activeViewTypeRef.current || !controlRef.current) {
      return;
    }
    const {style} = controlRef.current;

    const highlightSidePadding = 2;
    const highlightWidth =
      controlRef.current.offsetWidth / viewTypes.length -
      highlightSidePadding * 2;

    const highlightXPos =
      highlightWidth * activeIndex +
      (highlightSidePadding + activeIndex * highlightSidePadding);
    style.setProperty('--highlight-width', `${highlightWidth}px`);
    style.setProperty('--highlight-x-pos', `${highlightXPos}px`);
  }, [selectedViewTypeId, onChange, controlRef, viewTypes]);

  return (
    <div className={styles.container} data-testid={testID} ref={controlRef}>
      <div
        className={`${styles.controls} ${
          controlRef.current ? styles.ready : ''
        }`}
      >
        {viewTypesWithRefs?.map((viewTypeItem) => (
          <div
            key={viewTypeItem.id}
            className={`${styles.viewType} ${
              viewTypeItem.id === selectedViewTypeId ? styles.active : ''
            }`}
            ref={viewTypeItem.ref}
          >
            <div
              className={styles.button}
              onClick={() => onChange(viewTypeItem.id)}
            >
              {typeof viewTypeItem.icon === 'string' ? (
                <img className={styles.icon} src={viewTypeItem.icon} alt="" />
              ) : (
                viewTypeItem.icon
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViewTypeSelector;
