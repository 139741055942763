const BRIEF_WIZARD_TEST_IDS = {
  STEP__CONTINUE_BUTTON: 'step__continue-button',
  // CAMPAIGN TYPE STEP
  CAMPAIGN_TYPE_BUTTON__BRAND_AWARENESS: 'brand-awareness-option',
  CAMPAIGN_TYPE_BUTTON__CONTENT_CREATION: 'content-creation-option',

  // BUDGET AND NETWORK STEP
  STEP__BUDGET_AND_NETWORK: 'step__budget-and-network',
  BUDGET_AND_NETWORK__BRAND_NAME_INPUT: 'budget-and-network__brand-name-input',
  BUDGET_AND_NETWORK__BUDGET: 'budget-and-network__budget',
  BUDGET_AND_NETWORK__CUSTOM_BUDGET_INPUT:
    'budget-and-network__custom-budget-input',
  BUDGET_AND_NETWORK__NETWORK: 'budget-and-network__network',

  // DELIVERABLES STEP
  STEP__DELIVERABLES: 'step__deliverables',
  DELIVERABLES__DELIVERABLE_TYPE: 'deliverables__deliverable-type',
  DELIVERABLES__DELIVERABLE_ITEM: 'deliverables__deliverable-item',

  // INFLUENCER PACKAGE STEP
  STEP__INFLUENCER_PACKAGE: 'step__influencer-package',
  INFLUENCER_PACKAGE__CREATOR_TYPE: 'influencer-package__creator-type',

  // PRODUCT STEP
  STEP__PRODUCT: 'step__product',
  PRODUCT__PRODUCT_NAME_INPUT: 'product__product-name-input',
  PRODUCT__PROVIDE_TO_CREATORS_CHECKBOX:
    'product__provide-to-creators-checkbox',
  PRODUCT__PROVIDE_PRODUCT_METHOD: 'product__provide-product-method',
  PRODUCT__CASH_VALUE_INPUT: 'product__cash-value-input',

  // GUIDELINES STEP
  STEP__GUIDELINES: 'step__guidelines',
  GUIDELINES__CAMPAIGN_GUIDELINES_TEXTAREA:
    'guidelines__campaign-guidelines-textarea',
  // DONE STEP
  STEP__DONE: 'step__done',
  DONE__GENERATE_BRIEF_BUTTON: 'done__generate-brief-button',
};

export default BRIEF_WIZARD_TEST_IDS;
