import React, {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {
  Body,
  designSystemToken,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import ToggleableTags from '@/components/toggleable-tags';
import styles from './SavedInLists.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.saved-in-lists';

interface SavedInListsProps {
  testID?: string;
  labels: string[];
  initialIsOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

function SavedInLists(props: SavedInListsProps) {
  const {testID = 'saved-in-lists', labels, initialIsOpen, onToggle} = props;
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const onToggleHandler = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
    onToggle?.(newIsOpen);
  };

  return (
    <div className={styles.savedInListsContainer} data-testid={testID}>
      <div className={styles.title} onClick={() => onToggleHandler(!isOpen)}>
        <Body size="md">
          {translate(`${TRANSLATION_PREFIX}.saved-in-lists`)}
        </Body>
        <Label size="md" color={designSystemToken('semantic.fg.secondary')}>
          ({labels.length})
        </Label>
        <Icon
          className={`${styles.icon} ${isOpen ? styles.open : ''}`}
          size="small"
          appearance="neutral"
          name="Actions-Arrow-Down-Small"
        />
      </div>
      <AnimateHeight height={isOpen ? 'auto' : 0}>
        <ToggleableTags
          tags={labels}
          tagsContainerClassName={styles.labelsContainer}
          tagClassName={styles.label}
          initialShowAllTags
        />
      </AnimateHeight>
    </div>
  );
}

export default SavedInLists;
