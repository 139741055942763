import React from 'react';
import {Box} from '@mui/material';
import {Icon, designSystemToken} from '@lightricks/react-design-system';
import {VettableCreator} from '@/types/vettableCreatorsResponse';

type SocialIconBadgeProps = {
  className: string;
  platform: VettableCreator['provider'];
};

function SocialIconBadge(props: SocialIconBadgeProps) {
  const {className, platform} = props;
  if (!platform) return null;

  const iconMap = {
    facebook: 'Social-Facebook' as const,
    instagram: 'Social-Instagram' as const,
    instagram_business: 'Social-Instagram' as const,
    tiktok: 'Social-TikTok' as const,
    tiktok_v2: 'Social-TikTok' as const,
    youtube: 'Social-YouTube' as const,
  };

  const badgeStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '22px',
    height: '22px',
    borderRadius: '100px',
    border: `1px solid ${designSystemToken('semantic.bg.primary')}`,
    background: designSystemToken('semantic.bg.neutral'),
  };

  if (!iconMap[platform]) return null;

  return (
    <Box sx={badgeStyle} className={className}>
      <Icon name={iconMap[platform]} size="small" appearance="inverse" />
    </Box>
  );
}

export default SocialIconBadge;
