import translate from '@/utils/translate';
import {CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefItem from './BriefItem';

class TimelineBriefItem extends BriefItem {
  #daysToPostAfterDeliverableFulfilled?: number | null;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(
      briefFieldManager,
      campaignData,
      key,
      index,
      id,
      title,
      contentHtml,
      highlighted,
      clientBriefTemplateId
    );
    this.#daysToPostAfterDeliverableFulfilled =
      campaignData.daysToPostAfterDeliverableFulfilled;
  }

  set daysToPostAfterDeliverableFulfilled(value: number | null | undefined) {
    this.#daysToPostAfterDeliverableFulfilled = value;
  }

  get toContentHtml() {
    return `
        <div/>
              <span>${translate(
                `components.campaign-brief.sections.timeline.content-creation-duration-generated-brief`,
                {duration: this.#daysToPostAfterDeliverableFulfilled}
              )}</span>
        </div>
    `;
  }
}

export default TimelineBriefItem;
