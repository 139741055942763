import {useEffect, useState} from 'react';
import Fortress from '@/services/fortress/Fortress';
import useToken from '@/hooks/use-token';

function useLtxAuthToken() {
  const {isAuthenticated} = useToken(true);
  const [ltxAuthToken, setLtxAuthToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const ltxToken = await Fortress.getAccessToken();
      setLtxAuthToken(ltxToken);
    })();
  }, [isAuthenticated]);

  return {
    ltxAuthToken,
  };
}

export default useLtxAuthToken;
