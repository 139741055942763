import React, {useEffect} from 'react';
import {JsonApiError} from '@/utils/jsonapiClient';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import {ROUTES} from '@/config/routesConstants';
import {ProfileProps} from '@/types/creatorProfile';
import {DashboardUser} from '@/types/dashboardUser';
import PoppaysLoader from '@/components/PoppaysLoader';
import CreatorProfile from '@/components/creator-profile';
import Page from '@/components/page';
import useCreatorProfileQuery from '@/hooks/queries/use-creator-profile-query';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useParams from '@/hooks/use-params';
import styles from './Profile.module.scss';

function Profile() {
  const {creatorId} = useParams();
  const {data: dashboardUser} = useDashboardUserQuery({});
  const navigation = useNavigation();

  const brandId = useBrandId();
  const {
    profile,
    error,
    isLoading,
    isError: isErrorProfile,
  } = useCreatorProfileQuery({
    profileId: creatorId,
    brandId,
  });

  useEffect(() => {
    if (error) {
      const jsonApiError = error as JsonApiError;
      switch (Number(jsonApiError.status)) {
        case 404: {
          navigation.navigate(ROUTES.NOT_FOUND);
          return;
        }
        default: {
          raiseFlashMessageError();
        }
      }
    }
  }, [error]);

  if (isLoading || !dashboardUser) {
    return <PoppaysLoader />;
  }

  if (isErrorProfile) {
    return null;
  }

  return (
    <Page
      id="creator-profile"
      className={styles.profileContainer}
      childrenClassName={styles.profileWrapper}
    >
      <CreatorProfile
        testID="creator-profile"
        profile={profile as ProfileProps}
        dashboardUser={dashboardUser as DashboardUser}
        displayPublicDataOnly={false}
        componentAllowsAddMembership
        componentAllowsCollaboration
        componentAllowsInviteToCampaign
        canViewAudience
        canViewSocialHealth
        canViewPortfolio
        canViewPosts
        canViewProfileNotes
      />
    </Page>
  );
}

export default Profile;
