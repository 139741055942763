import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import Fortress from '@/services/fortress/Fortress';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {getEnv} from '@/config/environment';
import {tokenSerializer} from './token';

const instance = axios.create({
  baseURL: getEnv().VITE_CAPSULE_AI_SERVER_URL || 'http://localhost:3002',
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const ltxAuthTokenObject: any = {};
    const ltxAuthToken = await Fortress.getAccessToken();
    if (ltxAuthToken) {
      ltxAuthTokenObject['x-lightricks-auth-token'] = ltxAuthToken;
    }
    const headers = AxiosHeaders.from({
      ...(await tokenSerializer()),
      ...(config.headers || {}),
      ...ltxAuthTokenObject,
      Accept: 'application/json',
    }) as AxiosRequestHeaders;

    return {
      ...config,
      headers,
    };
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return {...response, data: mapKeysToCase(response.data)};
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
