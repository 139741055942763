import React from 'react';
import {Box, Stack} from '@mui/material';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import Chip from '@/components/chip/Chip';
import WeightSelectionDropDown from '../drop-down/WeightSelectionDropDown';
import styles from './SelectedOptionsWithWeightsDropDown.module.scss';

const AUTOCOMPLETE_MIN_WIDTH = 125;
const AUTOCOMPLETE_MIN_HEIGHT = 40;

type SelectedOption = {
  selectedWeight?: string;
  option: BaseFilterOption;
  label: string;
};

type SelectedOptionsWithWeightsDropDownProps = {
  containerClassName?: string;
  selectedOptions: SelectedOption[];
  onDeleteSelection: (option: BaseFilterOption) => void;
  weights: string[];
  weightPlaceholder?: string;
  weightPlaceholderColor?: string;
  onUpdateWeight: (optionId: string, weightId: string) => void;
};

function SelectedOptionsWithWeightsDropDown({
  containerClassName,
  selectedOptions,
  onDeleteSelection,
  weights,
  weightPlaceholder,
  weightPlaceholderColor = designSystemToken('semantic.fg.primary'),
  onUpdateWeight,
}: SelectedOptionsWithWeightsDropDownProps) {
  return (
    <>
      {selectedOptions.map((selectedOption) => (
        <Stack
          direction="row"
          sx={{justifyContent: 'space-between'}}
          key={selectedOption.option.id}
        >
          <Chip
            label={selectedOption.label}
            handleDelete={() => onDeleteSelection(selectedOption.option)}
            deleteIcon={
              <Box sx={{display: 'flex'}}>
                <Icon
                  size="medium"
                  appearance="neutral"
                  name="Actions-Close-Small"
                />
              </Box>
            }
          />
          <WeightSelectionDropDown
            containerClassName={containerClassName || styles.weightSelection}
            selectedOptionId={selectedOption.option.id}
            weights={weights}
            selectedWeightId={selectedOption.selectedWeight}
            onUpdateWeight={onUpdateWeight}
            placeholder={weightPlaceholder}
            minWidth={AUTOCOMPLETE_MIN_WIDTH}
            minHeight={AUTOCOMPLETE_MIN_HEIGHT}
          />
        </Stack>
      ))}
    </>
  );
}

export default SelectedOptionsWithWeightsDropDown;
