import React from 'react';
import {Icon} from '@lightricks/react-design-system';

const WIZARD_TRANSLATION_PREFIX =
  'views.campaigns.components.wizard.steps.deliverables';
const DROPDOWN_TRANSLATION_PREFIX =
  'components.campaign-brief.sections.deliverables.deliverable';

const AWARENESS_WIZARD_TRANSLATION_PREFIX = `${WIZARD_TRANSLATION_PREFIX}.awareness-deliverables`;
const CONTENT_WIZARD_TRANSLATION_PREFIX = `${WIZARD_TRANSLATION_PREFIX}.content-deliverables`;

export type DeliverableOption = {
  icon: React.ReactNode;
  id: string;
  value: string;
  labelLocaleKeyTitle: string;
  labelLocaleKeySubtitle: string;
  labelLocaleKeyTitleOverride?: string;
  dropdownLocaleKeyTitle: string;
};

export const DELIVERABLES_OPTIONS_CONTENT_CREATION: DeliverableOption[] = [
  {
    icon: (
      <Icon size="medium" appearance="neutral" name="Navigation-Photos-Light" />
    ),
    id: 'content_standard_photo',
    value: 'contentStandardPhoto',
    labelLocaleKeyTitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-standard-photo`,
    labelLocaleKeySubtitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-type-ugc`,
    dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-content_standard_photo`,
  },
  {
    icon: (
      <Icon size="medium" appearance="neutral" name="Social-InstagramReels" />
    ),
    id: 'content_standard_video',
    value: 'contentStandardVideo',
    labelLocaleKeyTitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-standard-video`,
    labelLocaleKeySubtitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-type-ugc`,
    dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-content_standard_video`,
  },
  // {
  //   icon: (
  //     <Icon size="medium" appearance="neutral" name="Navigation-Photos-Light" />
  //   ),
  //   id: 'content_premium_photo',
  //   value: 'contentPremiumPhoto',
  //   labelLocaleKeyTitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-premium-photo`,
  //   labelLocaleKeySubtitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-type-studio-look-and-feel`,
  //   dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-content_premium_photo`,
  // },
  // {
  //   icon: (
  //     <Icon size="medium" appearance="neutral" name="Social-InstagramReels" />
  //   ),
  //   id: 'content_premium_video',
  //   value: 'contentPremiumVideo',
  //   labelLocaleKeyTitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-premium-video`,
  //   labelLocaleKeySubtitle: `${CONTENT_WIZARD_TRANSLATION_PREFIX}.deliverable-type-studio-look-and-feel`,
  //   dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-content_premium_video`,
  // },
];

export const DELIVERABLES_OPTIONS_BY_NETWORK = {
  instagram: [
    {
      icon: (
        <Icon
          size="medium"
          appearance="neutral"
          name="Navigation-Photos-Light"
        />
      ),
      id: 'feed_post_photo',
      value: 'feedPostPhoto',
      labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-feed-post`,
      labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-photo`,
      dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-feed_post_photo`,
    },
    {
      icon: (
        <Icon
          size="medium"
          appearance="neutral"
          name="Social-InstagramStories"
        />
      ),
      id: 'story_post',
      value: 'storyPost',
      labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-story`,
      labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-story`,
      dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-story_post`,
    },
    {
      icon: (
        <Icon size="medium" appearance="neutral" name="Social-InstagramReels" />
      ),
      id: 'reel_post',
      value: 'reelPost',
      labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-reel`,
      labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-video`,
      dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-reel_post`,
    },
  ],
  tiktok_v2: [
    {
      icon: (
        <Icon size="medium" appearance="neutral" name="Social-InstagramReels" />
      ),
      id: 'tiktok_video',
      value: 'tiktokVideo',
      labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-tiktok-post`,
      labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-video`,
      labelLocaleKeyTitleOverride: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-tiktok-post`,
      dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-tiktok_video`,
    },
  ],
  youtube: [
    // {
    //   icon: <Icon size="medium" appearance="neutral" name="Social-YouTube" />,
    //   id: 'youtube_short_form_video',
    //   value: 'youtubeShortFormVideo',
    //   labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-short-form-video`,
    //   labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-short-form-video`,
    //   dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-youtube_short_form_video`,
    // },
    {
      icon: <Icon size="medium" appearance="neutral" name="Social-YouTube" />,
      id: 'youtube_long_form_video',
      value: 'youtubeLongFormVideo',
      labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-long-form-video`,
      labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-long-form-video`,
      dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-youtube_long_form_video`,
    },
    // {
    //   icon: <Icon size="medium" appearance="neutral" name="Social-YTShorts" />,
    //   id: 'youtube_short',
    //   value: 'youtubeShort',
    //   labelLocaleKeyTitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-shorts`,
    //   labelLocaleKeySubtitle: `${AWARENESS_WIZARD_TRANSLATION_PREFIX}.deliverable-type-shorts`,
    //   dropdownLocaleKeyTitle: `${DROPDOWN_TRANSLATION_PREFIX}.deliverable-type-youtube_short`,
    // },
  ],
} as {[key: string]: DeliverableOption[]};
