import {useCallback} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';

type SearchScreenPresentedEventParams = {
  screen_name: string;
  tab?: string;
  source?: string;
};

type SearchScreenDismissedEventParams = {
  screen_name: string;
  tab?: string;
  destination?: string;
};

function useTrackSearchScreenEvent() {
  const presented = useCallback(
    ({
      screen_name,
      tab = '',
      source = '',
    }: SearchScreenPresentedEventParams) => {
      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );
      AnalyticsService.dispatchEvent(eventNames.SEARCH_SCREEN_PRESENTED, {
        flow_id: recruitmentSearchFlow.flow_id,
        screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
        source:
          source || AnalyticsService.getActiveScreenPresented()?.source || '',
        screen_name,
        tab,
        trigger_flow_name: recruitmentSearchFlow.flow_name,
      });
    },
    []
  );

  const dismissed = useCallback(
    ({
      screen_name,
      tab = '',
      destination = '',
    }: SearchScreenDismissedEventParams) => {
      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );
      AnalyticsService.dispatchEvent(eventNames.SEARCH_SCREEN_DISMISSED, {
        flow_id: recruitmentSearchFlow.flow_id,
        screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
        screen_name,
        tab,
        destination:
          destination ||
          AnalyticsService.getActiveScreenPresented()?.source ||
          '',
      });
    },
    []
  );

  return {presented, dismissed};
}

export default useTrackSearchScreenEvent;
