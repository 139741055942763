import dayjs from 'dayjs';
import translate from './translate';

const TRANSLATION_PREFIX = 'utils.get-formatted-past-date';

const THRESHOLD_TODAY = 0; // 0 days difference
const THRESHOLD_YESTERDAY = 1; // 1 day difference
const THRESHOLD_MIN_DAYS = 2; // At least 2 days ago
const THRESHOLD_DAYS = 6; // Up to 6 days ago
const THRESHOLD_WEEKS = 4; // Up to 4 weeks ago
const THRESHOLD_MIN_WEEKS_DAYS = 7; // At least 7 days ago
const THRESHOLD_MAX_WEEKS_DAYS = THRESHOLD_WEEKS * THRESHOLD_MIN_WEEKS_DAYS; // At most 4 * 7 = 28 days in a month
const THRESHOLD_MIN_MONTHS = 1; // At least one month ago
const THRESHOLD_MONTHS = 11; // Up to 11 months ago

function getFormattedPastDate(date?: Date): string {
  const now = dayjs().startOf('day');
  const targetDate = dayjs(date).startOf('day');

  const diffDays = now.diff(targetDate, 'day');
  const diffWeeks = Math.floor((diffDays - 1) / 7) + 1;
  const diffMonths = now.diff(targetDate, 'month', true); // Get fractional months
  const daysInMonth = targetDate.daysInMonth();
  const diffMonthsFloor = Math.floor(diffMonths);
  const diffYears = now.diff(targetDate, 'year');

  // A. Recent Updates (within 7 days)
  if (diffDays === THRESHOLD_TODAY) {
    return translate(`${TRANSLATION_PREFIX}.today`);
  }

  if (diffDays === THRESHOLD_YESTERDAY) {
    return translate(`${TRANSLATION_PREFIX}.yesterday`);
  }

  if (diffDays >= THRESHOLD_MIN_DAYS && diffDays <= THRESHOLD_DAYS) {
    return translate(`${TRANSLATION_PREFIX}.days-ago`, {days: diffDays});
  }

  // Edge case of a month with 28 days
  if (
    daysInMonth === THRESHOLD_MAX_WEEKS_DAYS &&
    diffDays === THRESHOLD_MAX_WEEKS_DAYS
  ) {
    return translate(`${TRANSLATION_PREFIX}.month-ago`);
  }

  // B. Weekly Updates
  if (
    diffDays >= THRESHOLD_MIN_WEEKS_DAYS &&
    diffDays <= THRESHOLD_MAX_WEEKS_DAYS
  ) {
    let weeks = diffWeeks;
    weeks = Math.min(weeks, THRESHOLD_WEEKS); // Cap the weeks at the threshold

    if (weeks === 1) {
      return translate(`${TRANSLATION_PREFIX}.week-ago`);
    }

    return translate(`${TRANSLATION_PREFIX}.weeks-ago`, {weeks});
  }

  // C. Monthly Updates
  if (diffMonthsFloor === THRESHOLD_MIN_MONTHS) {
    const oneMonthAgo = now.subtract(1, 'month').startOf('day');
    if (targetDate.isSame(oneMonthAgo, 'day')) {
      // Exactly 1 month ago
      return translate(`${TRANSLATION_PREFIX}.month-ago`);
    }
    // Between 1 month + 1 day and 2 full months
    return translate(`${TRANSLATION_PREFIX}.months-ago`, {months: 2});
  }

  if (diffMonthsFloor >= 2 && diffMonthsFloor <= THRESHOLD_MONTHS) {
    return translate(`${TRANSLATION_PREFIX}.months-ago`, {
      months: diffMonthsFloor,
    });
  }

  // D. Yearly Updates
  if (diffYears === 1) {
    return translate(`${TRANSLATION_PREFIX}.year-ago`);
  }

  return translate(`${TRANSLATION_PREFIX}.years-ago`, {years: diffYears});
}

export default getFormattedPastDate;
