import {EmailPreference} from '@/types/emailPreference';
import api from '../index';

const emailPreferenceUpdaters = {
  bulkUpdate: async (emailPreferences: Partial<EmailPreference>) => {
    return api.put(
      '/dashboard_user/visible_brand_email_preferences/bulk_update',
      emailPreferences
    );
  },
};

export default emailPreferenceUpdaters;
