import AdditionalInformation from './additional-information';
import BasicDetails from './basic-details';
import ContentAgreement from './content-agreement';
import CreatorDetails from './creator-details';
import CreatorPayments from './creator-payments';
import Deliverables from './deliverables';
import TermsAndConditions from './terms-and-conditions';
import Timeline from './timeline';

function Section() {}

Section.BasicDetails = BasicDetails;
Section.ContentAgreement = ContentAgreement;
Section.CreatorDetails = CreatorDetails;
Section.CreatorPayments = CreatorPayments;
Section.Deliverables = Deliverables;
Section.TermsAndConditions = TermsAndConditions;
Section.Timeline = Timeline;
Section.AdditionalInformation = AdditionalInformation;

export default Section;
