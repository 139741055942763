import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  designSystemToken,
  Headline,
  Icon,
  Tooltip,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';
import ImageUploader from '@/views/ai-content-generator/components/image-uploader';
import Spinner from '@/views/ai-content-generator/components/spinner';
import DiscreteSlider from '@/components/discrete-slider/DiscreteSlider';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useImageCompression from '@/hooks/use-image-compression';
import {
  GeneratedStyle,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './ReferenceImage.module.scss';

interface ReferenceImageProps {
  setIsReferenceImageLoading: (isLoading: boolean) => void;
  setIsReferenceImageSelected: (isReferenceImageSelected: boolean) => void;
  isReferenceImageSelected: boolean;
}

const REFERENCE_IMAGE_MAX_SIZE_MB = 0.1;

function ReferenceImage({
  setIsReferenceImageLoading,
  setIsReferenceImageSelected,
  isReferenceImageSelected,
}: ReferenceImageProps) {
  const {state, dispatch} = useContentGenerationStore();
  const brandId = useBrandId();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {dashboardUser}: any = useDashboardUserQuery({});
  const IMAGE_UPLOADER_TRANSLATION_PREFIX =
    'views.ai-content-generator.components.image-uploader';

  const [originalLocalFile, setOriginalLocalFile] = useState('');
  const [selectedUploadFile, setSelectedUploadFile] = useState<File | null>(
    null
  );

  const [isLoading, setIsLoading] = useState(false);
  const {compressedFile, isCompressing} = useImageCompression(
    selectedUploadFile,
    REFERENCE_IMAGE_MAX_SIZE_MB
  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_STYLE_TYPE',
      styleType: GeneratedStyle.reference,
    });

    if (selectedUploadFile) {
      setOriginalLocalFile(URL.createObjectURL(selectedUploadFile));
    }
  }, [selectedUploadFile]);

  useEffect(() => {
    if (compressedFile) {
      uploadImage(compressedFile).catch((error) => console.error(error));
    }
  }, [compressedFile]);

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('uuid', dashboardUser.id);
    formData.append('horizontal_alignment', 'default');
    formData.append('vertical_alignment', 'default');
    formData.append('frame_width', '0');
    formData.append('frame_height', '0');
    formData.append('brand_id', brandId);

    setIsLoading(true);
    setIsReferenceImageLoading(true);
    await capsuleAiUpdaters.uploadReferenceImage(formData).then((response) => {
      dispatch({
        type: 'SET_REFERENCE_IMAGE',
        referenceImage: response.data.data.referenceImageUrl,
      });

      setIsLoading(false);
      setIsReferenceImageLoading(false);
    });
  };

  const handleReferenceContainerClick = () => {
    if (state.referenceImage && !isLoading && !isCompressing) {
      dispatch({
        type: 'UPDATE_STYLE_TYPE',
        styleType: GeneratedStyle.reference,
      });

      setIsReferenceImageSelected(true);
    }
  };

  const handleResetReferenceImage = () => {
    dispatch({
      type: 'UPDATE_STYLE_TYPE',
      styleType: GeneratedStyle.none,
    });

    dispatch({
      type: 'SET_REFERENCE_IMAGE',
      referenceImage: null,
    });

    dispatch({
      type: 'RESET_REFERENCE_IMAGE_WEIGHT',
    });

    setIsReferenceImageSelected(false);
    setOriginalLocalFile('');
    setSelectedUploadFile(null);
  };

  const handleSliderChange = (event: any, value: number | number[]) => {
    dispatch({
      type: 'SET_REFERENCE_IMAGE_WEIGHT',
      referenceImageWeight: value as number,
    });
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.referenceImageContainer} ${
          !isReferenceImageSelected && originalLocalFile
            ? styles.inactiveBorderIndicator
            : ''
        }`}
        onClick={() => handleReferenceContainerClick()}
      >
        <div className={styles.borderCover} />
        <ImageUploader
          setSelectedUploadFile={setSelectedUploadFile}
          fileInputRef={fileInputRef}
          onClick={() => fileInputRef.current?.click()}
        />
        {originalLocalFile ? (
          <div className={styles.referenceImageContainerLocalFile}>
            <img
              src={originalLocalFile}
              alt="reference"
              className={`${styles.referenceImage} ${
                isReferenceImageSelected
                  ? styles.referenceImageActiveIndicator
                  : ''
              }`}
            />
            <div
              className={`${styles.referenceImageSelectedBorder} ${
                isReferenceImageSelected
                  ? styles.referenceImageActiveIndicator
                  : ''
              }`}
            />
            <div className={styles.referenceImageOverlay}>
              <div
                className={styles.referenceImageReplaceButton}
                onClick={() => fileInputRef.current?.click()}
              >
                Replace
              </div>
            </div>
            <div
              className={`${styles.referenceImageCloseButton} ${
                isReferenceImageSelected
                  ? styles.referenceImageCloseButtonActive
                  : ''
              }`}
              onClick={handleResetReferenceImage}
            >
              <Icon
                size="medium"
                appearance="neutral"
                name="Actions-Patch-Cancel-Fill"
              />
            </div>
          </div>
        ) : (
          <div
            className={styles.emptyStateContainer}
            onClick={() => fileInputRef.current?.click()}
          >
            <div className={styles.emptyStateInstructions}>
              <Button
                icon={
                  <Icon
                    size="medium"
                    appearance="neutral"
                    name="Actions-Add-Small1"
                  />
                }
                appearance="neutral"
                mode="tinted"
                size="small"
              />
              <p className={styles.emptyStateText}>
                {translate(
                  `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.upload-box.empty-state.text`
                )}
              </p>
            </div>
          </div>
        )}

        {isLoading || isCompressing ? (
          <div className={styles.spinningLoaderWrapper}>
            <Spinner size={52} />
          </div>
        ) : null}
      </div>
      {originalLocalFile ? (
        <div className={styles.referenceImageDiscreteSliderContainer}>
          <Headline size="sm">
            <div
              className={`${styles.headlineHorizontalContainer} ${
                !isReferenceImageSelected
                  ? styles.headlineHorizontalContainerDisabled
                  : ''
              }`}
            >
              {translate(
                'views.ai-content-generator.components.style-strength.title'
              )}
              <div className={styles.headlineHorizontalContainerTootip} />
            </div>
          </Headline>

          <DiscreteSlider
            min={0.2}
            max={0.701}
            step={0.25}
            defaultValue={0.45}
            onChange={handleSliderChange}
            disabled={!isReferenceImageSelected}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ReferenceImage;
