import React, {useState} from 'react';
import translate from '@/utils/translate';
import styles from './UpgradeBanner.module.scss';

type UpgradeBannerProps = {
  testID?: string;
  onButtonClick?: () => void;
};

const TRANSLATION_PREFIX =
  'components.campaign-brief.components.upgrade-banner';

function TitleText({testID}: {testID: string}) {
  return (
    <span data-testid={`${testID}--title`}>
      {translate(`${TRANSLATION_PREFIX}.title`)}
    </span>
  );
}

function Subtitle() {
  return (
    <span className={styles.subtitle}>
      {translate(`${TRANSLATION_PREFIX}.subtitle`)}
    </span>
  );
}

function UpgradeBanner({
  testID = 'auto-fill-banner',
  onButtonClick = () => {},
}: UpgradeBannerProps) {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={styles.container}
      data-testid={testID}
      onClick={onButtonClick}
    >
      <div className={styles.bannerContainer}>
        <div className={styles.content}>
          <div className={styles.titlesContainer}>
            <div className={styles.titleContainer}>
              <TitleText testID={testID} />
            </div>
            <Subtitle />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeBanner;
