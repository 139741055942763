import {useLayoutEffect, useRef, useState} from 'react';

function useElementHeight(deps: any[] = [], initialHeight = 0) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementHeight, setElementHeight] = useState(initialHeight);

  useLayoutEffect(() => {
    setElementHeight(elementRef.current?.clientHeight || 0);
  }, [elementRef, ...deps]);

  return {elementRef, elementHeight};
}

export default useElementHeight;
