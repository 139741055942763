import React, {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';
import styles from './ElementInView.module.scss';

type ElementInViewProps = {
  onInView: (options?: any) => void | Promise<any>;
  threshold?: number;
  height?: number;
};

function ElementInView(props: ElementInViewProps) {
  const {onInView, threshold = 0, height = 0} = props;
  const {ref, inView} = useInView({
    /* Optional options */
    threshold,
  });

  useEffect(() => {
    if (inView) {
      onInView?.();
    }
  }, [inView]);

  return (
    <div
      style={{height}}
      className={`${styles.container} element-in-view full-width-item`}
      ref={ref}
    />
  );
}

export default ElementInView;
