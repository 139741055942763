import React from 'react';
import styles from './BreadcrumbSeparator.module.scss';

type BreadcrumbSeparatorProps = {
  className?: string;
};

function BreadcrumbSeparator({className}: BreadcrumbSeparatorProps) {
  return (
    <span className={`${styles.breadcrumbSeparator} ${className}`}>
      &#8725;
    </span>
  );
}

export default BreadcrumbSeparator;
