import React, {useMemo} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Button, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {FiltersGroupId} from '@/types/models/search-creators/filterId';
import searchCreatorsGetters from '@/views/creators/search/utils/searchCreatorsGetters';
import ButtonWithNumber from '@/components/button-with-number';
import useSearchCreatorsStore, {
  searchCreatorsActions,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './ActionDrawerButtons.module.scss';

const TRANSLATION_PREFIX = 'views.creators.components.action-drawer-buttons';

const FILTERS_GROUPS_IDS = [
  'platforms',
  'creatorDetails',
  'keywords',
] as FiltersGroupId[];

type ActionDrawerButtonsProps = {
  onActionDrawerButtonClick?: (label: string) => void;
};

function ActionDrawerButtons(props: ActionDrawerButtonsProps) {
  const {onActionDrawerButtonClick} = props;
  const [filtersGroups, filtersGroupsSnapshot, openedFiltersGroup] =
    useSearchCreatorsStore(
      useShallow((state) => [
        state.content.filtersState.filtersGroups,
        state.content.filtersState.filtersGroupsSnapshot,
        state.content.filtersState.openedFiltersGroup,
      ])
    );

  const activeFilterGroups: Record<FiltersGroupId, number | undefined> =
    useMemo(() => {
      return FILTERS_GROUPS_IDS.reduce((acc, filterGroupId) => {
        acc[filterGroupId] =
          searchCreatorsGetters.getFiltersGroupActiveFiltersCount(
            filtersGroupsSnapshot[filterGroupId]
          );
        return acc;
      }, {} as Record<FiltersGroupId, number | undefined>);
    }, [filtersGroupsSnapshot]);

  const clearButtonVisible: boolean = useMemo(() => {
    return Object.values(activeFilterGroups).some(
      (value) => value !== undefined && value > 0
    );
  }, [activeFilterGroups]);

  return (
    <div className={styles.container}>
      <Label size="md">{translate(`${TRANSLATION_PREFIX}.filter-by`)}</Label>
      {FILTERS_GROUPS_IDS.map((filterGroupId) => {
        const label = translate(filtersGroups[filterGroupId].localeLabelKey);
        const active = openedFiltersGroup === filterGroupId;
        return (
          <ButtonWithNumber
            key={filterGroupId}
            label={label}
            active={active}
            onClick={() => {
              if (active) {
                searchCreatorsActions.closeFiltersDrawer();
              } else {
                searchCreatorsActions.openFiltersDrawer(filterGroupId);
              }
              onActionDrawerButtonClick?.(label);
            }}
            number={activeFilterGroups[filterGroupId]}
          />
        );
      })}
      {clearButtonVisible ? (
        <Button
          size="small"
          mode="plain"
          appearance="neutral"
          sx={{padding: '0 12px'}}
          onClick={() => {
            searchCreatorsActions.updateSearchQuery('');
            searchCreatorsActions.resetAllFilters();
            onActionDrawerButtonClick?.(
              translate(`${TRANSLATION_PREFIX}.clear-all`)
            );
          }}
        >
          <Label size="md">
            {translate(`${TRANSLATION_PREFIX}.clear-all`)}
          </Label>
        </Button>
      ) : null}
    </div>
  );
}

export default ActionDrawerButtons;
