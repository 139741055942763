import React from 'react';
import {Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import styles from './CampaignTypeOptions.module.scss';

const TRANSLATION_PREFIX = 'components.modal-new-campaign-wizard';

const CAMPAIGN_TYPES: {
  key: CampaignTypes;
  iconName: IconName;
  testID: string;
  titleLocaleLabelKey: string;
  descriptionLocaleLabelKey: string;
}[] = [
  {
    key: 'brand-awareness',
    iconName: 'Navigation-Megaphone',
    testID: 'brand-awareness-option',
    titleLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.brand-awareness.title`,
    descriptionLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.brand-awareness.description`,
  },
  {
    key: 'content-creation',
    iconName: 'Navigation-Photos-Options',
    testID: 'content-creation-option',
    titleLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.content-creation.title`,
    descriptionLocaleLabelKey: `${TRANSLATION_PREFIX}.campaign-types.content-creation.description`,
  },
];

export const CAMPAIGN_TYPE: {[key: string]: string} = {
  BRAND_AWARENESS: 'brand-awareness',
  CONTENT_CREATION: 'content-creation',
} as const;

export type CampaignTypes =
  | typeof CAMPAIGN_TYPE.BRAND_AWARENESS
  | typeof CAMPAIGN_TYPE.CONTENT_CREATION
  | null;

type CampaignTypeOptionProps = {
  type: CampaignTypes;
  handleClick: (type: CampaignTypes) => void;
  iconName: IconName;
  titleLocaleLabelKey: string;
  descriptionLocaleLabelKey: string;
  testID?: string;
};

function CampaignTypeOption(props: CampaignTypeOptionProps) {
  const {
    type,
    handleClick,
    iconName,
    titleLocaleLabelKey,
    descriptionLocaleLabelKey,
    testID = 'campaign-type-option',
  } = props;
  return (
    <div
      className={styles.campaignTypeOption}
      onClick={() => handleClick(type)}
      data-testid={testID}
    >
      <Icon size="large" appearance="neutral" name={iconName} />
      <h3>{translate(titleLocaleLabelKey)}</h3>
      <span>{translate(descriptionLocaleLabelKey)}</span>
    </div>
  );
}

type CampaignTypeOptionsProps = {
  setCampaignType: (type: CampaignTypes) => void;
};

function CampaignTypeOptions(props: CampaignTypeOptionsProps) {
  const {setCampaignType} = props;
  return (
    <div className={styles.content}>
      <h2 className={styles.contentTitle}>
        {translate('components.modal-new-campaign-wizard.title')}
      </h2>
      <div className={styles.campaignTypeOptions}>
        {CAMPAIGN_TYPES.map((type) => (
          <CampaignTypeOption
            key={type.key}
            type={type.key}
            handleClick={setCampaignType}
            iconName={type.iconName}
            testID={type.testID}
            titleLocaleLabelKey={type.titleLocaleLabelKey}
            descriptionLocaleLabelKey={type.descriptionLocaleLabelKey}
          />
        ))}
      </div>
    </div>
  );
}

export default CampaignTypeOptions;
