import React, {useMemo} from 'react';
import {Body, Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './MetricCard.module.scss';

interface MetricCardProps {
  title: string;
  value: number;
  communityAverage: number;
}

function MetricCard(props: MetricCardProps) {
  const {title, value, communityAverage} = props;

  const valueAsPercentage = `${(value * 100).toFixed(2)}%`;

  const valueCommunityAverageDiffPercent = (value - communityAverage) * 100;

  const valueCommunityAverageDiffPercentFormatted = useMemo(() => {
    let formattedVal = valueCommunityAverageDiffPercent.toFixed(2);
    let output = translate(
      'components.social-network-identity-summary.health-scores.community-average.above',
      {
        percentage: formattedVal,
      }
    );

    if (valueCommunityAverageDiffPercent < 0) {
      formattedVal = formattedVal.substring(1); // remove leading negative sign
      output = translate(
        'components.social-network-identity-summary.health-scores.community-average.below',
        {
          percentage: formattedVal,
        }
      );
    }

    return output;
  }, [valueCommunityAverageDiffPercent]);

  return (
    <div className={styles.metricCardContainer}>
      <div className={styles.valueContainer}>
        <Headline size="xl">{valueAsPercentage}</Headline>
        <Body size="md">{title}</Body>
        <Body size="md">{valueCommunityAverageDiffPercentFormatted}</Body>
      </div>
    </div>
  );
}

export default MetricCard;
