import i18n from 'i18next';
import translations from '@/locales/en.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import legacyTranslations from '@/locales/enLegacy.js';

type NestedKeyOf<Obj extends object> = {
  [Key in keyof Obj]: Obj[Key] extends object
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${Key}` | `${Key}.${NestedKeyOf<Obj[Key]>}`
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${Key}`;
}[keyof Obj];

type TranslationKeys = NestedKeyOf<typeof translations>;
type LegacyTranslationKeys = NestedKeyOf<typeof legacyTranslations>;

/**
 * Translation helper function
 *
 * This function is a helper to access translation strings using the
 * i18n library. It supports both:
 *
 * 1. Autocompletion for keys defined in the flat translation structure
 *    (`TranslationKeys`) and in the legacy translation structure (`LegacyTranslationKeys`).
 * 2. Legacy translation keys (nested structure)
 *    for backward compatibility.
 *
 * @param {TranslationKeys | LegacyTranslationKeys} key - The translation key,
 *        either a valid key from the flat `translations` object or a
 *        legacy string. Autocomplete is available for valid flat
 *        translation keys and valid legacy translation keys.
 * @param {any} [options] - Optional parameters that can be passed to
 *        the i18n `t` function, such as dynamic values for placeholders
 *        in the translation string.
 *
 * @returns {string} The translated string if the key exists in the
 *          translation file. If the key is missing, it returns the key
 *          itself as a fallback, which is useful for identifying
 *          missing translations during development.
 *
 * ### Typing Details:
 *
 * **`key`**:
 *   - A valid key from the flat `translations` object
 *     (`TranslationKeys`), which benefits from TypeScript's
 *     type-checking and autocomplete.
 *   - A valid key from the legacy `translations` object (`LegacyTranslationKeys`)
 *
 *
 * @example
 * // Flat translation key with autocomplete support
 * const message = translate('out-of');
 *
 * // Legacy translation key with autocomplete support
 * const legacyMessage = translate(
 *   'views.ai-content-generator.components.image-viewer.save-to-library.tooltip-title.already-saved'
 * );
 *
 * ### Return Value:
 *
 * - Translated string: If the key exists in the i18n translation
 *   files.
 * - Fallback: If the key does not exist, the function returns the
 *   key itself as a fallback, making it easier to spot missing
 *   translations.
 */

// eslint-disable-next-line @typescript-eslint/ban-types
const translate = (
  key: TranslationKeys | LegacyTranslationKeys,
  options?: any
) => {
  if (i18n.exists(key, options)) {
    return `${i18n.t(key, options)}`;
  }
  return `${key}`;
};

export default translate;
