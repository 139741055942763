import React, {useMemo} from 'react';
import {Body, Button, Headline, Modal} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import useIsMobile from '@/hooks/use-is-mobile';
import useModalSubscriptionStore, {
  type ModalState,
} from '@/stores/modalSubscriptionStore';
import styles from './ModalSubscription.module.scss';
import {Entry, Payment, Footer, Result} from './upgrade-actions';
import UpgradeDetails from './upgrade-details';

const TRANSLATION_PREFIX = 'components.modal-subscription';

export type ModalSubscriptionProps = {
  testID?: string;
  open: boolean;
  state: ModalState;
  heading: string;
  subheading: string;
  image?: string;
  upsellFeatures: string[];
  planName: string;
  priceLabel?: string;
  priceFootnote: string;
  showContactSalesInFooter?: boolean;
  handleContactSalesClick: () => void;
  submitLabel: string;
  handleSubmitClick: () => void;
  handleClose?: () => void;
  paymentType?: string;
};

function ModalSubscription(props: ModalSubscriptionProps) {
  const {
    testID = 'modal-subscription',
    open,
    state,
    heading,
    subheading,
    image,
    upsellFeatures,
    planName,
    priceFootnote,
    showContactSalesInFooter = true,
    handleContactSalesClick,
    priceLabel,
    submitLabel,
    handleSubmitClick,
    handleClose,
    paymentType,
  } = props;

  const isMobile = useIsMobile();
  const {loading} = useModalSubscriptionStore();

  const renderEntryStateHeading = useMemo(() => {
    return (
      <>
        <Headline size="sm">{heading}</Headline>
        <Body size="lg" className={styles.subheading}>
          {subheading}
        </Body>
      </>
    );
  }, [heading, subheading]);

  const renderEntryStateButton = useMemo(() => {
    return (
      <Button
        isLoading={loading}
        appearance="brand"
        mode="filled"
        size="medium"
        onClick={handleSubmitClick}
        className={styles.submitButton}
      >
        {submitLabel ??
          translate(`${TRANSLATION_PREFIX}.content-right.entry.submit`)}
      </Button>
    );
  }, [submitLabel, loading]);

  const renderFooter = useMemo(() => {
    if (['payment', 'result'].includes(state)) return null;

    return (
      <Footer
        showContactSales={showContactSalesInFooter}
        handleContactSalesClick={handleContactSalesClick}
        paymentType={paymentType}
        mobileButton={renderEntryStateButton}
      />
    );
  }, [handleContactSalesClick, showContactSalesInFooter, state]);

  const upgradeActions = {
    entry: (
      <Entry
        image={image}
        paymentType={paymentType}
        entryStateButton={!isMobile ? renderEntryStateButton : null}
        entryStateHeading={!isMobile ? renderEntryStateHeading : null}
      />
    ),
    payment: <Payment />,
    result: <Result />,
  };

  return (
    <Modal
      showCloseButton
      open={open}
      testID={testID}
      width={800}
      style={{padding: '8px'}}
      handleClose={() => {
        handleClose?.();
      }}
      fullScreen={isMobile}
      className={styles.subscriptionModal}
    >
      <>
        <div
          className={`${styles.container} ${
            state === 'result' ? styles.result : ''
          }`}
        >
          {!isMobile || (isMobile && state === 'entry') ? (
            <UpgradeDetails
              upsellFeatures={upsellFeatures}
              planName={planName}
              priceLabel={priceLabel}
              priceFootnote={priceFootnote}
              heading={isMobile ? renderEntryStateHeading : null}
            />
          ) : null}
          <div className={styles.upgradeActionsContainer}>
            {upgradeActions[state]}
            {!isMobile && renderFooter}
          </div>
        </div>
        {isMobile && renderFooter}
      </>
    </Modal>
  );
}

export default ModalSubscription;
