import React, {useEffect} from 'react';
import {
  Headline,
  Body,
  Icon,
  designSystemToken,
  Tooltip,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import PresetSingleThumb from '@/views/ai-content-generator/components/preset-single-thumb';
import ReferenceImage from '@/views/ai-content-generator/components/reference-image';
import {SEPARATOR_CHARS_LENGTH} from '@/views/ai-content-generator/generated-content/dashboard/Dashboard';
import {
  useContentGenerationStore,
  GeneratedStyle,
  getStyleType,
  getPresetData,
} from '@/contexts/ContentGenerationStore';
import styles from './LookAndFeel.module.scss';

export type Preset = {
  name: string;
  promptLength: number;
  negativePromptLength: number;
  thumbUrl: string;
};

export type LookAndFeelProps = {
  presets: Preset[];
  userPrompt: string;
  negativeUserPrompt: string;
  limitPromptInput: number;
  productNamePrefixSubtract: number;
  isPresetsFetched: boolean;
  setIsReferenceImageLoading: (isLoading: boolean) => void;
  setIsReferenceImageSelected: (isReferenceImageSelected: boolean) => void;
  isReferenceImageSelected: boolean;
};

function LookAndFeel(props: LookAndFeelProps) {
  const {
    presets,
    userPrompt,
    negativeUserPrompt,
    limitPromptInput,
    productNamePrefixSubtract,
    isPresetsFetched,
    setIsReferenceImageLoading,
    setIsReferenceImageSelected,
    isReferenceImageSelected,
  } = props;

  const {state, dispatch} = useContentGenerationStore();
  const generateStyleType = getStyleType(state);
  const {selectedPreset} = getPresetData(state);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_STYLE_TYPE',
      styleType: GeneratedStyle.none,
    });
  }, []);

  useEffect(() => {
    if (isPresetsFetched) {
      const defaultPreset = presets[0];
      if (defaultPreset) {
        handlePresetSelection(
          defaultPreset.name,
          defaultPreset.promptLength,
          defaultPreset.negativePromptLength,
          defaultPreset.thumbUrl
        );
      }
    }
  }, [isPresetsFetched]);

  const handlePresetSelection = (
    name: string,
    promptLength: number,
    negativePromptLength: number,
    thumbUrl: string
  ) => {
    const presetNegativePromptLimit: number =
      limitPromptInput - negativePromptLength - SEPARATOR_CHARS_LENGTH;
    const presetPromptLimit: number =
      limitPromptInput - promptLength - productNamePrefixSubtract;
    dispatch({
      type: 'UPDATE_PRESET',
      selectedPreset: name,
      presetThumbnailPath: thumbUrl,
      promptValue: userPrompt.slice(
        0,
        presetPromptLimit - userPrompt.length < 0
          ? presetPromptLimit
          : userPrompt.length
      ),
      negativePromptValue: negativeUserPrompt.slice(
        0,
        presetNegativePromptLimit - negativeUserPrompt.length < 0
          ? presetNegativePromptLimit
          : negativeUserPrompt.length
      ),
      presetPromptLength: promptLength,
      presetNegativePromptLength: negativePromptLength,
    });

    dispatch({
      type: 'UPDATE_STYLE_TYPE',
      styleType: GeneratedStyle.preset,
    });

    setIsReferenceImageSelected(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Headline size="sm">
          <div className={styles.headlineHorizontalContainer}>
            {translate(
              'views.ai-content-generator.components.style-reference.title'
            )}
          </div>
        </Headline>
        <Body className={styles.subTitleReference} size="sm">
          {translate(
            'views.ai-content-generator.components.style-reference.content'
          )}
        </Body>
      </div>
      <div className={styles.referenceImageStyle}>
        <ReferenceImage
          setIsReferenceImageLoading={setIsReferenceImageLoading}
          setIsReferenceImageSelected={setIsReferenceImageSelected}
          isReferenceImageSelected={isReferenceImageSelected}
        />
      </div>
      <div className={styles.presetStyle}>
        <div className={styles.presetBank}>
          {presets?.map((item) => {
            return (
              <PresetSingleThumb
                isSelected={
                  selectedPreset === item.name ||
                  generateStyleType === GeneratedStyle.none
                }
                image={item.thumbUrl}
                name={item.name}
                key={item.name}
                handleClick={() =>
                  handlePresetSelection(
                    item.name,
                    item.promptLength,
                    item.negativePromptLength,
                    item.thumbUrl
                  )
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LookAndFeel;
