import React from 'react';
import {Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import BreadcrumbLabel from '@/components/breadcrumb-label';
import BreadcrumbSeparator from '@/components/breadcrumb-separator';
import Link from '@/components/link';
import styles from './Campaign.module.scss';

const TRANSLATION_PREFIX = 'components.brand-header.child-components.campaign';

function Campaign() {
  return (
    <div className={styles.container}>
      <Link to="/campaigns" className={styles.linkContainer}>
        <Icon
          size="medium"
          appearance="disabled"
          name="Actions-Arrow-Back-Small"
        />
        <BreadcrumbLabel
          testID="breadcrumb-label--generic"
          label={translate(`${TRANSLATION_PREFIX}.breadcrumbs.my-campaigns`)}
          className={styles.breadcrumbLabelLink}
        />
      </Link>
      <BreadcrumbSeparator className={styles.breadcrumbSeparator} />
      <BreadcrumbLabel
        testID="breadcrumb-label--generic"
        label={translate(`${TRANSLATION_PREFIX}.breadcrumbs.new-campaign`)}
        className={styles.breadcrumbLabelText}
      />
    </div>
  );
}

export default Campaign;
