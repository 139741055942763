import React from 'react';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import styles from './IconComponent.module.scss';

function IconComponent({
  className,
  onClear,
  containerStyle,
}: {
  className: string;
  containerStyle?: string;
  onClear?: () => void;
}) {
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      {onClear && (
        <div className={styles.clearIcon} onClick={onClear}>
          <Icon
            size="medium"
            appearance="neutral"
            name="Actions-Close-Small"
            color={designSystemToken('semantic.fg.secondary')}
          />
        </div>
      )}
      <div
        className={`${styles.caretIcon} ${
          className.includes('iconOpen') ? styles.iconOpen : ''
        }`}
      >
        <Icon
          size="medium"
          appearance="neutral"
          name="Actions-Arrow-Down-Small"
          color={designSystemToken('semantic.fg.secondary')}
        />
      </div>
    </div>
  );
}

export default IconComponent;
