import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {
  designSystemToken,
  Label,
  SwitchButton,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {WeightedFilterOption} from '@/types/models/search-creators/filter';
import MultiSelectionOptionsDropDown from '@/views/creators/components/actions-drawer/filters/multi-selection-options-drop-down';
import SelectedOptionsWithWeightsDropDown from '@/views/creators/components/actions-drawer/filters/weight-selection/options-with-weights-drop-down/SelectedOptionsWithWeightsDropDown';
import {WeightedFilterProps} from '../FilterProps';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer.filters';

interface AudienceAgeProps extends WeightedFilterProps {
  options: WeightedFilterOption[];
  selectedOptions: WeightedFilterOption[];
  weights: Record<string, number>;
  placeholder?: string;
  weightPlaceholder?: string;
  onChange: (option: WeightedFilterOption) => void;
  onUpdateWeight: (optionId: string, weightId: string) => void;
  isLdaSelected: boolean;
  onUpdateLda: (checked: boolean) => void;
}

function AudienceAge(props: AudienceAgeProps) {
  const {
    options,
    selectedOptions,
    weights,
    placeholder,
    weightPlaceholder,
    onChange,
    onUpdateWeight,
    isLdaSelected,
    onUpdateLda,
  } = props;

  const mappedSelectedOptions = useMemo(
    () =>
      selectedOptions.map((option) => ({
        option,
        label: translate(option.localeLabelKey),
        selectedWeight: Object.entries(weights).find(
          ([, value]) => value === option.weight
        )?.[0],
      })),
    [selectedOptions]
  );

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing="16px"
        sx={{justifyContent: 'space-between', marginBottom: '16px'}}
      >
        <div style={{display: 'flex'}}>
          <Label size="md">
            {translate(`${TRANSLATION_PREFIX}.age-options.lda`)}
          </Label>
          <Label
            size="md"
            sx={{
              paddingLeft: '4px',
              color: designSystemToken('semantic.fg.inverse-secondary'),
            }}
          >
            {translate(`${TRANSLATION_PREFIX}.age-lda-description`)}
          </Label>
        </div>
        <SwitchButton
          checked={isLdaSelected}
          onChange={(event, checked) => onUpdateLda(checked)}
        />
      </Stack>
      <MultiSelectionOptionsDropDown
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
        disabled={isLdaSelected}
        placeholder={placeholder}
      />
      <SelectedOptionsWithWeightsDropDown
        selectedOptions={mappedSelectedOptions}
        onDeleteSelection={onChange}
        weights={Object.keys(weights)}
        weightPlaceholder={weightPlaceholder}
        onUpdateWeight={onUpdateWeight}
      />
    </Stack>
  );
}

export default AudienceAge;
