import {useMutation} from '@tanstack/react-query';
import queryClient from '@/utils/reactQueryClient';
import brandProfileNoteUpdaters from '@/api/updaters/brandProfileNote';

function useUpdateBrandProfileNote({
  brandId,
  profileId,
}: {
  brandId: string;
  profileId: string;
}) {
  return useMutation({
    mutationKey: ['brandProfileNote', 'update'],
    mutationFn: brandProfileNoteUpdaters.update,
    onSuccess: () => {
      queryClient.invalidateQueries(['brandProfileNote', brandId, profileId]);
    },
  });
}

export default useUpdateBrandProfileNote;
