import React, {useEffect, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Popover} from '@mui/material';
import {Icon} from '@lightricks/react-design-system';
import AnalyticsService from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES} from '@/lib/analytics/analyticsConstants';
import downloadImageFromUrl from '@/utils/downloadImageFromUrl';
import getCapsuleAiImageUrl from '@/utils/getCapsuleAiImageUrl';
import translate from '@/utils/translate';
import capsuleAiUpdaters from '@/api/updaters/capsuleAi';
import Spinner from '@/views/ai-content-generator/components/spinner';
import useCreateAiContent from '@/hooks/mutations/capsule-ai/use-create-ai-content';
import useReplaceBgWithReferenceImage from '@/hooks/mutations/capsule-ai/use-replace-bg-with-reference-image';
import useReplaceImageBackground from '@/hooks/mutations/capsule-ai/use-replace-image-background';
import {useBannerStore, BannerSeverity} from '@/contexts/BannerContext';
import {
  GeneratedStyle,
  getsavedImagesToLibrary,
  useContentGenerationStore,
  ImageSavedItemProps,
  getTransparentImageDimensions,
} from '@/contexts/ContentGenerationStore';
import styles from './GeneratedImage.module.scss';

type GeneratedImageProps = {
  id: string | number;
  tempId?: string;
  projectId?: number;
  brandId: string;
  transparentImageUrl: string;
  promptParams?: PromptParams;
  imageUrl: string;
  imageThumbUrl?: string;
  onGenerateImageError: () => void;
  flowId: string;
  sessionId?: number;
  isSavedToLibrary: boolean;
  index?: number;
  eventInteractionId?: string;
  generateEventDetails?: string;
};

type PromptParams = {
  presetName?: string;
  productName?: string;
  userPrompt?: string;
  userNegativePrompt?: string;
  categoryId?: string;
  uuid: string;
  transparentImageUrl: string;
  sessionId?: number;
  width?: number;
  height?: number;
  brand_id: string;
  style_type: GeneratedStyle;
  referenceImage: string;
};

function GeneratedImage(props: GeneratedImageProps) {
  const {
    id,
    tempId,
    projectId,
    brandId,
    promptParams,
    imageUrl,
    imageThumbUrl,
    flowId,
    isSavedToLibrary,
    index,
    onGenerateImageError,
    eventInteractionId,
    generateEventDetails,
  } = props;
  const [realImageId, setRealImageId] = useState<number | string>(id);
  const [isLoading, setIsLoading] = useState(false);
  const [imageIsSaved, setImageIsSaved] = useState(isSavedToLibrary);
  const [imageUrlProxy, setImageUrlProxy] = useState(imageUrl);
  const [imageThumbUrlProxy, setImageThumbUrlProxy] = useState(imageThumbUrl);
  const replaceImageBackground = useReplaceImageBackground({tempId});
  const replaceBgWithReferenceImage = useReplaceBgWithReferenceImage({tempId});
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const contentClass = `${styles.overlay} ${menuOpen ? styles.active : ''}`;
  const createAiContent = useCreateAiContent();
  const {dispatch: bannerDispatch} = useBannerStore();
  const {state, dispatch} = useContentGenerationStore();
  const imageWasSavedToLibrary: ImageSavedItemProps[] =
    getsavedImagesToLibrary(state);
  const {transparentImageWidth, transparentImageHeight} =
    getTransparentImageDimensions(state);

  useEffect(() => {
    setImageIsSaved(
      imageWasSavedToLibrary.filter((image) => image.id === id)[0]
        ?.isSavedToLibrary || imageIsSaved
    );
  }, [state.savedImages]);
  const showBannerMessage = (
    message: string,
    severity: BannerSeverity,
    icon: React.ReactNode | undefined = undefined
  ) => {
    bannerDispatch({
      type: 'SET_BANNER',
      title: '',
      message,
      severity,
      icon,
    });
  };
  const onMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setMenuOpen(false);
  };

  const onSaveToLibrary = async (url: string) => {
    if (imageIsSaved) return;

    const interactionId = uuid();

    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_INTERACTION, {
      interaction_name: 'clicked_save_to_library',
      interaction_type: 'click',
      interaction_location: 'gallery',
      interaction_details: JSON.stringify({
        image_url: url,
      }),
      interaction_id: interactionId,
    });
    const processId = uuid();

    AnalyticsService.dispatchEvent(
      EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_STARTED,
      {
        flow_id: flowId,
        process_source: 'clicked_save_to_library_gallery_view',
        asset_type: 'photo',
        asset_size: '',
        process_details: JSON.stringify({
          image_url: url,
        }),
        process_id: processId,
        interaction_id: interactionId,
      }
    );

    try {
      await createAiContent.mutateAsync({
        url,
        merchantId: brandId,
        kind: 'photo',
        capsuleAiContentId: realImageId,
      });
      await capsuleAiUpdaters.updateIsSavedToLibrary({
        id,
        isSavedToLibrary: true,
      });
      dispatch({
        type: 'UPDATE_IMAGES_WAS_SAVED_TO_LIBRARY',
        savedImagesToLibrary: [{id, isSavedToLibrary: true}],
      });
      showBannerMessage(
        translate(
          'views.ai-content-generator.components.image-viewer.save-to-library.success'
        ),
        'success',
        <Icon size="large" appearance="brand" name="Actions-LooksOn" />
      );
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
        {
          flow_id: flowId,
          result: 'success',
          process_details: '',
          process_id: processId,
          error: null,
        }
      );
    } catch (e: any) {
      let errorMessage = '';
      if (
        e.status === '422' &&
        e.message.includes('Capsule ai content has already been taken')
      ) {
        errorMessage = translate(
          'views.ai-content-generator.components.image-viewer.save-to-library.already-exist'
        );
      } else {
        errorMessage = translate(
          'views.ai-content-generator.components.image-viewer.save-to-library.add-image-error'
        );
      }
      showBannerMessage(errorMessage, 'warning');
      AnalyticsService.dispatchEvent(
        EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
        {
          flow_id: flowId,
          result: 'failed',
          process_details: '',
          process_id: processId,
          error: errorMessage,
        }
      );
    }
    setMenuOpen(false);
  };

  const onDownload = async (url: string) => {
    await downloadImageFromUrl(url);
    setMenuOpen(false);

    AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_INTERACTION, {
      interaction_name: 'downloaded_image',
      interaction_type: 'click',
      interaction_location: 'gallery',
      interaction_details: JSON.stringify({
        image_url: url,
      }),
      interaction_id: uuid(),
    });
  };

  const delay = (ms: number) =>
    new Promise((res) => {
      setTimeout(res, ms);
    });

  useEffect(() => {
    (async () => {
      if (tempId && !imageUrlProxy) {
        const processId = uuid();
        try {
          setIsLoading(true);
          AnalyticsService.dispatchEvent(
            EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_STARTED,
            {
              flow_id: flowId,
              process_source: 'clicked_generate',
              asset_type: 'photo',
              asset_size: `${transparentImageWidth}x${transparentImageHeight}`,
              process_details: generateEventDetails,
              process_id: processId,
              interaction_id: eventInteractionId,
            }
          );
          const callReferenceImageEp =
            promptParams?.referenceImage &&
            promptParams?.style_type === GeneratedStyle.reference;

          await delay((index || 0) * 1000);
          const {data: image} = callReferenceImageEp
            ? await replaceBgWithReferenceImage.mutateAsync(promptParams)
            : await replaceImageBackground.mutateAsync(promptParams);

          setRealImageId(id);
          dispatch({
            type: 'REPLACE_DUMMY_WITH_IMAGE',
            image: {
              ...image.image,
              imageUrl: getCapsuleAiImageUrl(image.image.imageUrl),
              imageThumbUrl: getCapsuleAiImageUrl(image.image.imageThumbUrl),
            },
          });

          setIsLoading(false);
          AnalyticsService.dispatchEvent(
            EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
            {
              flow_id: flowId,
              result: 'success',
              process_details: generateEventDetails,
              process_id: processId,
              error: null,
            }
          );
        } catch (error: any) {
          console.log('error', error);
          dispatch({
            type: 'REMOVE_DUMMY_FROM_STACK',
          });

          onGenerateImageError();
          AnalyticsService.dispatchEvent(
            EVENT_NAMES.AI_STUDIO_ASSET_PROCESS_ENDED,
            {
              flow_id: flowId,
              result: 'failed',
              process_details: generateEventDetails,
              process_id: processId,
              error:
                error?.response?.data?.detail || error?.response?.data?.message,
            }
          );
        }
      }
    })();
  }, []);

  const handlePopoverClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const handleImageClick = (url: string) => {
    if (url.trim() !== '') {
      dispatch({type: 'UPDATE_SELECTED_IMAGE_URL', selectedImage: url});
      dispatch({type: 'UPDATE_SELECTED_IMAGE_ID', id: realImageId});

      AnalyticsService.dispatchEvent(EVENT_NAMES.AI_STUDIO_ASSET_INTERACTION, {
        interaction_name: 'opened_image_preview',
        interaction_type: 'click',
        interaction_location: 'gallery',
        interaction_details: JSON.stringify({
          image_id: realImageId,
          image_url: url,
        }),
        interaction_id: uuid(),
      });
    }
  };
  const getContent = () => {
    return (
      <div>
        <div
          className={styles.container}
          onClick={() => handleImageClick(imageUrlProxy)}
        >
          {isLoading ? null : (
            <div className={contentClass}>
              <span onClick={onMenuOpen}>
                <Icon size="large" appearance="white" name="Actions-More" />
              </span>
            </div>
          )}
          <Popover
            slotProps={{paper: {sx: {borderRadius: '24px'}}}}
            open={menuOpen}
            onClose={onCloseMenu}
            anchorEl={anchorEl}
            onClick={handlePopoverClick}
          >
            <ul className={styles.menu}>
              <li>
                <div
                  className={`${styles.menuItem} ${
                    imageIsSaved ? styles.disabled : ''
                  }`}
                  onClick={() => onSaveToLibrary(imageUrlProxy)}
                >
                  <Icon
                    size="medium"
                    appearance={imageIsSaved ? 'disabled' : 'neutral'}
                    name={
                      imageIsSaved
                        ? 'Actions-LooksOn'
                        : 'Navigation-Photos-Light'
                    }
                  />
                  <span>
                    {imageIsSaved
                      ? translate(
                          'views.ai-content-generator.components.image-viewer.save-to-library.title.already-saved'
                        )
                      : translate(
                          'views.ai-content-generator.components.image-viewer.save-to-library.title.save-to-library'
                        )}
                  </span>
                </div>
              </li>
              <li>
                <div
                  className={styles.menuItem}
                  onClick={() => onDownload(imageUrlProxy)}
                >
                  <Icon
                    size="medium"
                    appearance="neutral"
                    name="Navigation-Download"
                  />
                  <span>
                    {translate(
                      'views.ai-content-generator.components.image-viewer.download.title'
                    )}
                  </span>
                </div>
              </li>
            </ul>
          </Popover>
          {!imageUrlProxy ? (
            <div>
              <Spinner size={26} />
            </div>
          ) : (
            <img
              src={getCapsuleAiImageUrl(imageThumbUrlProxy) || imageUrlProxy}
              alt=""
              className={styles.img}
            />
          )}
          {imageIsSaved && (
            <div className={styles.saving}>
              <Icon size="medium" appearance="white" name="Actions-LooksOn" />
            </div>
          )}
        </div>
      </div>
    );
  };

  return getContent();
}

export default GeneratedImage;
