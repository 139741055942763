import {BrandProfileNote} from '@/types/brandProfileNote';
import brandProfileNoteFetchers from '@/api/fetchers/brandProfileNote';
import useDataQuery from '@/hooks/use-data-query';

function useBrandProfileNoteQuery({
  brandId,
  profileId,
  enabled = true,
}: {
  brandId: string;
  profileId?: string;
  enabled?: boolean;
}) {
  return useDataQuery<BrandProfileNote>({
    queryKey: ['brandProfileNote', brandId, profileId],
    queryFn: brandProfileNoteFetchers.note,
    enabled,
    meta: {brandId, profileId},
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

export default useBrandProfileNoteQuery;
