import api from '@/api';
import QueryContext from '../QueryContext';

export const DEFAULT_TIKTOK_ACCOUNT_INCLUDE = ['current_audience'];

const tiktokAccountFetcher = {
  account: (context: QueryContext) =>
    api.get(`/tiktok_accounts/${context?.meta?.accountId}`, {
      params: {
        include: context?.meta?.include || DEFAULT_TIKTOK_ACCOUNT_INCLUDE,
      },
    }),
};

export default tiktokAccountFetcher;
