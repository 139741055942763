import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import CreatorGroupMembership, {
  type QueryPage,
} from '@/types/creatorGroupMembership';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import usePreferredCreatorGroupMemberships from '@/views/creators/lists/hooks/usePreferredCreatorGroupMemberships';

function useUpdateMembershipLabels() {
  const {membershipQueryKey} = usePreferredCreatorGroupMemberships();

  const key = [
    'creatorGroupsMemberships',
    ...Object.values(membershipQueryKey),
  ];

  return useMutation({
    mutationKey: ['creatorGroupsMemberships', 'update'],
    mutationFn: creatorGroupUpdaters.updateMembershipLabels,
    onMutate: async (data) => {
      await queryClient.cancelQueries({queryKey: ['creatorGroupsMemberships']});

      const previousData = queryClient.getQueryData<{
        pageParams?: number[];
        pages: QueryPage[];
      }>(key);

      queryClient.setQueryData(key, (old: typeof previousData) => {
        if (!old) return undefined;

        const hasMembership = (membership: CreatorGroupMembership) =>
          membership.id === data.membership.id;
        const newPage = (page: QueryPage) => page.data.some(hasMembership);
        const index = old.pages.findIndex(newPage);

        return {
          ...old,
          pages: produce(old.pages, (draft: QueryPage[]) => {
            if (index === -1) return;

            const membership = draft[index].data.find(hasMembership);
            if (membership) membership.labels = data.labels;
          }),
        };
      });

      return {previousData};
    },
    onError: (_err, _, context) => {
      queryClient.setQueryData(key, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useUpdateMembershipLabels;
