import React, {useMemo} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import truncateText from '@/utils/truncateText';
import {
  SingleUserInput,
  AudienceAgeSelection,
  BaseFilterOption,
  Filter,
  MultiSelection,
  MultiSelectionAsync,
  DynamicPlatformFilters,
  Range,
  RangeSelection,
  SingleSelection,
  WeightedFilterOption,
  WeightedSingleSelection,
  MultiUserInput,
} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FiltersGroupId,
  PlatformId,
  PlatformFilterIds,
} from '@/types/models/search-creators/filterId';
import {
  LdaUpdate,
  WeightUpdate,
} from '@/types/models/search-creators/filterUpdate';
import AccordionList from '@/views/creators/components/actions-drawer/accordion-list';
import {ActionProps} from '@/views/creators/components/actions-drawer/actions/ActionProps';
import Filters from '@/views/creators/components/actions-drawer/filters';
import searchCreatorsGetters from '@/views/creators/search/utils/searchCreatorsGetters';
import useDebouncedQuery from '@/hooks/use-debounced-query';
import useSearchCreatorsStore, {
  searchCreatorsActions,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './Actions.module.scss';

const MAX_SUBTITLE_TEXT_LENGTH = 20;

type FilterProps = {
  filtersGroupId: FiltersGroupId;
  filterId: FilterIds;
  filterValue: Filter;
  platformFilterIds?: PlatformFilterIds;
};

function SingleSelectionOptionsSegmentedControl({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as SingleSelection<BaseFilterOption>;
  return (
    <Filters.SingleSelectionOptionsSegmentedControl
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={castedFilterValue.options}
      selectedOption={castedFilterValue.selected}
    />
  );
}

function SingleSelectionOptionsDropDown({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as SingleSelection<BaseFilterOption>;
  return (
    <Filters.SingleSelectionOptionsDropDown
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={castedFilterValue.options}
      selectedOption={castedFilterValue.selected}
      placeholder={
        castedFilterValue.placeholderLocaleLabelKey
          ? translate(castedFilterValue.placeholderLocaleLabelKey)
          : undefined
      }
    />
  );
}

function WeightedSingleSelectionOptionsSegmentedControl({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue =
    filterValue as WeightedSingleSelection<WeightedFilterOption>;
  const selectedWeight = Object.keys(castedFilterValue.weights).find((key) => {
    return (
      castedFilterValue.weights[key] === castedFilterValue.selected?.weight
    );
  });
  return (
    <Filters.WeightedSingleSelectionOptionsSegmentedControl
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      weights={Object.keys(castedFilterValue.weights)}
      onUpdateWeight={(optionId: string, weightId: string) => {
        const weightUpdate: WeightUpdate = {
          updateType: 'weight',
          optionId,
          weight: weightId,
        };
        searchCreatorsActions.updateFilterOption(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          weightUpdate
        );
      }}
      options={castedFilterValue.options}
      selectedOption={castedFilterValue.selected}
      selectedWeightId={selectedWeight}
      weightPlaceholder={
        castedFilterValue.weightPlaceholderLocaleLabelKey
          ? translate(castedFilterValue.weightPlaceholderLocaleLabelKey)
          : undefined
      }
      weightDisabled={!castedFilterValue.selected?.value}
    />
  );
}

function AudienceAge({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as AudienceAgeSelection;
  return (
    <Filters.AudienceAge
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      weights={castedFilterValue.weights}
      onUpdateWeight={(optionId: string, weightId: string) => {
        const weightUpdate: WeightUpdate = {
          updateType: 'weight',
          optionId,
          weight: weightId,
        };
        searchCreatorsActions.updateFilterOption(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          weightUpdate
        );
      }}
      onUpdateLda={(checked: boolean) => {
        const ldaUpdate: LdaUpdate = {
          updateType: 'lda',
          isLdaSelected: checked,
        };
        searchCreatorsActions.updateFilterOption(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          ldaUpdate
        );
      }}
      options={castedFilterValue.options}
      selectedOptions={castedFilterValue.selected}
      weightPlaceholder={
        castedFilterValue.weightPlaceholderLocaleLabelKey
          ? translate(castedFilterValue.weightPlaceholderLocaleLabelKey)
          : undefined
      }
      isLdaSelected={castedFilterValue.ldaSelected}
      placeholder={
        castedFilterValue.placeholderLocaleLabelKey
          ? translate(castedFilterValue.placeholderLocaleLabelKey)
          : undefined
      }
    />
  );
}

function SingleSelectionOptionsGridWithIcon({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as SingleSelection<BaseFilterOption>;
  const options = castedFilterValue.options.map((option) => ({
    ...option,
    ...platformIdToPlatformIconProps(option.id as PlatformId, 'medium'),
  }));
  return (
    <Filters.SingleSelectionOptionsGridWithIcon
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={options}
      selectedOption={castedFilterValue.selected}
    />
  );
}

function SingleSelectionOptionsRadioButtonsListWithIcon({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as SingleSelection<BaseFilterOption>;
  const options = castedFilterValue.options.map((option) => ({
    ...option,
    ...platformIdToPlatformIconProps(option.id as PlatformId, 'medium'),
  }));
  return (
    <Filters.SingleSelectionOptionsRadioButtonsListWithIcon
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={options}
      selectedOption={castedFilterValue.selected}
    />
  );
}

const ICON_SIZE_OVERRIDES = {
  small: {
    width: 14,
    height: 12,
  },
  medium: {
    width: 22,
    height: 18,
  },
} as {[key: string]: {small?: object; medium?: object}};

function platformIdToPlatformIconProps(
  platformId: PlatformId | 'all',
  iconSize: 'small' | 'medium'
): {
  iconName: IconName | undefined;
  iconStyle?: {small?: object; medium?: object};
} {
  switch (platformId) {
    case 'all':
      return {
        iconName: 'Navigation-Grid',
        iconStyle: ICON_SIZE_OVERRIDES[iconSize],
      };
    case 'facebook':
      return {iconName: 'Social-Facebook'};
    case 'instagram':
      return {iconName: 'Social-Instagram'};
    case 'pinterest':
      return {iconName: 'Social-Pinterest'};
    case 'tiktok':
      return {iconName: 'Social-TikTok'};
    case 'youtube':
      return {iconName: 'Social-YouTube'};
    case 'twitter':
      return {iconName: 'Social-X'};
    default:
      return {iconName: undefined};
  }
}

function MultiSelectionOptionsList({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as MultiSelection<BaseFilterOption>;
  return (
    <Filters.MultiSelectionOptionsList
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={castedFilterValue.options}
      selectedOptions={castedFilterValue.selected}
    />
  );
}

function MultiSelectionOptionsDropDown({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as MultiSelection<BaseFilterOption>;
  return (
    <Filters.MultiSelectionOptionsDropDown
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={castedFilterValue.options}
      selectedOptions={castedFilterValue.selected}
      placeholder={
        castedFilterValue.placeholderLocaleLabelKey
          ? translate(castedFilterValue.placeholderLocaleLabelKey)
          : undefined
      }
    />
  );
}

function useLocationQuery(
  getOptions: (query: string) => Promise<BaseFilterOption[]>
) {
  const [query, setQuery] = React.useState<string>('');

  const {data: options, isFetching} = useDebouncedQuery({
    queryKey: ['filters', 'useLocationQuery', query],
    queryFn: () => getOptions(query),
    enabled: !!query,
    delay: 500,
  });

  return {setQuery, options, isFetching};
}

function MultiSelectionOptionsAutocompleteAsync({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue =
    filterValue as MultiSelectionAsync<BaseFilterOption>;

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const {setQuery, options, isFetching} = useLocationQuery(
    castedFilterValue.getOptions
  );

  return (
    <Filters.MultiSelectionOptionsAutocompleteAsync
      onInputChange={onInputChange}
      loading={isFetching}
      onChange={(value: BaseFilterOption) =>
        searchCreatorsActions.changeFilterOptionSelection(
          castedFilterValue.type,
          filtersGroupId,
          filterId,
          value,
          platformFilterIds
        )
      }
      options={options || castedFilterValue.selected}
      selectedOptions={castedFilterValue.selected}
      placeholder={
        castedFilterValue.placeholderLocaleLabelKey
          ? translate(castedFilterValue.placeholderLocaleLabelKey)
          : undefined
      }
    />
  );
}

function RangeSelectionSliderAndInput({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
  noInputs,
}: FilterProps & {noInputs?: boolean}) {
  const castedFilterValue = filterValue as RangeSelection;
  const selectedRange: [number | undefined, number | undefined] =
    useMemo(() => {
      return [castedFilterValue.selected?.min, castedFilterValue.selected?.max];
    }, [castedFilterValue.selected]);

  const handleRangeChange = ([rangeMin, rangeMax]: [
    number | undefined,
    number | undefined
  ]) => {
    let newSelectedRange: Range | undefined;
    if (rangeMin !== undefined || rangeMax !== undefined) {
      newSelectedRange = {min: rangeMin, max: rangeMax} as Range;
    }
    searchCreatorsActions.changeFilterRange(
      filtersGroupId,
      filterId,
      newSelectedRange,
      platformFilterIds
    );
  };

  return (
    <Filters.RangeSelectionSliderAndInput
      selectedRange={selectedRange}
      steps={castedFilterValue.steps}
      onChange={handleRangeChange}
      noInputs={noInputs}
    />
  );
}

function MultiUserInputWithChips({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as MultiUserInput;
  return (
    <Filters.MultiUserInputWithChips
      onChange={(input: string) =>
        searchCreatorsActions.changeUserInputFilter(
          filtersGroupId,
          filterId,
          input,
          platformFilterIds
        )
      }
      inputs={castedFilterValue.inputs}
      placeholder={
        castedFilterValue.placeholderLocaleLabelKey
          ? translate(castedFilterValue.placeholderLocaleLabelKey)
          : undefined
      }
    />
  );
}

function UserInput({
  filtersGroupId,
  filterId,
  filterValue,
  platformFilterIds,
}: FilterProps) {
  const castedFilterValue = filterValue as SingleUserInput;
  return (
    <Filters.UserInput
      onChange={(input: string) =>
        searchCreatorsActions.changeSingleUserInputFilter(
          filtersGroupId,
          filterId,
          input,
          platformFilterIds
        )
      }
      value={castedFilterValue.input}
      placeholder={
        castedFilterValue.placeholderLocaleLabelKey
          ? translate(castedFilterValue.placeholderLocaleLabelKey)
          : undefined
      }
    />
  );
}

function Actions(props: ActionProps) {
  const {filtersGroupId} = props;

  const filtersGroup = useSearchCreatorsStore(
    useShallow(
      (state) => state.content.filtersState.filtersGroups[filtersGroupId]
    )
  );

  const {filters} = filtersGroup;

  const getFilterSubtitle = (filterId: FilterIds) => {
    const selectionString = searchCreatorsGetters.getActiveSelectionString(
      filtersGroup,
      filterId
    );

    const selectionIcon = searchCreatorsGetters.getActiveSelectionIconName(
      filtersGroup,
      filterId
    );

    const subtitleText = truncateText(
      selectionString,
      MAX_SUBTITLE_TEXT_LENGTH
    );

    if (selectionIcon) {
      const {iconName, iconStyle} = platformIdToPlatformIconProps(
        selectionIcon as PlatformId,
        'small'
      );
      return (
        <div className={styles.subtitleWithIconContainer}>
          <Icon
            name={iconName as IconName}
            style={iconStyle}
            size="small"
            appearance="neutral"
            color={designSystemToken('semantic.fg.inverse-secondary')}
          />
          {subtitleText}
        </div>
      );
    }
    return subtitleText;
  };

  const getFilterComponent = (
    filterId: FilterIds,
    filterValue: Filter,
    platformFilterIds?: PlatformFilterIds
  ) => {
    const baseProps = {
      filtersGroupId,
      filterId,
      filterValue,
      platformFilterIds,
    };
    switch (filterValue.presentationType) {
      case 'singleSelectionOptionsSegmentedControl': {
        return <SingleSelectionOptionsSegmentedControl {...baseProps} />;
      }
      case 'weightedSingleSelectionOptionsSegmentedControl':
        return (
          <WeightedSingleSelectionOptionsSegmentedControl {...baseProps} />
        );
      case 'singleSelectionOptionsGridWithIcon':
        return <SingleSelectionOptionsGridWithIcon {...baseProps} />;
      case 'singleSelectionOptionsRadioButtonsListWithIcon':
        return (
          <SingleSelectionOptionsRadioButtonsListWithIcon {...baseProps} />
        );
      case 'singleSelectionOptionsDropDown':
        return <SingleSelectionOptionsDropDown {...baseProps} />;
      case 'multiSelectionOptionsList': {
        return <MultiSelectionOptionsList {...baseProps} />;
      }
      case 'multiSelectionOptionsDropDown':
        return <MultiSelectionOptionsDropDown {...baseProps} />;
      case 'multiSelectionOptionsAutocompleteAsync':
        return <MultiSelectionOptionsAutocompleteAsync {...baseProps} />;
      case 'rangeSelectionSlider':
        return <RangeSelectionSliderAndInput {...baseProps} noInputs />;
      case 'rangeSelectionSliderAndInput':
        return <RangeSelectionSliderAndInput {...baseProps} />;
      case 'multiUserInputWithChips':
        return <MultiUserInputWithChips {...baseProps} />;
      case 'audienceAge':
        return <AudienceAge {...baseProps} />;
      case 'singleUserInput':
        return <UserInput {...baseProps} />;
      case 'platformFiltersGroup': {
        const castedFilterValue = filterValue as DynamicPlatformFilters;
        const platformFilters = Object.entries(
          castedFilterValue.platformsFilters[castedFilterValue.platformId]
        ).map(([platformFilterId, platformFilterValue]) => ({
          id: platformFilterId,
          title: translate(platformFilterValue.localeLabelKey),
          Component: getFilterComponent(
            filterId,
            platformFilterValue,
            platformFilterId as PlatformFilterIds
          ),
        }));
        return (
          <Filters.PlatformFiltersGroup platformFilterProps={platformFilters} />
        );
      }

      default:
        return null;
    }
  };

  const filtersList = Object.entries(filters)
    .filter(([, filterValue]) => !filterValue.isIrrelevant)
    .map(([filterId, filterValue]) => ({
      id: filterId,
      title: translate(filterValue.localeLabelKey),
      subtitle: getFilterSubtitle(filterId as FilterIds),
      expanded:
        !filtersGroup.isCollapsable ||
        filtersGroup?.expandedFilter === filterId,
      expandIcon: filtersGroup.isCollapsable ? 'default' : null,
      Component: getFilterComponent(filterId as FilterIds, filterValue),
    }));

  return (
    <div>
      <AccordionList
        items={filtersList}
        onAccordionChange={(id) =>
          searchCreatorsActions.toggleExpandFilter(
            filtersGroupId,
            id as FilterIds
          )
        }
      />
    </div>
  );
}

export default Actions;
