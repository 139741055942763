export const MONTH_DAY_YEAR_FORMAT = 'MMM DD, YYYY'; // example: Jan 01, 2020
export const SHORT_MONTH_SHORT_DAY_COMMA_YEAR_FORMAT = 'MMM DD, YYYY'; // example: Jan 01, 2020
export const MONTH_SHORT_DAY_YEAR_FORMAT = 'MMM D, YYYY'; // example: Jan 1, 2020
export const MONTH_DAY_FORMAT = 'MMM D'; // example: Jan 1
export const DAY_HOUR_MINUTE_FORMAT = 'ddd h:mm A'; // example: Mon 12:00 PM
export const MONTH_YEAR_FORMAT = 'MMMM YYYY'; // example: January 2020
export const YEAR_MONTH_FORMAT = 'YYYY-MM'; // example: 2020-01
export const SHORT_MONTH_YEAR_FORMAT = 'MMM YYYY'; // example: Jan 2020
export const SHORT_DAY_SHORT_MONTH_YEAR_FORMAT = 'DD MMM YYYY'; // example: 01 Jan 2020
export const US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR = 'MM/DD/YYYY'; // example: 01/01/2020
export const SHORT_MONTH_DAY_YEAR_FORMAT = 'MMM DD, YYYY'; // example: 01 January 2020
export const SHORT_MONTH_FORMAT = 'MMM'; // example: Jan
export const SHORT_DAY_FORMAT = 'dd'; // example: Mon
export const NUMERIC_DAY_MONTH_YEAR_FORMAT = 'DD-MM-YYYY'; // example: 01-01-2020
