declare global {
  interface Window {
    pendo: {
      initialize(options: any): void;
      identify(options: any): void;
      detele?: () => void | undefined;
    };
  }
}

export type DashboardUser = {
  id: string | undefined;
  email: string | undefined;
};

export type Brand = {
  id: string | undefined;
  name: string | undefined;
};

export default class Pendo {
  static initialize(dashboardUser: DashboardUser, brand: Brand) {
    if (!window.pendo) {
      return;
    }
    window.pendo.initialize(this.options(dashboardUser, brand));
  }

  static identify(dashboardUser: DashboardUser, brand: Brand) {
    if (!window.pendo) {
      return;
    }

    window.pendo.identify(this.options(dashboardUser, brand));
  }

  private static options(dashboardUser: DashboardUser, brand: Brand) {
    return {
      visitor: {
        id: dashboardUser?.id,
        email: dashboardUser?.email,
        dashboard_user_id: dashboardUser?.id,
        dashboard_user_email: dashboardUser?.email,
        brand_id: brand?.id,
        brand_name: brand?.name,
      },

      account: {
        id: brand?.id,
        name: brand?.name,
      },
    };
  }
}
