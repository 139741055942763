import React from 'react';
import {Stack} from '@mui/material';

type SocialStatProps = {
  title: string;
  value?: number | undefined;
  titleColor?: string;
  valueColor?: string;
  formatingFunc: (num: number) => string;
};

function SocialStat({
  title,
  value,
  titleColor,
  valueColor,
  formatingFunc,
}: SocialStatProps) {
  return value != null ? (
    <Stack direction="row">
      <span style={{color: titleColor, marginRight: '2px'}}>{`${title}`}</span>
      <span style={{color: valueColor, fontWeight: 700}}>{`${formatingFunc(
        value
      )}`}</span>
    </Stack>
  ) : (
    ''
  );
}

export default SocialStat;
