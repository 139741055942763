import {useMemo} from 'react';
import mapIdentityPost from '@/utils/mapIdentityPost';
import {Post} from '@/types/post';
import QueryContext from '@/api/QueryContext';
import identityFetcher from '@/api/fetchers/identity';
import useInfiniteDataQuery from '@/hooks/use-infinite-data-query';

interface Page {
  data: Post[];
  jsonapi: {version: string};
  meta: {
    pagination: {currentPage: number; totalPages: number; totalEntries: number};
  };
}

interface IdentityPostsResponse {
  data?: {pages: Page[]};
}

function useIdentityPaginatedPostsQuery({
  identityId,
  queryParams = {},
}: {
  identityId?: string;
  queryParams?: {[key: string]: string | number | boolean};
}) {
  const response = useInfiniteDataQuery({
    queryKey: ['identityPaginatedPosts', identityId, queryParams],
    queryFn: ({pageParam = 1, ...rest}: QueryContext) =>
      identityFetcher.paginatedPosts({pageParam, ...rest}),
    enabled: !!identityId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
    meta: {
      identityId,
      ...queryParams,
    },
    getNextPageParam: (lastPage: Page) => {
      if (!lastPage.meta) {
        return undefined;
      }
      const {meta} = lastPage;
      return meta.pagination.currentPage < meta.pagination.totalPages
        ? meta.pagination.currentPage + 1
        : undefined;
    },
  });

  const {data = {}} = response;
  const {pages = []}: {pages?: Page[]} = data || {};
  const meta = pages[pages.length - 1]?.meta;

  const posts = useMemo(() => {
    if (!response.data) {
      return [];
    }
    return pages
      .flatMap((page: {data: Post[]}) => page?.data)
      .map(mapIdentityPost);
  }, [response.data]);

  return {
    ...response,
    data: posts,
    meta,
  };
}

export default useIdentityPaginatedPostsQuery;
