import {difference, isEqual, sortBy} from 'lodash';
import React, {useState, useMemo, useEffect} from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';
import {Checkbox} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import IconComponent from '@/components/select/icon-component';
import CheckmarkRadioButton from '../../checkmark-radio-button';
import {Option, SelectVariant} from '../SelectProps';
import styles from './MultipleSelect.module.scss';

const TRANSLATION_PREFIX = 'components.select';

const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

export const SELECT_ALL_ID = '__ALL__';

const DEFAULT_VARIANT_PROPS = (onClear?: () => void) => ({
  inputProps: {
    'aria-label': translate(`${TRANSLATION_PREFIX}.select-all-that-apply`),
  },
  displayEmpty: true,
  IconComponent: ({className}: {className: string}) => (
    <IconComponent className={className} onClear={onClear} />
  ),
  MenuProps: {
    PaperProps: {
      sx: {
        marginTop: '8px',
        maxHeight: '40vh',
        borderRadius: '24px',
      },
    },
  },
});

export type MultipleSelectProps = {
  label?: string;
  labelId?: string;
  options: Option[];
  optionLabelKey?: string;
  variant?: SelectVariant;
  onChange?: (value: string[]) => void;
  selectedValueIds?: string[];
  renderSelectedLabel?: (options: Option[]) => string | React.ReactNode;
  immediateOnChange?: boolean;
  onClose?: () => void;
  onClear?: () => void;
  checkmarkRadioButtonClassName?: string;
  withSelectAllOption?: boolean;
  selectAllLabel?: string;
  disabled?: boolean;
  containerClassName?: string;
};

function inputLabel(label: string, labelId: string, variant: SelectVariant) {
  if (!label) return null;

  const variantLabelProps = {
    default: {},
    onboarding: {
      shrink: false,
    },
  };

  return (
    <InputLabel
      id={label ? labelId : undefined}
      className={`${styles[variant]} ${styles.label}`}
      {...variantLabelProps[variant]}
    >
      {label}
    </InputLabel>
  );
}

function MultipleSelect(props: MultipleSelectProps) {
  const {
    label = '',
    labelId = '',
    options,
    optionLabelKey = 'label',
    onChange,
    variant = 'default',
    selectedValueIds = [],
    renderSelectedLabel,
    immediateOnChange,
    onClose,
    onClear,
    checkmarkRadioButtonClassName = '',
    withSelectAllOption,
    selectAllLabel,
    disabled,
    containerClassName,
  } = props;
  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(selectedValueIds);

  const selectedOptionsMap = useMemo(
    () =>
      selectedOptions.reduce((acc: {[key: string]: boolean}, curr: string) => {
        acc[curr] = true;
        return acc;
      }, {}),
    [selectedOptions]
  );

  useEffect(() => {
    if (immediateOnChange) {
      setSelectedOptions(selectedValueIds);
    }
  }, [selectedValueIds, immediateOnChange]);

  useEffect(() => {
    const selectedValuesIdsSource = immediateOnChange
      ? selectedValueIds
      : selectedOptions;
    if (withSelectAllOption) {
      if (
        isEqual(
          sortBy(
            selectedValuesIdsSource.filter((option) => option !== SELECT_ALL_ID)
          ),
          sortBy(options.map((option) => option.id))
        ) &&
        !selectedValuesIdsSource.includes(SELECT_ALL_ID)
      ) {
        setSelectedOptions([SELECT_ALL_ID, ...selectedValuesIdsSource]);
      } else if (
        !isEqual(
          sortBy(
            selectedValuesIdsSource.filter((option) => option !== SELECT_ALL_ID)
          ),
          sortBy(options.map((option) => option.id))
        ) &&
        selectedValuesIdsSource.includes(SELECT_ALL_ID)
      ) {
        setSelectedOptions(
          selectedValuesIdsSource.filter((option) => option !== SELECT_ALL_ID)
        );
      }
    }
  }, [withSelectAllOption, selectedValueIds]);

  const getSelectAllOption = () => {
    return {
      id: SELECT_ALL_ID,
      [optionLabelKey]: selectAllLabel,
    };
  };

  const selectOptions = useMemo(() => {
    if (withSelectAllOption) {
      return [getSelectAllOption(), ...options];
    }
    return options;
  }, [options, withSelectAllOption, selectAllLabel]);

  const variantProps = useMemo(
    () => ({
      default: DEFAULT_VARIANT_PROPS(
        selectedOptions?.length && !disabled ? onClear : undefined
      ),
      onboarding: DEFAULT_VARIANT_PROPS(
        selectedOptions?.length && !disabled ? onClear : undefined
      ),
    }),
    [selectedOptions]
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: {value},
    } = event;
    let newSelectedOptions =
      typeof value === 'string' ? value.split(',') : value;
    const added = difference(newSelectedOptions, selectedOptions);
    const removed = difference(selectedOptions, newSelectedOptions);

    if (added.includes(SELECT_ALL_ID)) {
      newSelectedOptions = [
        SELECT_ALL_ID,
        ...options.map((option) => option.value || option.id),
      ];
    } else if (removed.includes(SELECT_ALL_ID)) {
      newSelectedOptions = [];
    }

    if (immediateOnChange) {
      onChange?.(
        newSelectedOptions.filter((option) => option !== SELECT_ALL_ID)
      );
    } else {
      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleClose = (event: any) => {
    if (!immediateOnChange) {
      onChange?.(selectedOptions.filter((option) => option !== SELECT_ALL_ID));
    }
    onClose?.();
  };

  const handleRender = (selected: string[]) => {
    const {placeholder, [variant]: variantClass} = styles;

    if (renderSelectedLabel) {
      const newSelectedOptions = options.filter((option) =>
        selected.includes(option.id)
      );

      if (selected.includes(SELECT_ALL_ID)) {
        newSelectedOptions.unshift(getSelectAllOption() as Option);
      }

      return renderSelectedLabel(newSelectedOptions);
    }

    switch (selected.length) {
      case 0:
        return (
          <span className={`${variantClass} ${placeholder}`}>
            {translate(`${TRANSLATION_PREFIX}.select-all-that-apply`)}
          </span>
        );
      case 1:
        return options.find((option) => option.id === selected[0])?.[
          optionLabelKey
        ];
      default:
        return translate(`${TRANSLATION_PREFIX}.selected`, {
          count: selected.length,
        });
    }
  };

  return (
    <>
      {inputLabel(label, labelId, variant)}
      <Select
        className={`${styles[variant]} ${styles.container} ${
          containerClassName || ''
        }`}
        labelId={label ? labelId : undefined}
        label={label}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        onClose={handleClose}
        renderValue={handleRender}
        disabled={disabled}
        {...variantProps[variant]}
      >
        {selectOptions.map((option) => (
          <MenuItem
            key={`menu-item-${option.id}`}
            value={option.id}
            focusVisibleClassName={styles.optionFocused}
            className={`${styles[variant]} ${styles.option}`}
          >
            {variant === 'default' && (
              <div className={styles.checkbox}>
                <Checkbox
                  checked={!!selectedOptionsMap[option.id]}
                  onChange={() => {}}
                  customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
                  customUncheckedIcon={
                    <img src={checkboxUncheckedSrc} alt="" />
                  }
                />
              </div>
            )}
            <ListItemText primary={option[optionLabelKey]} />
            {variant === 'onboarding' && (
              <CheckmarkRadioButton
                className={`${styles[variant]} ${styles.checkmarkRadioButton} ${checkmarkRadioButtonClassName}`}
                checked={!!selectedOptionsMap[option.id]}
              />
            )}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default MultipleSelect;
