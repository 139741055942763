import React from 'react';
import {Box, Stack, styled} from '@mui/material';
import {
  Button,
  designSystemToken,
  Icon,
  Tooltip,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';

const ACTIONS_BAR_SPACING = 24;

export type ActionItem = {
  id: string;
  icon: IconName;
  tooltip: string;
  onClick: () => void;
  disabled?: boolean;
  hidden?: boolean;
};

export type ActionsBarProps = {
  actionItems: ActionItem[];
};

const ButtonContainer = styled(Box)`
  &:hover {
    background: ${({className}) =>
      className === 'disabled'
        ? 'unset'
        : designSystemToken('semantic.bg.tertiary')};
    border-radius: 8px;
  }
`;

export function ActionsBar(props: ActionsBarProps) {
  const {actionItems} = props;
  return (
    <Stack direction="row" spacing={`${ACTIONS_BAR_SPACING}px`}>
      {actionItems.map((actionItem) => (
        <Tooltip
          key={actionItem.id}
          type="dark"
          title={actionItem.tooltip}
          placement="top"
          arrow
        >
          <ButtonContainer className={actionItem.disabled ? 'disabled' : ''}>
            <Button
              id={actionItem.id}
              size="medium"
              appearance="neutral"
              mode="plain"
              onClick={actionItem.onClick}
              disabled={actionItem.disabled}
              icon={
                <Icon
                  size="medium"
                  appearance="neutral"
                  name={actionItem.icon}
                  color={
                    actionItem.disabled
                      ? designSystemToken('semantic.fg.disabled')
                      : undefined
                  }
                />
              }
            />
          </ButtonContainer>
        </Tooltip>
      ))}
    </Stack>
  );
}
