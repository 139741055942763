import {
  CampaignBriefDeliverableItem,
  CampaignDeliverableCounters,
} from '@/types/campaign';

const CAMPAIGN_BRIEF_DELIVERABLES_COUNT_MAP = {
  story_post: ['requiredStoryPostsCount', 'mixedMediaToUpload'],
  feed_post_photo: ['requiredFeedPostsCount', 'photosToUpload'],
  reel_post: ['requiredReelsPostsCount', 'videosToUpload'],
  tiktok_video: ['requiredFeedPostsCount', 'videosToUpload'],
  youtube_short_form_video: ['videosToUpload'],
  youtube_long_form_video: ['videosToUpload'],
  youtube_short: ['videosToUpload'],
  content_standard_photo: ['photosToUpload'],
  content_premium_photo: ['photosToUpload'],
  content_standard_video: ['videosToUpload'],
  content_premium_video: ['videosToUpload'],
} as {[RequiredDeliverableTypeOptions: string]: CampaignDeliverableCounters[]};

function getBriefDeliverableCounters(
  campaignBriefDeliverableItems: CampaignBriefDeliverableItem[]
) {
  const counters = campaignBriefDeliverableItems.reduce(
    (acc: {[CampaignDeliverableCounters: string]: number}, {itemType}) => {
      const currentKeys = CAMPAIGN_BRIEF_DELIVERABLES_COUNT_MAP[itemType];
      currentKeys.forEach((currentKey) => {
        const currentValue = acc[currentKey] || 0;
        acc[currentKey] = currentValue + 1;
      });
      return acc;
    },
    {}
  );

  counters.minimumChildContentSubmissionCount = counters.requiredStoryPostsCount
    ? 3
    : 0;
  return {
    ...counters,
    requiredStoryPostsCount: counters.requiredStoryPostsCount || 0,
    mixedMediaToUpload: counters.mixedMediaToUpload || 0,
    requiredFeedPostsCount: counters.requiredFeedPostsCount || 0,
    requiredReelsPostsCount: counters.requiredReelsPostsCount || 0,
    videosToUpload: counters.videosToUpload || 0,
    photosToUpload: counters.photosToUpload || 0,
  };
}

export default getBriefDeliverableCounters;
