import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {TextareaAutosizeProps} from 'react-textarea-autosize/dist/declarations/src';
import styles from './Textarea.module.scss';

export interface TextareaProps extends TextareaAutosizeProps {
  testID?: string;
  containerClassName?: string;
  className?: string;
  label?: string;
  readOnly?: boolean;
}

function Textarea(props: TextareaProps) {
  const {
    testID = 'textarea',
    containerClassName,
    className,
    label,
    readOnly = false,
    ...rest
  } = props;

  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {label ? <label className={styles.label}>{label}</label> : null}
      <TextareaAutosize
        className={`${styles.textarea} ${
          readOnly ? styles.readOnly : ''
        } ${className}`}
        data-testid={testID}
        {...rest}
      />
    </div>
  );
}

export default Textarea;
