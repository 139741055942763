import React, {useEffect} from 'react';
import {Skeleton} from '@mui/material';
import {screenNames} from '@/lib/delta/deltaConstants.js';
import {handleScreenPresentedEvent} from '@/components/modal-subscription/AnalyticsEventsHandler';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './Entry.module.scss';

const TRANSLATION_PREFIX = 'components.modal-subscription.content-right.entry';

export type EntryProps = {
  testID?: string;
  image?: string;
  paymentType?: string;
  entryStateButton: JSX.Element | null;
  entryStateHeading: JSX.Element | null;
};

function Entry(props: EntryProps) {
  const {
    testID = 'modal-subscription-state-entry',
    image,
    paymentType,
    entryStateButton,
    entryStateHeading,
  } = props;

  const isMobile = useIsMobile();

  useEffect(() => {
    handleScreenPresentedEvent({
      screenName:
        paymentType === 'invoice'
          ? screenNames.SUBSCRIPTION_MODAL.CONTACT_SALES
          : screenNames.SUBSCRIPTION_MODAL.UPGRADE_OFFER,
    });
  }, []);

  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.top}>{entryStateHeading}</div>
      {image ? (
        <div className={isMobile ? styles.imageContainer : ''}>
          <img src={image} alt="upgrade" className={styles.image} />
        </div>
      ) : (
        <Skeleton variant="rectangular" className={styles.image} />
      )}
      {entryStateButton}
    </div>
  );
}

export default Entry;
