import React from 'react';
import {v4 as uuid} from 'uuid';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {SOURCE_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import PERMISSIONS from '@/config/permissions';
import {CREATOR_ACTION_KEYS} from '@/views/creators/components/creator-action/CreatorAction';
import {ActionItem} from '@/views/creators/components/creators-actions-bar/ActionsBar';
import getCreatorIdsForAnalytics from '@/views/creators/utils/getCreatorIdsForAnalytics';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useDashboardUserPermissions from '@/hooks/use-dashboard-user-permissions';
import useNavigation from '@/hooks/use-navigation';
import useToken from '@/hooks/use-token';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

const TRANSLATION_PREFIX = 'views.creators';

function useActionItems() {
  const {navigate} = useNavigation();
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const {isAuthenticated} = useToken(true);
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  const {viewCollaborations} = useDashboardUserPermissions(
    [PERMISSIONS.COLLABORATIONS.VIEW],
    dashboardUser?.permissions
  );

  const anyDeactivated = [...selectedCreators.values()].some(
    (membership) => membership.status === 'deactivated'
  );

  const anyPending = [...selectedCreators.values()].some(
    (membership) => membership.pending === true
  );

  const conversationIds = [...selectedCreators.values()]
    .filter(
      (membership) =>
        membership?.profile?.currentBrandCollaborationConversation !== undefined
    )
    .map(({profile}) => profile.currentBrandCollaborationConversation.id);

  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const screenPresentationId = uuid();

  const onClickAddToCampaign = () => {
    listCreatorsActions.setActiveCreatorAction('add-to-campaign');

    AnalyticsService.dispatchEvent(eventNames.MY_CREATORS_ACTION_FLOW_STARTED, {
      screen_presentation_id: screenPresentationId,
      action_name: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
      creator_ids: getCreatorIdsForAnalytics([...selectedCreators.values()]),
      flow_name: translate(
        `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
      ),
      campaign_id: '',
      source: SOURCE_NAMES.MY_CREATORS,
    });
  };

  const onClickMessages = () => {
    if (conversationIds.length > 0) {
      navigate(`/collaborations/${conversationIds[0]}`);
    } else {
      listCreatorsActions.setActiveCreatorAction('message-creator');
    }

    AnalyticsService.dispatchEvent(eventNames.MY_CREATORS_ACTION_FLOW_STARTED, {
      screen_presentation_id: screenPresentationId,
      action_name: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
      creator_ids: getCreatorIdsForAnalytics([...selectedCreators.values()]),
      flow_name: translate(
        `${TRANSLATION_PREFIX}.components.creator-action.message-creator.tooltip`
      ),
      campaign_id: '',
      source: SOURCE_NAMES.MY_CREATORS,
    });
  };

  const onClickRemove = () => {
    listCreatorsActions.setActiveCreatorAction('remove-from-my-creators');

    AnalyticsService.dispatchEvent(eventNames.MY_CREATORS_ACTION_FLOW_STARTED, {
      screen_presentation_id: screenPresentationId,
      action_name: CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS,
      creator_ids: getCreatorIdsForAnalytics([...selectedCreators.values()]),
      flow_name: translate(
        `${TRANSLATION_PREFIX}.components.creator-action.remove-creator.tooltip`
      ),
      campaign_id: '',
      source: SOURCE_NAMES.MY_CREATORS,
    });
  };

  return React.useMemo<ActionItem[]>(() => {
    const actions: ActionItem[] = [
      {
        id: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
        icon: 'Navigation-Megaphone',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
        ),
        onClick: onClickAddToCampaign,
        disabled: anyDeactivated,
      },
      {
        id: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
        icon: 'Navigation-Messages',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.message-creator.${
            selectedCreators.size > 1 ? 'bulk-tooltip' : 'tooltip'
          }`
        ),
        onClick: onClickMessages,
        disabled: selectedCreators.size !== 1 || anyDeactivated || anyPending,
        hidden: !viewCollaborations,
      },
      {
        id: CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS,
        icon: 'Actions-Remove',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.remove-creator.tooltip`
        ),
        onClick: onClickRemove,
        hidden: subscription?.isCampaign,
      },
    ];

    const visibleActions = actions.filter((action) => !action.hidden);

    return visibleActions;
  }, [
    selectedCreators,
    anyDeactivated,
    conversationIds,
    navigate,
    subscription,
  ]);
}

export default useActionItems;
