import type {QueryKey} from '@tanstack/query-core';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';

function useReactQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export default useReactQuery;
