import {useMemo} from 'react';
import PERMISSIONS from '@/config/permissions';
import {CreatorProfileAbilities, ProfileProps} from '@/types/creatorProfile';
import useDashboardUserPermissions from '@/hooks/use-dashboard-user-permissions';

interface CreatorProfileAbilitiesProps {
  profile: ProfileProps;
  dashboardUserPermissions: Array<{subjectClass: string; action: string}>;
  displayPublicDataOnly: boolean;
  componentAllowsAddMembership: boolean;
  componentAllowsCollaboration: boolean;
  componentAllowsInviteToCampaign: boolean;
  canViewAudience: boolean;
  canViewSocialHealth: boolean;
  canViewPortfolio: boolean;
  canViewPosts: boolean;
  canViewProfileNotes: boolean;
  showApplicationAnswers: boolean;
}

function useCreatorProfileAbilities({
  profile,
  dashboardUserPermissions,
  displayPublicDataOnly,
  componentAllowsAddMembership,
  componentAllowsCollaboration,
  componentAllowsInviteToCampaign,
  canViewAudience,
  canViewSocialHealth,
  canViewPortfolio,
  canViewPosts,
  canViewProfileNotes,
  showApplicationAnswers,
}: CreatorProfileAbilitiesProps): CreatorProfileAbilities {
  const permissions = useDashboardUserPermissions(
    [
      PERMISSIONS.AUDIENCE.VIEW,
      PERMISSIONS.COLLABORATIONS.CREATE,
      PERMISSIONS.CAMPAIGNS.VIEW,
      PERMISSIONS.CAMPAIGN.INVITE,
      PERMISSIONS.CREATOR_GROUPS.VIEW,
      PERMISSIONS.POSTS.VIEW,
      PERMISSIONS.MEMBERSHIP.CREATE,
      PERMISSIONS.DASHBOARD_USER.SEARCH_FOR_LEGACY_TIKTOK,
      PERMISSIONS.AGENCY_CREATOR_PROFILE.VIEW_SOCIAL_HEALTH,
      PERMISSIONS.AGENCY_CREATOR_PROFILE.VIEW_LAST_ACTIVE_AT,
    ],
    dashboardUserPermissions
  );

  const firstIdentityWithAudience = useMemo(() => {
    if (!profile?.identities) {
      return null;
    }
    return profile.identities.find((identity) => identity.audience);
  }, [profile]);

  const existingBrandMembership = profile?.currentBrandMembership;

  const creatorProfileAbilities = useMemo(() => {
    const userHasMembershipPermissions =
      permissions.createMembership && permissions.viewCreatorGroups;
    const membershipDoesNotExist = Boolean(
      !existingBrandMembership || existingBrandMembership.removedAt
    );
    const membershipExistsOrCanBeAdded =
      userHasMembershipPermissions &&
      (!membershipDoesNotExist || componentAllowsAddMembership);
    const userHasCollaborationsPermissions =
      componentAllowsCollaboration && permissions.createCollaborations;
    const canAddToMyCreators =
      componentAllowsAddMembership && userHasMembershipPermissions;
    const userHasInviteToCampaignPermissions =
      componentAllowsInviteToCampaign && permissions.inviteCampaign;
    const userCanSeeTiktokLegacyIdentity =
      permissions.searchForLegacyTiktokDashboardUser;

    const showMembershipLabels =
      !displayPublicDataOnly &&
      permissions.viewCreatorGroups &&
      existingBrandMembership?.labels &&
      !membershipDoesNotExist;

    // Show buttons based on permissions
    const showCollaborationButton =
      componentAllowsCollaboration && userHasCollaborationsPermissions;
    const showAddToMyCreatorsButton =
      componentAllowsAddMembership && canAddToMyCreators;
    const showInviteToCampaignButton =
      componentAllowsInviteToCampaign && userHasInviteToCampaignPermissions;

    // Show tabs based on permissions
    const showOverviewTab = canViewPortfolio || displayPublicDataOnly;
    const showAudienceTab =
      (permissions.viewAudience && !!firstIdentityWithAudience) ||
      displayPublicDataOnly;
    const showAnalyticsAndAudienceTab =
      permissions.viewSocialHealthAgencyCreatorProfile ||
      canViewSocialHealth ||
      displayPublicDataOnly;
    const showPostsTab =
      canViewPosts || permissions.viewPosts || displayPublicDataOnly;
    const showPastCampaignsTab =
      !displayPublicDataOnly && !!profile?.profilePreviousCampaigns?.length;
    const showNotesTab = canViewProfileNotes || displayPublicDataOnly;
    const showTabs =
      showOverviewTab ||
      showAudienceTab ||
      showAnalyticsAndAudienceTab ||
      showPostsTab;
    const showLastActiveAt = !!permissions.viewLastActiveAtAgencyCreatorProfile;
    const showSocialHealth =
      canViewSocialHealth || permissions.viewSocialHealthAgencyCreatorProfile;
    const showAudience = canViewAudience || permissions.viewAudience;

    return {
      displayPublicDataOnly,
      membershipDoesNotExist,
      userHasMembershipPermissions,
      membershipExistsOrCanBeAdded,
      userHasCollaborationsPermissions,
      userHasInviteToCampaignPermissions,
      userCanSeeTiktokLegacyIdentity,
      showMembershipLabels,
      showCollaborationButton,
      showAddToMyCreatorsButton,
      showInviteToCampaignButton,
      showOverviewTab,
      showAudienceTab,
      showAnalyticsAndAudienceTab,
      showPostsTab,
      showPastCampaignsTab,
      showNotesTab,
      showTabs,
      showLastActiveAt,
      showSocialHealth,
      showAudience,
      showApplicationAnswers,
      canViewPortfolio,
      canViewAudience,
      canViewPosts,
    };
  }, [
    firstIdentityWithAudience,
    profile,
    permissions,
    displayPublicDataOnly,
    existingBrandMembership,
    componentAllowsAddMembership,
    componentAllowsCollaboration,
    componentAllowsInviteToCampaign,
    canViewSocialHealth,
    canViewPortfolio,
    canViewProfileNotes,
    showApplicationAnswers,
  ]);

  return creatorProfileAbilities;
}

export default useCreatorProfileAbilities;
