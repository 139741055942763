import React, {useMemo, useRef} from 'react';
import {Icon, designSystemToken, Label} from '@lightricks/react-design-system';
import AwsService from '@/services/aws/aws';
import Sentry from '@/services/sentry/Sentry';
import translate from '@/utils/translate';
import {VerticalTag} from '@/types/campaign';
import {
  BriefFieldManagerAttributes,
  ErrorMessages,
} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefFieldHelperPopper from '@/views/campaigns/brief/components/brief-field-helper-popper';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import BriefItem from '@/views/campaigns/brief/components/campaign-brief/components/brief-item';
import BriefSubSection from '@/views/campaigns/brief/components/campaign-brief/components/brief-sub-section';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {
  SaveCampaign,
  SectionProps,
} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  DEFAULT_SAVE_CAMPAIGN_DELAY,
  IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY,
} from '@/views/campaigns/brief/constants';
import FileSelector from '@/components/file-selector';
import Skeleton from '@/components/skeleton';
import TagSelector from '@/components/tag-selector';
import useVerticalTagsQuery from '@/hooks/queries/use-vertical-tags-query';
import styles from './BasicDetails.module.scss';

const TRANSLATION_PREFIX = 'components.campaign-brief.sections.basic-details';

function CampaignAndBrandDetails({
  briefFieldManager,
  campaignName,
  campaignImageUrl,
  editable,
  onCampaignNameChange,
  onCampaignImageChange,
  errors,
  saveCampaign,
  campaignId,
  viewPresentationId,
}: {
  briefFieldManager: BriefFieldManagerAttributes;
  campaignName: string;
  campaignImageUrl?: string | null;
  editable?: boolean;
  onCampaignNameChange?: (value: string) => void;
  onCampaignImageChange?: (value: string) => void;
  errors?: ErrorMessages;
  saveCampaign?: SaveCampaign;
  campaignId: string;
  viewPresentationId?: string;
}) {
  const onFilesSelected = async (files: File[]) => {
    if (files.length === 0) return;

    const [file] = files;
    const awsService = new AwsService();

    try {
      const imageUrl = await awsService.uploadToBucket(
        AwsService.s3Settings.buckets.campaignBrief!,
        file
      );
      onCampaignImageChange?.(imageUrl);
    } catch (error: any) {
      Sentry.captureException(error, {message: 'Failed to upload file'});
    }
  };

  const getImage = () => {
    if (!campaignImageUrl) {
      return (
        <div className={styles.imageContainer}>
          <Skeleton
            variant="rounded"
            width="100%"
            height="100%"
            borderRadius={2}
          />
        </div>
      );
    }
    if (errors?.imageUrl) {
      return (
        <div className={`${styles.imagePlaceholder} ${styles.imageContainer}`}>
          <Icon
            size="large"
            appearance="danger"
            name="Navigation-Photos-Light"
          />
        </div>
      );
    }
    return (
      <div className={styles.imageContainer}>
        <img
          src={campaignImageUrl || ''}
          alt={campaignName}
          className={styles.imageStyle}
        />
      </div>
    );
  };

  const getContent = () => {
    return (
      <>
        <FileSelector
          onFilesSelected={onFilesSelected}
          PreviewIcon={
            campaignImageUrl ? (
              <div className={styles.campaignImage}>
                {getImage()}
                {editable ? (
                  <div className={styles.replaceIconContainer}>
                    <Label size="sm" className={styles.replaceIcon}>
                      {translate(`${TRANSLATION_PREFIX}.replace-image`)}
                    </Label>
                  </div>
                ) : null}
              </div>
            ) : (
              <Icon
                size="large"
                color={
                  !errors?.imageUrl
                    ? designSystemToken('semantic.bg.alpha-secondary')
                    : designSystemToken('semantic.fg.danger')
                }
                appearance="neutral"
                name="Navigation-Photos-Adding"
              />
            )
          }
          hasError={!!errors?.imageUrl}
          maxFiles={1}
          acceptTypes={['images']}
          maxSize={10 * 1024 * 1024}
          disabled={!editable}
        />
        <ValidatedField errors={errors?.name}>
          <div className={styles.column}>
            <BriefFieldHelperPopper
              fieldName={briefFieldManager.fields.name?.agentKey}
              fieldValue={briefFieldManager.fields.name?.value}
              onChange={onCampaignNameChange}
              saveCampaign={saveCampaign}
              disabled={!editable}
              campaignId={campaignId}
              viewPresentationId={viewPresentationId}
            >
              {(childrenProps) => (
                <BriefFieldInput
                  {...childrenProps}
                  testID={
                    CAMPAIGN_BRIEF_TEST_IDS.BASIC_DETAILS__CAMPAIGN_NAME_INPUT
                  }
                  label={translate(`${TRANSLATION_PREFIX}.campaign-name`)}
                  disabled={!editable}
                  value={campaignName}
                  placeholder={translate(
                    `${TRANSLATION_PREFIX}.campaign-name-placeholder`
                  )}
                  onInputChange={onCampaignNameChange}
                  inputProps={{
                    onBlur: () => {
                      return saveCampaign?.({
                        campaignId,
                        fieldName: briefFieldManager.fields.name?.agentKey,
                        fieldValue: briefFieldManager.fields.name?.value,
                      });
                    },
                    value: campaignName,
                  }}
                  hasErrors={!!errors?.name}
                />
              )}
            </BriefFieldHelperPopper>
          </div>
        </ValidatedField>
      </>
    );
  };

  return (
    <div className={styles.campaignAndBrandDetailsContainer}>
      <div className={styles.row}>{getContent()}</div>
    </div>
  );
}

function CampaignVerticals({
  verticalTags,
  editable,
  onChange,
  saveCampaign,
  errors,
}: {
  verticalTags: VerticalTag[];
  editable?: boolean;
  onChange?: (selectedOptions: any[]) => void;
  saveCampaign?: SaveCampaign;
  errors?: ErrorMessages;
}) {
  const {data: verticalTagsData} = useVerticalTagsQuery();

  const verticalTagsOptions = useMemo(() => {
    if (!verticalTagsData) {
      return [];
    }

    return (verticalTagsData as VerticalTag[]).map(
      (verticalTag: VerticalTag) => ({
        ...verticalTag,
        id: verticalTag.id,
        value: verticalTag.id,
        label: verticalTag.displayText || verticalTag.name || '',
      })
    );
  }, [verticalTagsData]);

  const selectedVerticalTags = verticalTagsOptions.filter((verticalTagOption) =>
    verticalTags.find((verticalTag) => verticalTag.id === verticalTagOption.id)
  );

  const getCampaignVerticals = () => {
    return (
      <ValidatedField errors={errors?.verticalTags}>
        <TagSelector
          readOnly={!editable}
          disabled={!editable}
          hasError={!!errors?.verticalTags}
          testID={
            CAMPAIGN_BRIEF_TEST_IDS.BASIC_DETAILS__CAMPAIGN_VERTICALS_INPUT
          }
          multiple
          hideSelectedOptions
          addChipLabel={translate(
            `${TRANSLATION_PREFIX}.campaign-verticals-add-button`
          )}
          selectedValueIds={selectedVerticalTags.map(
            (_verticalTags) => _verticalTags.id
          )}
          options={verticalTagsOptions}
          onChange={(selection) =>
            onChange?.(
              verticalTagsOptions.filter((verticalTagsOption) =>
                selection.includes(verticalTagsOption.id)
              )
            )
          }
        />
      </ValidatedField>
    );
  };

  return (
    <BriefSubSection
      disabled={!editable}
      title={translate(`${TRANSLATION_PREFIX}.campaign-verticals`)}
    >
      <div className={styles.campaignVerticalsContainer}>
        {getCampaignVerticals()}
      </div>
    </BriefSubSection>
  );
}

function BasicDetails(props: SectionProps) {
  const {
    updateBriefForm,
    editable,
    errors,
    saveCampaign,
    briefFieldManager,
    campaign,
    viewPresentationId,
  } = props;
  const onCampaignNameChangeRef = useRef<any>(null);
  const onAboutYourBrandAndCampaignChangeRef = useRef<any>(null);
  onCampaignNameChangeRef.current = (value: string) =>
    updateBriefForm?.('name', value, 'name');
  onAboutYourBrandAndCampaignChangeRef.current = (value: string) =>
    updateBriefForm?.('descriptionHtml', value, 'descriptionHtml');

  const aboutYourBrandAndCampaignBriefItem = {
    title: translate(`${TRANSLATION_PREFIX}.about-your-brand-and-campaign`),
    contentHtml: briefFieldManager.fields.descriptionHtml.value || '',
  };

  return (
    <div className={styles.container}>
      <CampaignAndBrandDetails
        briefFieldManager={briefFieldManager}
        campaignName={briefFieldManager.fields.name.value}
        campaignImageUrl={briefFieldManager.fields.imageUrl.value}
        editable={editable}
        onCampaignNameChange={onCampaignNameChangeRef.current}
        onCampaignImageChange={(value) =>
          updateBriefForm?.(
            'imageUrl',
            value,
            'imageUrl',
            value ? IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY : undefined
          )
        }
        errors={errors}
        saveCampaign={saveCampaign}
        campaignId={campaign.id}
      />
      <CampaignVerticals
        errors={errors}
        verticalTags={briefFieldManager.fields.verticalTags.value}
        editable={editable}
        onChange={(selectedVerticalTags) =>
          updateBriefForm?.(
            'verticalTags',
            selectedVerticalTags,
            'verticalTags',
            DEFAULT_SAVE_CAMPAIGN_DELAY
          )
        }
        saveCampaign={saveCampaign}
      />
      <BriefFieldHelperPopper
        fieldName={briefFieldManager.fields.descriptionHtml?.agentKey}
        fieldValue={briefFieldManager.fields.descriptionHtml?.value}
        onChange={onAboutYourBrandAndCampaignChangeRef.current}
        saveCampaign={saveCampaign}
        disabled={!editable}
        campaignId={campaign.id}
        viewPresentationId={viewPresentationId}
      >
        {(childrenProps) => (
          <BriefItem
            {...childrenProps}
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.BASIC_DETAILS__ABOUT_YOUR_BRAND_AND_CAMPAIGN_INPUT
            }
            briefItem={aboutYourBrandAndCampaignBriefItem}
            placeholder={translate(
              `${TRANSLATION_PREFIX}.about-your-brand-and-campaign-placeholder`
            )}
            onChange={onAboutYourBrandAndCampaignChangeRef.current}
            editable={editable}
            saveCampaign={saveCampaign}
            errors={errors?.descriptionHtml}
            campaignId={campaign.id}
            fieldName={briefFieldManager.fields.descriptionHtml?.agentKey}
          />
        )}
      </BriefFieldHelperPopper>
    </div>
  );
}

export default BasicDetails;
