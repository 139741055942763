import {CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import {VettableCreators} from '@/types/vettableCreatorsResponse';
import useCreatorBrandSafetyVetting from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import useBrandId from '@/hooks/use-brand-id';

function getMatchingCreatorIds(
  vettableCreators: VettableCreators | undefined,
  addedEmails: string[],
  addedModashIds: (string | null)[]
): string[] {
  if (vettableCreators === undefined) return [];

  const allCreators = [
    ...vettableCreators.onPlatform,
    ...vettableCreators.offPlatform,
  ];

  return allCreators
    .filter(
      (creator) =>
        // Check if there's a vetting already in the table with the same modash ID
        (creator.id && addedModashIds.includes(creator.id)) ||
        // Check if creator's primary email or any email in `emails` array matches any in `addedEmails`
        (creator.email && addedEmails.includes(creator.email)) ||
        (creator.emails &&
          creator.emails.some((email) => addedEmails.includes(email)))
    )
    .map((creator) => creator.id); // Extract matching creator IDs
}

function useInitialAddedCreatorIds(resultsData: VettableCreators | undefined) {
  const brandId = useBrandId();

  const {data: vettingsResponseData} = useCreatorBrandSafetyVetting({
    brandId,
  });

  const tableEmails = vettingsResponseData?.map(
    (vetting: CreatorBrandSafetyVetting) => vetting?.profile?.email
  );

  const tableModashIds = vettingsResponseData?.map(
    (vetting: CreatorBrandSafetyVetting) => vetting?.modashId
  );

  return getMatchingCreatorIds(
    resultsData,
    tableEmails ?? [],
    tableModashIds ?? []
  );
}

export default useInitialAddedCreatorIds;
