import React, {useState, useEffect} from 'react';
import {MenuItem, Select} from '@mui/material';
import {Checkbox, Icon} from '@lightricks/react-design-system';
import {EmailPreference} from '@/types/emailPreference';
import translate from '../../../utils/translate';
import styles from './Notifications.module.scss';
import {updateFrequency, getUniqueBrands} from './utils/preferencesUtils';

type NotificationsTableProps = {
  emailPreferences: EmailPreference[];
  setEmailPreferences: React.Dispatch<React.SetStateAction<EmailPreference[]>>;
  setChangedPreferences: React.Dispatch<
    React.SetStateAction<EmailPreference[]>
  >;
};

function NotificationsTable({
  emailPreferences,
  setEmailPreferences,
  setChangedPreferences,
}: NotificationsTableProps) {
  const TRANSLATION_PREFIX = 'components.preferences.notifications';

  const eventNotificationOrder = [
    'brand_campaign_launched',
    'campaign_action_nudge',
    'incomplete_brief_reminder',
    'new_creator_message',
    'social_media_post_published',
    'application_email',
    'content_submission',
  ];

  const cadencedNotificationOrder = ['brand_digest'];

  const frequencyMapping = [
    {label: 'day', value: 1},
    {label: 'mon_wed_fri', value: 2},
    {label: 'week', value: 3},
    {label: 'opt_out', value: null},
  ];

  const uniqueBrands = getUniqueBrands(emailPreferences);

  const [selectAllStatus, setSelectAllStatus] = useState<{
    [key: string]: boolean;
  }>(
    eventNotificationOrder.reduce(
      (acc, event) => ({...acc, [event]: false}),
      {}
    )
  );

  useEffect(() => {
    const newSelectAllStatus = eventNotificationOrder.reduce((acc, event) => {
      const allSelected = uniqueBrands.every((brand) => {
        const preference = emailPreferences.find(
          (item) => item.name === event && item.brandDisplayName === brand
        );
        return preference?.enabled || false;
      });
      return {...acc, [event]: allSelected};
    }, {});
    setSelectAllStatus(newSelectAllStatus);
  }, [emailPreferences]);

  const handleSelectAll = (event: string) => {
    const newSelectAllStatus = !selectAllStatus[event];
    const updatedPreferences = emailPreferences.map((preference) =>
      preference.name === event
        ? {...preference, enabled: newSelectAllStatus}
        : preference
    );
    setEmailPreferences(updatedPreferences);
    setChangedPreferences((prev) => [
      ...prev,
      ...updatedPreferences.filter(
        (preference) =>
          preference.name === event && preference.enabled === newSelectAllStatus
      ),
    ]);
  };

  const handleIndividualCheckboxChange = (eventName: string, brand: string) => {
    const updatedPreferences = emailPreferences.map((preference) =>
      preference.name === eventName && preference.brandDisplayName === brand
        ? {...preference, enabled: !preference.enabled}
        : preference
    );
    setEmailPreferences(updatedPreferences);
    setChangedPreferences((prev) => [
      ...prev,
      ...updatedPreferences.filter(
        (preference) =>
          preference.name === eventName && preference.brandDisplayName === brand
      ),
    ]);

    const allSelected = uniqueBrands.every((currentBrand) => {
      const preference = updatedPreferences.find(
        (item) =>
          item.name === eventName && item.brandDisplayName === currentBrand
      );
      return preference?.enabled || false;
    });
    setSelectAllStatus({...selectAllStatus, [eventName]: allSelected});
  };

  return (
    <table className={styles.table}>
      <thead className={styles.tableHeader}>
        <tr className={styles.selectAllRow}>
          <th aria-label="Select all" />
          {eventNotificationOrder.map((eventName) => (
            <th key={eventName}>
              {uniqueBrands.length > 1 && (
                <Checkbox
                  checked={selectAllStatus[eventName]}
                  onChange={() => handleSelectAll(eventName)}
                  aria-label={`Select all ${eventName}`}
                />
              )}
            </th>
          ))}
          {cadencedNotificationOrder.map((cadencedEvent) => (
            <th key={cadencedEvent} aria-label={`${cadencedEvent} header`} />
          ))}
        </tr>
        <tr>
          <th aria-label="Brands"> </th>
          {eventNotificationOrder.map((eventName) => (
            <th key={eventName}>
              <div className={styles.headerWithCheckbox}>
                {translate(
                  `${TRANSLATION_PREFIX}.events.${eventName.replace(/_/g, '-')}`
                )}
              </div>
            </th>
          ))}
          {cadencedNotificationOrder.map((cadencedEvent) => (
            <th key={cadencedEvent}>
              {translate(
                `${TRANSLATION_PREFIX}.events.${cadencedEvent.replace(
                  /_/g,
                  '-'
                )}`
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {uniqueBrands.map((brand) => (
          <tr key={brand}>
            <td>{brand}</td>
            {eventNotificationOrder.map((eventName) => {
              const preference = emailPreferences.find(
                (item) =>
                  item.name === eventName && item.brandDisplayName === brand
              );
              return (
                <td key={eventName}>
                  <Checkbox
                    checked={preference?.enabled || false}
                    onChange={() =>
                      handleIndividualCheckboxChange(eventName, brand)
                    }
                    aria-label={`${eventName} for ${brand}`}
                  />
                </td>
              );
            })}
            {cadencedNotificationOrder.map((cadencedEvent) => {
              const preference = emailPreferences.find(
                (item) =>
                  item.name === cadencedEvent && item.brandDisplayName === brand
              );
              return (
                <td key={cadencedEvent} className={styles.frequencyCell}>
                  <Select
                    className={styles.frequencySelect}
                    IconComponent={() => (
                      <Icon
                        size="small"
                        appearance="black"
                        name="Actions-Arrow-Down-Normal"
                      />
                    )}
                    variant="standard"
                    value={
                      preference?.frequency !== undefined &&
                      preference?.frequency !== null
                        ? preference.frequency
                        : 'opt_out'
                    }
                    onChange={(e) =>
                      preference &&
                      updateFrequency(
                        preference,
                        e.target.value !== 'opt_out'
                          ? parseInt(e.target.value.toString(), 10)
                          : null,
                        emailPreferences,
                        setEmailPreferences,
                        setChangedPreferences
                      )
                    }
                    aria-label={`${cadencedEvent} frequency for ${brand}`}
                    MenuProps={{
                      classes: {
                        paper: styles.frequencyMenu,
                      },
                    }}
                  >
                    {frequencyMapping.map((frequency) => (
                      <MenuItem
                        key={frequency.value}
                        value={
                          frequency.value !== null ? frequency.value : 'opt_out'
                        }
                      >
                        {translate(
                          `${TRANSLATION_PREFIX}.frequency.${frequency.label.replace(
                            /_/g,
                            '-'
                          )}`
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default NotificationsTable;
