import axiosWithAuth from '@/utils/axiosWithAuth';
import QueryContext from '@/api/QueryContext';

const CREATORS_PATH = 'creators';

export type CurrentBrandCollaborationConversationResponse =
  | {
      id: string;
    }
  | undefined;

const conversationsFetcher = {
  currentBrandCollaborationConversation: async (
    context: QueryContext
  ): Promise<CurrentBrandCollaborationConversationResponse> => {
    if (!context.meta) throw Error('invalid query context meta');
    const brandId = context.meta.brandId as string;
    const creatorId = context.meta.creatorId as string;
    const result =
      await axiosWithAuth.get<CurrentBrandCollaborationConversationResponse>(
        `${CREATORS_PATH}/${creatorId}/conversation`,
        {params: {brand_id: brandId}}
      );
    return result.data;
  },
};

export default conversationsFetcher;
