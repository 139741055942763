import React from 'react';
import {Box} from '@mui/material';
import {Body, Checkbox} from '@lightricks/react-design-system';
import styles from './CheckboxLabel.module.scss';

const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

type CheckboxLabelProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  disabled?: boolean;
};

function CheckboxLabel(props: CheckboxLabelProps) {
  const {checked, onChange, label, disabled} = props;

  return (
    <Box
      className={styles.container}
      sx={{
        'opacity': disabled ? 0.5 : 1,
        '&:hover': {cursor: disabled ? 'not-allowed' : 'pointer'},
      }}
      onClick={() => {
        if (!disabled) onChange(!checked);
      }}
    >
      <Checkbox
        checked={checked}
        disabled={disabled}
        customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
        customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
        onChange={() => {}}
      />
      {label ? (
        <Body size="lg" className={styles.label}>
          {label}
        </Body>
      ) : null}
    </Box>
  );
}

export default CheckboxLabel;
