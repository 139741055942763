import React from 'react';
import {Button, Body, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import useDeleteLabel from '@/hooks/mutations/creators/use-delete-label';
import {useBannerStore} from '@/contexts/BannerContext';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './DeleteList.module.scss';

export type DeleteListProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TRANSLATION_PREFIX = 'components.delete-list';

function DeleteList({label}: {label?: CreatorGroupLabel}) {
  const deleteLabel = useDeleteLabel();
  const {dispatch} = useBannerStore();
  const onClose = () => {
    listCreatorsActions.setActiveListAction(null);
  };

  const deleteList = async () => {
    const bannerData = {
      type: 'SET_BANNER' as const,
      title: '',
      message: translate(`${TRANSLATION_PREFIX}.success`),
      icon: (
        <Icon
          size="medium"
          appearance="brand"
          name="Actions-Patch-Accept-Line"
        />
      ),
    };

    if (label?.id) {
      try {
        await deleteLabel.mutateAsync(label.id);
        listCreatorsActions.deselectList(label.id);
      } catch (e) {
        bannerData.message = translate(`${TRANSLATION_PREFIX}.error`);
        bannerData.icon = (
          <Icon size="medium" appearance="danger" name="Actions-Alert-Line" />
        );
      }
    }

    dispatch(bannerData);
    onClose();
  };

  return (
    <div className={styles.content}>
      <Body size="md" className={styles.description}>
        {translate(`${TRANSLATION_PREFIX}.description`)}
      </Body>

      <div className={styles.buttons}>
        <Button
          appearance="neutral"
          size="large"
          mode="tinted"
          onClick={onClose}
          className={styles.cancelButton}
        >
          {translate(`${TRANSLATION_PREFIX}.cancel`)}
        </Button>

        <Button
          appearance="neutral"
          size="large"
          mode="filled"
          className={styles.deleteButton}
          onClick={deleteList}
        >
          {translate(`${TRANSLATION_PREFIX}.delete`)}
        </Button>
      </div>
    </div>
  );
}

export default DeleteList;
