import React, {useState} from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {BackgroundCheck} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import ProfileVettingBackgroundCheckFinding from '@/components/creator-vetting-report/components/profile-vetting-background-check-finding';
import Divider from '@/components/divider';
import useCreatorVettingReportStore, {
  creatorVettingReportActions,
} from '@/stores/creatorVettingReportStore';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import styles from './ProfileVettingBackgroundCheck.module.scss';

const MAX_MINIMIZED_DISPLAYED_FINDINGS_AMOUNT = 2;

interface BackgroundCheckProps {
  creatorId: string;
  reportId: string;
  backgroundCheck: BackgroundCheck;
}

function SeeAllButton({
  seeAll,
  setSeeAll,
}: {
  seeAll: boolean;
  setSeeAll: (seeAll: boolean) => void;
}) {
  return (
    <div onClick={() => setSeeAll(!seeAll)} className={styles.seeAllButton}>
      <Label size="sm" color={designSystemToken('semantic.fg.secondary')}>
        {translate(seeAll ? 'See fewer results' : 'See more results')}
      </Label>
    </div>
  );
}

function ProfileVettingBackgroundCheck(props: BackgroundCheckProps) {
  const {creatorId, reportId, backgroundCheck} = props;
  const creatorVettingReportState = useCreatorVettingReportStore();
  const report = creatorVettingReportState.reports[reportId];
  const [seeAll, setSeeAll] = useState(report?.seeAllBackgroundCheck || false);
  const {findings} = backgroundCheck;

  const displayedFindings = seeAll
    ? findings
    : findings.slice(0, MAX_MINIMIZED_DISPLAYED_FINDINGS_AMOUNT);

  const onSeeAllChange = (newSeeAll: boolean) => {
    setSeeAll(newSeeAll);
    creatorVettingReportActions.setSeeAllBackgroundCheck(report.id, newSeeAll);
  };

  return (
    <div
      className={styles.profileVettingBackgroundCheckContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.BACKGROUND_CHECK}
    >
      <div
        className={styles.profileVettingBackgroundChecks}
        data-testid={CREATOR_VETTING_REPORT_TEST_IDS.BACKGROUND_CHECK__ITEMS}
      >
        {displayedFindings.map((finding, index) => (
          <div key={finding.title}>
            <Conditional condition={index === 0}>
              <Divider margin={0} />
            </Conditional>
            <ProfileVettingBackgroundCheckFinding
              key={finding.title}
              finding={finding}
              reportId={reportId}
              creatorId={creatorId}
            />
            <Divider margin={0} />
          </div>
        ))}
      </div>
      <Conditional
        condition={findings.length > MAX_MINIMIZED_DISPLAYED_FINDINGS_AMOUNT}
      >
        <SeeAllButton seeAll={seeAll} setSeeAll={onSeeAllChange} />
      </Conditional>
    </div>
  );
}

export default ProfileVettingBackgroundCheck;
