import dayjs from 'dayjs';
import {MRT_ColumnDef, MRT_Row} from 'material-react-table';
import React, {RefObject, useEffect} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Tabs} from '@lightricks/react-design-system';
import {TabData} from '@lightricks/react-design-system/dist/components/tabs/TabsProps';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import Sentry from '@/services/sentry/Sentry';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import {
  type CreatorBrandSafetyVettingStatus,
  type CreatorBrandSafetyVetting,
  type CompletedCreatorBrandSafetyVettingStatus,
} from '@/types/creatorBrandSafetyVetting';
import Table from '@/components/table';
import useBrandId from '@/hooks/use-brand-id';
import useElementDimensions from '@/hooks/use-element-dimensions';
import useNavigation from '@/hooks/use-navigation';
import useEmberIframeStyleStore from '@/stores/emberIframeStyleStore';
import useVettingHubStore, {
  TAB_VALUES,
  vettingHubActions,
} from '@/stores/vettingHubStore';
import trackBrandSafetyDetailEvent from '../../utils/vettingHubAnalytics';
import styles from './VettingsTable.module.scss';
import {
  CreatorCell,
  LastUpdatedCell,
  DateAddedCell,
  FlagsCell,
  StatusCell,
} from './cells';
import {COMPLETED_REPORT_STATUSES, PLACEHOLDER_ID} from './constants';

const MAX_STICKY_TABLE_WIDTH = 1060;
const STATUS_SORT_ORDER_MAP = new Map<CreatorBrandSafetyVettingStatus, number>([
  ['ready_for_review', 0],
  ['approved', 1],
  ['declined', 2],
  ['in_progress', 3],
  ['invited', 4],
]);

type VettingsTableProps = {
  testID?: string;
  data: CreatorBrandSafetyVetting[];
  isLoading?: boolean;
};

function VettingsTable(props: VettingsTableProps) {
  const {testID = 'vettings-table', data, isLoading} = props;
  const {navigate} = useNavigation();
  const brandId = useBrandId();
  const [tabValue, sorting] = useVettingHubStore(
    useShallow((state) => [state.activeTab, state.sorting])
  );

  useEffect(() => {
    try {
      const flow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.BRAND_SAFETY_CREATOR
      );
      const creatorIds = data.map((creator) => ({
        creator_id: creator.id,
        creator_name: creator.creatorName,
        status: creator.status,
        date_added: creator.createdAt
          ? dayjs(creator.createdAt).valueOf()
          : null,
        last_updated: creator.reportUpdatedAt
          ? dayjs(creator.reportUpdatedAt).valueOf()
          : null,
      }));
      const payload = {
        flow_id: flow.flow_id,
        screen_presentation_id: flow.screen_presentation_id,
        tab: tabValue,
        brand_safety_screen_name: 'Hub',
        creator_ids: JSON.stringify(creatorIds),
      };
      console.log('BRAND_SAFETY_RESULTS_PRESENTED payload', payload);
      if (creatorIds.length > 0) {
        AnalyticsService.dispatchEvent(
          eventNames.BRAND_SAFETY_RESULTS_PRESENTED,
          payload
        );
      }
    } catch (error) {
      Sentry.captureException(Error('Error dispatching event', {cause: error}));
    }
  }, [data, tabValue]);

  const isWithinTabFilter =
    (value: string) => (creator: CreatorBrandSafetyVetting) => {
      if (value === 'all') return true;

      if (value === 'invited') {
        // map invited and in_progress to the same tab
        return ['invited', 'in_progress'].includes(creator.status);
      }

      return creator.status === value;
    };

  const appendTabCounts = React.useCallback(
    (tab: TabData) => {
      if (isLoading) return tab;

      return {
        ...tab,
        label: `${tab.label} (${
          data.filter(isWithinTabFilter(tab.value)).length
        })`,
      };
    },
    [data, isLoading]
  );

  const tabsData: TabData[] = [
    {label: translate('all'), value: TAB_VALUES.ALL},
    {label: translate('ready-to-review'), value: TAB_VALUES.READY_FOR_REVIEW},
    {label: translate('approved'), value: TAB_VALUES.APPROVED},
    {label: translate('rejected'), value: TAB_VALUES.DECLINED},
    {label: translate('queued'), value: TAB_VALUES.INVITED},
  ];

  const tabs = tabsData.map(appendTabCounts);

  const columnAlignment = (align: 'left' | 'center' | 'right') => ({
    muiTableHeadCellProps: {align},
    muiTableBodyCellProps: {align},
  });

  const columnSizes = {size: 100, grow: 1};

  const tableColumns: MRT_ColumnDef<CreatorBrandSafetyVetting>[] = [
    {
      header: translate('creator-name'),
      Header: translate('creator').toUpperCase(),
      Cell: CreatorCell,
      accessorKey: 'creatorName',
      enableSorting: true,
      ...columnSizes,
      size: 200,
      grow: 0,
      ...columnAlignment('left'),
    },
    {
      header: translate('last-updated'),
      Header: translate('last-updated').toUpperCase(),
      Cell: LastUpdatedCell,
      accessorKey: 'reportUpdatedAt',
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        type ReportUpdatedAt = CreatorBrandSafetyVetting['reportUpdatedAt'];
        const valueA = rowA.getValue<ReportUpdatedAt>('reportUpdatedAt');
        const valueB = rowB.getValue<ReportUpdatedAt>('reportUpdatedAt');

        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;

        return dayjs(valueB).diff(dayjs(valueA));
      },
      ...columnSizes,
      ...columnAlignment('center'),
    },
    {
      header: translate('added'),
      Header: translate('added').toUpperCase(),
      Cell: DateAddedCell,
      accessorKey: 'createdAt',
      enableSorting: true,
      ...columnSizes,
      ...columnAlignment('center'),
    },
    {
      header: translate('flags'),
      Header: translate('flags').toUpperCase(),
      Cell: FlagsCell,
      accessorKey: 'itemCount',
      enableSorting: true,
      ...columnSizes,
      ...columnAlignment('center'),
    },
    {
      header: translate('status'),
      Header: translate('status').toUpperCase(),
      Cell: StatusCell,
      accessorKey: 'status',
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const valueA = rowA.getValue<CreatorBrandSafetyVettingStatus>('status');
        const valueB = rowB.getValue<CreatorBrandSafetyVettingStatus>('status');
        return (
          (STATUS_SORT_ORDER_MAP.get(valueA) ?? -1) -
          (STATUS_SORT_ORDER_MAP.get(valueB) ?? -1)
        );
      },
      ...columnSizes,
      ...columnAlignment('center'),
    },
  ];

  const renderData = React.useMemo(() => {
    if (isLoading) return [];

    if (data.length === 0) return [placeholderRow];

    return data.filter(isWithinTabFilter(tabValue));
  }, [data, isLoading, tabValue]);

  const {sidebarWidth} = useEmberIframeStyleStore();

  const {elementRef: tableRef, elementWidth: tableWidth} = useElementDimensions(
    [renderData, sidebarWidth]
  );

  const isRowDisabled = (row: CreatorBrandSafetyVetting) =>
    row.id === PLACEHOLDER_ID ||
    !COMPLETED_REPORT_STATUSES.includes(
      row.status as CompletedCreatorBrandSafetyVettingStatus
    );

  const onRowClick = (row: CreatorBrandSafetyVetting) => {
    const redirectUrl = `/vetting/creators/${row.profile.id}/report/${row.reportId}?brandId=${brandId}`;
    navigate(redirectUrl);
  };

  return (
    <div
      className={`${styles.container} ${
        tableWidth < MAX_STICKY_TABLE_WIDTH ? styles.nonStickyTable : ''
      }`}
      data-testid={testID}
    >
      <Tabs
        testID={`${testID}-tabs`}
        ScrollButtonComponent="div"
        className={styles.tabsContainer}
        size="large"
        appearance="neutral"
        onChange={(_e, value) => {
          vettingHubActions.setActiveTab(value);
        }}
        value={tabValue}
        classes={{root: styles.tabs}}
        tabsData={tabs}
        big
      />
      <Table
        tableRef={tableRef as RefObject<HTMLTableElement>}
        testID={`${testID}-table`}
        classes={{
          container: undefined,
        }}
        columns={tableColumns}
        data={renderData}
        isLoadingInitial={isLoading}
        showProgressBars={false}
        visibleColumns={{}}
        rowSelection={{}}
        isRowDisabled={isRowDisabled}
        sorting={sorting}
        onSorting={(value) => {
          trackBrandSafetyDetailEvent({
            brand_safety_detail_name: 'Sorting',
            brand_safety_detail_value: JSON.stringify(value),
          });
          vettingHubActions.setSorting(value);
        }}
        isLoading={isLoading}
        enableVirtualization
        enableSorting
        manualSorting={false}
        onRowClick={onRowClick}
      />
    </div>
  );
}

const placeholderRow: CreatorBrandSafetyVetting = {
  id: PLACEHOLDER_ID,
  modashId: null,
  type: 'creator_brand_safety_vettings',
  reportId: '',
  status: 'ready_for_review',
  creatorName: '',
  createdAt: dayjs().subtract(8, 'day').toISOString(),
  reportUpdatedAt: dayjs().subtract(7, 'day').toISOString(),
  itemCount: 128,
  profile: {
    displayName: translate('Your First Creator'),
  },
};

export default VettingsTable;
