import React, {useEffect} from 'react';
import translate from '@/utils/translate';
import Link from '@/components/link';
import styles from './SubscriptionExpirationBanner.module.scss';

type Subscription = {
  loaded: boolean;
  expiresInDays: number;
  subscriptionType: string;
};

export type SubscriptionExpirationBannerProps = {
  testID?: string;
  subscription: Subscription;
  containerRef?: React.RefObject<HTMLDivElement>;
  setHeaderBannerShown?: (shown: boolean) => void;
};

function SubscriptionExpirationBanner(
  props: SubscriptionExpirationBannerProps
) {
  const {
    testID = 'subscription-expiration-banner',
    containerRef,
    subscription,
    setHeaderBannerShown,
  } = props;

  const isSelfServe =
    subscription.subscriptionType === 'essentials' ||
    subscription.subscriptionType === 'platform_free';

  const hideBanner =
    !subscription ||
    !subscription.loaded ||
    isSelfServe ||
    subscription.expiresInDays > 30 ||
    subscription.expiresInDays < 1;

  useEffect(() => {
    setHeaderBannerShown?.(!hideBanner);
  }, [hideBanner, setHeaderBannerShown]);

  if (hideBanner) {
    return null;
  }

  const {subscriptionType, expiresInDays} = subscription;
  const trackContactClick = () => {
    // TODO
  };

  const labelLocaleKey =
    subscriptionType === 'campaign'
      ? 'components.subscription-expiration-banner.main-text.campaign'
      : 'components.subscription-expiration-banner.main-text.default';

  return (
    <div data-testid={testID}>
      <div className={styles.content} ref={containerRef}>
        <strong style={{marginInlineEnd: 3}}>
          {translate(labelLocaleKey, {
            count: expiresInDays,
            timeFrame: translate(
              'components.subscription-expiration-banner.main-text.time-frame',
              {
                postProcess: 'interval',
                count: expiresInDays,
              }
            ),
          })}
        </strong>
        <Link
          to={`mailto:sales@popularpays.com?subject=${translate(
            `components.subscription-expiration-banner.contact-subject.${subscriptionType}`
          )}`}
          onClick={trackContactClick}
          style={{marginInlineEnd: 3}}
          keepBrandIdIfPresent={false}
        >
          {translate(
            'components.subscription-expiration-banner.contact-text-first'
          )}
        </Link>
        {translate(
          'components.subscription-expiration-banner.contact-text-last'
        )}
      </div>
    </div>
  );
}

export default SubscriptionExpirationBanner;
