import {RangeStep} from '@/types/models/search-creators/filter';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer.filters';

export const DEFAULT_WEIGHTS_OPTIONS: Record<string, number> = {
  '10%': 0.1,
  '20%': 0.2,
  '30%': 0.3,
  '40%': 0.4,
  '50%': 0.5,
  '60%': 0.6,
  '70%': 0.7,
  '80%': 0.8,
  '90%': 0.9,
  '100%': 1.0,
};

export const DEFAULT_FOLLOWERS_STEPS: RangeStep[] = [
  {value: 0},
  {value: 500},
  {value: 750},
  // nano
  {
    value: 1_000,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.followers-marks.nano`,
  },
  {value: 2_000},
  {value: 3_000},
  {value: 4_000},
  {value: 5_000},
  {value: 10_000},
  {value: 15_000},
  // micro
  {
    value: 20_000,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.followers-marks.micro`,
  },
  {value: 30_000},
  {value: 40_000},
  {value: 50_000},
  {value: 75_000},
  {value: 100_000},
  {value: 125_000},
  // macro
  {
    value: 150_000,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.followers-marks.mid`,
  },
  {value: 175_000},
  {value: 200_000},
  {value: 225_000},
  {value: 250_000},
  {value: 275_000},
  {value: 300_000},
  // max
  {
    value: 350_000,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.followers-marks.max`,
  },
  {value: 500_000},
  {value: 1_000_000_000},
];

const generateAgeRange = (start: number, end: number): RangeStep[] => {
  const steps: RangeStep[] = [];
  for (let i = start; i <= end; i += 1) {
    steps.push({
      value: i,
    });
  }
  return steps;
};

export const DEFAULT_AGE_STEPS: RangeStep[] = [
  {
    value: 13,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.age-marks.13`,
  },
  ...generateAgeRange(13, 64),
  {
    value: 65,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.age-marks.65-plus`,
  },
];

export const DEFAULT_AVERAGE_VIEWS_STEPS: RangeStep[] = [
  {
    value: 0,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.average-views-marks.low`,
  },
  {value: 1_000},
  {value: 3_000},
  {value: 5_000},
  {value: 10_000},
  {value: 15_000},
  {value: 20_000},
  {value: 50_000},
  {value: 75_000},
  {value: 100_000},
  {value: 200_000},
  {value: 250_000},
  {value: 300_000},
  {value: 500_000},
  {
    value: 1_000_000_000,
    markLocaleLabelKey: `${TRANSLATION_PREFIX}.average-views-marks.high`,
  },
];
