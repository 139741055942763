import React from 'react';
import {Button, designSystemToken, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Modal from '@/components/modal';
import Textarea from '@/components/textarea';
import styles from './GuidelinesModal.module.scss';

type GuidelinesModalProps = {
  testID?: string;
  onClose?: () => void;
  onSubmit: (guidelines: string) => void;
  open?: boolean;
};

const TRANSLATION_PREFIX =
  'components.campaign-brief.components.guidelines-modal';

function CloseButton({onClose}: {onClose?: () => void}) {
  return (
    <Button
      appearance="neutral"
      mode="plain"
      size="small"
      className={styles.closeButton}
      onClick={onClose}
      icon={
        <Icon size="medium" appearance="neutral" name="Actions-Close-Small" />
      }
    />
  );
}

function ActionButton({
  testID,
  onClick,
}: {
  testID?: string;
  onClick?: () => void;
}) {
  return (
    <div className={styles.actionButton}>
      <Button
        testID={`${testID}--action-button`}
        appearance="neutral"
        mode="tinted"
        size="medium"
        onClick={onClick}
        icon={
          <Icon
            size="medium"
            appearance="neutral"
            name="Features-MagicPen"
            color={designSystemToken('semantic.fg.white')}
          />
        }
        className={styles.actionButton}
      >
        <div className={styles.label}>
          {translate(`${TRANSLATION_PREFIX}.button`)}
        </div>
      </Button>
    </div>
  );
}

function GuidelinesModal({
  testID = 'guidelines-modal',
  onClose,
  onSubmit,
  open,
}: GuidelinesModalProps) {
  const [guidelines, setGuidelines] = React.useState('');

  const submit = () => {
    onSubmit(guidelines);
    setGuidelines('');
  };

  return (
    <Modal
      testID={testID}
      className={styles.container}
      open={open}
      onClose={onClose}
    >
      <>
        <CloseButton onClose={onClose} />
        <div className={styles.titlesContainer}>
          <label className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.title`)}
          </label>
          <label className={styles.subtitle}>
            {translate(`${TRANSLATION_PREFIX}.subtitle`)}
          </label>
        </div>
        <Textarea
          autoFocus
          value={guidelines}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setGuidelines(event.target.value);
          }}
          className={styles.textarea}
          minRows={3}
          maxRows={20}
        />
        <ActionButton testID={testID} onClick={submit} />
      </>
    </Modal>
  );
}

export default GuidelinesModal;
