import {identity as lodashIdentity, pickBy} from 'lodash';
import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Body} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {Identity} from '@/types/identity';
import {Post} from '@/types/post';
import PoppaysLoader from '@/components/PoppaysLoader';
import SocialNetworkSelector from '@/components/creator-profile/components/social-network-selector';
import useSocialNetworksSelector from '@/components/creator-profile/hooks/use-social-networks-selector';
import CREATOR_PROFILE_TEST_IDS from '@/components/creator-profile/testIds';
import ElementInView from '@/components/element-in-view';
import IdentityPost from '@/components/identity-post';
import InfiniteLoader from '@/components/infinite-loader';
import SearchInput from '@/components/search-input';
import useIdentityPaginatedPostsQuery from '@/hooks/queries/use-identity-paginated-posts-query';
import {CreatorProfileTabContentProps} from '../../CreatorProfileTabContentProps';
import TabWrapper from '../tab-wrapper';
import styles from './PostsTab.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.tabs.posts';

const noPostsIcon = '/assets/svg/icon-no-posts.svg';

const IDENTITY_POST_QUERY_PARAM = 'identityPostQuery';

const DEFAULT_QUERY_PARAMS = {
  socialStream: 'feed',
  allowDeletedPosts: true,
  serializer: 'identity_post_serializer',
};

interface IdentityPostsProps {
  posts: Post[];
  fetchMore: () => void;
  isLoading: boolean;
  isLoadingMore: boolean;
  onPostClick?: (post: Post) => void;
}

function IdentityPosts(props: IdentityPostsProps) {
  const {posts, fetchMore, isLoading, isLoadingMore, onPostClick} = props;

  if (isLoading) {
    return (
      <div className={styles.identityPostsLoading}>
        <PoppaysLoader absolute />
      </div>
    );
  }

  if (!isLoading && !posts.length) {
    return (
      <div className={styles.identityPostsEmpty}>
        <img src={noPostsIcon} alt="no-posts" />
        <Body size="xl">{translate(`${TRANSLATION_PREFIX}.no-posts`)}</Body>
      </div>
    );
  }

  return (
    <div className={styles.identityPostsContainer}>
      <div className={styles.identityPosts}>
        {posts.map((post) => (
          <IdentityPost key={post.id} post={post} onClick={onPostClick} />
        ))}
        <ElementInView onInView={fetchMore} />
      </div>
      <InfiniteLoader isLoading={isLoadingMore} />
    </div>
  );
}

function PostsTab(props: CreatorProfileTabContentProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get(IDENTITY_POST_QUERY_PARAM) || ''
  );
  const {profile, isSharedList, trackButtonPressed} = props;
  const {identities} = profile;

  const {socialNetworks, activeIdentity, onSocialNetworkClick} =
    useSocialNetworksSelector(identities);

  const handleSocialNetworkClick = (identity: Identity) => {
    onSocialNetworkClick(identity);
    trackButtonPressed(`Social Network: ${identity.provider}`);
  };

  const {
    data: posts,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
    isFetchingNextPage,
  } = useIdentityPaginatedPostsQuery({
    identityId: activeIdentity?.id,
    queryParams: pickBy(
      {
        ...DEFAULT_QUERY_PARAMS,
        q: searchQuery,
      },
      lodashIdentity
    ),
  });

  const fetchMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSubmitSearchInput = (value: string) => {
    setSearchQuery(value);
    if (value) {
      searchParams.set(IDENTITY_POST_QUERY_PARAM, value);
    } else {
      searchParams.delete(IDENTITY_POST_QUERY_PARAM);
    }
    setSearchParams(searchParams);
    trackButtonPressed(`Search ${activeIdentity?.provider} posts`, {
      flow_name: value,
    });
  };

  return (
    <TabWrapper testID={CREATOR_PROFILE_TEST_IDS.TAB__POSTS}>
      <div
        className={`${styles.socialNetworksBar} ${
          isSharedList ? styles.whiteBackground : ''
        }`}
      >
        <SocialNetworkSelector
          socialNetworks={socialNetworks}
          activeSocialNetworkProvider={activeIdentity?.provider || ''}
          onSocialNetworkClick={handleSocialNetworkClick}
        />
        <div className={styles.searchInputContainer}>
          <div className={styles.searchInput}>
            <SearchInput
              value={searchQuery || ''}
              onSubmit={onSubmitSearchInput}
              placeholder={translate(
                `${TRANSLATION_PREFIX}.search-placeholder`
              )}
            />
          </div>
        </div>
      </div>
      <IdentityPosts
        posts={posts}
        fetchMore={fetchMore}
        isLoading={isRefetching || isLoading}
        isLoadingMore={isFetchingNextPage}
        onPostClick={(post: Post) => {
          trackButtonPressed(`Open ${activeIdentity?.provider} post`, {
            flow_id: post.id,
            flow_name: post.networkUrl,
          });
        }}
      />
    </TabWrapper>
  );
}

export default PostsTab;
