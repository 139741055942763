import axiosWithAuth from '@/utils/axiosWithAuth';
import {
  CreatorSearchResult,
  PagingParams,
  MultiPlatformFilter,
  Sorting,
  RequestType,
  Metadata,
} from '@/types/models/search-creators/searchCreators';
import QueryContext from '@/api/QueryContext';

const SEARCH_CREATORS_PATH = 'creators/search';
const SEARCH_CREATORS_METADATA_PATH = 'creators/metadata';

export type CreatorsSearchStartSessionRequest = {
  type: RequestType;
  query: MultiPlatformFilter;
  sort?: Sorting;
  page_size: number;
};

export type CreatorsSearchResponse = {
  creators: CreatorSearchResult[];
  pagingParams: PagingParams;
  searchSessionId?: string;
  loadedPages: number;
};

const searchCreatorsFetchers = {
  startSearchSession: async (context: QueryContext) => {
    if (!context.meta) throw Error('invalid query context meta');
    const searchRequestBody: CreatorsSearchStartSessionRequest = {
      type: context.meta.type as RequestType,
      query: context.meta.query as MultiPlatformFilter,
      sort: context.meta.sort ? (context.meta.sort as Sorting) : undefined,
      page_size: context.meta.itemsPerPage as number,
    };
    const result = await axiosWithAuth.post<CreatorsSearchResponse>(
      SEARCH_CREATORS_PATH,
      searchRequestBody
    );
    return result.data;
  },
  changePage: async (context: QueryContext) => {
    if (!context.meta) throw Error('invalid query context meta');
    const page = context.pageParam as number;
    const sessionId = context.meta.sessionId as string;
    const result = await axiosWithAuth.get<CreatorsSearchResponse>(
      `${SEARCH_CREATORS_PATH}/${sessionId}`,
      {
        params: {
          page,
        },
      }
    );
    return result.data;
  },
  metadata: async () => {
    const result = await axiosWithAuth.get<Metadata>(
      SEARCH_CREATORS_METADATA_PATH
    );
    return result.data;
  },
};

export default searchCreatorsFetchers;
