import React, {useMemo} from 'react';
import {FormControl} from '@mui/material';
import translate from '@/utils/translate';
import styles from './Select.module.scss';
import {SelectProps} from './SelectProps';
import MultipleSelect from './multiple-select';
import SingleSelect from './single-select';

const TRANSLATION_PREFIX = 'components.select';

function Select(props: SelectProps) {
  const {
    testID = 'select-dropdown',
    multiple = false,
    variant = 'default',
    label,
    options,
    optionLabelKey = 'label',
    onChange,
    selectedValueIds,
    renderSelectedLabel,
    immediateOnChange = false,
    onClose,
    onClear,
    checkmarkRadioButtonClassName,
    withSelectAllOption = false,
    selectAllLabel = translate(`${TRANSLATION_PREFIX}.select-all`),
    disabled = false,
    containerClassName,
    allowReSelect = false,
  } = props;

  const labelId = useMemo(
    () => `select-label-${Math.random().toString(36).substring(7)}`,
    []
  );

  return (
    <div className={styles.container} data-testid={testID}>
      <FormControl fullWidth>
        {multiple ? (
          <MultipleSelect
            {...{
              label,
              options,
              optionLabelKey,
              onChange,
              selectedValueIds,
              variant,
              labelId,
              renderSelectedLabel,
              immediateOnChange,
              onClose,
              onClear,
              checkmarkRadioButtonClassName,
              withSelectAllOption,
              selectAllLabel,
              disabled,
              containerClassName,
            }}
          />
        ) : (
          <SingleSelect
            {...{
              label,
              options,
              optionLabelKey,
              onChange,
              selectedValueIds,
              variant,
              labelId,
              renderSelectedLabel,
              immediateOnChange,
              onClose,
              onClear,
              disabled,
              containerClassName,
              allowReSelect,
            }}
          />
        )}
      </FormControl>
    </div>
  );
}

export default Select;
