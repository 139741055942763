import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';

function mapOptions(options: BaseFilterOption[]) {
  return options.map((option) => ({
    ...option,
    label: translate(option.localeLabelKey),
  }));
}

export default mapOptions;
