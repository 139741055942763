import React, {useEffect} from 'react';
import {Icon} from '@lightricks/react-design-system';
import getCapsuleAiImageUrl from '@/utils/getCapsuleAiImageUrl';
import Chip from '@/components/chip/Chip';
import {
  GeneratedStyle,
  getPresetData,
  getReferenceImage,
  useContentGenerationStore,
} from '@/contexts/ContentGenerationStore';
import styles from './StyleChip.module.scss';

type StyleChipProps = {
  userPrompt: string;
  negativeUserPrompt: string;
  isReferenceImageSelected: boolean;
};
function StyleChip(props: StyleChipProps) {
  const {userPrompt, negativeUserPrompt, isReferenceImageSelected} = props;
  const {state, dispatch} = useContentGenerationStore();
  const {selectedPreset, presetThumbnailPath} = getPresetData(state);
  const referenceImage = getReferenceImage(state);
  const chipSelectedThumbnail = !isReferenceImageSelected
    ? presetThumbnailPath
    : getCapsuleAiImageUrl(referenceImage?.toString());

  const chipSelectedLabel = isReferenceImageSelected
    ? 'Reference image'
    : selectedPreset;
  const onResetChip = () => {
    if (isReferenceImageSelected) {
      dispatch({
        type: 'UPDATE_STYLE_TYPE',
        styleType: GeneratedStyle.none,
      });
    }
    dispatch({
      type: 'RESET_PRESET',
      promptValue: userPrompt,
      negativePromptValue: negativeUserPrompt,
      styleType: GeneratedStyle.none,
    });
  };

  return selectedPreset || isReferenceImageSelected ? (
    <div
      className={`${styles.chipsContainer} ${
        isReferenceImageSelected ? styles.chipsContainerReferenceMode : ''
      }`}
    >
      <Chip
        icon={<img src={chipSelectedThumbnail} alt="Chip selected" />}
        label={chipSelectedLabel}
        handleDelete={onResetChip}
        deleteIcon={
          <div>
            <Icon
              size="small"
              appearance="neutral"
              name="Actions-Close-Small"
            />
          </div>
        }
      />
    </div>
  ) : null;
}

export default StyleChip;
