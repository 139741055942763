import React from 'react';
import translate from '@/utils/translate';
import {ContentAgreementTerm, CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefItem from './BriefItem';

export const DEFAULT_CONTENT_AGREEMENT_TIME_PERIOD_IN_MONTHS = 12;

export function getFooterFields(contentAgreementTerms: ContentAgreementTerm[]) {
  const hasPostsToSocialMediaAccount = contentAgreementTerms.find(
    (contentAgreementTerm) =>
      contentAgreementTerm.name === 'posts_to_social_media_accounts'
  );
  const hasWebsitesAndMobileApps = contentAgreementTerms.find(
    (contentAgreementTerm) =>
      contentAgreementTerm.name === 'websites_and_mobile_apps'
  );

  let dynamicPartialFooter = null;
  if (!!hasPostsToSocialMediaAccount && !!hasWebsitesAndMobileApps) {
    dynamicPartialFooter = translate(
      'components.campaign-brief.sections.content-agreement.partial-footer-2-a',
      {
        purpose1: hasPostsToSocialMediaAccount.displayText,
        purpose2: hasWebsitesAndMobileApps.displayText,
      }
    );
  } else if (
    (!!hasPostsToSocialMediaAccount && !hasWebsitesAndMobileApps) ||
    (!hasPostsToSocialMediaAccount && !!hasWebsitesAndMobileApps)
  ) {
    dynamicPartialFooter = translate(
      'components.campaign-brief.sections.content-agreement.partial-footer-2-b',
      {
        purpose: (hasPostsToSocialMediaAccount || hasWebsitesAndMobileApps)!
          .displayText,
      }
    );
  }

  return [
    translate(
      'components.campaign-brief.sections.content-agreement.partial-footer-1'
    ),
    dynamicPartialFooter,
    translate(
      'components.campaign-brief.sections.content-agreement.partial-footer-3'
    ),
  ].filter(Boolean);
}
class ContentAgreementBriefItem extends BriefItem {
  #contentAgreementTerms: ContentAgreementTerm[];

  #contentAgreementTimePeriodInMonths: number | string;

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(
      briefFieldManager,
      campaignData,
      key,
      index,
      id,
      title,
      contentHtml,
      highlighted,
      clientBriefTemplateId
    );
    this.#contentAgreementTerms = campaignData.contentAgreementTerms;
    this.#contentAgreementTimePeriodInMonths =
      campaignData.contentAgreementTimePeriodInMonths ||
      DEFAULT_CONTENT_AGREEMENT_TIME_PERIOD_IN_MONTHS;
  }

  set contentAgreementTerms(contentAgreementTerms: ContentAgreementTerm[]) {
    this.#contentAgreementTerms = contentAgreementTerms;
  }

  set contentAgreementTimePeriodInMonths(
    contentAgreementTimePeriodInMonths: number | string
  ) {
    this.#contentAgreementTimePeriodInMonths =
      contentAgreementTimePeriodInMonths;
  }

  get toContentHtml() {
    if (!this.#contentAgreementTerms) {
      return this.contentHtml;
    }

    return `
    <div/>
        <h4>${translate(
          'components.campaign-brief.sections.content-agreement.rights-to-content-created-content'
        ).replace('<br/>', '')}</h4>
        <ol>
            ${this.#contentAgreementTerms
              .map(
                (contentAgreementTerm: ContentAgreementTerm) =>
                  `<li>${contentAgreementTerm.displayText}</li>`
              )
              .join('')}
        </ol>
        <span>${translate(
          `components.campaign-brief.sections.content-agreement.time-period`
        )} - ${translate(
      `components.campaign-brief.sections.content-agreement.time-period-${
        this.#contentAgreementTimePeriodInMonths
      }`
    )}</span>
        <h4>${getFooterFields(this.#contentAgreementTerms).join('')}</h4>
        <span>
        ${translate(
          'components.campaign-brief.sections.content-agreement.footer-note'
        )}
        </span>
    </div>
  `;
  }
}

export default ContentAgreementBriefItem;
