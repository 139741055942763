import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useBrandId from '@/hooks/use-brand-id';

function useUpdateLabelAttributes() {
  const brandId = useBrandId();
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';

  return useMutation({
    mutationKey: ['creatorGroupsLabels', 'update'],
    mutationFn: creatorGroupUpdaters.updateLabelAttributes,
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ['creatorGroupsLabels', creatorGroupId],
      });

      const previousData = await queryClient.getQueryData([
        'creatorGroupsLabels',
        creatorGroupId,
      ]);

      queryClient.setQueryData(
        ['creatorGroupsLabels', creatorGroupId],
        (old: any) => {
          return {
            ...old,
            data: produce(old.data, (draft: CreatorGroupLabel[]) => {
              const label = draft.find((_label) => _label.id === data.label.id);
              if (label) {
                Object.entries(data.newValues).forEach(([key, value]) => {
                  (label as any)[key] = value;
                });
              }
            }),
          };
        }
      );

      return {previousData};
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ['creatorGroupsLabels', creatorGroupId],
        context
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useUpdateLabelAttributes;
