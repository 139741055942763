import React, {useMemo} from 'react';
import {Label} from '@lightricks/react-design-system';
import styles from './Badge.module.scss';

export type BadgeProps = {
  testID?: string;
  text: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  textColor?: string;
  backgroundColor?: string;
};

function Badge(props: BadgeProps) {
  const {
    testID = 'badge',
    text,
    size = 'xs',
    backgroundColor,
    textColor,
  } = props;

  const style = useMemo(() => {
    return {
      backgroundColor,
    };
  }, [backgroundColor]);

  return (
    <Label
      data-testid={testID}
      size={size}
      color={textColor}
      className={`${styles.badgeContainer} ${styles[size]}`}
      sx={style}
    >
      {text}
    </Label>
  );
}

export default Badge;
