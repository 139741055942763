import React from 'react';
import ErrorLabel from '@/components/error-label';
import styles from './ValidatedField.module.scss';

type ValidatedFieldProps = {
  children: React.ReactNode;
  errors?: string[] | null;
};

function ValidatedField(props: ValidatedFieldProps) {
  const {children, errors} = props;

  return (
    <div className={styles.container}>
      {children}
      {errors &&
        errors.map((error) => <ErrorLabel key={error}>*{error}</ErrorLabel>)}
    </div>
  );
}

export default ValidatedField;
