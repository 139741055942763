import {useEffect} from 'react';
import Sentry from '@/services/sentry/Sentry';
import getCookieValue from '@/utils/getCookieValue';
import {getEnv} from '@/config/environment';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';

const LT_ID_COOKIE_NAME = getEnv().MODE === 'staging' ? 'stg__lt_id' : 'lt_id';

function useSentryUser() {
  const {data: dashboardUserData}: any = useDashboardUserQuery({});

  useEffect(() => {
    const deltaAnonymousId = getCookieValue('anonymous_id');
    const isDashboardUserDataPresent = !!dashboardUserData?.id;

    if (isDashboardUserDataPresent) {
      const ltId = getCookieValue(LT_ID_COOKIE_NAME);

      Sentry.setUser({
        id: dashboardUserData.id,
        ltxId: ltId,
        anonymousId: deltaAnonymousId,
      });
    } else {
      Sentry.setUser({anonymousId: deltaAnonymousId});
    }
  }, [dashboardUserData]);
}

export default useSentryUser;
