import React, {useState} from 'react';
import {Body, designSystemToken, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import truncateText from '@/utils/truncateText';
import Conditional from '@/components/conditional';
import styles from './ToggleableText.module.scss';

const TRANSLATION_PREFIX = 'components.toggleable-text';
const DEFAULT_MAX_TEXT_LENGTH = 150;

export type ToggleableTextProps = {
  testID?: string;
  text: string;
  textColor?: string;
  initialShowFullText?: boolean;
  onShowFullTextChange?: (showFullText: boolean) => void;
  maxTextLength?: number;
  showMoreLabel?: string;
  showLessLabel?: string;
};

function ToggleableText(props: ToggleableTextProps) {
  const {
    testID = 'toggleable-text',
    text,
    textColor = designSystemToken('semantic.fg.primary'),
    initialShowFullText = false,
    onShowFullTextChange,
    maxTextLength = DEFAULT_MAX_TEXT_LENGTH,
    showMoreLabel = translate(`${TRANSLATION_PREFIX}.show-more`),
    showLessLabel = translate(`${TRANSLATION_PREFIX}.show-less`),
  } = props;
  const [showFullText, setShowFullText] = useState(initialShowFullText);

  const showToggleTextButton = !!(text && text.length > maxTextLength);
  const textForDisplay = showFullText
    ? text
    : truncateText(text, maxTextLength);

  return (
    <div className={styles.toggleableTextContainer} data-testid={testID}>
      <Body size="lg" color={textColor} data-testid={`${testID}--text`}>
        {textForDisplay}
      </Body>
      <Conditional condition={showToggleTextButton}>
        <Label
          data-testid={`${testID}--toggle-button`}
          size="lg"
          className={styles.toggleButton}
          onClick={() => {
            setShowFullText(!showFullText);
            onShowFullTextChange?.(!showFullText);
          }}
        >
          {showFullText ? showLessLabel : showMoreLabel}
        </Label>
      </Conditional>
    </div>
  );
}

export default ToggleableText;
