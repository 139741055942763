import translate from '@/utils/translate';
import subscriptionUpdaters from '@/api/updaters/subscription';
import {modalSubscriptionActions} from '@/stores/modalSubscriptionStore';

const TRANSLATION_PREFIX =
  'components.modal-subscription.content-right.result.contact-sales';

const handleContactSalesClick = async (subscriptionId: string) => {
  const translationPrefix = `${TRANSLATION_PREFIX}`;
  modalSubscriptionActions.setLoading(true);
  try {
    await subscriptionUpdaters.requestCustomUpgrade(subscriptionId);
    modalSubscriptionActions.showSuccess({
      headline: translate(`${translationPrefix}.success.headline`),
      body: translate(`${translationPrefix}.success.body`),
    });
  } catch (error) {
    modalSubscriptionActions.showFailure({
      headline: translate(`${translationPrefix}.failure.headline`),
      body: translate(`${translationPrefix}.failure.body`),
    });
  }
  modalSubscriptionActions.setLoading(false);
};

export default handleContactSalesClick;
