import {capitalize} from 'lodash';

function getPlatformDisplayNameFromProvider(
  provider: string | null | undefined
) {
  if (!provider) {
    return '';
  }
  if (provider.includes('instagram')) {
    return 'Instagram';
  }
  if (provider.includes('tiktok')) {
    return 'TikTok';
  }
  if (provider.includes('pinterest')) {
    return 'Pinterest';
  }
  if (provider.includes('youtube')) {
    return 'YouTube';
  }
  if (provider.includes('facebook')) {
    return 'Facebook';
  }
  if (provider.includes('twitter')) {
    return 'Twitter';
  }
  return capitalize(provider);
}

export default getPlatformDisplayNameFromProvider;
