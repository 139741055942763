import React from 'react';
import {SimplifiedCampaign} from '@/views/creators/search/hooks/use-sorted-campaigns/useSortedCampaigns';
import Select from '@/components/select';
import styles from './CampaignSelector.module.scss';

type CampaignSelectorProps = {
  brandId: string;
  selectedCampaignId?: string;
  campaigns: SimplifiedCampaign[];
  onCampaignChange: (chosenCampaignId: string | string[]) => void;
};

function CampaignSelector({
  brandId,
  selectedCampaignId,
  campaigns,
  onCampaignChange,
}: CampaignSelectorProps) {
  return (
    <div className={styles.searchByCampaignSelect}>
      {campaigns.length > 0 && (
        <Select
          key={`${brandId}-${selectedCampaignId}`}
          containerClassName={styles.campaignSelect}
          selectedValueIds={[selectedCampaignId as string]}
          options={[...campaigns]}
          onChange={onCampaignChange}
          allowReSelect
        />
      )}
    </div>
  );
}

export default CampaignSelector;
