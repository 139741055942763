import React from 'react';
import BreadcrumbLabel from '@/components/breadcrumb-label';
import BreadcrumbSeparator from '@/components/breadcrumb-separator';
import styles from './Generic.module.scss';

function Generic({title}: {title?: string}) {
  return (
    <div className={styles.container}>
      {title ? (
        <BreadcrumbLabel testID="breadcrumb-label--generic" label={title} />
      ) : null}
    </div>
  );
}

export default Generic;
