import verticalTagFetchers from '@/api/fetchers/verticalTag';
import useDataQuery from '@/hooks/use-data-query';

function useVerticalTagsQuery() {
  return useDataQuery({
    queryKey: ['verticalTags'],
    queryFn: verticalTagFetchers.verticalTags,
  });
}

export default useVerticalTagsQuery;
