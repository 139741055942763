import {CreatorCardProps} from '@/components/creator-card/CreatorCard';
import {CreatorMenuItemProps} from '@/components/creator-card/CreatorOverflowMenuButton';
import useDataQuery from '@/hooks/use-data-query';

interface UseMenuItemsProps {
  creator: CreatorCardProps;
  creatorCardOverflowMenuItemsCallBack: (
    creator: CreatorCardProps
  ) => Promise<CreatorMenuItemProps[]>;
}

function useCreatorCardOverflowMenuItems({
  creator,
  creatorCardOverflowMenuItemsCallBack,
}: UseMenuItemsProps) {
  const queryKey = ['getMenuItems', creator];
  const queryFn = () => creatorCardOverflowMenuItemsCallBack(creator);

  return useDataQuery<CreatorMenuItemProps[], Error>({
    queryKey,
    queryFn,
  });
}

export default useCreatorCardOverflowMenuItems;
