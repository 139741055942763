const ENGAGEMENT_RATE_THRESHOLD = 0.01;

export default function getEngagementRateString(
  engagementRate: number | undefined,
  engagementRateThreshold = ENGAGEMENT_RATE_THRESHOLD,
  fallbackString = '-'
): string {
  let roundedEngagementRateString = fallbackString;
  if (engagementRate === undefined) {
    return roundedEngagementRateString;
  }
  const engagementRatePercentage = engagementRate * 100;
  const roundedEngagementRate = Number.isInteger(engagementRatePercentage)
    ? Math.round(engagementRatePercentage)
    : engagementRatePercentage.toFixed(3);

  if (Number(roundedEngagementRate) <= 0) {
    return roundedEngagementRateString;
  }
  if (Number(roundedEngagementRate) < engagementRateThreshold) {
    roundedEngagementRateString = `<${engagementRateThreshold}%`;
  } else {
    roundedEngagementRateString = `${Number(roundedEngagementRate).toFixed(
      2
    )}%`;
  }

  return roundedEngagementRateString;
}
