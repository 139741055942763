/**
 * returns a cleaned provider name from a given identity provider
 * @param provider - facebook, instagram, instagram_business,
 *                  tiktok, tiktok_v2, tiktok_creator_marketplace,
 *                  pinterest, pinterest_organic, twitter, youtube
 */
function getPlatformFromIdentityProvider(provider: string | null) {
  if (!provider) {
    return 'unknown';
  }

  if (provider.includes('instagram')) {
    return 'instagram';
  }
  if (provider.includes('tiktok')) {
    return 'tiktok';
  }
  if (provider.includes('pinterest')) {
    return 'pinterest';
  }
  return provider;
}

export default getPlatformFromIdentityProvider;
