import React from 'react';
import Box from '@mui/material/Box';
import {Button, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './BasicStepper.module.scss';
import StepperProgress from './stepper-progress';

const TRANSLATION_PREFIX = 'components.basic-stepper';

export type BasicStepperProps = {
  testID?: string;
  steps: number;
  onFinalStep?: () => void;
  stepperClassName?: string;
  showForwardButton?: boolean;
  showBackButtonLabel?: boolean;
  activeStep: number;
  setActiveStep: (newActiveStep: number, prevActiveStep: number) => void;
};

function BasicStepper(props: BasicStepperProps) {
  const {
    testID = 'basic-stepper',
    steps,
    onFinalStep,
    showForwardButton = true,
    showBackButtonLabel = true,
    stepperClassName,
    activeStep,
    setActiveStep,
  } = props;
  const isFinalStep = activeStep === steps - 1;

  const handleNext = () => {
    if (activeStep === steps - 1) {
      onFinalStep?.();
    }
    setActiveStep(
      activeStep < steps - 1 ? activeStep + 1 : activeStep,
      activeStep
    );
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1, activeStep);
  };

  const renderBackButton = () => (
    <div className={styles.backButton}>
      {activeStep > 0 && (
        <Button
          appearance="overlay"
          mode="filled"
          size="medium"
          icon={
            <Icon
              size="medium"
              appearance="neutral"
              name="Actions-Arrow-Back-Small"
            />
          }
          onClick={handleBack}
        >
          {showBackButtonLabel &&
            translate(`${TRANSLATION_PREFIX}.back-button-label`)}
        </Button>
      )}
    </div>
  );

  const renderForwardButton = () => (
    <div className={styles.forwardButton}>
      {showForwardButton && (
        <Button
          className={styles.button}
          appearance="neutral"
          mode="filled"
          size="medium"
          onClick={handleNext}
        >
          {isFinalStep
            ? translate(`${TRANSLATION_PREFIX}.complete-button-label`)
            : translate(`${TRANSLATION_PREFIX}.next-button-label`)}
        </Button>
      )}
    </div>
  );

  return (
    <Box
      className={`${styles.container} ${stepperClassName}`}
      data-testid={testID}
    >
      {renderBackButton()}
      <StepperProgress steps={steps} activeStep={activeStep} />
      {renderForwardButton()}
    </Box>
  );
}

export default BasicStepper;
