import dayjs from 'dayjs';
import {useMemo} from 'react';
import {TimeRange} from '@/types/timeRange';
import useParams from '@/hooks/use-params';
import useCreatorVettingReportStore from '@/stores/creatorVettingReportStore';
import {VettingFilters} from './types';

function useGetVettingFilters(timeRange: TimeRange): VettingFilters {
  const {reportId} = useParams();
  const creatorVettingReportState = useCreatorVettingReportStore();
  const report = creatorVettingReportState.reports[reportId];

  const dateRange = useMemo(() => {
    if (report?.timeRangeFilter) {
      return {
        startDate: report.timeRangeFilter.start,
        endDate: report.timeRangeFilter.end,
      };
    }

    return {
      startDate: dayjs(timeRange.start).toDate(),
      endDate: dayjs(timeRange.end).toDate(),
    };
  }, [report?.timeRangeFilter, timeRange]);

  return {
    dateRange,
    severity: report?.severityFilter,
  };
}

export default useGetVettingFilters;
