import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useBrandId from '@/hooks/use-brand-id';

function usePreferredCreatorGroupLabels() {
  const brandId = useBrandId();
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';

  const labelsResponse = useCreatorGroupsLabelsQuery({
    creatorGroupId,
    refetchOnWindowFocus: false,
  });

  return labelsResponse;
}

export default usePreferredCreatorGroupLabels;
