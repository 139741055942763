import React, {useState, useEffect} from 'react';
import {Button, Banner} from '@lightricks/react-design-system';
import queryClient from '@/utils/reactQueryClient';
import {EmailPreference} from '@/types/emailPreference';
import useUpdateEmailPreferences from '@/hooks/mutations/use-update-email-preferences';
import useEmailPreferencesQuery from '@/hooks/queries/use-email-preferences-query';
import translate from '../../../utils/translate';
import styles from './Notifications.module.scss';
import NotificationsTable from './NotificationsTable';

function Notifications() {
  const TRANSLATION_PREFIX = 'components.preferences.notifications';
  const {emailPreferences: initialEmailPreferences}: any =
    useEmailPreferencesQuery();
  const [emailPreferences, setEmailPreferences] = useState<EmailPreference[]>(
    []
  );
  const [changedPreferences, setChangedPreferences] = useState<
    Partial<EmailPreference[]>
  >([]) as any;
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');

  useEffect(() => {
    if (initialEmailPreferences) {
      setEmailPreferences(initialEmailPreferences);
    }
  }, [initialEmailPreferences]);

  const updateEmailPreferencesMutation = useUpdateEmailPreferences();

  const handleUpdatePreferences = async () => {
    await updateEmailPreferencesMutation.mutateAsync(changedPreferences);
    queryClient.setQueryData(['emailPreferences'], changedPreferences);
    setBannerMessage(
      translate(`${TRANSLATION_PREFIX}.save-preferences-banner-title`)
    );
    setShowBanner(true);
  };

  const handleUnsubscribe = async () => {
    const updatedPreferences = emailPreferences.map((preference) => {
      if (preference.name === 'brand_digest') {
        return {...preference, enabled: false, frequency: null};
      }
      return {...preference, enabled: false, frequency: null};
    });
    setEmailPreferences(updatedPreferences);
    setChangedPreferences(updatedPreferences);
    await updateEmailPreferencesMutation.mutateAsync(updatedPreferences as any);
    queryClient.setQueryData(['emailPreferences'], updatedPreferences);
    setBannerMessage(
      translate(`${TRANSLATION_PREFIX}.unsubscribe-banner-title`)
    );
    setShowBanner(true);
  };

  if (!emailPreferences || emailPreferences.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h2>{translate(`${TRANSLATION_PREFIX}.title`)}</h2>
      {showBanner && (
        <Banner
          isOpen={showBanner}
          title={bannerMessage}
          onActionClick={() => setShowBanner(false)}
          className={styles.unsubscribeBanner}
        />
      )}
      <NotificationsTable
        emailPreferences={emailPreferences}
        setEmailPreferences={setEmailPreferences}
        setChangedPreferences={setChangedPreferences}
      />
      <div className={styles.buttons}>
        <Button
          appearance="neutral"
          mode="tinted"
          size="large"
          onClick={handleUnsubscribe}
          className={styles.unsubscribeButton}
        >
          {translate(`${TRANSLATION_PREFIX}.unsubscribe`)}
        </Button>
        <Button
          appearance="neutral"
          mode="filled"
          size="large"
          onClick={handleUpdatePreferences}
          className={styles.updateButton}
        >
          {translate(`${TRANSLATION_PREFIX}.save-preferences`)}
        </Button>
      </div>
    </div>
  );
}

export default Notifications;
