import capsuleAiFetchers from '@/api/fetchers/capsuleAi';
import useDataQuery from '@/hooks/use-data-query';

function useTransparentImageQuery(brandId: string) {
  const response: any = useDataQuery({
    queryKey: ['transparentImage', brandId],
    queryFn: capsuleAiFetchers.transparentImage,
    enabled: !!brandId,
    meta: {
      brandId,
    },
    staleTime: 0,
  });
  return {...response};
}

export default useTransparentImageQuery;
