import clsx from 'clsx';
import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Avatar, Label} from '@lightricks/react-design-system';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import styles from './CreatorCell.module.scss';

function CreatorCell({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  if (row.original?.profile === undefined) return null;

  const src = row.original.profile.imageUrl;

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.avatarContainer,
          row.original.isLiveTracking && styles.liveTracking
        )}
      >
        <Avatar
          size="md"
          type={src ? 'picture' : 'placeholder'}
          src={src}
          className={styles.avatar}
        />
      </div>
      <Label size="sm">{row.original.profile.displayName}</Label>
    </div>
  );
}

export default CreatorCell;
