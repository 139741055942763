import {sortBy, uniqBy} from 'lodash';
import formattedPercentage from '@/utils/formattedPrecentage';
import numberAbbreviation from '@/utils/numberAbbreviation';
import {
  Audience,
  GenderAgePercentages,
  GenderPercentage,
} from '@/types/audience';
import {ProfileProps} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import {GENDER_PERCENTAGE_MOSTLY_THRESHOLD} from '@/components/creator-profile/constants';

const PLURAL_GENDERS = {
  male: 'Men',
  female: 'Women',
} as Record<string, 'Men' | 'Women'>;

function mapIdentity(identity: Identity) {
  function getFormattedCachedFollowerCount() {
    if (identity.cachedFollowerCount === null) {
      return '';
    }
    return numberAbbreviation(identity.cachedFollowerCount);
  }

  function getFormattedAverageImpressionsCount() {
    if (!identity.averageImpressionsCount) {
      return '';
    }

    return numberAbbreviation(identity.averageImpressionsCount);
  }

  function getFormattedEngagementValue() {
    if (!identity.engagementValue) {
      return '';
    }
    return numberAbbreviation(identity.engagementValue);
  }

  function getFormattedAverageEngagementRate() {
    if (!identity.averageEngagementRate) {
      return '';
    }
    return formattedPercentage(identity.averageEngagementRate);
  }

  function getFormattedAverageViewCount() {
    if (!identity.averageViewCount) {
      return '';
    }
    return numberAbbreviation(identity.averageViewCount);
  }

  function getFormattedAverageReelsPlays() {
    if (!identity.averageReelsPlays) {
      return '';
    }
    return numberAbbreviation(identity.averageReelsPlays);
  }

  function getFormattedAverageStoryImpressions() {
    if (!identity.averageStoryImpressions) {
      return '';
    }
    return numberAbbreviation(identity.averageStoryImpressions);
  }

  function getProviderDasherized() {
    return identity.provider.replaceAll('_', '-');
  }

  return {
    ...identity,
    formattedCachedFollowerCount: getFormattedCachedFollowerCount(),
    formattedAverageImpressionsCount: getFormattedAverageImpressionsCount(),
    formattedEngagementValue: getFormattedEngagementValue(),
    formattedAverageEngagementRate: getFormattedAverageEngagementRate(),
    formattedAverageViewCount: getFormattedAverageViewCount(),
    formattedAverageReelsPlays: getFormattedAverageReelsPlays(),
    formattedAverageStoryImpressions: getFormattedAverageStoryImpressions(),
    providerDasherized: getProviderDasherized(),
  };
}

function mapAudience(audience: Audience) {
  function getGenderPercentages(): GenderPercentage[] {
    const totalsByGender = audience.genderAgeTotals as Record<string, number>;
    const genders = Object.keys(totalsByGender);
    const values = Object.keys(totalsByGender).map(
      (gender) => totalsByGender[gender]
    );
    const totalCount = values.reduce((a, b) => a + b, 0);

    const totalsByGenderPercentageArray = genders.map((gender) => {
      return {
        gender: PLURAL_GENDERS[gender] || 'Unknown',
        percentage: Math.round((totalsByGender[gender] / totalCount) * 100),
      };
    });

    return totalsByGenderPercentageArray.filter((totalsByGenderItem) => {
      return totalsByGenderItem.percentage > 0;
    });
  }

  function getPercentageByGender(gender: 'Men' | 'Women' | 'Unknown') {
    if (genderPercentages !== undefined) {
      return (
        genderPercentages.find(
          (genderPercentage: GenderPercentage) =>
            genderPercentage.gender === gender
        )?.percentage || 0
      );
    }
    return 0;
  }

  function getPercentageOfAgeGroupIsGender(gender: 'all' | 'male' | 'female') {
    const genderAgePercentagesByGender = genderAgePercentages[gender];
    const genderPercentage =
      gender === 'male' ? maleGenderPercentage : femaleGenderPercentage;

    return genderAgePercentagesByGender.map((agePercentage) => {
      return {
        ageRange: agePercentage.ageRange,
        percentage: Math.round(
          agePercentage.percentage * (genderPercentage / 100)
        ),
      };
    });
  }

  function getPercentageOfAgeGroupByGender() {
    return {
      male: getPercentageOfAgeGroupIsGender('male'),
      female: getPercentageOfAgeGroupIsGender('female'),
    };
  }

  function getCityPercentages() {
    const totalsByCityFiltered = sortBy(audience.cities, ['value'])
      .reverse()
      .slice(0, 7);
    const totalCount = audience.cities.reduce((a, b) => a + b.value, 0);

    return totalsByCityFiltered.map((totalByCity) => {
      return {
        city: totalByCity.city,
        percentage: Math.round((totalByCity.value / totalCount) * 100),
      };
    });
  }

  function getCountryPercentages() {
    const totalsByCountryFiltered = sortBy(audience.countries, ['value'])
      .reverse()
      .slice(0, 7);
    const totalCount = audience.countries.reduce((a, b) => a + b.value, 0);

    return totalsByCountryFiltered.map((totalByCountry) => {
      return {
        country: totalByCountry.code,
        percentage: Math.round((totalByCountry.value / totalCount) * 100),
      };
    });
  }

  function getGenderAgePercentageForGender(genderFilter?: 'male' | 'female') {
    const totalsByGenderAge = audience.genderAge.filter((totalByGenderAge) => {
      if (genderFilter) {
        return genderFilter && totalByGenderAge.gender === genderFilter;
      }
      return true;
    });
    const totalFollowers = totalsByGenderAge.reduce(
      (a, b) => a + (b?.value || 0),
      0
    );
    const totalsByAgeRange = {} as Record<string, number>;

    totalsByGenderAge.forEach((genderAge) => {
      if (totalsByAgeRange[genderAge.age] !== undefined) {
        totalsByAgeRange[genderAge.age] += genderAge.value;
      } else {
        totalsByAgeRange[genderAge.age] = genderAge.value;
      }
    });

    return Object.keys(totalsByAgeRange).map((ageRange) => {
      return {
        ageRange,
        percentage: Math.round(
          (totalsByAgeRange[ageRange] / totalFollowers) * 100
        ),
      };
    });
  }

  function getGenderAgePercentages() {
    return {
      all: getGenderAgePercentageForGender(),
      female: getGenderAgePercentageForGender('female'),
      male: getGenderAgePercentageForGender('male'),
    } as GenderAgePercentages;
  }

  function getTopAgeGroup() {
    const sortedAgeGroups = genderAgePercentages.all
      .slice()
      .sort((ageGroup1, ageGroup2) => {
        return ageGroup2.percentage - ageGroup1.percentage;
      });

    return sortedAgeGroups[0];
  }

  const genderPercentages = getGenderPercentages();
  const maleGenderPercentage = getPercentageByGender(PLURAL_GENDERS.male);
  const femaleGenderPercentage = getPercentageByGender(PLURAL_GENDERS.female);
  const genderAgePercentages = getGenderAgePercentages();
  const percentageOfAgeGroupByGender = getPercentageOfAgeGroupByGender();
  const cityPercentages = getCityPercentages();
  const countryPercentages = getCountryPercentages();
  const topAgeGroup = getTopAgeGroup();

  return {
    ...audience,
    genderPercentages,
    maleGenderPercentage,
    femaleGenderPercentage,
    cityPercentages,
    countryPercentages,
    topCountry: countryPercentages[0],
    genderAgePercentages,
    topAgeGroup,
    percentageOfAgeGroupByGender,
    isMostlyMale: maleGenderPercentage > GENDER_PERCENTAGE_MOSTLY_THRESHOLD,
    isMostlyFemale: femaleGenderPercentage > GENDER_PERCENTAGE_MOSTLY_THRESHOLD,
  };
}

function mapCreatorProfileResponse(profileData: ProfileProps) {
  const identities = profileData.identities || [];

  const mappedIdentities: Identity[] = identities.map((identity) => ({
    ...mapIdentity(identity),
    audience: identity.audience ? mapAudience(identity.audience) : null,
  }));

  const mappedProfilePreviousCampaigns = uniqBy(
    profileData.profilePreviousCampaigns || [],
    'id'
  );
  return {
    ...profileData,
    identities: mappedIdentities,
    profilePreviousCampaigns: mappedProfilePreviousCampaigns,
  };
}

export default mapCreatorProfileResponse;
