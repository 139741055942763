import React from 'react';
import {Body, designSystemToken, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {Finding} from '@/types/creatorSafetyReport';
import BackgroundCheckItem, {
  BackgroundCheckItemProps,
} from '@/components/creator-vetting-report/components/background-check-item/BackgroundCheckItem';
import ProfileVettingBackgroundCheckReferenceDataWrapper from '@/components/creator-vetting-report/components/profile-vetting-background-check-reference-data-wrapper';
import styles from './ProfileVettingBackgroundCheckFinding.module.scss';

const TOP_REFERENCES_AMOUNT = 3;

interface ProfileVettingBackgroundCheckFindingProps {
  finding: Finding;
  reportId: string;
  creatorId: string;
}

function FindingHeader({title, summary}: {title: string; summary: string}) {
  return (
    <div className={styles.findingHeader}>
      <Label size="xl" color={designSystemToken('semantic.fg.primary')}>
        {title}
      </Label>
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {summary}
      </Body>
    </div>
  );
}

function ProfileVettingBackgroundCheckFinding(
  props: ProfileVettingBackgroundCheckFindingProps
) {
  const {finding, creatorId, reportId} = props;

  const topReferences = finding.references.slice(0, TOP_REFERENCES_AMOUNT);

  return (
    <div className={styles.profileVettingBackgroundCheckFindingContainer}>
      <FindingHeader title={finding.title} summary={finding.summary} />
      <Label
        size="sm"
        className={styles.topArticles}
        color={designSystemToken('semantic.fg.secondary')}
      >
        {translate('Top articles')}
      </Label>
      <div className={styles.references}>
        {topReferences.map((reference) => (
          <ProfileVettingBackgroundCheckReferenceDataWrapper
            key={reference.url}
            reportId={reportId}
            creatorId={creatorId}
            reference={reference}
          >
            {(item: BackgroundCheckItemProps) => (
              <BackgroundCheckItem {...item} />
            )}
          </ProfileVettingBackgroundCheckReferenceDataWrapper>
        ))}
      </div>
    </div>
  );
}

export default ProfileVettingBackgroundCheckFinding;
