import FortressWebClient from '@lightricks/fortress-web-client';
import {getEnv} from '@/config/environment';
import Fortress from '../services/fortress/Fortress';

const fortressConfig = {
  fortressAppName: getEnv().VITE_FORTRESS_APP_NAME,
  fortressVersion: getEnv().VITE_FORTRESS_VERSION,
  fortressApiBaseUri: getEnv().VITE_FORTRESS_API_BASE_URI,
};

Fortress.setDriver(
  new FortressWebClient(
    fortressConfig,
    ['staging', 'staging-gcp'].includes(getEnv().MODE) ? 'stg' : undefined
  )
);
