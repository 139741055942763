import Griffin from '@/utils/griffin';
import QueryContext from '@/api/QueryContext';
import useDataQuery from '@/hooks/use-data-query';

function useQuoteQuery({quoteId}: {quoteId: string}) {
  const response: any = useDataQuery({
    queryKey: ['quoteId', quoteId],
    queryFn: (context: QueryContext) => Griffin.getQuotes(quoteId),
    enabled: !!quoteId,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const quoteData = response?.data?.quotes?.[quoteId];

  return {...response, quoteData};
}

export default useQuoteQuery;
