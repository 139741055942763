export default [
  {
    id: 'Alabama',
    label: 'Alabama',
  },
  {
    id: 'Alaska',
    label: 'Alaska',
  },
  {
    id: 'Arizona',
    label: 'Arizona',
  },
  {
    id: 'Arkansas',
    label: 'Arkansas',
  },
  {
    id: 'California',
    label: 'California',
  },
  {
    id: 'Colorado',
    label: 'Colorado',
  },
  {
    id: 'Connecticut',
    label: 'Connecticut',
  },
  {
    id: 'Delaware',
    label: 'Delaware',
  },
  {
    id: 'Florida',
    label: 'Florida',
  },
  {
    id: 'Hawaii',
    label: 'Hawaii',
  },
  {
    id: 'Idaho',
    label: 'Idaho',
  },
  {
    id: 'Illinois',
    label: 'Illinois',
  },
  {
    id: 'Indiana',
    label: 'Indiana',
  },
  {
    id: 'Iowa',
    label: 'Iowa',
  },
  {
    id: 'Kansas',
    label: 'Kansas',
  },
  {
    id: 'Kentucky',
    label: 'Kentucky',
  },
  {
    id: 'Louisiana',
    label: 'Louisiana',
  },
  {
    id: 'Maine',
    label: 'Maine',
  },
  {
    id: 'Maryland',
    label: 'Maryland',
  },
  {
    id: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    id: 'Michigan',
    label: 'Michigan',
  },
  {
    id: 'Minnesota',
    label: 'Minnesota',
  },
  {
    id: 'Mississippi',
    label: 'Mississippi',
  },
  {
    id: 'Missouri',
    label: 'Missouri',
  },
  {
    id: 'Montana',
    label: 'Montana',
  },
  {
    id: 'Nebraska',
    label: 'Nebraska',
  },
  {
    id: 'Nevada',
    label: 'Nevada',
  },
  {
    id: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    id: 'New Jersey',
    label: 'New Jersey',
  },
  {
    id: 'New Mexico',
    label: 'New Mexico',
  },
  {
    id: 'New York',
    label: 'New York',
  },
  {
    id: 'North Carolina',
    label: 'North Carolina',
  },
  {
    id: 'North Dakota',
    label: 'North Dakota',
  },
  {
    id: 'Ohio',
    label: 'Ohio',
  },
  {
    id: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    id: 'Oregon',
    label: 'Oregon',
  },
  {
    id: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    id: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    id: 'South Carolina',
    label: 'South Carolina',
  },
  {
    id: 'South Dakota',
    label: 'South Dakota',
  },
  {
    id: 'Tennessee',
    label: 'Tennessee',
  },
  {
    id: 'Texas',
    label: 'Texas',
  },
  {
    id: 'Utah',
    label: 'Utah',
  },
  {
    id: 'Vermont',
    label: 'Vermont',
  },
  {
    id: 'Virginia',
    label: 'Virginia',
  },
  {
    id: 'Washington',
    label: 'Washington',
  },
  {
    id: 'West Virginia',
    label: 'West Virginia',
  },
  {
    id: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    id: 'Wyoming',
    label: 'Wyoming',
  },
];
