import {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Identity} from '@/types/identity';
import useFilteredTiktokSocialNetworks from '@/hooks/use-filtered-tiktok-social-networks';
import useSortedSocialNetworks from '@/hooks/use-sorted-social-networks';

interface SocialNetworksSelector {
  socialNetworks: Identity[];
  activeIdentity: Identity | null;
  onSocialNetworkClick: (socialNetwork: Identity) => void;
}

function useSocialNetworksSelector(
  identities: Identity[]
): SocialNetworksSelector {
  const [searchParams, setSearchParams] = useSearchParams();

  const socialNetworks = useSortedSocialNetworks(
    identities.filter((identity) => identity.socialNetwork?.supportsSocialData)
  );
  const filteredSocialNetworks = useFilteredTiktokSocialNetworks(
    socialNetworks
  ) as Identity[];
  const activeSocialNetworkId =
    searchParams.get('activeSocialNetwork') ||
    filteredSocialNetworks[0]?.provider ||
    '';
  const activeIdentity = useMemo(() => {
    return (
      filteredSocialNetworks.find(
        (socialNetwork) => socialNetwork.provider === activeSocialNetworkId
      ) || null
    );
  }, [filteredSocialNetworks, activeSocialNetworkId]);

  const onSocialNetworkClick = (socialNetwork: Identity) => {
    if (socialNetwork.provider === filteredSocialNetworks[0].provider) {
      searchParams.delete('activeSocialNetwork');
    } else {
      searchParams.set('activeSocialNetwork', socialNetwork.provider);
    }
    setSearchParams(searchParams);
  };

  return {
    socialNetworks: filteredSocialNetworks,
    activeIdentity,
    onSocialNetworkClick,
  };
}

export default useSocialNetworksSelector;
