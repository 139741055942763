import React from 'react';
import {Body, Headline, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Page from '@/components/page';
import {PopularPaysLogo} from '@/components/unusual-icons';
import styles from './MobileNotSupportedPlaceholder.module.scss';

const TRANSLATION_PREFIX = 'components.mobile-not-supported-placeholder';

function MobileNotSupportedPlaceholder() {
  return (
    <Page id="mobile-not-supported" className={styles.container}>
      <div className={styles.box}>
        <div className={styles.logoContainer}>
          <span className={styles.logo}>
            <PopularPaysLogo />
          </span>
          <Headline size="md" className={styles.popularPays}>
            {translate('shared.popular-pays')}
          </Headline>
          <Label size="xs" className={styles.byLightricks}>
            {translate('shared.by-lightricks')}
          </Label>
        </div>
        <Body
          size="md"
          className={`${styles.text} ${styles.title}`}
          dangerouslySetInnerHTML={{
            __html: translate(`${TRANSLATION_PREFIX}.subtitle`),
          }}
        />
        <Body size="md" className={`${styles.text} ${styles.subtitle}`}>
          {translate(`${TRANSLATION_PREFIX}.subtitle-bold`)}
        </Body>
      </div>
    </Page>
  );
}

export default MobileNotSupportedPlaceholder;
