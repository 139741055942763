import {
  AudienceAgeSelection,
  BaseFilterOption,
  DynamicPlatformFilters,
  Filter,
  FiltersGroup,
  MultiSelection,
  MultiUserInput,
  RangeSelection,
  SingleSelection,
  SingleUserInput,
  WeightedFilterOption,
  WeightedSingleSelection,
} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  PlatformFilterIds,
} from '@/types/models/search-creators/filterId';
import {getDirectOrNestedFilter} from '@/stores/search-creators/utils';
import translate from '../../../../utils/translate';

function getActiveSelectionString(
  filtersGroup: FiltersGroup<FilterIds>,
  filterId: FilterIds,
  platformFilterId?: PlatformFilterIds
) {
  const filter = getDirectOrNestedFilter(
    filtersGroup.filters,
    filterId,
    platformFilterId
  );

  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return '';
  }

  switch (filter.type) {
    case 'singleSelection':
      return translate(
        (filter as SingleSelection<BaseFilterOption>).selected
          ?.localeLabelKey || ''
      );
    case 'weightedSingleSelection': {
      const {selected} =
        filter as WeightedSingleSelection<WeightedFilterOption>;
      const selectedOptionLabel = translate(selected?.localeLabelKey || '');
      const selectedWeightValue = selected?.weight;

      if (selectedWeightValue) {
        return `${selectedOptionLabel} (${selectedWeightValue * 100}%)`;
      }
      return selectedOptionLabel;
    }
    case 'multiSelection':
    case 'weightedMultiSelection': {
      const selectedOptionsHash = (
        filter as MultiSelection<BaseFilterOption>
      ).selected.reduce((acc: {[key: string]: BaseFilterOption}, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      return (filter as MultiSelection<BaseFilterOption>).options
        .filter((option) => selectedOptionsHash[option.id] !== undefined)
        .map((option) => translate(option.localeLabelKey))
        .join(', ');
    }
    case 'multiSelectionAsync':
      return (filter as MultiSelection<BaseFilterOption>).selected
        .map((option) => translate(option.localeLabelKey))
        .join(', ');
    case 'rangeSelection': {
      const {selected, steps} = filter as RangeSelection;
      if (selected) {
        const {min, max} = selected;
        const minFallback = translate(steps[0].markLocaleLabelKey || '');
        const maxFallback = translate(
          steps[steps.length - 1].markLocaleLabelKey || ''
        );
        return `${min || minFallback} - ${max || maxFallback}`;
      }
      return '';
    }
    case 'multiUserInput':
      return (filter as MultiUserInput).inputs.join(', ');
    case 'singleUserInput':
      return (filter as SingleUserInput).input;
    case 'audienceAge': {
      const {selected, ldaSelected} = filter as AudienceAgeSelection;
      if (ldaSelected) {
        return translate(
          'views.creators.components.actions-drawer.filters.age-options.lda'
        );
      }
      return selected
        .map((option) => translate(option.localeLabelKey))
        .join(', ');
    }
    case 'dynamicPlatformFilters':
      // Do nothing
      break;

    default:
      break;
  }

  return '';
}

function getActiveSelectionIconName(
  filtersGroup: FiltersGroup<FilterIds>,
  filterId: FilterIds,
  platformFilterId?: PlatformFilterIds
) {
  const filter = getDirectOrNestedFilter(
    filtersGroup.filters,
    filterId,
    platformFilterId
  );

  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return '';
  }

  switch (filter.presentationType) {
    case 'singleSelectionOptionsGridWithIcon':
    case 'singleSelectionOptionsRadioButtonsListWithIcon':
      return (filter as SingleSelection<BaseFilterOption>).selected?.id;
    default:
      break;
  }

  return '';
}

function appliedFiltersCount(filter: Filter): number {
  switch (filter.type) {
    case 'singleSelection':
    case 'weightedSingleSelection':
      return (filter as SingleSelection<BaseFilterOption>).selected?.value
        ? 1
        : 0;
    case 'multiSelection':
    case 'multiSelectionAsync':
    case 'weightedMultiSelection':
      return (filter as MultiSelection<BaseFilterOption>).selected.length > 0
        ? 1
        : 0;
    case 'rangeSelection':
      return (filter as RangeSelection).selected ? 1 : 0;
    case 'multiUserInput':
      return (filter as MultiUserInput).inputs.length > 0 ? 1 : 0;
    case 'singleUserInput':
      return (filter as SingleUserInput).input ? 1 : 0;
    case 'audienceAge':
      return (filter as AudienceAgeSelection).selected.length > 0 ||
        (filter as AudienceAgeSelection).ldaSelected
        ? 1
        : 0;
    case 'dynamicPlatformFilters': {
      const {platformId, platformsFilters} = filter as DynamicPlatformFilters;
      const filters = platformsFilters[platformId];
      return Object.keys(filters).reduce((acc, platformFilterIds) => {
        return (
          acc +
          appliedFiltersCount(
            filters[platformFilterIds as PlatformFilterIds] as Filter
          )
        );
      }, 0);
    }
    default:
      return 0;
  }
}

function getFiltersGroupActiveFiltersCount(
  filtersGroup: FiltersGroup<FilterIds>
) {
  if (!filtersGroup) {
    return undefined;
  }

  return Object.keys(filtersGroup.filters).reduce(
    (acc: number, filterId: string) => {
      const filter = filtersGroup.filters[filterId as FilterIds] as Filter;
      return acc + (filter.isIrrelevant ? 0 : appliedFiltersCount(filter));
    },
    0
  );
}

export default {
  getActiveSelectionString,
  getFiltersGroupActiveFiltersCount,
  getActiveSelectionIconName,
};
