import {capitalize} from 'lodash';
import {FULL_NAME_TO_SHORT_NAME_MAP} from '@/utils/stateNameConverters';
import {
  BriefData,
  CampaignProps,
  PreferredCreatorGender,
  PreferredCreatorState,
  RequiredDeliverableTypeOptions,
  VerticalTag,
} from '@/types/campaign';
import BriefFieldManager from '../brief-field-manager';

function campaignDataToAgentMessage(campaignData: CampaignProps): BriefData {
  const briefFieldManager = new BriefFieldManager(campaignData);

  let product_fulfillment;
  if (campaignData.deliverablesType && campaignData.estimatedFulfillmentValue) {
    product_fulfillment = {
      method: campaignData.deliverablesType,
      cash_value: campaignData.estimatedFulfillmentValue,
    };
  }

  let creatorAgeRange = '';
  if (
    campaignData?.preferredCreatorAgeDetail?.minAge ||
    campaignData?.preferredCreatorAgeDetail?.maxAge
  ) {
    creatorAgeRange = `${campaignData?.preferredCreatorAgeDetail?.minAge}-${
      campaignData?.preferredCreatorAgeDetail?.maxAge || ''
    }`;
  }

  const required_deliverables =
    campaignData.campaignBriefDeliverableItems?.map((deliverable) => ({
      id: deliverable.id,
      type: deliverable.itemType as RequiredDeliverableTypeOptions,
      description: deliverable.description,
    })) || [];

  const campaign_brief_caption_guidelines = (
    campaignData.campaignBriefCaptionGuidelines || []
  ).map((guideline) => ({
    campaignId: guideline.campaignId,
    id: guideline.id,
    content: guideline.content,
    kind: guideline.kind,
    weight: guideline.weight,
  }));

  return {
    [briefFieldManager.fields.imageUrl.agentKey]: campaignData.imageUrl,
    [briefFieldManager.fields.name.agentKey]: campaignData.name,
    [briefFieldManager.fields.network.agentKey]: campaignData.network,
    [briefFieldManager.fields.descriptionHtml.agentKey]:
      campaignData.descriptionHtml,
    [briefFieldManager.fields.swapMatchKeywords.agentKey]: (
      campaignData?.swapMatchKeywords?.map(
        (keyword: string) => `#${keyword}`
      ) || []
    ).join(','),
    [briefFieldManager.fields.suggestedCostForCreations.agentKey]:
      campaignData.suggestedCostForCreationsMin &&
      campaignData.suggestedCostForCreations
        ? `$${campaignData.suggestedCostForCreationsMin}-$${campaignData.suggestedCostForCreations}`
        : '',
    [briefFieldManager.fields.preferredCreatorGenders.agentKey]:
      campaignData.preferredCreatorGenders?.map(
        (gender: PreferredCreatorGender) => gender.name?.toLowerCase() || ''
      ) || [],
    [briefFieldManager.fields.unlocksAt.agentKey]: `${
      campaignData.unlocksAt || ''
    }-${campaignData.locksAbove || ''}`,
    [briefFieldManager.fields.idealCreatorBriefItem.agentKey]: String(
      briefFieldManager.fields.idealCreatorBriefItem?.value.contentHtml || ''
    ),
    [briefFieldManager.fields.productToFeatureBriefItem.agentKey]: String(
      briefFieldManager.fields.productToFeatureBriefItem?.value.contentHtml ||
        ''
    ),
    [briefFieldManager.fields.preferredCreatorAgeDetail.agentKey]:
      creatorAgeRange,
    [briefFieldManager.fields.preferredCreatorStates.agentKey]:
      campaignData.preferredCreatorStates?.map(
        (state: PreferredCreatorState) =>
          FULL_NAME_TO_SHORT_NAME_MAP[state.name || ''] || ''
      ) || [],
    [briefFieldManager.fields.verticalTags.agentKey]:
      campaignData.verticalTags.map((verticalTag: VerticalTag) =>
        capitalize(verticalTag.name)
      ),
    [briefFieldManager.fields.deliverablesType.agentKey]: product_fulfillment,
    [briefFieldManager.fields.campaignBriefDeliverableItems.agentKey]:
      required_deliverables,
    [briefFieldManager.fields.campaignBriefCaptionGuidelines.agentKey]:
      campaign_brief_caption_guidelines,
    [briefFieldManager.fields.contentMandatoriesBriefItem.agentKey]: String(
      briefFieldManager.fields.contentMandatoriesBriefItem?.value.contentHtml ||
        ''
    ),
    [briefFieldManager.fields.postingInstructionsBriefItem.agentKey]: String(
      briefFieldManager.fields.postingInstructionsBriefItem?.value
        .contentHtml || ''
    ),
    [briefFieldManager.fields.daysToPostAfterDeliverableFulfilled.agentKey]:
      campaignData.daysToPostAfterDeliverableFulfilled,
    [briefFieldManager.fields.contentAgreementTerms.agentKey]: (
      campaignData.contentAgreementTerms || []
    ).map((contentAgreementTerm) => ({
      id: contentAgreementTerm.id,
      name: contentAgreementTerm.name,
      displayText: contentAgreementTerm.displayText,
    })),
    [briefFieldManager.fields.contentAgreementTimePeriodInMonths.agentKey]:
      campaignData.contentAgreementTimePeriodInMonths,
  };
}

export default campaignDataToAgentMessage;
