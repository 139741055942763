import React from 'react';
import Accordion from './Accordion';

type AccordionItemProps = {
  id: string;
  title: string;
  subtitle?: string | React.ReactNode;
  expanded?: boolean;
  expandIcon?: React.ReactNode | 'default';
  Component: React.ReactNode;
};

type AccordionListProps = {
  items: AccordionItemProps[];
  onAccordionChange?: (id: string, expanded: boolean) => void;
};

function AccordionList({items, onAccordionChange}: AccordionListProps) {
  return (
    <>
      {items.map(({id, title, subtitle, expanded, expandIcon, Component}) => (
        <Accordion
          key={id}
          onChange={(_, _expanded) => onAccordionChange?.(id, _expanded)}
          expanded={expanded}
          expandIcon={expandIcon}
          title={title}
          subtitle={subtitle}
          content={Component}
        />
      ))}
    </>
  );
}

export default AccordionList;
