import {useMutation} from '@tanstack/react-query';
import campaignUpdaters from '@/api/updaters/campaign';

/**
 * @name useUpsertCampaign
 * @description React hook that upsert a campaign
 * @returns {Object} An object containing the mutation state and mutation function
 */
function useUpsertCampaign({campaignId}: {campaignId: string}) {
  return useMutation({
    mutationKey: ['campaign', 'upsertCampaign', campaignId],
    mutationFn: campaignUpdaters.upsertCampaign,
    meta: {
      campaignId,
    },
  });
}

export default useUpsertCampaign;
