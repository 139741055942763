import React from 'react';
import {Box, ClickAwayListener, Popper} from '@mui/material';
import {Button, Icon, Input} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ENTER_KEY} from '@/config/constants';
import {type VettableCreators} from '@/types/vettableCreatorsResponse';
import useVettingHubStore, {vettingHubActions} from '@/stores/vettingHubStore';
import styles from './CreatorSearchBar.module.scss';
import CreatorSearchResults from './CreatorSearchResults';
import NetworkDropdown from './NetworkDropdown';
import useInitialAddedCreatorIds from './useInitialAddedCreatorIds';

export type CreatorSearchBarProps = {
  testID?: string;
  resultsData: VettableCreators | undefined;
  onSubmit: (value: string) => void;
  onClose: () => void;
  isLoading?: boolean;
};

function CreatorSearchBar(props: CreatorSearchBarProps) {
  const {
    testID = 'creator-search-bar',
    resultsData,
    onSubmit,
    onClose,
    isLoading,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const initialAddedCreators = useInitialAddedCreatorIds(resultsData);
  const searchValue = useVettingHubStore((state) => state.searchValue);

  const onClickClose = React.useCallback(() => {
    vettingHubActions.setSearchValue('');
    onClose();
    inputRef.current?.focus();
  }, [onClose]);

  const onClickSubmit = React.useCallback(() => {
    onSubmit(searchValue);
  }, [onSubmit, searchValue]);

  const getEndIcon = React.useCallback(() => {
    return resultsData !== undefined ? (
      <CloseButton onClickClose={onClickClose} />
    ) : (
      <SubmitButton onClickSubmit={onClickSubmit} isLoading={isLoading} />
    );
  }, [isLoading, resultsData, onClickSubmit, onClickClose]);

  const onInputChange = (val: string) => {
    if (val !== '@') vettingHubActions.setSearchValue(val);
  };

  const startIcon = (
    <>
      <NetworkDropdown />
      {searchValue && <Box sx={{ml: '12px', mr: '-10px'}}>@</Box>}
    </>
  );

  return (
    <>
      <div ref={containerRef} data-testid={testID} className={styles.container}>
        <Input
          className={styles.input}
          startIcon={startIcon}
          value={searchValue}
          onInputChange={onInputChange}
          endIcon={getEndIcon()}
          placeholder={translate('creator-handle-placeholder')}
          inputProps={{ref: inputRef, searchValue}}
          onKeyPress={({key, charCode}: {key: string; charCode: number}) => {
            if (key === ENTER_KEY.key || charCode === ENTER_KEY.charCode) {
              onClickSubmit();
            }
          }}
        />
      </div>
      <Popper
        disablePortal
        open={resultsData !== undefined}
        anchorEl={containerRef.current}
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={onClickClose}>
          <div
            className={styles.popperContent}
            data-testid={`${testID}-results`}
          >
            <CreatorSearchResults
              data={resultsData}
              initialAddedCreators={initialAddedCreators}
              onClose={onClose}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

type CloseButtonProps = {onClickClose: () => void};

function CloseButton({onClickClose}: CloseButtonProps) {
  return (
    <Button
      testID="close-button"
      appearance="neutral"
      size="medium"
      mode="plain"
      onClick={onClickClose}
      icon={
        <Icon name="Actions-Close-Small" size="medium" appearance="neutral" />
      }
    />
  );
}

type SubmitButtonProps = {onClickSubmit: () => void; isLoading?: boolean};

function SubmitButton({onClickSubmit, isLoading}: SubmitButtonProps) {
  const loadingIcon = '/assets/svg/icon-loading-circle-black.svg';
  const sharedSx = {mr: '-4px', pl: '16px', pr: '12px', zIndex: '100'};
  if (isLoading) {
    return (
      <Box
        component="img"
        src={loadingIcon}
        alt="loading circle icon"
        sx={{pr: '4px'}}
      />
    );
  }
  return (
    <Button
      appearance="neutral"
      size="medium"
      sx={sharedSx}
      mode="filled"
      onClick={onClickSubmit}
      endIcon={
        <Icon
          name="Actions-Arrow-Forward-Small"
          size="medium"
          appearance="inverse"
        />
      }
    >
      {translate('add-creator')}
    </Button>
  );
}

export default CreatorSearchBar;
