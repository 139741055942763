import {useMutation} from '@tanstack/react-query';
import agencyUpdaters from '@/api/updaters/agency';

/**
 * @name useCreateAgency
 * @description React hook that creates an agency
 * @returns {Object} An object containing the mutation state and mutation function
 */
function useCreateAgency() {
  return useMutation({
    mutationKey: ['agency', 'createAgency'],
    mutationFn: agencyUpdaters.createAgency,
  });
}

export default useCreateAgency;
