import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Profile from '@/views/creators/profile';

export const CREATOR_PROFILE_ROUTES = {
  MAIN: ':creatorId',
};

function CreatorProfileRoutes() {
  return (
    <Routes>
      <Route path={CREATOR_PROFILE_ROUTES.MAIN} element={<Profile />} />
    </Routes>
  );
}

export default CreatorProfileRoutes;
