import {useMutation} from '@tanstack/react-query';
import {type CreatorBrandSafetyVettingStatus} from '@/types/creatorBrandSafetyVetting';
import vettingUpdaters from '@/api/updaters/vetting';

type UpdateVettingStatusArgs = {
  onSuccess?: (data: unknown) => void;
  onMutate?: (data: {
    vettingId: string;
    status: CreatorBrandSafetyVettingStatus;
  }) => void;
  onError?: (error: unknown, variables: unknown, context: unknown) => void;
  onSettled?: (data: unknown, error: unknown) => void;
};

function useUpdateVettingStatus(args: UpdateVettingStatusArgs) {
  const {onSuccess, onMutate, onError, onSettled} = args;

  return useMutation({
    mutationKey: ['vettingStatus', 'update'],
    mutationFn: vettingUpdaters.status,
    onSuccess,
    onMutate,
    onError,
    onSettled,
  });
}

export default useUpdateVettingStatus;
