import React, {useEffect, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {
  Body,
  Button,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {
  buttonNames,
  flowNames,
  reasons,
  screenNames,
} from '@/lib/delta/deltaConstants.js';
import useIsMobile from '@/hooks/use-is-mobile';
import Fortress, {
  VerifyOTPAndSaveTokenCookiesResponse,
} from '../../services/fortress/Fortress';
import translate from '../../utils/translate';
import ModalAuth from '../modal-auth';
import OtpInput from '../otp-input/OtpInput';
import styles from './OtpVerificationModal.module.scss';

export type OtpVerificationModalProps = {
  testID?: string;
  isOpen: boolean;
  email: string;
  setIsModalOpen: (isOpen: boolean) => void;
  authenticateCallback: (token: string, data: Record<string, unknown>) => void;
};

function OtpVerificationModal(props: OtpVerificationModalProps) {
  const {
    isOpen,
    email,
    authenticateCallback,
    setIsModalOpen,
    testID = 'otp-verification-modal',
  } = props;
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);
  const [isResendCodeSuccess, setIsResendCodeSuccess] = useState(false);
  const [isLoadingCodeVerify, setIsLoadingCodeVerify] = useState(false);
  const isMobile = useIsMobile();
  const [otpInputs, setOtpInputs] = useState<HTMLInputElement[]>([]);

  const flowEvent = AnalyticsService.getFlow(flowNames.AUTH.NEW_SIGNUP) || {};

  useEffect(() => {
    return () => {
      resetOtpModal();
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetOtpModal();
    }
  }, [isOpen]);

  const resetOtpModal = () => {
    setIsResendCodeLoading(false);
    setIsResendCodeSuccess(false);
    setIsLoadingCodeVerify(false);
    setError('');
    setCode('');
  };

  const resendCode = async () => {
    AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
      button_name: buttonNames.SIGNUP.RESEND_CODE,
      screen_name: screenNames.AUTH.NEW_SIGNUP_OTP,
      flow_id: flowEvent.flow_id,
      flow_name: flowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id:
        AnalyticsService.getActiveScreenPresented().screen_presentation_id,
      campaign_id: '',
      creator_id: '',
      tab: '',
    });
    const processId = uuid();
    const stepDateEvent = {
      flow_id: flowEvent.flow_id,
      process_id: processId,
      signup_provider: 'fortress',
      request_type: 'send_otp',
      input_value: code,
      number_of_required_fields: 0,
      number_of_fields_completed: 0,
    };
    AnalyticsService.dispatchEvent(
      eventNames.SIGNUP_STEP_STARTED,
      stepDateEvent
    );

    setIsResendCodeLoading(true);
    setIsResendCodeSuccess(false);
    setError('');
    resetOtpInputs();
    try {
      await Fortress.generateOTP(email);
      setIsResendCodeSuccess(true);

      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.AUTH.SUCCESS,
        error: null,
      });
    } catch (e: any) {
      setError(e?.message);

      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.AUTH.FAILURE,
        error: e?.message || '',
      });
    }
    setIsResendCodeLoading(false);
  };

  const onVerifyButtonClick = async (otpCode: string): Promise<void> => {
    if (otpCode.length !== 6) {
      return;
    }
    AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
      button_name: buttonNames.SIGNUP.CONTINUE,
      screen_name: screenNames.AUTH.NEW_SIGNUP_OTP,
      flow_id: flowEvent.flow_id,
      flow_name: flowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id:
        AnalyticsService.getActiveScreenPresented().screen_presentation_id,
      campaign_id: '',
      creator_id: '',
      tab: '',
    });
    const processId = uuid();
    const stepDateEvent = {
      flow_id: flowEvent.flow_id,
      process_id: processId,
      signup_provider: 'fortress',
      request_type: 'verify_otp',
      input_value: code,
      number_of_required_fields: 1,
      number_of_fields_completed: 1,
    };
    AnalyticsService.dispatchEvent(
      eventNames.SIGNUP_STEP_STARTED,
      stepDateEvent
    );

    const verifySession: VerifyOTPAndSaveTokenCookiesResponse | null =
      await onVerifyCode(otpCode, stepDateEvent);
    if (verifySession?.token) {
      await authenticateCallback(verifySession.token, stepDateEvent);
    }
  };

  const onVerifyCode = async (
    otpCode: string,
    stepDateEvent: Record<string, unknown>
  ): Promise<VerifyOTPAndSaveTokenCookiesResponse | null> => {
    let tokens = null;
    try {
      setError('');
      setIsLoadingCodeVerify(true);
      tokens = await Fortress.verifyOTPAndSaveTokenCookies(email, otpCode);
    } catch (e: any) {
      setError(e?.message);
      setIsLoadingCodeVerify(false);

      AnalyticsService.dispatchEvent(eventNames.SIGNUP_STEP_ENDED, {
        ...stepDateEvent,
        reason: reasons.AUTH.FAILURE,
        error: e?.message || '',
      });
    }
    return tokens;
  };

  const resetOtpInputs = () => {
    otpInputs.forEach((element: HTMLInputElement) => {
      element.value = ''; // eslint-disable-line no-param-reassign
    });
    setCode('');
    otpInputs?.[0].focus();
  };

  const onModalClose = () => {
    AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
      button_name: buttonNames.SIGNUP.CLOSE_MODAL,
      screen_name: screenNames.AUTH.NEW_SIGNUP_OTP,
      flow_id: flowEvent.flow_id,
      flow_name: flowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id:
        AnalyticsService.getActiveScreenPresented().screen_presentation_id,
      campaign_id: '',
      creator_id: '',
      tab: '',
    });
    setIsModalOpen(false);

    AnalyticsService.screenDismissed(
      screenNames.AUTH.NEW_SIGNUP_OTP,
      screenNames.AUTH.NEW_SIGNUP
    );
    AnalyticsService.screenPresented(
      screenNames.AUTH.NEW_SIGNUP,
      screenNames.AUTH.NEW_SIGNUP_OTP
    );
  };

  const renderTitle = (
    <div className={styles.otpHeader}>
      <Button
        testID="close-modal-button"
        appearance="neutral"
        mode="plain"
        size="large"
        className={styles.otpCloseIcon}
        onClick={onModalClose}
      >
        <Icon
          size="large"
          appearance="neutral"
          name="Actions-Patch-Cancel-Line"
        />
      </Button>
      <Headline size={isMobile ? 'md' : 'xl'}>
        {translate('components.auth.otp-verification-modal.title')}
      </Headline>
    </div>
  );

  return (
    <ModalAuth
      testID={testID}
      open={isOpen}
      modalTitle={renderTitle}
      onClose={onModalClose}
    >
      <div className={styles.otpContent}>
        <Body
          size={isMobile ? 'lg' : 'xl'}
          color={designSystemToken('semantic.fg.secondary')}
        >
          {translate('components.auth.otp-verification-modal.email-label')}
        </Body>
        <Body
          size={isMobile ? 'lg' : 'xl'}
          color={designSystemToken('semantic.fg.secondary')}
        >
          {email}
        </Body>
        <div>
          <OtpInput
            onTypingCode={setCode}
            setOtpInputElements={setOtpInputs}
            onSubmit={onVerifyButtonClick}
          />
          <Body
            size="sm"
            className={styles.otpError}
            color={designSystemToken('semantic.fg.danger')}
            dangerouslyInnerHTML={error}
          />
        </div>
        <div className={styles.otpContinueButton}>
          <Button
            type="submit"
            appearance="brand"
            mode="filled"
            size="large"
            disabled={code.length < 6}
            onClick={() => onVerifyButtonClick(code)}
            isLoading={isLoadingCodeVerify}
            fullWidth
          >
            {translate('components.auth.otp-verification-modal.continue')}
          </Button>
        </div>
        <Button
          appearance="neutral"
          mode="plain"
          size="large"
          onClick={resendCode}
          className={styles.resendCodeButton}
          isLoading={isResendCodeLoading}
          disabled={isResendCodeLoading}
        >
          {translate('components.auth.otp-verification-modal.resend-code')}
        </Button>
        <div className={styles.otpCodeResendSuccess}>
          {isResendCodeSuccess ? (
            <>
              <Icon
                size="small"
                appearance="neutral"
                name="Actions-Patch-Accept-Line"
              />
              <Body size="sm">
                {translate(
                  'components.auth.otp-verification-modal.resend-code-success'
                )}
              </Body>
            </>
          ) : null}
        </div>
      </div>
    </ModalAuth>
  );
}

export default OtpVerificationModal;
