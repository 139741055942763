import copy from 'copy-to-clipboard';
import React from 'react';
import {Box} from '@mui/material';
import {
  Button,
  Body,
  designSystemToken,
  Icon,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {getEnv} from '@/config/environment';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import CheckboxLabel from '@/views/creators/components/checkbox-label';
import usePreferredCreatorGroupMemberships from '@/views/creators/lists/hooks/usePreferredCreatorGroupMemberships';
import styles from './ShareList.module.scss';

const TRANSLATION_PREFIX = 'components.share-list';

function ShareList({label}: {label?: CreatorGroupLabel}) {
  const {creatorGroupId} = usePreferredCreatorGroupMemberships();
  const [posts, setPosts] = React.useState(true);
  const [networkAnalytics, setNetworkAnalytics] = React.useState(true);
  const [portfolio, setPortfolio] = React.useState(true);
  const [linkCopied, setLinkCopied] = React.useState(false);

  const downloadCSV = () => {
    const url = new URL(`${getEnv().VITE_API_HOST}/shared_list_creators/csv`);
    url.searchParams.append('ref_id', label?.id ?? creatorGroupId);
    url.searchParams.append('type', label?.id ? 'labels' : 'creator_groups');

    window.open(url.href, '_blank');
  };

  const copyLink = () => {
    const url = new URL(`${getEnv().VITE_DOMAIN_HOST}/shared-list`);
    url.searchParams.append('ref_id', label?.id ?? creatorGroupId);
    url.searchParams.append('type', label?.id ? 'labels' : 'creator_groups');
    url.searchParams.append('posts', posts.toString());
    url.searchParams.append('networkAnalytics', networkAnalytics.toString());
    url.searchParams.append('portfolio', portfolio.toString());

    copy(url.href);
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  return (
    <div className={styles.content}>
      <Box className={styles.checkboxContainer}>
        <Body size="lg" color={designSystemToken('semantic.fg.secondary')}>
          {translate(`${TRANSLATION_PREFIX}.description`)}
        </Body>
        <CheckboxLabel
          checked={portfolio}
          onChange={() => {
            setPortfolio(!portfolio);
          }}
          label={translate(`${TRANSLATION_PREFIX}.options.overview`)}
        />
        <CheckboxLabel
          checked={networkAnalytics}
          onChange={() => {
            setNetworkAnalytics(!networkAnalytics);
          }}
          label={translate(`${TRANSLATION_PREFIX}.options.analytics`)}
        />
        <CheckboxLabel
          checked={posts}
          onChange={() => {
            setPosts(!posts);
          }}
          label={translate(`${TRANSLATION_PREFIX}.options.posts`)}
        />
      </Box>
      <div className={styles.saveButtonContainer}>
        <Button
          appearance="neutral"
          size="large"
          mode="tinted"
          className={styles.saveButton}
          onClick={downloadCSV}
          icon={
            <Icon
              name="Actions-Save-Single"
              size="medium"
              appearance="neutral"
            />
          }
        >
          {translate(`${TRANSLATION_PREFIX}.download`)}
        </Button>
        <Button
          appearance="neutral"
          size="large"
          mode="filled"
          className={styles.copyButton}
          onClick={copyLink}
          style={{
            backgroundColor: linkCopied ? '#494A4D' : '',
          }}
          icon={
            !linkCopied ? (
              <Icon name="Navigation-Copy" size="medium" appearance="inverse" />
            ) : null
          }
        >
          {linkCopied
            ? translate(`${TRANSLATION_PREFIX}.copied`)
            : translate(`${TRANSLATION_PREFIX}.copy`)}
        </Button>
      </div>
    </div>
  );
}

ShareList.textAlignment = 'left' as const;
ShareList.footerAlignment = 'right' as const;

export default ShareList;
