import {identity, isEmpty, pickBy} from 'lodash';
import {HealthEvaluation} from '@/types/healthEvaluation';
import identityFetcher from '@/api/fetchers/identity';
import useDataQuery from '@/hooks/use-data-query';

const TRANSLATION_PREFIX =
  'components.social-network-identity-summary.health-scores';

function useIdentityHealthEvaluationQuery({
  identityId,
  enabled = true,
}: {
  identityId: string;
  enabled?: boolean;
}) {
  const response = useDataQuery<HealthEvaluation>({
    queryKey: ['identityHealthEvaluation', identityId],
    queryFn: identityFetcher.healthEvaluation,
    enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    meta: {
      identityId,
    },
  });

  const getHealthEvaluation = () => {
    if (!response.data || isEmpty(response.data)) {
      return null;
    }
    return response.data as HealthEvaluation;
  };

  const getRating = () => {
    const healthEvaluation = getHealthEvaluation();
    if (!healthEvaluation) {
      return '';
    }
    if (healthEvaluation.score <= 0) {
      return 'unhealthy';
    }
    if (healthEvaluation.score <= 5) {
      return 'investigate';
    }

    return 'healthy';
  };

  const getRatingStringKey = () => {
    const rating = getRating();
    if (!rating) {
      return '';
    }
    return `${TRANSLATION_PREFIX}.${rating}`;
  };

  return {
    ...response,
    healthEvaluation: pickBy(
      {
        ...getHealthEvaluation(),
        rating: getRating(),
        ratingStringKey: getRatingStringKey(),
      },
      identity
    ) as HealthEvaluation,
  };
}

export default useIdentityHealthEvaluationQuery;
