import {useCallback} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';

type BrandSafetyCreatorPresentedEventParams = {
  flow_id?: string;
  screen_presentation_id?: string;
  report_id: string;
  creator_id: string;
  creator_name: string;
};

type BrandSafetyCreatorDismissedEventParams = {
  flow_id?: string;
  screen_presentation_id?: string;
  report_id: string;
  creator_id: string;
  creator_name: string;
  dismissed_reason?: string;
};

function useTrackBrandSafetyCreatorEvent() {
  const presented = useCallback(
    ({
      flow_id,
      screen_presentation_id,
      report_id,
      creator_id,
      creator_name,
    }: BrandSafetyCreatorPresentedEventParams) => {
      try {
        const brandSafetyCreatorFlow = AnalyticsService.getOrCreateFlow(
          FLOW_NAMES.BRAND_SAFETY_CREATOR
        );
        AnalyticsService.dispatchEvent(
          eventNames.BRAND_SAFETY_CREATOR_PRESENTED,
          {
            flow_id: flow_id || brandSafetyCreatorFlow.flow_id,
            screen_presentation_id:
              screen_presentation_id ||
              brandSafetyCreatorFlow.screen_presentation_id,
            report_id,
            creator_id,
            creator_name,
          }
        );
      } catch (error) {
        console.error(
          'Error tracking brand safety creator presented event',
          error
        );
      }
    },
    []
  );

  const dismissed = useCallback(
    ({
      flow_id,
      screen_presentation_id,
      report_id,
      creator_id,
      creator_name,
      dismissed_reason = '',
    }: BrandSafetyCreatorDismissedEventParams) => {
      try {
        const brandSafetyCreatorFlow = AnalyticsService.getOrCreateFlow(
          FLOW_NAMES.BRAND_SAFETY_CREATOR
        );
        AnalyticsService.dispatchEvent(
          eventNames.BRAND_SAFETY_CREATOR_DISMISSED,
          {
            flow_id: flow_id || brandSafetyCreatorFlow.flow_id,
            screen_presentation_id:
              screen_presentation_id ||
              brandSafetyCreatorFlow.screen_presentation_id,
            report_id,
            creator_id,
            creator_name,
            dismissed_reason,
          }
        );
        AnalyticsService.deleteFlow(FLOW_NAMES.BRAND_SAFETY_CREATOR);
      } catch (error) {
        console.error(
          'Error tracking brand safety creator dismissed event',
          error
        );
      }
    },
    []
  );

  return {presented, dismissed};
}

export default useTrackBrandSafetyCreatorEvent;
