import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import type {
  CreatorBrandSafetyVetting,
  CreatorBrandSafetyVettingStatus,
} from '@/types/creatorBrandSafetyVetting';
import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import {queryKey as creatorBrandSafetyVettingsQueryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import {queryKey as vettingReportQueryKey} from '@/hooks/queries/use-vetting-report-query/useVettingReportQuery';

interface UpdateVettingReportStatusParams {
  creatorId: string;
  reportId: string;
  status: CreatorBrandSafetyVettingStatus;
}

export const updateVettingReportStatus = ({
  creatorId,
  reportId,
  status,
}: UpdateVettingReportStatusParams) => {
  const queryKey = [vettingReportQueryKey, creatorId, reportId];
  const previousData = queryClient.getQueryData(queryKey);

  queryClient.setQueryData(
    queryKey,
    (old: {data: CreatorSafetyReport} | undefined) => {
      if (!old) {
        return old;
      }
      return produce(old, (draft) => {
        if (draft) {
          draft.data.vettingStatus = status;
        }
      });
    }
  );
  return previousData;
};

interface UpdateVettingReportsStatusParams {
  brandId: string;
  vettingId: string;
  status: CreatorBrandSafetyVettingStatus;
}

export const updateCreatorBrandSafetyVettingsStatus = ({
  brandId,
  vettingId,
  status,
}: UpdateVettingReportsStatusParams) => {
  const queryKey = [creatorBrandSafetyVettingsQueryKey, brandId];
  const previousData = queryClient.getQueryData(queryKey);

  queryClient.setQueryData(
    queryKey,
    (old: {data: CreatorBrandSafetyVetting[]} | undefined) => {
      if (!old) {
        return old;
      }
      return produce(old, (draft) => {
        const vettingRow = draft?.data.find(
          (vetting) => vetting.id === vettingId
        );
        if (vettingRow) {
          vettingRow.status = status;
        }
      });
    }
  );

  return previousData;
};
