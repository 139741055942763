import clsx from 'clsx';
import React from 'react';
import {Button, Icon, Label} from '@lightricks/react-design-system';
import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import styles from './List.module.scss';
import ListMenu from './ListMenu';

type ListProps = {
  group?: CreatorGroupLabel;
  isSelected?: boolean;
  onClick?: () => void;
  testID?: string;
};

function List(props: ListProps) {
  const {
    group,
    isSelected = false,
    onClick,
    testID = 'list-drawer-list',
  } = props;
  const contextMenuButtonRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [isChipHovered, setIsChipHovered] = React.useState(false);

  const updateContextMenu = (value: boolean) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpen(value);
  };

  if (!group) return null;

  return (
    <>
      <button
        className={clsx(styles.container, isSelected && styles.selected)}
        type="button"
        onClick={onClick}
        onMouseEnter={() => {
          setIsChipHovered(true);
        }}
        onMouseLeave={() => {
          setIsChipHovered(false);
        }}
      >
        <Label className={styles.label} size="md">
          {group.name}
        </Label>
        <div ref={contextMenuButtonRef} className={styles.contentRight}>
          {isChipHovered ? (
            <Button
              component="div"
              className={styles.contextMenuButton}
              onClick={updateContextMenu(true)}
              icon={
                <Icon
                  name="Actions-More"
                  appearance="secondary"
                  size="medium"
                />
              }
              appearance="overlay"
              size="medium"
              mode="plain"
            />
          ) : (
            <Label size="md">{group.creatorCount}</Label>
          )}
        </div>
      </button>
      <ListMenu
        group={group}
        open={menuOpen}
        onClose={updateContextMenu(false)}
        anchorEl={contextMenuButtonRef.current}
      />
    </>
  );
}

export default List;
