import React, {useMemo} from 'react';
import {
  NavLink as NavLinkReactRouterDOM,
  NavLinkProps as NavLinkPropsReactRouterDOM,
} from 'react-router-dom';
import getUrlWithBrandId from '@/utils/getUrlWithBrandId';
import useBrandId from '@/hooks/use-brand-id';

export type NavLinkProps = {
  testID?: string;
  keepBrandIdIfPresent?: boolean;
} & NavLinkPropsReactRouterDOM;

function NavLink(props: NavLinkProps) {
  const {testID = 'nav-link', to, keepBrandIdIfPresent = true, ...rest} = props;
  const brandId = useBrandId();
  const finalizeTo = useMemo(() => {
    return to && keepBrandIdIfPresent ? getUrlWithBrandId(String(to)) : to;
  }, [to, keepBrandIdIfPresent, brandId]);

  return (
    <NavLinkReactRouterDOM {...rest} to={finalizeTo} data-testid={testID} />
  );
}

export default NavLink;
