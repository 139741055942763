import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import {Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {
  CreatorProfileApplicationAnswer,
  ProfileProps,
} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import {MediaFile} from '@/types/mediaFile';
import Conditional from '@/components/conditional';
import ApplicationAnswers from '@/components/creator-profile/components/application-questions';
import CREATOR_PROFILE_TEST_IDS from '@/components/creator-profile/testIds';
import MediaFileViewer from '@/components/media-file-viewer';
import SocialNetworkIdentitySummary from '@/components/social-network-identity-summary';
import ToggleableTags from '@/components/toggleable-tags';
import ToggleableText from '@/components/toggleable-text';
import useFilteredTiktokSocialNetworks from '@/hooks/use-filtered-tiktok-social-networks';
import useSortedSocialNetworks from '@/hooks/use-sorted-social-networks';
import {profileActions, profileGetters} from '@/stores/profileStore';
import {CreatorProfileTabContentProps} from '../../CreatorProfileTabContentProps';
import TabWrapper from '../tab-wrapper';
import styles from './OverviewTab.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.tabs.overview';
const MAX_BIO_LENGTH = 150;
const TAGS_LIMIT = 8;

function AboutMe({
  profile,
  trackButtonPressed,
}: {
  profile: ProfileProps;
  trackButtonPressed: (buttonName: string, overrideParams?: object) => void;
}) {
  const profileSettings = profileGetters.getProfileSettings(profile.id);
  const {bio} = profile;

  const tagsAndContentPreferences = useMemo(() => {
    return [
      ...profile.tags.map((tag) => (typeof tag === 'string' ? tag : tag.name)),
      ...profile.contentPreferences.map(
        (contentPreference) => contentPreference.id
      ),
    ];
  }, [profile.tags, profile.contentPreferences]);

  return (
    <div className={styles.aboutMeSection}>
      <Headline size="sm">
        {translate(`${TRANSLATION_PREFIX}.about-me`)}
      </Headline>
      <ToggleableTags
        testID={
          CREATOR_PROFILE_TEST_IDS.TAB__OVERVIEW__TAGS_AND_CONTENT_PREFERENCES
        }
        tags={tagsAndContentPreferences}
        initialShowAllTags={profileSettings.showAllTags}
        onShowAllTagsChange={(showAllTags) => {
          profileActions.setShowAllTags(profile.id, showAllTags);
          trackButtonPressed('Show all tags and content preferences');
        }}
        tagsLimit={TAGS_LIMIT}
      />
      <ToggleableText
        testID={CREATOR_PROFILE_TEST_IDS.TAB__OVERVIEW__BIO}
        text={bio}
        initialShowFullText={profileSettings.showMoreBio}
        onShowFullTextChange={(showMoreBio) => {
          profileActions.setShowMoreBio(profile.id, showMoreBio);
          trackButtonPressed(
            showMoreBio
              ? translate(`${TRANSLATION_PREFIX}.show-more`)
              : translate(`${TRANSLATION_PREFIX}.show-less`)
          );
        }}
        maxTextLength={MAX_BIO_LENGTH}
        showLessLabel={translate(`${TRANSLATION_PREFIX}.show-less`)}
        showMoreLabel={translate(translate(`${TRANSLATION_PREFIX}.show-more`))}
      />
    </div>
  );
}

function ProfileIdentitiesSummary({
  profile,
  trackButtonPressed,
}: {
  profile: ProfileProps;
  trackButtonPressed: (buttonName: string, overrideParams?: object) => void;
}) {
  const profileSettings = profileGetters.getProfileSettings(profile.id);

  const socialNetworkIdentities = profile.identities || [];

  const sortedSocialNetworks = useSortedSocialNetworks(
    socialNetworkIdentities
  ) as Identity[];
  const filteredSocialNetworks =
    useFilteredTiktokSocialNetworks(sortedSocialNetworks);

  const onIdentityToggle = (identity: Identity, isOpen: boolean) => {
    trackButtonPressed(
      `Social Network Summary: ${identity.provider} => ${
        isOpen ? 'Show More' : 'Show Less'
      }`
    );
  };

  return filteredSocialNetworks.map((identity) => (
    <SocialNetworkIdentitySummary
      key={identity.id}
      identity={identity}
      initialIsOpen={profileSettings.openSocialNetworks[identity.provider]}
      onOpenChange={(isOpen) => {
        profileActions.setOpenSocialNetwork(
          profile.id,
          identity.provider,
          isOpen
        );
        onIdentityToggle(identity, isOpen);
      }}
      onOpenUrl={() => {
        trackButtonPressed(
          `SocialNetworkSummary => Open ${identity.provider} profile`,
          {
            flow_id: identity.id,
            flow_name: identity.profilePageUrl,
          }
        );
      }}
    />
  ));
}

function Portfolio({
  profile,
  onPortfolioItemClick,
}: {
  profile: ProfileProps;
  onPortfolioItemClick: (mediaItem: MediaFile) => void;
}) {
  const {portfolioMediaItems} = profile;
  const sortedPortfolioMediaItems = useMemo(
    () => sortBy(portfolioMediaItems, 'weight'),
    [portfolioMediaItems]
  );

  return (
    <div className={styles.portfolioSection}>
      <Headline size="sm">
        {translate(`${TRANSLATION_PREFIX}.portfolio`)}
      </Headline>
      <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
        <Masonry columnsCount={3} gutter="10px">
          {sortedPortfolioMediaItems.map((mediaFile) => (
            <MediaFileViewer
              key={mediaFile.id}
              id={mediaFile.id}
              url={mediaFile.url}
              kind={mediaFile.kind}
              thumbnailUrl={mediaFile.thumbnailUrl}
              onClick={onPortfolioItemClick}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

function OverviewTab(props: CreatorProfileTabContentProps) {
  const {profile, creatorProfileAbilities, trackButtonPressed} = props;

  return (
    <Conditional condition={creatorProfileAbilities.canViewPortfolio}>
      <TabWrapper
        testID={CREATOR_PROFILE_TEST_IDS.TAB__OVERVIEW}
        className={styles.overviewTabContainer}
      >
        <AboutMe profile={profile} trackButtonPressed={trackButtonPressed} />
        <Conditional
          condition={
            creatorProfileAbilities.showApplicationAnswers &&
            !!profile.applicationAnswers
          }
        >
          <ApplicationAnswers
            title={translate(`${TRANSLATION_PREFIX}.application-questions`)}
            items={
              profile.applicationAnswers as CreatorProfileApplicationAnswer[]
            }
          />
        </Conditional>
        <ProfileIdentitiesSummary
          profile={profile}
          trackButtonPressed={trackButtonPressed}
        />
        <Portfolio
          profile={profile}
          onPortfolioItemClick={(mediaFile) =>
            trackButtonPressed(`Open portfolio media`, {
              flow_id: mediaFile.kind,
              flow_name: mediaFile.url,
            })
          }
        />
      </TabWrapper>
    </Conditional>
  );
}

export default OverviewTab;
