import React from 'react';
import Marquee from 'react-fast-marquee';
import styles from './LogoMarquee.module.scss';

const SVG_FILES_PREFIX = '/assets/svg/pricing/';
const partnerBrandsLogos = [
  {
    name: 'delta-airlines',
    fileName: 'logo-delta-airlines.svg',
  },
  {
    name: 'frito-lay',
    fileName: 'logo-frito-lay.svg',
  },
  {
    name: 'google',
    fileName: 'logo-google.svg',
  },
  {name: 'hyatt', fileName: 'logo-hyatt.svg'},
  {
    name: 'jose-cuervo',
    fileName: 'logo-jose-cuervo.svg',
  },
  {name: 'lyft', fileName: 'logo-lyft.svg'},
  {
    name: 'method-products',
    fileName: 'logo-method-products.svg',
  },
  {name: 'olly', fileName: 'logo-olly.svg'},
  {
    name: 'postmates',
    fileName: 'logo-postmates.svg',
  },
  {
    name: 'skinny',
    fileName: 'logo-skinny-pop.svg',
  },
];

type LogoMarqueeProps = {
  logoMarqueeContainerClassName?: string;
};
function LogoMarquee(props: LogoMarqueeProps) {
  const {logoMarqueeContainerClassName} = props;

  return (
    <div
      className={`${styles.logoMarqueeContainer} ${logoMarqueeContainerClassName}`}
    >
      <Marquee className={styles.logoMarquee} gradient>
        {partnerBrandsLogos.map((item) => {
          const {name, fileName} = item;
          return (
            <img
              src={SVG_FILES_PREFIX + fileName}
              key={name}
              className={styles.logo}
              alt={name}
            />
          );
        })}
      </Marquee>
    </div>
  );
}

export default LogoMarquee;
