import QueryContext from '../QueryContext';
import api from '../index';

const DEFAULT_AGENCY_INCLUDE = [
  'social_networks',
  'brands.tiktok_business_authorizations',
  'brands.brand_instagram_business_account',
  'organization_platform_payment_manager',
];

const agencyFetchers = {
  agency: (context: QueryContext) =>
    api.get('/domain/agency', {
      params: {
        brand_id: context?.meta?.brandId,
        include: context?.meta?.include || DEFAULT_AGENCY_INCLUDE,
      },
    }),
  campaignSummary: (context: QueryContext) =>
    api.get(`/brands/${context?.meta?.brandId}/campaign_summary`, {}),
  brandCampaigns: (context: QueryContext) =>
    api.get(`/brands/${context?.meta?.brandId}/campaigns`, {}),
  brandStatsFilterableCampaigns: (context: QueryContext) =>
    api.get('/brand_stats/filterable_campaigns', {
      params: {
        brand_id: context?.meta?.brandId,
      },
    }),
  onboardingQuestionnaire: (context: QueryContext) =>
    api.get(
      `/agencies/${context?.meta?.agencyId}/onboarding_questionnaire`,
      {}
    ),
};

export default agencyFetchers;
