import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import Autocomplete from '@/views/creators/components/autocomplete';

interface WeightSelectionDropDownProps {
  containerClassName?: string;
  weights: string[];
  selectedWeightId?: string;
  selectedOptionId?: string;
  onUpdateWeight: (optionId: string, weightId: string) => void;
  placeholder?: string;
  weightDisabled?: boolean;
  minWidth?: number;
  minHeight?: number;
}

function WeightSelectionDropDown(props: WeightSelectionDropDownProps) {
  const {
    containerClassName,
    weights,
    selectedWeightId,
    selectedOptionId,
    weightDisabled,
    placeholder,
    onUpdateWeight,
    minWidth,
    minHeight,
  } = props;

  const mappedOptions = useMemo(
    () =>
      weights.map((weight) => ({
        id: weight,
        label: translate(weight),
      })),
    [weights]
  );

  const mappedSelectedOption = useMemo(
    () =>
      selectedWeightId
        ? {
            id: selectedWeightId,
            label: selectedWeightId,
            value: selectedWeightId,
          }
        : null,
    [selectedWeightId]
  );

  return (
    <div className={containerClassName}>
      <Autocomplete
        multiple={false}
        placeholder={placeholder}
        options={mappedOptions}
        disableCloseOnSelect={false}
        selectedOptions={mappedSelectedOption}
        disabled={weightDisabled}
        minWidth={minWidth}
        minHeight={minHeight}
        inputValue={selectedWeightId || ''}
        filterOptions={(options) => options}
        hideInputCursor
        onChange={(_, value) => {
          // fallback to selectedWeightId so we can clear the selection (toggle)
          if (selectedOptionId && value?.id !== selectedWeightId) {
            onUpdateWeight(selectedOptionId, value?.id || selectedWeightId);
          }
        }}
      />
    </div>
  );
}

export default WeightSelectionDropDown;
