import React from 'react';
import {InputAdornment} from '@mui/material';
import {designSystemToken, Input} from '@lightricks/react-design-system';
import styles from './IconInput.module.scss';

const INPUT_WITH_ADORNMENT_SX = {
  '.MuiInputBase-root:before': {borderBottom: 'unset !important'},
  '.MuiInputBase-root:after': {borderBottom: 'unset !important'},
};

type IconInputProps = {
  testID?: string;
  value: string | number | null | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  icon: React.ReactNode | string;
  disabled?: boolean;
  className?: string;
  type?: 'number' | 'text';
};

function IconInput(props: IconInputProps) {
  const {
    testID = 'icon-input',
    onChange,
    value,
    placeholder,
    icon,
    disabled,
    className,
    type = 'number',
  } = props;
  const inputProps = {
    className: `${styles.input} ${className}`,
    inputProps: {value: value || '', min: 0, placeholder},
    InputProps: {
      startAdornment: (
        <InputAdornment position="start" className={styles.icon}>
          {icon}
        </InputAdornment>
      ),
    },
  };

  return (
    <Input
      testID={testID}
      type={type}
      value={value || ''}
      onInputChange={onChange}
      disabled={disabled}
      sx={{
        ...INPUT_WITH_ADORNMENT_SX,
        '.MuiInputBase-root': {
          marginBottom: 0,
          border: `solid 2px ${
            value
              ? designSystemToken('semantic.bg.neutral')
              : designSystemToken('semantic.bg.neutral-inverse')
          }`,
        },
        '.Mui-focused': {
          '&.MuiInputBase-root': {
            border: `solid 2px ${designSystemToken('semantic.bg.neutral')}`,
          },
          '.MuiInputAdornment-root': {
            borderRight: `solid 2px ${designSystemToken(
              'semantic.bg.neutral'
            )}`,
          },
        },
        '.MuiInputAdornment-root': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginInlineStart: -2,
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          borderRight: `solid 2px ${
            value
              ? designSystemToken('semantic.bg.neutral')
              : designSystemToken('semantic.bg.neutral-inverse')
          }`,
          width: 46,
          minHeight: 42,
          background: designSystemToken('semantic.bg.tertiary'),
        },
      }}
      {...inputProps}
    />
  );
}

export default IconInput;
