// FileUploadSection.tsx
import React, {useState} from 'react';
import translate from '@/utils/translate';
import styles from './ImageUploader.module.scss';

interface ImageUploaderProps {
  setSelectedUploadFile: React.Dispatch<React.SetStateAction<File | null>>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onClick: () => void;
}

function ImageUploader({
  setSelectedUploadFile,
  fileInputRef,
  onClick,
}: ImageUploaderProps) {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e: React.DragEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedUploadFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedUploadFile(e.target.files[0]);
    }
  };

  return (
    <form
      className={`${styles.uploadMechanism} ${
        dragActive ? styles.dragActive : ''
      }`}
      id="file-upload-container"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      data-testid="image-upload--form"
    >
      {dragActive && (
        <div
          className={styles.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          data-testid="image-upload-drop-zone"
        />
      )}
      <button
        aria-label={translate(
          'views.ai-content-generator.components.image-uploader.aria-label'
        )}
        className={styles.uploadButton}
        type="button"
        onClick={() => onClick()}
        data-testid="image-upload--button"
      />
      <input
        type="file"
        accept="image/jpeg, image/png"
        onChange={handleFileChange}
        ref={fileInputRef}
        className={styles.uploadInput}
        id="fileUploadContainer"
        data-testid="image-upload--input"
      />
    </form>
  );
}

export default ImageUploader;
