import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Icon, Label} from '@lightricks/react-design-system';
import getFormattedPastDate from '@/utils/getFormattedPastDate';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';

function LastUpdatedCell({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  return (
    <Label size="sm">
      {row.original.reportUpdatedAt === null ? (
        <Icon name="Features-Duration" size="medium" appearance="disabled" />
      ) : (
        getFormattedPastDate(new Date(row.original.reportUpdatedAt))
      )}
    </Label>
  );
}

export default LastUpdatedCell;
