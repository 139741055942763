import {v4 as uuid} from 'uuid';
import translate from '@/utils/translate';
import {
  CampaignBriefDeliverableItem,
  CampaignPaidGigBriefItem,
  CampaignProps,
} from '@/types/campaign';
import {CaptionGuideline} from '@/views/campaigns/brief/components/campaign-brief/section/deliverables/caption-guidelines/CaptionGuidelines';
import getBriefItemAndIndexByTitle from '../utils/getBriefItemAndIndexByTitle';
import {
  BRIEF_FIELD_TYPE,
  BRIEF_FIELD_TYPES,
  BriefFieldsKeys,
} from './briefFieldTypes';

export type BriefFieldManagerAttributes = {
  fields: BriefFieldsKeys;
};

export type BriefFieldValidator = {
  validator: (
    briefFieldManager: BriefFieldManagerAttributes,
    value: any
  ) => boolean;
  errorMessageLocaleKey: string;
};

export type ErrorMessages = {
  [field: string]: string[];
};

class BriefFieldManager {
  campaignData: CampaignProps;

  fields: BriefFieldsKeys = {};

  constructor(campaignData: CampaignProps) {
    this.campaignData = campaignData;

    BRIEF_FIELD_TYPES.forEach((briefFieldType) => {
      const {key, Class, agentKey} = briefFieldType;

      if (briefFieldType.type === BRIEF_FIELD_TYPE.PRIMITIVE) {
        const {extractValue} = briefFieldType;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = extractValue?.(campaignData) ?? campaignData[key];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.fields[key] = new Class(this, campaignData, key, value);
      } else if (briefFieldType.type === BRIEF_FIELD_TYPE.BRIEF_ITEM) {
        const {titleLocaleKey, shouldGenerateIfMissing} = briefFieldType;
        const title = translate(titleLocaleKey!);
        const {briefItem, briefItemIndex} = getBriefItemAndIndexByTitle(
          campaignData?.campaignPaidGigBriefItems || [],
          title
        );

        if (briefItem || shouldGenerateIfMissing) {
          this.fields[key] = new Class(
            this,
            campaignData,
            key,
            briefItemIndex,
            briefItem?.id || uuid(),
            briefItem?.title || title,
            briefItem?.contentHtml || '',
            briefItem?.highlighted || '',
            briefItem?.clientBriefTemplateId || ''
          );
        }
      }

      if (this.fields[key] && agentKey) {
        this.fields[key].agentKey = agentKey;
      }
    });
  }

  get briefItemsList(): CampaignPaidGigBriefItem[] {
    return BRIEF_FIELD_TYPES.filter(
      ({type}) => type === BRIEF_FIELD_TYPE.BRIEF_ITEM
    )
      .sort(
        (briefFieldTypeA, briefFieldTypeB) =>
          briefFieldTypeA.weight! - briefFieldTypeB.weight!
      )
      .map(({key}) => this.fields[key]?.value)
      .filter(Boolean)
      .map((briefItem: CampaignPaidGigBriefItem, index) => ({
        ...briefItem,
        weight: index + 1,
      }));
  }

  get deliverableItemsList(): CampaignBriefDeliverableItem[] {
    if (this.fields.campaignBriefDeliverableItems?.value) {
      return this.fields.campaignBriefDeliverableItems.value
        .sort(
          (
            deliverableItemA: CampaignBriefDeliverableItem,
            deliverableItemB: CampaignBriefDeliverableItem
          ) => deliverableItemA.weight! - deliverableItemB.weight!
        )
        .map(
          (deliverableItem: CampaignBriefDeliverableItem, index: number) => ({
            id: deliverableItem.id,
            itemType: deliverableItem.itemType,
            description: deliverableItem.description,
            weight: index + 1,
          })
        );
    }
    return [];
  }

  get captionGuidelinesList(): CaptionGuideline[] {
    if (this.fields.campaignBriefCaptionGuidelines?.value) {
      return this.fields.campaignBriefCaptionGuidelines.value.map(
        (captionGuideline: CaptionGuideline, index: number) => ({
          id: captionGuideline.id,
          weight: index + 1,
          kind: captionGuideline.kind,
          content: captionGuideline.content,
        })
      );
    }
    return [];
  }

  get fieldsErrors() {
    if (this.campaignData.isPlaceholder || this.campaignData.isProcessing) {
      return {};
    }

    return BRIEF_FIELD_TYPES.reduce(
      (acc: {[key: string]: ErrorMessages}, briefFieldType) => {
        const {
          key,
          section,
          validators,
        }: {key: string; section: string; validators?: BriefFieldValidator[]} =
          briefFieldType;
        if (!acc[section]) {
          acc[section] = {};
        }

        if (validators) {
          const errors = validators.reduce((validatorsAcc, validator) => {
            if (!validator.validator(this, this.fields[key]?.value)) {
              validatorsAcc.push(translate(validator.errorMessageLocaleKey));
            }
            return validatorsAcc;
          }, [] as string[]);

          if (errors.length > 0) {
            acc[section][key] = errors;
          }
        }
        return acc;
      },
      {}
    );
  }

  async getSerializedData() {
    const campaignPaidGigBriefItems = this.briefItemsList;
    const campaignBriefDeliverableItems = this.deliverableItemsList;
    const campaignBriefCaptionGuidelines = this.captionGuidelinesList;

    return {
      ...Object.keys(this.fields).reduce((acc: any, key) => {
        acc[key] = this.fields[key]?.value;
        return acc;
      }, {}),
      image: this.fields.imageUrl?.value || '',
      campaignPaidGigBriefItems,
      campaignBriefDeliverableItems,
      campaignBriefCaptionGuidelines,
    };
  }
}

export default BriefFieldManager;
