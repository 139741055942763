import React from 'react';
import {Tabs} from '@lightricks/react-design-system';
import {TabData} from '@lightricks/react-design-system/dist/components/tabs/TabsProps';
import {CreatorProfileAbilities} from '@/types/creatorProfile';
import styles from './CreatorProfileTabs.module.scss';

interface CreatorProfileTabProps {
  value: string;
  label: string | React.ReactNode;
  locked?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

interface CreatorProfileTabsProps {
  tabs: CreatorProfileTabProps[];
  activeTabId: string;
  onChange: (tabId: string) => void;
  creatorProfileAbilities: CreatorProfileAbilities;
  isSharedList?: boolean;
  trackButtonPressed: (buttonName: string, overrideParams?: object) => void;
}

function CreatorProfileTabs(props: CreatorProfileTabsProps) {
  const {
    tabs,
    activeTabId,
    onChange,
    creatorProfileAbilities,
    isSharedList,
    trackButtonPressed,
  } = props;

  function openRequestDemoPage(tab: CreatorProfileTabProps) {
    trackButtonPressed(tab.value, {tab: activeTabId});
    window.open('https://popularpays.com/request-demo/', '_blank');
  }

  return (
    <div
      className={`${styles.tabsContainer} ${
        isSharedList ? styles.whiteBackground : ''
      }`}
    >
      <Tabs
        ScrollButtonComponent="div"
        size="large"
        appearance="brand"
        tabsData={tabs as TabData[]}
        value={activeTabId}
        onChange={(_, tabId) => {
          trackButtonPressed(tabId, {tab: activeTabId});
          const tab = tabs.find((_tab) => _tab.value === tabId);
          if (creatorProfileAbilities.displayPublicDataOnly && tab?.locked) {
            openRequestDemoPage(tab);
            return;
          }
          onChange(tabId);
        }}
      />
    </div>
  );
}

export default CreatorProfileTabs;
