import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import styles from './MenuCollapseButton.module.scss';

type MenuCollapseButtonProps = {
  onClickCollapse: () => void;
  isCollapsed: boolean;
};

function MenuCollapseButton(props: MenuCollapseButtonProps) {
  const {onClickCollapse, isCollapsed} = props;

  const iconName = isCollapsed
    ? 'Actions-Arrow-Forward-Small'
    : 'Actions-Arrow-Back-Small';

  return (
    <Button
      onClick={onClickCollapse}
      className={styles.collapseButton}
      appearance="neutral"
      mode="elevated"
      size="small"
      icon={<Icon name={iconName} size="medium" appearance="primary" />}
    />
  );
}

export default MenuCollapseButton;
