import React, {useState, useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Button, Icon, Modal} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import subscriptionUpdaters from '@/api/updaters/subscription';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import styles from './ModalContactSales.module.scss';

export type ModalContactSalesProps = {
  testID?: string;
  state?: 'default' | 'success';
  errorMessage?: string;
};

const TRANSLATION_PREFIX = 'components.modal-contact-sales';

const buttonProps = {
  appearance: 'neutral',
  size: 'large',
  mode: 'filled',
} as const;
const showCloseButton = true;

function ModalContactSales(props: ModalContactSalesProps) {
  const {
    testID = 'modal-contact-sales',
    state = 'default',
    errorMessage = '',
  } = props;
  const [contentState, setContentState] = useState(state);
  const [requestError, setRequestError] = useState(errorMessage);
  const [searchParams, setSearchParams] = useSearchParams();
  const brandId = searchParams.get('brandId') ?? undefined;
  const {subscription} = useSubscriptionQuery({enabled: !!brandId, brandId});

  const open = useMemo(
    () => searchParams.get('showContactSalesModal') === 'true',
    [searchParams]
  );

  const handleClose = useCallback(() => {
    searchParams.delete('showContactSalesModal');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handlePrimaryButtonClick = React.useCallback(async () => {
    try {
      await subscriptionUpdaters.requestCustomUpgrade(subscription.id);
      setContentState('success');
    } catch (error) {
      setRequestError(translate(`${TRANSLATION_PREFIX}.error`));
    }
  }, [subscription.id]);

  const DefaultContent = useCallback(
    () => (
      <>
        <div className={styles.defaultContentText}>
          <h1>{translate(`${TRANSLATION_PREFIX}.h1`)}</h1>
          <h2>{translate(`${TRANSLATION_PREFIX}.h2`)}</h2>
          <p className={styles.error} aria-describedby="error">
            {requestError ?? ''}
          </p>
        </div>
        <div style={{display: 'flex', gap: 8}}>
          <Button {...buttonProps} onClick={handleClose} mode="tinted">
            {translate(`${TRANSLATION_PREFIX}.button-secondary`)}
          </Button>
          <Button {...buttonProps} onClick={handlePrimaryButtonClick}>
            {translate(`${TRANSLATION_PREFIX}.button-primary`)}
          </Button>
        </div>
      </>
    ),
    [handlePrimaryButtonClick, handleClose, requestError]
  );

  const SuccessContent = useCallback(
    () => (
      <>
        <Icon
          name="Actions-Patch-Accept-Line"
          appearance="brand"
          size="large"
        />
        <h1>{translate(`${TRANSLATION_PREFIX}.success`)}</h1>
      </>
    ),
    []
  );

  const states = {default: <DefaultContent />, success: <SuccessContent />};

  return (
    <Modal
      testID={testID}
      className={styles.container}
      {...{open, handleClose, showCloseButton}}
    >
      {states[contentState]}
    </Modal>
  );
}

export default ModalContactSales;
