import {Severity, SEVERITY_THRESHOLDS} from '../constants';

function getScoreFromSeverity(severity: Severity): number {
  switch (severity) {
    case Severity.HIGH:
      return SEVERITY_THRESHOLDS[Severity.HIGH];
    case Severity.MEDIUM:
      return SEVERITY_THRESHOLDS[Severity.MEDIUM];
    case Severity.LOW:
      return SEVERITY_THRESHOLDS[Severity.LOW];
    default:
      return 0;
  }
}

export default getScoreFromSeverity;
