import React from 'react';
import {Route, Navigate, useLocation} from 'react-router-dom';
import {ROUTES} from '@/config/routesConstants';
import Signup from '@/views/auth/signup';

function RedirectWithQuery({to}: {to: string}) {
  const location = useLocation();
  return <Navigate to={`${to}${location.search}`} replace />;
}

function AuthRoutes() {
  return (
    <>
      <Route path={ROUTES.SIGN_UP_REACT} element={<Signup />} />
      <Route
        path={ROUTES.SIGN_UP_REACT_OLD}
        element={<RedirectWithQuery to={ROUTES.SIGN_UP_REACT} />}
      />
    </>
  );
}

export default AuthRoutes;
