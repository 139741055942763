import {SharedListCreatorProfile} from '@/types/creatorProfile';
import creatorProfileFetcher from '@/api/fetchers/creatorProfile';
import useDataQuery from '@/hooks/use-data-query';

type SharedListCreatorsResponse = {
  data?: SharedListCreatorProfile[];
  meta?: {title: string};
};

function useSharedListCreatorsQuery({
  enabled = true,
  queryParams = {},
}: {
  enabled?: boolean;
  queryParams?: {[key: string]: string | number | boolean};
}) {
  return useDataQuery<SharedListCreatorsResponse>({
    queryKey: ['sharedListCreators', queryParams],
    queryFn: creatorProfileFetcher.sharedListCreators,
    enabled,
    refetchOnWindowFocus: false,
    meta: {
      ...queryParams,
    },
  }) as SharedListCreatorsResponse;
}

export default useSharedListCreatorsQuery;
