import {snakeCase} from 'lodash';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {Box, ClickAwayListener, Fade, Popper} from '@mui/material';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {EVENT_NAMES, FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import BriefFieldHelper from '@/views/campaigns/brief/components/brief-field-helper';
import {SaveCampaign} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import {
  END_WRITE_BRIEF_MESSAGE,
  RawMessageProps,
} from '@/views/campaigns/brief/hooks/use-brief-ai-agent-messages/useBriefAiAgentMessages';
import {
  CampaignBriefHelperActions,
  getCampaignBriefHelperWebsocketReady,
  getCampaignBriefHelperFields,
  getCampaignBriefHelperWebsocketControls,
  useCampaignBriefHelperStore,
  getCampaignBriefHelperWebsocketClosed,
} from '@/contexts/CampaignBriefHelperContext';

type ChildrenProps = {
  handleOnFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onMouseDown?: (
    event: React.MouseEvent<HTMLDivElement | HTMLTextAreaElement>
  ) => void;
  isFocused?: boolean;
  readOnly?: boolean;
};

type BriefFieldHelperPopperProps = {
  fieldName: string;
  fieldValue: string;
  children: (childrenProps: ChildrenProps) => React.ReactNode;
  disabled?: boolean;
  onChange?: (value: string) => void;
  saveCampaign?: SaveCampaign;
  offsetX?: number;
  offsetY?: number;
  campaignId: string;
  viewPresentationId?: string;
};

type ProcessIdsMap = Record<string, string>;

function BriefFieldHelperPopper(props: BriefFieldHelperPopperProps) {
  const {
    fieldName,
    fieldValue,
    children,
    disabled = false,
    onChange,
    saveCampaign,
    offsetX = 25,
    offsetY = 15,
    campaignId,
    viewPresentationId,
  } = props;
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorElement);
  const [delayedSubmit, setDelayedSubmit] = useState<any>(null);
  const [processIdsByRequestId, setProcessIdsByRequestId] =
    useState<ProcessIdsMap>({});

  const removeProcessIdByInteractionId = (requestId: string) => {
    setProcessIdsByRequestId((prev) => {
      const {[requestId]: _, ...rest} = prev;
      return rest;
    });
  };

  const {
    state: campaignBriefHelperState,
    dispatch: campaignBriefHelperDispatch,
  } = useCampaignBriefHelperStore();

  const fields = getCampaignBriefHelperFields(campaignBriefHelperState);
  const field = fields[fieldName];

  const campaignCreationFlow = AnalyticsService.getOrCreateFlow(
    FLOW_NAMES.CAMPAIGN_CREATION
  );

  const websocketReady = getCampaignBriefHelperWebsocketReady(
    campaignBriefHelperState
  );

  const websocketClosed = getCampaignBriefHelperWebsocketClosed(
    campaignBriefHelperState
  );

  const websocketControls = getCampaignBriefHelperWebsocketControls(
    campaignBriefHelperState
  );

  useEffect(() => {
    if (websocketReady && delayedSubmit) {
      delayedSubmit();
      setDelayedSubmit(null);
    }
  }, [websocketReady, delayedSubmit]);

  const updateField = (fieldData: object) => {
    campaignBriefHelperDispatch({
      type: CampaignBriefHelperActions.SET_FIELD,
      name: fieldName,
      field: {
        ...(fields[fieldName] || {}),
        ...fieldData,
        name: fieldName,
      },
    });
  };

  const clearFieldChangesFromRawMessages = () => {
    websocketControls.setRawHelperMessages({
      ...websocketControls.rawHelperMessages,
      [field?.requestId || '']: undefined,
    });

    updateField({value: '', loading: false, requestId: undefined});
    saveCampaign?.();
  };

  const fieldChanges = useMemo(() => {
    return websocketControls.rawHelperMessages[field?.requestId || ''] || [];
  }, [websocketControls.rawHelperMessages]);

  useEffect(() => {
    if (fieldChanges.length) {
      if (
        fieldChanges.find(
          (fieldChange: RawMessageProps) =>
            fieldChange?.request_id === fields[fieldName]?.requestId &&
            !!fieldChange.error
        )
      ) {
        updateField({loading: false, error: true});

        const requestId = fields[fieldName]!.requestId!;
        AnalyticsService.dispatchEvent(
          EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
          {
            flow_id: campaignCreationFlow.flow_id,
            process_id: processIdsByRequestId[requestId],
            result: 'failed',
            error: 'ai_agent_error',
            process_details: JSON.stringify({
              field_name: fieldName,
              field_value: fieldValue,
            }),
          }
        );
        removeProcessIdByInteractionId(requestId);
        return;
      }
      if (fieldName.startsWith('required_deliverables')) {
        const newValue = fieldChanges.reduce(
          (acc: string, fieldChange: RawMessageProps) => {
            if (fieldChange.brief) {
              return (
                acc +
                (fieldChange.brief.required_deliverables?.[0]?.description ||
                  '')
              );
            }
            return acc;
          },
          ''
        );
        onChange?.(newValue);
      } else {
        const newValue = fieldChanges.reduce(
          (acc: string, fieldChange: RawMessageProps) => {
            if (fieldChange.brief) {
              return acc + fieldChange.brief[fieldName];
            }
            return acc;
          },
          ''
        );
        onChange?.(newValue);
      }
      if (
        fieldChanges.find(
          (fieldChange: RawMessageProps) =>
            fieldChange?.request_id === fields[fieldName]?.requestId &&
            fieldChange.information === END_WRITE_BRIEF_MESSAGE
        )
      ) {
        clearFieldChangesFromRawMessages();

        const requestId = fields[fieldName]!.requestId!;
        AnalyticsService.dispatchEvent(
          EVENT_NAMES.CAMPAIGN_CREATION_PROCESS_ENDED,
          {
            flow_id: campaignCreationFlow.flow_id,
            process_id: processIdsByRequestId[requestId],
            result: 'success',
            error: null,
            process_details: JSON.stringify({
              field_name: fieldName,
              field_value: fieldValue,
            }),
          }
        );
      }
    }
  }, [fieldChanges]);

  const handleOnChange = (value: string) => {
    updateField({value, loading: false});
  };

  const submit = (message: string) => {
    const requestId = `helper.${uuid()}`;
    const interactionId = uuid();
    const processId = uuid();

    setProcessIdsByRequestId((prev) => ({
      ...prev,
      [requestId]: processId,
    }));

    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_VIEW_INTERACTION, {
      flow_id: campaignCreationFlow.flow_id,
      interaction_type: 'submit_help_me',
      interaction_name: 'ai_field_helper_usage',
      campaign_id: campaignId,
      interaction_location: 'edit_brief_form',
      interaction_details: JSON.stringify({
        field_name: snakeCase(fieldName),
        field_value: fieldValue,
        message,
      }),
      interaction_id: interactionId,
      view_presentation_id: viewPresentationId,
    });

    updateField({value: message, loading: true, requestId});

    if (!websocketReady) {
      setDelayedSubmit(
        () => () =>
          websocketControls?.requestFieldChange(
            fieldName,
            message,
            requestId,
            interactionId,
            processId
          )
      );
    } else {
      websocketControls?.requestFieldChange(
        fieldName,
        message,
        requestId,
        interactionId,
        processId
      );
    }
  };

  const openHelperPopper = (
    event:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.MouseEvent<HTMLDivElement | HTMLTextAreaElement>
  ) => {
    setAnchorElement(event.currentTarget);
  };

  const handleOnClickAway = (event: Event | React.SyntheticEvent) => {
    if (
      containerRef.current &&
      containerRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setAnchorElement(null);
  };

  return (
    <>
      <div ref={containerRef}>
        {children(
          disabled || websocketClosed
            ? {}
            : {
                handleOnFocus: openHelperPopper,
                onMouseDown: openHelperPopper,
                isFocused: !!anchorElement,
                readOnly: fields[fieldName]?.loading,
              }
        )}
      </div>
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <Popper
          id="brief-field-helper-popper"
          placement="right-start"
          open={open}
          anchorEl={containerRef.current}
          transition
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ]}
        >
          {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box sx={{transform: `translate(${offsetX}%, ${offsetY}%)`}}>
                <BriefFieldHelper
                  testID={`brief-field-helper--${fieldName}`}
                  value={fields[fieldName]?.value || ''}
                  fieldValue={fieldValue}
                  loading={fields[fieldName]?.loading || false}
                  showError={fields[fieldName]?.error || false}
                  onChange={handleOnChange}
                  submit={submit}
                />
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
}

export default BriefFieldHelperPopper;
