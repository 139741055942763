import {xor} from 'lodash';
import React, {useMemo} from 'react';
import {FilterProps} from '@/views/creators/components/actions-drawer/filters/FilterProps';
import Autocomplete from '@/views/creators/components/autocomplete';
import {Option} from '@/components/select/SelectProps';

interface MultiUserInputWithChipsProps extends FilterProps {
  inputs: string[];
  placeholder?: string;
  onChange: (input: string) => void;
}

function MultiUserInputWithChips(props: MultiUserInputWithChipsProps) {
  const {inputs, placeholder, onChange} = props;
  const [inputValue, setInputValue] = React.useState('');

  const selectedOptions: Option[] = useMemo(
    () =>
      inputs.map((input) => ({
        id: input,
        label: input,
      })),
    [inputs]
  );

  const handleChange = (
    _: React.SyntheticEvent,
    value: (string | Option)[],
    reason: string
  ) => {
    if (reason !== 'removeOption') {
      setInputValue('');
    }
    const valueInputs = value
      .map((val) => (typeof val === 'string' ? val.trim() : val.label))
      .filter((val) => val !== '');
    const diff = xor(inputs, valueInputs);
    const selectedOption = diff?.[0];
    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  const handleInputChange = ({target: {value}}: {target: {value: string}}) => {
    setInputValue(value.replace(/\s/g, ''));
  };

  const clearInputAndHandleChange = (untrimmedInputValue: string) => {
    const value = untrimmedInputValue.trim();
    if (value) {
      setInputValue('');
      onChange(value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'Space') {
      clearInputAndHandleChange(inputValue);
    }
  };

  const handleOnBlur = () => {
    clearInputAndHandleChange(inputValue);
  };

  return (
    <Autocomplete
      clearIcon={false}
      multiple
      placeholder={placeholder}
      options={[]}
      selectedOptions={selectedOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      inputValue={inputValue}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
    />
  );
}

export default MultiUserInputWithChips;
