import capsuleAiApi from '@/api/capsuleApi';
import QueryContext from '../QueryContext';

const capsuleAiFetchers = {
  imageView: (context: QueryContext) =>
    capsuleAiApi.get(`/image/${context?.meta?.id}`),
  user: (context: QueryContext) =>
    capsuleAiApi.get(`/api/users/${context?.meta?.token}`),
  images: (context: any) =>
    capsuleAiApi.get(`/images`, {
      params: {
        project_id: context.meta?.projectId,
        brand_id: context.meta?.brandId,
        dashboard_user_id: context.meta?.dashboardUserId,
        page: context?.pageParam || context.meta?.page,
        per_page: context.meta?.perPage,
      },
    }),
  imagesCount: (context: QueryContext) =>
    capsuleAiApi.get(`/images/count`, {
      params: {
        brand_id: context.meta?.brandId,
        dashboard_user_id: context.meta?.dashboardUserId,
      },
    }),
  transparentImage: (context: QueryContext) =>
    capsuleAiApi.get(`/api/images/transparent/latest`, {
      params: {
        brand_id: context.meta?.brandId,
      },
    }),
  presets: (context: QueryContext) => capsuleAiApi.get(`/api/presets`),
};

export default capsuleAiFetchers;
