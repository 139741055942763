import {ChartOptions, ChartData, Point, ChartDataset} from 'chart.js';
import React from 'react';
import {Line} from 'react-chartjs-2';
import styles from './LineChart.module.scss';

interface LineChartProps {
  datasets: ChartDataset<'line', Point[] | (number | null)[]>[];
  additionalOptions?: ChartOptions<'line'>;
  xTickValues?: {min: number; max: number};
  yTickValues?: {min: number; max: number};
  labels: string[];
  displayTooltip?: boolean;
  displayLegend?: boolean;
  xTitle?: string;
  yTitle?: string;
  getYLabel?: (label: string | number) => string;
}

function LineChart({
  datasets,
  additionalOptions = {},
  xTickValues,
  yTickValues,
  labels,
  displayTooltip,
  displayLegend = false,
  xTitle = '',
  yTitle = '',
  getYLabel,
}: LineChartProps) {
  const data: ChartData<'line'> = {
    labels,
    datasets,
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    scales: {
      x: {
        title: {
          display: true,
          text: xTitle,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
        ...(xTickValues || {}),
      },
      y: {
        title: {
          display: true,
          text: yTitle,
        },
        ticks: {
          callback: (label) => {
            return getYLabel?.(label) || label;
          },
        },
        ...(yTickValues || {}),
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        enabled: displayTooltip,
      },
      legend: {
        display: displayLegend,
        position: 'bottom',
      },
    },
    ...additionalOptions,
  };

  return (
    <div className={styles.lineChartContainer}>
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChart;
