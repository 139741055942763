import {getEnv} from '@/config/environment';

function getCapsuleAiImageUrl(imageUrl: string | undefined): string {
  if (!imageUrl) {
    return '';
  }
  if (imageUrl.startsWith(getEnv().VITE_CAPSULE_AI_SERVER_URL)) {
    return imageUrl;
  }

  return `${getEnv().VITE_CAPSULE_AI_SERVER_URL}/${imageUrl}`;
}

export default getCapsuleAiImageUrl;
