import React from 'react';
import {Stack} from '@mui/material';
import {
  Body,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import styles from './CreatorInfo.module.scss';

type CreatorInfoProps = {
  fullName: string;
  age?: string;
  location?: string;
};

function CreatorInfo({fullName, age, location}: CreatorInfoProps) {
  const subtitle = [age, location].filter(Boolean).join(' | ');
  return (
    <Stack direction="column" className={styles.creatorContentContainer}>
      <Headline size="md" color={designSystemToken('semantic.fg.primary')}>
        {fullName}
      </Headline>
      <Body
        size="sm"
        color={designSystemToken('semantic.fg.primary')}
        className={styles.subtitle}
      >
        {subtitle}
      </Body>
    </Stack>
  );
}

export default CreatorInfo;
