import React from 'react';
import {Stack, styled} from '@mui/material';

const StyledContentContainer = styled(Stack)`
  padding-top: 16px;
  padding-bottom: 16px;
`;
function ContentContainer({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) {
  return <StyledContentContainer>{children}</StyledContentContainer>;
}

export default ContentContainer;
