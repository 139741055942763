import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import {Rating} from '@mui/material';
import {Body, designSystemToken, Label} from '@lightricks/react-design-system';
import {ProfileProps} from '@/types/creatorProfile';
import styles from './CreatorProfileRating.module.scss';

function getStyle(rating: number) {
  const nthChild = Math.max(1, Number(Math.ceil(rating)));

  return {
    '.MuiRating-decimal': {
      [`&:not(:nth-of-type(${nthChild}))`]: {
        display: 'none',
      },
    },
  };
}

function CreatorProfileRating({
  testID = 'profile-rating',
  profile,
}: {
  testID?: string;
  profile: ProfileProps;
}) {
  const {rating, ratingsCount} = profile;
  const ratingValue = rating / 5;
  return (
    <div className={styles.creatorProfileRatingContainer} data-testid={testID}>
      <Rating
        className={styles.rating}
        value={ratingValue}
        readOnly
        size="small"
        precision={0.1}
        emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit" />}
        sx={getStyle(ratingValue)}
      />
      <Label
        data-testid={`${testID}--rating`}
        size="md"
        color={designSystemToken('semantic.fg.primary')}
      >
        {rating || 0}
      </Label>
      <Body
        size="md"
        color={designSystemToken('semantic.fg.inverse-secondary')}
      >
        ({ratingsCount})
      </Body>
    </div>
  );
}

export default CreatorProfileRating;
