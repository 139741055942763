import React from 'react';
import translate from '@/utils/translate';
import AnchorText from '@/views/ai-content-generator/components/anchor-text';
import styles from './UploadImagePolicy.module.scss';

const IMAGE_UPLOADER_TRANSLATION_PREFIX =
  'views.ai-content-generator.components.image-uploader';

const LIGHTRICKS_LEGAL_TERMS_URL = {
  privacyTerms: {
    text: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.lightricks-legal-terms.policy`,
    url: 'https://static.lightricks.com/legal/privacy-policy.pdf',
  },
  platformTerms: {
    text: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.lightricks-legal-terms.platform`,
    url: 'https://static.lightricks.com/legal/popular-pays-platform-terms-and-conditions.pdf',
  },
  aiTerms: {
    text: `${IMAGE_UPLOADER_TRANSLATION_PREFIX}.lightricks-legal-terms.ai`,
    url: 'https://static.lightricks.com/legal/ai-terms-of-use.pdf#page18',
  },
};
function UploadImagePolicy() {
  return (
    <p className={styles.uploadImagePolicy}>
      By uploading your image, you acknowledge and accept Lightricks’{' '}
      <AnchorText
        text={translate(LIGHTRICKS_LEGAL_TERMS_URL.privacyTerms.text)}
        href={LIGHTRICKS_LEGAL_TERMS_URL.privacyTerms.url}
        className={styles.uploadImagePolicyEmphasis}
      />
      , the{' '}
      <AnchorText
        text={translate(LIGHTRICKS_LEGAL_TERMS_URL.platformTerms.text)}
        href={LIGHTRICKS_LEGAL_TERMS_URL.platformTerms.url}
        className={styles.uploadImagePolicyEmphasis}
      />{' '}
      and the{' '}
      <AnchorText
        text={translate(LIGHTRICKS_LEGAL_TERMS_URL.aiTerms.text)}
        href={LIGHTRICKS_LEGAL_TERMS_URL.aiTerms.url}
        className={styles.uploadImagePolicyEmphasis}
      />
    </p>
  );
}

export default UploadImagePolicy;
