import {MRT_Row} from 'material-react-table';
import React from 'react';
import {useShallow} from 'zustand/react/shallow';
import {ROUTES} from '@/config/routesConstants';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import UsernameCell from '@/views/creators/components/creators-table/UsernameCell';
import Link from '@/components/link';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

function CreatorNameCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  const {id} = row.original;
  const brandId = useBrandId();
  const isSelected = useListCreatorsStore(
    useShallow((state) => state.selectedCreators.has(id))
  );

  const imageUrl = row.original.profile?.imageUrl ?? '';
  const displayName =
    row.original.profile?.displayName ??
    row.original.email ??
    'Deactivated creator';

  const onCheckboxChange = React.useCallback(() => {
    listCreatorsActions.toggleCreator(id, row.original);
  }, [id, row.original]);

  return (
    <Link
      to={`${ROUTES.CREATOR_PROFILE}/${row.original.profile?.id}?brandId=${brandId}`}
      target="_blank"
    >
      <UsernameCell
        showProfilePopup
        name={displayName}
        picture={imageUrl}
        isSelected={isSelected}
        onCheckboxChange={onCheckboxChange}
        profile={row.original.profile}
        email={row.original?.email}
      />
    </Link>
  );
}

const CreatorNameCellMemo = React.memo(CreatorNameCell);

export default CreatorNameCellMemo;
