import getPrioritizedValidBrandId from '@/utils/getPrioritizedValidBrandId';
import getUrlParams from '@/utils/getUrlParams';

function getUpdatedUrl(destinationUrl: string, brandId?: string | null) {
  const [url, search] = destinationUrl.split('?');
  const urlParams = search ? getUrlParams(search) : {};

  if (brandId) {
    urlParams.brandId = brandId;
  } else {
    delete urlParams.brandId;
  }
  const searchParams = Object.entries(urlParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
    .join('&');

  if (!searchParams.length) {
    return url;
  }
  return `${url}?${searchParams}`;
}

function removeBrandIdFromUrl(destinationUrl: string) {
  return getUpdatedUrl(destinationUrl);
}

function addBrandIdToUrl(destinationUrl: string, brandId: string) {
  return getUpdatedUrl(destinationUrl, brandId);
}

function getUrlWithBrandId(destinationUrl: string) {
  const search = destinationUrl.split('?')[1];

  const {brandId: brandIdFromDestinationUrl} = search
    ? getUrlParams(search)
    : {brandId: null};
  const {brandId: brandIdFromUrlParams} = getUrlParams();

  if (!brandIdFromUrlParams && !brandIdFromDestinationUrl) {
    return destinationUrl;
  }

  const prioritizedBrandId = getPrioritizedValidBrandId(
    brandIdFromDestinationUrl,
    brandIdFromUrlParams
  );

  if (!prioritizedBrandId) {
    return removeBrandIdFromUrl(destinationUrl);
  }

  return addBrandIdToUrl(destinationUrl, prioritizedBrandId);
}

export default getUrlWithBrandId;
