import DOMPurify from 'dompurify';
import React from 'react';
import translate from '@/utils/translate';
import {CampaignPaidGigBriefItem} from '@/types/campaign';
import BriefSubSection from '@/views/campaigns/brief/components/campaign-brief/components/brief-sub-section';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {SaveCampaign} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import Textarea from '@/components/textarea';
import styles from './BriefItem.module.scss';

const TRANSLATION_PREFIX = 'views.campaigns.components.brief-item';

type BriefItemProps = {
  briefItem: CampaignPaidGigBriefItem;
  onChange?: (value: string) => void;
  editable?: boolean;
  optional?: boolean;
  saveCampaign?: SaveCampaign;
  errors?: string[];
  testID?: string;
  placeholder?: string;
  handleOnFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isFocused?: boolean;
  readOnly?: boolean;
  campaignId: string;
  fieldName?: string;
};

function BriefItem(props: BriefItemProps) {
  const {
    briefItem,
    onChange,
    editable,
    optional,
    saveCampaign,
    errors,
    testID = 'brief-item',
    placeholder,
    handleOnFocus,
    isFocused = false,
    readOnly = false,
    campaignId,
    fieldName,
  } = props;
  const {contentHtml, title} = briefItem || {};

  const getBriefItemContent = () => {
    return DOMPurify.sanitize(contentHtml || '');
  };

  const getTitle = () => {
    return title && optional
      ? `${title} (${translate(`${TRANSLATION_PREFIX}.optional`)})`
      : title;
  };

  // eslint-disable-next-line no-underscore-dangle
  const _handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    handleOnFocus?.(event);
  };

  return (
    <BriefSubSection disabled={!editable} title={getTitle()}>
      <div className={styles.container}>
        <ValidatedField errors={errors}>
          <Textarea
            testID={testID}
            className={`${styles.textarea} ${isFocused ? styles.focused : ''} ${
              !editable ? styles.disabled : ''
            } ${errors?.length ? styles.error : ''}`}
            minRows={3}
            value={getBriefItemContent()}
            onChange={
              readOnly ? undefined : ({target: {value}}) => onChange?.(value)
            }
            onFocus={_handleOnFocus}
            onBlur={() => {
              return saveCampaign?.({
                campaignId,
                fieldName,
                fieldValue: getBriefItemContent(),
              });
            }}
            placeholder={placeholder}
            disabled={!editable}
            readOnly={readOnly}
          />
        </ValidatedField>
      </div>
    </BriefSubSection>
  );
}

export default BriefItem;
