import React, {useEffect} from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Button, Headline, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {FiltersGroupId} from '@/types/models/search-creators/filterId';
import searchCreatorsGetters from '@/views/creators/search/utils/searchCreatorsGetters';
import SideDrawer from '@/components/side-drawer';
import useSearchCreatorsStore from '@/stores/search-creators/searchCreatorsStore';
import styles from './ActionsDrawer.module.scss';
import Actions from './actions';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer';

export type ActionsDrawerProps = {
  testID?: string;
  onClose: (label: string) => void;
  onApply: (label: string) => void;
  onClearAll: (label: string) => void;
  onPresentedOrDismissed?: (
    presentedOrDismissed: 'presented' | 'dismissed',
    name: string
  ) => void;
};

function ActionsDrawer(props: ActionsDrawerProps) {
  // TODO: decide whether to pass "isOpen" as a prop or directly get it from the store
  //   also applies to the action variant and filters/columns state (as a prop or directly get it in here)
  const {
    testID = 'actions-drawer',
    onClose,
    onApply,
    onClearAll,
    onPresentedOrDismissed,
  } = props;
  const [openedFiltersGroup, filtersGroups] = useSearchCreatorsStore(
    useShallow((state) => [
      state.content.filtersState.openedFiltersGroup,
      state.content.filtersState.filtersGroups,
    ])
  );
  const isOpen = !!openedFiltersGroup;

  useEffect(() => {
    if (isOpen) {
      onPresentedOrDismissed?.('presented', openedFiltersGroup);
    }

    return () => {
      if (isOpen) {
        onPresentedOrDismissed?.('dismissed', openedFiltersGroup);
      }
    };
  }, [isOpen]);

  const getActiveAction = () => {
    const filtersGroupId = openedFiltersGroup;
    const filterGroup = filtersGroups[filtersGroupId as FiltersGroupId];

    if (filterGroup) {
      return {
        title: translate(filterGroup.localeLabelKey),
        filtersGroupId,
      };
    }

    return undefined;
  };

  const activeAction = getActiveAction();

  const handleClose = () => {
    onClose('Close');
  };

  const handleClearAll = () => {
    onClearAll(translate(`${TRANSLATION_PREFIX}.button-clear-all`));
  };

  const handleApply = () => {
    onApply(translate(`${TRANSLATION_PREFIX}.button-apply`));
  };

  const renderHeader = () => {
    if (activeAction) {
      return (
        <div className={styles.header}>
          <Headline size="md">{activeAction.title}</Headline>
          <Button
            className={styles.closeButton}
            size="small"
            appearance="neutral"
            mode="plain"
            onClick={handleClose}
          >
            <Icon
              size="small"
              appearance="neutral"
              name="Actions-Close-Normal"
            />
          </Button>
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (activeAction) {
      return (
        <div className={styles.content}>
          <Actions
            filtersGroupId={activeAction.filtersGroupId as FiltersGroupId}
          />
        </div>
      );
    }
    return null;
  };

  const renderFooter = () => {
    const appliedFiltersCount =
      (activeAction &&
        searchCreatorsGetters.getFiltersGroupActiveFiltersCount(
          filtersGroups[activeAction.filtersGroupId as FiltersGroupId]
        )) ??
      0;

    return (
      <div className={styles.footer}>
        <div>
          <Button
            size="small"
            appearance="neutral"
            mode="plain"
            disabled={appliedFiltersCount === 0}
            onClick={handleClearAll}
          >
            {translate(`${TRANSLATION_PREFIX}.button-clear-all`)}
          </Button>
        </div>
        <div className={styles.right}>
          <Button
            size="medium"
            appearance="neutral"
            mode="filled"
            onClick={handleApply}
          >
            {translate(`${TRANSLATION_PREFIX}.button-apply`)}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <SideDrawer
      testID={testID}
      withoutBackdrop
      isOpen={isOpen}
      onClose={() => onClose('')}
      drawerClassName={styles.actionsDrawer}
    >
      {activeAction ? (
        <div className={styles.actionsDrawerContainer}>
          {renderHeader()}
          {renderContent()}
          {renderFooter()}
        </div>
      ) : null}
    </SideDrawer>
  );
}

export default ActionsDrawer;
