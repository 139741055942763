const CREATOR_PROFILE_TEST_IDS = {
  MAIN: 'creator-profile',
  HEADER__DISPLAY_NAME: 'header__display-name',
  HEADER__COLLABORATE_BUTTON: 'header__collaborate-button',
  HEADER__ADD_TO_LIST_BUTTON: 'header__add-to-list-button',
  HEADER__PROFILE_INFO: 'header__profile-info',
  HEADER__INVITE_TO_CAMPAIGN_BUTTON: 'header__invite-to-campaign-button',
  HEADER__RATING: 'header__rating',
  HEADER__COMPLETED_GIGS: 'header__completed_gigs',
  HEADER__LAST_ACTIVE: 'header__last_active',
  HEADER__SOCIAL_NETWORKS: 'header__social-networks',
  HEADER__MEMBERSHIP_LABELS: 'header__membership-labels',
  TAB__OVERVIEW: 'tab__overview',

  TAB__OVERVIEW__TAGS_AND_CONTENT_PREFERENCES: 'profile-tags',
  TAB__OVERVIEW__BIO: 'profile-bio',
  TAB__ANALYTICS_AUDIENCE: 'tab__analytics-audience',

  TAB__ANALYTICS_AUDIENCE__TIKTOK_SOCIAL_HEALTH: 'tiktok-social-health',
  TAB__ANALYTICS_AUDIENCE__IDENTITY_SOCIAL_HEALTH: 'identity-social-health',
  TAB__POSTS: 'tab__posts',

  TAB_PAST_CAMPAIGNS: 'tab__past-campaigns',

  TAB__NOTES: 'tab__notes',

  MAIN__PUBLIC: 'creator-public-profile',
  PUBLIC_PROFILE__UPSELL_BANNER: 'public-profile__upsell-banner',

  MAIN__MODAL: 'creator-profile-modal',

  MAIN__SHARED_LIST: 'creator-profile-shared-list',
};

export default CREATOR_PROFILE_TEST_IDS;
