import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Mark} from '@mui/base';
import {Stack} from '@mui/material';
import {designSystemToken, Input, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {RangeStep} from '@/types/models/search-creators/filter';
import DiscreteSlider from '@/components/discrete-slider/DiscreteSlider';
import styles from './RangeSelectionSliderAndInput.module.scss';

const TRANSLATION_PREFIX = 'views.creators.components.actions-drawer.filters';

type RangeSelectionSliderAndInputProps = {
  selectedRange: [number | undefined, number | undefined];
  onChange: (range: [number | undefined, number | undefined]) => void;
  steps: RangeStep[];
  noInputs?: boolean;
};

type InnerState = {
  sliderIndexes: [number, number];
  inputValues: [number | undefined, number | undefined];
};

function RangeSelectionSliderAndInput({
  selectedRange,
  onChange,
  steps,
  noInputs = false,
}: RangeSelectionSliderAndInputProps) {
  const [minIndex, maxIndex] = useMemo(() => [0, steps.length - 1], [steps]);
  const valuesToIndexes: Record<number, number> = useMemo(
    () => mapValuesToIndexes(steps),
    [steps]
  );

  const mapSelectedRangeToSliderIndexes = useCallback(() => {
    const [selectedRangeMin, selectedRangeMax] = selectedRange;
    let sliderMinIndex = minIndex;
    let sliderMaxIndex = maxIndex;
    if (selectedRangeMin) {
      sliderMinIndex =
        valuesToIndexes[selectedRangeMin] ||
        valuesToIndexes[findClosestValue(steps, selectedRangeMin)];
    }
    if (selectedRangeMax) {
      sliderMaxIndex =
        valuesToIndexes[selectedRangeMax] ||
        valuesToIndexes[findClosestValue(steps, selectedRangeMax)];
    }
    return [sliderMinIndex, sliderMaxIndex] as [number, number];
  }, [selectedRange, steps]);

  const [innerState, setInnerState] = useState<InnerState>(() => {
    return {
      sliderIndexes: mapSelectedRangeToSliderIndexes(),
      inputValues: selectedRange,
    };
  });

  useEffect(() => {
    setInnerState({
      sliderIndexes: mapSelectedRangeToSliderIndexes(),
      inputValues: selectedRange,
    });
  }, [selectedRange]);

  const marks: Mark[] = useMemo(() => {
    return mapToMarks(steps);
  }, [steps]);

  const track = useMemo(() => {
    const [inputMin, inputMax] = innerState.inputValues;
    return (inputMin === undefined || inputMin <= steps[minIndex].value) &&
      (inputMax === undefined || inputMax >= steps[maxIndex].value)
      ? false
      : 'normal';
  }, [innerState.inputValues]);

  const handleSliderChange = (newValue: number | number[]) => {
    const [sliderMin, sliderMax] = newValue as [number, number];
    const inputValues: [number | undefined, number | undefined] = [
      sliderMin === minIndex ? undefined : steps[sliderMin].value,
      sliderMax === maxIndex ? undefined : steps[sliderMax].value,
    ];
    setInnerState({sliderIndexes: [sliderMin, sliderMax], inputValues});
  };

  const handleSliderChangeCommitted = (value: number | number[]) => {
    const [sliderMin, sliderMax] = value as [number, number];
    const committedRange: [number | undefined, number | undefined] = [
      sliderMin === minIndex ? undefined : steps[sliderMin].value,
      sliderMax === maxIndex ? undefined : steps[sliderMax].value,
    ];
    onChange(committedRange as [number, number]);
  };

  function isValidFromInputValue(
    inputNumber: number,
    inputMax?: number
  ): boolean {
    if (inputNumber < steps[minIndex].value) {
      return false;
    }
    if (inputMax) {
      if (inputNumber > inputMax) {
        return false;
      }
    } else if (inputNumber > steps[maxIndex].value) {
      return false;
    }
    return true;
  }

  function isValidToInputValue(
    inputNumber: number,
    inputMin?: number
  ): boolean {
    if (inputNumber > steps[maxIndex].value) {
      return false;
    }
    if (inputMin) {
      if (inputNumber < inputMin) {
        return false;
      }
    } else if (inputNumber < steps[minIndex].value) {
      return false;
    }
    return true;
  }

  const handleToInputChange = (text: string) => {
    const [sliderIndexMin] = innerState.sliderIndexes;
    const [inputMin] = innerState.inputValues;
    if (!text) {
      setInnerState({
        sliderIndexes: [sliderIndexMin, maxIndex],
        inputValues: [inputMin, undefined],
      });
      return;
    }
    const inputNumber = Number(text);
    if (isValidToInputValue(inputNumber, inputMin)) {
      const toSliderValue =
        valuesToIndexes[findClosestValue(steps, inputNumber)];
      setInnerState({
        sliderIndexes: [sliderIndexMin, toSliderValue],
        inputValues: [inputMin, inputNumber],
      });
      onChange([inputMin, inputNumber]);
    } else {
      setInnerState({
        ...innerState,
        inputValues: [inputMin, inputNumber],
      });
    }
  };

  const handleFromInputChange = (text: string) => {
    const [, sliderIndexMax] = innerState.sliderIndexes;
    const [, inputMax] = innerState.inputValues;
    if (!text) {
      setInnerState({
        sliderIndexes: [minIndex, sliderIndexMax],
        inputValues: [undefined, inputMax],
      });
      return;
    }
    const inputNumber = Number(text);
    if (isValidFromInputValue(inputNumber, inputMax)) {
      const fromSliderValue =
        valuesToIndexes[findClosestValue(steps, inputNumber)];

      setInnerState({
        sliderIndexes: [fromSliderValue, sliderIndexMax],
        inputValues: [inputNumber, inputMax],
      });
      onChange([inputNumber, inputMax]);
    } else {
      setInnerState({
        ...innerState,
        inputValues: [inputNumber, inputMax],
      });
    }
  };

  const handleToInputBlur = (text: string) => {
    const [sliderIndexMin] = innerState.sliderIndexes;
    const [inputMin] = innerState.inputValues;
    if (!text) {
      setInnerState({
        sliderIndexes: [sliderIndexMin, maxIndex],
        inputValues: [inputMin, undefined],
      });
      return;
    }
    const inputNumber = Number(text);
    let toInputValue: number | undefined;
    let toSliderValue: number;
    if (!isValidToInputValue(inputNumber, inputMin)) {
      if (inputNumber >= steps[maxIndex].value) {
        toInputValue = undefined;
        toSliderValue = maxIndex;
      } else if (inputMin && inputNumber < inputMin) {
        toInputValue = inputMin;
        toSliderValue = sliderIndexMin;
      } else {
        toInputValue = steps[minIndex].value;
        toSliderValue = minIndex;
      }
      setInnerState({
        sliderIndexes: [sliderIndexMin, toSliderValue],
        inputValues: [inputMin, toInputValue],
      });
      onChange([inputMin, toInputValue]);
    }
  };

  const handleFromInputBlur = (text: string) => {
    const [, sliderIndexMax] = innerState.sliderIndexes;
    const [, inputMax] = innerState.inputValues;
    if (!text) {
      setInnerState({
        sliderIndexes: [minIndex, sliderIndexMax],
        inputValues: [undefined, inputMax],
      });
      return;
    }
    const inputNumber = Number(text);
    let fromInputValue: number | undefined;
    let fromSliderValue: number;
    if (!isValidFromInputValue(inputNumber, inputMax)) {
      if (inputNumber <= steps[minIndex].value) {
        fromInputValue = undefined;
        fromSliderValue = minIndex;
      } else if (inputMax && inputNumber > inputMax) {
        fromInputValue = inputMax;
        fromSliderValue = sliderIndexMax;
      } else {
        fromInputValue = steps[maxIndex].value;
        fromSliderValue = maxIndex;
      }
      setInnerState({
        sliderIndexes: [fromSliderValue, sliderIndexMax],
        inputValues: [fromInputValue, inputMax],
      });
      onChange([fromInputValue, inputMax]);
    }
  };

  return (
    <Stack className={styles.container} direction="column">
      <DiscreteSlider
        className={styles.slider}
        track={track}
        value={innerState.sliderIndexes as number[]}
        marks={marks}
        onChange={(_, value) => handleSliderChange(value)}
        onChangeCommitted={(_, value) => handleSliderChangeCommitted(value)}
        scale={(value: number) => steps[value].value}
        getAriaValueText={(value) => `${steps[value]}`}
        min={minIndex}
        max={maxIndex}
        disabled={false}
      />
      {noInputs ? null : (
        <Stack direction="row" spacing="16px">
          <Input
            className={styles.input}
            type="number"
            label={translate(`${TRANSLATION_PREFIX}.range-input-min`)}
            inputProps={{
              value: innerState.inputValues[0] || '',
              onBlur: ({target: {value}}: any) => handleFromInputBlur(value),
            }}
            placeholder={steps[minIndex].value.toLocaleString()}
            onInputChange={handleFromInputChange}
          />
          <Input
            className={styles.input}
            type="number"
            label={translate(`${TRANSLATION_PREFIX}.range-input-max`)}
            inputProps={{
              value: innerState.inputValues[1] || '',
              onBlur: ({target: {value}}: any) => handleToInputBlur(value),
            }}
            placeholder={`${steps[
              marks[marks.length - 1].value
            ].value.toLocaleString()}+`}
            onInputChange={handleToInputChange}
          />
        </Stack>
      )}
    </Stack>
  );
}

function mapValuesToIndexes(steps: RangeStep[]): Record<number, number> {
  return steps.reduce(
    (acc: Record<number, number>, step: RangeStep, index: number) => {
      acc[step.value] = index;
      return acc;
    },
    {}
  );
}

function mapToMarks(steps: RangeStep[]): Mark[] {
  const results: Mark[] = [];
  steps.forEach((step, index) => {
    if (step.markLocaleLabelKey) {
      results.push({
        value: index,
        label: (
          <Label size="xs" color={designSystemToken('semantic.fg.tertiary')}>
            {translate(step.markLocaleLabelKey)}
          </Label>
        ),
      });
    }
  });
  return results;
}

/**
 * Find the closest target value from a given array of ranges
 * |---100---200---1,000---1,500---3,000---|
 * steps: [{value:100}, {value:200}, {value:1_000}, {value:1_500}, {value:3_000}]
 *
 * target: 1,250, return value: 1_000
 * target: 1_251, return value: 1_500
 */
function findClosestValue(steps: RangeStep[], target: number): number {
  let left = 0;
  let right = steps.length - 1;

  while (right - left > 1) {
    const mid = Math.floor((left + right) / 2);
    if (steps[mid].value < target) {
      left = mid;
    } else {
      right = mid;
    }
  }

  if (target - steps[left].value <= steps[right].value - target) {
    return steps[left].value;
  }
  return steps[right].value;
}

export default RangeSelectionSliderAndInput;
