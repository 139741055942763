import React from 'react';
import translate from '@/utils/translate';
import styles from './UnderMaintenance.module.scss';

function UnderMaintenance() {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img src="/assets/svg/logo-p-green.svg" alt="" />
        </div>

        <div className={styles.title}>
          {translate('views.under-maintenance.title')}
        </div>

        <p className={styles.body}>
          {translate('views.under-maintenance.body')}
        </p>
      </div>
    </div>
  );
}

export default UnderMaintenance;
