import {useMemo} from 'react';
import {getTiktokNetworksPriority} from '@/utils/utils';

function useFilteredTiktokSocialNetworks<T extends {provider: string}>(
  socialNetworks: T[]
) {
  return useMemo(() => {
    const tiktokVersionsPriority = getTiktokNetworksPriority();
    const selectedTiktokAccount = socialNetworks
      .slice()
      .filter((network) => network.provider.includes('tiktok'))
      .sort((a, b) => {
        return (
          tiktokVersionsPriority.indexOf(a.provider) -
          tiktokVersionsPriority.indexOf(b.provider)
        );
      })
      .at(0);
    return socialNetworks.filter(
      (socialNetwork) =>
        !tiktokVersionsPriority.includes(socialNetwork.provider) ||
        socialNetwork.provider === selectedTiktokAccount?.provider
    );
  }, [socialNetworks]);
}

export default useFilteredTiktokSocialNetworks;
