import React, {useRef} from 'react';
import {v4 as uuid} from 'uuid';
import translate from '@/utils/translate';
import stateOptions from '@/config/stateOptions';
import {
  PreferredCreatorAgeDetail,
  PreferredCreatorGender,
  PreferredCreatorState,
} from '@/types/campaign';
import {ErrorMessages} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefFieldInput from '@/views/campaigns/brief/components/brief-field-input';
import BriefItem from '@/views/campaigns/brief/components/campaign-brief/components/brief-item';
import Divider from '@/views/campaigns/brief/components/campaign-brief/components/divider';
import ValidatedField from '@/views/campaigns/brief/components/campaign-brief/components/validated-field';
import {
  SaveCampaign,
  SectionProps,
} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import CAMPAIGN_BRIEF_TEST_IDS from '@/views/campaigns/brief/components/campaign-brief/testIds';
import {
  DEFAULT_AGE_RANGE,
  DEFAULT_SAVE_CAMPAIGN_DELAY,
  IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY,
  IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY,
} from '@/views/campaigns/brief/constants';
import Select from '@/components/select';
import {SELECT_ALL_ID} from '@/components/select/multiple-select/MultipleSelect';
import TagSelector from '@/components/tag-selector';
import BriefFieldHelperPopper from '../../../brief-field-helper-popper';
import styles from './CreatorDetails.module.scss';

type GenderOption = {
  id: string;
  name: string;
};

const TRANSLATION_PREFIX = 'components.campaign-brief.sections.creator-details';

const FOLLOWER_COUNT_OPTIONS = [
  {value: '1000-20000', label: '1K - 20K'},
  {value: '20000-150000', label: '20K - 150K'},
  {value: '150000-350000', label: '150K - 350K'},
  {value: '350000', label: '350K+'},
];

const GENDER_OPTIONS = [
  {value: 'Male', labelLocaleKey: `genders.male`},
  {value: 'Female', labelLocaleKey: `genders.female`},
  {value: 'Other', labelLocaleKey: `genders.other`},
];

const numberFormatter = Intl.NumberFormat('en', {notation: 'compact'});

function EditableCreatorDetail({
  testID = 'editable-creator-detail',
  title,
  editComponent,
  disabled,
}: {
  testID?: string;
  title?: string;
  editComponent: React.ReactNode;
  disabled: boolean;
}) {
  const getTitle = () => {
    return (
      <label className={`${styles.title} ${disabled ? styles.disabled : ''}`}>
        {title}
      </label>
    );
  };

  return (
    <div className={styles.editableCreatorDetail}>
      <div className={styles.editableCreatorDetailContent}>
        {getTitle()}
        {editComponent}
      </div>
    </div>
  );
}

function Demographics({
  editable,
  network,
  updateBriefForm,
  unlocksAt,
  locksAbove,
  preferredCreatorGenders,
  preferredCreatorAgeDetail,
  preferredCreatorStates,
  saveCampaign,
  errors,
  sectionsToHide,
  campaignId,
  preferredCreatorGendersKey,
  preferredCreatorGendersValue,
}: {
  editable?: boolean;
  network?: string | null;
  updateBriefForm?: (
    path: string,
    value: any,
    briefFieldKey?: string,
    saveCampaignDelay?: number,
    triggerShowFormErrors?: boolean
  ) => void;
  unlocksAt: number | null;
  locksAbove: number | null;
  preferredCreatorGenders?: PreferredCreatorGender[] | null;
  preferredCreatorAgeDetail?: PreferredCreatorAgeDetail | null;
  preferredCreatorStates?: PreferredCreatorState[] | null;
  saveCampaign?: SaveCampaign;
  errors?: ErrorMessages;
  sectionsToHide?: Record<string, boolean>;
  campaignId: string;
  preferredCreatorGendersKey: string;
  preferredCreatorGendersValue: GenderOption[];
}) {
  const getFollowersCountInput = () => {
    const isDisabled = !editable;

    function getSelectedFollowersCount(): string {
      if (unlocksAt && locksAbove) {
        return `${unlocksAt}-${locksAbove}`;
      }
      if (unlocksAt && !locksAbove) {
        return `${unlocksAt}`;
      }
      return '';
    }
    return (
      <Select
        testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__FOLLOWERS_COUNT_INPUT}
        containerClassName={styles.selectContainer}
        immediateOnChange
        disabled={isDisabled}
        options={FOLLOWER_COUNT_OPTIONS.map((option) => ({
          ...option,
          id: option.value,
        }))}
        variant="onboarding"
        selectedValueIds={[getSelectedFollowersCount()]}
        checkmarkRadioButtonClassName={styles.checkmarkRadioButtonClassName}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(value: string) => {
          let newUnlocksAt;
          let newLocksAbove;
          if (value.includes('-')) {
            const [min, max] = value.split('-');
            newUnlocksAt = min;
            newLocksAbove = max;
          } else {
            newUnlocksAt = value;
            newLocksAbove = null;
          }
          updateBriefForm?.('unlocksAt', newUnlocksAt, 'unlocksAt');
          updateBriefForm?.(
            'locksAbove',
            newLocksAbove,
            'locksAbove',
            IMMEDIATE_FORCE_SAVE_CAMPAIGN_DELAY
          );
        }}
      />
    );
  };

  const getGenderInput = () => {
    const isDisabled = !editable;

    function getSelectedGendersIds(): string[] {
      return (
        preferredCreatorGenders?.map(
          (preferredCreatorGender) => preferredCreatorGender.name || ''
        ) || []
      );
    }

    return (
      <Select
        testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__GENDER_INPUT}
        multiple
        containerClassName={styles.selectContainer}
        disabled={isDisabled}
        immediateOnChange
        withSelectAllOption
        selectAllLabel={translate('genders.all')}
        options={GENDER_OPTIONS.map((option) => ({
          ...option,
          id: option.value,
          label: translate(option.labelLocaleKey),
        }))}
        variant="onboarding"
        renderSelectedLabel={(options) => {
          if (options.find((option) => option.id === SELECT_ALL_ID)) {
            return translate('genders.all');
          }
          if (options.length === 0) {
            return translate(`${TRANSLATION_PREFIX}.select-genders`);
          }
          return options.map((option) => option.label).join(', ');
        }}
        selectedValueIds={getSelectedGendersIds()}
        onClose={() =>
          saveCampaign?.({
            campaignId,
            fieldName: preferredCreatorGendersKey,
            fieldValue: JSON.stringify(preferredCreatorGendersValue),
          })
        }
        checkmarkRadioButtonClassName={styles.checkmarkRadioButtonClassName}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(value: string[]) => {
          let newSelections = value;
          if (value.length === 0) {
            newSelections = GENDER_OPTIONS.map((option) => option.value);
          }
          updateBriefForm?.(
            'preferredCreatorGenders',
            newSelections.map((newSelection) => ({
              id: `temp-${uuid()}`,
              name: newSelection,
            })),
            'preferredCreatorGenders'
          );
        }}
      />
    );
  };

  const getAgeRangeInput = () => {
    const isDisabled = !editable;
    function getErrors(key: string, returnErrorsIfBothValuesAreFilled = false) {
      const bothValuesAreFilled =
        preferredCreatorAgeDetail?.minAge &&
        typeof Number(preferredCreatorAgeDetail?.minAge) === 'number' &&
        preferredCreatorAgeDetail?.maxAge &&
        typeof Number(preferredCreatorAgeDetail?.maxAge) === 'number';
      switch (key) {
        case 'minAge':
          if (!preferredCreatorAgeDetail?.minAge) {
            return errors?.preferredCreatorAgeDetail;
          }
          return bothValuesAreFilled ? errors?.preferredCreatorAgeDetail : [];
        case 'maxAge':
          if (preferredCreatorAgeDetail?.maxAge === null) {
            return [];
          }
          return returnErrorsIfBothValuesAreFilled &&
            Number(preferredCreatorAgeDetail?.minAge) >=
              Number(preferredCreatorAgeDetail?.maxAge)
            ? errors?.preferredCreatorAgeDetail
            : [];
        default:
          return errors?.preferredCreatorAgeDetail;
      }
    }

    function updateBriefFormAndSave(triggeredBy: 'minAge' | 'maxAge') {
      let minAge =
        Number(preferredCreatorAgeDetail?.minAge) || DEFAULT_AGE_RANGE.min;
      let maxAge: null | number =
        Number(preferredCreatorAgeDetail?.maxAge) || DEFAULT_AGE_RANGE.max;

      if (triggeredBy === 'minAge') {
        minAge = Math.min(
          Math.max(minAge, DEFAULT_AGE_RANGE.min),
          DEFAULT_AGE_RANGE.max
        );
        maxAge = minAge > maxAge ? minAge : maxAge;
        if (maxAge >= DEFAULT_AGE_RANGE.max) {
          maxAge = null;
        }
      } else if (triggeredBy === 'maxAge') {
        maxAge =
          maxAge >= DEFAULT_AGE_RANGE.max
            ? null
            : Math.max(maxAge, DEFAULT_AGE_RANGE.min);
        minAge =
          typeof maxAge === 'number' && maxAge < minAge ? maxAge : minAge;
      }

      updateBriefForm?.(
        'preferredCreatorAgeDetail',
        {minAge, maxAge},
        'preferredCreatorAgeDetail',
        IMMEDIATE_SAVE_CAMPAIGN_IF_CHANGED_DELAY
      );
    }

    return (
      <div className={styles.row}>
        <div className={styles.column}>
          <ValidatedField errors={getErrors('minAge')}>
            <BriefFieldInput
              testID={
                CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__AGE_RANGE_INPUT_MIN
              }
              disabled={isDisabled}
              type="number"
              label={translate(`${TRANSLATION_PREFIX}.min-age`)}
              inputProps={{
                value: preferredCreatorAgeDetail?.minAge || '',
                min: DEFAULT_AGE_RANGE.min,
                max: DEFAULT_AGE_RANGE.max,
                onBlur: () => updateBriefFormAndSave('minAge'),
              }}
              value={preferredCreatorAgeDetail?.minAge}
              onInputChange={(value: number | null | string) => {
                updateBriefForm?.(
                  'preferredCreatorAgeDetail',
                  {
                    ...preferredCreatorAgeDetail,
                    minAge: value,
                  },
                  'preferredCreatorAgeDetail',
                  undefined,
                  true
                );
              }}
              hasErrors={!!getErrors('minAge')?.length}
            />
          </ValidatedField>
        </div>
        <div className={styles.column}>
          <ValidatedField errors={getErrors('maxAge')}>
            <BriefFieldInput
              testID={
                CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__AGE_RANGE_INPUT_MAX
              }
              type="number"
              disabled={isDisabled}
              label={translate(`${TRANSLATION_PREFIX}.max-age`)}
              endIcon={
                preferredCreatorAgeDetail?.maxAge === null ? (
                  <label
                    className={`${styles.plus} ${
                      isDisabled ? styles.disabled : ''
                    }`}
                  >
                    +
                  </label>
                ) : undefined
              }
              inputProps={{
                value:
                  preferredCreatorAgeDetail?.maxAge === null
                    ? DEFAULT_AGE_RANGE.max
                    : preferredCreatorAgeDetail?.maxAge,
                min: DEFAULT_AGE_RANGE.min,
                max: DEFAULT_AGE_RANGE.max,
                onBlur: () => updateBriefFormAndSave('maxAge'),
              }}
              value={
                preferredCreatorAgeDetail?.maxAge === null
                  ? DEFAULT_AGE_RANGE.max
                  : preferredCreatorAgeDetail?.maxAge
              }
              onInputChange={(value: number | null | string) => {
                updateBriefForm?.(
                  'preferredCreatorAgeDetail',
                  {
                    ...preferredCreatorAgeDetail,
                    maxAge: value,
                  },
                  'preferredCreatorAgeDetail',
                  undefined,
                  true
                );
              }}
              hasErrors={!!getErrors('maxAge', true)?.length}
            />
          </ValidatedField>
        </div>
      </div>
    );
  };

  const getPreferredStateInput = () => {
    const isDisabled = !editable;

    return (
      <TagSelector
        readOnly={!editable || isDisabled}
        disabled={isDisabled}
        testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__PREFERRED_STATE_INPUT}
        multiple
        hideSelectedOptions
        addChipLabel={translate(`${TRANSLATION_PREFIX}.add-state`)}
        selectedValueIds={
          preferredCreatorStates?.map(
            (state) => (state.id || state.name) as string
          ) || []
        }
        options={stateOptions}
        onChange={(selection) =>
          updateBriefForm?.(
            'preferredCreatorStates',
            stateOptions
              .filter((option: any) => selection.includes(option.id))
              .map((option: any) => ({
                ...option,
                name: option.label,
              })),
            'preferredCreatorStates',
            DEFAULT_SAVE_CAMPAIGN_DELAY
          )
        }
      />
    );
  };

  return (
    <div className={styles.demographicsContainer}>
      <div className={styles.row}>
        {!sectionsToHide?.followersCount ? (
          <EditableCreatorDetail
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__FOLLOWERS_COUNT_INPUT
            }
            title={translate(`${TRANSLATION_PREFIX}.followers-count`)}
            disabled={!editable}
            editComponent={getFollowersCountInput()}
          />
        ) : null}
        <EditableCreatorDetail
          title={translate(`${TRANSLATION_PREFIX}.gender`)}
          disabled={!editable}
          editComponent={getGenderInput()}
        />
      </div>
      <div className={styles.ageRangeContainer}>
        <EditableCreatorDetail
          testID={CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__AGE_RANGE}
          disabled={!editable}
          editComponent={getAgeRangeInput()}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <EditableCreatorDetail
            testID={
              CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__PREFERRED_STATE_INPUT
            }
            title={translate(`${TRANSLATION_PREFIX}.preferred-state`)}
            disabled={!editable}
            editComponent={getPreferredStateInput()}
          />
        </div>
      </div>
    </div>
  );
}

function CreatorDetails(props: SectionProps) {
  const {
    updateBriefForm,
    editable,
    saveCampaign,
    briefFieldManager,
    errors,
    campaign,
    viewPresentationId,
  } = props;

  const onIdealCreatorChangeRef = useRef<any>(null);
  onIdealCreatorChangeRef.current = (value: string) =>
    updateBriefForm?.(
      `campaignPaidGigBriefItems.${briefFieldManager.fields.idealCreatorBriefItem?.index}.contentHtml`,
      value,
      'idealCreatorBriefItem'
    );

  return (
    <div className={styles.container}>
      <Demographics
        editable={editable}
        network={briefFieldManager.fields.network.value}
        updateBriefForm={updateBriefForm}
        unlocksAt={briefFieldManager.fields.unlocksAt.value}
        locksAbove={briefFieldManager.fields.locksAbove.value}
        preferredCreatorGenders={
          briefFieldManager.fields.preferredCreatorGenders.value
        }
        preferredCreatorAgeDetail={
          briefFieldManager.fields.preferredCreatorAgeDetail.value
        }
        preferredCreatorStates={
          briefFieldManager.fields.preferredCreatorStates.value
        }
        saveCampaign={saveCampaign}
        errors={errors}
        sectionsToHide={briefFieldManager.fields.sectionsToHide.value}
        campaignId={campaign.id}
        preferredCreatorGendersKey={
          briefFieldManager.fields.preferredCreatorGenders.key
        }
        preferredCreatorGendersValue={
          briefFieldManager.fields.preferredCreatorGenders.value
        }
      />
      {briefFieldManager.fields.idealCreatorBriefItem ? (
        <>
          <Divider />
          <BriefFieldHelperPopper
            fieldName={briefFieldManager.fields.idealCreatorBriefItem?.agentKey}
            fieldValue={
              briefFieldManager.fields.idealCreatorBriefItem?.value?.contentHtml
            }
            onChange={onIdealCreatorChangeRef.current}
            saveCampaign={saveCampaign}
            disabled={!editable}
            campaignId={campaign.id}
            viewPresentationId={viewPresentationId}
          >
            {(childrenProps) => (
              <BriefItem
                {...childrenProps}
                testID={
                  CAMPAIGN_BRIEF_TEST_IDS.CREATOR_DETAILS__IDEAL_CREATOR_INPUT
                }
                optional
                briefItem={
                  briefFieldManager.fields.idealCreatorBriefItem?.value
                }
                placeholder={translate(
                  `${TRANSLATION_PREFIX}.ideal-creator-placeholder`
                )}
                editable={editable}
                onChange={onIdealCreatorChangeRef.current}
                saveCampaign={saveCampaign}
                campaignId={campaign.id}
                fieldName={
                  briefFieldManager.fields.idealCreatorBriefItem?.agentKey
                }
              />
            )}
          </BriefFieldHelperPopper>
        </>
      ) : null}
    </div>
  );
}

export default CreatorDetails;
