import React, {useEffect, useLayoutEffect, useRef} from 'react';

function useClickOutside(
  callback: (event?: MouseEvent | DocumentEventMap['touchstart']) => void
) {
  const ref = useRef<HTMLDivElement | null>(null);
  const refCallback = useRef(callback);

  useLayoutEffect(() => {
    refCallback.current = callback;
  });

  useEffect(() => {
    const handleClickOutside = (
      event: MouseEvent | DocumentEventMap['touchstart']
    ) => {
      const element = ref.current;
      if (element && !element.contains(event.target as Node)) {
        refCallback.current(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return ref;
}

export default useClickOutside;
