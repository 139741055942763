import React from 'react';
import {Input} from '@lightricks/react-design-system';
import {FilterProps} from '@/views/creators/components/actions-drawer/filters/FilterProps';

interface UserInputProps extends FilterProps {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

function UserInput({value, onChange, placeholder}: UserInputProps) {
  return (
    <Input value={value} onInputChange={onChange} placeholder={placeholder} />
  );
}

export default UserInput;
