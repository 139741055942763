import React, {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  useMemo,
} from 'react';

export enum CampaignBriefHelperActions {
  SET_FIELDS = 'CAMPAIGN_BRIEF_FIELD_HELPER@SET_FIELDS',
  SET_FIELD = 'CAMPAIGN_BRIEF_FIELD_HELPER@SET_FIELD',
  SET_WEBSOCKET_CONTROLS = 'CAMPAIGN_BRIEF_FIELD_HELPER@SET_WEBSOCKET_CONTROLS',
}

export type CampaignBriefHelperField = {
  name: string;
  value?: string;
  requestId?: string;
  loading?: boolean;
  error?: boolean;
};

export type CampaignBriefHelperFields = {
  [key: string]: CampaignBriefHelperField | undefined;
};

interface CampaignBriefHelperStateDefinition {
  fields: CampaignBriefHelperFields;
  websocketReady: boolean;
  websocketClosed: boolean;
  websocketControls: any;
}

const initialState: CampaignBriefHelperStateDefinition = {
  fields: {},
  websocketReady: false,
  websocketClosed: false,
  websocketControls: null,
};

type Action =
  | {
      type: CampaignBriefHelperActions.SET_FIELDS;
      fields: CampaignBriefHelperFields;
    }
  | {
      type: CampaignBriefHelperActions.SET_FIELD;
      name: string;
      field: CampaignBriefHelperField | undefined;
    }
  | {
      type: CampaignBriefHelperActions.SET_WEBSOCKET_CONTROLS;
      websocketReady: boolean;
      websocketClosed: boolean;
      websocketControls: any;
    };

const campaignBriefHelperReducer = (
  state: CampaignBriefHelperStateDefinition,
  action: Action
): CampaignBriefHelperStateDefinition => {
  switch (action.type) {
    case CampaignBriefHelperActions.SET_FIELDS:
      return {
        ...state,
        fields: action.fields,
      };
    case CampaignBriefHelperActions.SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.name]: action.field,
        },
      };
    case CampaignBriefHelperActions.SET_WEBSOCKET_CONTROLS:
      return {
        ...state,
        websocketReady: action.websocketReady,
        websocketClosed: action.websocketClosed,
        websocketControls: action.websocketControls,
      };
    default:
      return state;
  }
};

interface StoreCampaignBriefHelperContextType {
  state: CampaignBriefHelperStateDefinition;
  dispatch: React.Dispatch<Action>;
}

const CampaignBriefHelperContext = createContext<
  StoreCampaignBriefHelperContextType | undefined
>(undefined);

export const useCampaignBriefHelperStore =
  (): StoreCampaignBriefHelperContextType => {
    const context = useContext(CampaignBriefHelperContext);
    if (!context) {
      throw new Error(
        'useCampaignBriefHelperStore must be used within a CampaignBriefHelperProvider'
      );
    }
    return context;
  };

export const getCampaignBriefHelperFields = (
  state: CampaignBriefHelperStateDefinition
) => state.fields;

export const getCampaignBriefHelperWebsocketReady = (
  state: CampaignBriefHelperStateDefinition
) => state.websocketReady;

export const getCampaignBriefHelperWebsocketClosed = (
  state: CampaignBriefHelperStateDefinition
) => state.websocketClosed;

export const getCampaignBriefHelperWebsocketControls = (
  state: CampaignBriefHelperStateDefinition
) => state.websocketControls;

interface StoreProviderProps {
  children: ReactNode;
}

export function CampaignBriefHelperProvider({children}: StoreProviderProps) {
  const [state, dispatch] = useReducer(
    campaignBriefHelperReducer,
    initialState
  );

  const contextValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return (
    <CampaignBriefHelperContext.Provider value={contextValue}>
      {children}
    </CampaignBriefHelperContext.Provider>
  );
}
