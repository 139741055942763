import {camelCase} from 'lodash';
import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {
  dismissedReasons,
  reasons,
  buttonNames,
} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import {Feature} from '@/types/feature';
import {Plan} from '@/types/plan';
import {Subscription} from '@/types/subscription';
import {
  getScreenName,
  handleScreenDismissedEvent,
  handleSubscriptionButtonPressedEvent,
  isPricingPage,
} from '@/components/modal-subscription/AnalyticsEventsHandler';
import usePlansQuery from '@/hooks/queries/use-plans-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useModalSubscriptionStore, {
  modalSubscriptionActions,
  DEFAULT_PLAN_NAME,
} from '@/stores/modalSubscriptionStore';
import ModalSubscription from '../modal-subscription';
import handleContactSalesClick from './handleContactSalesClick';

const TRANSLATION_PREFIX = 'components.modal-subscription';

const featureImages: {[key: string]: string} = {
  ActiveCampaignCount: '/assets/images/features/ActiveCampaignCount.png',
  BrandCount: '/assets/images/features/BrandCount.png',
  CollaborationCount: '/assets/images/features/CollaborationCount.png',
  DashboardUserCount: '/assets/images/features/DashboardUserCount.png',
  OffPlatformReview: '/assets/images/features/OffPlatformReview.png',
  ShareCreators: '/assets/images/features/ShareCreators.png',
  ShopifyIntegration: '/assets/images/features/ShopifyIntegration.png',
  ThreadsToggle: '/assets/images/features/ThreadsToggle.png',
};

const isDefaultPlan = (plan: Plan) => plan.name === DEFAULT_PLAN_NAME;

function getNextPlanWithFeature(
  plans: Plan[],
  featureName: string,
  currentPlan: Plan
) {
  if (!plans) return null;
  if (!featureName) return plans.find(isDefaultPlan);
  if (!currentPlan) return null;

  const validPlan = (plan: Plan) => {
    if (plan.name === DEFAULT_PLAN_NAME) return true;
    return plan.cost > currentPlan.cost;
  };

  return plans.filter(validPlan).find((plan: Plan) => {
    if (plan.name === DEFAULT_PLAN_NAME) return true;

    return plan.features.some((feature: Feature) => {
      if (feature.featureType === 'feature_toggle') {
        return feature.name === featureName;
      }
      if (feature.featureType === 'feature_lever') {
        const featureKey = camelCase(featureName);
        const planThreshold = plan.featureThresholds[featureKey];
        const currentPlanThreshold = currentPlan.featureThresholds[featureKey];
        return planThreshold > currentPlanThreshold;
      }
      return false;
    });
  });
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function useModalProps(
  featureName: string | undefined,
  plan: Plan,
  subscription: Subscription
) {
  const featurePrefix = [TRANSLATION_PREFIX, 'feature', featureName].join('.');
  if (!plan) return null;

  let priceLabel;
  if (plan.cost && plan.name !== 'Enterprise') {
    priceLabel = translate(`${TRANSLATION_PREFIX}.plan.price`, {
      price: USDollar.format(plan.cost),
    });
  }
  return {
    heading: featureName
      ? translate(`${featurePrefix}.heading`)
      : translate(`${TRANSLATION_PREFIX}.feature.generic.heading`),
    subheading: featureName
      ? translate(`${featurePrefix}.subheading`)
      : translate(`${TRANSLATION_PREFIX}.feature.generic.subheading`, {
          planLabel: plan.label,
        }),
    image: featureName ? featureImages[featureName] : undefined,
    upsellFeatures: plan.marketingFeatureList,
    priceLabel,
    priceFootnote: plan.priceFootnote,
    planName: plan.label,
    paymentType: plan?.paymentType,
    showContactSalesInFooter: plan.paymentType !== 'invoice',
    submitLabel:
      plan.paymentType === 'invoice'
        ? translate(
            `${TRANSLATION_PREFIX}.content-right.entry.submit-label.invoice`
          )
        : translate(
            `${TRANSLATION_PREFIX}.content-right.entry.submit-label.default`
          ),
    handleContactSalesClick: () => {
      handleSubscriptionButtonPressedEvent({
        screenName: getScreenName('entry', plan?.paymentType),
        buttonName: buttonNames.SUBSCRIPTION_MODAL.CONTACT_SALES,
      });
      handleContactSalesClick(subscription.id);
    },
    handleSubmitClick: () => {
      handleSubscriptionButtonPressedEvent({
        screenName: getScreenName('entry', plan?.paymentType),
        buttonName: buttonNames.SUBSCRIPTION_MODAL.CONTINUE,
      });
      handleScreenDismissedEvent({
        screenName: getScreenName('entry', plan?.paymentType),
        dismissedReason: dismissedReasons.SUBSCRIPTION_MODAL.CONTINUE,
      });

      if (plan.paymentType === 'invoice') {
        return handleContactSalesClick(subscription.id);
      }

      return modalSubscriptionActions.showPayment();
    },
  };
}

function ModalSubscriptionWrapper() {
  const brandId = useBrandId();
  const {plansData} = usePlansQuery();
  const {subscription} = useSubscriptionQuery({brandId});
  const {open, state, featureName, planName} = useModalSubscriptionStore();
  const location = useLocation();

  const plan = React.useMemo(() => {
    if (!plansData) return null;

    if (planName) {
      return plansData.find((_plan: Plan) => _plan?.name === planName);
    }
    if (featureName) {
      return getNextPlanWithFeature(plansData, featureName, subscription.plan);
    }
    return plansData.find(isDefaultPlan);
  }, [plansData, featureName, planName, subscription.plan]);

  const modalProps = useModalProps(featureName, plan, subscription);

  const eventData = useMemo(
    () => ({
      isFlowEnded: !isPricingPage({pathname: location.pathname}),
      reason: !isPricingPage({pathname: location.pathname})
        ? reasons.SUBSCRIPTION_MODAL.ABANDONED
        : '',
      currentScreenName: getScreenName(state, plan?.paymentType),
      isPricingPage: isPricingPage({pathname: location.pathname}),
    }),
    [location.pathname, plan?.paymentType, state]
  );

  if (!plan || !modalProps) return null;

  return (
    <ModalSubscription
      open={open}
      state={state}
      handleClose={() => {
        handleSubscriptionButtonPressedEvent({
          screenName: getScreenName(state, plan?.paymentType),
          buttonName: buttonNames.SUBSCRIPTION_MODAL.CLOSE_MODAL,
        });
        modalSubscriptionActions.close(eventData);
      }}
      {...modalProps}
    />
  );
}

export default ModalSubscriptionWrapper;
