import React, {useEffect, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {
  Avatar,
  Body,
  Checkbox,
  designSystemToken,
} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {buttonNames, screenNames} from '@/lib/delta/deltaConstants.js';
import checkImageValid from '@/utils/checkImageValid';
import type Profile from '@/types/profile';
import ProfilePopup from '@/components/profile/ProfilePopup';
import styles from './UsernameCell.module.scss';

const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

type UsernameCellProps = {
  picture: string;
  name: string;
  isSelected: boolean;
  onCheckboxChange: (checked: boolean) => void;
  profile?: Profile;
  showProfilePopup?: boolean;
  email?: string;
};

function UsernameCell(props: UsernameCellProps) {
  const {
    picture,
    name,
    isSelected,
    onCheckboxChange,
    profile,
    email,
    showProfilePopup = false,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setIsHovered(false);
  };

  const handleCheckboxChange = (checked: boolean) => {
    onCheckboxChange(checked);

    if (checked) {
      AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
        button_name: buttonNames.MY_CREATORS.CREATOR_MARK,
        screen_name: screenNames.MY_CREATORS.MY_CREATORS,
        creator_id: profile?.id,
        flow_id: '',
        flow_name: '',
        triggered_flow_id: '',
        triggered_flow_name: '',
        screen_presentation_id: uuid(),
        tab: '',
        campaign_id: '',
      });
    }
  };

  const [pictureUrlValid, setPictureUrlValid] = useState(true);
  useEffect(() => {
    checkImageValid(picture, setPictureUrlValid);
  }, [picture]);

  return (
    <div className={styles.usernameCell}>
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Checkbox
          checked={isSelected}
          onChange={handleCheckboxChange}
          customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
          customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
        />
      </div>
      <Avatar
        size="sm"
        src={pictureUrlValid ? picture : ''}
        type={pictureUrlValid && picture ? 'picture' : 'placeholder'}
        variant="circular"
        data-testid="avatar"
        background={designSystemToken('semantic.bg.tertiary')}
      />
      <div
        className={styles.nameContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Body size="md">{name}</Body>

        {profile && showProfilePopup && (
          <ProfilePopup
            isVisible={isHovered}
            profile={profile}
            anchorEl={anchorEl}
            onClose={handleMouseLeave}
            email={email}
          />
        )}
      </div>
    </div>
  );
}

export default UsernameCell;
