import React from 'react';
import {Drawer, DrawerProps} from '@mui/material';

export type SideDrawerProps = {
  testID?: string;
  isOpen: boolean;
  drawerClassName?: string;
  withoutBackdrop?: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

function CustomBackdrop() {
  // use custom backdrop to prevent passing the div invalid props that causes errors on the console
  return <div />;
}

const WITHOUT_BACKDROP_PROPS = {
  slots: {backdrop: CustomBackdrop},
  slotProps: {
    root: {
      // override the fixed position + the size of backdrop
      style: {
        position: 'absolute',
        top: 'unset',
        bottom: 'unset',
        left: 'unset',
        right: 'unset',
      },
    },
  },
} as Partial<DrawerProps>;

function SideDrawer(props: SideDrawerProps) {
  const {
    testID = 'side-drawer',
    isOpen,
    drawerClassName,
    withoutBackdrop = false,
    onClose,
    children,
  } = props;

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      data-testid={testID}
      onClose={onClose}
      classes={{paper: drawerClassName}}
      {...(withoutBackdrop ? WITHOUT_BACKDROP_PROPS : {})}
    >
      {children}
    </Drawer>
  );
}

export default SideDrawer;
