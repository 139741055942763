import {useCallback} from 'react';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';

type SearchButtonPressedEventParams = {
  button_name: string;
  screen_name: string;
  tab?: string;
  creator_id?: string;
};

function useTrackSearchButtonEvent() {
  const pressed = useCallback(
    ({
      button_name,
      screen_name,
      tab = '',
      creator_id = '',
    }: SearchButtonPressedEventParams) => {
      const recruitmentSearchFlow = AnalyticsService.getOrCreateFlow(
        FLOW_NAMES.RECRUITMENT_SEARCH
      );
      AnalyticsService.dispatchEvent(eventNames.SEARCH_BUTTON_PRESSED, {
        flow_id: recruitmentSearchFlow.flow_id,
        screen_presentation_id: recruitmentSearchFlow.screen_presentation_id,
        button_name,
        screen_name,
        tab,
        flow_name: recruitmentSearchFlow.flow_name,
        campaign_id: '',
        creator_id,
        source: AnalyticsService.getActiveScreenPresented()?.source || '',
      });
    },
    []
  );

  return {pressed};
}

export default useTrackSearchButtonEvent;
