import React from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import formattedTimestamp from '@/utils/formattedTimestamp';
import {MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {ProfilePreviousCampaign} from '@/types/creatorProfile';
import CREATOR_PROFILE_TEST_IDS from '@/components/creator-profile/testIds';
import Divider from '@/components/divider';
import Link from '@/components/link';
import {CreatorProfileTabContentProps} from '../../CreatorProfileTabContentProps';
import TabWrapper from '../tab-wrapper';
import styles from './PastCampaignsTab.module.scss';

interface PastCampaignProps {
  campaign: ProfilePreviousCampaign;
  isLast: boolean;
  onClick?: (campaign: ProfilePreviousCampaign) => void;
}

function PastCampaign(props: PastCampaignProps) {
  const {campaign, isLast, onClick} = props;
  const {id, name, date, imageUrl} = campaign;

  return (
    <>
      <Link
        to={`/campaigns/${id}`}
        target="_blank"
        onClick={() => onClick?.(campaign)}
      >
        <div className={styles.pastCampaignContainer}>
          <img className={styles.pastCampaignImage} src={imageUrl} alt={name} />
          <div className={styles.campaignInfo}>
            <Label size="lg" color={designSystemToken('semantic.fg.primary')}>
              {name},
            </Label>
            <Label
              size="lg"
              color={designSystemToken('semantic.fg.inverse-secondary')}
            >
              {formattedTimestamp(new Date(date), MONTH_YEAR_FORMAT)}
            </Label>
          </div>
        </div>
      </Link>
      {!isLast ? <Divider margin={0} /> : null}
    </>
  );
}

function PastCampaignsTab(props: CreatorProfileTabContentProps) {
  const {profile, trackButtonPressed} = props;
  const {profilePreviousCampaigns = []} = profile;

  return (
    <TabWrapper
      testID={CREATOR_PROFILE_TEST_IDS.TAB_PAST_CAMPAIGNS}
      className={styles.pastCampaignsTabContainer}
    >
      {profilePreviousCampaigns.map((campaign, index) => (
        <PastCampaign
          key={campaign.id}
          campaign={campaign}
          isLast={index === profilePreviousCampaigns.length - 1}
          onClick={(pastCampaign: ProfilePreviousCampaign) => {
            trackButtonPressed('Click past campaign', {
              campaign_id: pastCampaign.id,
            });
          }}
        />
      ))}
    </TabWrapper>
  );
}

export default PastCampaignsTab;
