import React, {useEffect, useRef, useState} from 'react';
import styles from './LazyImage.module.scss';

export interface LazyImageProps {
  testID?: string;
  className?: string;
  src?: string | null;
  alt?: string;
  style?: React.CSSProperties;
  onLoadCallback?: () => void;
  onErrorCallback?: () => void;
}

const pixelImage = '/assets/images/FFFFFF-0.png';

function LazyImage(props: LazyImageProps) {
  const {
    testID = 'lazy-image',
    className,
    src,
    alt,
    style,
    onLoadCallback,
    onErrorCallback,
  } = props;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(pixelImage);
  const imgRef = useRef(null);

  const loadImage = () => {
    if (!src || imageSrc !== pixelImage) {
      onErrorCallback?.();
      return;
    }

    setIsLoading(true);

    const img = new Image();

    img.onload = () => {
      if (!imgRef.current) return;

      setImageSrc(src);
      setIsLoading(false);

      onLoadCallback?.();
    };

    img.onerror = () => {
      if (!imgRef.current) return;

      setIsError(true);
      setIsLoading(false);
      setImageSrc(pixelImage);

      onErrorCallback?.();
    };

    img.src = src;
  };

  useEffect(() => {
    if (src) {
      setImageSrc(pixelImage);
      loadImage();
    }
  }, [src]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage();
          }
        });
      },
      {rootMargin: '0px', threshold: 0.1}
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <img
      data-testid={testID}
      ref={imgRef}
      src={imageSrc}
      alt={alt}
      style={style}
      className={`${styles.lazyImage} ${className} ${
        isError ? styles.error : ''
      } ${isLoading ? styles.loading : ''} ${
        isError || isLoading ? styles.placeholder : ''
      }`}
    />
  );
}

export default LazyImage;
