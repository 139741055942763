import {RefObject, useEffect, useRef, useState} from 'react';

type UseResizeObserverResult = {
  width?: number;
  height?: number;
};

function useResizeObserver(
  elementRef: RefObject<HTMLElement>
): UseResizeObserverResult {
  const [domRect, setDomRect] = useState<DOMRect>();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDomRect(entry.contentRect);
      });
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return {width: domRect?.width, height: domRect?.height};
}

export default useResizeObserver;
