import React, {useMemo, useState} from 'react';
import {designSystemToken, Icon} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {SectionProps} from '@/views/campaigns/brief/components/campaign-brief/section/SectionProps';
import SubSection from '@/views/campaigns/brief/components/campaign-brief/section/terms-and-conditions/sub-section/SubSection';
import styles from './TermsAndConditions.module.scss';
import getTermsAndConditionsSectionBriefItems from './getTermsAndConditionsSectionBriefItems';

const TRANSLATION_PREFIX =
  'components.campaign-brief.sections.terms-and-conditions';

function TermsAndConditions(props: SectionProps) {
  const {briefFieldManager} = props;
  const [showAll, setShowAll] = useState(false);

  const {displayName: brandName} = briefFieldManager.fields.brand.value;
  const network = briefFieldManager.fields.network.value;
  const subSections = useMemo(
    () => getTermsAndConditionsSectionBriefItems(brandName, network),
    []
  );

  const subSectionsToDisplay = showAll ? subSections : subSections.slice(0, 2);

  return (
    <div className={styles.container}>
      {subSectionsToDisplay.map((section) => (
        <SubSection
          key={section.title}
          title={section.title}
          content={section.contentHtml}
          testID={section.testID}
        />
      ))}

      <div className={styles.showButtonContainer} role="button" tabIndex={0}>
        <span
          className={styles.showButton}
          onClick={() => setShowAll(!showAll)}
        >
          {translate(
            `${TRANSLATION_PREFIX}.${showAll ? 'show-less' : 'show-more'}`
          )}
          <Icon
            size="small"
            appearance="neutral"
            color={designSystemToken('semantic.fg.secondary')}
            name={
              showAll ? 'Actions-Arrow-Up-Large' : 'Actions-Arrow-Down-Large'
            }
          />
        </span>
      </div>
    </div>
  );
}

export default TermsAndConditions;
