import api from '../index';

type AddCreditCardPayload = {
  redirectUri: string;
  card: {
    encryptedCardNumber: string;
    encryptedExpiryMonth: string;
    encryptedExpiryYear: string;
    encryptedSecurityCode: string;
  };
};

type CreateSubscriptionPayload = {
  planName?: string;
};

const subscriptionUpdaters = {
  create: async (data: CreateSubscriptionPayload) =>
    api.post('/subscriptions', data),
  addCreditCard: async (id: string, data: AddCreditCardPayload) =>
    api.put<{data: any}>(`/subscriptions/${id}/add_credit_card`, data),
  requestCustomUpgrade: async (id: string) =>
    api.put(`/subscriptions/${id}/upgrade`),
};

export default subscriptionUpdaters;
