import Campaign from './campaign';
import Campaigns from './campaigns';
import Generic from './generic';
import Stats from './stats';

function BrandHeaderChild() {}

BrandHeaderChild.Campaign = Campaign;
BrandHeaderChild.Campaigns = Campaigns;
BrandHeaderChild.Stats = Stats;
BrandHeaderChild.Generic = Generic;
export default BrandHeaderChild;
