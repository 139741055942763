import {v4 as uuid} from 'uuid';
import {create} from 'zustand';
import {
  FlashMessageData,
  FlashMessageOptions,
} from '@/components/flash-messages-service/flashMessageTypes';

export type FlashMessagesState = {
  flashMessages: FlashMessageData[];
};

const flashMessagesStore = create<FlashMessagesState>(() => ({
  flashMessages: [],
}));

const addFlashMessage = (options: FlashMessageOptions) => {
  const {
    status = 'success',
    message,
    noAutoHide = false,
    duration = 6000,
    delay = 0,
    closeOnClick = true,
    icon = null,
    mode = 'dark',
    allowOnlySingleAppearance = false,
    withCloseButton = true,
    style = {display: 'flex', justifyContent: 'flex-start'},
  } = options;

  const flashMessage = {
    id: uuid(),
    status,
    message,
    noAutoHide,
    duration,
    delay,
    closeOnClick,
    icon,
    mode,
    allowOnlySingleAppearance,
    withCloseButton,
    style,
  };
  flashMessagesStore.setState(({flashMessages}) => {
    let newFlashMessages = flashMessages;
    if (allowOnlySingleAppearance) {
      newFlashMessages = flashMessages.filter(
        (msg) => !msg.allowOnlySingleAppearance
      );
    }

    return {
      flashMessages: [...newFlashMessages, flashMessage],
    };
  });
  return flashMessage;
};

const removeFlashMessage = (flashMessage: FlashMessageData) => {
  flashMessagesStore.setState(({flashMessages}) => ({
    flashMessages: flashMessages.filter(
      (message) => message.id !== flashMessage.id
    ),
  }));
};

export const flashMessagesActions = {
  addFlashMessage,
  removeFlashMessage,
};

export default flashMessagesStore;
