import {CampaignProps} from '@/types/campaign';
import {BriefFieldManagerAttributes} from '@/views/campaigns/brief/brief-field-manager/BriefFieldManager';
import BriefItem from './BriefItem';

class HashtagBriefItem extends BriefItem {
  #swapMatchKeywords: string[];

  constructor(
    briefFieldManager: BriefFieldManagerAttributes,
    campaignData: CampaignProps,
    key: string,
    index: number,
    id: string,
    title: string,
    contentHtml: string,
    highlighted: string,
    clientBriefTemplateId: string
  ) {
    super(
      briefFieldManager,
      campaignData,
      key,
      index,
      id,
      title,
      contentHtml,
      highlighted,
      clientBriefTemplateId
    );
    this.#swapMatchKeywords = campaignData.swapMatchKeywords || [];
  }

  set swapMatchKeywords(value: string[]) {
    this.#swapMatchKeywords = value;
  }

  get toContentHtml() {
    if (this.#swapMatchKeywords?.length === 0) {
      return this.contentHtml;
    }

    return `
        <div>
            <span>#${this.#swapMatchKeywords?.[0]}</span>
        </div>
    `;
  }
}

export default HashtagBriefItem;
