import {xor} from 'lodash';
import React, {useMemo} from 'react';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import Select from '@/components/select';
import {FilterProps} from '../FilterProps';
import styles from './MultiSelectionOptionsDropDown.module.scss';

interface MultiSelectionOptionsDropDownProps extends FilterProps {
  options: BaseFilterOption[];
  selectedOptions: BaseFilterOption[];
  onChange: (option: BaseFilterOption) => void;
  placeholder?: string;
  disabled?: boolean;
}

function MultiSelectionOptionsDropDown({
  options,
  selectedOptions = [],
  onChange,
  placeholder,
  disabled = false,
}: MultiSelectionOptionsDropDownProps) {
  const selectedOptionsIds = useMemo(
    () => selectedOptions.map((option) => option.id),
    [selectedOptions]
  );

  const mappedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: translate(option.localeLabelKey),
      })),
    [options]
  );

  return (
    <Select
      containerClassName={styles.container}
      immediateOnChange
      multiple
      disabled={disabled}
      options={mappedOptions}
      selectedValueIds={selectedOptionsIds}
      renderSelectedLabel={(newSelectedOptions) => {
        if (newSelectedOptions.length > 0) {
          return newSelectedOptions.map((option) => option.label).join(', ');
        }
        if (placeholder) {
          return (
            <Body
              size="lg"
              color={designSystemToken('semantic.fg.inverse-secondary')}
            >
              {placeholder}
            </Body>
          );
        }

        return '';
      }}
      onChange={(value: string | string[]) => {
        const diff = xor(selectedOptionsIds, value);
        const selectedOptionId = diff?.[0];
        const selectedOption = options.find(
          (option) => option.id === selectedOptionId
        );
        if (selectedOption) {
          onChange(selectedOption);
        }
      }}
    />
  );
}

export default MultiSelectionOptionsDropDown;
