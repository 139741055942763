import {ChartOptions, ChartData, ChartDataset} from 'chart.js';
import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import styles from './DonutChart.module.scss';

export type DoughnutChartDataset = ChartDataset<'doughnut', number[]>;

interface DonutChartProps {
  labels?: string[];
  datasets: DoughnutChartDataset[];
  displayTooltip?: boolean;
  displayLegend?: boolean;
}

function DonutChart({
  labels,
  datasets,
  displayTooltip = false,
  displayLegend = false,
}: DonutChartProps) {
  const data: ChartData<'doughnut'> = {
    labels,
    datasets,
  };

  const options: ChartOptions<'doughnut'> = {
    hover: {
      mode: undefined,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: displayTooltip,
      },
      legend: {
        display: displayLegend,
        position: 'bottom',
      },
    },
  };

  return (
    <div className={styles.donutChartContainer}>
      <Doughnut data={data} options={options} />
    </div>
  );
}

export default DonutChart;
