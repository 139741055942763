import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import Preference from '@/views/preferences';
import Account from '@/views/preferences/account';
import Notifications from '@/views/preferences/notifications';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useToken from '@/hooks/use-token';

export const PREFERENCE_ROUTES = {
  MAIN: '',
  ACCOUNT: 'account',
  NOTIFICATIONS: 'notifications',
};

function PreferenceRoutes() {
  const {isAuthenticated} = useToken(true);
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  return (
    <Routes>
      <Route path="/" element={<Preference />}>
        <Route index element={<Account />} />
        <Route path={PREFERENCE_ROUTES.ACCOUNT} element={<Account />} />
        <Route
          path={PREFERENCE_ROUTES.NOTIFICATIONS}
          element={
            dashboardUser && !dashboardUser.isAdmin ? (
              <Notifications />
            ) : (
              <Navigate to="/preferences/account" replace />
            )
          }
        />
      </Route>
    </Routes>
  );
}

export default PreferenceRoutes;
