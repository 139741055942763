// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
async function signupPixelEvents(): Promise<void> {
  window.fbq('track', 'CompleteRegistration'); // Facebook
  window.twq('event', 'tw-oetaz-oetb8', {conversion_id: null}); // Twitter
  window.uetq.push('event', 'signup', {}); // Bing
  window.lintrk('track', {conversion_id: 12576161}); // LinkedIn
  // eslint-disable-next-line no-underscore-dangle
  window._tfa.push({
    notify: 'event',
    name: 'complete_registration',
    id: 1548556,
  }); // Taboola
  return new Promise((resolve) => {
    window.gtag('event', 'conversion', {
      // Google Ads
      send_to: 'AW-11244051408/Ep9zCOnQ2rYYENC3yvEp',
      event_callback() {
        resolve();
      },
    });
    setTimeout(resolve, 2000); // in case gtag request fails
  });
}

export default signupPixelEvents;
