import numeral from 'numeral';

function getChartLabelAbbreviation(number: string | number) {
  if (typeof number !== 'number') {
    return number;
  }

  let format = '0.0a';

  if (number < 1000) {
    format = '0,0';
  }
  if (number > 10000) {
    format = '0a';
  }
  if (number > 1000000) {
    format = '0.00a';
  }

  return numeral(number).format(format);
}

export default getChartLabelAbbreviation;
