import Section from '@/views/campaigns/brief/components/campaign-brief/section';

export const SECTION_ID_MAP = {
  BASIC_DETAILS: 'basicDetails',
  CREATOR_PAYMENTS: 'creatorPayments',
  CREATOR_DETAILS: 'creatorDetails',
  DELIVERABLES: 'deliverables',
  TIMELINE: 'timeline',
  ADDITIONAL_INFORMATION: 'additionalInformation',
  CONTENT_AGREEMENT: 'contentAgreement',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  GENERAL: 'general',
} as const; // `as const` makes it a readonly object

export const SECTIONS = [
  {
    id: SECTION_ID_MAP.BASIC_DETAILS,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.basic-details.title',
    Component: Section.BasicDetails,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.CREATOR_PAYMENTS,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.creator-payments.title',
    Component: Section.CreatorPayments,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.CREATOR_DETAILS,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.creator-details.title',
    Component: Section.CreatorDetails,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.DELIVERABLES,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.deliverables.title',
    Component: Section.Deliverables,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.TIMELINE,
    titleLocaleLabelKey: 'components.campaign-brief.sections.timeline.title',
    Component: Section.Timeline,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.ADDITIONAL_INFORMATION,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.additional-information.title',
    Component: Section.AdditionalInformation,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.CONTENT_AGREEMENT,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.content-agreement.title',
    Component: Section.ContentAgreement,
    initialIsOpen: true,
  },
  {
    id: SECTION_ID_MAP.TERMS_AND_CONDITIONS,
    titleLocaleLabelKey:
      'components.campaign-brief.sections.terms-and-conditions.title',
    Component: Section.TermsAndConditions,
    initialIsOpen: true,
  },
];
