import React, {useEffect, useState} from 'react';
import Spinner from '@/views/ai-content-generator/components/spinner';
import ElementInView from '@/components/element-in-view';
import {
  useContentGenerationStore,
  ImageItemProps,
} from '@/contexts/ContentGenerationStore';
import styles from './GridGallery.module.scss';

export type GridGalleryProps = {
  testID?: string;
  images: ImageItemProps[];
  renderImage: (image: ImageItemProps, index?: number) => React.ReactNode;
  onEndReached?: () => void;
  isFetchingNextPage?: boolean;
};

function GridGallery(props: GridGalleryProps) {
  const {state} = useContentGenerationStore();
  const [newGeneratedImages, setNewGeneratedImages] = useState<
    ImageItemProps[]
  >([]);
  const {
    testID = 'grid-gallery',
    images,
    renderImage,
    onEndReached,
    isFetchingNextPage,
  } = props;

  useEffect(() => {
    if (state.newGeneratedImages.length >= 0) {
      setNewGeneratedImages(state.newGeneratedImages);
    }
  }, [state.newGeneratedImages]);

  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.grid}>
        {newGeneratedImages.map((image, index) => {
          return renderImage(image, index);
        })}

        {images
          .filter(
            (image, index, self) =>
              self.findIndex((i) => i.id === image.id) === index
          )
          .filter(
            (image) =>
              !newGeneratedImages.some((newImage) => newImage.id === image.id)
          )
          .map((image, index) => {
            return renderImage(image, index);
          })}
        {onEndReached ? (
          <ElementInView onInView={onEndReached} height={5} />
        ) : null}
      </div>
      {isFetchingNextPage ? (
        <div className={styles.fetchingNextPageSpinner}>
          <Spinner size={26} />
        </div>
      ) : null}
    </div>
  );
}

export default GridGallery;
