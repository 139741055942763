import React, {useState} from 'react';
import {Checkbox} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import ButtonGroup from '@/components/button-group';
import OptionButton from '@/components/option-button';
import BRIEF_WIZARD_TEST_IDS from '../../testIds';
import IconInput from '../icon-input';
import styles from './SendProductToCreators.module.scss';

const TRANSLATION_PREFIX = `views.campaigns.components.wizard.steps.product.send-product-to-creators`;

type SendProductToCreatorsProps = {
  testID?: string;
  sendProductToCreators: boolean | null;
  sendProductToCreatorsMethod: 'shipments' | 'gift_codes' | null;
  sendProductToCreatorsCashValue: number | null;
  onChange: (sendProductToCreatorsData: object) => void;
  error?: string;
};

const DELIVERABLES_TYPE_OPTIONS = [
  {
    value: null,
    labelLocaleKeyTitle: `${TRANSLATION_PREFIX}.not-relevant`,
    labelLocaleKeySubtitle: `${TRANSLATION_PREFIX}.not-relevant-info`,
  },
  {
    value: 'gift_codes',
    labelLocaleKeyTitle: `${TRANSLATION_PREFIX}.discount-code`,
    labelLocaleKeySubtitle: `${TRANSLATION_PREFIX}.discount-code-info`,
  },
  {
    value: 'shipments',
    labelLocaleKeyTitle: `${TRANSLATION_PREFIX}.ship-product`,
    labelLocaleKeySubtitle: `${TRANSLATION_PREFIX}.ship-product-info`,
  },
];

function SendProductToCreators(props: SendProductToCreatorsProps) {
  const {
    testID = 'send-product-to-creators',
    sendProductToCreators,
    sendProductToCreatorsMethod,
    sendProductToCreatorsCashValue,
    onChange,
    error,
  } = props;

  const [buttonValue, setButtonValue] = useState(sendProductToCreatorsMethod);
  return (
    <div className={styles.container} data-testid={testID}>
      <div className={styles.sendProductToCreatorOptions}>
        <ButtonGroup
          containerClassName={styles.customButtonGroupStyle}
          buttons={DELIVERABLES_TYPE_OPTIONS.map((option) => ({
            ...option,
            title: translate(option.labelLocaleKeyTitle),
            subtitle: translate(option.labelLocaleKeySubtitle),
          }))}
          selectedButtonValue={sendProductToCreatorsMethod}
          onChange={(button: any) => {
            setButtonValue(button.value);
            const additionalData = button.value
              ? {
                  sendProductToCreatorsCashValue: undefined,
                  sendProductToCreatorsMethod: undefined,
                }
              : {};
            onChange({
              sendProductToCreators: !!button.value,
              ...additionalData,
              sendProductToCreatorsMethod: button.value,
            });
          }}
          renderButton={(button: any, isActive, onClick) => (
            <OptionButton
              testID={`${BRIEF_WIZARD_TEST_IDS.PRODUCT__PROVIDE_PRODUCT_METHOD}--${button.value}`}
              button={button}
              className={`${styles.sendProductButtonContainer} ${
                button.value ? '' : styles.notRelevantButtonContainer
              }`}
              onClick={onClick}
              isActive={isActive}
            >
              <div className={styles.sendProductButton}>
                <div className={styles.checkbox}>
                  <Checkbox radio checked={isActive} onChange={() => {}} />
                </div>
                <div className={styles.info}>
                  <label className={styles.title}>{button.title}</label>
                  <label className={styles.subtitle}>{button.subtitle}</label>
                </div>
              </div>
            </OptionButton>
          )}
        />
      </div>
      <div className={styles.sendProductToCreatorsCashValueContainer}>
        {buttonValue ? (
          <IconInput
            testID={BRIEF_WIZARD_TEST_IDS.PRODUCT__CASH_VALUE_INPUT}
            value={sendProductToCreatorsCashValue}
            placeholder={translate(
              `${TRANSLATION_PREFIX}.cash-value-placeholder`
            )}
            icon="$"
            onChange={(value: string) => {
              onChange({
                sendProductToCreators,
                sendProductToCreatorsCashValue: value ? parseInt(value, 10) : 0,
              });
            }}
          />
        ) : null}
      </div>
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
}

export default SendProductToCreators;
