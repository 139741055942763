import {useMutation} from '@tanstack/react-query';
import {produce} from 'immer';
import queryClient from '@/utils/reactQueryClient';
import {type QueryPage} from '@/types/creatorGroupMembership';
import creatorGroupUpdaters from '@/api/updaters/creatorGroup';

function useBulkUpdateMemberships(membershipQueryKey: object) {
  const key = [
    'creatorGroupsMemberships',
    ...Object.values(membershipQueryKey),
  ];

  return useMutation({
    mutationKey: [membershipQueryKey],
    mutationFn: creatorGroupUpdaters.bulkUpdateMemberships,
    onMutate: async (data) => {
      const previousData = await queryClient.getQueryData(key);

      queryClient.setQueryData(key, (old: any) => {
        if (!old) return undefined;

        return {
          ...old,
          pages: produce(old.pages, (draft: QueryPage[]) => {
            data.memberships.forEach((updatedMembership) => {
              draft.forEach((page) => {
                const index = page.data.findIndex(
                  (membership) => membership.id === updatedMembership.id
                );
                if (index !== -1) {
                  page.data[index] = updatedMembership;
                }
              });
            });
          }),
        };
      });

      return {previousData};
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(key, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['creatorGroupsMemberships']);
      queryClient.invalidateQueries(['creatorGroupsLabels']);
    },
  });
}

export default useBulkUpdateMemberships;
