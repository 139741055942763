import dayjs from 'dayjs';
import React, {useMemo} from 'react';
import {designSystemToken, Icon, Tag} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './Badge.module.scss';

type BadgeProps = {
  rating?: number;
  signupDate?: string;
};

const RATING_THRESHOLD = 4;
const IS_NEW_TALENT_DAYS_THRESHOLD = 30;

function Badge({rating, signupDate}: BadgeProps) {
  const isHighlyRated = rating && rating >= RATING_THRESHOLD;
  const isNewTalent = useMemo(() => getIsNewTalent(signupDate), [signupDate]);
  return (
    (isHighlyRated || isNewTalent) && (
      <div className={styles.badge}>
        <Tag
          text={
            isHighlyRated
              ? translate(`components.creator-card.highly-rated`)
              : translate(`components.creator-card.new-talent`)
          }
          textColor={designSystemToken('semantic.fg.white')}
          backgroundColor={designSystemToken('semantic.bg.black-secondary')}
          icon={
            isHighlyRated ? (
              <Icon
                name="Navigation-Star-Fill"
                appearance="inverse"
                size="small"
                className={styles.starIcon}
              />
            ) : null
          }
        />
      </div>
    )
  );
}

function getIsNewTalent(signupDate?: string): boolean {
  if (!signupDate || !dayjs(signupDate).isValid()) {
    return false;
  }
  return dayjs().diff(signupDate, 'day') <= IS_NEW_TALENT_DAYS_THRESHOLD;
}

export default Badge;
