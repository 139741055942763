import {useEffect} from 'react';
import EmberIframe from '@/components/ember-iframe';
import {modalSubscriptionActions} from '@/stores/modalSubscriptionStore';

function useEmberModalOpener() {
  useEffect(() => {
    return EmberIframe.listen(
      'openSubscriptionModal',
      ({featureName, planName, analyticsEventName}) => {
        const eventData = {
          isNewFlowStarted: true,
          reason: analyticsEventName || featureName,
        };
        modalSubscriptionActions.open({
          featureName,
          planName,
          eventData,
        });
      }
    );
  }, []);
}

export default useEmberModalOpener;
