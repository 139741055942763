import React, {useEffect, useRef} from 'react';
import styles from './OtpInput.module.scss';

export type OtpInputProps = {
  testID?: string;
  onTypingCode: (code: string) => void;
  setOtpInputElements: (otpInputElements: HTMLInputElement[]) => void;
  onSubmit: (otpCode: string) => void;
};

const KEY_CODES = {
  backspace: 8,
  delete: 46,
  enter: 13,
};

function OtpInput(props: OtpInputProps) {
  const {
    onTypingCode,
    setOtpInputElements,
    onSubmit,
    testID = 'otp-input',
  } = props;
  const inputRefs = useRef<HTMLInputElement[] | []>([]);
  const initializedRef = useRef<boolean>(false);

  useEffect(() => {
    if (initializedRef.current) {
      return;
    }
    initializedRef.current = true;
    if (inputRefs.current?.length) {
      handleInsert();
    }
  }, []);
  const handleInsert = () => {
    const inputElements: HTMLInputElement[] = inputRefs.current?.map(
      (inputRef: HTMLInputElement) => inputRef
    );
    setOtpInputElements(inputElements);
    inputElements[0].focus();

    inputElements.forEach((el: Element, index: number) => {
      el.addEventListener('keydown', (e: any) => {
        if (
          (e.keyCode === KEY_CODES.backspace && e.target?.value === '') ||
          (e.keyCode === KEY_CODES.delete && e.target?.value === '')
        ) {
          inputElements[Math.max(0, index - 1)].focus();
        } else if (e.keyCode === KEY_CODES.enter) {
          const code = inputElements.map(({value}) => value).join('');
          onSubmit(code);
        }
      });
      el.addEventListener('input', (e: any) => {
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? '';
        const lastInputBox = index === inputElements.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          // Focus next input, sets its value to the rest of the string, and fires its input event
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join('');
          inputElements[index + 1].dispatchEvent(new Event('input'));
        }
        const code = inputElements.map(({value}) => value).join('');
        onTypingCode(code);
      });
    });
  };

  return (
    <div className={styles.otpInputWrapper} data-testid={testID}>
      {[0, 1, 2, 3, 4, 5].map((item: number) => {
        return (
          <input
            key={item}
            ref={(el: HTMLInputElement): void => {
              inputRefs.current[item] = el;
            }}
            className={styles.otpInput}
            name="code"
            type="number"
            data-testid={`${testID}--field`}
          />
        );
      })}
    </div>
  );
}

export default OtpInput;
