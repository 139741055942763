import React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import styles from './AnimatedCheckmarkIcon.module.scss';

function AnimatedCheckmarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="10" className={styles.circle} />
      <path
        className={styles.checkmark}
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 12.5l3 3 6 -6"
      />
    </SvgIcon>
  );
}

export default AnimatedCheckmarkIcon;
