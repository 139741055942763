import QueryContext from '../QueryContext';
import api from '../index';

export const DEFAULT_DASHBOARD_USER_INCLUDE = [
  'current_brand.brand_instagram_business_account',
  'agency.collective',
  'agency.social_networks',
  'permissions',
  'roles',
];

const dashboardUserFetchers = {
  dashboardUser: (context: QueryContext) =>
    api.get('/dashboard_user', {
      params: {
        include: context?.meta?.include || DEFAULT_DASHBOARD_USER_INCLUDE,
      },
    }),
  dashboardUserCurrentBrand: (context: QueryContext) =>
    api.get('/dashboard_user/current_brand', {
      params: {
        brand_id: context?.meta?.brandId,
      },
    }),
};

export default dashboardUserFetchers;
