import type {QueryKey} from '@tanstack/query-core';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

function useInfiniteReactQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey
  >
): UseInfiniteQueryResult<TData, TError> {
  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>({
    refetchOnWindowFocus: false,
    ...options,
  });
}

export default useInfiniteReactQuery;
