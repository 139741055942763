import React, {useMemo} from 'react';
import {Body, Button} from '@lightricks/react-design-system';
import {buttonNames, reasons} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import Link from '@/components/link';
import {
  getScreenName,
  handleScreenDismissedEvent,
  handleSubscriptionButtonPressedEvent,
} from '@/components/modal-subscription/AnalyticsEventsHandler';
import useIsMobile from '@/hooks/use-is-mobile';
import {modalSubscriptionActions} from '@/stores/modalSubscriptionStore';
import styles from './Footer.module.scss';

const TRANSLATION_PREFIX = 'components.modal-subscription.content-right.entry';

type FooterProps = {
  showContactSales: boolean;
  handleContactSalesClick: () => void;
  paymentType?: string;
  mobileButton: JSX.Element;
};

function Footer(props: FooterProps) {
  const {showContactSales, handleContactSalesClick, paymentType, mobileButton} =
    props;
  const footerButtonProps = {
    appearance: 'neutral',
    mode: 'plain',
    size: 'small',
  } as const;

  const isMobile = useIsMobile();

  const eventData = useMemo(
    () => ({
      isFlowEnded: false,
      reason: reasons.SUBSCRIPTION_MODAL.SEE_ALL_PLANES,
      currentScreenName: getScreenName('entry', paymentType),
    }),
    [paymentType]
  );

  return (
    <div className={styles.container}>
      {isMobile ? mobileButton : null}
      <span>{translate(`${TRANSLATION_PREFIX}.footer.note`)}</span>
      <div className={styles.footerLinks}>
        {showContactSales && (
          <>
            <Button {...footerButtonProps} onClick={handleContactSalesClick}>
              <Body size="sm">
                {translate(`${TRANSLATION_PREFIX}.footer.contact-sales`)}
              </Body>
            </Button>
            |
          </>
        )}
        <Button {...footerButtonProps}>
          <Link
            to="/pricing"
            onClick={() => {
              handleSubscriptionButtonPressedEvent({
                screenName: getScreenName('entry', paymentType),
                buttonName: buttonNames.SUBSCRIPTION_MODAL.SEE_ALL_PLANES,
              });
              handleScreenDismissedEvent({
                screenName: eventData.currentScreenName,
                dismissedReason: eventData.reason,
              });
              modalSubscriptionActions.close(eventData);
            }}
          >
            <Body size="sm">
              {translate(`${TRANSLATION_PREFIX}.footer.see-plans`)}
            </Body>
          </Link>
        </Button>
      </div>
    </div>
  );
}

export default Footer;
