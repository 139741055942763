export const buttonNames = {
  LOGIN: {
    LOGIN: 'login',
    CONTINUE: 'continue',
    RESEND_CODE: 'resend_code',
  },
  SIGNUP: {
    CREATE_YOUR_ACCOUNT: 'create_your_account',
    CONTINUE: 'continue',
    RESEND_CODE: 'resend_code',
    CLOSE_MODAL: 'close_modal',
  },
  SUBSCRIPTION: {
    CANCEL_PLAN: 'cancel_plan',
    UPGRADE: 'upgrade',
  },
  CONTENT: {
    ARCHIVE_CONTENT: 'archive_content',
    CONFIRM_ARCHIVE_CONTENT: 'confirm_archive_content',
  },
  TIMELINE: {
    ADD_DATE: 'add_date',
    ADD_DATE_RANGE: 'add_date_range',
  },
  START_CAMPAIGN: {
    NEW_CAMPAIGN: 'new_campaign',
    SUBMIT_CAMPAIGN: 'submit_campaign',
    ADD_PAYMENT_METHOD: 'add_payment_method',
    SHOW_CREATORS: 'show_creators',
    INFO: 'info',
  },
  ORGANIZATION_SETTINGS: {
    PURCHASE_HISTORY: {
      VIEW_INVOICE: 'view_invoice',
    },
  },
  CAMPAIGNS: {
    CAMPAIGN: {
      ACCEPT_APPLICATION: 'accept_application',
      BULK_ACCEPT_APPLICATION: 'bulk_accept_application',
      NEGOTIATE_COST: 'negotiate_cost',
    },
  },
  SEARCH: {
    INVITE_TO_CAMPAIGN: 'invite_to_campaign',
    COLLABORATE: 'collaborate',
    RESUME_THREAD: 'resume_thread',
    ADD_TO_CREATORS: 'add_to_creators',
    OPEN_PROFILE_POPUP: 'open_profile_popup',
  },
  INVITES: {
    INVITE_TO_CAMPAIGN: 'invite_to_campaign',
    DISMISS: 'dismiss',
    OPEN_PROFILE_POPUP: 'open_profile_popup',
  },
  SUBSCRIPTION_MODAL: {
    CONTINUE: 'continue',
    SUBSCRIBE: 'subscribe',
    DONE: 'done',
    SAVE: 'save',
    CONTACT_SALES: 'contact_sales',
    SEE_ALL_PLANES: 'see_all_plans',
    CLOSE_MODAL: 'close_modal',
  },
  MY_CREATORS: {
    INVITE_EXTERNAL_CREATORS: 'invite_external_creators',
    NEW_LIST: 'new_list',
    CLEAR_SELECTION: 'clear_selection',
    CREATOR_MARK: 'creator_mark',
  },
};

export const screenNames = {
  LOGIN: {
    MAIN_LOGIN_SCREEN: 'main_login_screen',
    ENTER_OTP_SCREEN: 'enter_otp_screen',
  },
  SIGNUP: {
    MAIN_SIGNUP_SCREEN: 'main_signup_screen',
    ENTER_OTP_SCREEN: 'enter_otp_screen',
  },
  AUTH: {
    NEW_SIGNUP: 'new_main_signup_screen',
    NEW_SIGNUP_OTP: 'new_signup_enter_otp_screen',
  },
  CONTENT: {
    CAMPAIGNS: 'campaigns',
  },
  SUBSCRIPTION: {
    ORGANIZATION_SETTINGS: 'organization_settings',
    SUBSCRIPTION_PAYMENT: 'subscription_payment',
    UPGRADE_SUBSCRIPTION: 'upgrade_subscription',
    ORGANIZATION_SETTINGS_PLAN_AND_BILLING:
      'organization_settings_plan_and_billing',
  },
  TIMELINE: {
    CAMPAIGNS: 'campaigns',
  },
  // # TODO: Replace all usages of `START_CAMPAIGN` to `NAVIGATION`;
  START_CAMPAIGN: {
    SEARCH: 'search',
    CREATORS: 'creators',
    THREADS: 'threads',
    CAMPAIGNS: 'campaigns',
    CONTENT_LIBRARY: 'content_library',
    STATS: 'stats',
    ONBOARDING: 'onboarding',
  },
  NAVIGATION: {
    SEARCH: 'search',
    CREATORS: 'creators',
    THREADS: 'threads',
    CAMPAIGNS: 'campaigns',
    CONTENT_LIBRARY: 'content_library',
    STATS: 'stats',
    ONBOARDING: 'onboarding',
  },
  CAMPAIGNS: {
    CAMPAIGN: 'campaign',
    INVITES: 'invites',
  },
  SUBSCRIPTION_MODAL: {
    UPGRADE_OFFER: 'upgrade_offer',
    CREDIT_DETAILS: 'credit_details',
    CONTACT_SALES: 'contact_sales',
    ALL_SET: 'all_set',
  },
  PRICING: {
    PRICING_PAGE: 'pricing_page',
  },
  MY_CREATORS: {
    MY_CREATORS: 'my_creators',
  },
};

export const resultSources = {
  SUBSCRIPTION: {
    ORGANIZATION_SETTINGS: 'organization_settings',
    ORGANIZATION_SETTINGS_PLAN_AND_BILLING:
      'organization_settings_plan_and_billing',
  },
  CONTENT: {
    PARTICIPANTS_CONTENT: 'participants_content',
  },
};

export const resultFlows = {
  GENERIC: {
    SUCCESS: 'success',
    FAILURE: 'failure',
    CANCELLED: 'cancelled',
    SAVE_FINISH_LATER: 'save_finish_later',
  },
};

export const detailNames = {
  RECRUITMENT: {
    INSTAGRAM_HANDLE: 'instagram_handle',
    BRAND: 'brand',
    RESET_FILTERS: 'reset_filters',
  },
};

export const presentedReasons = {
  LOGIN: {
    LAUNCH: 'launch',
    BACKSTAGE: 'backstage',
    BACK: 'back',
    LOGIN_STEP: 'login_step',
  },
  SIGNUP: {
    LAUNCH: 'launch',
    BACKSTAGE: 'backstage',
    BACK: 'back',
    SIGNUP_STEP: 'signup_step',
  },
  SUBSCRIPTION: {
    UPGRADE_BUTTON: 'upgrade_button',
  },
};

export const dismissedReasons = {
  LOGIN: {
    EXIT: 'exit',
    TAB_CLOSED: 'tab_closed',
    SUCCESSFUL_STEP: 'successful_step',
    ERROR: 'error',
  },
  SIGNUP: {
    EXIT: 'exit',
    TAB_CLOSED: 'tab_closed',
    SUCCESSFUL_STEP: 'successful_step',
    ERROR: 'error',
    LOGIN: 'login',
  },
  SUBSCRIPTION: {
    BACK_BUTTON: 'back_button',
  },
  SUBSCRIPTION_MODAL: {
    CLOSED: 'closed',
    CONTINUE: 'continue',
  },
};

export const providers = {
  LOGIN: 'fortress',
  SIGNUP: 'fortress',
};

export const requestTypes = {
  LOGIN: {
    DIRECT_LOGIN: 'direct_login',
    LOGIN_WITH_EMAIL: 'login_with_email',
    SEND_OTP: 'send_otp',
    VERIFY_OTP: 'verify_otp',
  },
  SIGNUP: {
    DIRECT_SIGNUP: 'direct_signup',
    SIGNUP_WITH_EMAIL: 'signup_with_email',
    SEND_OTP: 'send_otp',
    VERIFY_OTP: 'verify_otp',
  },
};

export const reasons = {
  LOGIN: {
    SUCCESS: 'success',
    FAILURE: 'failure',
  },
  SIGNUP: {
    SUCCESS: 'success',
    FAILURE: 'failure',
  },
  AUTH: {
    SUCCESS: 'success',
    FAILURE: 'failure',
  },
  SUBSCRIPTION: {
    CANCEL_PLAN_BUTTON: 'cancel_plan_button',
    UPGRADE_HOME: 'upgrade_home',
    UPGRADE_SETTINGS: 'upgrade_settings',
    SUCCESS: 'success',
    FAILURE: 'failure',
    CANCELED: 'canceled',
    CLOSE_BUTTON: 'close_button',
  },
  CONTENT: {
    SUCCESS: 'success',
  },
  START_CAMPAIGN: {
    NEW_CAMPAIGN_BUTTON: 'new_campaign_button',
    ONBOARDING: 'onboarding',
    ADD_PAYMENT_METHOD_BUTTON_PRESSED: 'add_payment_method_button_pressed',
    SHOW_CREATORS: 'show_creators',
    INFO: 'info',
    CLOSED: 'closed',
  },
  SUBSCRIPTION_MODAL: {
    SUCCESS: 'success',
    FAILURE: 'failure',
    ABANDONED: 'abandoned',
    SEE_ALL_PLANES: 'see all plans',
  },
};

export const flowNames = {
  SUBSCRIPTION: {
    CANCEL_PLAN: 'cancel_plan',
    SUBSCRIPTION_CANCELLATION: 'subscription_cancellation',
    SUBSCRIPTION_FLOW_STARTED: 'subscription_flow_started',
    UPGRADE_SUBSCRIPTION: 'upgrade_subscription',
    UPGRADE: 'upgrade',
    LEVER_MAX_OUT: 'lever_max_out',
  },
  CONTENT: {
    ARCHIVE_CONTENT_STARTED: 'archive_content_started',
    ARCHIVE_CONTENT_ENDED: 'archive_content_ended',
  },
  START_CAMPAIGN: {
    NEW_CAMPAIGN: 'new_campaign',
    ADD_PAYMENT_METHOD: 'add_payment_method',
    NEW_CAMPAIGN_BRIEF: 'new_campaign_brief',
    EDIT_CAMPAIGN_BRIEF: 'edit_campaign_brief',
  },
  RECRUITMENT: {
    RECRUITMENT_SEARCH: 'recruitment_search',
  },
  AUTH: {
    NEW_SIGNUP: 'new_signup',
  },
};

export const tabs = {
  SUBSCRIPTION: {
    PLAN_AND_BILLING: 'plan_and_billing',
    BRAND_HEADER: 'brand_header',
  },
  CONTENT: {
    PARTICIPANTS_TAB: 'participants_tab',
  },
  TIMELINE: {
    TIMELINE: 'timeline',
  },
  START_CAMPAIGN: {
    NEW_CAMPAIGN: 'new_campaign',
  },
  ORGANIZATION_SETTINGS: {
    BRANDS: 'brands',
    USERS: 'users',
    PLAN_AND_BILLING: 'plan_and_billing',
    PURCHASE_HISTORY: 'purchase_history',
  },
  CAMPAIGNS: {
    BRIEF: 'brief',
    INVITES: 'invites',
    APPLICATIONS: 'applications',
    PARTICIPANTS: 'participants',
    ASSETS: 'assets',
    STATS: 'stats',
    SETTINGS: 'settings',
  },
};

export const triggeredFlowNames = {
  SUBSCRIPTION: {
    CANCEL_PLAN: 'cancel_plan',
  },
  CONTENT: {
    ARCHIVE_CONTENT: 'archive_content',
  },
  START_CAMPAIGN: {
    NEW_CAMPAIGN: 'new_campaign',
    ADD_PAYMENT_METHOD: 'add_payment_method',
  },
  ORGANIZATION_SETTINGS: {
    PURCHASE_HISTORY: {
      VIEW_INVOICE: 'view_invoice',
    },
  },
  CAMPAIGNS: {
    CAMPAIGN: {
      ACCEPT_APPLICATION: 'accept_application',
      BULK_ACCEPT_APPLICATION: 'bulk_accept_application',
      REMOVE_CREATOR: 'remove_creator',
      NEGOTIATE_CREATOR_COST: 'negotiate_creator_cost',
    },
    INVITES: {
      INVITE_TO_CAMPAIGN: 'invite_to_campaign',
      DISMISS: 'dismiss',
    },
  },
  SEARCH: {
    INVITE_TO_CAMPAIGN: 'invite_to_campaign',
    COLLABORATE: 'collaborate',
    RESUME_THREAD: 'resume_thread',
    ADD_TO_CREATORS: 'add_to_creators',
    OPEN_PROFILE_POPUP: 'open_profile_popup',
  },
};

export const productPrices = {
  SUBSCRIPTION: {
    PRODUCT_PRICE: 499,
  },
};

export const currencies = {
  SUBSCRIPTION: {
    US_DOLLARS: 'us_dollars',
  },
};

export const paymentOptions = {
  SUBSCRIPTION: {
    CREDIT_DEBIT: 'credit/debit',
  },
};

export const paymentDetailsEntered = {
  SUBSCRIPTION: {
    YES: 'yes',
    NO: 'no',
  },
};

export const messageType = {
  SUBSCRIPTION: {
    MODAL: 'modal',
  },
  START_CAMPAIGN: {
    POPUP: 'popup',
  },
};

export const messageNames = {
  SUBSCRIPTION: {
    UPGRADE_PLAN: 'upgrade_plan',
    LIMIT_REACHED: 'limit_reached',
  },
  START_CAMPAIGN: {
    BRIEF_CREATED: 'brief_created',
  },
};

export const sourceNames = {
  START_CAMPAIGN: {
    NEW_CAMPAIGN: 'new_campaign',
  },
};

export const errors = {
  START_CAMPAIGN: {
    INCOMPLETE_FORM: 'incomplete_form',
    FAILED_CHECK: 'failed_check',
  },
};

export const DELTA_SCREEN_IGNORE_ROUTES = [
  'protected.campaigns.campaign.loading',
];

export const DELTA_SCREEN_ROUTE_NAME_MAPPER = {
  'protected.campaigns.campaign.proposals.proposal': {
    screen_name: screenNames.CAMPAIGNS.CAMPAIGN,
    tab: tabs.CAMPAIGNS.APPLICATIONS,
  },
  'protected.campaigns.campaign.contracts.content-submissions.approved': {
    screen_name: screenNames.CAMPAIGNS.CAMPAIGN,
    tab: tabs.CAMPAIGNS.PARTICIPANTS,
  },
  'protected.campaigns.campaign.contracts.contract.content-submissions.pending':
    {
      screen_name: screenNames.CAMPAIGNS.CAMPAIGN,
      tab: tabs.CAMPAIGNS.PARTICIPANTS,
    },
  'protected.organization-settings.plan-billing': {
    screen_name: screenNames.SUBSCRIPTION.ORGANIZATION_SETTINGS,
    tab: tabs.ORGANIZATION_SETTINGS.PLAN_AND_BILLING,
  },
  'protected.organization-settings.purchase-history': {
    screen_name: screenNames.SUBSCRIPTION.ORGANIZATION_SETTINGS,
    tab: tabs.ORGANIZATION_SETTINGS.PURCHASE_HISTORY,
  },
};
