import React from 'react';
import {Button, Icon} from '@lightricks/react-design-system';
import Link from '@/components/link';
import styles from './LibraryButton.module.scss';

function LibraryButton() {
  return (
    <div className={styles.btnLibrary}>
      <Link to="/content-library">
        <Button
          appearance="neutral"
          mode="tinted"
          size="medium"
          icon={
            <Icon
              size="medium"
              appearance="neutral"
              name="Navigation-Photos-Options"
            />
          }
        >
          <div className={styles.buttonLibrary}>Library</div>
        </Button>
      </Link>
    </div>
  );
}

export default LibraryButton;
