import React from 'react';
import {TABS} from '@/components/creator-profile/constants';
import styles from './CreatorProfileTabContent.module.scss';
import {CreatorProfileTabContentProps} from './CreatorProfileTabContentProps';
import {
  OverviewTab,
  AnalyticsAndAudienceTab,
  PostsTab,
  PastCampaignsTab,
  NotesTab,
} from './tabs';

const TAB_MAPPER: {
  [key: string]: (props: CreatorProfileTabContentProps) => React.JSX.Element;
} = {
  [TABS.OVERVIEW.id]: OverviewTab,
  [TABS.ANALYTICS_AND_AUDIENCE.id]: AnalyticsAndAudienceTab,
  [TABS.POSTS.id]: PostsTab,
  [TABS.PAST_CAMPAIGNS.id]: PastCampaignsTab,
  [TABS.NOTES.id]: NotesTab,
};

function CreatorProfileTabContent(
  props: CreatorProfileTabContentProps & {activeTabId: string}
) {
  const {
    activeTabId,
    profile,
    creatorProfileAbilities,
    trackButtonPressed,
    isSharedList,
  } = props;

  const Component = TAB_MAPPER[activeTabId];

  if (!Component) {
    return null;
  }

  return (
    <div className={styles.creatorProfileTabContentContainer}>
      <Component
        activeTabId={activeTabId}
        profile={profile}
        creatorProfileAbilities={creatorProfileAbilities}
        trackButtonPressed={trackButtonPressed}
        isSharedList={isSharedList}
      />
    </div>
  );
}

export default CreatorProfileTabContent;
