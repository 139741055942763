import React from 'react';
import {Box, Stack} from '@mui/material';
import {
  Body,
  Button,
  designSystemToken,
  Headline,
  Modal,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

const MODAL_WIDTH = 600 - 80;

const TRANSLATION_PREFIX =
  'views.creators.components.no-active-campaigns-modal';

type NoActiveCampaignsModalProps = {
  open: boolean;
  close: () => void;
  onSaveToListClick: () => void;
  onCreateCampaignClick: () => void;
  showSaveToListButton?: boolean;
};

function NoActiveCampaignsModal(props: NoActiveCampaignsModalProps) {
  const {
    open,
    close,
    onSaveToListClick,
    onCreateCampaignClick,
    showSaveToListButton = true,
  } = props;
  return (
    <Modal open={open} showCloseButton handleClose={close}>
      <Stack width={MODAL_WIDTH} gap={2}>
        <Headline size="md">
          {translate(`${TRANSLATION_PREFIX}.title`)}
        </Headline>
        <Box>
          <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
            {translate(`${TRANSLATION_PREFIX}.subtitle`)}
          </Body>
        </Box>
        <Box marginTop={2} gap={1} display="flex" justifyContent="center">
          {showSaveToListButton && (
            <Button
              appearance="neutral"
              mode="tinted"
              size="large"
              onClick={onSaveToListClick}
            >
              {translate(`${TRANSLATION_PREFIX}.save-to-list-button`)}
            </Button>
          )}
          <Button
            appearance="neutral"
            mode="filled"
            size="large"
            onClick={onCreateCampaignClick}
          >
            {translate(`${TRANSLATION_PREFIX}.create-campaign-button`)}
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
}

export default NoActiveCampaignsModal;
