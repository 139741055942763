import React from 'react';
import {designSystemToken} from '@lightricks/react-design-system';

type DividerProps = {
  margin?: number;
  vertical?: boolean;
  height?: number;
};

function Divider({margin = 32, vertical = false, height}: DividerProps) {
  const getVerticalStyle = () => {
    if (vertical) {
      return {
        display: 'flex',
        width: 1,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: margin - 8,
        marginRight: margin - 8,
        height: height || '100%',
      };
    }
    return {};
  };
  return (
    <div
      style={{
        height: 1,
        backgroundColor: designSystemToken('semantic.bg.neutral-secondary'),
        marginTop: margin,
        marginBottom: margin,
        ...getVerticalStyle(),
      }}
    />
  );
}

export default Divider;
