import {set} from 'lodash/fp';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
  CampaignProps,
  WizardFormData,
  Brand,
  VerticalTag,
  ContentAgreementTerm,
} from '@/types/campaign';
import BriefFieldManager from '@/views/campaigns/brief/brief-field-manager';
import useContentAgreementTermsQuery from '@/hooks/queries/use-content-agreement-terms-query';
import useVerticalTagsQuery from '@/hooks/queries/use-vertical-tags-query';
import agentMessageToCampaignData from '../../utils/agentMessageToCampaignData';
import campaignDataToAgentMessage from '../../utils/campaignDataToAgentMessage';
import {
  END_WRITE_BRIEF_MESSAGE,
  RawMessageProps,
} from '../use-brief-ai-agent-messages/useBriefAiAgentMessages';

const OMIT_PROCESSED_BRIEF_KEYS = {
  product_to_feature: true,
} as {[key: string]: boolean};

const PLACEHOLDER_BRIEF = {
  campaign_name: '',
  logo_url: '',
  about_your_brand_and_campaign: '',
  campaign_verticals: [],
  creator_followers_count: '',
  creator_gender: [],
  creator_age_range: '',
  creator_states: [],
  ideal_creator: '',
  required_deliverables: [],
  product_to_feature: '',
  campaign_hashtag: '',
  is_placeholder: true,
  is_processing: true,
};

const briefItemMerger = {
  required_deliverables: (currentValue: any, [value]: any) => {
    const matchingIndex = currentValue.findIndex(
      (deliverable: {id: string}) => deliverable.id === value.id
    );

    if (matchingIndex > -1) {
      return set(
        `${matchingIndex}.description`,
        `${currentValue[matchingIndex].description}${value.description}`,
        currentValue
      );
    }

    return currentValue;
  },
} as any;

const briefItemSerializer = {
  required_deliverables: (value: any) => {
    try {
      return typeof value === 'string' ? [...JSON.parse(value)] : [...value];
    } catch (e) {
      console.log('briefItemSerializer:required_deliverables error:', e);
      return [...value];
    }
  },
} as any;

type InitialData = {
  id: string;
  brand: Brand;
};

type UseGetCampaignBriefProps = {
  rawMessages: RawMessageProps[];
  campaignDataFromServer: CampaignProps;
  initialData: InitialData;
  wizardFormData: WizardFormData | null;
  onNewCampaignBriefCallback?: (
    campaignBrief: CampaignProps,
    requestId: string,
    showDraftSavedFlashMessage?: boolean
  ) => Promise<CampaignProps>;
};

type UseGetCampaignBriefReturn = {
  campaignBrief: CampaignProps | null;
};

function useGetCampaignBrief({
  rawMessages,
  campaignDataFromServer,
  initialData,
  wizardFormData,
  onNewCampaignBriefCallback,
}: UseGetCampaignBriefProps): UseGetCampaignBriefReturn {
  const {data: availableVerticalTags, isFetched: availableVerticalTagsFetched} =
    useVerticalTagsQuery();
  const {
    data: availableContentAgreementTerms,
    isFetched: availableContentAgreementTermsFetched,
  } = useContentAgreementTermsQuery();

  const [campaignLoaded, setCampaignLoaded] = useState(false);
  const [campaignBrief, setCampaignBrief] = useState<CampaignProps | null>(
    null
  );
  const briefAutoSaveRef = useRef({lastSavedVersion: 0});
  const appliedBriefChangesRef = useRef<{[key: number]: boolean}>({});
  const newCampaignRef = useRef(false);

  const briefOrInformationMessages = useMemo(() => {
    return rawMessages.filter(
      (message: any) => message.brief || message.information
    );
  }, [rawMessages]);

  const saveInitialCampaignBrief = async (
    placeholderCampaignBrief: CampaignProps
  ) => {
    const serializedCampaignBrief = await new BriefFieldManager(
      campaignDataFromServer
    ).getSerializedData();
    const initialCampaignBrief = {
      ...placeholderCampaignBrief,
      campaignPaidGigBriefItems:
        serializedCampaignBrief.campaignPaidGigBriefItems,
      isProcessing: false,
      isPlaceholder: false,
    };

    const draftCampaignData = await onNewCampaignBriefCallback?.(
      initialCampaignBrief,
      'new_campaign',
      false
    );

    setCampaignBrief({
      ...agentMessageToCampaignData(
        {brief: campaignDataToAgentMessage(draftCampaignData as CampaignProps)},
        draftCampaignData as CampaignProps,
        initialData,
        wizardFormData,
        availableVerticalTags as VerticalTag[],
        availableContentAgreementTerms as ContentAgreementTerm[]
      ),
    });
  };

  const insertPlaceholderCampaignBrief = () => {
    const placeholderCampaignBrief = agentMessageToCampaignData(
      {brief: PLACEHOLDER_BRIEF},
      campaignDataFromServer,
      initialData,
      wizardFormData,
      availableVerticalTags as VerticalTag[],
      availableContentAgreementTerms as ContentAgreementTerm[]
    );
    setCampaignBrief(placeholderCampaignBrief);
    return placeholderCampaignBrief;
  };

  useEffect(() => {
    if (campaignLoaded) {
      return;
    }
    (async () => {
      if (
        campaignDataFromServer?.loaded &&
        !wizardFormData &&
        availableVerticalTagsFetched &&
        availableContentAgreementTermsFetched
      ) {
        setCampaignLoaded(true);
        setCampaignBrief({
          ...agentMessageToCampaignData(
            {brief: campaignDataToAgentMessage(campaignDataFromServer)},
            campaignDataFromServer,
            initialData,
            wizardFormData,
            availableVerticalTags as VerticalTag[],
            availableContentAgreementTerms as ContentAgreementTerm[]
          ),
        });
      }
    })();
  }, [
    campaignDataFromServer,
    wizardFormData,
    availableVerticalTagsFetched,
    availableContentAgreementTermsFetched,
  ]);

  useEffect(() => {
    if (
      !campaignDataFromServer?.loaded &&
      availableVerticalTagsFetched &&
      availableContentAgreementTermsFetched
    ) {
      const placeholderCampaignBrief = insertPlaceholderCampaignBrief();
      if (wizardFormData) {
        saveInitialCampaignBrief(placeholderCampaignBrief);
      }
    }
  }, [
    campaignDataFromServer?.loaded,
    availableVerticalTagsFetched,
    availableContentAgreementTermsFetched,
  ]);

  useEffect(() => {
    if (
      !availableVerticalTagsFetched ||
      !availableContentAgreementTermsFetched
    ) {
      return;
    }
    (async () => {
      if (briefOrInformationMessages.length) {
        let processedBriefKeys: any = {
          required_deliverables: true,
        };
        const briefMessages = briefOrInformationMessages.reduce(
          (acc: any, message: any, index) => {
            if (appliedBriefChangesRef.current[index]) {
              return acc;
            }

            if (message.brief) {
              let briefData = message.brief;
              try {
                if (typeof message.brief === 'string') {
                  briefData = JSON.parse(message.brief);
                }
              } catch (e) {
                console.log('Error parsing brief', e);
                // TODO: should we report this to Sentry?
              }
              Object.keys(briefData).forEach((key) => {
                const itemSerializer = briefItemSerializer[key];
                if (!processedBriefKeys[key]) {
                  if (itemSerializer) {
                    acc.brief[key] = briefItemSerializer[key](briefData[key]);
                  } else {
                    acc.brief[key] = briefData[key];
                  }
                  if (!OMIT_PROCESSED_BRIEF_KEYS[key]) {
                    processedBriefKeys[key] = true;
                  }
                } else if (key in acc.brief) {
                  const newValue = itemSerializer
                    ? briefItemSerializer?.[key]?.(briefData[key])
                    : briefData[key];
                  if (briefItemMerger[key]) {
                    acc.brief[key] = briefItemMerger[key](
                      acc.brief[key],
                      briefData[key]
                    );
                  } else {
                    acc.brief[key] = acc.brief[key].concat(newValue);
                  }
                }
              });
            }
            if (message.information === END_WRITE_BRIEF_MESSAGE) {
              for (let i = 0; i < index; i += 1) {
                // mark all messages up to index 'i' as processed => they will be skipped in the next iteration
                // when there are additional changes to the brief that were requested by the user
                appliedBriefChangesRef.current[i] = true;
              }
              // if next message is the start of a new brief => copy the current brief into it
              processedBriefKeys = {
                required_deliverables: true,
              };
              acc.brief.is_processing = false;
              acc.brief.version = briefOrInformationMessages.length;
              const endWriteBriefMessages = briefOrInformationMessages.filter(
                (briefOrInformationMessage: any) =>
                  briefOrInformationMessage?.information ===
                  END_WRITE_BRIEF_MESSAGE
              );
              if (
                endWriteBriefMessages.length >
                briefAutoSaveRef.current.lastSavedVersion
              ) {
                briefAutoSaveRef.current = {
                  lastSavedVersion: endWriteBriefMessages.length,
                };
                newCampaignRef.current = true;
              }
            }
            return acc;
          },
          {
            brief: {
              is_processing: true,
              version: briefOrInformationMessages.length,
              ...(campaignDataFromServer?.loaded
                ? campaignDataToAgentMessage(campaignDataFromServer)
                : {}),
            },
          }
        );

        if (Object.keys(briefMessages.brief).length === 2) {
          // do not process if there is no new brief data
          // the only keys present are is_processing and version
          return;
        }

        const newCampaignBrief = agentMessageToCampaignData(
          briefMessages,
          campaignDataFromServer,
          initialData,
          wizardFormData,
          availableVerticalTags as VerticalTag[],
          availableContentAgreementTerms as ContentAgreementTerm[]
        );
        setCampaignBrief(newCampaignBrief);

        if (newCampaignRef.current && !newCampaignBrief.isProcessing) {
          onNewCampaignBriefCallback?.(
            newCampaignBrief,
            briefOrInformationMessages[briefOrInformationMessages.length - 1]
              .request_id || ''
          );
          newCampaignRef.current = false;
        }
      }
    })();
  }, [
    briefOrInformationMessages,
    campaignDataFromServer,
    availableVerticalTagsFetched,
    availableContentAgreementTermsFetched,
  ]);

  return {
    campaignBrief,
  };
}

export default useGetCampaignBrief;
