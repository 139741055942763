import React from 'react';
import {Body, Headline} from '@lightricks/react-design-system';
import styles from './SubSection.module.scss';

interface SubSectionProps {
  title: string;
  content: string;
  testID?: string;
}

function SubSection(props: SubSectionProps) {
  const {title, content, testID} = props;

  return (
    <div className={styles.container} data-testid={testID}>
      <Headline size="xs" className={styles.title}>
        {title}
      </Headline>
      <Body
        size="md"
        className={styles.content}
        dangerouslySetInnerHTML={{__html: content}}
      />
    </div>
  );
}

export default SubSection;
