import React from 'react';
import {
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
  AccordionDetails as AccordionDetailsMui,
  styled,
  AccordionProps as AccordionPropsMui,
  Box,
} from '@mui/material';
import {designSystemToken, Icon, Label} from '@lightricks/react-design-system';

type AccordionProps = {
  expanded?: boolean;
  expandIcon?: React.ReactNode | 'default';
  title: string;
  subtitle?: string | React.ReactNode;
  content: React.ReactNode;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
};

const StyledAccordion = styled((props: AccordionPropsMui) => (
  <AccordionMui disableGutters elevation={0} square {...props} />
))(() => ({
  'border': `1px solid ${designSystemToken('semantic.bg.neutral-inverse')}`,
  'borderRight': 'unset',
  'borderLeft': 'unset',
  '&:first-of-type': {
    borderTop: 'unset',
  },
  '&:not(:last-child)': {
    borderBottom: 'unset',
  },
  '&::before': {
    display: 'none',
  },
}));
function Accordion({
  expanded,
  expandIcon = 'default',
  title,
  subtitle,
  content,
  onChange,
}: AccordionProps) {
  return (
    <StyledAccordion expanded={expanded} onChange={onChange}>
      <AccordionSummaryMui
        sx={{padding: '16px 0'}}
        expandIcon={
          expandIcon === 'default' ? (
            <Icon
              size="small"
              appearance="neutral"
              name="Actions-Arrow-Down-Large"
            />
          ) : (
            expandIcon
          )
        }
      >
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
          <Label size="md">{title}</Label>
          {subtitle ? (
            <Label
              size="md"
              sx={{paddingInlineEnd: 1}}
              color={designSystemToken('semantic.fg.inverse-secondary')}
            >
              {subtitle}
            </Label>
          ) : null}
        </Box>
      </AccordionSummaryMui>
      <AccordionDetailsMui sx={{padding: '0 0 16px 0'}}>
        {content}
      </AccordionDetailsMui>
    </StyledAccordion>
  );
}

export default Accordion;
