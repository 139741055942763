import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player';
import {Icon} from '@lightricks/react-design-system';
import useScreenDimensions from '@/hooks/use-screen-dimensions';
import styles from './VideoPlayer.module.scss';

export type VideoPlayerProps = {
  testID?: string;
  url: string;
  autoPlay?: boolean;
  playerRef?: any;
  isMobile?: boolean;
  playerWrapperClassName?: string;
  playerStyle?: React.CSSProperties;
  onError?: (e: any) => void;
  onPlay?: (isInitialPlay: boolean) => void;
  customLoader?: React.ReactNode;
  containerClassName?: string;
};

type VideoPlayerProgress = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

const DESKTOP_SIZE = 388;

function VideoPlayer(props: VideoPlayerProps) {
  const {
    testID = 'video-player',
    url,
    autoPlay = false,
    playerRef,
    isMobile,
    playerWrapperClassName = '',
    playerStyle,
    onError,
    onPlay: onVideoPlay,
    customLoader,
    containerClassName = '',
  } = props;
  const [playerWrapperStyle, setPlayerWrapperStyle] =
    useState<React.CSSProperties>({});
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [isPlayedOnce, setIsPlayedOnce] = useState(false);
  const videoPlayerRef = useRef(null) as any;
  const {screenWidth} = useScreenDimensions();

  useEffect(() => {
    if (playerRef) {
      playerRef.current = videoPlayerRef.current;
    }
  }, []);

  useLayoutEffect(() => {
    if (videoPlayerRef.current) {
      setPlayerWrapperStyle({
        width: isMobile ? undefined : DESKTOP_SIZE,
        height: isMobile ? undefined : DESKTOP_SIZE,
        ...(playerStyle || {}),
      });
    }
  }, [videoPlayerRef.current, screenWidth, playerStyle]);

  const onReady = () => {
    setIsReady(true);
  };

  const onPlay = () => {
    setIsPlaying(true);
    setIsPlayedOnce(true);
    onVideoPlay?.(!isPlayedOnce);
  };
  const onPause = () => {
    setIsPlaying(false);
  };

  const seekTo = (time: number, type: 'seconds' | 'fraction' = 'seconds') => {
    videoPlayerRef?.current?.seekTo?.(time, type);
  };

  const getPlayIcon = () => {
    if (!isReady || (isReady && isPlayedOnce && isPlaying)) {
      return null;
    }

    return (
      <div onClick={onPlay} className={styles.playIcon}>
        <Icon size="large" appearance="white" name="Actions-Play" />
      </div>
    );
  };

  return (
    <div
      className={`${styles.container} ${containerClassName}`}
      data-testid={testID}
    >
      {!isReady ? customLoader : null}
      <div
        className={`${styles.player} ${playerWrapperClassName} ${
          !isReady && !!customLoader ? styles.hidden : ''
        }`}
        style={playerWrapperStyle}
      >
        <ReactPlayer
          style={playerWrapperStyle}
          width="100%"
          height="100%"
          url={url}
          ref={videoPlayerRef}
          playing={isPlaying}
          onReady={onReady}
          onPlay={onPlay}
          onPause={onPause}
          onError={onError}
          controls={isPlayedOnce}
        />
        {getPlayIcon()}
      </div>
    </div>
  );
}

export default VideoPlayer;
