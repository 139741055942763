import React, {useState} from 'react';
import {
  Button,
  Input,
  Body,
  SwitchButton,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import CreatorGroupLabel from '@/types/creatorGroupLabel';
import useUpdateLabelAttributes from '@/hooks/mutations/use-update-label-attributes';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './EditList.module.scss';

const TRANSLATION_PREFIX = 'components.edit-list';

function EditList({label}: {label?: CreatorGroupLabel}) {
  // const {isOpen, onClose} = props;
  const [newName, setNewLabelName] = useState(label?.name);
  const [newOrganizationLevel, setNewOrganizationLevel] = useState(
    label?.organizationLevel
  );

  const handleSwitchChange = () => {
    setNewOrganizationLevel((prevState) => !prevState);
  };

  const updateLabelAttributes = useUpdateLabelAttributes();

  if (!label) return null;

  const handleSubmit = async () => {
    await updateLabelAttributes.mutateAsync({
      label,
      newValues: {
        name: newName,
        organizationLevel: newOrganizationLevel,
      },
    });

    listCreatorsActions.setActiveListAction(null);
  };

  return (
    <div className={styles.content}>
      <Input
        value={newName}
        className={styles.input}
        onInputChange={(val: string) => {
          setNewLabelName(val);
        }}
      />

      <div className={styles.organizationVisibility}>
        <Body size="lg" className={styles.description}>
          {translate(`${TRANSLATION_PREFIX}.organization-visibility`)}
        </Body>

        <SwitchButton
          checked={newOrganizationLevel ?? false}
          onChange={handleSwitchChange}
          className={`${styles.switchButton} ${
            newOrganizationLevel ? styles.checked : ''
          }`}
        />
      </div>
      <div className={styles.saveButtonContainer}>
        <Button
          appearance="neutral"
          size="large"
          mode="filled"
          className={styles.saveButton}
          onClick={handleSubmit}
        >
          {translate(`${TRANSLATION_PREFIX}.save`)}
        </Button>
      </div>
    </div>
  );
}

EditList.textAlignment = 'left' as const;
EditList.footerAlignment = 'right' as const;

export default EditList;
