import React from 'react';
import {RouteProps} from '@/routes/RouteProps';
import translate from '@/utils/translate';
import {SUBSCRIPTION_HOME_ROUTES} from '@/config/subscriptionConstants';
import {SubscriptionVariant} from '@/types/subscription';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import styles from './Home.module.scss';
import WorkflowCard from './components/workflow-card';
import useHomepageWorkflows from './useHomepageWorkflows';

export default function Home(props: RouteProps) {
  const {
    testID = 'home',
    dashboardUser = {hasPlatformAdminRole: false},
    decisions = {},
  } = props;
  const {hasPlatformAdminRole: isPlatformAdmin} = dashboardUser;

  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const navigation = useNavigation();
  const workflows = useHomepageWorkflows();
  const isMobile = useIsMobile(parseInt(styles.homepageBreakLg, 10));
  const aiStudioDecision = decisions['ai-studio'];

  React.useEffect(
    function useRedirectToSubscriptionHome() {
      const subscriptionHomeRoute =
        SUBSCRIPTION_HOME_ROUTES[
          subscription.subscriptionType as SubscriptionVariant
        ];
      if (subscriptionHomeRoute) {
        navigation.navigate(subscriptionHomeRoute);
      }
    },
    [navigation, subscription.subscriptionType]
  );

  React.useEffect(
    function useRedirectToSubscriptionHome() {
      const subscriptionHomeRoute =
        SUBSCRIPTION_HOME_ROUTES[
          subscription.subscriptionType as SubscriptionVariant
        ];
      if (subscriptionHomeRoute) {
        navigation.navigate(subscriptionHomeRoute);
      }
    },
    [navigation, subscription.subscriptionType]
  );

  return (
    <div className={styles.container} data-testid={testID}>
      <section aria-label={translate('views.home.workflows.label')}>
        <div className={styles.workflowsContainer}>
          {workflows.map((workflow) => {
            if (workflow.title === 'AI Studio' && !aiStudioDecision.enabled) {
              return null;
            }
            return <WorkflowCard key={workflow.title} {...workflow} />;
          })}
        </div>
      </section>
    </div>
  );
}
