import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {Button, Icon} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import PERMISSIONS from '@/config/permissions';
import Link from '@/components/link';
import useBrandId from '@/hooks/use-brand-id';
import useDashboardUserPermissions from '@/hooks/use-dashboard-user-permissions';
import useIsMobile from '@/hooks/use-is-mobile';
import styles from './NewCampiagnButton.module.scss';

type NewCampaignButtonProps = {
  testID?: string;
  user?: any;
  hideLabel?: boolean;
  style?: React.CSSProperties;
};

function NewCampaignButton(props: NewCampaignButtonProps) {
  const {testID = 'new-campaign-button', user, hideLabel, style} = props;
  const brandId = useBrandId();
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();

  const createCampaignPermissions = useDashboardUserPermissions(
    [PERMISSIONS.CAMPAIGN.CREATE],
    user?.permissions
  );
  const canCreateCampaign = Object.values(createCampaignPermissions).every(
    Boolean
  );

  const onClick = () => {
    const newCampaignCreationFlow = AnalyticsService.createFlow(
      FLOW_NAMES.CAMPAIGN_CREATION
    );
    AnalyticsService.dispatchEvent(eventNames.CAMPAIGN_CREATION_FLOW_STARTED, {
      campaign_creation_location: 'sidebar',
      campaign_creation_source: 'new_campaign_button',
      flow_id: newCampaignCreationFlow.flow_id,
      source_text: 'New Campaign',
      campaign_id: '',
    });
    searchParams.set('showNewCampaignWizardModal', 'true');
    setSearchParams(searchParams);
  };

  if (canCreateCampaign) {
    return isMobile ? (
      <Link
        testID={testID}
        to={`?brandId=${brandId}&showNewCampaignWizardModal=true`}
        className={styles.newCampaignButtonWrapper}
        onClick={onClick}
      >
        {isMobile ? (
          <div className={styles.newCampaignButtonMobile}>
            {translate('components.new-campaign-button.label')}
          </div>
        ) : (
          <Button
            className={styles.newCampaignButton}
            appearance="brand"
            mode="filled"
            size="medium"
            icon={
              <Icon
                size="medium"
                appearance="white"
                name="Actions-Add-Normal"
              />
            }
            iconPosition="start"
          >
            {translate('components.new-campaign-button.label')}
          </Button>
        )}
      </Link>
    ) : (
      <div
        data-testid={testID}
        className={styles.newCampaignButtonWrapper}
        onClick={onClick}
      >
        <Button
          style={style}
          className={styles.newCampaignButton}
          appearance="brand"
          mode="filled"
          size="medium"
          icon={
            <Icon size="medium" appearance="white" name="Actions-Add-Normal" />
          }
          iconPosition="start"
        >
          {!hideLabel && translate('components.new-campaign-button.label')}
        </Button>
      </div>
    );
  }
  return null;
}

export default NewCampaignButton;
