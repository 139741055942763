import QueryContext from '../QueryContext';
import api from '../index';

const subscriptionFetchers = {
  subscription: (context: QueryContext) =>
    api.get('/subscriptions', {
      params: {
        brand_id: context?.meta?.brandId,
        include: ['levers', 'roles', 'plan', 'plan.features', 'requested_plan'],
      },
    }),
};

export default subscriptionFetchers;
