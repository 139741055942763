import dayjs from 'dayjs';
import {sortBy} from 'lodash';
import React, {useMemo} from 'react';
import {Headline} from '@lightricks/react-design-system';
import getChartLabelAbbreviation from '@/utils/getChartLabelAbbreviation';
import getTickValues from '@/utils/getTickValues';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {MONTH_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import {TiktokAudience} from '@/types/TiktokAccount';
import ChartLayout from '@/components/chart-layout';
import LineChart from '@/components/charts/line-chart';
import FollowerChangeIndication from '@/components/follower-change-indication';
import {TiktokSocialHealthChartProps} from '../../tiktokSocialHealthChart';
import styles from './FollowersCountChart.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-profile.tiktok-social-health.charts.followers';

function getPoints(audiences: TiktokAudience[]) {
  return audiences.map((audience, index) => {
    const {followersCount} = audience;
    return {
      x: index,
      y: followersCount,
    };
  });
}

function getFourWeekFollowerChange(audiences: TiktokAudience[]) {
  const today = dayjs();
  const fourWeeksAgoDayJs = today.subtract(4, 'weeks');

  const audiencesInLastFourWeeks = sortBy(audiences, 'createdAt').filter(
    (audienceRecord) => {
      const createdAtDayJs = dayjs(audienceRecord.createdAt);

      return (
        createdAtDayJs.isBefore(today) &&
        createdAtDayJs.isAfter(fourWeeksAgoDayJs)
      );
    }
  );

  if (audiencesInLastFourWeeks.length <= 1) {
    return null;
  }

  const firstFollowerCount = audiencesInLastFourWeeks[0];
  const lastFollowerCount =
    audiencesInLastFourWeeks[audiencesInLastFourWeeks.length - 1];

  return lastFollowerCount.followersCount - firstFollowerCount.followersCount;
}

function FollowersCountChart(props: TiktokSocialHealthChartProps) {
  const {audiences} = props;
  const sortedAudiences = useMemo(
    () => sortBy(audiences, (audience) => dayjs(audience.createdAt).unix()),
    [audiences]
  );

  const fourWeekFollowerChange = useMemo(
    () => getFourWeekFollowerChange(sortedAudiences),
    [sortedAudiences]
  );

  const points = getPoints(sortedAudiences);
  const tickValues = getTickValues(points.map((point) => point.y));

  const getFourWeekFollowerChangePercentage = () => {
    if (
      fourWeekFollowerChange == null ||
      fourWeekFollowerChange === 0 ||
      audiences == null ||
      audiences.length === 0
    ) {
      return null;
    }

    const latestAudienceFollowerCount = audiences[0].followersCount;

    const denominator = latestAudienceFollowerCount - fourWeekFollowerChange;
    if (denominator === 0) {
      return null;
    }

    return ((fourWeekFollowerChange / denominator) * 100).toFixed(3);
  };

  const title = (
    <div className={styles.title}>
      <Headline size="xxs">
        {translate(`${TRANSLATION_PREFIX}.followers`)}
      </Headline>
      <FollowerChangeIndication
        followerChange={fourWeekFollowerChange}
        followerChangePercentage={getFourWeekFollowerChangePercentage()}
        followerChangeLabel={translate(`${TRANSLATION_PREFIX}.last-four-weeks`)}
      />
    </div>
  );

  const chart = (
    <LineChart
      datasets={[
        {
          fill: false,
          data: points,
          backgroundColor: LEGACY_COLORS.BLUE,
          borderColor: LEGACY_COLORS.BLUE,
          borderWidth: 2,
          pointRadius: 0,
          cubicInterpolationMode: 'monotone',
        },
      ]}
      yTickValues={tickValues}
      labels={sortedAudiences.map(({createdAt}) =>
        dayjs(createdAt).format(MONTH_DAY_YEAR_FORMAT)
      )}
      xTitle={translate(`${TRANSLATION_PREFIX}.date`)}
      yTitle={translate(`${TRANSLATION_PREFIX}.followers`)}
      getYLabel={(label) => getChartLabelAbbreviation(label)}
    />
  );

  return (
    <ChartLayout
      title={title}
      chart={chart}
      showEmptyState={audiences.length === 0}
    />
  );
}

export default FollowersCountChart;
