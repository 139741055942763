import {CreatorProps} from '@/views/creators/components/creator-action/action/ActionProps';
import {CreatorCardProps} from '@/components/creator-card/CreatorCard';

function mapCreatorSearchResultsForAction(
  creator: CreatorCardProps
): CreatorProps {
  return {
    id: creator.id,
    name: creator.fullName,
    avatar: creator.profileImage || '',
    age: creator.age ? parseInt(creator.age, 10) : undefined,
    location: creator.location,
  };
}

export default mapCreatorSearchResultsForAction;
