import React from 'react';
import {Icon, Label} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import {BaseFilterOption} from '@/types/models/search-creators/filter';
import OptionButton from '@/components/option-button';
import {FilterProps} from '../FilterProps';
import styles from './SingleSelectionOptionsGridWithIcon.module.scss';

export interface SingleSelectionOptionsGridWithIconProps extends FilterProps {
  options: {
    id: string;
    localeLabelKey: string;
    iconName?: IconName;
    value: string;
  }[];
  selectedOption?: BaseFilterOption;
  onChange: (option: BaseFilterOption) => void;
}

function SingleSelectionOptionsGridWithIcon(
  props: SingleSelectionOptionsGridWithIconProps
) {
  const {options, selectedOption, onChange} = props;

  return (
    <div className={styles.container}>
      {options.map((option) => (
        <OptionButton
          key={option.id}
          testID={`single-selection-options-grid-with-icon--${option.value}`}
          className={styles.networkButton}
          onClick={() => onChange(option)}
          isActive={option.id === selectedOption?.id}
        >
          <>
            {option.iconName && (
              <Icon name={option.iconName} size="medium" appearance="primary" />
            )}
            <Label size="md">{translate(option.localeLabelKey)}</Label>
          </>
        </OptionButton>
      ))}
    </div>
  );
}

export default SingleSelectionOptionsGridWithIcon;
