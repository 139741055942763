export const postTypeTitles = {
  InstagramPost: 'Instagram Post',
  InstagramStory: 'Instagram Story',
  InstagramReel: 'Instagram Reel',
  TikTokVideo: 'TikTok Video',
  YouTubeVideo: 'YouTube Video',
  YouTubeShorts: 'YouTube Shorts',
  FacebookPost: 'Facebook Post',
  TwitterPost: 'Twitter Post',
  PinterestPost: 'Pinterest Post',
  Post: 'Post',
} as const;

export type Social = (typeof postTypeTitles)[keyof typeof postTypeTitles];
