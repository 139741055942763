import React, {useMemo} from 'react';
import getCountryFullName from '@/utils/getCountryFullName';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {Audience} from '@/types/audience';
import {Identity} from '@/types/identity';
import {Subscription} from '@/types/subscription';
import BarChart from '@/components/charts/bar-chart';
import DonutChart from '@/components/charts/donut-chart';
import Conditional from '@/components/conditional';
import LocationsList from '@/components/locations-list';
import styles from './AudienceGraphics.module.scss';
import LdaCompliantSummary from './LdaCompliantSummary';

const TRANSLATION_PREFIX = 'components.creator-profile.identity-social-health';

const MALE_COLOR = LEGACY_COLORS.BLUE_DARK;
const FEMALE_COLOR = LEGACY_COLORS.PURPLE;

interface AudienceGraphicsProps {
  testID?: string;
  identity: Identity;
  audience: Audience;
}

function getAgeRangeByGenderDatasets(audience: Audience) {
  const femaleAgePercentages = audience.genderAgePercentages.female;
  const maleAgePercentages = audience.genderAgePercentages.male;
  const percentOfAgeGroupFemale = audience.percentageOfAgeGroupByGender.female;
  const percentOfAgeGroupMale = audience.percentageOfAgeGroupByGender.male;

  if (
    !maleAgePercentages ||
    !femaleAgePercentages ||
    !percentOfAgeGroupFemale ||
    !percentOfAgeGroupMale
  ) {
    return [];
  }

  return [
    {
      label: translate(`${TRANSLATION_PREFIX}.men`),
      backgroundColor: MALE_COLOR,
      data: percentOfAgeGroupMale.map((p) => p.percentage),
    },
    {
      label: translate(`${TRANSLATION_PREFIX}.women`),
      backgroundColor: FEMALE_COLOR,
      data: percentOfAgeGroupFemale.map((p) => p.percentage),
    },
  ];
}

function AudienceGraphics(props: AudienceGraphicsProps) {
  const {testID = 'audience-graphics', identity, audience} = props;

  const ageRangeByGenderDatasets = useMemo(() => {
    return getAgeRangeByGenderDatasets(audience);
  }, [audience]);

  const ageRangeByGenderLabels = audience.genderAgePercentages.all.map(
    ({ageRange}) => ageRange
  );

  return (
    <div data-testid={testID} className={styles.audienceGraphicsContainer}>
      <div
        className={`${styles.item} ${styles.gender} ${styles.firstHalfColumn}`}
      >
        <div className={`${styles.header}`}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.audience.item-header-1`)}
          </span>
        </div>
        <div className={styles.body}>
          <DonutChart
            datasets={[
              {
                data: [
                  audience.maleGenderPercentage,
                  audience.femaleGenderPercentage,
                ],
                backgroundColor: [MALE_COLOR, FEMALE_COLOR],
                borderWidth: 0,
              },
            ]}
            labels={[
              `${audience.maleGenderPercentage}% ${translate(
                `${TRANSLATION_PREFIX}.men`
              )}`,
              `${audience.femaleGenderPercentage}% ${translate(
                `${TRANSLATION_PREFIX}.women`
              )}`,
            ]}
            displayLegend
            displayTooltip
          />
        </div>
      </div>
      <div
        className={`${styles.item} ${styles.ageRange} ${
          !identity.ldaComplianceStanding ? styles.firstHalfColumn : ''
        }`}
      >
        <div className={styles.header}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.audience.item-header-2`)}
          </span>
        </div>
        <div className={styles.body}>
          <BarChart
            datasets={ageRangeByGenderDatasets}
            labels={ageRangeByGenderLabels}
            stackDatasets
            displayLegend
          />
        </div>
      </div>
      <Conditional condition={!!identity.ldaComplianceStanding}>
        <div className={`${styles.item} ${styles.lda}`}>
          <LdaCompliantSummary standing={identity.ldaComplianceStanding} />
        </div>
      </Conditional>
      <div
        className={`${styles.item} ${styles.leftColumn} ${styles.locationsList}`}
      >
        <div className={styles.header}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.locations.header-1`)}
          </span>
        </div>
        <div className={`${styles.body} ${styles.locationsList}`}>
          <LocationsList
            locations={audience.cityPercentages}
            accessorKey="city"
          />
        </div>
      </div>
      <div className={`${styles.item} ${styles.locationsList}`}>
        <div className={styles.header}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.locations.header-2`)}
          </span>
        </div>
        <div className={`${styles.body} ${styles.locationsList}`}>
          <LocationsList
            locations={audience.countryPercentages}
            accessorKey="country"
            getLocationName={(country: string) => getCountryFullName(country)}
          />
        </div>
      </div>
    </div>
  );
}

export default AudienceGraphics;
