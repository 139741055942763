import React from 'react';
import styles from './AnimatedLinearGradient.module.scss';

type AnimatedLinearGradientProps = {
  className?: string;
};

function AnimatedLinearGradient({className}: AnimatedLinearGradientProps) {
  return <div className={`${styles.container} ${className}`} />;
}

export default AnimatedLinearGradient;
