import {pickBy, identity} from 'lodash';
import React, {useEffect, useMemo} from 'react';
import {ROUTES} from '@/config/routesConstants';
import {ProfileProps} from '@/types/creatorProfile';
import CreatorProfile from '@/components/creator-profile';
import CREATOR_PROFILE_TEST_IDS from '@/components/creator-profile/testIds';
import Page from '@/components/page';
import useSharedListCreatorProfileQuery from '@/hooks/queries/use-shared-list-creator-profile-query';
import useSharedListCreatorsQuery from '@/hooks/queries/use-shared-list-creators-query';
import useNavigation from '@/hooks/use-navigation';
import useParams from '@/hooks/use-params';
import useWhitelistedSearchParams from '@/hooks/use-whitelisted-search-params';
import styles from './SharedList.module.scss';
import CreatorsSidebar from './components/creators-sidebar';
import Header from './components/header';

const WHITELISTED_SEARCH_PARAMS = [
  'type',
  'ref_id',
  'filter',
  'portfolio',
  'networkAnalytics',
  'posts',
  'activeTabId',
  'activeSocialNetwork',
];

function SharedList() {
  const {creatorProfileId, ...params} = useParams();
  const {ref_id, type, filter} = params;
  const shouldShowApplicationAnswers =
    ['proposals', 'contracts'].includes(type) && ref_id;

  useWhitelistedSearchParams(WHITELISTED_SEARCH_PARAMS);

  const navigation = useNavigation();

  const {data: creators, meta} = useSharedListCreatorsQuery({
    queryParams: {ref_id, type, filter},
  });

  const {profile, isLoading: isLoadingProfile} =
    useSharedListCreatorProfileQuery({
      profileId: creatorProfileId,
      enabled: !!creatorProfileId,
      queryParams: {
        campaign_ref_id: shouldShowApplicationAnswers ? ref_id : undefined,
      },
    });

  useEffect(() => {
    if (!creatorProfileId && creators && creators?.length) {
      navigation.navigate(getCreatorProfileUrl(creators[0].id));
    }
  }, [creators, creatorProfileId]);

  const modalAbilities = useMemo(() => {
    if (!creatorProfileId) {
      return {};
    }

    return {
      canViewPosts: params.posts === 'true',
      canViewAudience: params.networkAnalytics === 'true',
      canViewSocialHealth: params.networkAnalytics === 'true',
      canViewPortfolio: params.portfolio === 'true',
      showApplicationAnswers: shouldShowApplicationAnswers,
    };
  }, [params]);

  const getCreatorProfileUrl = (id: string) => {
    const filteredParams = pickBy({...params, '*': undefined}, identity);
    const urlParams = new URLSearchParams(filteredParams);
    return `${ROUTES.SHARED_LIST}/${id}?${urlParams.toString()}`;
  };

  return (
    <Page
      id="shared-list"
      className={styles.sharedListContainer}
      childrenClassName={styles.sharedListWrapper}
    >
      <Header title={meta?.title || ''} />
      <div className={styles.sharedListContent}>
        <CreatorsSidebar
          testID="shared-list-creators-sidebar"
          creators={creators}
          getCreatorProfileUrl={getCreatorProfileUrl}
          activeCreatorProfileId={creatorProfileId}
        />
        <div className={styles.creatorProfile}>
          <CreatorProfile
            testID={CREATOR_PROFILE_TEST_IDS.MAIN__SHARED_LIST}
            creatorProfileId={creatorProfileId}
            profile={profile as ProfileProps}
            componentAllowsAddMembership={false}
            componentAllowsCollaboration={false}
            componentAllowsInviteToCampaign={false}
            canViewAudience={false}
            canViewSocialHealth={false}
            canViewPortfolio={false}
            canViewPosts={false}
            canViewProfileNotes={false}
            showApplicationAnswers={false}
            isLoading={isLoadingProfile}
            isSharedList
            loaderContainerClassName={styles.loaderContainer}
            {...modalAbilities}
          />
        </div>
      </div>
    </Page>
  );
}

export default SharedList;
