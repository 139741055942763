import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import {
  WorkflowCardProps,
  TRANSLATION_PREFIX,
} from './components/workflow-card/WorkflowCard';

const useHomepageWorkflows = () => {
  const brandId = useBrandId();
  const navigation = useNavigation();

  const workflowActions = {
    createReachCampaign() {
      const urlParams = new URLSearchParams({
        brandId,
        showNewCampaignWizardModal: 'true',
      });
      const url = `?${urlParams.toString()}`;

      const campaignCreationFlow = AnalyticsService.createFlow(
        FLOW_NAMES.CAMPAIGN_CREATION
      );
      AnalyticsService.dispatchEvent(
        eventNames.CAMPAIGN_CREATION_FLOW_STARTED,
        {
          campaign_creation_location: 'homepage',
          campaign_creation_source: 'influencer_campaign_workflow_card',
          flow_id: campaignCreationFlow.flow_id,
          source_text: workflowTranslations(0).title,
          campaign_id: '',
        }
      );
      navigation.navigate(url);
    },
    searchCreators() {
      const urlParams = new URLSearchParams({
        brandId,
      });
      const url = `/search?${urlParams.toString()}`;

      const campaignCreationFlow = AnalyticsService.createFlow(
        FLOW_NAMES.CAMPAIGN_CREATION
      );
      AnalyticsService.dispatchEvent(
        eventNames.CAMPAIGN_CREATION_FLOW_STARTED,
        {
          campaign_creation_location: 'homepage',
          campaign_creation_source: 'visual_content_workflow_card',
          flow_id: campaignCreationFlow.flow_id,
          source_text: workflowTranslations(1).title,
          campaign_id: '',
        }
      );
      navigation.navigate(url);
    },
  };

  const workflowTranslations = (idx: number) => ({
    title: translate(`${TRANSLATION_PREFIX}.${idx}.title`),
    description: translate(`${TRANSLATION_PREFIX}.${idx}.description`),
    buttonLabel: translate(`${TRANSLATION_PREFIX}.${idx}.button`),
  });

  const workflows: WorkflowCardProps[] = [
    {
      ...workflowTranslations(0),
      svgVariant: 'green',
      action: workflowActions.createReachCampaign,
      backgroundColor: 'green',
    },
    {
      ...workflowTranslations(1),
      svgVariant: 'blue',
      action: workflowActions.searchCreators,
      backgroundColor: 'blue',
    },
  ];

  return workflows;
};

export default useHomepageWorkflows;
