import AddToCampaign from './AddToCampaign';
import AddToMyCreators from './AddToMyCreators';
import MessageCreator from './MessageCreator';
import RemoveFromMyCreators from './RemoveFromMyCreators';

function Action() {}

Action.AddToCampaign = AddToCampaign;
Action.AddToMyCreators = AddToMyCreators;
Action.MessageCreator = MessageCreator;
Action.RemoveFromMyCreators = RemoveFromMyCreators;

export default Action;
