import dayjs from 'dayjs';
import {get} from 'lodash';
import React, {useState} from 'react';
import {Body, Label, Tooltip} from '@lightricks/react-design-system';
import compactNumberFormat from '@/utils/compactNumberFormat';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {MONTH_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import {Post} from '@/types/post';
import LazyImage from '@/components/lazy-image';
import Link from '@/components/link';
import styles from './IdentityPost.module.scss';

const TRANSLATION_PREFIX = 'components.identity-post';

export interface IdentityPostProps {
  testID?: string;
  post: Post;
  onClick?: (post: Post) => void;
}

interface Counter {
  id: string;
  iconClassName: string;
  valueKey: string;
  condition: (post: Post) => boolean;
  localeLabelKey: string;
}

const COUNTERS_MAP: Counter[] = [
  {
    id: 'likes',
    iconClassName: styles.likesCountIcon,
    valueKey: 'likeCount',
    condition: (post: Post) => !!post.likeCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.likes`,
  },
  {
    id: 'comments',
    iconClassName: styles.commentsCountIcon,
    valueKey: 'commentCount',
    condition: (post: Post) => !!post.commentCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.comments`,
  },
  {
    id: 'impressions',
    iconClassName: styles.impressionsCountIcon,
    valueKey: 'impressionCount',
    condition: (post: Post) => !!post.impressionCount,
    localeLabelKey: `${TRANSLATION_PREFIX}.impressions`,
  },
];

function PostCounter(props: {
  iconClassName: string;
  value: number;
  label: string;
}) {
  const {iconClassName, value, label} = props;
  return (
    <Tooltip
      title={label}
      arrow
      type="dark"
      placement="top"
      slotProps={{
        popper: {
          modifiers: [{name: 'offset', options: {offset: [0, -10]}}],
        },
      }}
    >
      <div className={styles.postCounter}>
        <div className={iconClassName} />
        <Label size="md">{compactNumberFormat(value)}</Label>
      </div>
    </Tooltip>
  );
}

function IdentityPost(props: IdentityPostProps) {
  const {testID = 'identity-post', post, onClick} = props;
  const [imageError, setImageError] = useState(false);

  return (
    <div className={styles.identityPostContainer} data-testid={testID}>
      <Link
        to={post.networkUrl}
        keepBrandIdIfPresent={false}
        target="_blank"
        onClick={() => onClick?.(post)}
      >
        {imageError ? (
          <div className={styles.missingWrap}>
            <Tooltip
              title={translate(`${TRANSLATION_PREFIX}.image-not-available`)}
              arrow
              type="dark"
              placement="top"
            >
              <div className={styles.missing} />
            </Tooltip>
          </div>
        ) : (
          <div className={styles.imageContainer}>
            <LazyImage
              className={styles.image}
              src={post.imageUrl}
              alt=""
              onErrorCallback={() => setImageError(true)}
            />
          </div>
        )}
      </Link>
      <div className={styles.footerContainer}>
        <div className={styles.countersContainer}>
          {COUNTERS_MAP.filter((counter) => counter.condition(post)).map(
            (counter) => (
              <PostCounter
                key={counter.id}
                iconClassName={counter.iconClassName}
                value={get(post, counter.valueKey)}
                label={translate(counter.localeLabelKey)}
              />
            )
          )}
        </div>
        <div className={styles.date}>
          <Body size="sm" color={LEGACY_COLORS.GRAY_MEDIUM}>
            {dayjs(post.createdAt).format(MONTH_DAY_YEAR_FORMAT)}
          </Body>
        </div>
        <div className={styles.caption}>
          <Body size="md">{post.body}</Body>
        </div>
      </div>
    </div>
  );
}

export default IdentityPost;
