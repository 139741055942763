import React from 'react';
import {Box} from '@mui/material';
import {
  designSystemToken,
  Tooltip,
  Icon,
  Body,
} from '@lightricks/react-design-system';
import styles from './InfoTooltip.module.scss';

export interface InfoTooltipProps {
  testID?: string;
  title: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  iconSize?: 'small' | 'medium' | 'large';
  iconAppearance?: 'primary' | 'secondary' | 'neutral';
  iconColor?: string;
}

function InfoTooltip(props: InfoTooltipProps) {
  const {
    testID = 'info-tooltip',
    title,
    placement = 'top',
    iconSize = 'small',
    iconAppearance = 'neutral',
    iconColor = designSystemToken('semantic.fg.primary'),
  } = props;

  return (
    <Tooltip
      title={<Body size="md">{title}</Body>}
      arrow
      className={styles.tooltip}
      placement={placement}
      data-testid={testID}
    >
      <Box className={styles.centered}>
        <Icon
          size={iconSize}
          appearance={iconAppearance}
          name="Actions-Info-Line"
          color={iconColor}
        />
      </Box>
    </Tooltip>
  );
}

export default InfoTooltip;
