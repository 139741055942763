import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Button,
  Modal,
  Body,
  designSystemToken,
} from '@lightricks/react-design-system';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import useUpdateDashboardUser from '@/hooks/mutations/use-update-dashboard-user';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useIsMobile from '@/hooks/use-is-mobile';
import useToken from '@/hooks/use-token';
import styles from './ModalTermsOfService.module.scss';

const TRANSLATION_PREFIX = 'components.modal-terms-of-service';

export type ModalTermsOfServiceProps = {
  testID?: string;
};

function ModalTermsOfService(props: ModalTermsOfServiceProps) {
  const {testID = 'modal-terms-of-service'} = props;
  const isMobile = useIsMobile();
  const {isAuthenticated} = useToken(true);
  const dashboardUserMutation = useUpdateDashboardUser();
  const {dashboardUser} = useDashboardUserQuery({
    enabled: isAuthenticated,
  });

  if (!dashboardUser?.loaded) return null;

  const open =
    dashboardUser?.acceptedTosVersion !== dashboardUser?.currentTosVersion;

  const onClickAccept = () => {
    dashboardUserMutation.mutateAsync({
      id: dashboardUser.id,
      acceptedTosVersion: dashboardUser.currentTosVersion,
    });
    queryClient.setQueryData(['dashboardUser'], {
      ...dashboardUser,
      acceptedTosVersion: dashboardUser.currentTosVersion,
    });
  };

  return (
    <Modal
      testID={testID}
      open={open}
      width={800}
      style={{padding: '8px'}}
      fullScreen={isMobile}
    >
      <div className={styles.container}>
        <ErrorOutlineIcon />
        <h1>{translate(`${TRANSLATION_PREFIX}.h1`)}</h1>
        <Body
          size="sm"
          color={designSystemToken('semantic.fg.secondary')}
          dangerouslySetInnerHTML={{
            __html: translate(`${TRANSLATION_PREFIX}.p`),
          }}
        />
        <AcceptButton onClick={onClickAccept} />
      </div>
    </Modal>
  );
}

function AcceptButton({onClick}: {onClick: () => void}) {
  return (
    <Button
      testID="modal-terms-of-service__button"
      appearance="neutral"
      size="medium"
      mode="filled"
      onClick={onClick}
    >
      {translate(`${TRANSLATION_PREFIX}.button-text`)}
    </Button>
  );
}

export default ModalTermsOfService;
