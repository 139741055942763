import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';

type BrandSafetyDetailEventParams = {
  flow_id?: string;
  screen_presentation_id?: string;
  brand_safety_screen_name?: string;
  tab?: string;
  brand_safety_detail_name: string;
  brand_safety_detail_value: string;
};
export default function trackBrandSafetyDetailEvent({
  flow_id = '',
  screen_presentation_id = '',
  brand_safety_screen_name = 'Hub',
  tab = '',
  brand_safety_detail_name = '',
  brand_safety_detail_value = '',
}: BrandSafetyDetailEventParams) {
  const flow = AnalyticsService.getOrCreateFlow(
    FLOW_NAMES.BRAND_SAFETY_CREATOR
  );
  const payload = {
    flow_id: flow_id || flow.flow_id,
    screen_presentation_id:
      screen_presentation_id || flow.screen_presentation_id,
    brand_safety_screen_name,
    tab,
    brand_safety_detail_name,
    brand_safety_detail_value,
  };

  AnalyticsService.dispatchEvent(eventNames.BRAND_SAFETY_DETAIL, payload);
}
